import { Form, Formik, FormikHelpers } from "formik"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT } from "../../../../endpoints"
import { IClientPropalModel, IHttpErrorResponseModel, IPersonnelModel } from "@common-models/*"
import MyCard from "@common-utils/MyCard"
import { setResponsablePropalsRequest } from "../core/_requests"
import { AxiosError } from "axios"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import MyAlert from "@common-utils/MyAlert"

const PtfsChangementResponsableForm = ({ ptfs, onSubmitCallBack = () => {} }: IPtfChangementResponsableFormPropsModel) => {
     const initialValues: IPtfChangementResponsableRequestModel = {}

     function handleSubmit(values: IPtfChangementResponsableRequestModel, helpers: FormikHelpers<IPtfChangementResponsableRequestModel>) {
          helpers.setStatus(null)

          setResponsablePropalsRequest(
               ptfs.map(item => item.id as number),
               values.responsable as number
          )
               .then(r => {
                    toast.success("Le changement a bien été appliqué")
                    onSubmitCallBack(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <MyCard classNames={"mb-10"} defaultIsCollapsed={true}>
                    <MyCard.Header isCollapseIconShown>Références concernées ( {ptfs.length} )</MyCard.Header>
                    <MyCard.Body>
                         <>
                              {ptfs.map(item => (
                                   <div>{item.reference}</div>
                              ))}
                         </>
                    </MyCard.Body>
               </MyCard>
               <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}
                                   {/* Responsables */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez le nouveau responsable</label>

                                        {/* Simple search */}
                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"responsable"}
                                                  url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                  value={helpers.values.responsable}
                                                  onChange={val => helpers.setFieldValue("responsable", val)}
                                                  method={"GET"}
                                                  isInvalid={!!helpers.errors.responsable}
                                             />
                                             {helpers.errors.responsable && <div className={"text-danger"}>{helpers.errors.responsable}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer le changement</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPtfChangementResponsableFormPropsModel {
     ptfs: IClientPropalModel[]
     onSubmitCallBack?: (val: IPersonnelModel) => void
}

interface IPtfChangementResponsableRequestModel {
     responsable?: number
}

export default PtfsChangementResponsableForm
