import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query" /**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './common/_metronic/assets/css/style.rtl.css'
 **/
import { MetronicI18nProvider } from "@common-metronic/i18n/Metronici18n"
import "./common/_metronic/assets/ts/general"
import "./common/_metronic/assets/sass/style.scss"
import "./common/_metronic/assets/sass/plugins.scss"
import "./common/_metronic/assets/sass/style.react.scss"
import { AppRoutes } from "./app/routing/AppRoutes"
import "moment/locale/fr"
import { fr } from "yup-locales"
import { setLocale } from "yup"
import { AuthProvider } from "./app/AppContext"
import React from "react"

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

setLocale(fr) // Set Yup local globally to FR

const queryClient = new QueryClient({
     defaultOptions: {
          queries: {
               retry: false,
               refetchOnWindowFocus: false,
          },
     },
})
const container = document.getElementById("root")

if (container) {
     createRoot(container).render(
          <>
               <QueryClientProvider client={queryClient}>
                    <MetronicI18nProvider>
                         <AuthProvider>
                              <AppRoutes />
                         </AuthProvider>
                    </MetronicI18nProvider>
               </QueryClientProvider>
          </>
     )
}
