import React from "react"
import MyTooltip from "@common-utils/MyTooltip"

interface IPropsModel {
     areAllElementsInAllPagesChecked: boolean
     checkedElementIdsInPage: number[]
     totalElementsInPage: number
     totalElementsInAllPages: number
     handleCheckAllElementsInPageCallBack?: () => void
     handleCheckAllElementsAcrossAllPagesCallBack?: () => void
     handleQuitBulkSelectionModeCallBack: () => void
     AllOrFewElementsCheckedInCurrentPageActionsChildren?: React.ReactNode
     AllElementsCheckedAcrossAllPagesActionsChildren?: React.ReactNode
     maxItemsThatCanBeChecked?: number
     allowCheckAllElementsInCurrentPage?: boolean
     allowCheckAllElementInAllPages?: boolean
}

// This container is visible when bulk selection is active. It's a simple container used to keep track of the number of selected elements and perform actions on them, such as deletion for example.
const BulkSelectionContainerForListings = ({
     areAllElementsInAllPagesChecked,
     checkedElementIdsInPage,
     totalElementsInPage,
     totalElementsInAllPages,
     handleCheckAllElementsInPageCallBack = () => {},
     handleCheckAllElementsAcrossAllPagesCallBack = () => {},
     handleQuitBulkSelectionModeCallBack,
     AllOrFewElementsCheckedInCurrentPageActionsChildren,
     AllElementsCheckedAcrossAllPagesActionsChildren,
     allowCheckAllElementsInCurrentPage = true,
     allowCheckAllElementInAllPages = true,
}: IPropsModel) => {
     // Variables that determine whether all items on the current page or across all pages have been marked as checked.
     const areAllElementsInPageChecked: boolean = !areAllElementsInAllPagesChecked && checkedElementIdsInPage.length === totalElementsInPage
     const isEveryItemAcrossAllPagesSelected: boolean = areAllElementsInAllPagesChecked && checkedElementIdsInPage.length === totalElementsInPage

     return (
          <div className={"text-center p-3 bg-secondary"}>
               {/* CASE 1: All elements in all pages has been checked */}
               {isEveryItemAcrossAllPagesSelected && (
                    <>
                         <div className={"mb-2"}>
                              {/* Info */}
                              <span>Les {totalElementsInAllPages} éléments de cette recherche ont étés cochés</span>
                              {/* Quit bulk selection */}
                              <MyTooltip title={"Quitter le mode sélection multiple"}>
                                   <span className="fas fa-times text-danger cursor-pointer fs-1 float-end me-2" onClick={() => handleQuitBulkSelectionModeCallBack()} />
                              </MyTooltip>
                         </div>
                         {/* Actions */}
                         <div>{AllElementsCheckedAcrossAllPagesActionsChildren}</div>
                    </>
               )}

               {/* CASE 2: All elements in page has been checked */}
               {!isEveryItemAcrossAllPagesSelected && areAllElementsInPageChecked && (
                    <>
                         <div>
                              {/* Info */}
                              <span>Tout les éléments de cette page ont été cochés.</span>
                              {/* Quit bulk selection */}
                              <MyTooltip title={"Quitter le mode sélection multiple"}>
                                   <span className="fas fa-times text-danger cursor-pointer fs-1 float-end me-2" onClick={() => handleQuitBulkSelectionModeCallBack()} />
                              </MyTooltip>
                         </div>
                         {/* Check all elements in all pages */}
                         {totalElementsInAllPages > totalElementsInPage && allowCheckAllElementInAllPages && (
                              <div className={"text-primary cursor-pointer"} onClick={() => handleCheckAllElementsAcrossAllPagesCallBack()}>
                                   Cocher les {totalElementsInAllPages} éléments de cette recherche.
                              </div>
                         )}
                         {/* Actions */}
                         <div className={"mt-2"}>{AllOrFewElementsCheckedInCurrentPageActionsChildren}</div>
                    </>
               )}

               {/* CASE 3: Some elements of the page has been selected (not all of them) */}
               {!areAllElementsInPageChecked && !isEveryItemAcrossAllPagesSelected && (
                    <>
                         <div>
                              {/* Info */}
                              <span>
                                   {checkedElementIdsInPage.length > 0
                                        ? `Vous avez coché ${checkedElementIdsInPage.length} ${checkedElementIdsInPage.length > 1 ? "éléments" : "élément"}`
                                        : "Démarrez en cochant quelques élements"}
                              </span>
                              {/* Quit bulk selection */}
                              <MyTooltip title={"Quitter le mode sélection multiple"}>
                                   <span className="fas fa-times text-danger cursor-pointer fs-1 float-end me-2" onClick={() => handleQuitBulkSelectionModeCallBack()} />
                              </MyTooltip>
                         </div>
                         {/* Check all elements in the current */}
                         {allowCheckAllElementsInCurrentPage && (
                              <div className={"text-primary cursor-pointer"} onClick={() => handleCheckAllElementsInPageCallBack()}>
                                   Cocher les {totalElementsInPage} éléments de cette page
                              </div>
                         )}
                         {/* Actions */}
                         <div className={"mt-2"}>{checkedElementIdsInPage.length > 0 && AllOrFewElementsCheckedInCurrentPageActionsChildren}</div>
                    </>
               )}
          </div>
     )
}

export default BulkSelectionContainerForListings
