import React from "react"

type Props = {
     classNames?: string
     size?: "sm" | "md" | "lg" | "xl"
}

const MySimpleSpinner: React.FC<Props> = ({ classNames = "", size = "md" }) => {
     let widthAndHeight
     switch (size) {
          case "sm":
               widthAndHeight = 1.4
               break
          case "md":
               widthAndHeight = 2
               break
     }

     return (
          <div
               className={`spinner-border align-middle ${classNames}`}
               style={{
                    width: `${widthAndHeight}rem`,
                    height: `${widthAndHeight}rem`,
               }}
          />
     )
}

export default MySimpleSpinner
