import React, { useMemo } from "react"
import MyCard from "@common-utils/MyCard"
import { usePtfListingContext } from "./PtfListing"
import PtfDetailsPeriodesBonsCommandeBonsLivraison from "../periodesBonsCommandeBonsLivraison/_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import { IClientBonLivraisonModel } from "@common-models/*"
import { useQueryClient } from "react-query"
import { IGetPropositionsResponseModel } from "./core/_models"
import { Link } from "react-router-dom"

/* Wrapper */
const PtfListingPreview = () => {
     const queryClient = useQueryClient()
     const globalContext = usePtfListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.proposition.id === selectedItemInPreview?.proposition.id)

     // Periodes, BC, BL callback (used for forfait in order to set PTF state)
     function handleSubmitAddBonLivraisonCallback(val: IClientBonLivraisonModel, livraisonTotale?: boolean) {
          // ON ADD LIVRABLE (FORFAIT): Set état of PTF détails
          if (selectedItemInPreview.proposition?.type === "TYPE_FORFAIT") {
               queryClient.setQueryData<IGetPropositionsResponseModel | undefined>(globalContext.REACT_QUERY_KEY_LISTING, (prev: IGetPropositionsResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              if (item.proposition?.id === selectedItemInPreview.proposition?.id) {
                                   if (!["ETAT_REFUSEE", "ETAT_DISABLED", "ETAT_LIVRE"].includes(item.proposition.etat as string)) {
                                        // Set PTF état to "En production" only if livrable is état = "En production"
                                        if (val.etat === "ETAT_EN_COURS" && !["ETAT_EN_PROD", "ETAT_PARTIELLEMENT_LIVRE"].includes(item.proposition.etat as string)) {
                                             item.proposition.etat = "ETAT_EN_PROD"
                                             item.proposition.etatLabel = "En production"
                                             return item
                                        }

                                        if (val.etat === "ETAT_ENVOYE_AU_CLIENT") {
                                             item.proposition.etat = livraisonTotale ? "ETAT_LIVRE" : "ETAT_PARTIELLEMENT_LIVRE"
                                             item.proposition.etatLabel = livraisonTotale ? "Livré" : "Partiellement livré"
                                             return item
                                        }
                                   }
                              }
                         })
                    }
                    return prev
               })
          }
     }

     const PtfDetailsPeriodesBonsCommandeBonsLivraisonMemo = useMemo(() => {
          return (
               <PtfDetailsPeriodesBonsCommandeBonsLivraison
                    pFilters={{ ptfs: [selectedItemInPreview?.proposition?.id as number] }}
                    handleSubmitAddBonLivraisonCallback={handleSubmitAddBonLivraisonCallback}
                    handleOnLoadCallback={() => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : null))}
               />
          )
     }, [selectedItemInPreview.proposition?.id])

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>
                    <div className="d-flex align-items-center">
                         <span className={"fas fa-eye-slash fs-4 text-dark p-2 cursor-pointer"} onClick={() => globalContext.setItemInPreview(null)} />
                         <Link to={`/client/ptf/${itemInPreview?.proposition.id}/details`} className={"me-2"}>
                              {itemInPreview?.proposition?.reference}
                         </Link>
                    </div>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ height: "75.1vh", overflowY: "scroll" }}>
                    <>{PtfDetailsPeriodesBonsCommandeBonsLivraisonMemo}</>
               </MyCard.Body>
          </MyCard>
     )
}

export default PtfListingPreview
