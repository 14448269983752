import { useQuery, useQueryClient } from "react-query"
import { ICandidatSuiviDocumentModel, ICandidatSuiviModel, ICandidatSuiviSMDModel, IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { getSuivis } from "../core/_requests"
import { toast } from "react-toastify"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useMemo, useState } from "react"
import MyAlert from "@common-utils/MyAlert"
import MyTooltip from "@common-utils/MyTooltip"
import MyModal from "@common-utils/MyModal"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import {
     CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE,
     CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE,
     CONST_MIME_APPLICATION_DOC,
     CONST_MIME_APPLICATION_DOCX,
     CONST_MIME_APPLICATION_GOOGLE_DOC,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
} from "@common-constants/*"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { useAuth } from "../../../../AppContext"
import CandidatSuiviEditForm from "../candidatSuivi/_CandidatSuiviEditForm"

const REACT_QUERY_KEY_GET_SUIVI_LISTING_QUERY = "REACT_QUERY_KEY_GET_SUIVI_LISTING_QUERY"
export const CandidatSuiviListing = ({ candidat_id }: { candidat_id: number }) => {
     const queryClient = useQueryClient()
     const { currentUser } = useAuth()
     const [commentInModal, setCommentInModal] = useState<string | null>(null)
     const [someItemBeingEdited, setSomeItemBeingEdited] = useState<ICandidatSuiviDocumentModel | null>(null)
     const [suiviDocumentInModal, setSuiviDocumentInModal] = useState<{ item: ICandidatSuiviDocumentModel; loading: boolean; mode: "view" | "edit" } | null>(null)
     const suiviListingQuery = useQuery<ICandidatSuiviModel[], AxiosError>(REACT_QUERY_KEY_GET_SUIVI_LISTING_QUERY, () => {
          return getSuivis(candidat_id)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const SuiviDocumentInModal = useMemo(() => {
          return suiviDocumentInModal ? (
               <MyModal title={<span>Document du suivi</span>} show={true} handleClose={() => setSuiviDocumentInModal(null)} size={"lg"} noPadding>
                    <>
                         <MyLoadingOverlay isActive={suiviDocumentInModal.loading}>
                              <iframe
                                   onLoad={() => setSuiviDocumentInModal(prev => (prev ? { ...prev, loading: false } : null))}
                                   src={
                                        suiviDocumentInModal!.mode === "view"
                                             ? FileAndDriveHelper.getUrlOfDriveFilePreview(suiviDocumentInModal.item.googleDriveID as string)
                                             : FileAndDriveHelper.getUrlOfDriveFileEditMode(suiviDocumentInModal.item.googleDriveID as string)
                                   }
                                   style={{ height: "100vh", width: "100%" }}
                              />
                         </MyLoadingOverlay>

                         {/* Iframe utils (only if word) */}
                         {suiviDocumentInModal.item.googleDriveMimeType &&
                              [CONST_MIME_APPLICATION_DOCX, CONST_MIME_APPLICATION_DOC, CONST_MIME_APPLICATION_GOOGLE_DOC, CONST_MIME_APPLICATION_GOOGLE_DOC].includes(
                                   suiviDocumentInModal.item.googleDriveMimeType
                              ) && (
                                   <div className={"vertical-container-of-button-on-iframe bg-secondary rounded text-center"}>
                                        {/* View / Edit Mode */}
                                        <MyTooltip title={suiviDocumentInModal!.mode === "view" ? "Passer en mode édition" : "Passer en mode visualisation"}>
                                             <button
                                                  type={"button"}
                                                  className={"btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle"}
                                                  onClick={() =>
                                                       setSuiviDocumentInModal(prev => (prev ? { ...prev, mode: prev.mode === "view" ? "edit" : "view", loading: true } : null))
                                                  }
                                             >
                                                  {suiviDocumentInModal!.mode === "view" ? <i className={"fas fa-edit"} /> : <i className={"fas fa-eye"} />}
                                             </button>
                                        </MyTooltip>
                                   </div>
                              )}
                    </>
               </MyModal>
          ) : (
               <></>
          )
     }, [suiviDocumentInModal])

     const ItemInEditionForm = () => {
          function handleSubmitCallBack(modifiedCandidatSuivi: ICandidatSuiviModel) {
               queryClient.setQueryData<ICandidatSuiviModel[] | undefined>(REACT_QUERY_KEY_GET_SUIVI_LISTING_QUERY, (prev: ICandidatSuiviModel[] | undefined) => {
                    if (prev) {
                         const index = prev.findIndex(item_ => item_.id === modifiedCandidatSuivi.id)
                         prev[index] = {
                              ...prev[index],
                              ...modifiedCandidatSuivi,
                         }

                         return prev
                    }

                    return prev
               })
               setSomeItemBeingEdited(null)
          }
          return someItemBeingEdited ? <CandidatSuiviEditForm id={someItemBeingEdited.id as number} handleSubmitCallBack={handleSubmitCallBack} /> : <></>
     }

     const CommentInModal = useMemo(() => {
          return commentInModal ? (
               <MyModal title={<span>Commentaire</span>} show={true} handleClose={() => setCommentInModal(null)}>
                    <span dangerouslySetInnerHTML={{ __html: commentInModal }} />
               </MyModal>
          ) : (
               <></>
          )
     }, [commentInModal])

     const DmsIcons = ({ dms }: { dms: ICandidatSuiviSMDModel }) => {
          return (
               <>
                    {dms.disponibilite && (
                         <span className={"me-2"}>
                              {dms.disponibilite === CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE ? (
                                   <MyTooltip title={`Disponible à partir du ${dms.disponibiliteDate?.format("dddd Do MMM YYYY")}`}>
                                        <i className={"fas fa-calendar-check fs-3 text-dark"} />
                                   </MyTooltip>
                              ) : (
                                   <MyTooltip title={`Disponibilité: ${dms.disponibiliteLabel}`}>
                                        <i className={"fas fa-calendar-check fs-3 text-dark"} />
                                   </MyTooltip>
                              )}
                         </span>
                    )}

                    {dms.mobilite && (
                         <span className={"me-2 fs-3 text-dark"}>
                              {[CONST_MOBILITE_REGIONAL, CONST_MOBILITE_DEPARTEMENTAL, CONST_MOBILITE_PAR_VILLE].includes(dms.mobilite) ? (
                                   <>
                                        {dms.mobilite === CONST_MOBILITE_REGIONAL && (
                                             <MyTooltip title={`Mobile sur: ${dms.candidatSuiviSMDRegions.map(reg => reg.region?.nom).join(", ")}`}>
                                                  <i className={"fas fa-globe fs-3 text-dark"} />
                                             </MyTooltip>
                                        )}
                                        {dms.mobilite === CONST_MOBILITE_DEPARTEMENTAL && (
                                             <MyTooltip title={`Mobile sur: ${dms.candidatSuiviSMDDepartements.map(dep => dep.departement?.nom).join(", ")}`}>
                                                  <i className={"fas fa-globe fs-3 text-dark"} />
                                             </MyTooltip>
                                        )}
                                        {dms.mobilite === CONST_MOBILITE_PAR_VILLE && (
                                             <MyTooltip title={`Mobile sur: ${dms.candidatSuiviSMDVilles.map(vil => vil.ville?.nom).join(", ")}`}>
                                                  <i className={"fas fa-globe fs-3 text-dark"} />
                                             </MyTooltip>
                                        )}
                                   </>
                              ) : (
                                   <MyTooltip title={`Mobilité: ${dms.mobiliteLabel}`}>
                                        <i className={"fas fas fa-globe fs-3 text-dark"} />
                                   </MyTooltip>
                              )}
                         </span>
                    )}

                    {dms.salaire && (
                         <MyTooltip title={`Salaire net mensuel souhaité: ${dms.salaire}€`}>
                              <i className={"fas fa-euro-sign fs-3 text-dark"} />
                         </MyTooltip>
                    )}
               </>
          )
     }

     return (
          <>
               {/* Fetching */}
               {suiviListingQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {/* Error */}
               {!suiviListingQuery.isFetching && suiviListingQuery.error && <MyAlert type={"danger"}>{suiviListingQuery.error.response?.data.detail}</MyAlert>}

               {/* If data */}
               {!suiviListingQuery.isFetching && suiviListingQuery.data && suiviListingQuery.data.length > 0 && (
                    <>
                         {!someItemBeingEdited && (
                              <table className={"table table-rounded table-striped  gy-7 gs-7 m-0 p-0"}>
                                   <thead className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <tr>
                                             <th>Date</th>
                                             <th>Type</th>
                                             <th>Responsable</th>
                                             <th>Commentaire</th>
                                             <th>Document</th>
                                             <th></th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {suiviListingQuery.data.map((item: ICandidatSuiviModel, key) => (
                                             <tr key={key}>
                                                  <td>{item.date_suivi?.format("DD/MM/YYYY")}</td>
                                                  <td>{item.typeLabel}</td>
                                                  <td>{item.personnel?.prenomNom}</td>
                                                  <td className={"d-flex align-items-center"}>
                                                       {item.commentaire && (
                                                            <MyTooltip title={"Voir commentaire"}>
                                                                 <span
                                                                      className={`fas fa-comment-dots text-primary cursor-pointer fs-2`}
                                                                      onClick={() => item.commentaire && setCommentInModal(item.commentaire)}
                                                                 />
                                                            </MyTooltip>
                                                       )}
                                                       {item.type === CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE &&
                                                            item.candidatSuiviSMDs &&
                                                            item.candidatSuiviSMDs.length > 0 && (
                                                                 <div className={"ms-4"}>
                                                                      <DmsIcons dms={item.candidatSuiviSMDs[0]} />
                                                                 </div>
                                                            )}
                                                  </td>
                                                  <td>
                                                       {item.candidatSuiviDocuments?.map((item_, key) => (
                                                            <MyTooltip title={"Voir document"} key={key}>
                                                                 <span
                                                                      className={`${FileAndDriveHelper.getFontAwesomeClassAndColorAccordingToFileMimeType(
                                                                           item_.googleDriveMimeType as string
                                                                      )} cursor-pointer fs-2 me-2`}
                                                                      onClick={() => setSuiviDocumentInModal({ item: item_, loading: true, mode: "view" })}
                                                                 />
                                                            </MyTooltip>
                                                       ))}
                                                  </td>
                                                  <td>
                                                       {currentUser?.teamRecrutement?.find(item_ => item_.id === item.personnel?.id) && (
                                                            <span className={`fas fa-edit cursor-pointer fs-2`} onClick={() => setSomeItemBeingEdited(item)} />
                                                       )}
                                                  </td>
                                             </tr>
                                        ))}
                                   </tbody>
                              </table>
                         )}

                         {someItemBeingEdited && (
                              <div className={"p-5"}>
                                   <button
                                        type={"button"}
                                        className={"btn btn-sm btn-outline btn-outline-dark d-flex align-items-center"}
                                        onClick={() => setSomeItemBeingEdited(null)}
                                   >
                                        <i className={"fas fa-arrow-left me-2"} /> Retourner à la liste
                                   </button>
                                   <ItemInEditionForm />
                              </div>
                         )}
                    </>
               )}

               {suiviDocumentInModal && <>{SuiviDocumentInModal}</>}
               {commentInModal && <>{CommentInModal}</>}
          </>
     )
}
