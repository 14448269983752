import { useParams } from "react-router-dom"
import SideBar from "./_SideBar"
import { useQuery, UseQueryResult } from "react-query"
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { getPersonnelDetailsRequest } from "../core/_requests"
import { IGetPersonnelDetailsResponseModel } from "../core/_models"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import Navigation from "./_Navigation"
import "./styles.scss"
import InfoBase from "./infoBase/InfoBase"
import Kpi from "./kpi/Kpi"
import Nfc from "./nfc/Nfc"
import Compte from "./compte/Compte"

const REACT_QUERY_KEY_GET_PERSONNEL_DETAILS = "REACT_QUERY_KEY_GET_PERSONNEL_DETAILS"
const PersonnelDetailsContext = createContext<IPersonnelDetailsContextModel>({} as IPersonnelDetailsContextModel)
export const usePersonnelDetailsContext = () => useContext(PersonnelDetailsContext)
const PersonnelDetails = () => {
     const id = parseInt(useParams<{ id: string }>().id as string)
     const [selectedNavigationItem, setSelectedNavigationItem] = useState<"INFO_BASE" | "KPI" | "NFC" | "COMPTE">("INFO_BASE")

     const detailsQuery = useQuery<IGetPersonnelDetailsResponseModel, AxiosError>(REACT_QUERY_KEY_GET_PERSONNEL_DETAILS, async () => {
          return getPersonnelDetailsRequest(id)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     useEffect(() => {
          detailsQuery.refetch().then()
     }, [id])

     return (
          <>
               <div className="d-flex justify-content-center">
                    <div className={"w-100 w-xxl-80"}>
                         <PersonnelDetailsContext.Provider
                              value={{
                                   detailsQuery,
                                   selectedNavigationItem,
                                   setSelectedNavigationItem,
                                   REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                              }}
                         >
                              <>
                                   {detailsQuery.isFetching && (
                                        <div className={"text-center p-5 bg-body"}>
                                             <MySimpleSpinner size={"xl"} />
                                        </div>
                                   )}

                                   {!detailsQuery.isFetching && detailsQuery.isError && (
                                        <div className={"text-center p-5 bg-body"}>
                                             <MyAlert type={"danger"}>
                                                  <>
                                                       <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                                                       <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                                            Recharger
                                                       </button>
                                                  </>
                                             </MyAlert>
                                        </div>
                                   )}

                                   {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                                        <div className="d-flex flex-column flex-lg-row">
                                             <div className="mb-10 mb-lg-0">
                                                  <SideBar />
                                             </div>

                                             <div className="flex-lg-row-fluid ms-lg-15">
                                                  <div className="bg-body pt-2 d-lg-none">
                                                       <Navigation />
                                                  </div>

                                                  {selectedNavigationItem === "INFO_BASE" && <InfoBase />}
                                                  {selectedNavigationItem === "KPI" && <Kpi />}
                                                  {selectedNavigationItem === "NFC" && <Nfc />}
                                                  {selectedNavigationItem === "COMPTE" && <Compte />}
                                             </div>
                                        </div>
                                   )}
                              </>
                         </PersonnelDetailsContext.Provider>
                    </div>
               </div>
          </>
     )
}

interface IPersonnelDetailsContextModel {
     REACT_QUERY_KEY_GET_PERSONNEL_DETAILS: string
     detailsQuery: UseQueryResult<IGetPersonnelDetailsResponseModel, AxiosError>
     selectedNavigationItem: "INFO_BASE" | "KPI" | "NFC" | "COMPTE"
     setSelectedNavigationItem: Dispatch<SetStateAction<"INFO_BASE" | "KPI" | "NFC" | "COMPTE">>
}

export default PersonnelDetails
