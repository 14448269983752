import React from "react"
import MyCard from "@common-utils/MyCard"
import MyAlert from "@common-utils/MyAlert"

const CompteDocuments = () => {
     return (
          <MyCard>
               <MyCard.Header>Mes documents</MyCard.Header>
               <MyCard.Body>
                    <MyAlert type={"primary"}>Coming soon ...</MyAlert>
               </MyCard.Body>
          </MyCard>
     )
}

export default CompteDocuments
