import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import MyCard from "@common-utils/MyCard"
import { useQuery, UseQueryResult } from "react-query"
import { IPersonnelCompteModel } from "@common-models/*"
import { AxiosError } from "axios"
import { getInfoPersoPersonnelCompteRequest } from "../core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import CompteInfoPersoPreview from "./_CompteInfoPersoPreview"
import CompteInfoPersoEdit from "./_CompteInfoPersoEdit"
import { useAuth } from "../../../../AppContext"

const CompteInfoPersoContext = createContext<ICompteInfoPersoContextPropsModel>({} as ICompteInfoPersoContextPropsModel)
export const useCompteInfoPersoContext = () => useContext(CompteInfoPersoContext)

function CompteInfoPerso() {
     const { currentUser } = useAuth()

     // Mode state (preview | edit)
     const [mode, setMode] = useState<"preview" | "edit">(currentUser?.isProfilCompleted ? "preview" : "edit")

     // Get compte info perso
     const infoPersoQuery = useQuery<IPersonnelCompteModel, AxiosError>(REACT_QUERY_KEY_GET_INFO_PERSO, () => getInfoPersoPersonnelCompteRequest().then(r => r.data))

     // Button: switch between preview & edit mode
     const SwitchModeButton = () => (
          <button
               type={"button"}
               className={"btn btn-sm btn-primary"}
               onClick={() => setMode(prev => (prev === "preview" ? "edit" : "preview"))}
               disabled={infoPersoQuery.isFetching || infoPersoQuery.isError}
          >
               {mode === "preview" ? "Passer en mode édition" : "Abandonner le mode édition"}
          </button>
     )

     return (
          <CompteInfoPersoContext.Provider value={{ infoPersoQuery, REACT_QUERY_KEY_GET_INFO_PERSO, setMode }}>
               <MyCard>
                    <MyCard.Header rightSideContent={<SwitchModeButton />}>Mes informations personnelles</MyCard.Header>
                    <MyCard.Body>
                         {infoPersoQuery.isFetching && (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner size={"xl"} />
                              </div>
                         )}

                         {!infoPersoQuery.isFetching && infoPersoQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{infoPersoQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => infoPersoQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}

                         {!infoPersoQuery.isFetching && !infoPersoQuery.isError && infoPersoQuery.data && (
                              <>{mode === "preview" ? <CompteInfoPersoPreview /> : <CompteInfoPersoEdit />}</>
                         )}
                    </MyCard.Body>
               </MyCard>
          </CompteInfoPersoContext.Provider>
     )
}

const REACT_QUERY_KEY_GET_INFO_PERSO = "REACT_QUERY_KEY_GET_INFO_PERSO"

interface ICompteInfoPersoContextPropsModel {
     infoPersoQuery: UseQueryResult<IPersonnelCompteModel, AxiosError>
     REACT_QUERY_KEY_GET_INFO_PERSO: string
     setMode: Dispatch<SetStateAction<"preview" | "edit">>
}

export default CompteInfoPerso
