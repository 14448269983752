import {
     CONST_CLIENT_BON_LIVRAISON_ETAT_ENVOYE_AU_CLIENT,
     CONST_CLIENT_BON_LIVRAISON_ETAT_RECU_AVEC_SIGNATURE_CLIENT,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { IEditBonLivraisonRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { IClientBonLivraisonModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { editBonLivraisonRequest } from "../core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectField from "@common-utils/fields/MySelectField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MyFileField from "@common-utils/fields/MyFileField"
import MyInputField from "@common-utils/fields/MyInputField"

const PtfPeriodeBonCommandeBonLivraisonEditForm = ({ bl, handleSubmitCallback = () => {} }: IPtfPeriodeBonCommandeBonLivraisonEditFormPropsModel) => {
     // Form initial values
     const initialValues: IEditBonLivraisonRequestModel = {
          dateDebut: bl.dateDebut?.format("YYYY-MM-DD"),
          dateFin: bl.dateFin?.format("YYYY-MM-DD"),
          etat: bl.etat,
          montantHT: bl.montant,
          doesDocumentGoingToBeReplaced: false,
     }

     // Formik handle submit
     function handleSubmit(values: IEditBonLivraisonRequestModel, helpers: FormikHelpers<IEditBonLivraisonRequestModel>) {
          helpers.setStatus(null)

          editBonLivraisonRequest(bl.id as number, values)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    handleSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Période */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                        <div className="col-lg-8">
                                             <div className="d-flex">
                                                  <MyDateDayMonthYearField
                                                       name={"dateDebut"}
                                                       value={helpers.values.dateDebut}
                                                       isInvalid={!!helpers.errors.dateDebut}
                                                       maxDate={helpers.values.dateFin}
                                                       onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                       placeholder={"Date de début"}
                                                       classNames={"rounded-0 rounded-start"}
                                                  />
                                                  <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                  <MyDateDayMonthYearField
                                                       name={"dateFin"}
                                                       value={helpers.values.dateFin}
                                                       minDate={helpers.values.dateDebut}
                                                       isInvalid={!!helpers.errors.dateFin}
                                                       onChange={val => helpers.setFieldValue("dateFin", val)}
                                                       placeholder={"Date de fin"}
                                                       classNames={"rounded-0 rounded-end"}
                                                  />
                                             </div>
                                             {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                             {helpers.errors.dateFin && <div className={"text-danger"}>{helpers.errors.dateFin}</div>}
                                        </div>
                                   </div>

                                   {/* Montant HT */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Montant HT</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"montantHT"}
                                                  value={helpers.values.montantHT}
                                                  type={"number"}
                                                  inputMode={"numeric"}
                                                  isInvalid={!!helpers.errors.montantHT}
                                                  onChange={val => helpers.setFieldValue("montantHT", val)}
                                                  placeholder={"Montant HT"}
                                             />
                                             {helpers.errors.montantHT && <div className={"text-danger"}>{helpers.errors.montantHT}</div>}
                                        </div>
                                   </div>

                                   {/* Etat */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">État</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"etat"}
                                                  options={[
                                                       {
                                                            value: CONST_CLIENT_BON_LIVRAISON_ETAT_ENVOYE_AU_CLIENT,
                                                            label: "Envoyé au client",
                                                       },
                                                       {
                                                            value: CONST_CLIENT_BON_LIVRAISON_ETAT_RECU_AVEC_SIGNATURE_CLIENT,
                                                            label: "Signé par le client",
                                                       },
                                                  ]}
                                                  value={helpers.values.etat}
                                                  isInvalid={!!helpers.errors.etat}
                                                  onChange={val => helpers.setFieldValue("etat", val)}
                                             />
                                             {helpers.errors.etat && <div className={"text-danger"}>{helpers.errors.etat}</div>}
                                        </div>
                                   </div>

                                   {/* Changement document */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous remplacer le document?</label>

                                        <div className="col-lg-8 d-flex align-items-center">
                                             <MyCheckBoxField
                                                  name={"doesDocumentGoingToBeReplaced"}
                                                  value={helpers.values.doesDocumentGoingToBeReplaced}
                                                  isInvalid={!!helpers.errors.doesDocumentGoingToBeReplaced}
                                                  onChange={val => helpers.setFieldValue("doesDocumentGoingToBeReplaced", val)}
                                             />
                                             {helpers.errors.doesDocumentGoingToBeReplaced && <div className={"text-danger"}>{helpers.errors.doesDocumentGoingToBeReplaced}</div>}
                                        </div>
                                   </div>

                                   {helpers.values.doesDocumentGoingToBeReplaced && (
                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Document</label>

                                             <div className="col-lg-8">
                                                  <MyFileField name={"document"} isInvalid={!!helpers.errors.document} onChange={val => helpers.setFieldValue("document", val)} />
                                                  {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                             </div>
                                        </div>
                                   )}

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Appliquer les modifications <i className={"fas fa-check-circle fs-4 ms-1"} />
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPtfPeriodeBonCommandeBonLivraisonEditFormPropsModel {
     bl: IClientBonLivraisonModel
     handleSubmitCallback?: (bl: IClientBonLivraisonModel) => void
}

export default PtfPeriodeBonCommandeBonLivraisonEditForm
