import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import MyCard from "@common-utils/MyCard"
import { ICreateCandidatRequestModel, ICreateCandidatResponseModel } from "./core/_models"
import {
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_APPLICATION_PDF,
     CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2,
     CONST_PAYS_ID_FRANCE,
} from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { createCandidatRequest } from "./core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MySelectField from "@common-utils/fields/MySelectField"
import MyFileField from "@common-utils/fields/MyFileField"
import * as Yup from "yup"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyModal from "@common-utils/MyModal"
import CandidatAddSuiviEntretienTelephoniqueForm from "../suivi/_CandidatAddSuiviEntretienTel"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import CandidatAddSuiviMessageRepondeurForm from "../suivi/_CandidatAddSuiviMessageRepondeur"
import CandidatProgrammerEntretienMeetForm from "../actions/_CandidatProgrammerEntretienMeetForm"
import CandidatGenererDocoEtNotifierCandidatParMailForm from "../actions/_CandidatGenererDocoEtNotifierCandidatParMailForm"
import CandidatAddToViviersForm from "../actions/_CandidatAddToViviers"
import CandidatEntamerDemandeEmbauche from "../actions/_CandidatEntamerDemandeEmbauche"
import CandidatDemanderInfoPreEmbaucheParMail from "../actions/_CandidatDemanderInfoPreEmbaucheParMail"
import { Link, useNavigate } from "react-router-dom"
import { CONST_API_UTILS_GET_METIERS_URL_ENDPOINT } from "../../../../endpoints"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import AddMetierForm from "../../../utils/_AddMetierForm"

const FormAddCandidates = () => {
     const context = useCandidatCreateContext()
     const schema = Yup.object().shape({
          civilite: Yup.string().label(`"Civilité"`).required(),
          nom: Yup.string().label(`"Nom"`).required(),
          prenom: Yup.string().label(`"Prénom"`).required(),
          email: Yup.string().email().label(`"Email"`).required(),
          metier: Yup.string().label(`"Métier"`),
          niveauEtudes: Yup.string().label(`"Niveau d'études"`),
          curriculum: Yup.mixed()
               .required()
               .test("fileFormat", "Seuls les formats PDF sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_PDF].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Curriculum"`),
     })

     const [isAddingMetier, setIsAddingMetier] = useState<boolean>(false)

     const initialValues: ICreateCandidatRequestModel = {
          telPaysIndicatif: CONST_PAYS_ID_FRANCE,
     }

     function handleSubmit(values: ICreateCandidatRequestModel, helpers: FormikHelpers<ICreateCandidatRequestModel>) {
          helpers.setStatus(null)

          createCandidatRequest([values])
               .then(r => {
                    context.setCandidats(r.data)
                    context.setStep("two")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Civilité */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>
                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"civilite"}
                                                  options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                  value={helpers.values.civilite}
                                                  isInvalid={!!helpers.errors.civilite}
                                                  onChange={val => helpers.setFieldValue("civilite", val)}
                                             />
                                             {helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}
                                        </div>
                                   </div>

                                   {/* Nom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom</label>
                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"nom"}
                                                  value={helpers.values.nom}
                                                  isInvalid={!!helpers.errors.nom}
                                                  placeholder={"Nom"}
                                                  onChange={value => helpers.setFieldValue("nom", value)}
                                             />
                                             {helpers.errors.nom && <div className="text-danger">{helpers.errors.nom}</div>}
                                        </div>
                                   </div>

                                   {/* Prénom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom</label>
                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"prenom"}
                                                  value={helpers.values.prenom}
                                                  isInvalid={!!helpers.errors.prenom}
                                                  placeholder={"Prénom"}
                                                  onChange={value => helpers.setFieldValue("prenom", value)}
                                             />
                                             {helpers.errors.prenom && <div className="text-danger">{helpers.errors.prenom}</div>}
                                        </div>
                                   </div>

                                   {/* Email */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>
                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"email"}
                                                  value={helpers.values.email}
                                                  isInvalid={!!helpers.errors.email}
                                                  placeholder={"Email"}
                                                  onChange={value => helpers.setFieldValue("email", value)}
                                             />
                                             {helpers.errors.email && <div className="text-danger">{helpers.errors.email}</div>}
                                        </div>
                                   </div>

                                   {/* Curriculum */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Curriculum</label>
                                        <div className="col-lg-8">
                                             <MyFileField
                                                  name={"curriculum"}
                                                  isInvalid={!!helpers.errors.curriculum}
                                                  onChange={value => helpers.setFieldValue("curriculum", value)}
                                             />
                                             {helpers.errors.curriculum && <div className="text-danger">{helpers.errors.curriculum}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Métier</label>
                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"metier"}
                                                  url={CONST_API_UTILS_GET_METIERS_URL_ENDPOINT}
                                                  value={helpers.values.metier}
                                                  isInvalid={!!helpers.errors.metier}
                                                  onChange={val => helpers.setFieldValue("metier", val)}
                                             />
                                             <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                  {helpers.errors.metier && <div className={"text-danger"}>{helpers.errors.metier}</div>}
                                                  <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingMetier(true)}>
                                                       Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Niveau études */}
                                   <div className="row">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Niveau d'études</label>
                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"niveauEtudes"}
                                                  options={CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2}
                                                  value={helpers.values.niveauEtudes}
                                                  isInvalid={!!helpers.errors.niveauEtudes}
                                                  onChange={val => helpers.setFieldValue("niveauEtudes", val)}
                                             />
                                             {helpers.errors.niveauEtudes && <div className={"text-danger"}>{helpers.errors.niveauEtudes}</div>}
                                        </div>
                                   </div>

                                   <div className="separator separator-dashed mt-8 mb-4" />

                                   <div className="d-flex justify-content-end">
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Ajouter</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>

               {/* Modal add métier */}
               {isAddingMetier && (
                    <MyModal title={<>Nouveau métier</>} show={true} handleClose={() => setIsAddingMetier(false)}>
                         <AddMetierForm callback={() => setIsAddingMetier(false)} />
                    </MyModal>
               )}
          </>
     )
}

const ManageCandidate = ({ item }: { item: ICreateCandidatResponseModel }) => {
     const context = useCandidatCreateContext()
     const navigate = useNavigate()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const [ongoingAction, setOngoingAction] = useState<
          "MESSAGE_REPONDEUR" | "DMS" | "VIVIER" | "VISIO" | "DOCO" | "RECRUTER" | "ENTAMER_DEMANDE_EMBAUCHE" | "DEMANDE_INFO_PRE_EMBAUCHE"
     >()
     const [isRecrutementEntame, setIsRecrutementEntame] = useState<boolean>(false)

     return (
          <>
               <div className={"d-flex justify-content-center mb-2"}>
                    <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "80px", width: "80px", borderRadius: "50%" }} size={"sm"}>
                         <div className={"mb-4"}>
                              <img
                                   src={toAbsoluteUrl("/media/svg/avatars/blank.svg")}
                                   onLoad={() => setIsAvatarLoading(false)}
                                   style={{ height: "80px", width: "80px", borderRadius: "50%" }}
                                   alt="Avatar"
                              />
                         </div>
                    </MyLoadingOverlay>
               </div>
               <div className={"d-flex justify-content-center"}>
                    <h5>{item.candidat.prenomNom}</h5>
               </div>
               <div className="separator separator-dashed mb-4" />

               <div className="d-flex justify-content-sm-between flex-column flex-sm-row mb-4">
                    <button
                         type={"button"}
                         className={"btn btn-sm btn-outline btn-outline-primary d-flex align-items-center justify-content-center mb-4 mb-sm-0"}
                         onClick={() => setOngoingAction("MESSAGE_REPONDEUR")}
                    >
                         Message répondeur <i className={"fas fa-phone-slash ms-1"} />
                    </button>
                    <button
                         type={"button"}
                         className={"btn btn-sm btn-success d-flex align-items-center justify-content-center mb-4 mb-sm-0"}
                         onClick={() => setOngoingAction("DMS")}
                    >
                         Ajouter ma DMS <i className={"fas fa-phone ms-1"} />
                    </button>
                    <button
                         type={"button"}
                         className={"btn btn-sm btn-outline btn-outline-danger d-flex align-items-center justify-content-center mb-4 mb-sm-0"}
                         onClick={() => setOngoingAction("VIVIER")}
                    >
                         Ajouter à un vivier <i className={"fas fa-heart ms-1"} />
                    </button>
               </div>

               <div className="d-flex justify-content-sm-between flex-column flex-sm-row mb-4">
                    <button
                         type={"button"}
                         className={"btn btn-sm btn-outline btn-outline-warning d-flex align-items-center justify-content-center mb-4 mb-sm-0"}
                         onClick={() => setOngoingAction("VISIO")}
                    >
                         Programmer une visio <i className={"fas fa-video ms-1"} />
                    </button>

                    <button
                         type={"button"}
                         className={"btn btn-sm btn-dark d-flex align-items-center justify-content-center mb-4 mb-sm-0"}
                         onClick={() => setOngoingAction("DOCO")}
                    >
                         Envoyer un DoCo vierge
                         <i className={"fas fa-paper-plane ms-1"} />
                    </button>

                    {!isRecrutementEntame && (
                         <div className={"d-flex flex-column"}>
                              <button
                                   className="btn btn-sm btn-outline btn-outline-info mb-4 mb-sm-0"
                                   type="button"
                                   data-bs-toggle="dropdown"
                                   data-bs-auto-close="true"
                                   aria-expanded="false"
                              >
                                   Recruter <i className={"fas fa-door-open ms-1"} />
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                   <li>
                                        <h6 className="dropdown-header">Recruter</h6>
                                   </li>
                                   <li onClick={() => setOngoingAction("ENTAMER_DEMANDE_EMBAUCHE")}>
                                        <span className="dropdown-item cursor-pointer">Entamer la demande d'embauche</span>
                                   </li>
                                   <li>
                                        <span className="dropdown-item cursor-pointer" onClick={() => setOngoingAction("DEMANDE_INFO_PRE_EMBAUCHE")}>
                                             Demander les informations de pré-embauche
                                        </span>
                                   </li>
                              </ul>
                         </div>
                    )}
               </div>

               <div className="separator mt-5 mb-4" />

               <div className={"d-flex justify-content-between"}>
                    <Link to={"/recrutement/candidats/search"} className="btn btn-sm btn-primary d-flex align-items-center">
                         Quitter
                    </Link>
                    <div className={"d-flex"}>
                         <Link
                              to={"/recrutement/candidats/search/results"}
                              state={{ candidat_ids: [item.candidat.id], focusFirstItemAndPreviewIt: "" }}
                              className={"btn btn-sm btn-outline btn-outline-primary d-flex align-items-center me-2"}
                         >
                              <span className={"d-none d-sm-block"}>Voir la fiche</span> <i className={"fas fa-user ms-1"} />
                         </Link>
                         <button
                              type={"button"}
                              className={"btn btn-sm btn-outline btn-outline-success d-flex align-items-center"}
                              onClick={() => {
                                   context.setCandidats([])
                                   context.setStep("one")
                              }}
                         >
                              Ajouter un autre <i className={"fas fa-plus-circle ms-1"} />
                         </button>
                    </div>
               </div>

               {ongoingAction === "MESSAGE_REPONDEUR" && (
                    <MyModal title={<span>Message répondeur</span>} show={true} handleClose={() => setOngoingAction(undefined)} fullscreen>
                         <CandidatAddSuiviMessageRepondeurForm
                              candidat={{ id: item.candidat.id as number, prenomNom: item.candidat.prenomNom as string }}
                              curriculumGoogleDriveUrl={FileAndDriveHelper.getUrlOfDriveFilePreview(item.curriculum.googleDriveID as string)}
                              handleSubmitCallBack={() => setOngoingAction(undefined)}
                         />
                    </MyModal>
               )}

               {ongoingAction === "DMS" && (
                    <MyModal title={<>DMS</>} show={true} handleClose={() => setOngoingAction(undefined)} fullscreen>
                         <CandidatAddSuiviEntretienTelephoniqueForm
                              candidat={{ id: item.candidat.id as number, prenomNom: item.candidat.prenomNom as string }}
                              curriculumGoogleDriveUrl={FileAndDriveHelper.getUrlOfDriveFilePreview(item.curriculum.googleDriveID as string)}
                              handleSubmitCallBack={() => setOngoingAction(undefined)}
                         />
                    </MyModal>
               )}

               {ongoingAction === "VISIO" && (
                    <MyModal title={<span>Programmer un entretien</span>} show={true} handleClose={() => setOngoingAction(undefined)} size={"lg"}>
                         <CandidatProgrammerEntretienMeetForm
                              candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                              handleSubmitCallBack={() => setOngoingAction(undefined)}
                         />
                    </MyModal>
               )}

               {ongoingAction === "DOCO" && (
                    <MyModal title={<span>Générer un DoCo vierge et notifier le candidat par mail</span>} show={true} handleClose={() => setOngoingAction(undefined)} size={"lg"}>
                         <CandidatGenererDocoEtNotifierCandidatParMailForm
                              candidat={{
                                   id: item.candidat?.id as number,
                                   prenomNom: item.candidat?.prenomNom as string,
                                   email: item.candidat?.email as string,
                              }}
                              handleSubmitCallBack={() => setOngoingAction(undefined)}
                         />
                    </MyModal>
               )}

               {ongoingAction === "VIVIER" && (
                    <MyModal title={<span>Ajout à un vivier</span>} show={true} handleClose={() => setOngoingAction(undefined)} size={"lg"}>
                         <CandidatAddToViviersForm
                              candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                              handleSubmitCallBack={() => setOngoingAction(undefined)}
                         />
                    </MyModal>
               )}

               <>
                    {ongoingAction === "ENTAMER_DEMANDE_EMBAUCHE" && (
                         <MyModal title={<span>Initialisation de la demande d'embauche</span>} show={true} handleClose={() => setOngoingAction(undefined)} size={"xl"}>
                              <CandidatEntamerDemandeEmbauche
                                   candidat={item.candidat}
                                   callBackOnSubmit={val => {
                                        setOngoingAction(undefined)
                                        navigate(`/recrutement/demandes-embauche/${val.consultant?.id}/details`)
                                   }}
                              />
                         </MyModal>
                    )}

                    {ongoingAction === "DEMANDE_INFO_PRE_EMBAUCHE" && (
                         <MyModal title={<span>Demande des informations de pré-embauche par mail</span>} show={true} handleClose={() => setOngoingAction(undefined)} size={"xl"}>
                              <CandidatDemanderInfoPreEmbaucheParMail
                                   candidat={item!.candidat}
                                   callBackOnSubmit={() => {
                                        setOngoingAction(undefined)
                                        setIsRecrutementEntame(true)
                                   }}
                              />
                         </MyModal>
                    )}
               </>
          </>
     )
}

const CandidatCreateContext = createContext<ICandidatCreateContextPropsModel>({} as ICandidatCreateContextPropsModel)
const useCandidatCreateContext = () => useContext(CandidatCreateContext)

export const CandidatCreate = () => {
     const [step, setStep] = useState<"one" | "two">("one")
     const [candidats, setCandidats] = useState<ICreateCandidatResponseModel[]>([])

     return (
          <>
               <CandidatCreateContext.Provider value={{ setStep, candidats, setCandidats }}>
                    <div className={"d-flex justify-content-center"}>
                         <div className={"w-100 w-md-80 w-lg-75 w-xl-60"}>
                              {step === "one" && (
                                   <MyCard>
                                        <MyCard.Header>
                                             <span className={"fas fa-plus-circle fs-4 text-dark me-2"} /> Ajouter un candidat
                                        </MyCard.Header>
                                        <MyCard.Body>
                                             <FormAddCandidates />
                                        </MyCard.Body>
                                   </MyCard>
                              )}

                              {step === "two" && (
                                   <>
                                        {candidats.map(item => {
                                             return (
                                                  <MyCard key={item.candidat.id}>
                                                       <MyCard.Header>
                                                            <span className={"fas fa-user fs-4 text-dark me-2"} /> {item.candidat.prenomNom}
                                                       </MyCard.Header>
                                                       <MyCard.Body>
                                                            <ManageCandidate item={item} />
                                                       </MyCard.Body>
                                                  </MyCard>
                                             )
                                        })}
                                   </>
                              )}
                         </div>
                    </div>
               </CandidatCreateContext.Provider>
          </>
     )
}

export interface ICandidatCreateContextPropsModel {
     candidats: ICreateCandidatResponseModel[]
     setCandidats: Dispatch<SetStateAction<ICreateCandidatResponseModel[]>>
     setStep: Dispatch<SetStateAction<"one" | "two">>
}

export default CandidatCreate
