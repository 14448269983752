import React from "react"
import { AxiosError } from "axios"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { IHttpErrorResponseModel, IPersonnelArretMaladieModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MIME_APPLICATION_PDF, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG, CONST_MIME_IMAGE_PNG } from "@common-constants/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import moment from "moment"
import MyFileField from "@common-utils/fields/MyFileField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import { IEditPersonnelMaladieRequestModel } from "../core/_models"
import { editPersonnelMaladieRequest } from "../core/_requests"

const PersonnelMaladieEditForm = ({ maladie, duOrAuWithThisMonthAndYear, du, au, callback = () => {} }: IConnectedUserEditMaladieFormPropsModel) => {
     // Schema
     const schema = Yup.object().shape({
          du: Yup.string().label(`"Date de début"`).required().nullable(),
          au: Yup.string().label(`"Date de fin"`).required().nullable(),
          isJustificatifReplaced: Yup.boolean().label(`"Remplacement du justificatif"`).required(),
          justificatif: Yup.mixed()
               .nullable()
               .label(`"Justificatif"`)
               .when("isJustificatifReplaced", {
                    is: true,
                    then: schema => schema.required(),
               })
               .test("fileFormat", "Seuls les formats JPEG, PNG ou PDF sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG, CONST_MIME_APPLICATION_PDF].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               }),
     })

     // Init values
     const initialValues: IEditPersonnelMaladieRequestModel = {
          du: maladie.du?.format("YYYY-MM-DD"),
          au: maladie.au?.format("YYYY-MM-DD"),
          isJustificatifReplaced: false,
     }

     // Handle submit
     function handleSubmit(values: IEditPersonnelMaladieRequestModel, helpers: FormikHelpers<IEditPersonnelMaladieRequestModel>) {
          helpers.setStatus(null)

          editPersonnelMaladieRequest(maladie.id as number, values)
               .then(r => {
                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    console.log(helpers.errors)
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              <div className="row pb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Saisissez la période</label>

                                   <div className="col-lg-8">
                                        <div className="row">
                                             <div className="col">
                                                  <MyDateDayMonthYearField
                                                       onChange={date => helpers.setFieldValue("du", date)}
                                                       minDate={
                                                            moment(duOrAuWithThisMonthAndYear)?.startOf("month").format("DD/MM/YYYY") ||
                                                            moment(du)?.startOf("month").format("DD/MM/YYYY")
                                                       }
                                                       maxDate={
                                                            moment(duOrAuWithThisMonthAndYear)?.endOf("month").format("DD/MM/YYYY") ||
                                                            moment(au)?.endOf("month").format("DD/MM/YYYY")
                                                       }
                                                       value={helpers.values.du}
                                                       name={"du"}
                                                       placeholder={"Du (inclus)"}
                                                       isInvalid={!!helpers.errors.du}
                                                  />
                                                  {helpers.errors.du && <div className={"text-danger"}>{helpers.errors.du}</div>}
                                             </div>
                                             <div className="col">
                                                  <MyDateDayMonthYearField
                                                       onChange={date => helpers.setFieldValue("au", date)}
                                                       value={helpers.values.au}
                                                       minDate={helpers.values.du}
                                                       name={"au"}
                                                       placeholder={"Au (inclus)"}
                                                       isInvalid={!!helpers.errors.au}
                                                  />
                                                  {helpers.errors.au && <div className={"text-danger"}>{helpers.errors.au}</div>}
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="row pb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous remplacer la pièce jointe?</label>

                                   <div className="col-lg-8 d-flex align-items-center">
                                        <MyCheckBoxField
                                             name={"isJustificatifReplaced"}
                                             value={helpers.values.isJustificatifReplaced}
                                             isInvalid={!!helpers.errors.isJustificatifReplaced}
                                             onChange={val => helpers.setFieldValue("isJustificatifReplaced", val)}
                                        />
                                        {helpers.errors.isJustificatifReplaced && <div className={"text-danger"}>{helpers.errors.isJustificatifReplaced}</div>}
                                   </div>
                              </div>
                              {helpers.values.isJustificatifReplaced && (
                                   <div className="row pb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Justificatif</label>

                                        <div className="col-lg-8">
                                             <MyFileField
                                                  name={"justificatif"}
                                                  isInvalid={!!helpers.errors.justificatif}
                                                  onChange={value => helpers.setFieldValue("justificatif", value)}
                                             />
                                             {helpers.errors.justificatif && <div className={"text-danger"}>{helpers.errors.justificatif}</div>}
                                        </div>
                                   </div>
                              )}

                              {/* Submit button */}
                              <div className={"d-flex justify-content-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

interface IConnectedUserEditMaladieFormPropsModel {
     maladie: IPersonnelArretMaladieModel
     callback?: (maladie: IPersonnelArretMaladieModel) => void
     duOrAuWithThisMonthAndYear?: string
     du?: string
     au?: string
}

export default PersonnelMaladieEditForm
