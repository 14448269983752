import axios from "axios"
import { IFournisseurDocumentModel, IFournisseurModel } from "@common-models/*"
import {
     CONST_API_FOURNISSEUR_GET_DOCUMENTS_ENDPOINT,
     CONST_API_FOURNISSEUR_GET_SIMPLE_DETAILS_ENDPOINT,
     CONST_API_FOURNISSEUR_POST_ADD_DOCUMENT_ENDPOINT,
} from "../../../endpoints"

export function getFournisseurSimpleDetails(id: number) {
     return axios.get<IFournisseurModel>(CONST_API_FOURNISSEUR_GET_SIMPLE_DETAILS_ENDPOINT(id))
}

export function getFournisseurDocuments(id: number) {
     return axios.get<IFournisseurDocumentModel[]>(CONST_API_FOURNISSEUR_GET_DOCUMENTS_ENDPOINT(id))
}

export function addFournisseurDocument(id: number, type: string, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)
     formData.append("type", type)

     return axios.post<IFournisseurDocumentModel>(CONST_API_FOURNISSEUR_POST_ADD_DOCUMENT_ENDPOINT(id), formData)
}
