import React, { useState } from "react"
import { IPersonnelDdcModel } from "@common-models/*"
import MyCard from "@common-utils/MyCard"
import { toast } from "react-toastify"
import PersonnelDdcListingForOne, { REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING } from "../../../declaration/ddc/listing-one-personnel/PersonnelDdcListingForOne"
import MyModal from "@common-utils/MyModal"
import ConnectedPersonnelCreateDdcForm from "../create/_ConnectedPersonnelCreateDdcForm"
import { useQueryClient } from "react-query"
import { useAuth } from "../../../../../AppContext"

const ConnectedUserDdcListing = () => {
     const queryClient = useQueryClient()
     const { currentUser } = useAuth()
     const [createInModal, setCreateInModal] = useState<boolean>(false)

     function handleCreateDdcCallback(newItem: IPersonnelDdcModel) {
          queryClient.setQueryData<IPersonnelDdcModel[] | undefined>(REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING, (prev: IPersonnelDdcModel[] | undefined) => {
               if (prev) {
                    return [newItem, ...prev]
               }

               return prev
          })
          setCreateInModal(false)
          toast.success("Votre demande de congés a bien été envoyée.")
     }

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={"w-100 w-lg-75 w-xxl-65"}>
                         <MyCard>
                              <MyCard.Header
                                   rightSideContent={
                                        <>
                                             <button type={"button"} className={`btn btn-primary btn-sm d-flex align-items-center`} onClick={() => setCreateInModal(true)}>
                                                  <span className={`d-md-inline d-none`}>Nouvelle demande</span>
                                                  <i className={"fas fa-plus-circle ms-md-2 pe-0 fs-3"} />
                                             </button>
                                        </>
                                   }
                              >
                                   Mes demandes de congés
                              </MyCard.Header>
                              <MyCard.Body>
                                   <PersonnelDdcListingForOne personnel_ID={currentUser?.id as number} />
                              </MyCard.Body>
                         </MyCard>
                    </div>
               </div>

               {createInModal && (
                    <MyModal show={true} handleClose={() => setCreateInModal(false)} title={<>Nouvelle demande</>} size={"lg"}>
                         <ConnectedPersonnelCreateDdcForm callback={handleCreateDdcCallback} />
                    </MyModal>
               )}
          </>
     )
}

export default ConnectedUserDdcListing
