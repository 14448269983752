import axios from "axios"
import { CONST_API_RECRUITMENT_CANDIDATES_POST_CREATE } from "../../../../../endpoints"
import { ICreateCandidatRequestModel, ICreateCandidatResponseModel } from "./_models"

export function createCandidatRequest(payload: ICreateCandidatRequestModel[]) {
     const formData = new FormData()
     payload.map((item, index) => {
          if (item.civilite) formData.append(`data[${index}][civilite]`, item.civilite)
          if (item.nom) formData.append(`data[${index}][nom]`, item.nom)
          if (item.prenom) formData.append(`data[${index}][prenom]`, item.prenom)
          if (item.email) formData.append(`data[${index}][email]`, item.email)
          if (item.niveauEtudes) formData.append(`data[${index}][niveauEtudes]`, item.niveauEtudes)
          if (item.metier) formData.append(`data[${index}][metier]`, item.metier)
          if (item.curriculum) formData.append(`fichiers[${index}]`, item.curriculum)
     })
     return axios.post<ICreateCandidatResponseModel[]>(CONST_API_RECRUITMENT_CANDIDATES_POST_CREATE, formData)
}
