import axios from "axios"
import {
     CONST_API_PERSONNEL_DECLARATION_GET_NDF_BY_ID_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_GET_NDF_DEPENSES_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_ADD_DEPENSE_GROUPED_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_ADD_DEPENSE_SIMPLE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_DELETE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_ADD_JUSTIFICATIF_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_DELETE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_EDIT_DEPENSE_GROUPED_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_EDIT_DEPENSE_SIMPLE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF_DELETE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF_REPLACE_ENDPOINT,
} from "../../../../../endpoints"
import { IPersonnelNdfDetailModel, IPersonnelNdfDocumentModel, IPersonnelNdfModel } from "@common-models/*"
import {
     ICreateConnectedUserNdfDepenseGroupedRequestModel,
     ICreateConnectedUserNdfDepenseSimpleRequestModel,
     IEditConnectedUserNdfDepenseGroupedRequestModel,
     IEditConnectedUserNdfDepenseSimpleRequestModel,
} from "../../../mes-activites/ndf/core/_models"

export function deletePersonnelNdfRequest(id: number) {
     return axios.delete(CONST_API_PERSONNEL_DECLARATION_NDF_DELETE_ENDPOINT(id), {})
}

export function getPersonnelNdfByIdRequest(id: number) {
     return axios.get<IPersonnelNdfModel | null>(CONST_API_PERSONNEL_DECLARATION_GET_NDF_BY_ID_ENDPOINT(id))
}

export function getPersonnelNdfDepensesRequest(id: number) {
     return axios.get<IPersonnelNdfDetailModel[]>(CONST_API_PERSONNEL_DECLARATION_GET_NDF_DEPENSES_ENDPOINT(id))
}

export function createPersonnelNdfDepenseGroupedRequest(ndf_id: number, childs: ICreateConnectedUserNdfDepenseGroupedRequestModel[], fichiers: File[]) {
     const formData = new FormData()
     childs.map((item, index) => {
          if (item.numeroTicket) formData.append(`childs[${index}][numeroTicket]`, item.numeroTicket.toString())
          if (item.montantTTC) formData.append(`childs[${index}][montantTTC]`, item.montantTTC.toString())
          if (item.categorie) formData.append(`childs[${index}][categorie]`, item.categorie)
          if (item.decription) formData.append(`childs[${index}][description]`, item.decription)
          if (item.date) formData.append(`childs[${index}][date]`, item.date)
          if (item.repasSeulOuEnGroupe) formData.append(`childs[${index}][repasSeulOuEnGroupe]`, item.repasSeulOuEnGroupe)
          if (item.invites) item.invites.map((item_, index_) => formData.append(`childs[${index}][invites][${index_}]`, item_))
     })
     fichiers.map((item, index) => formData.append(`fichiers[${index}]`, item))

     return axios.post<IPersonnelNdfDetailModel>(CONST_API_PERSONNEL_DECLARATION_NDF_ADD_DEPENSE_GROUPED_ENDPOINT(ndf_id), formData)
}

export function createPersonnelNdfDepenseSimpleRequest(id: number, payload: ICreateConnectedUserNdfDepenseSimpleRequestModel) {
     const formData = new FormData()
     if (payload.date) formData.append(`data[date]`, payload.date)
     if (payload.categorie) formData.append(`data[categorie]`, payload.categorie)
     if (payload.description) formData.append(`data[description]`, payload.description)
     if (payload.montantTTC) formData.append(`data[montantTTC]`, payload.montantTTC.toString())
     if (payload.documents) payload.documents.map((item, index) => formData.append(`documents[${index}]`, item))
     if (payload.repasSeulOuEnGroupe) formData.append(`data[repasSeulOuEnGroupe]`, payload.repasSeulOuEnGroupe)
     if (payload.invites) payload.invites.map((item, index) => formData.append(`data[invites][${index}]`, item))

     return axios.post<IPersonnelNdfDetailModel>(CONST_API_PERSONNEL_DECLARATION_NDF_ADD_DEPENSE_SIMPLE_ENDPOINT(id), formData)
}

export function deletePersonnelNdfDepenseRequest(id: number) {
     return axios.delete(CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_DELETE_ENDPOINT(id))
}

export function editPersonnelDepenseGroupedRequest(id: number, payload: IEditConnectedUserNdfDepenseGroupedRequestModel) {
     payload = {
          ...payload,
          depenses: payload.depenses.map(item => ({
               ...item,
               date: item.date,
          })),
     }

     return axios.post<IPersonnelNdfDetailModel>(CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_EDIT_DEPENSE_GROUPED_ENDPOINT(id), payload)
}

export function editPersonnelNdfDepenseSimpleRequest(id: number, payload: IEditConnectedUserNdfDepenseSimpleRequestModel) {
     return axios.post<IPersonnelNdfDetailModel>(CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_EDIT_DEPENSE_SIMPLE_ENDPOINT(id), payload)
}

export function addPersonnelNdfJustificatifRequest(ndf_detail_id: number, justificatif: File) {
     const formData = new FormData()
     formData.append("justificatif", justificatif)
     return axios.post<IPersonnelNdfDocumentModel>(CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_ADD_JUSTIFICATIF_ENDPOINT(ndf_detail_id), formData)
}

export function deletePersonnelNdfJustificatifRequest(id: number) {
     return axios.delete(CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF_DELETE_ENDPOINT(id))
}

export function replacePersonnelNdfJustificatifRequest(id: number, justificatif: File) {
     const formData = new FormData()
     formData.append("justificatif", justificatif)
     return axios.post<IPersonnelNdfDocumentModel>(CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF_REPLACE_ENDPOINT(id), formData)
}
