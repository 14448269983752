import { PageTitle } from "@common-metronic/layout/core"
import { Navigate, Route, Routes } from "react-router-dom"
import GestionStructureDeclarationsVoyants from "../structure/declarations/voyants/GestionStructureDeclarationsVoyants"
import ConsultantsDeclarationsListing from "../../business/consultants/declaration/listing/ConsultantsDeclarationsListing"
import GestionProductionDeclarationsVoyants from "../production/GestionProductionDeclarationsVoyants"
import {
     ROUTING_BREADCRUMBS_GESTION_PRODUCTION,
     ROUTING_BREADCRUMBS_GESTION_PRODUCTION_DECLARATIONS_VOYANTS,
     ROUTING_BREADCRUMBS_GESTION_STRUCTURE,
     ROUTING_BREADCRUMBS_GESTION_STRUCTURE_DECLARATIONS_VOYANTS,
     ROUTING_BREADCRUMBS_GESTION_STRUCTURE_ORGANIGRAMME,
     ROUTING_BREADCRUMBS_GESTION_STRUCTURE_STAFF,
} from "./routing-breadcrumbs"
import {
     ROUTING_RELATIVE_PATH_GESTION_PRODUCTION,
     ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DDC_LISTING,
     ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING,
     ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS,
     ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_MALADIES_LISTING,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DDC_LISTING,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_MALADIES_LISTING,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_ORGANIGRAMME,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_STAFF,
     ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_STAFF_DETAILS,
} from "./routing-endpoints"
import GestionStructureDeclarationsListing from "../structure/declarations/listing/GestionStructureDeclarationsListing"
import ConsultantDdcListing from "../../business/consultants/ddc/listing/ConsultantDdcListing"
import PersonnelDdcListingMultiplePersonnel from "../../personnel/declaration/ddc/listing-multiple-personnel/PersonnelDdcListingMultiplePersonnel"
import PersonnelMaladieListingMultiple from "../../personnel/declaration/maladie/listing/listing-multiple/PersonnelMaladieListingMultiple"
import ConsultantsMaladiesListingMultiple from "../../business/consultants/maladie/listing-multiple/ConsultantsMaladiesListingMultiple"
import PersonnelListing from "../../personnel/listing/PersonnelListing"
import PersonnelDetails from "../../personnel/details/PersonnelDetails"
import { PersonnelsOrganigramme } from "../../personnel/details/organigramme/PersonnelsOrganigramme"

const GestionRouting = () => {
     const urlParams = new URLSearchParams(window.location.search)

     return (
          <Routes>
               {/* PRODUCTION */}
               <Route path={ROUTING_RELATIVE_PATH_GESTION_PRODUCTION}>
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_PRODUCTION}>Déclarations ( voyants )</PageTitle>
                                   <GestionProductionDeclarationsVoyants />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_PRODUCTION_DECLARATIONS_VOYANTS}>Liste</PageTitle>
                                   <ConsultantsDeclarationsListing />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DDC_LISTING}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_PRODUCTION}>DDC</PageTitle>
                                   <ConsultantDdcListing title={"Liste des DDC"} isFilterIconVisible={true} />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_MALADIES_LISTING}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_PRODUCTION}>Arrêts maladie</PageTitle>
                                   <ConsultantsMaladiesListingMultiple title={"Liste des arrêts maladie"} isFilterIconVisible={true} />
                              </>
                         }
                    />
               </Route>

               {/* STRUCTURE */}
               <Route path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE}>
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_STRUCTURE}>Déclarations ( voyants )</PageTitle>
                                   <GestionStructureDeclarationsVoyants />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_STAFF}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_STRUCTURE}>Staff</PageTitle>
                                   <PersonnelListing />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_ORGANIGRAMME}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_STRUCTURE_ORGANIGRAMME}>Organigramme</PageTitle>
                                   <PersonnelsOrganigramme />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_STAFF_DETAILS}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_STRUCTURE_STAFF}>{urlParams.get("nom")}</PageTitle>
                                   <PersonnelDetails />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_STRUCTURE_DECLARATIONS_VOYANTS}>Liste</PageTitle>
                                   <GestionStructureDeclarationsListing />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DDC_LISTING}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_STRUCTURE}>DDC</PageTitle>
                                   <PersonnelDdcListingMultiplePersonnel title={"Liste des DDC"} isSearchBarVisible={true} />
                              </>
                         }
                    />
                    <Route
                         path={ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_MALADIES_LISTING}
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_GESTION_STRUCTURE}>Arrêts maladie</PageTitle>
                                   <PersonnelMaladieListingMultiple title={"Liste des arrêts maladie"} isFilterIconVisible={true} />
                              </>
                         }
                    />
               </Route>

               <Route index element={<Navigate to="profil" />} />
          </Routes>
     )
}

export default GestionRouting
