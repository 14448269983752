// This function facilitates the navigation between items. It helps know where is the previous item and the next item.
// Example n°1: if the second item of the page is selected, the next item will be the second in the list and the previous one will be the first one of the list
// Example n°2: if the last item of the page is selected, the next item will be the first one of the next page (if there is a next page)
// Example n°3: if the first item of the second page is selected, the previous item will be the last item of the previous page
import { IGetPropositionsDataItemResponseModel, IItemInPreviewModel } from "./_models"

export function getSelectedItemPlusPreviousAndNextItem(
     selectedItem: IGetPropositionsDataItemResponseModel,
     selectedItemIndex: number,
     pageItems: IGetPropositionsDataItemResponseModel[],
     selectedItemPageNumber: number,
     totalPages: number
): IItemInPreviewModel {
     // If item other than the last and the first one is selected   XoooooooX
     if (selectedItemIndex > 0 && selectedItemIndex < pageItems.length - 1) {
          return {
               previousItem: pageItems[selectedItemIndex - 1],
               selectedItem,
               selectedItemIndex,
               selectedItemPageNumber,
               isSelectedItemLoading: true,
               nextItem: pageItems[selectedItemIndex + 1],
               isNextItemInTheNextPage: false,
               isPreviousItemInThePreviousPage: false,
               isPreviewVisible: true,
          }
     } else {
          // The first or the last item is selected <- OxxxxxxxO ->
          // -- CASE: there is only one item in the list
          if (pageItems.length === 1) {
               // Check if there is next or previous page
               return {
                    previousItem: null,
                    selectedItem,
                    selectedItemIndex,
                    selectedItemPageNumber,
                    isSelectedItemLoading: true,
                    nextItem: null,
                    // This will help user navigates to the next page but only if he is not in the last page
                    isNextItemInTheNextPage: selectedItemPageNumber < totalPages,
                    // This will help user navigates to the previous page but only if he is not in the first page (Example: he is in the second page and there are multiple pages)
                    isPreviousItemInThePreviousPage: selectedItemPageNumber > 1 && totalPages > 1,
                    isPreviewVisible: true,
               }
          } else {
               // Case: there are multiple items in the list
               return {
                    // previousItem exists only if the selected item is not the first one
                    previousItem: selectedItemIndex > 0 ? pageItems[selectedItemIndex - 1] : null,
                    selectedItem,
                    selectedItemIndex,
                    selectedItemPageNumber,
                    isSelectedItemLoading: true,
                    // nextItem exists only if the selected item is not the last one
                    nextItem: selectedItemIndex < pageItems.length - 1 ? pageItems[selectedItemIndex + 1] : null,
                    // This will help user navigates to the next page but only if the last item is selected and there are more pages left
                    isNextItemInTheNextPage: selectedItemIndex === pageItems.length - 1 && selectedItemPageNumber < totalPages,
                    // This will help user navigates to the previous page but only if the first item is selected and if he is not in the first page
                    isPreviousItemInThePreviousPage: selectedItemIndex === 0 && selectedItemPageNumber > 1 && totalPages > 1,
                    isPreviewVisible: true,
               }
          }
     }
}
