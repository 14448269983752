import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IClientResponsableModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_PAYS_ID_FRANCE } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyInputField from "@common-utils/fields/MyInputField"
import * as Yup from "yup"
import { addAdresseClientRequest } from "./core/_requests"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_CLIENTS_ENDPOINT, CONST_API_UTILS_GET_PAYS_ENDPOINT, CONST_API_UTILS_GET_VILLES_URL_ENDPOINT } from "../../endpoints"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import { IAddAdresseClientRequestModel } from "./core/_models"

const AddAdresseClientForm = ({ callback = () => {} }: IAddAdresseClientFormPropsModel) => {
     // Schema
     const schema = Yup.object().shape({
          client: Yup.number().required().nullable().label(`"Client"`),
          adresse: Yup.string().required().nullable().label(`"Adresse postale"`),
          ville: Yup.string()
               .nullable()
               .label(`"Ville"`)
               .when("pays", {
                    is: val => val && val !== CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          villeFrance: Yup.number()
               .nullable()
               .label(`"Ville"`)
               .when("pays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          codePostal: Yup.number().required().label(`"Code postal"`),
          pays: Yup.number().required().nullable().label(`"Pays"`),
     })

     // Init values
     const initialValues: IAddAdresseClientRequestModel = {
          pays: CONST_PAYS_ID_FRANCE,
     }

     // Handle submit
     function handleSubmit(values: IAddAdresseClientRequestModel, helpers: FormikHelpers<IAddAdresseClientRequestModel>) {
          helpers.setStatus(null)

          addAdresseClientRequest(values)
               .then(r => {
                    toast.success("Le responsable a bien été ajouté")

                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Client */}
                                   <div className="row">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Client</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"client"}
                                                  url={CONST_API_UTILS_GET_CLIENTS_ENDPOINT}
                                                  value={helpers.values.client}
                                                  onChange={val => helpers.setFieldValue("client", val)}
                                                  isInvalid={!!helpers.errors.client}
                                             />
                                             <div>{helpers.errors.client && <div className={"text-danger"}>{helpers.errors.client}</div>}</div>
                                        </div>
                                   </div>

                                   <div className="separator my-5" />

                                   {/* Pays*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Pays</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"pays"}
                                                  value={helpers.values.pays}
                                                  url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                  defaultOptions={[{ value: CONST_PAYS_ID_FRANCE, label: "+33 - France" }]}
                                                  isInvalid={!!helpers.errors.pays}
                                                  onChange={val => helpers.setFieldValue("pays", val)}
                                             />
                                             {helpers.errors.pays && <div className={"text-danger"}>{helpers.errors.pays}</div>}
                                        </div>
                                   </div>

                                   {/*Ville et code postal*/}
                                   {helpers.values.pays && (
                                        <>
                                             {/* Adresse */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Adresse</label>

                                                  <div className="col-lg-8">
                                                       <MyInputField
                                                            name={"adresse"}
                                                            value={helpers.values.adresse}
                                                            onChange={val => helpers.setFieldValue("adresse", val)}
                                                            placeholder={"Adresse"}
                                                            isInvalid={!!helpers.errors.adresse}
                                                       />
                                                       <div>{helpers.errors.adresse && <div className={"text-danger"}>{helpers.errors.adresse}</div>}</div>
                                                  </div>
                                             </div>
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Ville et code postal</label>

                                                  <div className="col-lg-8">
                                                       <div className="row">
                                                            <div className="col-6">
                                                                 {helpers.values.pays === CONST_PAYS_ID_FRANCE ? (
                                                                      <>
                                                                           <MySelectSearchDataFromServer
                                                                                name={"villeFrance"}
                                                                                value={helpers.values.villeFrance}
                                                                                url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                                method={"GET"}
                                                                                minimumLengthSearch={2}
                                                                                placeholder={"Choisissez la ville"}
                                                                                isInvalid={!!helpers.errors.villeFrance}
                                                                                onChange={val => helpers.setFieldValue("villeFrance", val)}
                                                                           />
                                                                           {helpers.errors.villeFrance && <div className={"text-danger"}>{helpers.errors.villeFrance}</div>}
                                                                      </>
                                                                 ) : (
                                                                      <>
                                                                           <MyInputField
                                                                                name={"ville"}
                                                                                value={helpers.values.ville}
                                                                                isInvalid={!!helpers.errors.ville}
                                                                                placeholder={"Ville"}
                                                                                onChange={val => helpers.setFieldValue("ville", val)}
                                                                           />
                                                                           {helpers.errors.ville && <div className={"text-danger"}>{helpers.errors.ville}</div>}
                                                                      </>
                                                                 )}
                                                            </div>
                                                            <div className="col-6">
                                                                 <MyInputField
                                                                      name={"codePostal"}
                                                                      value={helpers.values.codePostal}
                                                                      isInvalid={!!helpers.errors.codePostal}
                                                                      placeholder={"Code postal"}
                                                                      inputMode={"numeric"}
                                                                      onChange={val => helpers.setFieldValue("codePostal", val)}
                                                                 />
                                                                 {helpers.errors.codePostal && <div className={"text-danger"}>{helpers.errors.codePostal}</div>}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </>
                                   )}

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Ajouter</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IAddAdresseClientFormPropsModel {
     callback?: (val: IClientResponsableModel) => void
}

export default AddAdresseClientForm
