import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { IGetPropositionsRequestModel, IGetPropositionsResponseModel, IItemInPreviewModel, IPtfListingContextPropsModel } from "./core/_models"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { IClientPropalModel, IHttpErrorResponseModel, IPersonnelModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import { getPropositionsListing } from "./core/_requests"
import PtfListingFilters from "./_PtfListingFilters"
import PtfListingSearchBar from "./_PtfListingSearchBar"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../models"
import PtfListingList from "./_PtfListingList"
import PtfListingPreview from "./_PtfListingPreview"
import MyModal from "@common-utils/MyModal"
import PtfsChangementResponsableForm from "../actions/_PtfsChangementResponsableForm"

// View display Lg or less
const ViewLgOrLess = () => {
     const context = usePtfListingContext()

     return (
          <>
               <div className={`${context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <PtfListingSearchBar />
               </div>

               {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                    <div className={"mb-2"}>
                         <PtfListingPreview />
                    </div>
               )}

               <div className={`${!context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <PtfListingFilters />
               </div>

               <PtfListingList />
          </>
     )
}

// View display Xl or higher
const ViewXlOrHigher = () => {
     const context = usePtfListingContext()
     return (
          <>
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-5 ${!context.areFiltersVisible ? "d-none" : ""}`}>
                         <PtfListingFilters />
                    </div>
                    {/*
                     * List & preview will share the remaining width
                     * Preview will not be visible at the beginning
                     */}
                    <div className={`col ${context.itemInPreview && context.itemInPreview.isPreviewVisible ? "" : "col-7"}`}>
                         <div className={"row"}>
                              <div className={context.itemInPreview && context.itemInPreview.isPreviewVisible ? "col-5" : ""}>
                                   {/* Simple bar search will be shown only if filters are not displayed */}
                                   <div className={`mb-2 ${context.areFiltersVisible ? "d-none" : ""}`}>
                                        <PtfListingSearchBar />
                                   </div>

                                   <PtfListingList />
                              </div>
                              {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                                   <div className="col-7">
                                        <PtfListingPreview />
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          </>
     )
}

const REACT_QUERY_KEY_LISTING = "REACT_QUERY_KEY_LISTING"
const PtfListingContext = createContext<IPtfListingContextPropsModel>({} as IPtfListingContextPropsModel)
export const usePtfListingContext = () => useContext(PtfListingContext)
const PtfListing = () => {
     const queryClient = useQueryClient()

     // Referencing filters form, simple search bar form & preview container
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)
     const previewContainerRef = useRef<any>(null)

     const [filters, setFilters] = useState<IGetPropositionsRequestModel>({
          page: 1,
          pageLength: 20,
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [itemInPreview, setItemInPreview] = useState<IItemInPreviewModel | null>(null)
     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })
     const [changementResponsableOfOneOrMultiplePropal, setChangementResponsableOfOneOrMultiplePropal] = useState<IClientPropalModel[]>()

     // Main listing query
     const listingQuery = useQuery<IGetPropositionsResponseModel, AxiosError>(REACT_QUERY_KEY_LISTING, async () => {
          return getPropositionsListing(filters)
               .then(r => {
                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters form
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     useEffect(() => {
          listingQuery.refetch().then()
     }, [filters])

     function handleSubmitChangementResponsableCallBack(responsable: IPersonnelModel) {
          // Update item
          queryClient.setQueryData<IGetPropositionsResponseModel | undefined>(REACT_QUERY_KEY_LISTING, (prev: IGetPropositionsResponseModel | undefined) => {
               if (prev?.data && prev.data.length > 0) {
                    changementResponsableOfOneOrMultiplePropal?.map(item => {
                         const index = prev?.data.findIndex(item_ => item_.proposition.id === item.id)
                         if (index >= 0) {
                              prev.data[index] = {
                                   ...prev.data[index],
                                   proposition: {
                                        ...prev.data[index].proposition,
                                        personnel: { ...prev.data[index].proposition.personnel, ...responsable },
                                   },
                              }
                              return prev
                         }
                    })
               }

               return prev
          })
          setChangementResponsableOfOneOrMultiplePropal(undefined)
     }

     return (
          <>
               <PtfListingContext.Provider
                    value={{
                         areFiltersVisible,
                         setAreFiltersVisible,
                         filters,
                         setFilters,
                         itemInPreview,
                         setItemInPreview,
                         REACT_QUERY_KEY_LISTING,
                         listingQuery,
                         filtersFormikRef,
                         previewContainerRef,
                         simpleSearchBarFormikRef,
                         isBulkSelectionEnabled,
                         setIsBulkSelectionEnabled,
                         setChangementResponsableOfOneOrMultiplePropal,
                    }}
               >
                    {/* Adapt view to display */}
                    {window.innerWidth >= 1200 ? <ViewXlOrHigher /> : <ViewLgOrLess />}
               </PtfListingContext.Provider>

               {changementResponsableOfOneOrMultiplePropal && (
                    <MyModal title={<span>Changement de responsable</span>} show={true} handleClose={() => setChangementResponsableOfOneOrMultiplePropal(undefined)} size={"lg"}>
                         <PtfsChangementResponsableForm ptfs={changementResponsableOfOneOrMultiplePropal} onSubmitCallBack={handleSubmitChangementResponsableCallBack} />
                    </MyModal>
               )}
          </>
     )
}

export default PtfListing
