import React, { useMemo } from "react"
import MyCard from "@common-utils/MyCard"
import MyTooltip from "@common-utils/MyTooltip"
import { useSearchResultsContext } from "./CandidatsRechercheResults"
import FormRechercheCandidat from "../FormRechercheCandidat"
import { ISearchCandidatesFiltersModel } from "../core/_models"

// Filters
const CandidatsRechercheResultsFilters = () => {
     const context = useSearchResultsContext()

     function handleReset() {
          context.setItemInPreview(null)

          context.filtersFormikRef.current.resetForm() // Reset local form
          context.simpleSearchBarFormikRef.current.resetForm() // Reset search bar form
          context.setFilters(() => ({ ...context.INITIAL_FILTERS_EMPTY_STATE })) // Reset payload filters

          context.filtersFormikRef.current.setSubmitting(true)
     }

     function onSearch(values: ISearchCandidatesFiltersModel) {
          context.setItemInPreview(null)
          context.setFilters(prev => ({
               page: context.INITIAL_FILTERS_EMPTY_STATE.page,
               pageLength: context.INITIAL_FILTERS_EMPTY_STATE.pageLength,
               filters: { ...context.INITIAL_FILTERS_EMPTY_STATE.filters, ...values },
          }))
     }

     function onChangeKeywordsField(val: string[]) {
          context.simpleSearchBarFormikRef.current.setFieldValue("searchKeywords", val).then()
     }

     function handleClickHideFilters() {
          // Hide filters
          context.setAreFiltersVisible(prev => !prev)
          // Show preview only if it was opened before showing filters
          context.setItemInPreview(prev => {
               if (prev) {
                    return { ...prev, isPreviewVisible: true }
               }
               return null
          })
     }

     // /!\ DO NOT SIMPLIFY THIS MECHANIC!
     // This mechanic helps prefill the form with default values (context.initialFiltersForForm set the state in parent component that's why we need memo + useMemo, otherwise it will not update state)
     // the property enableReinitialize seems not to work in Formik (for some cases like viviers, annonces (MySelectSearchDataFromServer)) contrary to the Formik in the simple bar
     const MemorizedFormRechercheCandidat = React.memo(({ pInitialValues }: { pInitialValues }) => {
          return (
               <FormRechercheCandidat
                    formRef={context.filtersFormikRef}
                    handleSubmitCallback={onSearch}
                    handleChangeKeywordsField={onChangeKeywordsField}
                    callBackOnReset={handleReset}
                    pInialValues={pInitialValues}
               />
          )
     })
     const memorizedFormRechercheCandidat = useMemo(() => <MemorizedFormRechercheCandidat pInitialValues={context.initialFiltersForForm} />, [context.initialFiltersForForm])

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <MyTooltip title={"Cacher les filters"}>
                              <button className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={() => handleClickHideFilters()}>
                                   <i className={"fas fa-eye-slash fs-4 text-dark"} />
                              </button>
                         </MyTooltip>
                    }
               >
                    Filtres
               </MyCard.Header>

               <MyCard.Body>{memorizedFormRechercheCandidat}</MyCard.Body>
          </MyCard>
     )
}

export default CandidatsRechercheResultsFilters
