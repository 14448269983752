import { IGetDetailsConsultant } from "../core/_models"
import MyCard from "@common-utils/MyCard"
import ConsultantDetailsInfoBaseInfoContract from "./_ConsultantDetails_InfoBase_InfoContract"
import ConsultantDetailsInfoBaseMission from "./_ConsultantDetails_InfoBase_mission"
import ConsultantDetailsInfoBaseFormInfoPersoSalarie from "./formInfoPerso/_ConsultantDetails_InfoBase_formInfoPersoSalarie"
import ConsultantDetailsInfoBaseFormInfoPersoNonSalarie from "./formInfoPerso/_ConsultantDetails_InfoBase_formInfoPersoNonSalarie"
import MyAlert from "@common-utils/MyAlert"
import moment from "moment"

const ConsultantDetailsInfoBase = ({ consultantInfoPerso }: IConsultantDetailsInfoBasePropsModel) => {
     const dateFinActivePE = consultantInfoPerso.activePeriodeEssai?.dateFin2 || consultantInfoPerso.activePeriodeEssai?.dateFin1

     return (
          <>
               <div className="p-4">
                    {/* Alertes */}
                    <div className={"mb-4"}>
                         {/* Alert embauche sur profil */}
                         {consultantInfoPerso.consultant.etat === "ETAT_EMBAUCHE_SANS_MISSION" && (
                              <MyAlert type={"warning"} classNames={"mb-4"}>
                                   Ce collaborateur n'a pas de mission car il a été embauché sur profil.
                              </MyAlert>
                         )}

                         {/* Alert CDD */}
                         {consultantInfoPerso.consultant.etat !== "ETAT_HORS_EFFECTIF" && consultantInfoPerso.consultant.contrat === "CONTRAT_CDD" && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <div>Ce collaborateur est en CDD et quittera les effectifs le {consultantInfoPerso.consultant.dateSortie?.format("dddd D MMMM YYYY")}</div>
                              </MyAlert>
                         )}

                         {/* Alert PE */}
                         {consultantInfoPerso.consultant.etat !== "ETAT_HORS_EFFECTIF" && dateFinActivePE && (
                              <MyAlert type={moment().isBefore(dateFinActivePE) ? "warning" : "danger"}>
                                   <div>
                                        {moment().isBefore(dateFinActivePE) ? (
                                             <span>Une période d'essai est en cours et expirera le {dateFinActivePE.format("dddd D MMMM YYYY")}</span>
                                        ) : (
                                             <span>Une période d'essai a expiré le {dateFinActivePE.format("dddd D MMMM YYYY")}</span>
                                        )}
                                   </div>
                              </MyAlert>
                         )}
                    </div>

                    {/* Info contract */}
                    <MyCard classNames={"mb-4"}>
                         <MyCard.Header isCollapseIconShown={true}>
                              <div className={"d-flex align-items-center"}>
                                   <span className={"fas fa-file-signature me-2"} /> Informations contractuelles
                              </div>
                         </MyCard.Header>
                         <MyCard.Body>
                              <ConsultantDetailsInfoBaseInfoContract consultantInfoPerso={consultantInfoPerso} />
                         </MyCard.Body>
                    </MyCard>

                    {/* Info mission */}
                    {consultantInfoPerso.consultant.etat !== "ETAT_EMBAUCHE_SANS_MISSION" && (
                         <MyCard defaultIsCollapsed={true} classNames={"mb-4"}>
                              <MyCard.Header isCollapseIconShown={true}>
                                   <div className={"d-flex align-items-center"}>
                                        <span className={"fas fa-building me-3"} /> Mission
                                   </div>
                              </MyCard.Header>
                              <MyCard.Body>
                                   <ConsultantDetailsInfoBaseMission consultantInfoPerso={consultantInfoPerso} />
                              </MyCard.Body>
                         </MyCard>
                    )}

                    {/* Info perso */}
                    <MyCard defaultIsCollapsed={true}>
                         <MyCard.Header isCollapseIconShown={true}>
                              <div className={"d-flex align-items-center"}>
                                   <span className={"fas fa-id-card me-2"} /> Informations personnelles
                              </div>
                         </MyCard.Header>
                         <MyCard.Body>
                              {consultantInfoPerso.consultant.contrat !== "CONTRAT_ST" ? (
                                   <ConsultantDetailsInfoBaseFormInfoPersoSalarie consultantInfoPerso={consultantInfoPerso} />
                              ) : (
                                   <ConsultantDetailsInfoBaseFormInfoPersoNonSalarie consultantInfoPerso={consultantInfoPerso} />
                              )}
                         </MyCard.Body>
                    </MyCard>
               </div>
          </>
     )
}

interface IConsultantDetailsInfoBasePropsModel {
     consultantInfoPerso: IGetDetailsConsultant
}

export default ConsultantDetailsInfoBase
