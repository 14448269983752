import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { getFournisseurSimpleDetails } from "../../../../../fournisseur/core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import {
     CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT,
     CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT,
     CONST_API_UTILS_GET_PAYS_ENDPOINT,
     CONST_API_UTILS_GET_VILLES_URL_ENDPOINT,
} from "../../../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MySelectField from "@common-utils/fields/MySelectField"
import MyInputField from "@common-utils/fields/MyInputField"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import React, { useState } from "react"
import { IFournisseurModel, IHttpErrorResponseModel } from "@common-models/*"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import {
     CONST_CIVILITE_MR,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
     CONST_PAYS_CODE_FRANCE,
     CONST_PAYS_ID_FRANCE,
} from "@common-constants/*"
import { IGetDetailsConsultant, ISetInfoPersoNonSalarieRequestModel } from "../../core/_models"
import { setConsultantInfoPersoNonSalarie } from "../../core/_requests"
import MyModal from "@common-utils/MyModal"
import AddFournisseurForm from "../../../../../utils/core/_AddFournisseurForm"
import { useThemeMode } from "@common-metronic/partials"

const ConsultantDetailsInfoBaseFormInfoPersoNonSalarie = ({ consultantInfoPerso }: IConsultantDetailsInfoBaseFormInfoPersoPropsModel) => {
     const { mode } = useThemeMode()

     const [fournisseurSimpleInfo, setFournisseurSimpleInfo] = useState<{ loading: boolean; data: IFournisseurModel | null }>({
          loading: false,
          data: consultantInfoPerso.st?.fournisseur || null,
     })
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const [avatarPreview, setAvatarPreview] = useState<any>(
          consultantInfoPerso.avatarGoogleDriveId
               ? FileAndDriveHelper.getThumbnailDriveUrl(consultantInfoPerso.avatarGoogleDriveId)
               : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
     )
     const [isAddingFournisseur, setIsAddingFournisseur] = useState<boolean>(false)

     const schema = Yup.object().shape({
          fournisseur: Yup.number().label(`"Fournisseur"`).nullable().required(),
          civilite: Yup.string().label(`"Civilité"`).nullable().required(),
          nom: Yup.string().label(`"Nom"`).nullable().required(),
          prenom: Yup.string().label(`"Prénom"`).nullable().required(),
          telIndicatif: Yup.number().label(`"Indicatif tél."`).nullable().required(),
          tel: Yup.number().label(`"Téléphone"`).nullable().required(),
          email: Yup.string().email().label(`"Email"`).nullable().required(),
          naissanceDate: Yup.string().label(`"Date de naissance"`).nullable().required(),
          naissancePays: Yup.number().label(`"Pays de naissance"`).nullable().required(),
          isNationaliteEtrangere: Yup.boolean().required(),
          nationalite: Yup.number()
               .label(`"Nationalité"`)
               .nullable()
               .when("isNationaliteEtrangere", {
                    is: true,
                    then: schema => schema.required(),
               }),
          residenceAdresse: Yup.string().label(`"Adresse de résidence"`).nullable().required(),
          residencePays: Yup.number().label(`"Pays de résidence"`).nullable().required(),
          residenceVille: Yup.string()
               .label(`"Ville de résidence"`)
               .nullable()
               .when("residencePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    otherwise: schema => schema.required(),
               }),
          residenceVilleFrance: Yup.number()
               .label(`"Ville de résidence"`)
               .nullable()
               .when("residencePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          residenceCodePostal: Yup.number().label(`"Code postal de résidence"`).nullable().required(),
          avatar: Yup.mixed()
               .nullable()
               .test("fileFormat", "Seuls les formats JPEG ou PNG sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Avatar"`),
     })

     const initialValues: ISetInfoPersoNonSalarieRequestModel = {
          fournisseur: consultantInfoPerso.st?.fournisseur?.id,
          civilite: consultantInfoPerso.consultant?.civilite,
          nom: consultantInfoPerso.consultant?.nom,
          prenom: consultantInfoPerso.consultant?.prenom,
          telIndicatif: consultantInfoPerso.consultant?.telProPays?.id,
          tel: consultantInfoPerso.consultant?.telPro,
          email: consultantInfoPerso.consultant?.mailPro,
          naissanceDate: consultantInfoPerso.consultant?.naissanceDate?.format("YYYY-MM-DD"),
          naissancePays: consultantInfoPerso.consultant?.naissancePays?.id,
          isNationaliteEtrangere: !!(consultantInfoPerso.consultant?.nationalite && consultantInfoPerso.consultant?.nationalite?.code !== CONST_PAYS_CODE_FRANCE),
          nationalite:
               consultantInfoPerso.consultant?.nationalite && consultantInfoPerso.consultant?.nationalite?.code !== CONST_PAYS_CODE_FRANCE
                    ? consultantInfoPerso.consultant?.nationalite?.id
                    : undefined,
          residenceAdresse: consultantInfoPerso.consultant?.domicileAdresse,
          residencePays: consultantInfoPerso.consultant?.domicilePays?.id,
          residenceVille: consultantInfoPerso.consultant?.domicileVille,
          residenceVilleFrance: consultantInfoPerso.consultant?.domocileVilleFrance?.id,
          residenceCodePostal: consultantInfoPerso.consultant?.domicileCodePostal,
     }

     function handleSubmit(values: ISetInfoPersoNonSalarieRequestModel, helpers: FormikHelpers<ISetInfoPersoNonSalarieRequestModel>) {
          helpers.setStatus(null)

          setConsultantInfoPersoNonSalarie(consultantInfoPerso.consultant.id as number, values)
               .then(() => {
                    toast.success("Vos modifications ont bien été prises en compte.")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Handles change Fournisseur
     function handleFournisseur(fournisseur_id: number | undefined | null) {
          if (fournisseur_id) {
               setFournisseurSimpleInfo(prev => ({ ...prev, loading: true }))
               getFournisseurSimpleDetails(fournisseur_id)
                    .then(r => {
                         setFournisseurSimpleInfo({ loading: false, data: r.data })
                    })
                    .catch(() => {
                         setFournisseurSimpleInfo(prev => ({ ...prev, loading: false }))
                         toast.error("Un problème est survenu lors de la récupération des informations du forunisseur sélectionné.", { autoClose: false })
                    })
          }
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Fournisseur */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Fournisseur</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"fournisseur"}
                                                  value={helpers.values.fournisseur}
                                                  defaultOptions={
                                                       consultantInfoPerso.st?.fournisseur
                                                            ? [
                                                                   {
                                                                        value: consultantInfoPerso.st?.fournisseur.id as number,
                                                                        label: consultantInfoPerso.st?.fournisseur.raisonSociale as string,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  isInvalid={!!helpers.errors.fournisseur}
                                                  onChange={val => {
                                                       helpers.setFieldValue("fournisseur", val).then(() => {
                                                            handleFournisseur(val as number)
                                                       })
                                                  }}
                                                  url={CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT}
                                                  isClearable={false}
                                             />
                                             <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                  {helpers.errors.fournisseur && <div className={"text-danger"}>{helpers.errors.fournisseur}</div>}
                                                  <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingFournisseur(true)}>
                                                       Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   {fournisseurSimpleInfo.loading && (
                                        <div className={"text-center p-5"}>
                                             <MySimpleSpinner size={"md"} />{" "}
                                        </div>
                                   )}

                                   {fournisseurSimpleInfo.data && (
                                        <div>
                                             {/* Détails Fournisseur (loaded) */}
                                             <div className={"p-4 border border-dashed text-muted mb-6"}>
                                                  <h4 className={"mb-4 text-muted"}>
                                                       Détails de "{fournisseurSimpleInfo.data.raisonSociale}"
                                                       <i className="fas fa-info-circle fs-3 float-end" />
                                                  </h4>
                                                  <div className="separator separator-dashed mb-6" />

                                                  <p>Type: {fournisseurSimpleInfo.data.typeLabel}</p>
                                                  <p>Forme juridique: {fournisseurSimpleInfo.data.formeJuridiqueLabel}</p>
                                             </div>

                                             <h3 className={"mt-8 mb-4"}>Consultant</h3>
                                             <div className="separator separator-dashed mb-6" />

                                             {/* Field Avatar */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6">Photo</label>
                                                  <div className="col-lg-8">
                                                       <div
                                                            className="image-input image-input-outline"
                                                            data-kt-image-input="true"
                                                            style={{ backgroundImage: `url(${avatarPreview})` }}
                                                       >
                                                            <MyLoadingOverlay isActive={isAvatarLoading}>
                                                                 <img
                                                                      className="image-input-wrapper w-125px h-125px"
                                                                      src={avatarPreview}
                                                                      onLoad={() => setIsAvatarLoading(false)}
                                                                      alt={"Avatar"}
                                                                 ></img>
                                                            </MyLoadingOverlay>

                                                            <label
                                                                 className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                 data-kt-image-input-action="change"
                                                                 data-bs-original-title="Change avatar"
                                                            >
                                                                 <i className="bi bi-pencil-fill fs-7"></i>
                                                                 <input
                                                                      type="file"
                                                                      name="avatar"
                                                                      accept=".png, .jpg, .jpeg"
                                                                      onChange={e => {
                                                                           const file = e.target.files![0]
                                                                           helpers.setFieldValue("avatar", file).then()
                                                                           const reader = new FileReader()
                                                                           reader.onloadend = () => {
                                                                                // Set the image preview URL
                                                                                setAvatarPreview(reader.result as string)
                                                                           }
                                                                           reader.readAsDataURL(file)
                                                                      }}
                                                                 />
                                                            </label>
                                                       </div>
                                                  </div>
                                             </div>

                                             {/* Field Civilité */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            name={"civilite"}
                                                            options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                            value={helpers.values.civilite}
                                                            isInvalid={!!helpers.errors.civilite}
                                                            onChange={val => helpers.setFieldValue("civilite", val)}
                                                       />
                                                       {helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}
                                                  </div>
                                             </div>

                                             {/* Nom & prénom */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom NOM</label>

                                                  <div className="col-lg-8">
                                                       <div className="row">
                                                            <div className="col-lg-6 mb-2 mb-lg-0">
                                                                 <MyInputField
                                                                      name={"prenom"}
                                                                      value={helpers.values.prenom}
                                                                      isInvalid={!!helpers.errors.prenom}
                                                                      placeholder={"Prénom"}
                                                                      onChange={val => helpers.setFieldValue("prenom", val)}
                                                                 />
                                                                 {helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                 <MyInputField
                                                                      name={"nom"}
                                                                      value={helpers.values.nom}
                                                                      isInvalid={!!helpers.errors.nom}
                                                                      placeholder={"Nom"}
                                                                      onChange={val => helpers.setFieldValue("nom", val)}
                                                                 />
                                                                 {helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             {/* Email */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>

                                                  <div className="col-lg-8">
                                                       <MyInputField
                                                            name={"email"}
                                                            value={helpers.values.email}
                                                            isInvalid={!!helpers.errors.email}
                                                            onChange={val => helpers.setFieldValue("email", val)}
                                                       />
                                                       {helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}
                                                  </div>
                                             </div>

                                             {/* Field Telindicatif + Tél*/}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                                                  <div className="col-lg-8">
                                                       <div className="row">
                                                            <div className="col-lg-6 mb-2 mb-lg-0">
                                                                 <MySelectGetAllDataFromServer
                                                                      name={"telIndicatif"}
                                                                      value={helpers.values.telIndicatif}
                                                                      url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                                      defaultOptions={
                                                                           consultantInfoPerso.consultant.telProPays
                                                                                ? [
                                                                                       {
                                                                                            value: consultantInfoPerso.consultant.telProPays.id as number,
                                                                                            label: consultantInfoPerso.consultant.telProPays
                                                                                                 .phoneCodeWithPlusPrefixAndCountry as string,
                                                                                       },
                                                                                  ]
                                                                                : []
                                                                      }
                                                                      placeholder={"Choisissez un indicatif"}
                                                                      isInvalid={!!helpers.errors.telIndicatif}
                                                                      onChange={val => helpers.setFieldValue("telIndicatif", val)}
                                                                 />
                                                                 {helpers.errors.telIndicatif && <div className={"text-danger"}>{helpers.errors.telIndicatif}</div>}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                 <MyInptuMaskField
                                                                      name={"tel"}
                                                                      value={helpers.values.tel}
                                                                      isInvalid={!!helpers.errors.tel}
                                                                      mask={"99 9999999999999999999999999"}
                                                                      placeholder={"Téléphone"}
                                                                      inputMode={"numeric"}
                                                                      onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                                                 />
                                                                 {helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             {/*NAISSANCE*/}
                                             <div className={"pb-6"}>
                                                  <h4 className={"mt-8 mb-4"}>Naissance</h4>
                                                  <div className="separator separator-dashed mb-6" />

                                                  {/*Date de naissance*/}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                            <span>Date de naissance</span>
                                                       </label>

                                                       <div className="col-lg-8">
                                                            <MyDateDayMonthYearField
                                                                 name={"naissanceDate"}
                                                                 onChange={date => helpers.setFieldValue("naissanceDate", date)}
                                                                 value={helpers.values.naissanceDate}
                                                                 isInvalid={!!helpers.errors.naissanceDate}
                                                                 placeholder={"Date de naissance"}
                                                            />
                                                            {helpers.errors.naissanceDate && <div className={"text-danger"}>{helpers.errors.naissanceDate}</div>}
                                                       </div>
                                                  </div>

                                                  {/*Pays et ville de naissance*/}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label required fw-bold fs-6">Pays de naissance</label>

                                                       <div className="col-lg-8">
                                                            <MySelectGetAllDataFromServer
                                                                 name={"naissancePays"}
                                                                 value={helpers.values.naissancePays}
                                                                 url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                                 defaultOptions={
                                                                      consultantInfoPerso.consultant.naissancePays
                                                                           ? [
                                                                                  {
                                                                                       value: consultantInfoPerso.consultant.naissancePays.id as number,
                                                                                       label: consultantInfoPerso.consultant.naissancePays.nomFr as string,
                                                                                  },
                                                                             ]
                                                                           : []
                                                                 }
                                                                 isInvalid={!!helpers.errors.naissancePays}
                                                                 onChange={val => helpers.setFieldValue("naissancePays", val)}
                                                            />
                                                            {helpers.errors.naissancePays && <div className={"text-danger"}>{helpers.errors.naissancePays}</div>}
                                                       </div>
                                                  </div>

                                                  {/*French?*/}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                            Est-{helpers.values.civilite === CONST_CIVILITE_MR ? "il" : "elle"} de nationalité étrangère?
                                                       </label>

                                                       <div className="col-lg-8 d-flex align-items-center">
                                                            <MyCheckBoxField
                                                                 name={"isNationaliteEtrangere"}
                                                                 value={helpers.values.isNationaliteEtrangere}
                                                                 isInvalid={!!helpers.errors.isNationaliteEtrangere}
                                                                 onChange={val => helpers.setFieldValue("isNationaliteEtrangere", val)}
                                                            />
                                                            {helpers.errors.isNationaliteEtrangere && <div className={"text-danger"}>{helpers.errors.isNationaliteEtrangere}</div>}
                                                       </div>
                                                  </div>

                                                  {helpers.values.isNationaliteEtrangere && (
                                                       <div className="row">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez sa nationalité</label>

                                                            <div className="col-lg-8">
                                                                 <MySelectGetAllDataFromServer
                                                                      name={"document"}
                                                                      value={helpers.values.nationalite}
                                                                      defaultOptions={
                                                                           consultantInfoPerso.consultant.nationalite
                                                                                ? [
                                                                                       {
                                                                                            value: consultantInfoPerso.consultant.nationalite.id as number,
                                                                                            label: consultantInfoPerso.consultant.nationalite.nomFr as string,
                                                                                       },
                                                                                  ]
                                                                                : []
                                                                      }
                                                                      url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                                      payload={{ excluded_country_codes: [CONST_PAYS_CODE_FRANCE] }}
                                                                      isInvalid={!!helpers.errors.nationalite}
                                                                      onChange={val => helpers.setFieldValue("nationalite", val)}
                                                                 />
                                                                 {helpers.errors.nationalite && <div className={"text-danger"}>{helpers.errors.nationalite}</div>}
                                                            </div>
                                                       </div>
                                                  )}
                                             </div>

                                             {/*RÉSIDENCE*/}
                                             <div className={"pb-6"}>
                                                  <h4 className={"mt-8 mb-4"}>Résidence</h4>
                                                  <div className="separator separator-dashed mb-6" />

                                                  {/*Adresse*/}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                            <span>Adresse</span>
                                                       </label>

                                                       <div className="col-lg-8">
                                                            <MyInputField
                                                                 name={"residenceAdresse"}
                                                                 onChange={date => helpers.setFieldValue("residenceAdresse", date)}
                                                                 value={helpers.values.residenceAdresse}
                                                                 isInvalid={!!helpers.errors.residenceAdresse}
                                                                 placeholder={"Adresse"}
                                                            />
                                                            {helpers.errors.residenceAdresse && <div className={"text-danger"}>{helpers.errors.residenceAdresse}</div>}
                                                       </div>
                                                  </div>

                                                  {/* Pays*/}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label required fw-bold fs-6">Pays</label>

                                                       <div className="col-lg-8">
                                                            <MySelectGetAllDataFromServer
                                                                 name={"residencePays"}
                                                                 value={helpers.values.residencePays}
                                                                 url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                                 defaultOptions={
                                                                      consultantInfoPerso.consultant.domicilePays
                                                                           ? [
                                                                                  {
                                                                                       value: consultantInfoPerso.consultant.domicilePays.id as number,
                                                                                       label: consultantInfoPerso.consultant.domicilePays.nomFr as string,
                                                                                  },
                                                                             ]
                                                                           : []
                                                                 }
                                                                 isInvalid={!!helpers.errors.residencePays}
                                                                 onChange={val => helpers.setFieldValue("residencePays", val)}
                                                            />
                                                            {helpers.errors.residencePays && <div className={"text-danger"}>{helpers.errors.residencePays}</div>}
                                                       </div>
                                                  </div>

                                                  {/*Ville et code postal*/}
                                                  {helpers.values.residencePays && (
                                                       <div className="row">
                                                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Ville et code postal</label>

                                                            <div className="col-lg-8">
                                                                 <div className="row">
                                                                      <div className="col-6">
                                                                           {helpers.values.residencePays === CONST_PAYS_ID_FRANCE ? (
                                                                                <>
                                                                                     <MySelectSearchDataFromServer
                                                                                          name={"residenceVilleFrance"}
                                                                                          value={helpers.values.residenceVilleFrance}
                                                                                          url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                                          method={"GET"}
                                                                                          minimumLengthSearch={2}
                                                                                          placeholder={"Choisissez la ville"}
                                                                                          defaultOptions={
                                                                                               consultantInfoPerso.consultant.domocileVilleFrance
                                                                                                    ? [
                                                                                                           {
                                                                                                                value: consultantInfoPerso.consultant.domocileVilleFrance
                                                                                                                     .id as number,
                                                                                                                label: consultantInfoPerso.consultant.domocileVilleFrance
                                                                                                                     .nom as string,
                                                                                                           },
                                                                                                      ]
                                                                                                    : []
                                                                                          }
                                                                                          isInvalid={!!helpers.errors.residenceVilleFrance}
                                                                                          onChange={val => helpers.setFieldValue("residenceVilleFrance", val)}
                                                                                     />
                                                                                     {helpers.errors.residenceVilleFrance && (
                                                                                          <div className={"text-danger"}>{helpers.errors.residenceVilleFrance}</div>
                                                                                     )}
                                                                                </>
                                                                           ) : (
                                                                                <>
                                                                                     <MyInputField
                                                                                          name={"residenceVille"}
                                                                                          value={helpers.values.residenceVille}
                                                                                          isInvalid={!!helpers.errors.residenceVille}
                                                                                          placeholder={"Ville"}
                                                                                          onChange={val => helpers.setFieldValue("residenceVille", val)}
                                                                                     />
                                                                                     {helpers.errors.residenceVille && (
                                                                                          <div className={"text-danger"}>{helpers.errors.residenceVille}</div>
                                                                                     )}
                                                                                </>
                                                                           )}
                                                                      </div>
                                                                      <div className="col-6">
                                                                           <MyInputField
                                                                                name={"residenceCodePostal"}
                                                                                value={helpers.values.residenceCodePostal}
                                                                                isInvalid={!!helpers.errors.residenceCodePostal}
                                                                                placeholder={"Code postal"}
                                                                                inputMode={"numeric"}
                                                                                onChange={val => helpers.setFieldValue("residenceCodePostal", val)}
                                                                           />
                                                                           {helpers.errors.residenceCodePostal && (
                                                                                <div className={"text-danger"}>{helpers.errors.residenceCodePostal}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  )}
                                             </div>
                                        </div>
                                   )}

                                   <div className="separator bg-primary my-5"></div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Sauvegarder et suivant <span className={"fas fa-arrow-right fs-4 ms-1"}></span>
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>

               {/* Modal add fournisseur */}
               {isAddingFournisseur && (
                    <MyModal title={<>Nouveau fournisseur</>} show={true} handleClose={() => setIsAddingFournisseur(false)}>
                         <AddFournisseurForm callback={() => setIsAddingFournisseur(false)} />
                    </MyModal>
               )}
          </>
     )
}

interface IConsultantDetailsInfoBaseFormInfoPersoPropsModel {
     consultantInfoPerso: IGetDetailsConsultant
}

export default ConsultantDetailsInfoBaseFormInfoPersoNonSalarie
