import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import MyEditorField from "@common-utils/fields/MyEditorField"
import MyInputField from "@common-utils/fields/MyInputField"
import React, { useState } from "react"
import { IPersonnelTemplateEditRequestModel } from "./core/_models"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_ENVOYER_DOCO_VIERGE_PAR_MAIL_A_REMPLIR_PAR_CANDIDAT,
     CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL,
     CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PERSONNEL_MAIL_TEMPLATE_TYPE_CANDIDAT_REFUS,
} from "@common-constants/*"
import { editPersonneltemplate, getDetailsPersonnelTemplate } from "./core/_request"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel, IPersonnelTemplateModel } from "@common-models/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useQuery } from "react-query"
import MyAlert from "@common-utils/MyAlert"

interface IFormValuesModel {
     titre: string
     objet?: string | null
     body: string
}

const PersonnelTemplateEditForm = ({
     template_ID,
     handleOnSubmitCallBack = () => {},
}: {
     template_ID: number
     handleOnSubmitCallBack?: (editedTemplate: IPersonnelTemplateModel) => void
}) => {
     const [initialValues, setInitialValues] = useState<IFormValuesModel | null>(null)

     const getTemplateQuery = useQuery<IPersonnelTemplateModel, AxiosError>("template", () =>
          getDetailsPersonnelTemplate(template_ID).then(r => {
               setInitialValues({
                    titre: r.data.titreTemplate as string,
                    objet: r.data.intitule,
                    body: r.data.contenu as string,
               })
               return r.data
          })
     )

     const schema = Yup.object().shape({
          titre: Yup.string().label(`"Titre du template"`).required(),
          objet: Yup.string().label(`"Objet"`).nullable(),
          body: Yup.string().label(`"Corps"`).required(),
     })

     function handleSubmit(values: IFormValuesModel, helpers: FormikHelpers<IFormValuesModel>) {
          const data: IPersonnelTemplateEditRequestModel = {
               titre: values.titre,
               body: values.body,
               objet: values.objet,
               categorie: getTemplateQuery.data?.categorie?.code as string,
          }
          editPersonneltemplate(template_ID, data)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    handleOnSubmitCallBack(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               {/* Fetching */}
               {getTemplateQuery.isFetching ? (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               ) : (
                    <>
                         {/* In case of error */}
                         {getTemplateQuery.error ? (
                              <MyAlert type={"danger"}>{getTemplateQuery.error.response?.data.detail}</MyAlert>
                         ) : (
                              <>
                                   {initialValues && getTemplateQuery.data && (
                                        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                             {helpers => {
                                                  return (
                                                       <Form noValidate autoComplete="off" className={"w-xl-70 w-xxl-50"}>
                                                            {/* Titre field */}
                                                            <div className="row mb-5">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6">Titre</label>

                                                                 <div className="col-lg-8">
                                                                      <MyInputField
                                                                           name={"titre"}
                                                                           value={helpers.values.titre}
                                                                           onChange={val => helpers.setFieldValue("titre", val)}
                                                                           placeholder={"Titre du template"}
                                                                           isInvalid={!!helpers.errors.titre}
                                                                      />
                                                                      <div className={"mb-2"}>
                                                                           {helpers.errors.titre && <div className={"text-danger"}>{helpers.errors.titre}</div>}
                                                                      </div>
                                                                 </div>
                                                            </div>

                                                            {/* Objet field */}
                                                            {[
                                                                 CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PERSONNEL_MAIL_TEMPLATE_TYPE_CANDIDAT_REFUS,
                                                                 CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL,
                                                                 CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_ENVOYER_DOCO_VIERGE_PAR_MAIL_A_REMPLIR_PAR_CANDIDAT,
                                                            ].includes(getTemplateQuery.data.categorie?.code as string) && (
                                                                 <div className="row mb-5">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6">Objet</label>

                                                                      <div className="col-lg-8">
                                                                           <MyInputField
                                                                                name={"objet"}
                                                                                value={helpers.values.objet}
                                                                                onChange={val => helpers.setFieldValue("objet", val)}
                                                                                placeholder={"Objet"}
                                                                                isInvalid={!!helpers.errors.objet}
                                                                           />
                                                                           <div className={"mb-2"}>
                                                                                {helpers.errors.objet && <div className={"text-danger"}>{helpers.errors.objet}</div>}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            )}

                                                            {/* Body field */}
                                                            <>
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6">Contenu</label>
                                                                 <MyEditorField
                                                                      value={helpers.values.body}
                                                                      height={250}
                                                                      onChange={val => helpers.setFieldValue("body", val)}
                                                                      placeholder={"Contenu du template"}
                                                                 />
                                                                 <div className={"mb-2"}>{helpers.errors.body && <div className={"text-danger"}>{helpers.errors.body}</div>}</div>
                                                            </>

                                                            {/* Submit button */}
                                                            <div className={"text-end"}>
                                                                 <button
                                                                      className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                                      type={"submit"}
                                                                      disabled={helpers.isSubmitting}
                                                                 >
                                                                      {!helpers.isSubmitting ? "Appliquer les modifications" : <MySimpleSpinner size={"sm"} />}
                                                                 </button>
                                                            </div>
                                                       </Form>
                                                  )
                                             }}
                                        </Formik>
                                   )}
                              </>
                         )}
                    </>
               )}
          </>
     )
}

export default PersonnelTemplateEditForm
