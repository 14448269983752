import useSwal from "@common-hooks/useSwal"
import { useAuth } from "../../../../AppContext"
import React from "react"
import { IConsultantDocumentModel, IConsultantMissionModel } from "@common-models/*"
import { toast } from "react-toastify"
import {
     addConsultantDocumentRequest,
     addVersionSigneeConsultantDocument,
     addVersionSigneeConsultantMissionDoc,
     deleteConsultantDocument,
     replaceConsultantDocument,
     replaceConsultantMissionDoc,
} from "./_requests"
import { AxiosError } from "axios"
import { CONST_CIVILITE_MME } from "@common-constants/*"

export const useConsultantDocumentActions = (consultant_ID: number) => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()

     function add(e: React.ChangeEvent<HTMLInputElement>, type: string, callback: (val: IConsultantDocumentModel) => void = () => {}) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info(`Envoi du document en cours ...`, { autoClose: false })

               addConsultantDocumentRequest(consultant_ID, type, fileBinary)
                    .then(r => {
                         callback(r.data)
                         toast.dismiss()
                         toast.success("Le document a bien ajouté.")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function flagAsDeleted(id: number, callback: () => void = () => {}) {
          swal.fire({
               icon: "warning",
               text: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir supprimer le document?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deleteConsultantDocument(id)
                         .then(() => {
                              callback()
                              toast.success("Le document a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function replace(e: React.ChangeEvent<HTMLInputElement>, id: number, callback: (val: IConsultantDocumentModel) => void = () => {}) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Remplacement du document en cours ...", { autoClose: false })

               replaceConsultantDocument(id, fileBinary)
                    .then(r => {
                         callback(r.data)
                         toast.dismiss()
                         toast.success("Le document a bien été remplacé.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function addVersionSignee(e: React.ChangeEvent<HTMLInputElement>, id: number, callback: (val: IConsultantDocumentModel) => void = () => {}) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Ajout de la version signée en cours ...", { autoClose: false })

               addVersionSigneeConsultantDocument(id, fileBinary)
                    .then(r => {
                         callback(r.data)
                         toast.dismiss()
                         toast.success("Le version signée a bien été ajoutée.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     return { add, flagAsDeleted, replace, addVersionSignee }
}

export const useConsultantMissionDocActions = () => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()

     function replace(e: React.ChangeEvent<HTMLInputElement>, id: number, callback: (val: IConsultantMissionModel) => void = () => {}) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Remplacement du document en cours ...", { autoClose: false })

               replaceConsultantMissionDoc(id, fileBinary)
                    .then(r => {
                         callback(r.data)
                         toast.dismiss()
                         toast.success("Le document a bien été remplacé.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function addVersionSignee(e: React.ChangeEvent<HTMLInputElement>, id: number, callback: (val: IConsultantMissionModel) => void = () => {}) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Ajout de la version signée en cours ...", { autoClose: false })

               addVersionSigneeConsultantMissionDoc(id, fileBinary)
                    .then(r => {
                         callback(r.data)
                         toast.dismiss()
                         toast.success("Le version signée a bien été ajoutée.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     return { replace, addVersionSignee }
}
