import { usePersonnelDetailsContext } from "../PersonnelDetails"
import { Link } from "react-router-dom"

const InfoProViewOnly = () => {
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data

     return (
          <>
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Statut</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.statutLabel}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Contrat</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.contratLabel}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Email</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              <a className={"text-dark text-hover-primary"} href={`mailto:${details?.personnel.mailProfessionnel}`}>
                                   {details?.personnel.mailProfessionnel}
                              </a>
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Téléphone</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              <a
                                   href={`tel:${details?.personnel.telProfessionnelIndicatif ? details?.personnel.telProfessionnelIndicatif.phoneCodeWithPlusPrefix : ""} ${details
                                        ?.personnel.telProfessionnel}`}
                                   className="text-dark text-hover-primary"
                              >
                                   {details?.personnel.telProfessionnelIndicatif ? details?.personnel.telProfessionnelIndicatif.phoneCodeWithPlusPrefix : ""}{" "}
                                   {details?.personnel.telProfessionnel}
                              </a>
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Poste</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {details?.personnel.personnelPostes && details?.personnel.personnelPostes.length > 0 && details?.personnel.personnelPostes[0].poste?.nom}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Agence</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.agence?.nom}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Entité de rattachement</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.mainEntite?.nom}</span>
                    </div>
               </div>

               {details?.responsable && (
                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Responsable direct</label>
                         <div className="col-lg-8">
                              <Link to={`/gestion/structure/staff/${details?.responsable?.id}/details`} className={"fw-bolder fs-6 text-dark text-hover-primary"}>
                                   {details?.responsable?.prenomNom}
                              </Link>
                         </div>
                    </div>
               )}
          </>
     )
}

export default InfoProViewOnly
