import * as Yup from "yup"
import { CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "@common-utils/MyAlert"
import React, { useState } from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyCard from "@common-utils/MyCard"
import { useDemandeEmbaucheDetailsContext } from "../DemandeEmbaucheDetails"
import {
     IGetDemandeEmbaucheInfoContractResponseModel,
     ISetDemandeEmbaucheInfoContractNonSalarieFormikValuesModel,
     ISetDemandeEmbaucheInfoContractNonSalarieRequestModel,
} from "./core/_models"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT, CONST_API_UTILS_GET_ENTITES_ENDPOINT, CONST_API_UTILS_GET_METIERS_URL_ENDPOINT } from "../../../../../endpoints"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { setDemandeEmbaucheInfoContractNonSalarie } from "./core/_requests"
import { IHttpErrorResponseModel } from "@common-models/*"
import MyModal from "@common-utils/MyModal"
import AddMetierForm from "../../../../utils/_AddMetierForm"

const DemandeEmbaucheDetailsInfoContractNonSalarie = ({ data }: { data: IGetDemandeEmbaucheInfoContractResponseModel }) => {
     const context = useDemandeEmbaucheDetailsContext()
     const [isAddingMetier, setIsAddingMetier] = useState<boolean>(false)

     const schema = Yup.object().shape({
          entiteRattachement: Yup.number().label(`"Entité de rattachement"`).nullable().required(),
          entiteFacturation: Yup.number().label(`"Entité de facturation"`).nullable().required(),
          metier: Yup.number().label(`"Métier"`).nullable().required(),
          dateDateDebutMission: Yup.string().label(`"Date de début de mission"`).nullable().required(),
          doesConsultantHaveDelegationAdministrative: Yup.boolean().label("Délégation").nullable().required(),
          personnelsWithDelegationAdminDuConsultant: Yup.array()
               .label("Responsables")
               .nullable()
               .when("doesConsultantHaveDelegationAdministrative", {
                    is: true,
                    then: schema => schema.required(),
               }),
          tauxJournalierAchat: Yup.number()
               .label(`"Taux journalier d'achat HT"`)
               .nullable()
               .when("typeEmbauche", {
                    is: CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION,
                    then: schema => schema.required(),
               }),
     })

     const initialValues: ISetDemandeEmbaucheInfoContractNonSalarieFormikValuesModel = {
          entiteRattachement: data.consultant.entite ? (data.consultant.entite.id as number) : null,
          entiteFacturation: data.consultant.entiteFacturation ? (data.consultant.entiteFacturation.id as number) : null,
          metier: data.consultant.metier ? (data.consultant.metier.id as number) : null,
          dateDateDebutMission: data.consultant.dateEmbauche ? data.consultant.dateEmbauche.format("YYYY-MM-DD") : null,
          tauxJournalierAchat: data?.consultant.coutBrutNonCharge as number | null,
          doesConsultantHaveDelegationAdministrative: !!(
               data.consultant.personnelAvecDelegationDroitsConsultants && data.consultant.personnelAvecDelegationDroitsConsultants.length > 0
          ),
          personnelsWithDelegationAdminDuConsultant:
               data.consultant.personnelAvecDelegationDroitsConsultants && data.consultant.personnelAvecDelegationDroitsConsultants.length > 0
                    ? data.consultant.personnelAvecDelegationDroitsConsultants.map(item => item.personnel?.id as number)
                    : null,
     }

     function handleSubmit(values: ISetDemandeEmbaucheInfoContractNonSalarieFormikValuesModel, helpers: FormikHelpers<ISetDemandeEmbaucheInfoContractNonSalarieFormikValuesModel>) {
          helpers.setStatus(null)

          const payload: ISetDemandeEmbaucheInfoContractNonSalarieRequestModel = {
               entiteRattachement: values.entiteRattachement as number,
               entiteFacturation: values.entiteFacturation as number,
               metier: values.metier as number,
               dateDateDebutMission: values.dateDateDebutMission as string,
               tauxJournalierAchat: values.tauxJournalierAchat as number,
               doesConsultantHaveDelegationAdministrative: values.doesConsultantHaveDelegationAdministrative,
               personnelsWithDelegationAdminDuConsultant: values.personnelsWithDelegationAdminDuConsultant,
          }

          setDemandeEmbaucheInfoContractNonSalarie(data.consultant.id as number, payload)
               .then(() => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    context.setNavigationStep("client")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                                   <MyCard.Body>
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}

                                             {/* Date de début de mission */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de début de mission</label>

                                                  <div className="col-lg-8">
                                                       <MyDateDayMonthYearField
                                                            name={"dateDateDebutMission"}
                                                            value={helpers.values.dateDateDebutMission}
                                                            isInvalid={!!helpers.errors.dateDateDebutMission}
                                                            onChange={val => helpers.setFieldValue("dateDateDebutMission", val)}
                                                            placeholder={"Date d'embauche"}
                                                       />
                                                       {helpers.errors.dateDateDebutMission && <div className={"text-danger"}>{helpers.errors.dateDateDebutMission}</div>}
                                                  </div>
                                             </div>

                                             {/* Entité de rattachement */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de rattachement</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"entiteRattachement"}
                                                            value={helpers.values.entiteRattachement}
                                                            defaultOptions={
                                                                 data.consultant.entite
                                                                      ? [{ value: data.consultant.entite.id as number, label: data.consultant.entite.nom as string }]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.entiteRattachement}
                                                            onChange={val => helpers.setFieldValue("entiteRattachement", val)}
                                                            url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       />
                                                       {helpers.errors.entiteRattachement && <div className={"text-danger"}>{helpers.errors.entiteRattachement}</div>}
                                                  </div>
                                             </div>

                                             {/* Entité de facturation */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de facturation</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"entiteFacturation"}
                                                            value={helpers.values.entiteFacturation}
                                                            defaultOptions={
                                                                 data.consultant.entiteFacturation
                                                                      ? [
                                                                             {
                                                                                  value: data.consultant.entiteFacturation.id as number,
                                                                                  label: data.consultant.entiteFacturation.nom as string,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.entiteFacturation}
                                                            onChange={val => helpers.setFieldValue("entiteFacturation", val)}
                                                            url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       />
                                                       {helpers.errors.entiteFacturation && <div className={"text-danger"}>{helpers.errors.entiteFacturation}</div>}
                                                  </div>
                                             </div>

                                             {/* Métier */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Métier</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"metier"}
                                                            value={helpers.values.metier}
                                                            defaultOptions={
                                                                 data.consultant.metier
                                                                      ? [{ value: data.consultant.metier.id as number, label: data.consultant.metier.nom as string }]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.metier}
                                                            onChange={val => helpers.setFieldValue("metier", val)}
                                                            url={CONST_API_UTILS_GET_METIERS_URL_ENDPOINT}
                                                       />
                                                       <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                            {helpers.errors.metier && <div className={"text-danger"}>{helpers.errors.metier}</div>}
                                                            <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingMetier(true)}>
                                                                 Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             {/* TJMST */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Taux journalier d'achat HT</label>

                                                  <div className="col-lg-8">
                                                       <MyInputField
                                                            type={"number"}
                                                            name={"tauxJournalierAchat"}
                                                            value={helpers.values.tauxJournalierAchat}
                                                            isInvalid={!!helpers.errors.tauxJournalierAchat}
                                                            inputMode={"numeric"}
                                                            onChange={val => helpers.setFieldValue("tauxJournalierAchat", val)}
                                                            placeholder={"Taux journalier"}
                                                       />
                                                       {helpers.errors.tauxJournalierAchat && <div className={"text-danger"}>{helpers.errors.tauxJournalierAchat}</div>}
                                                  </div>
                                             </div>

                                             {/* Délégation admin */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                       Voulez-vous déléguer la gestion administrative du futur collaborateur?
                                                  </label>

                                                  <div className="col-lg-8 d-flex align-items-center">
                                                       <MyCheckBoxField
                                                            name={"doesConsultantHaveDelegationAdministrative"}
                                                            value={helpers.values.doesConsultantHaveDelegationAdministrative}
                                                            isInvalid={!!helpers.errors.doesConsultantHaveDelegationAdministrative}
                                                            onChange={val => helpers.setFieldValue("doesConsultantHaveDelegationAdministrative", val)}
                                                       />
                                                       {helpers.errors.doesConsultantHaveDelegationAdministrative && (
                                                            <div className={"text-danger"}>{helpers.errors.doesConsultantHaveDelegationAdministrative}</div>
                                                       )}
                                                  </div>
                                             </div>

                                             {helpers.values.doesConsultantHaveDelegationAdministrative && (
                                                  <div className="row">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez les délégués</label>

                                                       <div className="col-lg-8">
                                                            <MySelectGetAllDataFromServer
                                                                 name={"personnelsWithDelegationAdminDuConsultant"}
                                                                 value={helpers.values.personnelsWithDelegationAdminDuConsultant}
                                                                 defaultOptions={
                                                                      data.consultant.personnelAvecDelegationDroitsConsultants &&
                                                                      data.consultant.personnelAvecDelegationDroitsConsultants.length > 0
                                                                           ? data.consultant.personnelAvecDelegationDroitsConsultants.map(item => ({
                                                                                  value: item.personnel?.id as number,
                                                                                  label: item.personnel?.prenomNom as string,
                                                                             }))
                                                                           : []
                                                                 }
                                                                 isInvalid={!!helpers.errors.personnelsWithDelegationAdminDuConsultant}
                                                                 onChange={val => helpers.setFieldValue("personnelsWithDelegationAdminDuConsultant", val)}
                                                                 url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.personnelsWithDelegationAdminDuConsultant && (
                                                                 <div className={"text-danger"}>{helpers.errors.personnelsWithDelegationAdminDuConsultant}</div>
                                                            )}
                                                       </div>
                                                  </div>
                                             )}

                                             <div className="separator bg-primary my-5"></div>

                                             {/* Submit button */}
                                             <div className={"d-flex justify-content-between"}>
                                                  <button
                                                       className={"btn btn-sm btn-warning d-inline-flex align-items-center"}
                                                       type={"button"}
                                                       disabled={helpers.isSubmitting}
                                                       onClick={() => context.setNavigationStep("perso")}
                                                  >
                                                       <span className={"fas fa-arrow-left fs-4 me-1"}></span> Précédant
                                                  </button>
                                                  <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? (
                                                            <>
                                                                 Sauvegarder et suivant <span className={"fas fa-arrow-right fs-4 ms-1"}></span>
                                                            </>
                                                       ) : (
                                                            <MySimpleSpinner size={"sm"} />
                                                       )}
                                                  </button>
                                             </div>
                                        </Form>
                                   </MyCard.Body>
                              </MyCard>
                         )
                    }}
               </Formik>

               {/* Modal add métier */}
               {isAddingMetier && (
                    <MyModal title={<>Nouveau métier</>} show={true} handleClose={() => setIsAddingMetier(false)}>
                         <AddMetierForm callback={() => setIsAddingMetier(false)} />
                    </MyModal>
               )}
          </>
     )
}

export default DemandeEmbaucheDetailsInfoContractNonSalarie
