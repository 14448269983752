import React from "react"
import Footer from "./_Footer"
import PersonnelCra from "../../../../declaration/cra/_PersonnelCra"
import { useConnectedUserDeclarationDetails } from "../ConnectedPersonnelDeclarationDetails"

export const ConnectedUserDeclarationDetails_craWrapper = () => {
     const context = useConnectedUserDeclarationDetails()

     return (
          <>
               <PersonnelCra declaration_ID={context.data.declaration?.id as number} editionModeEnabled={context.editionModeEnabled} />

               <div className="separator separator-dashed my-6" />

               <Footer />
          </>
     )
}
