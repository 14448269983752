import { useNavigate, useParams } from "react-router-dom"
import { useQuery, useQueryClient } from "react-query"
import { IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getDemandeEmbaucheDocuments } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React, { useRef, useState } from "react"
import { IGetDemandeEmbaucheDocumentsRequestModel } from "./core/_models"
import MyCard from "@common-utils/MyCard"
import {
     CONST_CANDIDAT_DOCUMENT_TYPE_CV,
     CONST_CANDIDAT_DOCUMENT_TYPE_DOCO,
     CONST_CIVILITE_MME,
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
     CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES,
     CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT,
     CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME,
     CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
     CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
     CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
     CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
     CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION,
     CONST_PAYS_CODE_FRANCE,
     CONST_PERSONNEL_CODE_HUGO_DUTERTRE,
     CONST_PERSONNEL_CODE_THOMAS_NAULEAU,
} from "@common-constants/*"
import { addCandidatDocument, deleteCandidatDocument, replaceCandidatDocument } from "../../../candidats/core/_requests"
import { addConsultantDocumentRequest, deleteConsultantDocument, replaceConsultantDocument } from "../../../../business/consultants/core/_requests"
import useSwal from "@common-hooks/useSwal"
import { useAuth } from "../../../../../AppContext"
import MyModal from "@common-utils/MyModal"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { useDemandeEmbaucheDetailsContext } from "../DemandeEmbaucheDetails"
import MyTooltip from "@common-utils/MyTooltip"
import { useDemandeEmbaucheActions } from "../core/_hooks"
import FournisseurDocuments from "../../../../fournisseur/document/_FournisseurDocuments"
import DemandeEmbaucheDetailsValidationSiege from "../_DemandeEmbaucheDetailsValidationSiege"

const REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY = "REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY"
const DemandeEmbaucheDetailsDocuments = () => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()
     const queryClient = useQueryClient()
     const consultant_ID = parseInt(useParams<{ consultant_id: string }>().consultant_id as string)
     const navigate = useNavigate()
     const context = useDemandeEmbaucheDetailsContext()
     const actions = useDemandeEmbaucheActions(consultant_ID)

     const [validationSiegeInModal, setValidationSiegeInModal] = useState<{
          modalTitle: JSX.Element
          isModalFullScreen: boolean
     }>()

     const refsInputRemplacerDocument = {}
     refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_CV] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_DOCO] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_RIB] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME] = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)]
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES] = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)]

     const [activeDriveDocumentInModal, setActiveDriveDocumentInModal] = useState<{
          driveID: string
          driveMimeType: string
          modalTitle: string
          isLoading: boolean
          isShown: boolean
     }>()

     // Query
     const documentsQuery = useQuery<IGetDemandeEmbaucheDocumentsRequestModel, AxiosError>(REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY, () => {
          return getDemandeEmbaucheDocuments(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function handleAddConsultantDocument(e: React.ChangeEvent<HTMLInputElement>, type: string) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info(`Envoi du document en cours ...`, { autoClose: false })

               if (
                    [
                         CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT,
                         CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
                         CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
                         CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
                         CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
                         CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
                         CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME,
                         CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES,
                    ].includes(type)
               ) {
                    addConsultantDocumentRequest(consultant_ID, type, fileBinary)
                         .then(r => {
                              queryClient.setQueryData<IGetDemandeEmbaucheDocumentsRequestModel | undefined>(
                                   REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY,
                                   (prev: IGetDemandeEmbaucheDocumentsRequestModel | undefined) => {
                                        if (prev) {
                                             prev = { ...prev, consultantDocuments: [...prev.consultantDocuments, r.data] }
                                        }
                                        return prev
                                   }
                              )
                              toast.dismiss()
                              toast.success("Le document a bien ajouté.")
                         })
                         .catch((e: AxiosError) => {
                              toast.dismiss()
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
                         .finally(() => {
                              e.target.value = ""
                              e.target.disabled = false
                         })
               } else {
                    const candidat_ID = documentsQuery.data?.consultant.candidat?.id as number
                    addCandidatDocument(candidat_ID, type, fileBinary)
                         .then(r => {
                              queryClient.setQueryData<IGetDemandeEmbaucheDocumentsRequestModel | undefined>(
                                   REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY,
                                   (prev: IGetDemandeEmbaucheDocumentsRequestModel | undefined) => {
                                        if (prev) {
                                             prev = { ...prev, candidatDocuments: [...prev.candidatDocuments, r.data] }
                                        }
                                        return prev
                                   }
                              )
                              toast.dismiss()
                              toast.success("Le document a bien ajouté.")
                         })
                         .catch(e => {
                              toast.dismiss()
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
                         .finally(() => {
                              e.target.value = ""
                              e.target.disabled = false
                         })
               }
          }
     }

     function handleSetConsultantDocument(e: React.ChangeEvent<HTMLInputElement>, id: number, type: string) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Remplacement du document en cours ...", { autoClose: false })

               if (
                    [
                         CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT,
                         CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
                         CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
                         CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
                         CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
                         CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
                         CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME,
                         CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES,
                    ].includes(type)
               ) {
                    replaceConsultantDocument(id, fileBinary)
                         .then(r => {
                              queryClient.setQueryData<IGetDemandeEmbaucheDocumentsRequestModel | undefined>(
                                   REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY,
                                   (prev: IGetDemandeEmbaucheDocumentsRequestModel | undefined) => {
                                        if (prev) {
                                             const index = prev.consultantDocuments.findIndex(item => item.id === id) as number
                                             prev.consultantDocuments[index] = r.data
                                             return prev
                                        }
                                        return prev
                                   }
                              )
                              toast.dismiss()
                              toast.success("Le document a bien été remplacé.")
                         })
                         .catch(e => {
                              toast.dismiss()
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
                         .finally(() => {
                              e.target.value = ""
                              e.target.disabled = false
                         })
               } else {
                    replaceCandidatDocument(id, fileBinary)
                         .then(r => {
                              queryClient.setQueryData<IGetDemandeEmbaucheDocumentsRequestModel | undefined>(
                                   REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY,
                                   (prev: IGetDemandeEmbaucheDocumentsRequestModel | undefined) => {
                                        if (prev) {
                                             const index = prev.candidatDocuments.findIndex(item => item.id === id) as number
                                             prev.candidatDocuments[index] = r.data
                                             return prev
                                        }
                                        return prev
                                   }
                              )
                              toast.dismiss()
                              toast.success("Le document a bien été remplacé.")
                         })
                         .catch(e => {
                              toast.dismiss()
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
                         .finally(() => {
                              e.target.value = ""
                              e.target.disabled = false
                         })
               }
          }
     }

     function handleRemoveConsultantDocument(id: number, type: string) {
          swal.fire({
               icon: "warning",
               text: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir supprimer le document?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return [
                         CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT,
                         CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
                         CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
                         CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
                         CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
                         CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
                         CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME,
                         CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES,
                    ].includes(type)
                         ? deleteConsultantDocument(id)
                                .then(() => {
                                     queryClient.setQueryData<IGetDemandeEmbaucheDocumentsRequestModel | undefined>(
                                          REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY,
                                          (prev: IGetDemandeEmbaucheDocumentsRequestModel | undefined) => {
                                               if (prev) {
                                                    return { ...prev, consultantDocuments: prev.consultantDocuments.filter(item => item.id !== id) }
                                               }

                                               return prev
                                          }
                                     )
                                     toast.success("Le document a bien été supprimé.")
                                })
                                .catch(e => {
                                     toast.error(e?.response?.data?.detail, { autoClose: false })
                                })
                         : deleteCandidatDocument(id)
                                .then(() => {
                                     queryClient.setQueryData<IGetDemandeEmbaucheDocumentsRequestModel | undefined>(
                                          REACT_QUERY_KEY_GET_DE_DOCUMENTS_QUERY,
                                          (prev: IGetDemandeEmbaucheDocumentsRequestModel | undefined) => {
                                               if (prev) {
                                                    return { ...prev, candidatDocuments: prev.candidatDocuments.filter(item => item.id !== id) }
                                               }

                                               return prev
                                          }
                                     )
                                     toast.success("Le document a bien été supprimé.")
                                })
                                .catch(e => {
                                     toast.error(e?.response?.data?.detail, { autoClose: false })
                                })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function handleValidationAdministrativeCallback(generationOuUpload: "GENERATION_OU_UPLOAD__GENERATION" | "GENERATION_OU_UPLOAD__UPLOAD") {
          if (generationOuUpload === "GENERATION_OU_UPLOAD__UPLOAD") {
               toast.success("Votre validation a bien été prise en compte.")
               // DE ends at this point
               navigate("/recrutement/demandes-embauche/listing")
          } else {
               setValidationSiegeInModal(prev =>
                    prev
                         ? {
                                ...prev,
                                modalTitle: (
                                     <div className={"d-flex"}>
                                          Complétion du contrat
                                          <button
                                               className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"}
                                               onClick={() => actions.validationSiege(() => navigate("/recrutement/demandes-embauche/listing"))}
                                          >
                                               Terminer et valider <i className={"fas fa-thumbs-up ms-1"} />
                                          </button>
                                     </div>
                                ),
                                isModalFullScreen: true,
                           }
                         : prev
               )
          }
     }

     // Query Is fetching
     if (documentsQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />
               </div>
          )
     }

     // Query on Error
     if (!documentsQuery.isFetching && documentsQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{documentsQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => documentsQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     // Query with data
     if (!documentsQuery.isFetching && documentsQuery.data) {
          const candidatDocuments = documentsQuery.data.candidatDocuments
          const consultantDocuments = documentsQuery.data.consultantDocuments
          const consultant = documentsQuery.data.consultant

          const ActionsAccordingToEtatDemandeEmbauche = () => {
               const isUserFromSiege = currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF"
               const isUserFromDirection = [CONST_PERSONNEL_CODE_HUGO_DUTERTRE, CONST_PERSONNEL_CODE_THOMAS_NAULEAU].find(item_ => item_ === currentUser?.code) !== undefined
               const isUserResponsable = consultant.personnel?.id === currentUser?.id
               const isUserResponsableOfResponsable = currentUser?.personnelHierarchyFromUpToDown?.find(item_ => item_.id === consultant.personnel?.id) !== undefined

               if (isUserFromSiege) {
                    if (context.dernierSuiviDemandeEmbauche === "TYPE_DG_VALIDATION" || context.dernierSuiviDemandeEmbauche === "TYPE_IA_RECTIFICATION_FOR_ADMIN") {
                         return (
                              <div className={"d-flex justify-content-center"}>
                                   <MyTooltip title={"Demander une rectification"}>
                                        <button
                                             className={"btn btn-sm btn-warning p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.demandeRectifParSiege(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-hand-point-right"} />
                                        </button>
                                   </MyTooltip>
                                   <MyTooltip title={"Valider"}>
                                        <button
                                             className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"}
                                             onClick={() =>
                                                  setValidationSiegeInModal({
                                                       modalTitle: <MySimpleSpinner size={"sm"} />,
                                                       isModalFullScreen: false,
                                                  })
                                             }
                                        >
                                             <i className={"fas fa-thumbs-up"} />
                                        </button>
                                   </MyTooltip>
                                   <MyTooltip title={"Télécharger les documents"}>
                                        <button className={"btn btn-sm btn-dark p-0 px-3 py-2 ms-2"} onClick={() => actions.downloadDocs(context.consultant)}>
                                             <i className={"fas fa-download"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         )
                    }
               }

               if (isUserFromDirection) {
                    if (
                         ["TYPE_IA_DEMANDE_EMBAUCHE", "TYPE_IARESP_VALIDATION", "TYPE_IA_RECTIFICATION_FOR_DG", "TYPE_IA_RECTIFICATION_FOR_IARESP"].find(
                              item_ => item_ === context.dernierSuiviDemandeEmbauche
                         )
                    ) {
                         return (
                              <div className={"d-flex justify-content-center"}>
                                   <MyTooltip title={"Rejeter définitivement"}>
                                        <button
                                             className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.rejetDefinitifParDirection(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-thumbs-down"} />
                                        </button>
                                   </MyTooltip>
                                   <MyTooltip title={"Demander une rectification"}>
                                        <button
                                             className={"btn btn-sm btn-warning p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.demandeRectifParDirection(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-hand-point-right"} />
                                        </button>
                                   </MyTooltip>
                                   <MyTooltip title={"Valider"}>
                                        <button
                                             className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.validationDirection(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-thumbs-up"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         )
                    }
               }

               if (isUserResponsableOfResponsable) {
                    if (context.dernierSuiviDemandeEmbauche === "TYPE_IA_DEMANDE_EMBAUCHE" || context.dernierSuiviDemandeEmbauche === "TYPE_IA_RECTIFICATION_FOR_IARESP") {
                         return (
                              <div className={"d-flex justify-content-center"}>
                                   <MyTooltip title={"Rejeter définitivement"}>
                                        <button
                                             className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.rejetDefinitifParResponsable(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-thumbs-down"} />
                                        </button>
                                   </MyTooltip>
                                   <MyTooltip title={"Demander une rectification"}>
                                        <button
                                             className={"btn btn-sm btn-warning p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.demandeRectifParResponsable(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-hand-point-right"} />
                                        </button>
                                   </MyTooltip>
                                   <MyTooltip title={"Valider"}>
                                        <button className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"}>
                                             <i className={"fas fa-thumbs-up"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         )
                    }
               }

               if (isUserResponsable) {
                    if (context.dernierSuiviDemandeEmbauche === "TYPE_BROUILLON") {
                         return (
                              <div className={"d-flex justify-content-center"}>
                                   <button
                                        className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2 d-flex align-items-center"}
                                        onClick={() => actions.soumission(() => navigate("/recrutement/demandes-embauche/listing"))}
                                   >
                                        Terminer et Envoyer <i className={"fas fa-paper-plane ms-1"} />
                                   </button>
                                   <MyTooltip title={"Supprimer la demande"}>
                                        <button
                                             className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.deleteDemande(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-trash"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         )
                    }

                    if (
                         context.dernierSuiviDemandeEmbauche === "TYPE_IARESP_DEMANDE_RECTIFICATION" ||
                         context.dernierSuiviDemandeEmbauche === "TYPE_DG_DEMANDE_RECTIFICATION" ||
                         context.dernierSuiviDemandeEmbauche === "TYPE_ADMIN_DEMANDE_RECTIFICATION"
                    ) {
                         return (
                              <div className={"d-flex justify-content-center"}>
                                   <button
                                        className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2 d-flex align-items-center"}
                                        onClick={() => actions.soumission(() => navigate("/recrutement/demandes-embauche/listing"))}
                                   >
                                        Terminer et Envoyer <i className={"fas fa-paper-plane ms-1"} />
                                   </button>
                              </div>
                         )
                    }

                    if (context.dernierSuiviDemandeEmbauche === "TYPE_IARESP_REJET_DEFINITIF" || context.dernierSuiviDemandeEmbauche === "TYPE_DG_REJET_DEFINITIF") {
                         return (
                              <div className={"d-flex justify-content-center"}>
                                   <MyTooltip title={"Supprimer la demande"}>
                                        <button
                                             className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"}
                                             onClick={() => actions.deleteDemande(() => navigate("/recrutement/demandes-embauche/listing"))}
                                        >
                                             <i className={"fas fa-trash"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         )
                    }
               }

               return <></>
          }

          return (
               <>
                    <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                         <MyCard.Body>
                              {/* CV */}
                              <div className={`row mb-6`}>
                                   <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                        <span className={"me-2"}>CV</span>
                                        {candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV) ? (
                                             <i className={"fas fa-check-circle text-success"} />
                                        ) : (
                                             <i className={"fas fa-exclamation-circle text-warning"} />
                                        )}
                                   </label>
                                   <div className="col-md-8 d-flex form-group">
                                        {candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV) ? (
                                             <>
                                                  <button
                                                       className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                       onClick={() => refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_CV]?.current?.click()}
                                                  >
                                                       Remplacer <i className={"fas fa-sync ms-1"} />
                                                  </button>
                                                  <input
                                                       type="file"
                                                       accept={"application/pdf, image/*"}
                                                       className={"d-none"}
                                                       ref={refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_CV]}
                                                       onChange={e =>
                                                            handleSetConsultantDocument(
                                                                 e,
                                                                 candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)?.id as number,
                                                                 CONST_CANDIDAT_DOCUMENT_TYPE_CV
                                                            )
                                                       }
                                                  />
                                                  <button
                                                       className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                       onClick={() =>
                                                            setActiveDriveDocumentInModal({
                                                                 isShown: true,
                                                                 isLoading: true,
                                                                 driveID: candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)?.googleDriveID as string,
                                                                 modalTitle: candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)?.typeLabel as string,
                                                                 driveMimeType: candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)
                                                                      ?.googleDriveMimeType as string as string,
                                                            })
                                                       }
                                                  >
                                                       Voir <i className="fas fa-eye ms-1" />
                                                  </button>
                                                  <button
                                                       className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                       onClick={() =>
                                                            handleRemoveConsultantDocument(
                                                                 candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)?.id as number,
                                                                 CONST_CANDIDAT_DOCUMENT_TYPE_CV
                                                            )
                                                       }
                                                  >
                                                       Supprimer <i className={"fas fa-trash ms-1"} />
                                                  </button>
                                             </>
                                        ) : (
                                             <input type={"file"} className={"form-control"} onChange={e => handleAddConsultantDocument(e, CONST_CANDIDAT_DOCUMENT_TYPE_CV)} />
                                        )}
                                   </div>
                              </div>

                              {/* DoCo */}
                              <div className={`row mb-6`}>
                                   <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                        <span className={"me-2"}>Dossier de compétences</span>
                                        {candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO) ? (
                                             <i className={"fas fa-check-circle text-success"} />
                                        ) : (
                                             <i className={"fas fa-exclamation-circle text-warning"} />
                                        )}
                                   </label>
                                   <div className="col-md-8 d-flex form-group">
                                        {candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO) ? (
                                             <>
                                                  <button
                                                       className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                       onClick={() => refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_DOCO]?.current?.click()}
                                                  >
                                                       Remplacer <i className={"fas fa-sync ms-1"} />
                                                  </button>
                                                  <input
                                                       type="file"
                                                       accept={"application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"}
                                                       className={"d-none"}
                                                       ref={refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_DOCO]}
                                                       onChange={e =>
                                                            handleSetConsultantDocument(
                                                                 e,
                                                                 candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO)?.id as number,
                                                                 CONST_CANDIDAT_DOCUMENT_TYPE_DOCO
                                                            )
                                                       }
                                                  />
                                                  <button
                                                       className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                       onClick={() =>
                                                            setActiveDriveDocumentInModal({
                                                                 isShown: true,
                                                                 isLoading: true,
                                                                 driveID: candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO)?.googleDriveID as string,
                                                                 modalTitle: candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO)?.typeLabel as string,
                                                                 driveMimeType: candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO)
                                                                      ?.googleDriveMimeType as string as string,
                                                            })
                                                       }
                                                  >
                                                       Voir <i className="fas fa-eye ms-1" />
                                                  </button>
                                                  <button
                                                       className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                       onClick={() =>
                                                            handleRemoveConsultantDocument(
                                                                 candidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO)?.id as number,
                                                                 CONST_CANDIDAT_DOCUMENT_TYPE_DOCO
                                                            )
                                                       }
                                                  >
                                                       Supprimer <i className={"fas fa-trash ms-1"} />
                                                  </button>
                                             </>
                                        ) : (
                                             <input type={"file"} className={"form-control"} onChange={e => handleAddConsultantDocument(e, CONST_CANDIDAT_DOCUMENT_TYPE_DOCO)} />
                                        )}
                                   </div>
                              </div>

                              {consultant.contrat !== "CONTRAT_ST" && (
                                   <>
                                        {/* CNI / PASSEPORT */}
                                        <div className={`row mb-6`}>
                                             <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                                  <span className={"me-2"}>Passeport ou carte d'identité</span>
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI) ? (
                                                       <i className={"fas fa-check-circle text-success"} />
                                                  ) : (
                                                       <i className={"fas fa-exclamation-circle text-warning"} />
                                                  )}
                                             </label>
                                             <div className="col-md-8 d-flex form-group">
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI) ? (
                                                       <>
                                                            <button
                                                                 className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI]?.current?.click()}
                                                            >
                                                                 Remplacer <i className={"fas fa-sync ms-1"} />
                                                            </button>
                                                            <input
                                                                 type="file"
                                                                 accept={"application/pdf, image/*"}
                                                                 className={"d-none"}
                                                                 ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI]}
                                                                 onChange={e =>
                                                                      handleSetConsultantDocument(
                                                                           e,
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)
                                                                                ?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI
                                                                      )
                                                                 }
                                                            />
                                                            <button
                                                                 className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           isShown: true,
                                                                           isLoading: true,
                                                                           driveID: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)
                                                                                ?.googleDriveID as string,
                                                                           modalTitle: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)
                                                                                ?.typeLabel as string,
                                                                           driveMimeType: consultantDocuments.find(
                                                                                item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI
                                                                           )?.googleDriveMimeType as string,
                                                                      })
                                                                 }
                                                            >
                                                                 Voir <i className="fas fa-eye ms-1" />
                                                            </button>
                                                            <button
                                                                 className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                 onClick={() =>
                                                                      handleRemoveConsultantDocument(
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)
                                                                                ?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI
                                                                      )
                                                                 }
                                                            >
                                                                 Supprimer <i className={"fas fa-trash ms-1"} />
                                                            </button>
                                                       </>
                                                  ) : (
                                                       <input
                                                            type={"file"}
                                                            className={"form-control"}
                                                            onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)}
                                                       />
                                                  )}
                                             </div>
                                        </div>

                                        {/* Titre de séjour */}
                                        {consultant.nationalite?.code !== CONST_PAYS_CODE_FRANCE && (
                                             <div className={`row mb-6`}>
                                                  <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                                       <span className={"me-2"}>Titre de séjour</span>
                                                       {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR) ? (
                                                            <i className={"fas fa-check-circle text-success"} />
                                                       ) : (
                                                            <i className={"fas fa-exclamation-circle text-warning"} />
                                                       )}
                                                  </label>
                                                  <div className="col-md-8 d-flex form-group">
                                                       {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR) ? (
                                                            <>
                                                                 <button
                                                                      className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR]?.current?.click()}
                                                                 >
                                                                      Remplacer <i className={"fas fa-sync ms-1"} />
                                                                 </button>
                                                                 <input
                                                                      type="file"
                                                                      accept={"application/pdf, image/*"}
                                                                      className={"d-none"}
                                                                      ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR]}
                                                                      onChange={e =>
                                                                           handleSetConsultantDocument(
                                                                                e,
                                                                                consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                                                                                     ?.id as number,
                                                                                CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR
                                                                           )
                                                                      }
                                                                 />
                                                                 <button
                                                                      className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() =>
                                                                           setActiveDriveDocumentInModal({
                                                                                isShown: true,
                                                                                isLoading: true,
                                                                                driveID: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                                                                                     ?.googleDriveID as string,
                                                                                modalTitle: consultantDocuments.find(
                                                                                     item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR
                                                                                )?.typeLabel as string,
                                                                                driveMimeType: consultantDocuments.find(
                                                                                     item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR
                                                                                )?.googleDriveMimeType as string,
                                                                           })
                                                                      }
                                                                 >
                                                                      Voir <i className="fas fa-eye ms-1" />
                                                                 </button>
                                                                 <button
                                                                      className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                      onClick={() =>
                                                                           handleRemoveConsultantDocument(
                                                                                consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                                                                                     ?.id as number,
                                                                                CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR
                                                                           )
                                                                      }
                                                                 >
                                                                      Supprimer <i className={"fas fa-trash ms-1"} />
                                                                 </button>
                                                            </>
                                                       ) : (
                                                            <input
                                                                 type={"file"}
                                                                 className={"form-control"}
                                                                 onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)}
                                                            />
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Attestation de Sécurité Sociale */}
                                        <div className={`row mb-6`}>
                                             <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                                  <span className={"me-2"}>Attestation de Sécurité Sociale</span>
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS) ? (
                                                       <i className={"fas fa-check-circle text-success"} />
                                                  ) : (
                                                       <i className={"fas fa-exclamation-circle text-warning"} />
                                                  )}
                                             </label>
                                             <div className="col-md-8 d-flex form-group">
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS) ? (
                                                       <>
                                                            <button
                                                                 className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS]?.current?.click()}
                                                            >
                                                                 Remplacer <i className={"fas fa-sync ms-1"} />
                                                            </button>
                                                            <input
                                                                 type="file"
                                                                 accept={"application/pdf, image/*"}
                                                                 className={"d-none"}
                                                                 ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS]}
                                                                 onChange={e =>
                                                                      handleSetConsultantDocument(
                                                                           e,
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)
                                                                                ?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS
                                                                      )
                                                                 }
                                                            />
                                                            <button
                                                                 className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           isShown: true,
                                                                           isLoading: true,
                                                                           driveID: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)
                                                                                ?.googleDriveID as string,
                                                                           modalTitle: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)
                                                                                ?.typeLabel as string,
                                                                           driveMimeType: consultantDocuments.find(
                                                                                item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS
                                                                           )?.googleDriveMimeType as string,
                                                                      })
                                                                 }
                                                            >
                                                                 Voir <i className="fas fa-eye ms-1" />
                                                            </button>
                                                            <button
                                                                 className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                 onClick={() =>
                                                                      handleRemoveConsultantDocument(
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)
                                                                                ?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS
                                                                      )
                                                                 }
                                                            >
                                                                 Supprimer <i className={"fas fa-trash ms-1"} />
                                                            </button>
                                                       </>
                                                  ) : (
                                                       <input
                                                            type={"file"}
                                                            className={"form-control"}
                                                            onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)}
                                                       />
                                                  )}
                                             </div>
                                        </div>

                                        {/* RIB */}
                                        <div className={`row mb-6`}>
                                             <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                                  <span className={"me-2"}>Relevé d'Identité Bancaire (RIB)</span>
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB) ? (
                                                       <i className={"fas fa-check-circle text-success"} />
                                                  ) : (
                                                       <i className={"fas fa-exclamation-circle text-warning"} />
                                                  )}
                                             </label>
                                             <div className="col-md-8 d-flex form-group">
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB) ? (
                                                       <>
                                                            <button
                                                                 className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_RIB]?.current?.click()}
                                                            >
                                                                 Remplacer <i className={"fas fa-sync ms-1"} />
                                                            </button>
                                                            <input
                                                                 type="file"
                                                                 accept={"application/pdf, image/*"}
                                                                 className={"d-none"}
                                                                 ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_RIB]}
                                                                 onChange={e =>
                                                                      handleSetConsultantDocument(
                                                                           e,
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_RIB
                                                                      )
                                                                 }
                                                            />
                                                            <button
                                                                 className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           isShown: true,
                                                                           isLoading: true,
                                                                           driveID: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)
                                                                                ?.googleDriveID as string,
                                                                           modalTitle: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)
                                                                                ?.typeLabel as string,
                                                                           driveMimeType: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)
                                                                                ?.googleDriveMimeType as string,
                                                                      })
                                                                 }
                                                            >
                                                                 Voir <i className="fas fa-eye ms-1" />
                                                            </button>
                                                            <button
                                                                 className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                 onClick={() =>
                                                                      handleRemoveConsultantDocument(
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_RIB
                                                                      )
                                                                 }
                                                            >
                                                                 Supprimer <i className={"fas fa-trash ms-1"} />
                                                            </button>
                                                       </>
                                                  ) : (
                                                       <input
                                                            type={"file"}
                                                            className={"form-control"}
                                                            onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_RIB)}
                                                       />
                                                  )}
                                             </div>
                                        </div>

                                        {/* PERMIS DE CONDUIRE */}
                                        <div className={`row mb-6`}>
                                             <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                                  <span className={"me-2"}>Permis de conduire</span>
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE) ? (
                                                       <i className={"fas fa-check-circle text-success"} />
                                                  ) : (
                                                       <i className={"fas fa-exclamation-circle text-warning"} />
                                                  )}
                                             </label>
                                             <div className="col-md-8 d-flex form-group">
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE) ? (
                                                       <>
                                                            <button
                                                                 className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE]?.current?.click()}
                                                            >
                                                                 Remplacer <i className={"fas fa-sync ms-1"} />
                                                            </button>
                                                            <input
                                                                 type="file"
                                                                 accept={"application/pdf, image/*"}
                                                                 className={"d-none"}
                                                                 ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE]}
                                                                 onChange={e =>
                                                                      handleSetConsultantDocument(
                                                                           e,
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)
                                                                                ?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE
                                                                      )
                                                                 }
                                                            />
                                                            <button
                                                                 className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           isShown: true,
                                                                           isLoading: true,
                                                                           driveID: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)
                                                                                ?.googleDriveID as string,
                                                                           modalTitle: consultantDocuments.find(
                                                                                item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE
                                                                           )?.typeLabel as string,
                                                                           driveMimeType: consultantDocuments.find(
                                                                                item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE
                                                                           )?.googleDriveMimeType as string,
                                                                      })
                                                                 }
                                                            >
                                                                 Voir <i className="fas fa-eye ms-1" />
                                                            </button>
                                                            <button
                                                                 className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                 onClick={() =>
                                                                      handleRemoveConsultantDocument(
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)
                                                                                ?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE
                                                                      )
                                                                 }
                                                            >
                                                                 Supprimer <i className={"fas fa-trash ms-1"} />
                                                            </button>
                                                       </>
                                                  ) : (
                                                       <input
                                                            type={"file"}
                                                            className={"form-control"}
                                                            onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)}
                                                       />
                                                  )}
                                             </div>
                                        </div>

                                        {/* Diplomes */}
                                        <>
                                             <h4 className={"mt-8 mb-4"}>Diplômes</h4>
                                             <div className="separator separator-dashed mb-6" />

                                             {/* Diplôme 1 */}
                                             <div className="row mb-6">
                                                  <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">Diplôme 1</label>
                                                  <div className="col-md-8 d-flex">
                                                       {consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME).length > 0 ? (
                                                            <>
                                                                 <button
                                                                      className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][0]?.current?.click()}
                                                                 >
                                                                      Remplacer <i className={"fas fa-sync ms-1"} />
                                                                 </button>
                                                                 <input
                                                                      type="file"
                                                                      accept={"application/pdf, image/*"}
                                                                      className={"d-none"}
                                                                      ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][0]}
                                                                      onChange={e => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           handleSetConsultantDocument(e, diplomes[0]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                      }}
                                                                 />
                                                                 <button
                                                                      className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           setActiveDriveDocumentInModal({
                                                                                isShown: true,
                                                                                isLoading: true,
                                                                                driveID: diplomes[0].googleDriveID as string,
                                                                                modalTitle: "Diplôme 1",
                                                                                driveMimeType: diplomes[0]?.googleDriveMimeType as string,
                                                                           })
                                                                      }}
                                                                 >
                                                                      Voir <i className="fas fa-eye ms-1" />
                                                                 </button>
                                                                 <button
                                                                      className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                      onClick={() => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           handleRemoveConsultantDocument(diplomes[0]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                      }}
                                                                 >
                                                                      Supprimer <i className={"fas fa-trash ms-1"} />
                                                                 </button>
                                                            </>
                                                       ) : (
                                                            <input
                                                                 type={"file"}
                                                                 className={"form-control"}
                                                                 onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)}
                                                            />
                                                       )}
                                                  </div>
                                             </div>

                                             {/* Diplôme 2 */}
                                             <div className="row mb-6">
                                                  <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">Diplôme 2</label>
                                                  <div className="col-md-8 d-flex">
                                                       {consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME).length > 1 ? (
                                                            <>
                                                                 <button
                                                                      className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][1]?.current?.click()}
                                                                 >
                                                                      Remplacer <i className={"fas fa-sync ms-1"} />
                                                                 </button>
                                                                 <input
                                                                      type="file"
                                                                      accept={"application/pdf, image/*"}
                                                                      className={"d-none"}
                                                                      ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][1]}
                                                                      onChange={e => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           handleSetConsultantDocument(e, diplomes[1]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                      }}
                                                                 />
                                                                 <button
                                                                      className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           setActiveDriveDocumentInModal({
                                                                                isShown: true,
                                                                                isLoading: true,
                                                                                driveID: diplomes[1].googleDriveID as string,
                                                                                modalTitle: "Diplôme 2",
                                                                                driveMimeType: diplomes[1]?.googleDriveMimeType as string,
                                                                           })
                                                                      }}
                                                                 >
                                                                      Voir <i className="fas fa-eye ms-1" />
                                                                 </button>
                                                                 <button
                                                                      className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                      onClick={() => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           handleRemoveConsultantDocument(diplomes[1]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                      }}
                                                                 >
                                                                      Supprimer <i className={"fas fa-trash ms-1"} />
                                                                 </button>
                                                            </>
                                                       ) : (
                                                            <input
                                                                 type={"file"}
                                                                 className={"form-control"}
                                                                 onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)}
                                                            />
                                                       )}
                                                  </div>
                                             </div>

                                             {/* Diplôme 3 */}
                                             <div className="row mb-6">
                                                  <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">Diplôme 3</label>
                                                  <div className="col-md-8 d-flex">
                                                       {consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME).length > 2 ? (
                                                            <>
                                                                 <button
                                                                      className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][2]?.current?.click()}
                                                                 >
                                                                      Remplacer <i className={"fas fa-sync ms-1"} />
                                                                 </button>
                                                                 <input
                                                                      type="file"
                                                                      accept={"application/pdf, image/*"}
                                                                      className={"d-none"}
                                                                      ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][2]}
                                                                      onChange={e => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           handleSetConsultantDocument(e, diplomes[2]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                      }}
                                                                 />
                                                                 <button
                                                                      className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                      onClick={() => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           setActiveDriveDocumentInModal({
                                                                                isShown: true,
                                                                                isLoading: true,
                                                                                driveID: diplomes[2].googleDriveID as string,
                                                                                modalTitle: "Diplôme 3",
                                                                                driveMimeType: diplomes[2]?.googleDriveMimeType as string,
                                                                           })
                                                                      }}
                                                                 >
                                                                      Voir <i className="fas fa-eye ms-1" />
                                                                 </button>
                                                                 <button
                                                                      className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                      onClick={() => {
                                                                           const diplomes = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                           handleRemoveConsultantDocument(diplomes[2]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)
                                                                      }}
                                                                 >
                                                                      Supprimer <i className={"fas fa-trash ms-1"} />
                                                                 </button>
                                                            </>
                                                       ) : (
                                                            <input
                                                                 type={"file"}
                                                                 className={"form-control"}
                                                                 onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)}
                                                            />
                                                       )}
                                                  </div>
                                             </div>
                                        </>
                                   </>
                              )}

                              {consultant.contrat === "CONTRAT_ST" && (
                                   <>
                                        {/* Contrat */}
                                        <div className={`row mb-6`}>
                                             <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                                  <span className={"me-2"}>Contrat</span>
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT) ? (
                                                       <i className={"fas fa-check-circle text-success"} />
                                                  ) : (
                                                       <i className={"fas fa-exclamation-circle text-warning"} />
                                                  )}
                                             </label>
                                             <div className="col-md-8 d-flex form-group">
                                                  {consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT) ? (
                                                       <>
                                                            <button
                                                                 className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT]?.current?.click()}
                                                            >
                                                                 Remplacer <i className={"fas fa-sync ms-1"} />
                                                            </button>
                                                            <input
                                                                 type="file"
                                                                 accept={"application/pdf, image/*"}
                                                                 className={"d-none"}
                                                                 ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT]}
                                                                 onChange={e =>
                                                                      handleSetConsultantDocument(
                                                                           e,
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT)?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT
                                                                      )
                                                                 }
                                                            />
                                                            <button
                                                                 className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           isShown: true,
                                                                           isLoading: true,
                                                                           driveID: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT)
                                                                                ?.googleDriveID as string,
                                                                           modalTitle: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT)
                                                                                ?.typeLabel as string,
                                                                           driveMimeType: consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT)
                                                                                ?.googleDriveMimeType as string,
                                                                      })
                                                                 }
                                                            >
                                                                 Voir <i className="fas fa-eye ms-1" />
                                                            </button>
                                                            <button
                                                                 className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                                 onClick={() =>
                                                                      handleRemoveConsultantDocument(
                                                                           consultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT)?.id as number,
                                                                           CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT
                                                                      )
                                                                 }
                                                            >
                                                                 Supprimer <i className={"fas fa-trash ms-1"} />
                                                            </button>
                                                       </>
                                                  ) : (
                                                       <input
                                                            type={"file"}
                                                            className={"form-control"}
                                                            onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT)}
                                                       />
                                                  )}
                                             </div>
                                        </div>

                                        <div className={"p-4 border border-dashed text-muted mb-4"}>
                                             <h4 className={"mb-4 text-muted"}>
                                                  Fournisseur
                                                  <i className="fas fa-info-circle fs-3 float-end" />
                                             </h4>
                                             <div className="separator separator-dashed mb-6" />

                                             <FournisseurDocuments fournisseur_ID={documentsQuery.data.st!.fournisseur!.id as number} />
                                        </div>
                                   </>
                              )}

                              {/* AUTRES */}
                              <>
                                   <h4 className={"mt-8 mb-4"}>Autres</h4>
                                   <div className="separator separator-dashed mb-6" />

                                   {/* Auter 1 */}
                                   <div className="row mb-6">
                                        <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">Autre 1</label>
                                        <div className="col-md-8 d-flex">
                                             {consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES).length > 0 ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][0]?.current?.click()}
                                                       >
                                                            Remplacer <i className={"fas fa-sync ms-1"} />
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][0]}
                                                            onChange={e => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 handleSetConsultantDocument(e, autres[0]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                            }}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                            onClick={() => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 setActiveDriveDocumentInModal({
                                                                      isShown: true,
                                                                      isLoading: true,
                                                                      driveID: autres[0].googleDriveID as string,
                                                                      modalTitle: "Autre 1",
                                                                      driveMimeType: autres[0]?.googleDriveMimeType as string,
                                                                 })
                                                            }}
                                                       >
                                                            Voir <i className="fas fa-eye ms-1" />
                                                       </button>
                                                       <button
                                                            className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                            onClick={() => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 handleRemoveConsultantDocument(autres[0]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                            }}
                                                       >
                                                            Supprimer <i className={"fas fa-trash ms-1"} />
                                                       </button>
                                                  </>
                                             ) : (
                                                  <input
                                                       type={"file"}
                                                       className={"form-control"}
                                                       onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)}
                                                  />
                                             )}
                                        </div>
                                   </div>

                                   {/* Auter 2 */}
                                   <div className="row mb-6">
                                        <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">Autre 2</label>
                                        <div className="col-md-8 d-flex">
                                             {consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES).length > 1 ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][1]?.current?.click()}
                                                       >
                                                            Remplacer <i className={"fas fa-sync ms-1"} />
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][1]}
                                                            onChange={e => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 handleSetConsultantDocument(e, autres[1]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                            }}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                            onClick={() => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 setActiveDriveDocumentInModal({
                                                                      isShown: true,
                                                                      isLoading: true,
                                                                      driveID: autres[1].googleDriveID as string,
                                                                      modalTitle: "Autre 2",
                                                                      driveMimeType: autres[1]?.googleDriveMimeType as string,
                                                                 })
                                                            }}
                                                       >
                                                            Voir <i className="fas fa-eye ms-1" />
                                                       </button>
                                                       <button
                                                            className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                            onClick={() => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 handleRemoveConsultantDocument(autres[1]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                            }}
                                                       >
                                                            Supprimer <i className={"fas fa-trash ms-1"} />
                                                       </button>
                                                  </>
                                             ) : (
                                                  <input
                                                       type={"file"}
                                                       className={"form-control"}
                                                       onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)}
                                                  />
                                             )}
                                        </div>
                                   </div>

                                   {/* Auter 3 */}
                                   <div className="row mb-6">
                                        <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">Autre 3</label>
                                        <div className="col-md-8 d-flex">
                                             {consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES).length > 2 ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][2]?.current?.click()}
                                                       >
                                                            Remplacer <i className={"fas fa-sync ms-1"} />
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][2]}
                                                            onChange={e => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 handleSetConsultantDocument(e, autres[2]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                            }}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                            onClick={() => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 setActiveDriveDocumentInModal({
                                                                      isShown: true,
                                                                      isLoading: true,
                                                                      driveID: autres[2].googleDriveID as string,
                                                                      modalTitle: "Autre 3",
                                                                      driveMimeType: autres[2]?.googleDriveMimeType as string,
                                                                 })
                                                            }}
                                                       >
                                                            Voir <i className="fas fa-eye ms-1" />
                                                       </button>
                                                       <button
                                                            className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                            onClick={() => {
                                                                 const autres = consultantDocuments.filter(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                                 handleRemoveConsultantDocument(autres[2]?.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)
                                                            }}
                                                       >
                                                            Supprimer <i className={"fas fa-trash ms-1"} />
                                                       </button>
                                                  </>
                                             ) : (
                                                  <input
                                                       type={"file"}
                                                       className={"form-control"}
                                                       onChange={e => handleAddConsultantDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)}
                                                  />
                                             )}
                                        </div>
                                   </div>

                                   <div className="separator bg-primary my-5" />

                                   <div className="d-flex justify-content-between">
                                        <button
                                             className={"btn btn-sm btn-warning d-inline-flex align-items-center"}
                                             type={"button"}
                                             onClick={() => {
                                                  if (consultant.contrat === CONST_CONSULTANT_CONTRAT_ST) {
                                                       context.setNavigationStep("client")
                                                  } else {
                                                       if (consultant.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION) {
                                                            context.setNavigationStep("odm")
                                                       } else {
                                                            context.setNavigationStep("client")
                                                       }
                                                  }
                                             }}
                                        >
                                             <span className={"fas fa-arrow-left fs-4 me-1"}></span> Précédant
                                        </button>
                                        <ActionsAccordingToEtatDemandeEmbauche />
                                   </div>
                              </>
                         </MyCard.Body>
                    </MyCard>

                    {activeDriveDocumentInModal && (
                         <MyModal
                              title={<span>{activeDriveDocumentInModal.modalTitle}</span>}
                              show={true}
                              handleClose={() => setActiveDriveDocumentInModal(undefined)}
                              noPadding
                              size={"lg"}
                         >
                              <MyLoadingOverlay isActive={activeDriveDocumentInModal.isLoading}>
                                   <iframe
                                        src={FileAndDriveHelper.getUrlOfDriveFilePreview(activeDriveDocumentInModal.driveID)}
                                        onLoad={() => setActiveDriveDocumentInModal(prev => (prev ? { ...prev, isLoading: false } : prev))}
                                        style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                                   />
                              </MyLoadingOverlay>
                         </MyModal>
                    )}

                    {validationSiegeInModal && (
                         <MyModal
                              title={validationSiegeInModal.modalTitle}
                              show={true}
                              handleClose={() => setValidationSiegeInModal(undefined)}
                              size={"lg"}
                              fullscreen={validationSiegeInModal.isModalFullScreen ? true : undefined}
                              noPadding={validationSiegeInModal.isModalFullScreen}
                         >
                              <DemandeEmbaucheDetailsValidationSiege
                                   consultant_ID={context.consultant.id as number}
                                   onContractLoadCallback={val => {
                                        const title = val ? (
                                             <div className={"d-flex"}>
                                                  Complétion du contrat
                                                  <button
                                                       className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"}
                                                       onClick={() => actions.validationSiege(() => navigate("/recrutement/demandes-embauche/listing"))}
                                                  >
                                                       Terminer et valider <i className={"fas fa-thumbs-up ms-1"} />
                                                  </button>
                                             </div>
                                        ) : (
                                             <>Génération / saisie du contrat de travail</>
                                        )
                                        setValidationSiegeInModal(prev => (prev ? { ...prev, modalTitle: title, isModalFullScreen: !!val } : prev))
                                   }}
                                   onSubmitCallback={handleValidationAdministrativeCallback}
                              />
                         </MyModal>
                    )}
               </>
          )
     }

     return <></>
}

export default DemandeEmbaucheDetailsDocuments
