import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import ConnectedUserMaladiesListingItem from "./_Item"
import { toast } from "react-toastify"
import MyModal from "@common-utils/MyModal"
import { IPersonnelArretMaladieModel } from "@common-models/*"
import PersonnelMaladieEditForm from "../../edit/_PersonnelMaladieEditForm"
import { getPersonnelMaladiesRequest } from "../../../../core/_requests"
import { IGetPersonnelMaladiesRequestModel } from "../../../../core/_models"

const PersonnelMaladiesListingContext = createContext<IPersonnelMaladiesListingContextPropsModel>({} as IPersonnelMaladiesListingContextPropsModel)
export const usePersonnelMaladiesListingContext = () => useContext(PersonnelMaladiesListingContext)

export const REACT_QUERY_KEY_GET_PERSONNEL_MALADIES = "REACT_QUERY_KEY_GET_PERSONNEL_MALADIES"
const PersonnelMaladieListingForOne = ({ personnel_ID, filtres = {}, editionMode = true, setIsDataLoaded }: IPersonnelMaladiesListingPropsModel) => {
     const queryClient = useQueryClient()

     const [maladieEditionInModal, setMaladieEditionInModal] = useState<IPersonnelArretMaladieModel>()

     const getMaladiesListingQuery = useQuery<IPersonnelArretMaladieModel[], AxiosError>(REACT_QUERY_KEY_GET_PERSONNEL_MALADIES, () =>
          getPersonnelMaladiesRequest(personnel_ID, filtres).then(r => {
               if (setIsDataLoaded) setIsDataLoaded(true)
               return r.data
          })
     )

     function handleEditMaladieCallback(newItem: IPersonnelArretMaladieModel) {
          queryClient.setQueryData<IPersonnelArretMaladieModel[] | undefined>(REACT_QUERY_KEY_GET_PERSONNEL_MALADIES, (prev: IPersonnelArretMaladieModel[] | undefined) => {
               if (prev) {
                    const index = prev.findIndex(item => item.id === maladieEditionInModal?.id)
                    if (index >= 0) {
                         prev[index] = {
                              ...prev[index],
                              ...newItem,
                         }
                    }
               }

               return prev
          })
          setMaladieEditionInModal(undefined)
          toast.success("Votre modification a bien été prise en compte.")
          toast.info(
               "Merci de vérifier, lors de l'étape de Compte Rendu d'Activité (CRA), si les heures de l'absence ainsi que celles de travail pour la période concernée par votre demande sont corrects.",
               {
                    autoClose: false,
               }
          )
     }

     return (
          <>
               <PersonnelMaladiesListingContext.Provider
                    value={{
                         filtres,
                         setMaladieEditionInModal,
                         editionMode,
                         REACT_QUERY_KEY_GET_CONNECTED_USER_MALADIE_LISTING: REACT_QUERY_KEY_GET_PERSONNEL_MALADIES,
                    }}
               >
                    {/* Is fetching */}
                    {getMaladiesListingQuery.isFetching && (
                         <div className={"text-center"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {/* Is error */}
                    {!getMaladiesListingQuery.isFetching && getMaladiesListingQuery.isError && (
                         <MyAlert type={"danger"}>
                              <div className={"d-flex align-items-center"}>
                                   <span className={"me-2"}>{getMaladiesListingQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => getMaladiesListingQuery.refetch()}>
                                        Recharger
                                   </button>
                              </div>
                         </MyAlert>
                    )}

                    {/* Without data */}
                    {!getMaladiesListingQuery.isFetching && !getMaladiesListingQuery.isError && getMaladiesListingQuery.data!.length === 0 && (
                         <MyAlert type={"primary"}>Aucun arrêt n'a été déclaré</MyAlert>
                    )}

                    {/* With data */}
                    {!getMaladiesListingQuery.isFetching && !getMaladiesListingQuery.isError && getMaladiesListingQuery.data && getMaladiesListingQuery.data!.length > 0 && (
                         <>
                              <div className="row">
                                   {getMaladiesListingQuery.data.map((item, key) => (
                                        <div className={`col-md-6 ${key < getMaladiesListingQuery.data.length - 2 && "mb-6"}`} key={key}>
                                             <ConnectedUserMaladiesListingItem item={item} />
                                        </div>
                                   ))}
                              </div>
                         </>
                    )}
               </PersonnelMaladiesListingContext.Provider>

               {/* Edition in modal */}
               {maladieEditionInModal && (
                    <MyModal show={true} handleClose={() => setMaladieEditionInModal(undefined)} title={<>Edition de l'arrêt maladie</>} size={"lg"}>
                         <PersonnelMaladieEditForm
                              maladie={maladieEditionInModal}
                              du={filtres?.du}
                              au={filtres?.au}
                              duOrAuWithThisMonthAndYear={filtres?.duOrAuWithThisMonthAndYear}
                              callback={handleEditMaladieCallback}
                         />
                    </MyModal>
               )}
          </>
     )
}

interface IPersonnelMaladiesListingPropsModel {
     personnel_ID: number
     editionMode?: boolean
     filtres?: IGetPersonnelMaladiesRequestModel
     maxHeight?: string
     setIsDataLoaded?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IPersonnelMaladiesListingContextPropsModel {
     editionMode?: boolean
     filtres?: IGetPersonnelMaladiesRequestModel
     setMaladieEditionInModal: Dispatch<SetStateAction<IPersonnelArretMaladieModel | undefined>>
     REACT_QUERY_KEY_GET_CONNECTED_USER_MALADIE_LISTING: string
}

export { PersonnelMaladieListingForOne }
