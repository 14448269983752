import React, { createContext, Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react"
import { useQuery, UseQueryResult } from "react-query"
import { ILogsSearchCandidateRequestModel, ILogsSearchCandidateResponseModel } from "./core/_models"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { IHttpErrorResponseModel } from "@common-models/*"
import LogsSearchCandidateList from "./LogsSearchCandidateList"
import LogsSearchCandidateFilters from "./LogsSearchCandidateFilters"
import LogsSearchCandidateSimpleSearchBar from "./LogsSearchCandidateSimpleSearchBar"
import { getLogsSearchCandidate } from "./core/_requests"

// Wrapper's context
interface ILogsSearchCandidateContextPropsModel {
     areFiltersVisible: boolean
     setAreFiltersVisible: Dispatch<SetStateAction<boolean>>
     filters: ILogsSearchCandidateRequestModel
     setFilters: Dispatch<SetStateAction<ILogsSearchCandidateRequestModel>>
     useLogsQuery: UseQueryResult<ILogsSearchCandidateResponseModel, AxiosError>
     filtersFormikRef: MutableRefObject<any>
     simpleSearchBarFormikRef: MutableRefObject<any>
     INITIAL_FILTERS_STATE: ILogsSearchCandidateRequestModel
}

const logsSearchCandidateContext = createContext<ILogsSearchCandidateContextPropsModel>({} as ILogsSearchCandidateContextPropsModel)
export const useLogsSearchCandidateListeContext = () => useContext(logsSearchCandidateContext)

// Wrapper
const REACT_QUERY_KEY_LOGS = "REACT_QUERY_KEY_LOGS"
const INITIAL_FILTERS_STATE: ILogsSearchCandidateRequestModel = {
     page: 1,
     pageLength: 20,
}
const LogsSearchCandidate = () => {
     // Referencing both filters form and simple search bar form
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)

     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [filters, setFilters] = useState<ILogsSearchCandidateRequestModel>(INITIAL_FILTERS_STATE)

     // Main listing query
     const useLogsQuery = useQuery<ILogsSearchCandidateResponseModel, AxiosError>([REACT_QUERY_KEY_LOGS, filters], () => {
          return getLogsSearchCandidate(filters)
               .then(r => {
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error.errors && error.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    toast.error(error?.detail, { autoClose: false })

                    throw new Error("Something happened")
               })
     })

     // Re-fetch main listing query whenever filters change
     useEffect(() => {
          useLogsQuery.refetch().then()
     }, [filters])

     return (
          <logsSearchCandidateContext.Provider
               value={{
                    areFiltersVisible,
                    setAreFiltersVisible,
                    filters,
                    setFilters,
                    useLogsQuery,
                    filtersFormikRef,
                    simpleSearchBarFormikRef,
                    INITIAL_FILTERS_STATE,
               }}
          >
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-lg-5 mb-4 mb-lg-0 ${!areFiltersVisible ? "d-none" : ""}`}>
                         <LogsSearchCandidateFilters />
                    </div>
                    <div className="col-lg col-xxl-5">
                         {/* Simple bar search will be shown only if filters are not displayed */}
                         <div className={`mb-2 ${areFiltersVisible ? "d-none" : ""}`}>
                              <LogsSearchCandidateSimpleSearchBar />
                         </div>

                         <LogsSearchCandidateList />
                    </div>
               </div>
          </logsSearchCandidateContext.Provider>
     )
}

export default LogsSearchCandidate
