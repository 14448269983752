import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import { IHttpErrorResponseModel, IPersonnelModel, OffreModel } from "@common-models/*"
import MyCard from "@common-utils/MyCard"
import { changerResponsableAnnoncesRequest } from "../core/_requests"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { useMutation } from "react-query"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT } from "../../../../endpoints"

const AnnoncesActionChangerResponsable = ({ annonces, callback = () => {} }: IAnnoncesActionChangerResponsablePropsModel) => {
     const annonceMutation = useMutation((payload: { ids: number[]; newResponsable: number }) => changerResponsableAnnoncesRequest(payload.ids, payload.newResponsable))
     const [responsable, setResponsable] = useState<number>()

     function handleSubmit() {
          if (responsable) {
               const ids = annonces.map(item => item.id as number)
               annonceMutation
                    .mutateAsync({ ids, newResponsable: responsable as number })
                    .then(r => {
                         toast.success(annonces.length > 1 ? "Les annonces que vous avez choisies ont bien été transférées." : "L'annonce a bien été transférée.")
                         callback(r.data)
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data
                         toast.error(error?.detail, { autoClose: false })
                    })
          } else {
               toast.error("Choisissez un responsable")
          }
     }

     return (
          <>
               <MyCard classNames={"mb-10"}>
                    <MyCard.Header isCollapseIconShown>Annonces concernées ( {annonces.length} )</MyCard.Header>
                    <MyCard.Body>
                         <>
                              {annonces.map(item => (
                                   <div key={item.intitule}>{item.intitule}</div>
                              ))}
                         </>
                    </MyCard.Body>
               </MyCard>

               {/* Responsables */}
               <div className="row mb-5">
                    <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez le nouveau responsable</label>

                    <div className="col-lg-8">
                         <MySelectGetAllDataFromServer
                              name={"responsable"}
                              url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                              value={responsable}
                              onChange={val => setResponsable(val as number)}
                              method={"GET"}
                              payload={{ except_ids: annonces.map(item => item.personnel?.id as number) }}
                              isInvalid={false}
                         />
                    </div>
               </div>

               {/* Submit button */}
               <div className={"d-flex justify-content-end"}>
                    <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"button"} disabled={annonceMutation.isLoading} onClick={handleSubmit}>
                         {!annonceMutation.isLoading ? <>Confirmer le changement</> : <MySimpleSpinner size={"sm"} />}
                    </button>
               </div>
          </>
     )
}

interface IAnnoncesActionChangerResponsablePropsModel {
     annonces: OffreModel[]
     callback?: (val: IPersonnelModel) => void
}

export default AnnoncesActionChangerResponsable
