import React, { useMemo } from "react"
import * as Yup from "yup"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { CONST_API_UTILS_GET_ENTITES_ENDPOINT } from "../../../../endpoints"
import { Form, Formik, FormikHelpers } from "formik"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import MyInputField from "@common-utils/fields/MyInputField"
import MyAlert from "@common-utils/MyAlert"
import { useAuth } from "../../../../AppContext"
import {
     ICandidatDocoGenererEtNotifierCandidatParMailFormPropsModel,
     ICandidatGenererDocoEtNotifierCandidatParMailFormikModel,
     ICandidatGenererDocoEtNotifierCandidatParMailRequestModel,
} from "./core/_models"
import { genererDocoEtNotifierCandidatParMail } from "../core/_requests"

const CandidatGenererDocoEtNotifierCandidatParMailForm = ({ candidat, handleSubmitCallBack }: ICandidatDocoGenererEtNotifierCandidatParMailFormPropsModel) => {
     const { currentUser } = useAuth()

     const schema = Yup.object().shape({
          titreDocument: Yup.string().label(`"Titre du document"`).required(),
          mailDestinataire: Yup.string().email().label(`"Mail destinataire"`).required(),
          entiteDoco: Yup.number().label(`"Entité"`).required(),
     })

     const initialValues: ICandidatGenererDocoEtNotifierCandidatParMailFormikModel = {
          entiteDoco: currentUser?.mainEntite?.id as number,
          mailDestinataire: candidat.email,
          titreDocument: "",
     }

     function handleSubmit(values: ICandidatGenererDocoEtNotifierCandidatParMailFormikModel, helpers: FormikHelpers<ICandidatGenererDocoEtNotifierCandidatParMailFormikModel>) {
          helpers.setStatus(null)
          const payload: ICandidatGenererDocoEtNotifierCandidatParMailRequestModel = {
               titreDocument: values.titreDocument as string,
               mailDestinataire: values.mailDestinataire as string,
               entiteDoco: values.entiteDoco as number,
          }
          genererDocoEtNotifierCandidatParMail(candidat.id, payload)
               .then(r => {
                    toast.success("Le DoCo a bien été généré. Le candidat recevera un courriel contenant les instructions.")
                    handleSubmitCallBack(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Form Suivi
     const FormSuivi = useMemo(() => {
          return (
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {/* format field */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             Titre du DoCo <span className={"text-danger fst-italic"}>( visible par le candidat )</span>
                                        </label>
                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"titreDocument"}
                                                  value={helpers.values.titreDocument}
                                                  isInvalid={!!helpers.errors.titreDocument}
                                                  placeholder={"Titre du DoCo"}
                                                  onChange={value => helpers.setFieldValue("titreDocument", value)}
                                             />
                                             {helpers.errors.titreDocument && <div className="text-danger">{helpers.errors.titreDocument}</div>}
                                        </div>
                                   </div>

                                   {/* mailDestinataire field */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email candidat</label>
                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"mailDestinataire"}
                                                  placeholder={"Mail du candidat"}
                                                  value={helpers.values.mailDestinataire}
                                                  isInvalid={!!helpers.errors.mailDestinataire}
                                                  onChange={value => helpers.setFieldValue("mailDestinataire", value)}
                                             />
                                             {helpers.errors.mailDestinataire && <div className="text-danger">{helpers.errors.mailDestinataire}</div>}
                                        </div>
                                   </div>

                                   {/* entiteDoco field */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité</label>
                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"entiteDoco"}
                                                  value={helpers.values.entiteDoco}
                                                  defaultOptions={[{ value: currentUser?.mainEntite?.id as number, label: currentUser?.mainEntite?.nom as string }]}
                                                  onChange={val => helpers.setFieldValue("entiteDoco", val)}
                                                  url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                  isInvalid={!!helpers.errors.entiteDoco}
                                             />
                                             {helpers.errors.entiteDoco && <div className="text-danger">{helpers.errors.entiteDoco}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? "Générer et envoyer" : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          )
     }, [])

     return (
          <>
               <MyAlert type={"primary"} classNames={"mb-5"}>
                    Après validation, un Doco vierge sera ajouté dans l'espace du candidat et un mail lui sera envoyé.
               </MyAlert>
               {FormSuivi}
          </>
     )
}

export default CandidatGenererDocoEtNotifierCandidatParMailForm
