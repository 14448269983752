import { IConsultantSuiviSalarieChangementEtatToIntercontratRequestModel } from "./core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { consultantSuiviSalarieChangementEtatToIntercontratRequest } from "./core/_requests"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MyTextareaField from "@common-utils/fields/MyTextareaField"
import * as Yup from "yup"
import MyCard from "@common-utils/MyCard"

const ConsultantSuiviSalarieChangementEtatToIntercontrat = ({ consultant, annulerCallback = () => {}, onSubmitCallback = () => {} }: IPropsModel) => {
     // Form schema
     const schema = Yup.object().shape({
          date: Yup.string().label(`"Date"`).required(),
          isRemarqueIntercontrat: Yup.boolean().required(),
          remarque: Yup.string()
               .label(`"Remarque"`)
               .when("isRemarqueIntercontrat", {
                    is: true,
                    then: schema => schema.required(),
               }),
     })

     // Init values
     const initialValues: IConsultantSuiviSalarieChangementEtatToIntercontratRequestModel = {
          isRemarqueIntercontrat: false,
     }

     // Handle submit
     function handleSubmit(
          values: IConsultantSuiviSalarieChangementEtatToIntercontratRequestModel,
          helpers: FormikHelpers<IConsultantSuiviSalarieChangementEtatToIntercontratRequestModel>
     ) {
          helpers.setStatus(null)

          consultantSuiviSalarieChangementEtatToIntercontratRequest(consultant.id as number, values)
               .then(() => {
                    onSubmitCallback()
                    toast.success("Votre action a bien été prise en compte.")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <MyCard>
                    <MyCard.Header>INTERCONTRAT</MyCard.Header>
                    <MyCard.Body>
                         <MyAlert type={"primary"} classNames={"mb-5"}>
                              Une fois en intercontrat, le salarié sera visible par l'ensemble du groupe CTS.
                         </MyAlert>
                         <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                              {helpers => {
                                   return (
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}

                                             {/* Date */}
                                             <div className="row mb-4">
                                                  <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Date de prise d'effet</label>

                                                  <div className="col-lg-8">
                                                       <MyDateDayMonthYearField
                                                            name={"date"}
                                                            value={helpers.values.date}
                                                            onChange={val => helpers.setFieldValue("date", val)}
                                                            isInvalid={!!helpers.errors.date}
                                                            placeholder={"Date de prise d'effet"}
                                                       />
                                                       {helpers.errors.date && <div className={"text-danger"}>{helpers.errors.date}</div>}
                                                  </div>
                                             </div>

                                             {/* Is Remarque */}
                                             <div className="row mb-4">
                                                  <label className={`col-lg-4 col-form-label fw-bold fs-6`}>Souhaitez-vous ajouter une remarque à destination du groupe?</label>

                                                  <div className="col-lg-8 d-flex align-items-center">
                                                       <MyCheckBoxField
                                                            name={"isRemarqueIntercontrat"}
                                                            value={helpers.values.isRemarqueIntercontrat}
                                                            isInvalid={!!helpers.errors.isRemarqueIntercontrat}
                                                            onChange={val => helpers.setFieldValue("isRemarqueIntercontrat", val)}
                                                       />
                                                       {helpers.errors.isRemarqueIntercontrat && <div className={"text-danger"}>{helpers.errors.isRemarqueIntercontrat}</div>}
                                                  </div>
                                             </div>

                                             {/* Remarque */}
                                             {helpers.values.isRemarqueIntercontrat && (
                                                  <div className="row mb-4">
                                                       <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Remarque</label>

                                                       <div className="col-lg-8 d-flex align-items-center">
                                                            <MyTextareaField
                                                                 name={"remarque"}
                                                                 value={helpers.values.remarque}
                                                                 isInvalid={!!helpers.errors.remarque}
                                                                 onChange={val => helpers.setFieldValue("remarque", val)}
                                                                 placeholder={"Remarque"}
                                                            />
                                                       </div>
                                                       {helpers.errors.remarque && <div className={"text-danger"}>{helpers.errors.remarque}</div>}
                                                  </div>
                                             )}

                                             {/* Submit button */}
                                             <div className={"d-flex justify-content-end"}>
                                                  <button
                                                       className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                       type={"button"}
                                                       onClick={annulerCallback}
                                                       disabled={helpers.isSubmitting}
                                                  >
                                                       Annuler
                                                  </button>
                                                  <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? <>Valider</> : <MySimpleSpinner size={"sm"} />}
                                                  </button>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </MyCard.Body>
               </MyCard>
          </>
     )
}

interface IPropsModel {
     consultant: IConsultantModel
     annulerCallback?: () => void
     onSubmitCallback?: () => void
}

export default ConsultantSuiviSalarieChangementEtatToIntercontrat
