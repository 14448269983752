import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"
import PtfCreate from "./ptf/create/PtfCreate"
import PtfListing from "./ptf/listing/PtfListing"
import PtfDetails from "./ptf/details/PtfDetails"
import BonsLivraisonDashboard from "./bl/dashboard/BonsLivraisonDashboard"
import BonsLivraisonManquantsOfGivenMonthListing from "./bl/manquantsOfGivenMonth/BonsLivraisonManquantsOfGivenMonthListing"
import moment from "moment"
import BonsLivraisonNonFacturesOfGivenMonthListing from "./bl/nonFacturesOfGivenMonth/BonsLivraisonNonFacturesOfGivenMonthListing"

const PtfRouting = () => {
     // Page states & URL parameters
     const location = useLocation() as {
          state: {
               mois?: string
          }
     }
     const urlParams = new URLSearchParams(window.location.search)

     return (
          <Routes>
               <Route
                    element={
                         <>
                              <Outlet />
                         </>
                    }
               >
                    <Route
                         path="/ptf"
                         element={
                              <>
                                   <Outlet />
                              </>
                         }
                    >
                         <Route
                              path="create"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ptfBreadCrumbs}>Création</PageTitle>
                                        <PtfCreate />
                                   </>
                              }
                         />
                         <Route
                              path="listing"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ptfBreadCrumbs}>Listing</PageTitle>
                                        <PtfListing />
                                   </>
                              }
                         />
                         <Route
                              path=":ptf_id/details"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ptfBreadCrumbs}>Détails</PageTitle>
                                        <PtfDetails />
                                   </>
                              }
                         />
                    </Route>

                    <Route
                         path="bl"
                         element={
                              <>
                                   <Outlet />
                              </>
                         }
                    >
                         <Route
                              path="dashboard"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={bonsLivraisonBreadCrumbs} />
                                        <BonsLivraisonDashboard />
                                   </>
                              }
                         />

                         <Route
                              path="manquants"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={bonsLivraisonManquantsBreadCrumbs}>{moment(location.state?.mois).format("MMM YYYY")}</PageTitle>
                                        <BonsLivraisonManquantsOfGivenMonthListing />
                                   </>
                              }
                         />

                         <Route
                              path="non-factures"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={bonsLivraisonNonFacturesBreadCrumbs}>{moment(location.state?.mois).format("MMM YYYY")}</PageTitle>
                                        <BonsLivraisonNonFacturesOfGivenMonthListing />
                                   </>
                              }
                         />

                         <Route index element={<Navigate to="manquants/voyants" />} />
                    </Route>

                    {/*Default route*/}
                    <Route index element={<Navigate to="" />} />
               </Route>
          </Routes>
     )
}

const ptfBreadCrumbs: Array<PageLink> = [
     {
          title: "PTF",
          path: "/client/ptf/listing",
     },
]

const bonsLivraisonBreadCrumbs: Array<PageLink> = [
     {
          title: "Bons de livraison",
          path: "/client/bl/dashboard",
     },
]

const bonsLivraisonManquantsBreadCrumbs: Array<PageLink> = [
     ...bonsLivraisonBreadCrumbs,
     {
          title: "manquants",
     },
]
const bonsLivraisonNonFacturesBreadCrumbs: Array<PageLink> = [
     ...bonsLivraisonBreadCrumbs,
     {
          title: "non facturés",
     },
]

export default PtfRouting
