import { createContext, useContext, useState } from "react"
import { IDemandeEmbaucheDetailsContextPropsModel, IGetDemandeEmbaucheInfoForNavigation } from "./core/_models"
import { useLocation, useParams } from "react-router-dom"
import DemandeEmbaucheDetailsNavigationMenu from "./_DemandeEmbaucheDetailsNavigationMenu"
import DemandeEmbaucheDetailsInfoPerso from "./_infoPerso/_DemandeEmbaucheDetailsInfoPerso"
import DemandeEmbaucheDetailsInfoContract from "./_infoContract/_DemandeEmbaucheDetailsInfoContract"
import DemandeEmbaucheDetailsInfoClient from "./_infoClient/_DemandeEmbaucheDetailsInfoClient"
import DemandeEmbaucheDetailsOrdreMission from "./_odm/_DemandeEmbaucheDetailsOrdreMission"
import DemandeEmbaucheDetailsDocuments from "./_documents/_DemandeEmbaucheDetailsDocuments"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { getDemandeEmbaucheInfoForNavigation } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"

const DemandeEmbaucheDetailsContext = createContext<IDemandeEmbaucheDetailsContextPropsModel>({} as IDemandeEmbaucheDetailsContextPropsModel)
export const useDemandeEmbaucheDetailsContext = () => useContext(DemandeEmbaucheDetailsContext)

const REACT_QUERY_KEY_GET_DE_CONTRAT_ETAT_STATUT = "REACT_QUERY_KEY_GET_DE_CONTRAT_ETAT_STATUT"
const DemandeEmbaucheDetails = () => {
     // Navigation step state (maybe hydrated by page state or url param), default is "perso"
     const navigationStep_pageState = (
          useLocation() as {
               state: { navigationStep: "perso" | "contract" | "client" | "odm" | "doc" | "recap" }
          }
     ).state?.navigationStep
     const navigationStep_urlParam = new URLSearchParams(window.location.search).get("navigationStep") as "contract" | "client" | "odm" | "doc" | "recap"
     const [navigationStep, setNavigationStep] = useState<"perso" | "contract" | "client" | "odm" | "doc" | "recap">(navigationStep_pageState || navigationStep_urlParam || "perso")

     // Params
     const consultant_ID = parseInt(useParams<{ consultant_id: string }>().consultant_id as string)

     // Get info for navigation menu
     const infoForNavigationQuery = useQuery<IGetDemandeEmbaucheInfoForNavigation, AxiosError>(REACT_QUERY_KEY_GET_DE_CONTRAT_ETAT_STATUT, () => {
          return getDemandeEmbaucheInfoForNavigation(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Is fetching
     if (infoForNavigationQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />
               </div>
          )
     }

     // Error
     if (!infoForNavigationQuery.isFetching && infoForNavigationQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{infoForNavigationQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => infoForNavigationQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     if (infoForNavigationQuery.data) {
          const consultant = infoForNavigationQuery.data.consultant
          const dernierSuiviDemandeEmbauche = infoForNavigationQuery.data.dernierSuiviDemandeEmbauche.type as
               | "TYPE_BROUILLON"
               | "TYPE_IA_DEMANDE_EMBAUCHE"
               | "TYPE_IA_RECTIFICATION_FOR_ADMIN"
               | "TYPE_IA_RECTIFICATION_FOR_DG"
               | "TYPE_IA_RECTIFICATION_FOR_IARESP"
               | "TYPE_IARESP_VALIDATION"
               | "TYPE_IARESP_DEMANDE_RECTIFICATION"
               | "TYPE_IARESP_REJET_DEFINITIF"
               | "TYPE_DG_VALIDATION"
               | "TYPE_DG_DEMANDE_RECTIFICATION"
               | "TYPE_DG_REJET_DEFINITIF"
               | "TYPE_ADMIN_DEMANDE_RECTIFICATION"

          return (
               <DemandeEmbaucheDetailsContext.Provider
                    value={{
                         navigationStep,
                         setNavigationStep,
                         consultant,
                         dernierSuiviDemandeEmbauche,
                         REACT_QUERY_KEY_GET_DE_CONTRAT_ETAT_STATUT,
                    }}
               >
                    <DemandeEmbaucheDetailsNavigationMenu />

                    <div className={"d-flex justify-content-center"}>
                         {navigationStep === "perso" && <DemandeEmbaucheDetailsInfoPerso />}
                         {navigationStep === "contract" && <DemandeEmbaucheDetailsInfoContract />}
                         {/*{consultant.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION && (*/}
                         <>
                              {navigationStep === "client" && <DemandeEmbaucheDetailsInfoClient />}
                              {navigationStep === "odm" && <DemandeEmbaucheDetailsOrdreMission />}
                         </>
                         {/*)}*/}
                         {navigationStep === "doc" && <DemandeEmbaucheDetailsDocuments />}
                    </div>
               </DemandeEmbaucheDetailsContext.Provider>
          )
     }

     return <></>
}

export default DemandeEmbaucheDetails
