import { Form, Formik, FormikHelpers } from "formik"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import MyAlert from "@common-utils/MyAlert"
import MyInputField from "@common-utils/fields/MyInputField"
import MyTextareaField from "@common-utils/fields/MyTextareaField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { createVivierRequest } from "./core/_requests"
import { ICreateVivierRequestModel } from "./core/_models"
import { useNavigate } from "react-router-dom"
import MyCard from "@common-utils/MyCard"

const FormCreate = () => {
     const navigate = useNavigate()

     const initialValues: ICreateVivierRequestModel = {}

     function handleSubmit(values: ICreateVivierRequestModel, helpers: FormikHelpers<ICreateVivierRequestModel>) {
          helpers.setStatus(null)

          createVivierRequest(values)
               .then(() => {
                    navigate("/recrutement/viviers/listing")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              {/* Titre */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre</label>
                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"titre"}
                                             value={helpers.values.titre}
                                             isInvalid={!!helpers.errors.titre}
                                             placeholder={"Titre"}
                                             onChange={value => helpers.setFieldValue("titre", value)}
                                        />
                                        {helpers.errors.titre && <div className="text-danger">{helpers.errors.titre}</div>}
                                   </div>
                              </div>

                              {/* Description */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Description</label>
                                   <div className="col-lg-8">
                                        <MyTextareaField
                                             name={"description"}
                                             value={helpers.values.description}
                                             isInvalid={!!helpers.errors.description}
                                             placeholder={"Saisissez une description (optionnel)"}
                                             rows={8}
                                             onChange={value => helpers.setFieldValue("description", value)}
                                        />
                                        {helpers.errors.description && <div className="text-danger">{helpers.errors.description}</div>}
                                   </div>
                              </div>

                              <div className="d-flex justify-content-end">
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? <>Créer</> : <MySimpleSpinner size={"sm"} />}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export const VivierCreate = () => {
     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-65"}>
                    <MyCard>
                         <MyCard.Header>
                              <i className={"fas fa-plus-circle fs-4 text-dark me-2"} /> Créer un vivier
                         </MyCard.Header>
                         <MyCard.Body>
                              <FormCreate />
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default VivierCreate
