import React from "react"
import { useQuery } from "react-query"
import { IConsultantCraModel, IConsultantDeclarationMensuelleModel } from "@common-models/*"
import { AxiosError } from "axios"

import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import moment from "moment"
import "../listing/styles.scss"
import { useConsultantDeclarationDetailsContext } from "./_ConsultantDeclarationDetails"
import { useAuth } from "../../../../../AppContext"
import { getDeclarationActiveCra } from "../core/_requests"
import { CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION } from "@common-constants/*"

const REACT_QUERY_KEY_GET_CONSULTANT_CRA = "REACT_QUERY_KEY_GET_CONSULTANT_CRA"
const ConsultantDeclarationDetailsTicketsRestaurant = ({ declaration }: IConsultantDeclarationDetailsTicketsRestaurantPropsModel) => {
     const { currentUser } = useAuth()
     const context = useConsultantDeclarationDetailsContext()
     const declarationDetailsContext = useConsultantDeclarationDetailsContext()
     const declarationActiveCraQuery = useQuery<IConsultantCraModel, AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_CRA, () =>
          getDeclarationActiveCra(declaration.id as number).then(r => r.data)
     )

     // Is fetching
     if (declarationActiveCraQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />{" "}
               </div>
          )
     }

     // Error
     if (!declarationActiveCraQuery.isFetching && declarationActiveCraQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{declarationActiveCraQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => declarationActiveCraQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     // Empty data
     if (!declarationActiveCraQuery.isFetching && !declarationActiveCraQuery.isError && !declarationActiveCraQuery.data) {
          return (
               <div className={"text-center p-4"}>
                    <p style={{ fontWeight: 500 }}>Aucun CRA n'a été ajouté par le collaborateur ...</p>
               </div>
          )
     }

     // Une row avec les jours du mois
     const TableHeadRow = () => {
          return (
               <tr className={"px-2"}>
                    <th className="col fw-bold sticky-column"></th>
                    {declarationActiveCraQuery.data?.consultantCraDetails.map((item, key) => {
                         return (
                              <th key={item.id} className={`text-center text-primary fw-bold ${key === declarationActiveCraQuery.data?.consultantCraDetails.length - 1 && "pe-4"}`}>
                                   {item.date.format("DD")}
                              </th>
                         )
                    })}
               </tr>
          )
     }

     // Une row avec les tickets restaurant
     const TableBodyRowTicketsRestaurant = () => {
          return (
               <>
                    <tr>
                         <th scope="row" className={"d-flex justify-content-between sticky-column border-end"}>
                              <span className={"text-primary fw-bold ps-2"}>TICKETS</span>
                         </th>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((item, key) => (
                              <td
                                   key={key}
                                   className={`text-center heuresCell ${[5, 6].includes(item.date.weekday()) && "isWeekendCell"} ${
                                        moment().format("YYYYMMDD") === item.date.format("YYYYMMDD") && "isTodayCell"
                                   }`}
                              >
                                   <span>{item.ticketResto && <i className={"fas fa-check-circle text-dark"} />}</span>
                              </td>
                         ))}
                    </tr>
               </>
          )
     }

     const NavigationFooter = () => {
          return (
               <>
                    <div className="d-flex justify-content-between">
                         <div>
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => declarationDetailsContext.setNavigationStep("ndf")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> NDF
                              </button>
                         </div>

                         <div className={"d-flex"}>
                              {context.isActionDemandeRectifAvailable && (
                                   <button type="button" className="btn btn-sm btn-warning d-flex align-items-center" onClick={context.handleDemandeRectification}>
                                        <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                        <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                   </button>
                              )}

                              {context.isActionValidationAvailable && (
                                   <button type="button" className="btn btn-sm btn-success d-flex align-items-center ms-2" onClick={context.handleValidation}>
                                        <span className={"d-none d-sm-flex"}>
                                             {declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION
                                                  ? "Retourner la déclaration au siège"
                                                  : "Valider et suivant"}
                                        </span>
                                        <span
                                             className={`fas fa-${
                                                  declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION ? "hand-point-right" : "thumbs-up"
                                             } fs-3 ms-1`}
                                        />
                                   </button>
                              )}

                              {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                                   <>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-success d-flex align-items-center me-2"
                                             onClick={() => context.handleValidationDemandeRegul()}
                                        >
                                             <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                             <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                             <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                        </button>
                                   </>
                              )}
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               <div className={"mb-4"}>
                    <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
                         <table id={"cra-table"} className={`table m-0`}>
                              {/* Begin::thead */}
                              <thead className="thead-dark border-bottom border-top">
                                   <TableHeadRow />
                              </thead>
                              {/* End::thead */}

                              {/* Begin::tbody */}
                              <tbody className={"border-top border-bottom"}>
                                   <TableBodyRowTicketsRestaurant />
                              </tbody>
                              {/* End::tbody */}
                         </table>
                    </div>

                    {declarationActiveCraQuery.data && (
                         <div className="text-primary d-flex flex-row-reverse fs-6 mb-4">
                              <span className={"fw-bold p-3 border-primary"}>
                                   {declarationActiveCraQuery.data.consultantCraDetails.filter(item => item.ticketResto).length === 0 && "Aucun ticket restaurant"}
                                   {declarationActiveCraQuery.data.consultantCraDetails.filter(item => item.ticketResto).length === 1 && "1 ticket restaurant"}
                                   {declarationActiveCraQuery.data.consultantCraDetails.filter(item => item.ticketResto).length > 1 &&
                                        `${declarationActiveCraQuery.data.consultantCraDetails.filter(item => item.ticketResto).length} tickets restaurant`}
                              </span>
                         </div>
                    )}
               </div>

               <NavigationFooter />
          </>
     )
}

interface IConsultantDeclarationDetailsTicketsRestaurantPropsModel {
     declaration: IConsultantDeclarationMensuelleModel
}

export default ConsultantDeclarationDetailsTicketsRestaurant
