import axios from "axios"
import { CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CONTRACT_ENDPOINT, CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CONTRACT_ENDPOINT } from "../../../../../../endpoints"
import { IGetDemandeEmbaucheInfoContractResponseModel, ISetDemandeEmbaucheInfoContractNonSalarieRequestModel, ISetDemandeEmbaucheInfoProSalarieRequestModel } from "./_models"

export function getDemandeEmbaucheInfoContract(consultant_id: number) {
     return axios.get<IGetDemandeEmbaucheInfoContractResponseModel>(CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CONTRACT_ENDPOINT(consultant_id))
}

export function setDemandeEmbaucheInfoContractSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoProSalarieRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CONTRACT_ENDPOINT(consultant_id), payload)
}

export function setDemandeEmbaucheInfoContractNonSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoContractNonSalarieRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CONTRACT_ENDPOINT(consultant_id), payload)
}
