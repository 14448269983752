import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { IEditPropalPeriodeRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { IClientPropalPeriodeModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { editPropalPeriodeRequest } from "../core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"

const PtfPeriodeEditForm = ({ periode, handleSubmitCallback = () => {} }: IPtfPeriodeEditFormPropsModel) => {
     // Form initial values
     const initialValues: IEditPropalPeriodeRequestModel = {
          dateDebut: periode.dateDebutValidite?.format("YYYY-MM-DD"),
          dateFin: periode.dateFinValidite?.format("YYYY-MM-DD"),
     }

     // Formik handle submit
     function handleSubmit(values: IEditPropalPeriodeRequestModel, helpers: FormikHelpers<IEditPropalPeriodeRequestModel>) {
          helpers.setStatus(null)

          editPropalPeriodeRequest(periode.id as number, values)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    handleSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Date de début */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de début</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateDebut"}
                                                  value={helpers.values.dateDebut}
                                                  isInvalid={!!helpers.errors.dateDebut}
                                                  maxDate={helpers.values.dateFin}
                                                  onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                  placeholder={"Date de début"}
                                             />
                                             {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                        </div>
                                   </div>

                                   {/* Date de fin */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de fin</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateFin"}
                                                  value={helpers.values.dateFin}
                                                  minDate={helpers.values.dateDebut}
                                                  isInvalid={!!helpers.errors.dateFin}
                                                  onChange={val => helpers.setFieldValue("dateFin", val)}
                                                  placeholder={"Date de fin"}
                                             />
                                             {helpers.errors.dateFin && <div className={"text-danger"}>{helpers.errors.dateFin}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Appliquer les modifications <i className={"fas fa-check-circle fs-4 ms-1"} />
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPtfPeriodeEditFormPropsModel {
     periode: IClientPropalPeriodeModel
     handleSubmitCallback?: (periode: IClientPropalPeriodeModel) => void
}

export default PtfPeriodeEditForm
