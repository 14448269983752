import { PageLink } from "@common-metronic/layout/core"
import { ROUTING_ABSOLUTE_PATH_DASHBOARD } from "./routing-endpoints"

export const ROUTING_BREADCRUMBS_DASHBOARD: Array<PageLink> = [
     {
          title: "Tableau de bord",
     },
]

export const ROUTING_BREADCRUMBS_DASHBOARD_ALERTES: Array<PageLink> = [
     ...ROUTING_BREADCRUMBS_DASHBOARD,
     {
          title: "Alertes",
          path: ROUTING_ABSOLUTE_PATH_DASHBOARD,
     },
]
