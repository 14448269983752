// Menu permettant de naviguer d'un élément à un autre (Exemple de CRA to NDF)
import { useConnectedUserDeclarationDetails } from "./ConnectedPersonnelDeclarationDetails"

export const ConnectedUserDeclarationDetails_NavigationHeader = () => {
     const detailsInModalContext = useConnectedUserDeclarationDetails()

     return (
          <div className="stepper stepper-links">
               <div className="stepper-nav">
                    {/* BEGIN:: STEP -- Période */}
                    <div className={`stepper-item m-sm-5 m-4 completed`}>
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>1. Période</span>
                              <span className={"d-block d-sm-none fas fa-calendar-day"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* END:: STEP -- Période */}

                    {/* BEGIN:: STEP -- Arrêts */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.currentStepHeader === "arrets" && "current"} ${
                              ["ddc", "cra", "ndf", "ticket_resto", "recap"].includes(detailsInModalContext.currentStepHeader) && "completed"
                         }  ${!["arrets"].includes(detailsInModalContext.currentStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["arrets"].includes(detailsInModalContext.currentStepHeader) && detailsInModalContext.setCurrentStepHeader("arrets")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>2. Arrêts</span>
                              <span className={"d-block d-sm-none fas fa-file-medical"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* END:: STEP -- Arrêts */}

                    {/* BEGIN:: STEP -- DDC */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.currentStepHeader === "ddc" && "current"} ${
                              ["cra", "ndf", "ticket_resto", "recap"].includes(detailsInModalContext.currentStepHeader) && "completed"
                         }  ${!["ddc"].includes(detailsInModalContext.currentStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["ddc"].includes(detailsInModalContext.currentStepHeader) && detailsInModalContext.setCurrentStepHeader("ddc")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>3. DDC</span>
                              <span className={"d-block d-sm-none fas fa-umbrella-beach"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* END:: STEP -- DDC */}

                    {/* BEGIN:: STEP -- CRA */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.currentStepHeader === "cra" && "current"} ${
                              ["ndf", "ticket_resto", "recap"].includes(detailsInModalContext.currentStepHeader) && "completed"
                         } ${!["cra"].includes(detailsInModalContext.currentStepHeader) && "cursor-pointer"}`}
                         onClick={() => {
                              if (!["cra"].includes(detailsInModalContext.currentStepHeader)) detailsInModalContext.setCurrentStepHeader("cra")
                         }}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>4. CRA</span>
                              <span className={"d-block d-sm-none fas fa-calendar-days"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* END:: STEP -- CRA */}

                    {/* BEGIN:: STEP -- NDF */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${["ndf"].includes(detailsInModalContext.currentStepHeader) && "current"} ${
                              ["ticket_resto", "recap"].includes(detailsInModalContext.currentStepHeader) && "completed"
                         } ${!["ndf"].includes(detailsInModalContext.currentStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["ndf"].includes(detailsInModalContext.currentStepHeader) && detailsInModalContext.setCurrentStepHeader("ndf")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>5. NDF</span>
                              <span className={"d-block d-sm-none fas fa-file-invoice-dollar"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* END:: STEP -- NDF */}

                    {/* BEGIN:: STEP -- TR */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${["ticket_resto"].includes(detailsInModalContext.currentStepHeader) && "current"} ${
                              ["recap"].includes(detailsInModalContext.currentStepHeader) && "completed"
                         } ${!["ticket_resto"].includes(detailsInModalContext.currentStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["ticket_resto"].includes(detailsInModalContext.currentStepHeader) && detailsInModalContext.setCurrentStepHeader("ticket_resto")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>6. TR</span>
                              <span className={"d-block d-sm-none fas fa-utensils"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* END:: STEP -- TR */}

                    {/* BEGIN:: STEP -- TR */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.currentStepHeader === "recap" && "current"} ${
                              !["recap"].includes(detailsInModalContext.currentStepHeader) && "cursor-pointer"
                         }`}
                         onClick={() => !["recap"].includes(detailsInModalContext.currentStepHeader) && detailsInModalContext.setCurrentStepHeader("recap")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>7. Récapitulatif</span>
                              <span className={"d-block d-sm-none fas fa-list-alt"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* END:: STEP -- TR */}
               </div>
          </div>
     )
}
