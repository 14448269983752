import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { IConsultantDocumentModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { setConsultantDocumentDetails } from "../../../core/_requests"
import * as Yup from "yup"
import { ISetConsultantDocumentDetailsRequestModel } from "../../../core/_models"

const ConsultantEditContratDetailsForm = ({ document, callback = () => {} }: IConsultantEditContratFormPropsModel) => {
     // Form schema
     const schema = Yup.object().shape({
          dateDebutValidite: Yup.string().label(`"Date début de validité"`).required(),
     })

     // Form initial values
     const initialValues: ISetConsultantDocumentDetailsRequestModel = {
          dateDebutValidite: document.dateDebutValidite?.format("YYYY-MM-DD"),
     }

     // Formik handle submit
     function handleSubmit(values: ISetConsultantDocumentDetailsRequestModel, helpers: FormikHelpers<ISetConsultantDocumentDetailsRequestModel>) {
          helpers.setStatus(null)

          setConsultantDocumentDetails(document.id as number, values)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Date début de validité */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de début de validité</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateDebutValidite"}
                                                  value={helpers.values.dateDebutValidite}
                                                  isInvalid={!!helpers.errors.dateDebutValidite}
                                                  onChange={val => helpers.setFieldValue("dateDebutValidite", val)}
                                                  placeholder={"Date de début"}
                                             />
                                             {helpers.errors.dateDebutValidite && <div className={"text-danger"}>{helpers.errors.dateDebutValidite}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Ajouter <i className={"fas fa-plus-circle fs-4 ms-1"} />
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IConsultantEditContratFormPropsModel {
     document: IConsultantDocumentModel
     callback: (val: IConsultantDocumentModel) => void
}

export default ConsultantEditContratDetailsForm
