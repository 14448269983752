import { IGetDetailsConsultant } from "../core/_models"
import ConsultantDetailsSimulateurRentabiliteForm from "./_ConsultantDetails_SimulateurRentabilite_Form"
import { useRef, useState } from "react"
import { ISimulationRentabiliteConsultantAvantApresResponseModel } from "../../core/_models"
import ConsultantDetailsSimulateurRentabiliteTableaux from "./_ConsultantDetails_SimulateurRentabilite_Tableaux"
import MyCard from "@common-utils/MyCard"

const ConsultantDetailsSimulateurRentabilite = ({ consultantDetails }: IConsultantDetailsSimulateurRentabilitePropsModel) => {
     const [simulationResults, setSimulationResults] = useState<ISimulationRentabiliteConsultantAvantApresResponseModel>()
     const calculatriceHeaderRef = useRef<any>()

     function handleSubmitSimulation(results: ISimulationRentabiliteConsultantAvantApresResponseModel) {
          // For column salaire + hs
          results.btc.avant.base35hPlusHeuresSuppMensuelBrut = results.btc.avant.salaireBrutMensuel + results.btc.avant.coutHeuresSuppMensuel
          results.btc.apres.base35hPlusHeuresSuppMensuelBrut = results.btc.apres.salaireBrutMensuel + results.btc.apres.coutHeuresSuppMensuel
          results.btc.avant.base35hPlusHeuresSuppAnnuelBrut = results.btc.avant.salaireBrutAnnuel + results.btc.avant.coutHeuresSuppAnnuel
          results.btc.apres.base35hPlusHeuresSuppAnnuelBrut = results.btc.apres.salaireBrutAnnuel + results.btc.apres.coutHeuresSuppAnnuel
          results.btc.avant.base35hPlusHeuresSuppMensuelNet = results.btc.avant.salaireChargeSansHeuresSuppMensuel + results.btc.avant.salaireQueLesHeuresSuppMensuelCharge
          results.btc.apres.base35hPlusHeuresSuppMensuelNet = results.btc.apres.salaireChargeSansHeuresSuppMensuel + results.btc.apres.salaireQueLesHeuresSuppMensuelCharge
          results.btc.avant.base35hPlusHeuresSuppAnnuelNet = results.btc.avant.salaireChargeSansHeuresSuppAnnuel + results.btc.avant.salaireQueLesHeuresSuppAnnuelCharge
          results.btc.apres.base35hPlusHeuresSuppAnnuelNet = results.btc.apres.salaireChargeSansHeuresSuppAnnuel + results.btc.apres.salaireQueLesHeuresSuppAnnuelCharge

          // For column “salaire net versé sur bulletin de salaire“
          results.btc.avant.salaireNetVerseSurBulletingSalaireMensuel =
               results.btc.avant.salaireChargeSansHeuresSuppMensuel + results.btc.avant.salaireQueLesHeuresSuppMensuelCharge - results.btc.avant.partMensuelleTicketsRestoSalarie
          results.btc.apres.salaireNetVerseSurBulletingSalaireMensuel =
               results.btc.apres.salaireChargeSansHeuresSuppMensuel + results.btc.apres.salaireQueLesHeuresSuppMensuelCharge - results.btc.apres.partMensuelleTicketsRestoSalarie
          results.btc.avant.salaireNetVerseSurBulletingSalaireAnnuel =
               results.btc.avant.salaireChargeSansHeuresSuppAnnuel + results.btc.avant.salaireQueLesHeuresSuppAnnuelCharge - results.btc.avant.partAnnuelleTicketsRestoSalarie
          results.btc.apres.salaireNetVerseSurBulletingSalaireAnnuel =
               results.btc.apres.salaireChargeSansHeuresSuppAnnuel + results.btc.apres.salaireQueLesHeuresSuppAnnuelCharge - results.btc.apres.partAnnuelleTicketsRestoSalarie

          // For column “Equivalent brut annuel & mensuel“
          results.btc.avant.equivalentBrutAvecTicketRestoAnnuel =
               (results.btc.avant.salaireChargeSansHeuresSuppAnnuel + results.btc.avant.salaireQueLesHeuresSuppAnnuelCharge + results.btc.avant.partAnnuelleTicketsRestoEmployeur) /
               results.btc.avant.coeffChargesSalariales
          results.btc.apres.equivalentBrutAvecTicketRestoAnnuel =
               (results.btc.apres.salaireChargeSansHeuresSuppAnnuel + results.btc.apres.salaireQueLesHeuresSuppAnnuelCharge + results.btc.apres.partAnnuelleTicketsRestoEmployeur) /
               results.btc.apres.coeffChargesSalariales
          results.btc.avant.equivalentBrutAvecTicketRestoMensuel =
               (results.btc.avant.salaireChargeSansHeuresSuppMensuel +
                    results.btc.avant.salaireQueLesHeuresSuppMensuelCharge +
                    results.btc.avant.partMensuelleTicketsRestoEmployeur) /
               results.btc.avant.coeffChargesSalariales
          results.btc.apres.equivalentBrutAvecTicketRestoMensuel =
               (results.btc.apres.salaireChargeSansHeuresSuppMensuel +
                    results.btc.apres.salaireQueLesHeuresSuppMensuelCharge +
                    results.btc.apres.partMensuelleTicketsRestoEmployeur) /
               results.btc.apres.coeffChargesSalariales
          results.btc.avant.equivalentBrutSansTicketRestoAnnuel =
               (results.btc.avant.salaireChargeSansHeuresSuppAnnuel + results.btc.avant.salaireQueLesHeuresSuppAnnuelCharge) / results.btc.avant.coeffChargesSalariales
          results.btc.apres.equivalentBrutSansTicketRestoAnnuel =
               (results.btc.apres.salaireChargeSansHeuresSuppAnnuel + results.btc.apres.salaireQueLesHeuresSuppAnnuelCharge) / results.btc.apres.coeffChargesSalariales
          results.btc.avant.equivalentBrutSansTicketRestoMensuel =
               (results.btc.avant.salaireChargeSansHeuresSuppMensuel + results.btc.avant.salaireQueLesHeuresSuppMensuelCharge) / results.btc.avant.coeffChargesSalariales
          results.btc.apres.equivalentBrutSansTicketRestoMensuel =
               (results.btc.apres.salaireChargeSansHeuresSuppMensuel + results.btc.apres.salaireQueLesHeuresSuppMensuelCharge) / results.btc.apres.coeffChargesSalariales

          // Add pairs for "coûts" table
          // -- Row coût total
          results.btc.avant.coutTotalSalarieAnnuel =
               results.btc.avant.coutSalaireAnnuelCharge + results.btc.avant.coutHeuresSuppAnnuelCharge + results.btc.avant.partAnnuelleTicketsRestoEmployeur
          results.btc.apres.coutTotalSalarieAnnuel =
               results.btc.apres.coutSalaireAnnuelCharge + results.btc.apres.coutHeuresSuppAnnuelCharge + results.btc.apres.partAnnuelleTicketsRestoEmployeur
          results.btc.avant.coutTotalSalarieMensuel =
               results.btc.avant.coutSalaireMensuelCharge + results.btc.avant.coutHeuresSuppMensuelCharge + results.btc.avant.partMensuelleTicketsRestoEmployeur
          results.btc.apres.coutTotalSalarieMensuel =
               results.btc.apres.coutSalaireMensuelCharge + results.btc.apres.coutHeuresSuppMensuelCharge + results.btc.apres.partMensuelleTicketsRestoEmployeur
          results.btc.avant.coutTotalSalarieJournalier =
               results.btc.avant.coutSalaireJournalierCharge + results.btc.avant.coutHeuresSuppJournalierCharge + results.btc.apres.partJournaliereTicketsRestoEmployeur
          results.btc.apres.coutTotalSalarieJournalier =
               results.btc.apres.coutSalaireJournalierCharge + results.btc.apres.coutHeuresSuppJournalierCharge + results.btc.apres.partJournaliereTicketsRestoEmployeur

          // Add pairs for "marge" table
          // -- MBP en chiffre
          results.btc.avant.margeBrutProductionAnnuelle = results.btc.avant.chiffreAffairesAnnuel - results.btc.avant.coutTotalSalarieAnnuel
          results.btc.apres.margeBrutProductionAnnuelle = results.btc.apres.chiffreAffairesAnnuel - results.btc.apres.coutTotalSalarieAnnuel
          results.btc.avant.margeBrutProductionMensuelle = results.btc.avant.chiffreAffairesMensuel - results.btc.avant.coutTotalSalarieMensuel
          results.btc.apres.margeBrutProductionMensuelle = results.btc.apres.chiffreAffairesMensuel - results.btc.apres.coutTotalSalarieMensuel
          results.btc.avant.margeBrutProductionJournaliere = results.btc.avant.chiffreAffairesJournalier - results.btc.avant.coutTotalSalarieJournalier
          results.btc.apres.margeBrutProductionJournaliere = results.btc.apres.chiffreAffairesJournalier - results.btc.apres.coutTotalSalarieJournalier
          // -- MBP en pourcentage
          results.btc.avant.margeBrutProductionEnPourcentage =
               ((results.btc.avant.chiffreAffairesAnnuel - results.btc.avant.coutTotalSalarieAnnuel) / results.btc.avant.chiffreAffairesAnnuel) * 100
          results.btc.apres.margeBrutProductionEnPourcentage =
               ((results.btc.apres.chiffreAffairesAnnuel - results.btc.apres.coutTotalSalarieAnnuel) / results.btc.apres.chiffreAffairesAnnuel) * 100

          // results.btc.avant.margeBrutProductionEnPourcentage = results.btc.avant.margeBrutProductionEnPourcentage.toFixed(2)
          // results.btc.apres.margeBrutProductionEnPourcentage = GlobalHelper.separateNumber((results.btc.apres.margeBrutProductionEnPourcentage.toFixed(2))
          //
          // results.btc.avant.coeffChargesSalariales = GlobalHelper.separateNumber((results.btc.avant.coeffChargesSalariales.toFixed(2))
          // results.btc.apres.coeffChargesSalariales = GlobalHelper.separateNumber((results.btc.apres.coeffChargesSalariales.toFixed(2))

          setSimulationResults(results)

          calculatriceHeaderRef.current?.click()
     }

     return (
          <>
               <div className="p-4">
                    <MyCard classNames={"mb-4"}>
                         <MyCard.Header isCollapseIconShown refHeader={calculatriceHeaderRef}>
                              Calculatrice
                         </MyCard.Header>
                         <MyCard.Body>
                              <ConsultantDetailsSimulateurRentabiliteForm consultantDetails={consultantDetails} callback={handleSubmitSimulation} />
                         </MyCard.Body>
                    </MyCard>

                    {simulationResults && (
                         <MyCard classNames={"mb-4"}>
                              <MyCard.Header>Résultats</MyCard.Header>
                              <MyCard.Body>
                                   <ConsultantDetailsSimulateurRentabiliteTableaux results={simulationResults} />
                              </MyCard.Body>
                         </MyCard>
                    )}
               </div>
          </>
     )
}

interface IConsultantDetailsSimulateurRentabilitePropsModel {
     consultantDetails: IGetDetailsConsultant
}

export default ConsultantDetailsSimulateurRentabilite
