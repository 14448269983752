import axios from "axios"
import {
     CONST_API_RECRUITMENT_CANDIDAT_DOCO_DELETE_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDAT_DOCO_LOCK_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDAT_DOCO_PARTAGER_PAR_MAIL_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDAT_DOCO_UNLOCK_ENDPOINT,
} from "../../../../../endpoints"
import { ICandidatDocoPartagerParMailRequestModel } from "./_models"

export function deleteDoco(id: number) {
     return axios.delete(CONST_API_RECRUITMENT_CANDIDAT_DOCO_DELETE_ENDPOINT(id))
}

export function lockDoco(id: number) {
     return axios.put(CONST_API_RECRUITMENT_CANDIDAT_DOCO_LOCK_ENDPOINT(id), {})
}

export function unlockDoco(id: number) {
     return axios.put(CONST_API_RECRUITMENT_CANDIDAT_DOCO_UNLOCK_ENDPOINT(id), {})
}

export function partagerDocoParMail(id: number, payload: ICandidatDocoPartagerParMailRequestModel) {
     return axios.post(CONST_API_RECRUITMENT_CANDIDAT_DOCO_PARTAGER_PAR_MAIL_ENDPOINT(id), payload)
}
