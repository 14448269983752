import React from "react"
import MyCard from "@common-utils/MyCard"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import { IGetPropositionsRequestModel } from "./core/_models"
import MySelectField from "@common-utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { usePtfListingContext } from "./PtfListing"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT, CONST_API_UTILS_GET_CLIENTS_ENDPOINT } from "../../../../endpoints"
import {
     CONST_CLIENT_PROPAL_ETAT_DISABLED,
     CONST_CLIENT_PROPAL_ETAT_EN_COURS,
     CONST_CLIENT_PROPAL_ETAT_EN_PROD,
     CONST_CLIENT_PROPAL_ETAT_LIVRE,
     CONST_CLIENT_PROPAL_ETAT_PARTIELLEMENT_LIVRE,
     CONST_CLIENT_PROPAL_ETAT_REFUSEE,
     CONST_CLIENT_PROPAL_ETAT_VALIDEE,
     CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE,
     CONST_CLIENT_PROPAL_TYPE_FORFAIT,
} from "@common-constants/*"

// Filters
const PtfListingFilters = () => {
     const context = usePtfListingContext()

     const initialValues: IGetPropositionsRequestModel = {}

     function handleSubmit(values: IGetPropositionsRequestModel) {
          context.setFilters(prev => ({
               ...prev,
               ...values,
          }))
          context.setAreFiltersVisible(false)
          context.setItemInPreview(null)
     }

     function handleReset() {
          context.filtersFormikRef.current.resetForm() // Reset local form
          context.simpleSearchBarFormikRef.current.resetForm() // Reset search bar form
          context.setItemInPreview(null)
          context.setAreFiltersVisible(false)

          // Reset payload filters
          context.setFilters({
               page: 1,
               pageLength: 20,
          })

          context.filtersFormikRef.current.setSubmitting(true)
     }

     function handleClickHideFilters() {
          // Hide filters
          context.setAreFiltersVisible(prev => !prev)
          // Show preview only if it was opened before showing filters
          context.setItemInPreview(prev => {
               if (prev) {
                    return { ...prev, isPreviewVisible: true }
               }
               return null
          })
     }

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <MyTooltip title={"Cacher les filters"}>
                              <button className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={handleClickHideFilters}>
                                   <i className={"fas fa-eye-slash fs-4 text-dark"} />
                              </button>
                         </MyTooltip>
                    }
               >
                    Filtres
               </MyCard.Header>

               <MyCard.Body>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={context.filtersFormikRef}>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mot clé</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"keyword"}
                                                       value={helpers.values.keyword}
                                                       onChange={val => {
                                                            helpers.setFieldValue("keyword", val).then()
                                                            context.simpleSearchBarFormikRef.current.setFieldValue("keyword", val).then()
                                                       }}
                                                       isInvalid={!!helpers.errors.keyword}
                                                       placeholder={"Rechercher ..."}
                                                  />
                                                  {helpers.errors.keyword && <div className={"text-danger"}>{helpers.errors.keyword}</div>}
                                             </div>
                                        </div>

                                        <div className="separator separator-dotted mt-4 mb-8"></div>

                                        {/* Type FORFAIT | AT */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Type</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"type"}
                                                       options={[
                                                            { value: CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE, label: "Assistance technique" },
                                                            { value: CONST_CLIENT_PROPAL_TYPE_FORFAIT, label: "Forfait" },
                                                       ]}
                                                       value={helpers.values.type}
                                                       onChange={val => helpers.setFieldValue("type", val)}
                                                       isInvalid={!!helpers.errors.type}
                                                  />
                                                  {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}
                                             </div>
                                        </div>

                                        {/* Etats */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">États</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"etats"}
                                                       options={[
                                                            { value: CONST_CLIENT_PROPAL_ETAT_EN_COURS, label: "En cours" },
                                                            { value: CONST_CLIENT_PROPAL_ETAT_VALIDEE, label: "Gagné" },
                                                            { value: CONST_CLIENT_PROPAL_ETAT_REFUSEE, label: "Perdu" },
                                                            { value: CONST_CLIENT_PROPAL_ETAT_DISABLED, label: "Archivé" },
                                                            { value: CONST_CLIENT_PROPAL_ETAT_EN_PROD, label: "En production" },
                                                            { value: CONST_CLIENT_PROPAL_ETAT_PARTIELLEMENT_LIVRE, label: "Partiellement livré" },
                                                            { value: CONST_CLIENT_PROPAL_ETAT_LIVRE, label: "Livré" },
                                                       ]}
                                                       value={helpers.values.etats}
                                                       onChange={val => helpers.setFieldValue("etats", val)}
                                                       isInvalid={!!helpers.errors.etats}
                                                       isMulti
                                                  />
                                                  {helpers.errors.etats && <div className={"text-danger"}>{helpers.errors.etats}</div>}
                                             </div>
                                        </div>

                                        {/* Responsables */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Responsables</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"responsables"}
                                                       url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                       value={helpers.values.responsables}
                                                       onChange={val => helpers.setFieldValue("responsables", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.responsables}
                                                       isMulti
                                                  />
                                                  {helpers.errors.responsables && <div className={"text-danger"}>{helpers.errors.responsables}</div>}
                                             </div>
                                        </div>

                                        {/* Clients */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Clients</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"clients"}
                                                       url={CONST_API_UTILS_GET_CLIENTS_ENDPOINT}
                                                       value={helpers.values.clients}
                                                       onChange={val => helpers.setFieldValue("clients", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.clients}
                                                       isMulti
                                                  />
                                                  {helpers.errors.clients && <div className={"text-danger"}>{helpers.errors.clients}</div>}
                                             </div>
                                        </div>

                                        <div className={"text-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  disabled={helpers.isSubmitting}
                                                  onClick={() => handleReset()}
                                             >
                                                  <span className={"d-none d-lg-block"}>Réinitialiser</span> <i className={"fas fa-sync ms-lg-1"} />
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  <span className={"d-none d-lg-block"}>Filtrer </span>
                                                  {!helpers.isSubmitting ? (
                                                       <i className={"fas fa-filter ms-md-1"} />
                                                  ) : (
                                                       <span className="spinner-border spinner-border-sm align-middle ms-lg-1" />
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyCard.Body>
          </MyCard>
     )
}

export default PtfListingFilters
