import { IGetDocumentsConsultant } from "../core/_models"
import React, { useRef, useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { IConsultantDocumentModel } from "@common-models/*"
import MyTooltip from "@common-utils/MyTooltip"
import { CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES } from "@common-constants/*"
import { useQueryClient } from "react-query"
import { REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS } from "./_ConsultantDetails_Documents"
import { useConsultantDocumentActions } from "../../core/_hooks"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"

const ConsultantDetailsDocumentsAutres = ({ documents }: IConsultantDetailsDocumentsAutresPropsModel) => {
     const context = useConsultantDetailsContext()

     const queryClient = useQueryClient()
     const consultantDocumentActions = useConsultantDocumentActions(context.detailsQuery.data?.consultant.id as number)

     const [documentInModal, setActiveDriveDocumentInModal] = useState<{
          driveID: string
          driveMime: string
          modalTitle: string
          isLoading: boolean
     }>()

     /* Container containing consultant documents */
     const DocumentsConsultantContainer = ({ type, containerTitle, children }: { type: string; containerTitle: string; children: JSX.Element }) => {
          const refInputAddDocument = useRef<HTMLInputElement>(null)

          function handleAddDocumentCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES && prev.documentsAutres.autres) prev.documentsAutres.autres = [val, ...prev.documentsAutres.autres]
                    }
                    return prev
               })
          }

          return (
               <div className={"p-4 border border-dashed mb-4"}>
                    <div className="d-flex justify-content-between d-flex align-items-center mb-4">
                         <h4>{containerTitle}</h4>
                         <MyTooltip title={"Ajouter"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-success p-0 px-2 py-1 me-2"}
                                   onClick={() => refInputAddDocument.current?.click()}
                              >
                                   <i className={"fas fa-plus-circle fs-5"} />
                              </button>
                         </MyTooltip>
                         <input type={"file"} className={"d-none"} ref={refInputAddDocument} onChange={e => consultantDocumentActions.add(e, type, handleAddDocumentCallback)} />
                    </div>
                    <div className="separator separator-dashed mb-6" />
                    <div className="row">
                         <div className="col-sm-6 col-xxl-4 mb-4">{children}</div>
                    </div>
               </div>
          )
     }

     /* Consultant document */
     const DocumentConsultantItem = ({ consultantDocument, classNames = "" }: { consultantDocument: IConsultantDocumentModel; classNames?: string }) => {
          const refInputReplaceDocument = useRef<HTMLInputElement>(null)

          function handleDeleteDocumentCallback() {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (consultantDocument.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES && prev.documentsAutres.autres) {
                              prev.documentsAutres.autres = prev.documentsAutres.autres.filter(item => item.id !== consultantDocument.id)
                         }
                    }
                    return prev
               })
          }

          function handleReplaceDocumentCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (consultantDocument.type === CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES && prev.documentsAutres.autres) {
                              prev.documentsAutres.autres.map(item => {
                                   if (item.id === val.id) {
                                        item.googleDriveID = val.googleDriveID
                                        return item
                                   }
                              })
                         }
                    }
                    return prev
               })
          }

          return (
               <div className={`d-flex ${classNames}`}>
                    <MyTooltip title={<span>Déposé le {consultantDocument.createdAt?.format("dddd D MMMM YYYY")}</span>}>
                         <div
                              className="symbol symbol-35px cursor-pointer me-4 pe-1 border-end-dashed rounded-0 border-gray-300"
                              onClick={() =>
                                   setActiveDriveDocumentInModal({
                                        modalTitle: `${consultantDocument.typeLabel}`,
                                        driveMime: consultantDocument.googleDriveMimeType as string,
                                        driveID: consultantDocument.googleDriveID as string,
                                        isLoading: true,
                                   })
                              }
                         >
                              <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-light-show" alt="" />
                              <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-dark-show" alt="" />
                         </div>
                    </MyTooltip>
                    <div className={"d-flex align-items-center"}>
                         <button
                              type={"button"}
                              className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2 d-flex align-items-center"}
                              onClick={() =>
                                   setActiveDriveDocumentInModal({
                                        modalTitle: `${consultantDocument.typeLabel}`,
                                        driveID: consultantDocument.googleDriveID as string,
                                        driveMime: consultantDocument.googleDriveMimeType as string,
                                        isLoading: true,
                                   })
                              }
                         >
                              Consulter <i className={"fas fa-eye fs-5 ms-1"} />
                         </button>
                         <MyTooltip title={"Remplacer"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-warning p-0 px-2 py-1 me-2"}
                                   onClick={() => refInputReplaceDocument.current?.click()}
                              >
                                   <i className={"fas fa-sync fs-5"} />
                              </button>
                         </MyTooltip>
                         <input
                              type={"file"}
                              className={"d-none"}
                              ref={refInputReplaceDocument}
                              onChange={e => consultantDocumentActions.replace(e, consultantDocument.id as number, handleReplaceDocumentCallback)}
                         />
                         <MyTooltip title={"Supprimer"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-danger p-0 px-2 py-1"}
                                   onClick={() => consultantDocumentActions.flagAsDeleted(consultantDocument.id as number, handleDeleteDocumentCallback)}
                              >
                                   <i className={"fas fa-trash fs-5"} />
                              </button>
                         </MyTooltip>
                    </div>
               </div>
          )
     }

     return (
          <>
               <div className="row">
                    {/* Diplômes */}
                    <div className="col-sm-6">
                         <DocumentsConsultantContainer containerTitle={"Autres"} type={CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES}>
                              <>
                                   {documents.documentsAutres.autres?.map((item, index) => {
                                        return <DocumentConsultantItem consultantDocument={item} key={index} classNames={index > 0 ? "mt-4" : ""} />
                                   })}
                              </>
                         </DocumentsConsultantContainer>
                    </div>
               </div>

               {/* Document in modal */}
               {documentInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={documentInModal.driveID as string}
                         googleDriveMimetype={documentInModal.driveMime as string}
                         modalTitle={<span>{documentInModal.modalTitle}</span>}
                         handleCloseModal={() => setActiveDriveDocumentInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}

interface IConsultantDetailsDocumentsAutresPropsModel {
     documents: IGetDocumentsConsultant
}

export default ConsultantDetailsDocumentsAutres
