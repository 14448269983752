import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { getDemandesEmbaucheListing } from "./core/_requests"
import { IGetDemandesEmbaucheListingRequestModel } from "./core/_models"
import MyCard from "@common-utils/MyCard"
import React from "react"
import { useAuth } from "../../../../AppContext"
import DemandesEmbaucheListingItem from "./_DemandesEmbaucheListingItem"
import { CONST_PERSONNEL_CODE_HUGO_DUTERTRE, CONST_PERSONNEL_CODE_THOMAS_NAULEAU } from "@common-constants/*"

const REACT_QUERY_KEY_GET_LISTING_QUERY = "REACT_QUERY_KEY_GET_LISTING_QUERY"

const DemandesEmbaucheListing = () => {
     const { currentUser } = useAuth()
     const isUserFromSiege = currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF"
     const isUserFromDirection = [CONST_PERSONNEL_CODE_HUGO_DUTERTRE, CONST_PERSONNEL_CODE_THOMAS_NAULEAU].find(item_ => item_ === currentUser?.code) !== undefined

     const listingQuery = useQuery<IGetDemandesEmbaucheListingRequestModel[], AxiosError>(REACT_QUERY_KEY_GET_LISTING_QUERY, () => {
          return getDemandesEmbaucheListing()
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Filter demandes d'embauche according to user's position (siège, direction, responsable or responsable of responsable)
     const filtredDemandesEmbauche = (() => {
          return listingQuery.data
               ? listingQuery.data!.filter(item => {
                      const isUserResponsableOfResponsable = currentUser?.personnelHierarchyFromUpToDown?.find(item_ => item_.id === item.consultant.personnel?.id) !== undefined

                      // Siège sees only valited DE by direction or DE en cours de rectification (après demande de rectification par siège)
                      if (
                           isUserFromSiege &&
                           !["TYPE_DG_VALIDATION", "TYPE_ADMIN_DEMANDE_RECTIFICATION", "TYPE_IA_RECTIFICATION_FOR_ADMIN"].find(
                                item_ => item_ === item.dernierSuiviDemandeEmbauche.type
                           )
                      ) {
                           return false
                      }

                      // DG sees only submitted DE from responsable or DE en cours de rectification (après demande de rectification par DG)
                      if (
                           isUserFromDirection &&
                           ![
                                "TYPE_IA_DEMANDE_EMBAUCHE",
                                "TYPE_IARESP_VALIDATION",
                                "TYPE_DG_DEMANDE_RECTIFICATION",
                                "TYPE_IA_RECTIFICATION_FOR_DG",
                                "TYPE_IA_RECTIFICATION_FOR_IARESP",
                           ].find(item_ => item_ === item.dernierSuiviDemandeEmbauche.type)
                      ) {
                           return false
                      }

                      if (
                           isUserResponsableOfResponsable &&
                           !isUserFromDirection &&
                           !["TYPE_IA_DEMANDE_EMBAUCHE", "TYPE_IA_RECTIFICATION_FOR_IARESP", "TYPE_IARESP_DEMANDE_RECTIFICATION"].find(
                                item_ => item_ === item.dernierSuiviDemandeEmbauche.type
                           )
                      ) {
                           return false
                      }

                      // Responsable sees candidate all along the workflow, same for responsable fo responsable
                      return true
                 })
               : []
     })()

     return (
          <div className={"d-flex justify-content-center"}>
               <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                    <MyCard.Header rightSideContent={<span></span>}>
                         Demandes d'embauche {listingQuery.isFetching && <MySimpleSpinner size={"sm"} />}
                         {!listingQuery.isFetching && !listingQuery.isError && <span>( {filtredDemandesEmbauche.length} ) </span>}
                    </MyCard.Header>
                    <MyCard.Body>
                         {/* IS FETCHING */}
                         {listingQuery.isFetching && (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner size={"xl"} />
                              </div>
                         )}

                         {/* IS ERROR */}
                         {!listingQuery.isFetching && listingQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{listingQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => listingQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}

                         {/* WITH NO DATA */}
                         {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data!.length === 0 && (
                              <MyAlert type={"primary"} classNames={"mb-4"}>
                                   Aucune demande d'embauche en cours
                              </MyAlert>
                         )}

                         {/* WITH DATA */}
                         {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data!.length > 0 && (
                              <>
                                   {filtredDemandesEmbauche.length === 0 && <MyAlert type={"primary"}>Aucune demande d'embauche à traiter</MyAlert>}
                                   <div className={"row"}>
                                        {filtredDemandesEmbauche.map((item, index) => {
                                             return (
                                                  <div className={"col-md-4 mb-4"} key={index}>
                                                       <DemandesEmbaucheListingItem item={item} />
                                                  </div>
                                             )
                                        })}
                                   </div>
                              </>
                         )}
                    </MyCard.Body>
               </MyCard>
          </div>
     )
}

export default DemandesEmbaucheListing
