import axios from "axios"
import { IPersonnelArretMaladieModel, IPersonnelDdcModel, IPersonnelDeclarationMensuelleModel, IPersonnelModel } from "@common-models/*"
import {
     CONST_API_PERSONNEL_ADD_PERSONNELS_TO_TEAM_ENDPOINT,
     CONST_API_PERSONNEL_COMPTE_DISABLE_ACCOUNT_ENDPOINT,
     CONST_API_PERSONNEL_COMPTE_ENABLE_ACCOUNT_ENDPOINT,
     CONST_API_PERSONNEL_GET_DDC_ENDPOINT,
     CONST_API_PERSONNEL_GET_MALADIES_ENDPOINT,
     CONST_API_PERSONNEL_LISTING_DETAILS_ENDPOINT,
     CONST_API_PERSONNEL_ORGANIGRAMME_ENDPOINT,
     CONST_API_PERSONNEL_POST_GET_DECLARATIONS_ENDPOINT,
     CONST_API_PERSONNEL_REMOVE_PERSONNELS_FROM_TEAM_ENDPOINT,
     CONST_API_PERSONNEL_SET_INFO_PERSO_ENDPOINT,
     CONST_API_PERSONNEL_SET_INFO_PRO_ENDPOINT,
     CONST_API_PERSONNEL_SET_NFC_ENDPOINT,
} from "../../../endpoints"
import {
     IGetPersonnelDdcRequestModel,
     IGetPersonnelDeclarationsRequestModel,
     IGetPersonnelDetailsResponseModel,
     IGetPersonnelMaladiesRequestModel,
     ISetPersonnelInfoPersoRequestModel,
     ISetPersonnelInfoProRequestModel,
} from "./_models"
import { IPersonnelOrganigrammeResponse } from "../details/organigramme/PersonnelsOrganigramme"

export function getPersonnelDeclarationsRequest(id: number, payload: IGetPersonnelDeclarationsRequestModel) {
     return axios.post<IPersonnelDeclarationMensuelleModel[]>(CONST_API_PERSONNEL_POST_GET_DECLARATIONS_ENDPOINT(id), payload)
}

export function getPersonnelDdcRequest(id: number, payload: IGetPersonnelDdcRequestModel) {
     return axios.post<IPersonnelDdcModel[]>(CONST_API_PERSONNEL_GET_DDC_ENDPOINT(id), payload)
}

export function getPersonnelMaladiesRequest(id: number, payload: IGetPersonnelMaladiesRequestModel) {
     return axios.post<IPersonnelArretMaladieModel[]>(CONST_API_PERSONNEL_GET_MALADIES_ENDPOINT(id), payload)
}

export function getPersonnelDetailsRequest(id: number) {
     return axios.get<IGetPersonnelDetailsResponseModel>(CONST_API_PERSONNEL_LISTING_DETAILS_ENDPOINT(id))
}

export function getPersonnelsOrgranigrammeRequest() {
     return axios.post<IPersonnelOrganigrammeResponse>(CONST_API_PERSONNEL_ORGANIGRAMME_ENDPOINT, {})
}

export function setInfoPersoPersonnelRequest(id: number, payload: ISetPersonnelInfoPersoRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key !== "avatar") formData.append(`data[${key}]`, value)
          if (key === "avatar") formData.append(`avatar`, value)
     }

     return axios.post<IGetPersonnelDetailsResponseModel>(CONST_API_PERSONNEL_SET_INFO_PERSO_ENDPOINT(id), formData)
}

export function setInfoPersoProRequest(id: number, payload: ISetPersonnelInfoProRequestModel) {
     return axios.post<IGetPersonnelDetailsResponseModel>(CONST_API_PERSONNEL_SET_INFO_PRO_ENDPOINT(id), payload)
}

export function personnelAddPersonnelsToTeamRequest(id: number, personnelsToAdd: number[]) {
     return axios.post<IGetPersonnelDetailsResponseModel>(CONST_API_PERSONNEL_ADD_PERSONNELS_TO_TEAM_ENDPOINT(id), { personnelsToAdd })
}

export function personnelRemovePersonnelsFromTeamRequest(id: number, personnelsToRemove: number[]) {
     return axios.post<IGetPersonnelDetailsResponseModel>(CONST_API_PERSONNEL_REMOVE_PERSONNELS_FROM_TEAM_ENDPOINT(id), { personnelsToRemove })
}

export function personnelEnableAccountRequest(id: number) {
     return axios.post(CONST_API_PERSONNEL_COMPTE_ENABLE_ACCOUNT_ENDPOINT(id), {})
}

export function personnelDisableAccountRequest(id: number, dateFinAccess: string) {
     return axios.post(CONST_API_PERSONNEL_COMPTE_DISABLE_ACCOUNT_ENDPOINT(id), { dateFinAccess })
}

export function personnelSetNfcRequest(id: number, nfc_id: number | null) {
     return axios.post<IPersonnelModel>(CONST_API_PERSONNEL_SET_NFC_ENDPOINT(id), { nfc: nfc_id })
}
