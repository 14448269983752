import React from "react"
import "../listing/styles.scss"
import { useQuery } from "react-query"
import { IConsultantCraModel } from "@common-models/*"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import moment from "moment"
import {
     CONST_CRA_DETAILS_ABSCENCE_AUTORISEE,
     CONST_CRA_DETAILS_ABSCENCE_CEX,
     CONST_CRA_DETAILS_ABSCENCE_CMA,
     CONST_CRA_DETAILS_ABSCENCE_CPA,
     CONST_CRA_DETAILS_ABSCENCE_CSS,
     CONST_CRA_DETAILS_ABSCENCE_FERIES,
     CONST_CRA_DETAILS_ABSCENCE_JOURS_NON_TRAVAILLES_FORFAIT,
     CONST_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR,
     CONST_CRA_DETAILS_ABSCENCE_RTT,
     CONST_CRA_DETAILS_ABSCENCE_SFP,
     CONST_ENTITE_CODE_GS,
} from "@common-constants/*"
import { useConsultantDeclarationDetailsContext } from "./_ConsultantDeclarationDetails"
import { getDeclarationActiveCra } from "../core/_requests"

const REACT_QUERY_KEY_GET_CONSULTANT_CRA = "REACT_QUERY_KEY_GET_CONSULTANT_CRA"
const ConsultantDeclarationDetailsCra = ({ declaration_id }: { declaration_id: number }) => {
     const context = useConsultantDeclarationDetailsContext()

     const declarationActiveCraQuery = useQuery<IConsultantCraModel, AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_CRA, () =>
          getDeclarationActiveCra(declaration_id).then(r => r.data)
     )

     // Is fetching
     if (declarationActiveCraQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />{" "}
               </div>
          )
     }

     // Error
     if (!declarationActiveCraQuery.isFetching && declarationActiveCraQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{declarationActiveCraQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => declarationActiveCraQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     // Empty data
     if (!declarationActiveCraQuery.isFetching && !declarationActiveCraQuery.isError && !declarationActiveCraQuery.data) {
          return (
               <div className={"text-center p-4"}>
                    <p style={{ fontWeight: 500 }}>Aucun CRA n'a été ajouté par le collaborateur ...</p>
               </div>
          )
     }

     // Heures pour chaque ligne de mission
     const heuresParRefMissionParJour: IDeclarationCraHeuresParRefMissionParJourModel[] = (() => {
          let list: IDeclarationCraHeuresParRefMissionParJourModel[] = []

          declarationActiveCraQuery.data?.consultantCraDetails.map(craDetails => {
               craDetails.consultantCraDetailsHeures.map(craDetailsHeure => {
                    const ref = craDetailsHeure.mission ? (craDetailsHeure.mission.reference as string) : "INTERCONTRAT"
                    const id = craDetailsHeure.mission ? (craDetailsHeure.mission.id as number) : 0
                    const index = list.findIndex(item => item.refMission === ref)
                    if (index === -1) {
                         list.push({ idMission: id, refMission: ref, items: [{ jour: craDetails.date, heures: craDetailsHeure.heures }] })
                    } else {
                         list[index] = { ...list[index], items: [...list[index].items, { jour: craDetails.date, heures: craDetailsHeure.heures }] }
                    }
               })
          })
          return list
     })()

     // Jours avec absence
     const joursAvecAbsence: IJoursAvecAbsenceModel[] = (() => {
          let list: IJoursAvecAbsenceModel[] = []
          declarationActiveCraQuery.data?.consultantCraDetails.map(craDetails => {
               if (craDetails.absence) list.push({ absence: craDetails.absence, heuresAbsence: craDetails.absenceHeures, jour: craDetails.date })
          })

          return list
     })()

     // Une row avec les jours du mois
     const TableHeadRow = () => {
          return (
               <tr className={"px-2"}>
                    <th className="col fw-bold sticky-column"></th>
                    {declarationActiveCraQuery.data?.consultantCraDetails.map((item, key) => {
                         return (
                              <th key={item.id} className={`text-center text-primary fw-bold ${key === declarationActiveCraQuery.data?.consultantCraDetails.length - 1 && "pe-4"}`}>
                                   {item.date.format("DD")}
                              </th>
                         )
                    })}
               </tr>
          )
     }

     // Heures de travail pour chaque ligne de mission
     const TableBodyHeuresTravailParRefMissionRows = () => {
          return (
               <>
                    <tr>
                         <td scope="row" className={"border-end sticky-column"}>
                              <span className={"d-flex align-items-center text-primary fw-bold ps-sm-2"}>ACTIVITÉS</span>
                         </td>
                    </tr>

                    {heuresParRefMissionParJour.map((item, key) => (
                         <tr key={key}>
                              <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                                   {item.refMission}
                              </td>
                              {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key_) => {
                                   // Chaque réf de mission doit avoir toute la ligne d'initialisé (chaque jour doit avoir une heure)
                                   const rowReferenceMission = heuresParRefMissionParJour.find(item_ => item_.refMission === item.refMission)!.items
                                   const cellHeures = rowReferenceMission.find(item__ => item__.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY"))
                                   return (
                                        <td
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             key={key_}
                                        >
                                             <span>{cellHeures!.heures > 0 ? cellHeures!.heures : ""} </span>
                                        </td>
                                   )
                              })}
                         </tr>
                    ))}
               </>
          )
     }

     // Chaque type d'absence a sa propre row
     const TableBodyHeuresAbsenceParTypeDeAbsenceRows = () => {
          return (
               <>
                    <tr className={""}>
                         <td scope="row" className={"border-end sticky-column"}>
                              <span className={"text-primary fw-bold ps-sm-2"}>ABSENCES</span>
                         </td>
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Congés Payés
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_CPA && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    {declarationActiveCraQuery.data?.declaration?.consultant?.entite?.code === CONST_ENTITE_CODE_GS && (
                         <tr>
                              <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                                   RTT - Réduction du temps de travail
                              </td>
                              {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                                   const item = joursAvecAbsence.find(
                                        item => item.absence === CONST_CRA_DETAILS_ABSCENCE_RTT && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                                   )
                                   return (
                                        <td
                                             key={key}
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                        >
                                             {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                        </td>
                                   )
                              })}
                         </tr>
                    )}

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Absence autorisée
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_AUTORISEE && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Fériés
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_FERIES && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Maladie
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_CMA && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Congés exceptionnels
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_CEX && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Repos compensateur
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Stage de formation
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_SFP && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Congés sans solde
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence === CONST_CRA_DETAILS_ABSCENCE_CSS && item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Non travaillé (forfait)
                         </td>
                         {declarationActiveCraQuery.data?.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item =>
                                        item.absence === CONST_CRA_DETAILS_ABSCENCE_JOURS_NON_TRAVAILLES_FORFAIT &&
                                        item.jour.format("DD/MM/YYYY") === craDetails.date.format("DD/MM/YYYY")
                              )
                              return (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") === craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>
               </>
          )
     }

     const NavigationFooter = () => {
          return (
               <>
                    <div className="d-flex justify-content-between">
                         <div>
                              <button type="button" className="btn btn-sm btn-light-primary d-flex align-items-center me-3" onClick={() => context.setNavigationStep("ddc")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> DDC
                              </button>
                         </div>

                         <div className={"d-flex"}>
                              {context.isActionDemandeRectifAvailable && (
                                   <button type="button" className="btn btn-sm btn-warning d-flex align-items-center me-2" onClick={() => context.handleDemandeRectification()}>
                                        <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                        <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                   </button>
                              )}
                              {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                                   <>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-success d-flex align-items-center me-2"
                                             onClick={() => context.handleValidationDemandeRegul()}
                                        >
                                             <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                             <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                             <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                        </button>
                                   </>
                              )}
                              <button type="button" className="btn btn-sm btn-primary d-flex align-items-center " onClick={() => context.setNavigationStep("ndf")}>
                                   NDF <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                              </button>
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               <div className="table-responsive text-nowrap mb-4" style={{ overflowY: "hidden" }}>
                    <table id={"cra-table"} className={`table m-0`}>
                         {/* Begin::thead */}
                         <thead className="thead-dark border-bottom border-top">
                              <TableHeadRow />
                         </thead>

                         {/* End::thead */}

                         {/* Begin::tbody */}
                         <tbody className={"border-top border-bottom"}>
                              {/* Begin::Heures de travail */}
                              <TableBodyHeuresTravailParRefMissionRows />

                              {/* End::Heures de travail */}

                              {/* Begin::Absences */}
                              <TableBodyHeuresAbsenceParTypeDeAbsenceRows />

                              {/* End::Absences */}
                         </tbody>

                         {/* End::tbody */}
                    </table>
               </div>
               <NavigationFooter />
          </>
     )
}

export default ConsultantDeclarationDetailsCra

interface IJoursAvecAbsenceModel {
     jour: moment.Moment
     absence: string
     heuresAbsence: number
}

interface IDeclarationCraHeuresParRefMissionParJourModel {
     idMission: number
     refMission: string
     items: { heures: number; jour: moment.Moment }[]
}
