import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"
import Annuaire from "./annuaire/Annuaire"
import ConnectedPersonnelDeclarationsList from "./mes-activites/declarations/ConnectedPersonnelDeclarationsList"
import ConnectedPersonnelNdfListing from "./mes-activites/ndf/listing/ConnectedPersonnelNdfListing"
import _PersonnelNdfCreateForm from "./declaration/ndf/create/_PersonnelNdfCreateForm"
import ConnectedUserCreateDeclarationForm from "./mes-activites/create/ConnectedUserCreateDeclarationForm"
import ConnectedPersonnelDeclarationDetails from "./mes-activites/declarations/details/ConnectedPersonnelDeclarationDetails"
import { ConnectedUserNdfDepensesWrapper } from "./declaration/ndf/depenses/_PersonnelNdfDepenses"
import { CompteHeader } from "./mon-compte/CompteHeader"
import CompteInfoPerso from "./mon-compte/infoPerso/CompteInfoPerso"
import { CompteParametres } from "./mon-compte/parametres/CompteParametres"
import CompteDocuments from "./mon-compte/CompteDocuments"
import ConnectedPersonnelMaladiesListing from "./mes-activites/maladie/listing/ConnectedPersonnelMaladiesListing"
import ConnectedUserDdcListing from "./mes-activites/ddc/listing/ConnectedPersonnelDdcListing"

const urlParams = new URLSearchParams(window.location.search)

const PersonnelRouting = () => {
     const location_state = useLocation().state as { breadCrumbPageTitle: string }
     const breadCrumbPageTitle = location_state?.breadCrumbPageTitle || (urlParams.get("breadCrumbPageTitle") as string)

     return (
          <Routes>
               <Route
                    element={
                         <>
                              <Outlet />
                         </>
                    }
               >
                    <Route
                         path="annuaire"
                         element={
                              <>
                                   <PageTitle breadcrumbs={annuaireBreadCrumbs} />
                                   <Annuaire />
                              </>
                         }
                    />

                    <Route path={"mes-activites"}>
                         <Route path={"declarations"}>
                              <Route
                                   path=""
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={declarationsBreadCrumbs} />
                                             <ConnectedPersonnelDeclarationsList />
                                        </>
                                   }
                              />
                              <Route
                                   path="create"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={declarationsCreateBreadCrumbs} />
                                             <ConnectedUserCreateDeclarationForm />
                                        </>
                                   }
                              />
                              <Route
                                   path=":declaration_id/details"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={declarationsBreadCrumbs}>{breadCrumbPageTitle}</PageTitle>
                                             <ConnectedPersonnelDeclarationDetails />
                                        </>
                                   }
                              />
                         </Route>

                         <Route
                              path="ddc"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ddcBreadCrumbs} />
                                        <ConnectedUserDdcListing />
                                   </>
                              }
                         />

                         <Route
                              path="maladies"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={maladiesBreadCrumbs} />
                                        <ConnectedPersonnelMaladiesListing />
                                   </>
                              }
                         />

                         <Route path={"ndf"}>
                              <Route
                                   path=""
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={ndfBreadCrumbs} />
                                             <ConnectedPersonnelNdfListing />
                                        </>
                                   }
                              />
                              <Route
                                   path="create"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={ndfCreateBreadCrumbs} />
                                             <_PersonnelNdfCreateForm />
                                        </>
                                   }
                              />
                              <Route
                                   path=":ndf_id/depenses"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={ndfBreadCrumbs}>{breadCrumbPageTitle}</PageTitle>
                                             <ConnectedUserNdfDepensesWrapper />
                                        </>
                                   }
                              />
                         </Route>
                    </Route>

                    <Route
                         path={"mon-compte"}
                         element={
                              <div className={"d-flex justify-content-center"}>
                                   <div className={"w-100 w-lg-75 w-xxl-65"}>
                                        <CompteHeader />
                                        <Outlet />
                                   </div>
                              </div>
                         }
                    >
                         <Route
                              path="profil"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={monCompteBreadCrumbs}>Informations perso.</PageTitle>
                                        <CompteInfoPerso />
                                   </>
                              }
                         />

                         <Route
                              path="settings"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={monCompteBreadCrumbs}>Paramètres du compte</PageTitle>
                                        <CompteParametres />
                                   </>
                              }
                         />

                         <Route
                              path="mes-documents"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={monCompteBreadCrumbs}>Mes documents</PageTitle>
                                        <CompteDocuments />
                                   </>
                              }
                         />

                         <Route index element={<Navigate to="profil" />} />
                    </Route>

                    {/*Default route*/}
                    <Route index element={<Navigate to="annuaire" />} />
               </Route>
          </Routes>
     )
}

const annuaireBreadCrumbs: Array<PageLink> = [
     {
          title: "Annuaire interne",
          path: "/personnel/annuaire",
     },
]

const declarationsBreadCrumbs: Array<PageLink> = [
     {
          title: "Déclarations",
          path: "/personnel/mes-activites/declarations",
     },
]

const declarationsCreateBreadCrumbs: Array<PageLink> = [
     ...declarationsBreadCrumbs,
     {
          title: "Ajouter",
          path: "/personnel/mes-activites/declarations/create",
     },
]

const ddcBreadCrumbs: Array<PageLink> = [
     {
          title: "Demandes de congés",
          path: "/personnel/ddc",
     },
]

const maladiesBreadCrumbs: Array<PageLink> = [
     {
          title: "Arrêts maladie",
          path: "/personnel/maladies",
     },
]

const ndfBreadCrumbs: Array<PageLink> = [
     {
          title: "Notes de frais",
          path: "/personnel/mes-activites/ndf",
     },
]

const ndfCreateBreadCrumbs: Array<PageLink> = [
     ...ndfBreadCrumbs,
     {
          title: "Ajouter",
          path: "/personnel/mes-activites/ndf/create",
     },
]

const monCompteBreadCrumbs: Array<PageLink> = [
     {
          title: "Mon compte",
          path: "/personnel/mon-compte",
     },
]
export default PersonnelRouting
