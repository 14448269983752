import React, { Suspense, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { I18nProvider } from "@common-metronic/i18n/i18nProvider"
import { LayoutProvider, LayoutSplashScreen } from "@common-metronic/layout/core"
import { MasterInit } from "@common-metronic/layout/MasterInit"
import { toast, ToastContainer } from "react-toastify"
import { CONST_DEFAULT_TOAST_OPTIONS } from "../common/constants"
import { AuthInit } from "./AppContext"
import useAuthHelper from "@common-hooks/useAuthHelpers"
import axios from "axios"
import { useUpdateCheck } from "react-update-notification"

const App = () => {
     const { setupAxios } = useAuthHelper()
     const { status, reloadPage } = useUpdateCheck({
          type: "interval",
          interval: 10000,
          ignoreServerCache: true,
     })

     useEffect(() => {
          setupAxios(axios)
     }, [])

     useEffect(() => {
          if (status === "available") {
               toast.info(
                    <>
                         <h5 className={"text-primary"}>Nouvelle version disponible !</h5>
                         <div>Veuillez rafraîchir la page pour mettre à jour l'application.</div>
                         <button className={"btn btn-primary btn-sm p-0 px-2 py-1 d-flex align-items-center mt-2"} onClick={reloadPage}>
                              Rafraîchir <i className={"fas fa-sync fs-7 ms-1"} />
                         </button>
                    </>,
                    {
                         autoClose: false,
                         closeButton: false,
                         closeOnClick: false,
                         position: "top-right",
                    }
               )
          }
     }, [status])

     return (
          <Suspense fallback={<LayoutSplashScreen />}>
               <I18nProvider>
                    <LayoutProvider>
                         <AuthInit>
                              <Outlet />
                              <MasterInit />
                         </AuthInit>
                         <ToastContainer {...CONST_DEFAULT_TOAST_OPTIONS} />
                    </LayoutProvider>
               </I18nProvider>
          </Suspense>
     )
}

export { App }
