import React from "react"
import MyTooltip from "@common-utils/MyTooltip"
import { useConnectedUserNdfDepensesContext } from "../_PersonnelNdfDepenses"
import { KTSVG } from "@common-metronic/helpers"
import MyCard from "@common-utils/MyCard"
import ConnectedUserNdfDepenseCreateGrouped from "./_ConnectedUserNdfDepenseCreateGrouped"
import { ConnectedUserNdfDepenseCreateSimple } from "./_ConnectedUserNdfDepenseCreateSimple"

const ConnectedUserNdfDepenseCreateForm = () => {
     const context = useConnectedUserNdfDepensesContext()

     return (
          <>
               <div className={`card`}>
                    <div className={`card-header cursor-pointer`} style={{ minHeight: "4rem" }} onClick={() => context.setIsCardDepenseSimpleCollapsed(prev => !prev)}>
                         <div className="card-title m-0">
                              <h4 className="fw-bolder m-0">Choisissez le type de dépense</h4>
                         </div>

                         <MyTooltip title={!context.isCardDepenseSimpleCollapsed ? "Cacher contenu" : "Afficher contenu"}>
                              <div className={"align-self-center"}>
                                   <KTSVG
                                        path={!context.isCardDepenseSimpleCollapsed ? "/media/icons/duotune/arrows/arr073.svg" : "/media/icons/duotune/arrows/arr072.svg"}
                                        className="svg-icon-1 svg-icon-3x"
                                   />
                              </div>
                         </MyTooltip>
                    </div>
                    <div className={`card-body ${context.isCardDepenseSimpleCollapsed && "d-none"}`}>
                         <div className="row">
                              <div
                                   className="col-lg-6"
                                   onClick={() => {
                                        context.setTypeAddDepense("simple")
                                        context.setIsCardDepenseSimpleCollapsed(true)
                                   }}
                              >
                                   <label
                                        className={`btn btn-outline btn-outline-dashed p-7 d-flex align-items-center mb-5 ${
                                             context.typeAddDepense === "simple" ? "btn-outline-primary" : "btn-outline-default"
                                        } btn-active-light-primary`}
                                   >
                                        <span className="me-5">
                                             <i className={"fas fa-file-invoice-dollar"} style={{ fontSize: "2rem" }} />
                                        </span>
                                        <span className="d-block fw-bold text-start">
                                             <span className="text-dark fw-bolder d-block fs-4 mb-2">Dépense simple</span>
                                             <span className="text-muted fw-bold fs-6">Permet d'ajouter une seule dépense</span>
                                        </span>
                                   </label>
                              </div>
                              <div
                                   className="col-lg-6"
                                   onClick={() => {
                                        context.setTypeAddDepense("group")
                                        context.setIsCardDepenseSimpleCollapsed(true)
                                   }}
                              >
                                   <label
                                        className={`btn btn-outline btn-outline-dashed p-7 d-flex align-items-center ${
                                             context.typeAddDepense === "group" ? "btn-outline-primary" : "btn-outline-default"
                                        } btn-active-light-primary`}
                                   >
                                        <span className=" me-5">
                                             <i className={"fas fa-layer-group"} style={{ fontSize: "2rem" }} />
                                        </span>
                                        <span className="d-block fw-bold text-start">
                                             <span className="text-dark fw-bolder d-block fs-4 mb-2">Dépenses groupées</span>
                                             <span className="text-muted fw-bold fs-6">Permet d'agréger plusieurs dépenses</span>
                                        </span>
                                   </label>
                              </div>
                         </div>
                    </div>
               </div>

               {context.typeAddDepense !== null && (
                    <MyCard>
                         <MyCard.Header>
                              {context.typeAddDepense === "simple" && `Dépense simple`}
                              {context.typeAddDepense === "group" && `Dépenses groupées`}
                         </MyCard.Header>
                         <MyCard.Body>
                              {context.typeAddDepense === "group" && <ConnectedUserNdfDepenseCreateGrouped />}
                              {context.typeAddDepense === "simple" && <ConnectedUserNdfDepenseCreateSimple />}
                         </MyCard.Body>
                    </MyCard>
               )}
          </>
     )
}

export default ConnectedUserNdfDepenseCreateForm
