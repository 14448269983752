import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { reInitMenu } from "@common-metronic/helpers"
import { PageDataProvider } from "@common-metronic/layout/core"
import { ThemeModeProvider } from "@common-metronic/partials"
import { ToolbarWrapper } from "@common-metronic/layout/components/toolbar"
import { Content } from "@common-metronic/layout/components/content"
import { FooterWrapper } from "@common-metronic/layout/components/footer"
import { ScrollTop } from "@common-metronic/layout/components/scroll-top"
import { Sidebar } from "./sidebar"
import { HeaderWrapper } from "./header"

const MasterLayout = () => {
     const location = useLocation()

     useEffect(() => {
          reInitMenu()
     }, [location.key])

     return (
          <PageDataProvider>
               <ThemeModeProvider>
                    <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                         <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                              <HeaderWrapper />
                              <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                                   <Sidebar />
                                   <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                        <div className="d-flex flex-column flex-column-fluid">
                                             <ToolbarWrapper />
                                             <Content>
                                                  <Outlet />
                                             </Content>
                                        </div>
                                        <FooterWrapper />
                                   </div>
                              </div>
                         </div>
                    </div>
                    <ScrollTop />
               </ThemeModeProvider>
          </PageDataProvider>
     )
}

export { MasterLayout }
