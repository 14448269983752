import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyTooltip from "@common-utils/MyTooltip"
import { useSavedFiltersSearchCandidateListContext } from "./_SavedFiltersSearchCandidateList"
import { ISavedFiltersSearchCandidateDataItemResponseModel } from "../core/_models"
import { useSavedFiltersSearchCandidateContext } from "../SavedFiltersSearchCandidate"
import { Link } from "react-router-dom"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

// Listing for >= MD breakpoint
const BrowserListItem = ({ item }: { item: ISavedFiltersSearchCandidateDataItemResponseModel }) => {
     const globalContext = useSavedFiltersSearchCandidateContext()
     const listContext = useSavedFiltersSearchCandidateListContext()

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.id],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <div className={"d-flex justify-content-between align-items-center p-4"}>
               <div className={"d-flex align-items-center"}>
                    {/* checkbox */}
                    {globalContext.isBulkSelectionEnabled.enabled && (
                         <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                              <input
                                   type="checkbox"
                                   className={"form-check-input cursor-pointer border border-secondary"}
                                   checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id)}
                                   onChange={() => handleOnCheckItem()}
                              />
                         </div>
                    )}
                    {/* User's avatar */}
                    <MyTooltip title={<>{item.responsable.prenomNom}</>}>
                         <img
                              src={
                                   item.responsable.avatarGoogleDriveId
                                        ? FileAndDriveHelper.getThumbnailDriveUrl(item.responsable.avatarGoogleDriveId)
                                        : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                              }
                              className="rounded-circle me-4"
                              style={{ width: "50px" }}
                              alt="Avatar"
                         />
                    </MyTooltip>
                    <div className={"fs-4"}>{item.titreSauvegarde}</div>
               </div>
               {/* Actions */}
               <div className={"d-flex align-items-center"}>
                    <button className={"btn btn-sm btn-outline btn-outline-danger p-0 px-3 py-2 me-2"} onClick={() => listContext.handleDeleteItems({ ids: [item.id] }).then()}>
                         <i className={"fas fa-trash fs-5"} />
                    </button>
                    <button className={"btn btn-sm btn-outline btn-outline-dark p-0 px-3 py-2 me-2"} onClick={() => listContext.handleDuplication(item)}>
                         <i className={"fas fa-clone fs-5"} />
                    </button>
                    <button
                         className={"btn btn-sm btn-outline btn-outline-dark p-0 px-3 py-2 me-2"}
                         onClick={() => listContext.handleShowElementDetails(item.id)}
                         disabled={listContext.itemDetailsInModal.loading}
                    >
                         {listContext.itemDetailsInModal.id === item.id && listContext.itemDetailsInModal.loading ? (
                              <MySimpleSpinner size={"sm"} />
                         ) : (
                              <i className={"fas fa-eye fs-5"} />
                         )}
                    </button>
                    <Link
                         to={"/recrutement/candidats/search/results"}
                         state={{ log_or_saved_filters_id: item.id }}
                         className={"btn btn-sm btn-outline btn-outline-primary p-0 px-3 py-2"}
                    >
                         <i className={"fas fa-search fs-5"} />
                    </Link>
               </div>
          </div>
     )
}

// Listing for < MD breakpoint
const MobileListItem = ({ item }: { item: ISavedFiltersSearchCandidateDataItemResponseModel }) => {
     const globalContext = useSavedFiltersSearchCandidateContext()
     const listContext = useSavedFiltersSearchCandidateListContext()
     const [areActionsVisible, setAreActionsVisible] = useState<boolean>(false)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.id],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <>
               <div className={`d-flex justify-content-between align-items-center ${areActionsVisible ? "px-4 pt-4 pb-6" : "p-4"}`}>
                    <div className={"d-flex align-items-center"}>
                         {/* checkbox */}
                         {globalContext.isBulkSelectionEnabled.enabled && (
                              <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                   <input
                                        type="checkbox"
                                        className={"form-check-input cursor-pointer border border-secondary"}
                                        checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id)}
                                        onChange={() => handleOnCheckItem()}
                                   />
                              </div>
                         )}
                         <MyTooltip title={<>{item.responsable.prenomNom}</>}>
                              <img
                                   src={
                                        item.responsable.avatarGoogleDriveId
                                             ? FileAndDriveHelper.getThumbnailDriveUrl(item.responsable.avatarGoogleDriveId)
                                             : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                   }
                                   className="rounded-circle me-4"
                                   style={{ width: "50px" }}
                                   alt="Avatar"
                              />
                         </MyTooltip>
                         <div className={"fs-4"}>Mobile {item.titreSauvegarde}</div>
                    </div>
                    <div className={"d-flex align-items-center"}>
                         <span
                              className={`p-4 fas fa-${areActionsVisible ? "eye-slash" : "ellipsis-vertical"} text-dark fs-2 p-4 cursor-pointer`}
                              onClick={() => setAreActionsVisible(prev => !prev)}
                         />
                    </div>
               </div>
               {areActionsVisible && (
                    <div className={"d-flex justify-content-center mb-2"}>
                         <button
                              className={"btn btn-sm btn-outline btn-outline-danger p-0 px-3 py-2 me-2"}
                              onClick={() => listContext.handleDeleteItems({ ids: [item.id] }).then()}
                         >
                              <i className={"fas fa-trash fs-5"} />
                         </button>
                         <button className={"btn btn-sm btn-outline btn-outline-dark p-0 px-3 py-2 me-2"}>
                              <i className={"fas fa-clone fs-5"} />
                         </button>
                         <button
                              className={"btn btn-sm btn-outline btn-outline-dark p-0 px-3 py-2 me-2"}
                              onClick={() => listContext.handleShowElementDetails(item.id)}
                              disabled={listContext.itemDetailsInModal.loading}
                         >
                              {listContext.itemDetailsInModal.id === item.id && listContext.itemDetailsInModal.loading ? (
                                   <MySimpleSpinner size={"sm"} />
                              ) : (
                                   <i className={"fas fa-eye fs-5"} />
                              )}
                         </button>
                         <button className={"btn btn-sm btn-outline btn-outline-primary p-0 px-3 py-2"}>
                              <i className={"fas fa-search fs-5"} />
                         </button>
                    </div>
               )}
          </>
     )
}

const SavedFiltersSearchCandidateListItem = ({ item }: { item: ISavedFiltersSearchCandidateDataItemResponseModel }) => (
     <>
          {/* Display BrowserList only on breakpoint >= md */}
          <div className="d-none d-md-block">
               <BrowserListItem item={item} />
          </div>
          <div className="d-block d-md-none">
               <MobileListItem item={item} />
          </div>
     </>
)

export default SavedFiltersSearchCandidateListItem
