import React from "react"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useSearchResultsContext } from "./CandidatsRechercheResults"
import useSelectReact from "@common-hooks/useSelectReact"
import CreatableSelect from "react-select/creatable"
import { useThemeMode } from "@common-metronic/partials"

interface ICustomCreatableFieldPropsModel {
     name: string
     options: Array<{ label: string; value: string | boolean } | any>
     value: any
     onChange: (value: any) => void
     isClearable?: boolean
}
// Simple search bar
const CandidatsRechercheResultsSimpleSearchBar = () => {
     const context = useSearchResultsContext()

     function handleSubmit(values: { searchKeywords?: string[] }) {
          context.setItemInPreview(null)
          context.setFilters(prev => {
               return {
                    page: context.INITIAL_FILTERS_EMPTY_STATE.page,
                    pageLength: context.INITIAL_FILTERS_EMPTY_STATE.pageLength,
                    filters: { ...context.filtersFormikRef.current.values },
               }
          })
     }

     function handleReset() {
          context.setItemInPreview(null)

          context.simpleSearchBarFormikRef.current.resetForm() // Reset local form
          context.filtersFormikRef.current.resetForm() // Reset search bar form
          context.setFilters(() => ({ ...context.INITIAL_FILTERS_EMPTY_STATE })) // Reset payload filters
          context.setInitialFiltersForForm(undefined)

          context.simpleSearchBarFormikRef.current.setSubmitting(true)
     }

     function handleClickShowFilters() {
          context.setAreFiltersVisible(prev => !prev)
          // Hide preview if it was opened before showing filters.
          context.setItemInPreview(prev => {
               if (prev) {
                    return { ...prev, isPreviewVisible: false }
               }
               return null
          })
     }

     const initialValues: { searchKeywords?: string[] } = {
          searchKeywords: context.initialFiltersForForm?.searchKeywords,
     }

     return (
          <Formik<{ searchKeywords?: string[] }> initialValues={initialValues} onSubmit={handleSubmit} innerRef={context.simpleSearchBarFormikRef} enableReinitialize={true}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete={"off"}>
                              <div className="input-group">
                                   <MyTooltip title={"Voir les filtres"}>
                                        <button type={"button"} className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={() => handleClickShowFilters()}>
                                             <i className={"fas fa-filter fs-4 text-dark"} />
                                        </button>
                                   </MyTooltip>
                                   <div className={"flex-grow-1"}>
                                        <CustomCreatableField
                                             name={"searchKeywords"}
                                             options={[]}
                                             value={helpers.values.searchKeywords}
                                             onChange={val => {
                                                  helpers.setFieldValue("searchKeywords", val).then()
                                                  context.filtersFormikRef.current.setFieldValue("searchKeywords", val).then()
                                             }}
                                        />
                                   </div>
                                   <button type={"submit"} className={"btn btn-sm btn-outline btn-outline-secondary"} disabled={helpers.isSubmitting}>
                                        {helpers.isSubmitting ? <MySimpleSpinner size={"sm"} classNames={"text-dark"} /> : <i className={"fas fa-search text-dark"} />}
                                   </button>
                                   <MyTooltip title={"Réinitialiser les filtres"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-secondary"}
                                             onClick={() => handleReset()}
                                             disabled={helpers.isSubmitting}
                                        >
                                             <i className={"fas fa-sync text-dark"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default CandidatsRechercheResultsSimpleSearchBar

const CustomCreatableField: React.FC<ICustomCreatableFieldPropsModel> = ({ options, name, value, onChange, isClearable = true }) => {
     const { mode } = useThemeMode()

     const { formatGroupLabel, styles } = useSelectReact()
     const customStyles = {
          ...styles(false),
          control: baseStyles => ({
               ...baseStyles,
               borderColor: " var(--kt-secondary)",
               borderRight: "none",
               boxShadow: "none",
               borderRadius: "0rem!important",
               padding: "2px 0px",
               backgroundColor: mode === "light" ? "white" : "var(--kt-input-solid-bg)",
               color: "var(--kt-input-solid-bg)",
               ":hover": {
                    borderColor: "var(--kt-input-border-color)",
                    boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               },
               ":active": {
                    borderColor: "var(--kt-input-border-color)",
                    boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               },
          }),
     }

     return (
          <CreatableSelect
               options={value ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
               name={name}
               formatGroupLabel={formatGroupLabel}
               value={value ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
               onChange={values => {
                    onChange(values.map((item: any) => item.value))
               }}
               noOptionsMessage={() => "Tapez vos mots clés"}
               formatCreateLabel={inputValue => `Ajouter "${inputValue}"`}
               isMulti={true}
               isClearable={isClearable}
               placeholder={"Tapez vos mots clés"}
               styles={customStyles}
          />
     )
}
