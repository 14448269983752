import axios from "axios"
import {
     CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_CHECK_AS_FACTURE_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_BL_NON_FACTURES_OF_GIVEN_MONTH_ENDPOINT,
} from "../../../../../endpoints"
import { IBonsLivraisonNonFacturesOfGivenMonthRequestModel, IBonsLivraisonNonFacturesOfGivenMonthResponseModel } from "./_models"

export function getBonsLivraisonNonFacturesOfGivenMonthRequest(payload: IBonsLivraisonNonFacturesOfGivenMonthRequestModel) {
     return axios.post<IBonsLivraisonNonFacturesOfGivenMonthResponseModel>(CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_BL_NON_FACTURES_OF_GIVEN_MONTH_ENDPOINT, payload)
}

export function checkBonsLivraisonAsFactureRequest(ids: number[]) {
     return axios.post(CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_CHECK_AS_FACTURE_ENDPOINT, { ids })
}
