import React, { createContext, useContext, useRef } from "react"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyPagination from "@common-utils/MyPagination"
import { useBonsLivraisonManquantsOfGivenMonthListingContext } from "./BonsLivraisonManquantsOfGivenMonthListing"
import BonsLivraisonManquantsOfGivenMonthListingListItem from "./_BonsLivraisonManquantsOfGivenMonthListingListItem"
import { IBonsLivraisonManquantsOfGivenMonthListingListContextPropsModel } from "./core/_models"
import moment from "moment"

const BonsLivraisonManquantsOfGivenMonthListingListContext = createContext<IBonsLivraisonManquantsOfGivenMonthListingListContextPropsModel>(
     {} as IBonsLivraisonManquantsOfGivenMonthListingListContextPropsModel
)
export const useBonsLivraisonManquantsOfGivenMonthListingListContext = () => useContext(BonsLivraisonManquantsOfGivenMonthListingListContext)
const BonsLivraisonManquantsOfGivenMonthListingList = () => {
     const context = useBonsLivraisonManquantsOfGivenMonthListingContext()
     const selectedItemRef = useRef<any>(null)

     const CardHeaderRightSide = () => {
          return (
               <>
                    {context.listingQuery.isFetching ? (
                         <MySimpleSpinner size={"sm"} />
                    ) : (
                         <>
                              {context.listingQuery.data && context.listingQuery.data.totalElementsInPage > 0 && (
                                   <div className={"d-flex align-items-center"}>
                                        {/* Pagination component*/}
                                        <MyPagination
                                             page={context.filters.page as number}
                                             pageStart={context.listingQuery.data.pageStart}
                                             pageEnd={context.listingQuery.data.pageEnd}
                                             totalPages={context.listingQuery.data.totalPages}
                                             totalElementsInAllPages={context.listingQuery.data.totalElementsInAllPages}
                                             onPreviousPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) - 1,
                                                  }))
                                                  context.setItemInPreview(null)
                                             }}
                                             onNextPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) + 1,
                                                  }))
                                                  context.setItemInPreview(null)
                                             }}
                                        />
                                   </div>
                              )}
                         </>
                    )}
               </>
          )
     }

     return (
          <BonsLivraisonManquantsOfGivenMonthListingListContext.Provider value={{ selectedItemRef }}>
               <MyCard>
                    <MyCard.Header isCollapseIconShown={false} rightSideContent={<CardHeaderRightSide />}>
                         BL manquants de {moment(context.filters.mois).format("MMM YYYY")}
                    </MyCard.Header>

                    <MyCard.Body noPadding={true}>
                         <>
                              {/* Fetching */}
                              {context.listingQuery.isFetching ? (
                                   <div className={"text-center p-5"}>
                                        <MySimpleSpinner classNames={"ms-2"} />
                                   </div>
                              ) : (
                                   <>
                                        {/* In case of error */}
                                        {context.listingQuery.error ? (
                                             <MyAlert type={"danger"}>{context.listingQuery.error.response?.data.detail}</MyAlert>
                                        ) : (
                                             <>
                                                  {/* CASE: no data */}
                                                  {context.listingQuery.data && context.listingQuery.data.data.length === 0 && (
                                                       <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>
                                                  )}

                                                  {/* CASE: If some data */}
                                                  {context.listingQuery.data && context.listingQuery.data.data.length > 0 && (
                                                       <>
                                                            {/* List elements */}
                                                            <div
                                                                 // className={"hover-scroll-overlay-y"}
                                                                 style={{
                                                                      maxHeight: context.isBulkSelectionEnabled.enabled ? "59vh" : "68vh",
                                                                      overflowY: "auto",
                                                                 }}
                                                            >
                                                                 {context.listingQuery.data.data.map((item, index) => (
                                                                      <div className="border border-bottom" key={index}>
                                                                           <BonsLivraisonManquantsOfGivenMonthListingListItem item={item} index={index} />
                                                                      </div>
                                                                 ))}
                                                            </div>
                                                       </>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}
                         </>
                    </MyCard.Body>
               </MyCard>
          </BonsLivraisonManquantsOfGivenMonthListingListContext.Provider>
     )
}

export default BonsLivraisonManquantsOfGivenMonthListingList
