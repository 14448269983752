import { ISimulationRentabiliteConsultantAvantApresResponseModel } from "../../core/_models"
import "./style.scss"
import GlobalHelper from "@common-helpers/GlobalHelper"

const ConsultantDetailsSimulateurRentabiliteTableaux = ({ results }: IConsultantDetailsSimulateurRentabiliteTableauxPropsModel) => {
     const TableSalaire = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "550px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td scope="col" colSpan={2} className="text-center border bg-primary border-white title_fixed_width">
                                        <b className="text-white" style={{ fontSize: "15px" }}>
                                             SALAIRE
                                        </b>
                                   </td>
                                   <th scope="col">Base 35h</th>
                                   <th scope="col">Heures supplémentaires</th>
                                   <th scope="col">Base 35h + HS</th>
                              </tr>
                              <tr>
                                   <th rowSpan={2} className="align-middle">
                                        Brut
                                   </th>
                                   <th>Mensuel</th>
                                   <td>
                                        <div className="d-flex justify-content-between justify-content-center">
                                             <span>{GlobalHelper.separateNumber(results.btc.avant.salaireBrutMensuel.toFixed())}</span>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(GlobalHelper.separateNumber(results.btc.apres.salaireBrutMensuel.toFixed()))}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutHeuresSuppMensuel.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(GlobalHelper.separateNumber(results.btc.apres.coutHeuresSuppMensuel.toFixed()))}
                                             </span>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.base35hPlusHeuresSuppMensuelBrut!.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.base35hPlusHeuresSuppMensuelBrut!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Annuel</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.salaireBrutAnnuel.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.salaireBrutAnnuel.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutHeuresSuppAnnuel.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutHeuresSuppAnnuel.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.base35hPlusHeuresSuppAnnuelBrut!.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.base35hPlusHeuresSuppAnnuelBrut!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th rowSpan={2} className="align-middle">
                                        Net
                                   </th>
                                   <th>Mensuel</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.salaireChargeSansHeuresSuppMensuel.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.salaireChargeSansHeuresSuppMensuel.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.salaireQueLesHeuresSuppMensuelCharge.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.salaireQueLesHeuresSuppMensuelCharge.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.base35hPlusHeuresSuppMensuelNet!.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.base35hPlusHeuresSuppMensuelNet!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Annuel</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.salaireChargeSansHeuresSuppAnnuel.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.salaireChargeSansHeuresSuppAnnuel.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.salaireQueLesHeuresSuppAnnuelCharge.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.salaireQueLesHeuresSuppAnnuelCharge.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.base35hPlusHeuresSuppAnnuelNet!.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.base35hPlusHeuresSuppAnnuelNet!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableAvantage = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "600px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td
                                        rowSpan={2}
                                        className="text-center border bg-primary border-white align-self-center title_fixed_width"
                                        style={{ fontSize: "15px", width: "159px", maxWidth: "159px" }}
                                   >
                                        <b className="text-white">AVANTAGES</b>
                                   </td>
                                   <th colSpan={3}>Tickets Restaurant</th>
                                   <th rowSpan={2}>Salaire net versé sur le bulletin de salaire</th>
                              </tr>
                              <tr>
                                   <th>Part Employeur</th>
                                   <th>Part Salarié</th>
                                   <th>Total versé sur le compte Apetiz</th>
                              </tr>
                              <tr>
                                   <th>Mensuel</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.partMensuelleTicketsRestoEmployeur.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.partMensuelleTicketsRestoEmployeur.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.partMensuelleTicketsRestoSalarie.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.partMensuelleTicketsRestoSalarie.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.totalVerseSurCompteApetizMensuel!.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.totalVerseSurCompteApetizMensuel!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.salaireNetVerseSurBulletingSalaireMensuel!.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.salaireNetVerseSurBulletingSalaireMensuel!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Annuel</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.partAnnuelleTicketsRestoEmployeur.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.partAnnuelleTicketsRestoEmployeur.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.partAnnuelleTicketsRestoSalarie.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.partAnnuelleTicketsRestoSalarie.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.totalVerseSurCompteApetizAnnuel!.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.totalVerseSurCompteApetizAnnuel!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-between">
                                             <span>{GlobalHelper.separateNumber(results.btc.avant.salaireNetVerseSurBulletingSalaireAnnuel!.toFixed())}</span>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.salaireNetVerseSurBulletingSalaireAnnuel!.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableEquivalentBrut = () => {
          return (
               <table className="table js-calculationsResults">
                    <tbody className="thead-light">
                         <tr>
                              <td className="bg-primary title_fixed_width" style={{ fontSize: "15px" }}>
                                   <b className="text-white">Équivalent brut</b>
                              </td>
                              <th>Annuel</th>
                              <th>Mensuel</th>
                         </tr>
                         <tr>
                              <th>Avec tickets restaurant</th>
                              <td>
                                   <div className="d-flex justify-content-between">
                                        <div>{GlobalHelper.separateNumber(results.btc.avant.equivalentBrutAvecTicketRestoAnnuel!.toFixed())}</div>
                                        <span className="text-primary fw-bold">
                                             {GlobalHelper.separateNumber(results.btc.apres.equivalentBrutAvecTicketRestoAnnuel!.toFixed())}
                                        </span>
                                   </div>
                              </td>
                              <td>
                                   <div className="d-flex justify-content-between">
                                        <div>{GlobalHelper.separateNumber(results.btc.avant.equivalentBrutAvecTicketRestoMensuel!.toFixed())}</div>
                                        <span className="text-primary fw-bold pe-2">
                                             {GlobalHelper.separateNumber(results.btc.apres.equivalentBrutAvecTicketRestoMensuel!.toFixed())}
                                        </span>
                                   </div>
                              </td>
                         </tr>
                         <tr>
                              <th>Sans tickets restaurant</th>
                              <td>
                                   <div className="d-flex justify-content-between">
                                        <div>{GlobalHelper.separateNumber(results.btc.avant.equivalentBrutSansTicketRestoAnnuel!.toFixed())}</div>
                                        <span className="text-primary fw-bold">
                                             {GlobalHelper.separateNumber(results.btc.apres.equivalentBrutSansTicketRestoAnnuel!.toFixed())}
                                        </span>
                                   </div>
                              </td>
                              <td>
                                   <div className="d-flex justify-content-between">
                                        <div>{GlobalHelper.separateNumber(results.btc.avant.equivalentBrutSansTicketRestoMensuel!.toFixed())}</div>
                                        <span className="text-primary fw-bold pe-2">
                                             {GlobalHelper.separateNumber(results.btc.apres.equivalentBrutSansTicketRestoMensuel!.toFixed())}
                                        </span>
                                   </div>
                              </td>
                         </tr>
                    </tbody>
               </table>
          )
     }

     const TableCout = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "550px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td scope="col" className="bg-primary  title_fixed_width" style={{ fontSize: "15px" }}>
                                        <b className="text-white">COÛTS</b>
                                   </td>
                                   <th>Annuel</th>
                                   <th>Mensuel</th>
                                   <th>Journalier</th>
                              </tr>
                              <tr>
                                   <th>Salaire Mensuel 35h</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutSalaireAnnuelCharge.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutSalaireAnnuelCharge.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutSalaireMensuelCharge.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutSalaireMensuelCharge.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutSalaireJournalierCharge.toFixed(2))}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutSalaireJournalierCharge.toFixed(2))}</span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Heures Supplémentairers</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutHeuresSuppAnnuelCharge.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutHeuresSuppAnnuelCharge.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutHeuresSuppMensuelCharge.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutHeuresSuppMensuelCharge.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutHeuresSuppJournalierCharge.toFixed(2))}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.coutHeuresSuppJournalierCharge.toFixed(2))}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Tickets Restaurant</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.partAnnuelleTicketsRestoEmployeur.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.partAnnuelleTicketsRestoEmployeur.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.partMensuelleTicketsRestoEmployeur.toFixed())}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.partMensuelleTicketsRestoEmployeur.toFixed())}
                                             </span>
                                        </div>
                                   </td>
                                   <td>{GlobalHelper.separateNumber(results.btc.apres.partJournaliereTicketsRestoEmployeur)}</td>
                              </tr>
                              <tr className="fw-bold">
                                   <th>Coût Total</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutTotalSalarieAnnuel!.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutTotalSalarieAnnuel!.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutTotalSalarieMensuel!.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutTotalSalarieMensuel!.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coutTotalSalarieJournalier!.toFixed(2))}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coutTotalSalarieJournalier!.toFixed(2))}</span>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableMarge = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "550px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td className="bg-primary title_fixed_width" style={{ fontSize: "15px" }}>
                                        <b className="text-white">MARGE</b>
                                   </td>
                                   <th>Annuel</th>
                                   <th>Mensuel</th>
                                   <th>Journalier</th>
                              </tr>
                              <tr className={"fw-bold"}>
                                   <th>Chiffre d'affaires</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.chiffreAffairesAnnuel.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.chiffreAffairesAnnuel.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.chiffreAffairesMensuel.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.chiffreAffairesMensuel.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.chiffreAffairesJournalier.toFixed(2))}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.chiffreAffairesJournalier.toFixed(2))}</span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>MBP</th>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.margeBrutProductionAnnuelle!.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.margeBrutProductionAnnuelle!.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.margeBrutProductionMensuelle!.toFixed())}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.margeBrutProductionMensuelle!.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-between pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.margeBrutProductionJournaliere!.toFixed(2))}</div>
                                             <span className="text-primary fw-bold">
                                                  {GlobalHelper.separateNumber(results.btc.apres.margeBrutProductionJournaliere!.toFixed(2))}
                                             </span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>MBP en %</th>
                                   <td colSpan={3}>
                                        <div className="d-flex justify-content-center">
                                             <div className="fs-1 me-10">
                                                  <span className={"me-2"}>{GlobalHelper.separateNumber(results.btc.avant.margeBrutProductionEnPourcentage.toFixed(2))}</span>
                                                  {(results.btc.avant.margeBrutProductionEnPourcentage as number) > 31 ? (
                                                       <span className={"fas fa-thumbs-up text-success"} />
                                                  ) : (
                                                       <>
                                                            {(results.btc.avant.margeBrutProductionEnPourcentage as number) >= 25 ? (
                                                                 <span className={"fas fa-face-smile text-primary"} />
                                                            ) : (
                                                                 <span className={"fas fa-thumbs-down text-danger"} />
                                                            )}
                                                       </>
                                                  )}
                                             </div>
                                             <div className="fs-1">
                                                  <span className={"me-2 text-primary"}>
                                                       {GlobalHelper.separateNumber(results.btc.apres.margeBrutProductionEnPourcentage.toFixed(2))}
                                                  </span>
                                                  {(results.btc.apres.margeBrutProductionEnPourcentage as number) > 31 ? (
                                                       <span className={"fas fa-thumbs-up text-success"} />
                                                  ) : (
                                                       <>
                                                            {(results.btc.apres.margeBrutProductionEnPourcentage as number) >= 25 ? (
                                                                 <span className={"fas fa-face-smile text-primary"} />
                                                            ) : (
                                                                 <span className={"fas fa-thumbs-down text-danger"} />
                                                            )}
                                                       </>
                                                  )}
                                             </div>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableInfoSupp = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "450px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td
                                        scope="col"
                                        colSpan={2}
                                        className="text-center border bg-primary border-white"
                                        style={{ fontSize: "15px", width: "213px", maxWidth: "213px" }}
                                   >
                                        <b className="text-white">INFO. SUPPLÉMENTAIRES</b>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Salaire horaire brut (35h)</th>
                                   <td>
                                        <div className="d-flex justify-content-between pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.salaireHoraireBrut?.toFixed(2))}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.salaireHoraireBrut?.toFixed(2))}</span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Coeff. charges salariales (35h)</th>
                                   <td>
                                        <div className="d-flex justify-content-between pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.avant.coeffChargesSalariales.toFixed(2))}</div>
                                             <span className="text-primary fw-bold">{GlobalHelper.separateNumber(results.btc.apres.coeffChargesSalariales.toFixed(2))}</span>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Ticket restaurant part salarié</th>
                                   <td>{GlobalHelper.separateNumber(results.btc.apres.partJournaliereTicketsRestoSalarie)}</td>
                              </tr>
                              <tr>
                                   <th>Ticket restaurant part employeur</th>
                                   <td>{GlobalHelper.separateNumber(results.btc.apres.partJournaliereTicketsRestoEmployeur)}</td>
                              </tr>
                              <tr>
                                   <th>Ticket restaurant total</th>
                                   <td>{(results.btc.apres.partJournaliereTicketsRestoEmployeur + results.btc.apres.partJournaliereTicketsRestoSalarie).toFixed(2)}</td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     return (
          <>
               <TableSalaire />
               <TableAvantage />
               <TableEquivalentBrut />
               <TableCout />
               <TableMarge />
               <TableInfoSupp />
          </>
     )
}

interface IConsultantDetailsSimulateurRentabiliteTableauxPropsModel {
     results: ISimulationRentabiliteConsultantAvantApresResponseModel
}

export default ConsultantDetailsSimulateurRentabiliteTableaux
