import React from "react"
import { useQueryClient } from "react-query"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { IPersonnelArretMaladieModel, IPersonnelNdfModel } from "@common-models/*"
import { CONST_CIVILITE_MME } from "@common-constants/*"
import { useConnectedUserNdfListingContext } from "./ConnectedPersonnelNdfListing"
import useSwal from "@common-hooks/useSwal"
import { useAuth } from "../../../../../AppContext"
import MyCard from "@common-utils/MyCard"
import { useNavigate } from "react-router-dom"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyTooltip from "@common-utils/MyTooltip"
import { deletePersonnelNdfRequest } from "../../../declaration/ndf/core/_requests"

const ConnectedPersonnelNdfListingItem = ({ item }: ConnectedUserMaladiesListingItemPropsModel) => {
     const { currentUser } = useAuth()
     const context = useConnectedUserNdfListingContext()
     const { swal } = useSwal()
     const queryClient = useQueryClient()
     const navigate = useNavigate()

     function handleSupprimerNdf(item: IPersonnelNdfModel) {
          swal.fire({
               icon: "warning",
               title: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir supprimer la note de frais du mois de ${item.declaration?.mois?.format(
                    "MMMM YYYY"
               )}?`,
               text: "Le détail ainsi que les justificatifs liés à cette NDF seront également supprimés.",
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deletePersonnelNdfRequest(item.id as number)
                         .then(() => {
                              queryClient.setQueryData<IPersonnelArretMaladieModel[] | undefined>(
                                   context.REACT_QUERY_KEY_GET_LISTING,
                                   (prev: IPersonnelArretMaladieModel[] | undefined) => {
                                        if (prev) {
                                             const index = prev.findIndex(item => item.id === item.id)
                                             if (index >= 0) {
                                                  return prev.filter((item, i) => i !== index)
                                             }
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Votre NDF a bien été supprimée.")
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function navigateToDetails() {
          navigate(`${item.id}/depenses`, {
               state: {
                    ndf_id: item.id,
                    default_step: "two",
                    breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(item.declaration?.mois?.format("MMMM YYYY")),
               },
          })
     }

     return (
          <>
               <MyCard>
                    <MyCard.Header classNames={"bg-light-primary d-flex justify-content-center cursor-pointer"} onClick={navigateToDetails}>
                         <span className={"fas fa-file-invoice-dollar fs-1 me-2"}></span> NDF de {item.declaration?.mois?.format("MMMM YYYY")}
                    </MyCard.Header>
                    <MyCard.Body classNames={"d-flex justify-content-center"}>
                         <button className={`btn btn-light-dark btn-sm me-2`} onClick={navigateToDetails}>
                              <i className={"fas fa-eye"} /> Consulter
                         </button>

                         <MyTooltip title={"Supprimer"}>
                              <button className={"btn btn-danger btn-sm"} onClick={() => handleSupprimerNdf(item)}>
                                   <i className={"fas fa-trash"} /> Supprimer
                              </button>
                         </MyTooltip>
                    </MyCard.Body>
               </MyCard>
          </>
     )
}

interface ConnectedUserMaladiesListingItemPropsModel {
     item: IPersonnelNdfModel
}

export default ConnectedPersonnelNdfListingItem
