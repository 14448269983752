import { CONST_API_BASE_URL_ENDPOINT } from "../../../endpoints"
import { useAuth } from "../../../AppContext"
import React, { useEffect, useState } from "react"
import axios, { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getJwt } from "../core/_requests"
import MyAlert from "../../../../common/utils/MyAlert"
import { toAbsoluteUrl } from "@common-metronic/helpers"

const API_AUTH_URL_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + "/get-google-oauth2-screen-url"

const LoginWithGoogle = () => {
     const urlParams = new URLSearchParams(window.location.search)
     const { saveAuth, setCurrentUser } = useAuth()
     const [isRedirectingToGoogleOAuthScreen, setIsRedirectingToGoogleOAuthScreen] = useState<boolean>(false)
     const [isLogingToApp, setIsLogingToApp] = useState<boolean>(false)
     const [error, setError] = useState<string | null>(null)
     const [urlParamsTmpCodeToGetJwt, setUrlParamsTmpCodeToGetJwt] = useState(urlParams.get("code") as string | null)

     /* STEP 1: get Google OAuth screen url*/
     function redirectToGoogleOAuthScreenUrl() {
          setIsRedirectingToGoogleOAuthScreen(true)

          axios.get<{ url: string }>(API_AUTH_URL_ENDPOINT)
               .then(r => {
                    toast.info("Redirection en cours, veuillez patienter ...", {
                         autoClose: false,
                    })
                    setTimeout(() => {
                         window.location.href = r.data.url
                    }, 2000)
               })
               .catch((e: AxiosError) => {
                    const error = e.response?.data?.detail
                    setError(error)
                    toast.error(error, { autoClose: false })
                    setIsRedirectingToGoogleOAuthScreen(false)
               })
     }

     useEffect(() => {
          /* STEP 2: verify if the JWT is legit*/
          if (urlParamsTmpCodeToGetJwt) {
               setIsLogingToApp(true)
               getJwt(urlParamsTmpCodeToGetJwt)
                    .then(r => {
                         setCurrentUser(r.data.user)
                         saveAuth({ token: r.data.jwt })
                    })
                    .catch((e: AxiosError) => {
                         const error = e.response?.data?.detail
                         setError(error)
                         toast.error(error, { autoClose: false })
                         saveAuth(undefined)
                         setCurrentUser(undefined)
                    })
                    .finally(() => {
                         setIsLogingToApp(false)
                         setUrlParamsTmpCodeToGetJwt(null)
                         urlParams.delete("code")
                    })
          }
     }, [urlParamsTmpCodeToGetJwt])

     return (
          <div>
               {error && (
                    <div className={"mx-5"}>
                         <MyAlert type={"danger"}>{error}</MyAlert>
                    </div>
               )}

               <div className={"mx-20 d-flex flex-column"}>
                    <div className="d-flex d-grid justify-content-center">
                         <button
                              type="submit"
                              className="btn btn-flex flex-center btn-secondary btn-lg w-100"
                              disabled={isRedirectingToGoogleOAuthScreen || isLogingToApp}
                              onClick={() => redirectToGoogleOAuthScreenUrl()}
                         >
                              <img alt="Logo" src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")} className="h-20px me-3" />

                              {!isRedirectingToGoogleOAuthScreen && !isLogingToApp && "Connexion avec Google"}

                              {(isRedirectingToGoogleOAuthScreen || isLogingToApp) && (
                                   <>
                                        {isRedirectingToGoogleOAuthScreen && "Redirection en cours"}
                                        {isLogingToApp && "Connexion en cours"}
                                        <span className="spinner-border spinner-border-sm ms-2"></span>
                                   </>
                              )}
                         </button>
                    </div>
               </div>
          </div>
     )
}

export default LoginWithGoogle
