export const ETATS_FOR_ADMIN_DECLARATION_PRODUCTION_OPTIONS_SELECT2_FIELD = [
     {
          value: "ETAT_ADMIN__EN_ATTENTE_RECEPTION",
          label: "En attente de réception",
     },
     {
          value: "ETAT_ADMIN__EN_COURS_RECTIFICATION",
          label: "En cours de rectification",
     },
     {
          value: "ETAT_ADMIN__A_VALIDER",
          label: "À valider",
     },
     {
          value: "ETAT_ADMIN__VALIDE_POUR_PAIE",
          label: "Validé pour la paie",
     },
]

export const ETATS_FOR_NON_ADMIN_DECLARATION_PRODUCTION_OPTIONS_SELECT2_FIELD = [
     {
          value: "ETAT_NON_ADMIN__EN_ATTENTE_RECEPTION",
          label: "En attente de réception",
     },
     {
          value: "ETAT_NON_ADMIN__EN_COURS_RECTIFICATION",
          label: "En cours de rectification",
     },
     {
          value: "ETAT_NON_ADMIN__A_ENVOYER_AU_SIEGE",
          label: "À valider",
     },
     {
          value: "ETAT_NON_ADMIN__A_CONTROLER",
          label: "À contrôler",
     },
     {
          value: "ETAT_NON_ADMIN__ENVOYE_AU_SIEGE",
          label: "Envoyé au siège",
     },
     {
          value: "ETAT_NON_ADMIN__VALIDE_POUR_PAIE",
          label: "Validé pour paie",
     },
]
