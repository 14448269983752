import React from "react"
import { AxiosError } from "axios"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MOIS_LIST } from "@common-constants/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { ICreateConnectedUserNdfRequestModel } from "../../../mes-activites/ndf/core/_models"
import { createConnectedUserNdfRequest, getConnectedUserNdfByDateRequest } from "../../../mes-activites/ndf/core/_requests"
import moment from "moment"
import MySelectField from "@common-utils/fields/MySelectField"
import { Link, useNavigate } from "react-router-dom"
import MyCard from "@common-utils/MyCard"
import GlobalHelper from "@common-helpers/GlobalHelper"

const urlParams = new URLSearchParams(window.location.search)

const _PersonnelNdfCreateForm = () => {
     const navigate = useNavigate()
     const urlParamsMois = urlParams.get("mois") as string | null
     const urlParamsAnnee = urlParams.get("annee") as string | null

     // Schema
     const schema = Yup.object().shape({
          mois: Yup.string().label(`"Mois"`).required(),
          annee: Yup.string().label(`"Année"`).required(),
     })

     // Init values
     const initialValues: ICreateConnectedUserNdfRequestModel = {
          mois: urlParamsMois || moment().format("MM"),
          annee: urlParamsAnnee || moment().format("YYYY"),
     }

     // Handle submit
     function handleSubmit(values: ICreateConnectedUserNdfRequestModel, helpers: FormikHelpers<ICreateConnectedUserNdfRequestModel>) {
          helpers.setStatus(null)

          getConnectedUserNdfByDateRequest(values.mois as string, values.annee as string).then(r => {
               if (!r.data) {
                    createConnectedUserNdfRequest({ mois: values.mois, annee: values.annee })
                         .then(r => {
                              helpers.setSubmitting(false)
                              navigate(`/personnel/mes-activites/ndf/${r.data.id}/depenses`, {
                                   state: {
                                        breadCrumbPageTitle: `${GlobalHelper.firstLetterUppercase(moment(`${values.annee}-${values.mois}-01`).format("MMMM YYYY"))}`,
                                   },
                              })
                         })
                         .catch((e: AxiosError) => {
                              const error: IHttpErrorResponseModel = e.response?.data

                              // Set form errors
                              if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                                   for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                              }

                              // Set form global status and notify user using a toast
                              helpers.setStatus(error?.detail)
                              toast.error(error?.detail, { autoClose: false })

                              // Stop submit loader
                              helpers.setSubmitting(false)
                         })
               } else {
                    navigate(`/personnel/mes-activites/ndf/${r.data.id}/depenses`, {
                         state: {
                              breadCrumbPageTitle: `${GlobalHelper.firstLetterUppercase(moment(`${values.annee}-${values.mois}-01`).format("MMMM YYYY"))}`,
                         },
                    })
               }
          })
     }

     const Header = () => {
          return (
               <div className="stepper stepper-links mb-2">
                    <div className="stepper-nav">
                         {/* begin::Step 1 */}
                         <div className={`stepper-item current`}>
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>1. Période</span>
                                   <span className={"d-block d-sm-none fas fa-calendar-plus"} style={{ fontSize: "2rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step 1 */}
                         {/* begin::Step 2 */}
                         <div className={`stepper-item pending`}>
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>2. Dépenses</span>
                                   <span className={"d-block d-sm-none fas fa-file-invoice"} style={{ fontSize: "2rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step 2 */}
                    </div>
               </div>
          )
     }

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-65"}>
                    <MyCard>
                         <MyCard.Body>
                              <Header />
                              <h5 className={"mb-3"}>Choisissez une période</h5>
                              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                   {helpers => {
                                        console.log(helpers.errors)
                                        return (
                                             <Form noValidate autoComplete="off">
                                                  {helpers.status && (
                                                       <MyAlert type={"danger"} classNames={"mb-4"}>
                                                            {helpers.status}
                                                       </MyAlert>
                                                  )}

                                                  <div className="row mb-5">
                                                       <div className="col-sm-6 mb-sm-0 mb-3">
                                                            <MySelectField
                                                                 options={CONST_MOIS_LIST}
                                                                 name={"mois"}
                                                                 value={helpers.values.mois}
                                                                 isSearchable={false}
                                                                 onChange={value => helpers.setFieldValue("mois", value)}
                                                                 placeholder={"Mois"}
                                                                 isInvalid={!!helpers.errors.mois}
                                                            />
                                                            {helpers.errors.mois && <div className={"text-danger"}>{helpers.errors.mois}</div>}
                                                       </div>
                                                       <div className="col-sm-6">
                                                            <MySelectField
                                                                 options={[
                                                                      { label: moment().subtract(1, "year").format("YYYY"), value: moment().subtract(1, "year").format("YYYY") },
                                                                      {
                                                                           label: moment().format("YYYY"),
                                                                           value: moment().format("YYYY"),
                                                                      },
                                                                 ]}
                                                                 name={"annee"}
                                                                 isSearchable={false}
                                                                 value={helpers.values.annee}
                                                                 onChange={value => helpers.setFieldValue("annee", value)}
                                                                 placeholder={"Année"}
                                                                 isInvalid={!!helpers.errors.annee}
                                                            />
                                                            {helpers.errors.annee && <div className={"text-danger"}>{helpers.errors.annee}</div>}
                                                       </div>
                                                  </div>

                                                  {/* FOOTER */}
                                                  <div className="d-flex flex-stack">
                                                       <Link to="/personnel/mes-activites/ndf " className={`btn btn-sm btn-light-primary me-2`}>
                                                            <i className={"fas fa-list-dots align-middle me-1"} /> Retour à la liste des NDF
                                                       </Link>

                                                       <button type="submit" className="btn btn-md-lg btn-sm btn-primary" disabled={helpers.isSubmitting}>
                                                            {!helpers.isSubmitting ? (
                                                                 <>
                                                                      Suivant <i className={"fas fa-arrow-right align-middle ms-1"} />
                                                                 </>
                                                            ) : (
                                                                 <MySimpleSpinner size={"sm"} />
                                                            )}
                                                       </button>
                                                  </div>
                                             </Form>
                                        )
                                   }}
                              </Formik>
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default _PersonnelNdfCreateForm
