import { useAuth } from "../../../../../AppContext"
import { CONST_PERSONNEL_CODE_CELINE_CORLE, CONST_PERSONNEL_CODE_DIDIER_BAGUR } from "@common-constants/*"
import Voyants from "./_Voyants"
import VoyantsDidierEtCeline from "./_VoyantsDidierEtCeline"

const GestionStructureDeclarationsVoyants = () => {
     const { currentUser } = useAuth()

     return <>{currentUser?.code === CONST_PERSONNEL_CODE_CELINE_CORLE || currentUser?.code === CONST_PERSONNEL_CODE_DIDIER_BAGUR ? <VoyantsDidierEtCeline /> : <Voyants />}</>
}

export default GestionStructureDeclarationsVoyants
