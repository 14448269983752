// Check candidate as seen
import axios from "axios"
import {
     CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT_DELETE_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT_REPLACE_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDAT_GENERER_DOCO_ET_NOTIFIER_CANDIDAT_PAR_MAIL_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_GET_CANDIDATURES_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_GET_COMMENTS_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_GET_DETAILS_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_GET_DOCOS_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_GET_SUIVIS_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_DOCUMENT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_STATUT_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_TO_LIST_TRAITEMENT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_TO_VIVIERS,
     CONST_API_RECRUITMENT_CANDIDATES_POST_CREATE_COMMENT_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_DEMANDER_INFO_PRE_EMBAUCHE_PAR_MAIL_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ENTAMER_DEMANDE_EMBAUCHE_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_PARTAGER_PROFIL_PAR_MAIL,
     CONST_API_RECRUITMENT_CANDIDATES_POST_PROGRAMMER_ENTRETIEN_MEET_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_REDEMANDER_INFO_PRE_EMBAUCHE_PAR_MAIL_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_post_REMOVE_FROM_LIST_TRAITEMENT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_REMOVE_STATUT_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_REPLACE_CURRICULUM_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_SET_DETAILS_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_PUT_CHECK_AS_SEEN,
} from "../../../../endpoints"
import {
     CandidatureModel,
     ICandidatCommentaireModel,
     ICandidatDocumentModel,
     ICandidatModel,
     ICandidatSeenModel,
     ICandidatStatusModel,
     ICandidatSuiviModel,
     ICandidatVivierModel,
     IConsultantDEModel,
} from "@common-models/*"
import { ICandidatGenererDocoEtNotifierCandidatParMailRequestModel } from "../actions/core/_models"
import { ICandidatRechcherResultsPreviewHelpersDetailsRequestModel } from "../recherche/results/_preview/helpers/core/_models"
import { CONST_MOBILITE_DEPARTEMENTAL, CONST_MOBILITE_PAR_VILLE, CONST_MOBILITE_REGIONAL } from "@common-constants/*"
import { IDemanderInformationsPreEmbaucheParMailAuCandidatRequestModel, IEntamerDemandeEmbaucheRequestModel } from "./_models"

export function checkCandidateAsSeen(id: number) {
     return axios.put<{ seen: ICandidatSeenModel | null }>(CONST_API_RECRUITMENT_CANDIDATES_PUT_CHECK_AS_SEEN(id))
}

export function addCandidateToListeTraitement(id: number) {
     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_TO_LIST_TRAITEMENT, {
          ids: [id],
     })
}

export function removeCandidateFromListeTraitement(id: number) {
     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_post_REMOVE_FROM_LIST_TRAITEMENT, {
          ids: [id],
     })
}

export function addCandidatesToViviers(candidat_ids: number[], new_vivier_nom: string | null, vivier_ids: number[] | null) {
     return axios.post<ICandidatVivierModel[]>(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_TO_VIVIERS, {
          candidat_ids,
          vivier_ids,
          new_vivier_nom,
     })
}

export function partagerProfilParMail(id: number, destinataires: number[], objet: string, commentaire?: string) {
     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_POST_PARTAGER_PROFIL_PAR_MAIL(id), {
          destinataires,
          objet,
          commentaire,
     })
}

export function programmerEntretienMeet(
     id: number,
     type: string,
     reunionTitre: string,
     reunionDate: string,
     reunionDureeEnMinutes: number,
     doesMeetingHaveExtraParticipants: boolean,
     extraParticipantMails?: string[]
) {
     let payload: any = { type, reunionTitre, reunionDate, reunionDureeEnMinutes, doesMeetingHaveExtraParticipants }
     if (doesMeetingHaveExtraParticipants) payload = { ...payload, extraParticipantMails }
     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_POST_PROGRAMMER_ENTRETIEN_MEET_ENDPOINT(id), payload)
}

export function replaceCurriculum(id: number, file: File) {
     const formData = new FormData()
     formData.append("fichier", file)
     return axios.post<{ googleDriveId: string; googleDriveMimeType: string }>(CONST_API_RECRUITMENT_CANDIDATES_POST_REPLACE_CURRICULUM_ENDPOINT(id), formData)
}

export function addStatut(id: number, statut: string) {
     return axios.post<ICandidatStatusModel[]>(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_STATUT_ENDPOINT(id), { statut })
}

export function removeStatut(id: number, statut: string) {
     return axios.post<ICandidatStatusModel[]>(CONST_API_RECRUITMENT_CANDIDATES_POST_REMOVE_STATUT_ENDPOINT(id), { statut })
}

export function getDocos(id: number) {
     return axios.get<ICandidatDocumentModel[]>(CONST_API_RECRUITMENT_CANDIDATES_GET_DOCOS_ENDPOINT(id))
}

export function getCandidatures(id: number) {
     return axios.get<CandidatureModel[]>(CONST_API_RECRUITMENT_CANDIDATES_GET_CANDIDATURES_ENDPOINT(id))
}

export function getCommentaires(id: number) {
     return axios.get<ICandidatCommentaireModel[]>(CONST_API_RECRUITMENT_CANDIDATES_GET_COMMENTS_ENDPOINT(id))
}

export function addCommentaire(id: number, commentaire: string) {
     return axios.post<ICandidatCommentaireModel>(CONST_API_RECRUITMENT_CANDIDATES_POST_CREATE_COMMENT_ENDPOINT(id), { commentaire })
}

export function genererDocoEtNotifierCandidatParMail(id: number, payload: ICandidatGenererDocoEtNotifierCandidatParMailRequestModel) {
     return axios.post<ICandidatDocumentModel>(CONST_API_RECRUITMENT_CANDIDAT_GENERER_DOCO_ET_NOTIFIER_CANDIDAT_PAR_MAIL_ENDPOINT(id), payload)
}

export function getSuivis(id: number) {
     return axios.get<ICandidatSuiviModel[]>(CONST_API_RECRUITMENT_CANDIDATES_GET_SUIVIS_ENDPOINT(id))
}

export function getDetails(id: number) {
     return axios.get<ICandidatModel>(CONST_API_RECRUITMENT_CANDIDATES_GET_DETAILS_ENDPOINT(id))
}

export function setDetails(id: number, payload: ICandidatRechcherResultsPreviewHelpersDetailsRequestModel) {
     if (payload.habilitable === "UNKNOWN") {
          payload["isDejaEuUneHabilitation"] = undefined
          payload["dateFinHabilitation"] = undefined
     }
     if (payload.habilitable === "NON") {
          payload["isDejaEuUneHabilitation"] = undefined
          payload["dateFinHabilitation"] = undefined
     }
     if (payload.mobilite === CONST_MOBILITE_REGIONAL) {
          payload["mobiliteDepartements"] = undefined
          payload["mobiliteVilles"] = undefined
     }
     if (payload.mobilite === CONST_MOBILITE_DEPARTEMENTAL) {
          payload["mobiliteRegions"] = undefined
          payload["mobiliteVilles"] = undefined
     }
     if (payload.mobilite === CONST_MOBILITE_PAR_VILLE) {
          payload["mobiliteDepartements"] = undefined
          payload["mobiliteRegions"] = undefined
     }
     return axios.post<ICandidatModel>(CONST_API_RECRUITMENT_CANDIDATES_POST_SET_DETAILS_ENDPOINT(id), payload)
}

export function entamerDemandeEmbauche(id: number, payload: IEntamerDemandeEmbaucheRequestModel) {
     if (payload.typeContrat !== "TYPE_CONTRAT_SALARIE") {
          payload.contrat = undefined
          payload.statut = undefined
     }

     return axios.post<IConsultantDEModel>(CONST_API_RECRUITMENT_CANDIDATES_POST_ENTAMER_DEMANDE_EMBAUCHE_ENDPOINT(id), payload)
}

export function demanderInformationsPreEmbaucheParMail(id: number, payload: IDemanderInformationsPreEmbaucheParMailAuCandidatRequestModel) {
     if (payload.typeContrat !== "TYPE_CONTRAT_SALARIE") {
          payload.contrat = undefined
          payload.statut = undefined
     }

     return axios.post<IConsultantDEModel>(CONST_API_RECRUITMENT_CANDIDATES_POST_DEMANDER_INFO_PRE_EMBAUCHE_PAR_MAIL_ENDPOINT(id), payload)
}

export function reDemanderInformationsPreEmbaucheParMail(id: number) {
     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_POST_REDEMANDER_INFO_PRE_EMBAUCHE_PAR_MAIL_ENDPOINT(id), {})
}

export function addCandidatDocument(id: number, type: string, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)
     formData.append("type", type)

     return axios.post<ICandidatDocumentModel>(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_DOCUMENT(id), formData)
}

export function replaceCandidatDocument(id: number, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)

     return axios.post<ICandidatDocumentModel>(CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT_REPLACE_ENDPOINT(id), formData)
}

export function deleteCandidatDocument(id: number) {
     return axios.delete(CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT_DELETE_ENDPOINT(id))
}
