import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import axios, { AxiosError } from "axios"
import { useQuery, useQueryClient } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { IHttpErrorResponseModel, IPersonnelDeclarationMensuelleModel, IPersonnelDeclarationMensuelleSuiviModel } from "@common-models/*"

import {
     IGetStructureDeclarationsDataItemResponseModel,
     IGetStructureDeclarationsRequestModel,
     IGetStructureDeclarationsResponseModel,
     IItemInModal,
     IStructureDeclarationsListingContextPropsModel,
} from "./core/_models"
import { getStructureDeclarationsListingRequest, relancePersonnelsDeclarationActiviteRequest } from "./core/_requests"
import SearchBar from "./_SearchBar"
import List from "./_list/_List"
import Filters from "./_Filters"
import MyModal from "@common-utils/MyModal"
import { getSelectedDeclarationPlusPreviousAndNextDeclaration } from "./core/helpers"
import { useAuth } from "../../../../../AppContext"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../../models"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "@common-constants/*"
import { CONST_API_PERSONNEL_DECLARATION_DOWNLOAD_ENDPOINT } from "../../../../../endpoints"
import PersonnelDeclarationSuivi from "../../../../personnel/declaration/_PersonnelDeclarationSuivi"
import PersonnelDeclarationDetails from "../../../../personnel/declaration/details/_PersonnelDeclarationDetails"
import useSwal from "@common-hooks/useSwal"
import moment from "moment"

// Wrapper's context
const StructureDeclarationsListingContext = createContext<IStructureDeclarationsListingContextPropsModel>({} as IStructureDeclarationsListingContextPropsModel)
export const useStructureDeclarationsListingContext = () => useContext(StructureDeclarationsListingContext)

const REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE = "REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE"
const GestionStructureDeclarationsListing = () => {
     const queryClient = useQueryClient()
     const { swal } = useSwal()
     const navigate = useNavigate()
     const { currentUser } = useAuth()

     // Page states & URL parameters
     const location = useLocation() as {
          state: {
               etat?:
                    | "ETAT_ADMIN__EN_ATTENTE_RECEPTION"
                    | "ETAT_ADMIN__EN_COURS_RECTIFICATION"
                    | "ETAT_ADMIN__A_VALIDER"
                    | "ETAT_ADMIN__VALIDE_POUR_PAIE"
                    | "ETAT_NON_ADMIN__EN_ATTENTE_RECEPTION"
                    | "ETAT_NON_ADMIN__EN_COURS_RECTIFICATION"
                    | "ETAT_NON_ADMIN__A_ENVOYER_AU_SIEGE"
                    | "ETAT_NON_ADMIN__A_CONTROLER"
                    | "ETAT_NON_ADMIN__ENVOYE_AU_SIEGE"
                    | "ETAT_NON_ADMIN__VALIDE_POUR_PAIE"
               mois?: string
               keyword?: string
               includeOnlyThisPersonnels?: number[]
               excludedPersonnels?: number[]
          }
     }
     const urlParams = new URLSearchParams(window.location.search)
     const urlParamsKeyword = urlParams.get("keyword")

     // Referencing both filters form and simple search bar form
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)

     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [filters, setFilters] = useState<IGetStructureDeclarationsRequestModel>({
          page: 1,
          pageLength: 500,
          etat: location.state?.etat,
          includeOnlyThisPersonnels: location.state?.includeOnlyThisPersonnels,
          excludedPersonnels: location.state?.excludedPersonnels,
          mois: !location.state?.mois && !urlParams.get("mois") ? "" : location.state?.mois || (urlParams.get("mois") as string),
          keyword: urlParamsKeyword || undefined,
     })
     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })
     const [itemInModal, setItemInModal] = useState<IItemInModal | null>(null)
     const [suiviInModal, setSuiviInModal] = useState<IPersonnelDeclarationMensuelleModel>()
     const doesConnectedUserHaveAccessToModuleAdmin = (() => {
          return currentUser?.authorizedAppModules?.find(item => item === CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF) !== undefined
     })()

     // Main listing query
     const listingQuery = useQuery<IGetStructureDeclarationsResponseModel, AxiosError>(
          REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE,
          async () => {
               return getStructureDeclarationsListingRequest(filters)
                    .then(r => {
                         // Stop loaders (filters + simple search bar)
                         filtersFormikRef.current.setSubmitting(false)
                         simpleSearchBarFormikRef.current.setSubmitting(false)

                         return r.data
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors in the filters fomr
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                         }

                         // Stop loaders (filters + simple search bar)
                         filtersFormikRef.current.setSubmitting(false)
                         simpleSearchBarFormikRef.current.setSubmitting(false)

                         // Toast error
                         toast.error(error?.detail, { autoClose: false })

                         throw e
                    })
          },
          { enabled: false }
     )

     // Handles demande rectification
     function handleDemandeRectificationCallback(val: IPersonnelDeclarationMensuelleSuiviModel) {
          // Update item in declarations list
          queryClient.setQueryData<IGetStructureDeclarationsResponseModel | undefined>(
               REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE,
               (prev: IGetStructureDeclarationsResponseModel | undefined) => {
                    if (prev?.data && prev.data.length > 0) {
                         const index = prev.data.findIndex(item => item.declaration?.id === itemInModal?.selectedItem.declaration?.id)
                         if (index >= 0) {
                              prev.data[index] = {
                                   ...prev.data[index],
                                   declaration: {
                                        ...prev.data[index].declaration,
                                        etat: val,
                                   },
                              }
                              return prev
                         }
                    }

                    return prev
               }
          )
          showNextItemToControlInModal()
     }

     // Handles validation
     function handleValidationCallback(val: IPersonnelDeclarationMensuelleSuiviModel) {
          queryClient.setQueryData<IGetStructureDeclarationsResponseModel | undefined>(
               REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE,
               (prev: IGetStructureDeclarationsResponseModel | undefined) => {
                    if (prev?.data && prev.data.length > 0) {
                         const index = prev.data.findIndex(item => item.declaration?.id === itemInModal?.selectedItem.declaration?.id)
                         if (index >= 0) {
                              prev.data[index] = {
                                   ...prev.data[index],
                                   declaration: {
                                        ...prev.data[index].declaration,
                                        etat: val,
                                   },
                              }
                              return prev
                         }
                    }

                    return prev
               }
          )
          showNextItemToControlInModal()
     }

     // Handles validation demande de réctif par resp
     function handleValidationDemandeRegulCallback(val: IPersonnelDeclarationMensuelleSuiviModel) {
          // Update item in declarations list
          queryClient.setQueryData<IGetStructureDeclarationsResponseModel | undefined>(
               REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE,
               (prev: IGetStructureDeclarationsResponseModel | undefined) => {
                    if (prev?.data && prev.data.length > 0) {
                         const index = prev.data.findIndex(item => item.declaration?.id === itemInModal?.selectedItem.declaration?.id)
                         if (index >= 0) {
                              prev.data[index] = {
                                   ...prev.data[index],
                                   declaration: {
                                        ...prev.data[index].declaration,
                                        etat: val,
                                   },
                              }
                              return prev
                         }
                    }

                    return prev
               }
          )
          showNextItemToControlInModal()
     }

     // Handles rejet demande de réctif par resp
     function handleRejetDemandeRegulCallback(val: IPersonnelDeclarationMensuelleSuiviModel) {
          // Update item in declarations list
          queryClient.setQueryData<IGetStructureDeclarationsResponseModel | undefined>(
               REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE,
               (prev: IGetStructureDeclarationsResponseModel | undefined) => {
                    if (prev?.data && prev.data.length > 0) {
                         const index = prev.data.findIndex(item => item.declaration?.id === itemInModal?.selectedItem.declaration?.id)
                         if (index >= 0) {
                              prev.data[index] = {
                                   ...prev.data[index],
                                   declaration: {
                                        ...prev.data[index].declaration,
                                        etat: val,
                                   },
                              }
                              return prev
                         }
                    }

                    return prev
               }
          )
          showNextItemToControlInModal()
     }

     function showNextItemToControlInModal() {
          // Get index of the next controllable declaration
          const nextItemToControlIndex = listingQuery.data?.data.findIndex(item => {
               if (item.declaration?.id !== itemInModal?.selectedItem.declaration?.id) {
                    // Depending if connected user is from siège or BM
                    if (doesConnectedUserHaveAccessToModuleAdmin) {
                         return item.declaration?.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION
                    } else {
                         return [
                              CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                              CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
                              CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                         ].includes(item.declaration?.etat?.type as string)
                    }
               }
          }) as number

          // If there is one in the current page, show it in modal
          if (nextItemToControlIndex >= 0) {
               const nextItemToControl = listingQuery.data!.data[nextItemToControlIndex]
               if (nextItemToControl) {
                    setItemInModal(
                         getSelectedDeclarationPlusPreviousAndNextDeclaration(
                              nextItemToControl,
                              nextItemToControlIndex,
                              listingQuery.data!.data,
                              listingQuery.data!.page,
                              listingQuery.data!.totalPages
                         )
                    )
               }
          } else {
               // If there is no one left to control, hide modal
               setItemInModal(null)
               toast.info("Il ne reste plus aucune déclaration à contrôler.")
          }
     }

     // Handles download declarations
     function handleDownloadDeclarations(items: IGetStructureDeclarationsDataItemResponseModel[]) {
          toast.info("Téléchargement en cours ...", {
               autoClose: false,
          })
          axios.get(CONST_API_PERSONNEL_DECLARATION_DOWNLOAD_ENDPOINT, {
               params: { ids: items.map(item => item.declaration?.id) },
               responseType: "blob",
          })
               .then(r => {
                    const fileName = items.length > 1 ? "déclarations.zip" : `declaration_${items[0].personnel!.prenomNom!.replace(/ /g, "_").toLowerCase()}.zip`
                    FileAndDriveHelper.downloadFile(r.data, fileName)

                    toast.dismiss()
                    toast.success(
                         items.length > 1
                              ? "Les déclarations séléctionnées ont bien été téléchargées."
                              : `La déclaration de ${items[0].personnel!.prenomNom} a bien été téléchargée.`
                    )
               })
               .catch(() => {
                    toast.dismiss()
                    toast.error("Un problème est survenu lors du téléchargement.", { autoClose: false })
               })
     }

     function isRelancable(item: IGetStructureDeclarationsDataItemResponseModel) {
          const isSiege = currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF"
          const declaration = item?.declaration

          if (!declaration || !declaration?.etat) return true

          if (isSiege) {
               if (![CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION].includes(declaration.etat.type as string)) {
                    return true
               }
          } else {
               if (
                    ![
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
                    ].includes(declaration.etat.type as string)
               ) {
                    return true
               }
          }

          return false
     }

     function handleRelance(items: IGetStructureDeclarationsDataItemResponseModel[]) {
          const itemsRelancables = items.filter(item => isRelancable(item))

          if (itemsRelancables.length === 0) {
               toast.info(items.length > 1 ? "Aucune personne ne peut être relancée parmi votre sélection." : "Cette personne ne peut pas être relancée.")
               return
          }

          const date = moment(filters.mois)
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de relancer ${
                    itemsRelancables.length > 1 ? ` ${itemsRelancables.length} déclarations` : `la déclaration de ${itemsRelancables[0]?.personnel.prenomNom}`
               }.`,
               showCancelButton: true,
               confirmButtonText: "Relancer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return relancePersonnelsDeclarationActiviteRequest({
                         ids: itemsRelancables.map(item => item.personnel.id as number),
                         month: date.format("MM"),
                         year: date.format("YYYY"),
                    })
                         .then(() => {
                              toast.success(itemsRelancables.length > 1 ? "Les mails de relance ont bien été envoyés." : "Le mail de relance a bien été envoyé.")
                              if (itemsRelancables.length > 1) {
                                   setIsBulkSelectionEnabled({
                                        enabled: false,
                                        checkedElementsInPage: [],
                                        areAllElementsInAllPagesChecked: false,
                                   })
                              }
                              // Update item in declarations list
                              queryClient.setQueryData<IGetStructureDeclarationsResponseModel | undefined>(
                                   REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE,
                                   (prev: IGetStructureDeclarationsResponseModel | undefined) => {
                                        if (prev?.data && prev.data.length > 0) {
                                             itemsRelancables.map(item_ => {
                                                  const index = prev.data.findIndex(item => item?.personnel.id === item_.personnel.id)
                                                  if (index >= 0) {
                                                       prev.data[index] = {
                                                            ...prev.data[index],
                                                            derniereRelance: {
                                                                 createdAt: moment(),
                                                                 relanceur: {
                                                                      id: currentUser?.id,
                                                                      prenomNom: currentUser?.prenomNom,
                                                                 },
                                                            },
                                                       }
                                                  }
                                             })
                                        }

                                        return prev
                                   }
                              )
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     useEffect(() => {
          // mois param is mandatory
          if (!location.state?.mois && !urlParams.get("mois")) {
               navigate(-1)
               return
          }

          // Re-fetch main listing query on filters change
          listingQuery.refetch().then()

          // Reset bulk selection
          setIsBulkSelectionEnabled({
               enabled: false,
               checkedElementsInPage: [],
               areAllElementsInAllPagesChecked: false,
          })
     }, [filters])

     return (
          <StructureDeclarationsListingContext.Provider
               value={{
                    areFiltersVisible,
                    setAreFiltersVisible,
                    filters,
                    setFilters,
                    listingQuery,
                    filtersFormikRef,
                    simpleSearchBarFormikRef,
                    REACT_QUERY_KEY_GET_DECLARATIONS_PROD: REACT_QUERY_KEY_GET_DECLARATIONS_STRUCTURE,
                    itemInModal,
                    setItemInModal,
                    suiviInModal,
                    setSuiviInModal,
                    isBulkSelectionEnabled,
                    setIsBulkSelectionEnabled,
                    handleDownloadDeclarations,
                    handleRelance,
                    isRelancable,
               }}
          >
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div
                         className={`
                              col-xl-5
                              mb-4
                              mb-xl-0 ${!areFiltersVisible ? "d-none" : ""}`}
                    >
                         <Filters />
                    </div>
                    <div className="col-xl col-xl-7">
                         {/* Simple bar search will be shown only if filters are not displayed */}
                         <div
                              className={`
                              mb-2${areFiltersVisible ? "d-none" : ""}`}
                         >
                              <SearchBar />
                         </div>
                         <List />
                    </div>
               </div>

               {/* Modal with déclaration details */}
               {itemInModal && (
                    <MyModal
                         title={<span>Déclaration de {itemInModal.selectedItem.declaration!.personnel!.prenomNom}</span>}
                         show={true}
                         handleClose={() => setItemInModal(null)}
                         fullscreen
                    >
                         <PersonnelDeclarationDetails
                              declaration={itemInModal.selectedItem.declaration as IPersonnelDeclarationMensuelleModel}
                              handleDemandeRectificationCallback={handleDemandeRectificationCallback}
                              handleValidationCallback={handleValidationCallback}
                              handleValidationDemandeRegulCallback={handleValidationDemandeRegulCallback}
                              handleRejetDemandeRegulCallback={handleRejetDemandeRegulCallback}
                              defaultStep={"cra"}
                         />
                    </MyModal>
               )}

               {/* Modal with suivi */}
               {suiviInModal && (
                    <MyModal title={<span>Suivi de la déclaration de {suiviInModal.personnel!.prenomNom}</span>} show={true} handleClose={() => setSuiviInModal(undefined)}>
                         <PersonnelDeclarationSuivi declaration={suiviInModal} />
                    </MyModal>
               )}
          </StructureDeclarationsListingContext.Provider>
     )
}
export default GestionStructureDeclarationsListing
