import axios from "axios"
import { CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_PERSO_ENDPOINT, CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_PERSO_ENDPOINT } from "../../../../../../endpoints"
import { IGetDemandeEmbaucheInfoPersoResponseModel, ISetDemandeEmbaucheInfoPersoNonSalarieRequestModel, ISetDemandeEmbaucheInfoPersoSalarieRequestModel } from "./_models"

export function getDemandeEmbaucheInfoPerso(consultant_id: number) {
     return axios.get<IGetDemandeEmbaucheInfoPersoResponseModel>(CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_PERSO_ENDPOINT(consultant_id))
}

export function setDemandeEmbaucheInfoPersoSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoPersoSalarieRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key !== "avatar") formData.append(`data[${key}]`, value)
     }

     if (payload.avatar) formData.append("avatar", payload.avatar)

     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_PERSO_ENDPOINT(consultant_id), formData)
}

export function setDemandeEmbaucheInfoPersoNonSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoPersoNonSalarieRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key !== "avatar") formData.append(`data[${key}]`, value)
          if (key === "isNationaliteEtrangere") formData.append(`data[${key}]`, value ? "1" : "0")
     }

     if (payload.avatar) formData.append("avatar", payload.avatar)

     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_PERSO_ENDPOINT(consultant_id), formData)
}
