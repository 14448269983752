import React, { useState } from "react"
import { usePersonnelDeclarationDetailsContext } from "./_PersonnelDeclarationDetails"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { IPersonnelCraModel } from "@common-models/*"
import { PersonnelNdfDepenses } from "../ndf/depenses/_PersonnelNdfDepenses"
import { getPersonnelDeclarationActiveNdfRequest } from "../core/_requests"

const REACT_QUERY_KEY_GET_ACTIVE_NDF = "REACT_QUERY_KEY_GET_ACTIVE_NDF"
const NDF = () => {
     const context = usePersonnelDeclarationDetailsContext()

     const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

     const activeNdfQuery = useQuery<IPersonnelCraModel, AxiosError>(REACT_QUERY_KEY_GET_ACTIVE_NDF, () =>
          getPersonnelDeclarationActiveNdfRequest(context.declaration?.id as number).then(r => r.data)
     )

     const Footer = () => {
          return (
               <>
                    <div className="d-flex justify-content-between">
                         <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                   context.setNavigationStep("cra")
                              }}
                         >
                              <i className={"fas fa-arrow-left me-1"} />
                              CRA
                         </button>
                         <div className="d-flex">
                              {context.areActionsDemandeRectifOrValidationAvailable && (
                                   <button type="button" className="btn btn-sm btn-warning d-flex align-items-center me-2" onClick={() => context.handleDemandeRectification()}>
                                        <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                        <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                   </button>
                              )}
                              {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                                   <>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-success d-flex align-items-center me-2"
                                             onClick={() => context.handleValidationDemandeRegul()}
                                        >
                                             <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                             <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                             <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                        </button>
                                   </>
                              )}
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary"
                                   onClick={() => {
                                        context.setNavigationStep("tr")
                                   }}
                              >
                                   TR
                                   <i className={"fas fa-arrow-right ms-1"} />
                              </button>
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               {/* loader */}
               {activeNdfQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {/* error */}
               {!activeNdfQuery.isFetching && activeNdfQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{activeNdfQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => activeNdfQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!activeNdfQuery.isFetching && !activeNdfQuery.isError && (
                    <>
                         {!activeNdfQuery.data && <MyAlert type={"primary"}>Aucune NDF n'a été déclarée</MyAlert>}

                         <div className="mb-2">
                              {activeNdfQuery.data && <PersonnelNdfDepenses ndfId={activeNdfQuery.data.id as number} editionMode={false} setIsDataLoaded={setIsDataLoaded} />}
                         </div>
                         {(isDataLoaded || !activeNdfQuery.data) && <Footer />}
                    </>
               )}
          </>
     )
}

export default NDF
