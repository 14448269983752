import axios from "axios"
import {
     CONST_API_CLIENT_PTF_CDC_DELETE_ENDPOINT,
     CONST_API_CLIENT_PTF_CDC_POST_REPLACE_ENDPOINT,
     CONST_API_CLIENT_PTF_GET_CDCs_ENDPOINT,
     CONST_API_CLIENT_PTF_GET_DETAILS_ENDPOINT,
     CONST_API_CLIENT_PTF_GET_GENERATE_REF_DATE_DEBUT_NEXT_PERIODE_ENDPOINT,
     CONST_API_CLIENT_PTF_GET_PERIODES_BCs_BLs_ENDPOINT,
     CONST_API_CLIENT_PTF_GET_SIMPLE_DETAILS_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_BC_BL_DELETE_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_BC_BL_EDIT_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_BC_DELETE_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_BC_EDIT_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_BC_POST_ADD_BL_OR_LIVRABLE_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_DELETE_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_POST_ADD_BC_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_POST_ADD_VERSION_DOC_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_POST_EDIT_ENDPOINT,
     CONST_API_CLIENT_PTF_POST_ADD_CDC_ENDPOINT,
     CONST_API_CLIENT_PTF_POST_PROLONGEMENT_ENDPOINT,
     CONST_API_CLIENT_PTF_POST_SET_DETAILS_ENDPOINT,
     CONST_API_CLIENT_PTF_POST_SET_RESPONSABLE_ENDPOINT,
     CONST_API_CLIENT_PTF_POST_SET_STATE_ENDPOINT,
     CONST_API_CLIENT_PTF_POST_VALIDATION_PAR_CLIENT_ENDPOINT,
} from "../../../../endpoints"
import { IClientPropalCDCModel, IClientPropalModel, IClientPropalPeriodeModel, IPersonnelModel } from "@common-models/*"
import {
     IAddBonCommandeToPtfPeriodeRequestModel,
     IAddBonLivraisonToBonCommandeRequestModel,
     IAddLivrableToBonCommandeRequestModel,
     IAddVersionDocumentPropalRequestModel,
     IEditBonCommandeRequestModel,
     IEditBonLivraisonRequestModel,
     IEditLivrableRequestModel,
     IEditPropalPeriodeRequestModel,
     IGetDetailsPropalRequestModel,
     IGetPeriodesBonsCommandeBonsLivraisonRequestModel,
     IGetPeriodesBonsCommandeBonsLivraisonResponseModel,
     IProlongementPtfRequestModel,
     ISetDetailsPropalRequestModel,
     IValidationPtfParClientRequestModel,
} from "./_models"
import moment from "moment"

export function getPeriodesBonsCommandeBonsLivraisonRequest(payload: IGetPeriodesBonsCommandeBonsLivraisonRequestModel) {
     return axios.post<IGetPeriodesBonsCommandeBonsLivraisonResponseModel>(CONST_API_CLIENT_PTF_GET_PERIODES_BCs_BLs_ENDPOINT, payload)
}

export function getClientPropalSimpleDetailsRequest(id: number) {
     return axios.get<IClientPropalModel>(CONST_API_CLIENT_PTF_GET_SIMPLE_DETAILS_ENDPOINT(id))
}

export function getClientPropalDetailsRequest(id: number) {
     return axios.get<IGetDetailsPropalRequestModel>(CONST_API_CLIENT_PTF_GET_DETAILS_ENDPOINT(id))
}

export function getClientPropalCDCsRequest(id: number) {
     return axios.get<IClientPropalCDCModel[]>(CONST_API_CLIENT_PTF_GET_CDCs_ENDPOINT(id))
}

export function setEtatClientPropalRequest(id: number, etat: string) {
     return axios.post<IClientPropalModel>(CONST_API_CLIENT_PTF_POST_SET_STATE_ENDPOINT(id), {
          etat,
     })
}

export function setResponsablePropalsRequest(ptf_ids: number[], responsable_id: number) {
     return axios.post<IPersonnelModel>(CONST_API_CLIENT_PTF_POST_SET_RESPONSABLE_ENDPOINT, {
          ptf_ids,
          responsable_id,
     })
}

export function setDetailsPropalRequest(id: number, payload: ISetDetailsPropalRequestModel) {
     return axios.post<IPersonnelModel>(CONST_API_CLIENT_PTF_POST_SET_DETAILS_ENDPOINT(id), payload)
}

export function validationPropalParClientRequest(id: number, payload: IValidationPtfParClientRequestModel) {
     const formData = new FormData()
     if (payload.bonCommandeDateDebut) formData.append("data[bonCommandeDateDebut]", payload.bonCommandeDateDebut)
     if (payload.bonCommandeDateFin) formData.append("data[bonCommandeDateFin]", payload.bonCommandeDateFin)
     if (payload.bonCommandeType) formData.append("data[bonCommandeType]", payload.bonCommandeType)
     if (payload.bonCommandeDocument) formData.append("document", payload.bonCommandeDocument)

     return axios.post<IPersonnelModel>(CONST_API_CLIENT_PTF_POST_VALIDATION_PAR_CLIENT_ENDPOINT(id), formData)
}

export function prolongementPropalRequest(ptf_id: number, payload: IProlongementPtfRequestModel) {
     const formData = new FormData()
     if (payload.ptfPeriodeReference) formData.append(`data[ptfPeriodeReference]`, payload.ptfPeriodeReference)
     if (payload.ptfPeriodeDateDebut) formData.append(`data[ptfPeriodeDateDebut]`, payload.ptfPeriodeDateDebut)
     if (payload.ptfPeriodeDateFin) formData.append(`data[ptfPeriodeDateFin]`, payload.ptfPeriodeDateFin)
     if (payload.ptfPeriodeDocumentGeneratedOrSaisieManuelle)
          formData.append(`data[ptfPeriodeDocumentGeneratedOrSaisieManuelle]`, payload.ptfPeriodeDocumentGeneratedOrSaisieManuelle)
     if (payload.ptfPeriodeDocument) formData.append(`documents[ptf]`, payload.ptfPeriodeDocument)
     if (payload.bonCommandeType) formData.append(`data[bonCommandeType]`, payload.bonCommandeType)
     if (payload.bonCommandeDateDebut) formData.append(`data[bonCommandeDateDebut]`, payload.bonCommandeDateDebut)
     if (payload.bonCommandeDateFin) formData.append(`data[bonCommandeDateFin]`, payload.bonCommandeDateFin)
     if (payload.bonCommandeDocument) formData.append(`documents[bc]`, payload.bonCommandeDocument)
     if (payload.documentCdc) formData.append(`documents[cdc]`, payload.documentCdc)

     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_POST_PROLONGEMENT_ENDPOINT(ptf_id), formData)
}

export function getReferenceAndDateDebutNextPeriodeOfPropalRequest(ptf_ID: number) {
     return axios.get<{ reference: string; dateDebut: moment.Moment }>(CONST_API_CLIENT_PTF_GET_GENERATE_REF_DATE_DEBUT_NEXT_PERIODE_ENDPOINT(ptf_ID))
}

// AT
export function addBonLivraisonToBonCommandeRequest(bc_id: number, payload: IAddBonLivraisonToBonCommandeRequestModel) {
     const formData = new FormData()

     if (payload.dateDebut) formData.append(`data[dateDebut]`, payload.dateDebut)
     if (payload.dateFin) formData.append(`data[dateFin]`, payload.dateFin)
     if (payload.montantHT) formData.append(`data[montantHT]`, payload.montantHT.toString())
     if (payload.etat) formData.append(`data[etat]`, payload.etat)
     if (payload.document) formData.append(`document`, payload.document)

     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_BC_POST_ADD_BL_OR_LIVRABLE_ENDPOINT(bc_id), formData)
}

// FORFAIT
export function addLivrableToBonCommandeRequest(bc_id: number, payload: IAddLivrableToBonCommandeRequestModel) {
     const formData = new FormData()

     if (payload.titre) formData.append(`data[titre]`, payload.titre)
     if (payload.posteLot) formData.append(`data[posteLot]`, payload.posteLot)
     if (payload.dateLivraison) formData.append(`data[dateLivraison]`, payload.dateLivraison)
     if (payload.montantHT) formData.append(`data[montantHT]`, payload.montantHT.toString())
     if (payload.etat) formData.append(`data[etat]`, payload.etat)
     if (payload.isLivraisonTotal) formData.append(`data[isLivraisonTotal]`, payload.isLivraisonTotal ? "1" : "0")
     if (payload.document) formData.append(`document`, payload.document)

     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_BC_POST_ADD_BL_OR_LIVRABLE_ENDPOINT(bc_id), formData)
}

export function deleteBonLivraisonRequest(bl_id: number) {
     return axios.delete(CONST_API_CLIENT_PTF_PERIODE_BC_BL_DELETE_ENDPOINT(bl_id))
}

export function deleteBonCommandeRequest(bc_id: number) {
     return axios.delete(CONST_API_CLIENT_PTF_PERIODE_BC_DELETE_ENDPOINT(bc_id))
}

/* CAHIER DES CHARGES */

// -- Add
export function addCdcToPropalRequest(ptf_id: number, document: File) {
     const formData = new FormData()
     formData.append("document", document)
     return axios.post<IClientPropalCDCModel>(CONST_API_CLIENT_PTF_POST_ADD_CDC_ENDPOINT(ptf_id), formData)
}

// -- Replace
export function replaceCdcOfPropalRequest(cdc_id: number, document: File) {
     const formData = new FormData()
     formData.append("document", document)
     return axios.post<IClientPropalCDCModel>(CONST_API_CLIENT_PTF_CDC_POST_REPLACE_ENDPOINT(cdc_id), formData)
}

// -- Delete
export function deleteCdcOfPropalRequest(cdc_id: number) {
     return axios.delete(CONST_API_CLIENT_PTF_CDC_DELETE_ENDPOINT(cdc_id))
}

/* PTF PERIODE */

// -- Delete période
export function deletePropalPeriodeRequest(periode_id: number) {
     return axios.delete(CONST_API_CLIENT_PTF_PERIODE_DELETE_ENDPOINT(periode_id))
}

// -- Edit période
export function editPropalPeriodeRequest(periode_id: number, payload: IEditPropalPeriodeRequestModel) {
     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_POST_EDIT_ENDPOINT(periode_id), payload)
}

// -- Add version
export function addVersionDocumentPropalRequest(propal_periode_id: number, payload: IAddVersionDocumentPropalRequestModel) {
     const formData = new FormData()
     formData.append(`data[currentVersionRef]`, payload.currentVersionRef)
     formData.append(`data[nextReference]`, payload.nextReference)
     formData.append(`data[documentGeneratedOrSaisieManuelle]`, payload.documentGeneratedOrSaisieManuelle as string)

     if (payload.commentaire) formData.append(`data[commentaire]`, payload.commentaire)
     if (payload.documentGeneratedOrSaisieManuelle === "SAISIE" && payload.document) formData.append("document", payload.document)
     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_POST_ADD_VERSION_DOC_ENDPOINT(propal_periode_id), formData)
}

// Add BC
export function addBonCommandeToPtfPeriodeRequest(ptf_periode_id: number, payload: IAddBonCommandeToPtfPeriodeRequestModel) {
     const formData = new FormData()
     if (payload.type) formData.append(`data[type]`, payload.type)
     if (payload.dateDebut) formData.append(`data[dateDebut]`, payload.dateDebut)
     if (payload.dateFin) formData.append(`data[dateFin]`, payload.dateFin)
     if (payload.document) formData.append(`document`, payload.document)

     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_POST_ADD_BC_ENDPOINT(ptf_periode_id), formData)
}

/* BON DE COMMANDE */
export function editBonCommandeRequest(bc_id: number, payload: IEditBonCommandeRequestModel) {
     const formData = new FormData()
     if (payload.dateDebut) formData.append("data[dateDebut]", payload.dateDebut)
     if (payload.dateFin) formData.append("data[dateFin]", payload.dateFin)
     if (payload.type) formData.append("data[type]", payload.type)
     formData.append("data[doesDocumentGoingToBeReplaced]", payload.doesDocumentGoingToBeReplaced ? "1" : "0")
     if (payload.document) formData.append("document", payload.document)
     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_BC_EDIT_ENDPOINT(bc_id), formData)
}

/* BON LIVRAISON */

// -- Edit bon livrason
export function editBonLivraisonRequest(bl_id: number, payload: IEditBonLivraisonRequestModel) {
     const formData = new FormData()
     if (payload.dateDebut) formData.append("data[dateDebut]", payload.dateDebut)
     if (payload.dateFin) formData.append("data[dateFin]", payload.dateFin)
     if (payload.etat) formData.append("data[etat]", payload.etat)
     if (payload.montantHT) formData.append("data[montantHT]", payload.montantHT.toString())
     formData.append("data[doesDocumentGoingToBeReplaced]", payload.doesDocumentGoingToBeReplaced ? "1" : "0")
     if (payload.document) formData.append("document", payload.document)
     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_BC_BL_EDIT_ENDPOINT(bl_id), formData)
}

// -- Edit livrable
export function editBonLivrableRequest(bl_id: number, payload: IEditLivrableRequestModel) {
     const formData = new FormData()
     if (payload.titre) formData.append("data[titre]", payload.titre)
     if (payload.posteLot) formData.append("data[posteLot]", payload.posteLot)
     if (payload.dateLivraison) formData.append("data[dateLivraison]", payload.dateLivraison)
     if (payload.etat) formData.append("data[etat]", payload.etat)
     if (payload.montantHT) formData.append("data[montantHT]", payload.montantHT.toString())
     formData.append("data[doesDocumentGoingToBeReplacedOrAdded]", payload.doesDocumentGoingToBeReplacedOrAdded ? "1" : "0")
     if (payload.document) formData.append("document", payload.document)
     return axios.post<IClientPropalPeriodeModel>(CONST_API_CLIENT_PTF_PERIODE_BC_BL_EDIT_ENDPOINT(bl_id), formData)
}
