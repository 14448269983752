import axios from "axios"
import { CONST_API_BUSINESS_CONSULTANT_GET_DE_ODM_ENDPOINT, CONST_API_BUSINESS_CONSULTANT_POST_DE_SET_ODM_ENDPOINT } from "../../../../../../endpoints"
import { IConsultantMissionModel } from "@common-models/*"
import { ISetDemandeEmbaucheOrdreMissionRequestModel } from "./_models"

export function getDemandeEmbaucheOrdreMission(consultant_id: number) {
     return axios.get<IConsultantMissionModel>(CONST_API_BUSINESS_CONSULTANT_GET_DE_ODM_ENDPOINT(consultant_id))
}

export function setDemandeEmbaucheOrdreMission(consultant_id: number, payload: ISetDemandeEmbaucheOrdreMissionRequestModel) {
     const formData = new FormData()
     formData.append("saisieManuelleOuGenerationAutomatique", payload.saisieManuelleOuGenerationAutomatique)
     if (payload.saisieManuelleOuGenerationAutomatique === "SAISIE_MANUELLE") formData.append("document", payload.document as File)

     return axios.post<IConsultantMissionModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_SET_ODM_ENDPOINT(consultant_id), formData)
}
