import { IConsultantSuiviEvolutionTjmRequestModel } from "./core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MIME_APPLICATION_DOCX } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useEffect, useState } from "react"
import { consultantSuiviEvolutionTjmRequest } from "./core/_requests"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import * as Yup from "yup"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyFileField from "@common-utils/fields/MyFileField"
import MyInputField from "@common-utils/fields/MyInputField"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { generateCurrentPeriodeNextVersionRefRequest } from "../../../client/ptf/create/core/_requests"
import { getCurrentPtfRequest } from "../core/_requests"

const ConsultantSuiviChangementTjm = ({
     consultant,
     annulerCallback = () => {},
     onSubmitCallback = () => {},
     setModalNoPaddingCallback = () => {},
     setModalSizeCallback = () => {},
}: IPropsModel) => {
     const [generatedPtf, setGeneratedPtf] = useState<{
          loading: boolean
          googleDriveID: string
     }>()

     const [reference, setReference] = useState<{ loading: boolean; ref: string | null; error: boolean }>({
          loading: true,
          ref: null,
          error: false,
     })

     // Form schema
     const schema = Yup.object().shape({
          ptfUploadedOrGenerated: Yup.string().required().label(`"Génération ou upload"`),
          ptf: Yup.mixed()
               .test("fileFormat", "Seul le format Word est autorisé.", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_DOCX].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"PTF"`)
               .when("ptfUploadedOrGenerated", {
                    is: "UPLOADED",
                    then: schema => schema.required(),
               }),
          date: Yup.string().required().label(`"Date"`),
          tjm: Yup.number().required().label(`"TJM"`),
     })

     // Form initial values
     const initialValues: IConsultantSuiviEvolutionTjmRequestModel = {}

     // Handle submit
     function handleSubmit(values: IConsultantSuiviEvolutionTjmRequestModel, helpers: FormikHelpers<IConsultantSuiviEvolutionTjmRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviEvolutionTjmRequest(consultant.id as number, values)
               .then(r => {
                    if (values.ptfUploadedOrGenerated === "UPLOADED") {
                         onSubmitCallback()
                         toast.success(`Le changement de TJM a bien été enregistré.`)
                    } else {
                         setModalNoPaddingCallback(true)
                         setModalSizeCallback("fullscreen")
                         setGeneratedPtf({ loading: true, googleDriveID: r.data.ptfGoogleDriveID })
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Get current periode next version ref
     function genererCurrentPeriodeNextVersionRef() {
          setReference(prev => ({ ...prev, loading: true, error: false }))
          getCurrentPtfRequest(consultant.id as number)
               .then(r => {
                    generateCurrentPeriodeNextVersionRefRequest(r.data.id as number)
                         .then(r => {
                              setReference(prev => ({ ...prev, loading: false, error: false, ref: r.data.ref }))
                         })
                         .catch(() => {
                              setReference(prev => ({ ...prev, error: true, loading: false }))
                              toast.error("Une erreur s'est produite lors de la génération de la référence.")
                         })
               })
               .catch(() => {
                    setReference(prev => ({ ...prev, error: true, loading: false }))
                    toast.error("Une erreur s'est produite lors de la génération de la référence.")
               })
     }

     useEffect(() => {
          genererCurrentPeriodeNextVersionRef()
     }, [])
     if (generatedPtf) {
          return (
               <>
                    <div className="text-center mt-2">
                         <button
                              type={"button"}
                              className={"btn btn-sm btn-primary"}
                              onClick={() => {
                                   onSubmitCallback()
                                   toast.success(`Le changement de TJM a bien été enregistré.`)
                              }}
                         >
                              TERMINER <i className={"fas fa-check-circle"} />
                         </button>
                    </div>
                    <MyLoadingOverlay isActive={generatedPtf.loading}>
                         <iframe
                              onLoad={() => setGeneratedPtf(prev => prev && { ...prev, loading: false })}
                              src={FileAndDriveHelper.getUrlOfDriveFileEditMode(generatedPtf.googleDriveID)}
                              className={"vh-100 w-100"}
                         />
                    </MyLoadingOverlay>
               </>
          )
     } else {
          return (
               <>
                    {reference.loading && (
                         <div className={"text-center"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {reference.error && (
                         <MyAlert type={"danger"}>
                              <div className="text-center">
                                   Une erreur s'est produite lors de la génération de la référence. <br />
                                   <button type={"button"} className={"btn btn-danger btn-sm mt-2"} onClick={genererCurrentPeriodeNextVersionRef}>
                                        Re-générer la référence <i className={"fas fa-sync ms-1"} />
                                   </button>
                              </div>
                         </MyAlert>
                    )}

                    {!reference.loading && !reference.error && (
                         <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                              {helpers => {
                                   console.log(helpers.errors)
                                   return (
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}

                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de prise d'effet</label>

                                                  <div className="col-lg-8">
                                                       <MyDateDayMonthYearField
                                                            name={"date"}
                                                            value={helpers.values.date}
                                                            isInvalid={!!helpers.errors.date}
                                                            onChange={val => helpers.setFieldValue("date", val)}
                                                            placeholder={"Date de prise d'effet"}
                                                       />
                                                       {helpers.errors.date && <div className={"text-danger"}>{helpers.errors.date}</div>}
                                                  </div>
                                             </div>

                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nouveau TJM</label>

                                                  <div className="col-lg-8">
                                                       <MyInputField
                                                            name={"tjm"}
                                                            type={"number"}
                                                            inputMode={"numeric"}
                                                            value={helpers.values.tjm}
                                                            isInvalid={!!helpers.errors.tjm}
                                                            onChange={val => helpers.setFieldValue("tjm", val)}
                                                            placeholder={"Nouveau TJM"}
                                                       />
                                                       {helpers.errors.tjm && <div className={"text-danger"}>{helpers.errors.tjm}</div>}
                                                  </div>
                                             </div>

                                             <div className="separator separator-dashed mb-6" />

                                             {/* ODM */}
                                             <div className="border border-dashed p-4 mb-4">
                                                  <h4 className={"mb-4"}>{reference.ref}</h4>
                                                  <div className="separator separator-dashed mb-6" />
                                                  <MyAlert type={"primary"} classNames={"mb-4"}>
                                                       Assurez-vous d'utiliser la référence ci-dessus dans votre nouvelle version de PTF.
                                                  </MyAlert>
                                                  <div className="row">
                                                       <div className="col-lg-6 mb-2 mb-lg-0">
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"ptfUploadedOrGenerated"}
                                                                 value={"UPLOADED"}
                                                                 isChecked={helpers.values.ptfUploadedOrGenerated === "UPLOADED"}
                                                                 label={"Saisie manuelle"}
                                                                 description={"Joignez votre propre document"}
                                                                 iconFontAwesomeClassName={"upload"}
                                                                 color={"primary"}
                                                                 isInvalid={!!helpers.errors.ptfUploadedOrGenerated}
                                                                 onChange={val => helpers.setFieldValue("ptfUploadedOrGenerated", val)}
                                                            />
                                                       </div>
                                                       <div className="col-lg-6">
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"ptfUploadedOrGenerated"}
                                                                 value={"GENERATED"}
                                                                 isChecked={helpers.values.ptfUploadedOrGenerated === "GENERATED"}
                                                                 label={
                                                                      <span>
                                                                           Génération automatique <br /> ( recommandé )
                                                                      </span>
                                                                 }
                                                                 description={"Générer un ODM pré-rempli éditable en ligne"}
                                                                 iconFontAwesomeClassName={"bolt"}
                                                                 color={"success"}
                                                                 isInvalid={!!helpers.errors.ptfUploadedOrGenerated}
                                                                 onChange={val => helpers.setFieldValue("ptfUploadedOrGenerated", val)}
                                                            />
                                                       </div>
                                                  </div>

                                                  {/* PTF */}
                                                  {helpers.values.ptfUploadedOrGenerated === "UPLOADED" && (
                                                       <div className="row mt-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">PTF</label>

                                                            <div className="col-lg-8">
                                                                 <MyFileField
                                                                      name={"ptf"}
                                                                      onChange={val => helpers.setFieldValue("ptf", val)}
                                                                      isInvalid={!!helpers.errors.ptf}
                                                                      accept={`${CONST_MIME_APPLICATION_DOCX}`}
                                                                 />
                                                                 <div className={"mb-2"}>{helpers.errors.ptf && <div className={"text-danger"}>{helpers.errors.ptf}</div>}</div>
                                                            </div>
                                                       </div>
                                                  )}

                                                  {helpers.values.ptfUploadedOrGenerated === "GENERATED" && (
                                                       <MyAlert type={"primary"} classNames={"mt-4"}>
                                                            <b className={"text-dark"}>Vous avez opter pour une génération automatique</b>. Une fois que vous aurez validé le
                                                            formulaire, une nouvelle version de la PTF sera générée et vous pourrez ensuite le modifier en ligne.
                                                       </MyAlert>
                                                  )}
                                             </div>

                                             <div className={"mb-2"}>
                                                  {helpers.errors.ptfUploadedOrGenerated && <div className={"text-danger"}>{helpers.errors.ptfUploadedOrGenerated}</div>}
                                             </div>

                                             {/* Submit button */}
                                             <div className={"d-flex justify-content-end"}>
                                                  <button
                                                       className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                       type={"button"}
                                                       onClick={annulerCallback}
                                                       disabled={helpers.isSubmitting}
                                                  >
                                                       Annuler
                                                  </button>
                                                  <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? <>Valider</> : <MySimpleSpinner size={"sm"} />}
                                                  </button>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    )}
               </>
          )
     }
}

interface IPropsModel {
     consultant: IConsultantModel
     annulerCallback?: () => void
     onSubmitCallback?: () => void
     setModalNoPaddingCallback?: (noPadding: boolean) => void
     setModalSizeCallback?: (size: "lg" | "xl" | "fullscreen") => void
}

export default ConsultantSuiviChangementTjm
