import axios from "axios"
import { IClientPropalModel, IConsultantArretMaladieModel, IConsultantDdcModel, IConsultantDocumentModel, IConsultantMissionModel } from "@common-models/*"
import {
     IGetConsultantArretsRequestModel,
     IGetConsultantDdcRequestModel,
     IGetConsultantsListingRequestModel,
     IGetConsultantsListingResponseModel,
     ISetConsultantDocumentDetailsRequestModel,
     ISetConsultantMissionDetailsRequestModel,
     ISimulationRentabiliteConsultantAvantApresRequestModel,
     ISimulationRentabiliteConsultantAvantApresResponseModel,
     ISimulationRentabiliteConsultantResponseModel,
} from "./_models"
import {
     CONST_API_BUSINESS_CONSULTANT_DOCUMENT_DELETE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_ADD_VERSION_SIGNEE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_REPLACE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_SET_DETAILS_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_ARRETS_MALADIE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_CURRENT_PTF_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_DDC_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_MISSION_POST_ADD_DOC_SIGNEE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_MISSION_POST_REPLACE_DOC_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_MISSION_POST_SET_DETAILS_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_ADD_DOCUMENT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_LISTING_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SIMULATION_RENTABILITE_ENDPOINT,
     CONST_API_UTILS_POST_SIMULATION_RENTABILITE_ENDPOINT,
} from "../../../../endpoints"

export function getCurrentPtfRequest(id: number) {
     return axios.get<IClientPropalModel>(CONST_API_BUSINESS_CONSULTANT_GET_CURRENT_PTF_ENDPOINT(id))
}

export function getConsultantsListingRequest(payload: IGetConsultantsListingRequestModel) {
     return axios.post<IGetConsultantsListingResponseModel>(CONST_API_BUSINESS_CONSULTANT_POST_LISTING_ENDPOINT, payload)
}

export function getConsultantDdcRequest(id: number, payload: IGetConsultantDdcRequestModel) {
     return axios.post<IConsultantDdcModel[]>(CONST_API_BUSINESS_CONSULTANT_GET_DDC_ENDPOINT(id), payload)
}

export function getConsultantArretsRequest(id: number, payload: IGetConsultantArretsRequestModel) {
     return axios.post<IConsultantArretMaladieModel[]>(CONST_API_BUSINESS_CONSULTANT_GET_ARRETS_MALADIE_ENDPOINT(id), payload)
}

export function addConsultantDocumentRequest(id: number, type: string, fichier: File, dateDebutValidite?: string) {
     const formData = new FormData()
     formData.append("fichier", fichier)
     formData.append("type", type)
     if (dateDebutValidite) formData.append("dateDebutValidite", dateDebutValidite)

     return axios.post<IConsultantDocumentModel>(CONST_API_BUSINESS_CONSULTANT_POST_ADD_DOCUMENT_ENDPOINT(id), formData)
}

export function getSimulationRentabiliteAvantApres(id, payload: ISimulationRentabiliteConsultantAvantApresRequestModel) {
     return axios.post<ISimulationRentabiliteConsultantAvantApresResponseModel>(CONST_API_BUSINESS_CONSULTANT_POST_SIMULATION_RENTABILITE_ENDPOINT(id), payload)
}

export function getSimulationRentabilite(payload: ISimulationRentabiliteConsultantAvantApresRequestModel) {
     return axios.post<{ btc: ISimulationRentabiliteConsultantResponseModel }>(CONST_API_UTILS_POST_SIMULATION_RENTABILITE_ENDPOINT, payload)
}

/* Consultant mission */

// -- Set details
export function setConsultantMissionDetails(mission_id: number, payload: ISetConsultantMissionDetailsRequestModel) {
     const formData = new FormData()
     if (payload.type) formData.append("data[type]", payload.type)
     if (payload.dateDebutValidite) formData.append("data[dateDebutValidite]", payload.dateDebutValidite)
     if (payload.dateFinValidite) formData.append("data[dateFinValidite]", payload.dateFinValidite)

     return axios.post<IConsultantMissionModel>(CONST_API_BUSINESS_CONSULTANT_MISSION_POST_SET_DETAILS_ENDPOINT(mission_id), formData)
}

// -- Replace doc
export function replaceConsultantMissionDoc(mission_id: number, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)

     return axios.post<IConsultantMissionModel>(CONST_API_BUSINESS_CONSULTANT_MISSION_POST_REPLACE_DOC_ENDPOINT(mission_id), formData)
}

// -- Add version signée
export function addVersionSigneeConsultantMissionDoc(mission_id: number, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)

     return axios.post<IConsultantMissionModel>(CONST_API_BUSINESS_CONSULTANT_MISSION_POST_ADD_DOC_SIGNEE_ENDPOINT(mission_id), formData)
}

/* Consultant document */

// -- Set details
export function setConsultantDocumentDetails(consultant_doc_id: number, payload: ISetConsultantDocumentDetailsRequestModel) {
     const formData = new FormData()
     if (payload.dateDebutValidite) formData.append("data[dateDebutValidite]", payload.dateDebutValidite)

     return axios.post<IConsultantDocumentModel>(CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_SET_DETAILS_ENDPOINT(consultant_doc_id), formData)
}

// -- Add version signée
export function addVersionSigneeConsultantDocument(consultant_doc_id: number, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)

     return axios.post<IConsultantDocumentModel>(CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_ADD_VERSION_SIGNEE_ENDPOINT(consultant_doc_id), formData)
}

// -- Replace doc
export function replaceConsultantDocument(consultant_doc_id: number, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)

     return axios.post<IConsultantDocumentModel>(CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_REPLACE_ENDPOINT(consultant_doc_id), formData)
}

// -- Delete
export function deleteConsultantDocument(consultant_doc_id: number) {
     return axios.delete(CONST_API_BUSINESS_CONSULTANT_DOCUMENT_DELETE_ENDPOINT(consultant_doc_id))
}
