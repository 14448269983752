import axios from "axios"
import {
     CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_TOTAL_BL_MANQUANTS_OF_GIVEN_YEAR_ENDPOINT,
     CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_TOTAL_BL_NON_FACTURES_OF_GIVEN_YEAR_ENDPOINT,
} from "../../../../endpoints"
import moment from "moment"

export function getTotalBonsLivraisonManquantsOfGivenYearRequest({ year }: { year: string }) {
     return axios.post<{ mois: moment.Moment; total: number }[]>(CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_TOTAL_BL_MANQUANTS_OF_GIVEN_YEAR_ENDPOINT, { year })
}

export function getTotalBonsLivraisonNonFacturesOfGivenYearRequest({ year }: { year: string }) {
     return axios.post<{ mois: moment.Moment; total: number }[]>(CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_TOTAL_BL_NON_FACTURES_OF_GIVEN_YEAR_ENDPOINT, { year })
}
