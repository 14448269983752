import React, { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { getConsultantDeclarationsActiviteRequest } from "../core/_requests"
import { IConsultantDeclarationMensuelleModel, IConsultantDeclarationMensuelleSuiviModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { useConsultantsDeclarationsListingContext } from "../../declaration/listing/ConsultantsDeclarationsListing"
import { EtatBadge } from "../../declaration/listing/_list/_ConsultantsDeclarationsListingListItemHelpers"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyModal from "@common-utils/MyModal"
import ConsultantDeclarationDetails from "../../declaration/details/_ConsultantDeclarationDetails"
import { CONST_DEFAULT_TOAST_OPTIONS } from "@common-constants/*"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import ConsultantDeclarationSuivi from "../../declaration/_ConsultantDeclarationSuivi"
import MyTooltip from "@common-utils/MyTooltip"

const REACT_QUERY_KEY_GET_CONSULTANT_DECLARATIONS = "REACT_QUERY_KEY_GET_CONSULTANT_DECLARATIONS"
const ConsultantDetailsDeclarationsActivite = () => {
     const context = useConsultantDetailsContext()
     const [suiviInModal, setSuiviInModal] = useState<IConsultantDeclarationMensuelleModel>()

     // Get declarations query
     const declarationsQuery = useQuery<IConsultantDeclarationMensuelleModel[], AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_DECLARATIONS, async () => {
          return getConsultantDeclarationsActiviteRequest(context.detailsQuery.data?.consultant.id as number)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const DeclarationItem = ({ item }: { item: IConsultantDeclarationMensuelleModel }) => {
          const queryClient = useQueryClient()
          const context = useConsultantsDeclarationsListingContext()
          const [isItemInModal, setIsItemInModal] = useState<boolean>(false)

          // Handles demande rectification
          function handleDemandeRectificationCallback(val: IConsultantDeclarationMensuelleSuiviModel) {
               queryClient.setQueryData<IConsultantDeclarationMensuelleModel[] | undefined>(
                    REACT_QUERY_KEY_GET_CONSULTANT_DECLARATIONS,
                    (prev: IConsultantDeclarationMensuelleModel[] | undefined) => {
                         if (prev) {
                              const index = prev.findIndex(item_ => item_.id === item.id)
                              prev[index] = {
                                   ...prev[index],
                                   etat: val,
                              }
                         }

                         return prev
                    }
               )

               setIsItemInModal(false)
          }

          // Handles validation
          function handleValidationCallback(val: IConsultantDeclarationMensuelleSuiviModel) {
               queryClient.setQueryData<IConsultantDeclarationMensuelleModel[] | undefined>(
                    REACT_QUERY_KEY_GET_CONSULTANT_DECLARATIONS,
                    (prev: IConsultantDeclarationMensuelleModel[] | undefined) => {
                         if (prev) {
                              prev.map(item_ => {
                                   if (item_.id === item.id) {
                                        item_.etat = val
                                        return item_
                                   }
                              })
                         }

                         return prev
                    }
               )

               setIsItemInModal(false)
          }

          // Is tr clickable
          function isTrClickable(e: React.MouseEvent<any>): boolean {
               const clickedElement = e.target as HTMLElement
               // .not-concerned-by-tr-click elements are not concerned by the click
               if (!clickedElement.classList.contains("not-concerned-by-tr-click") && context.itemInModal?.selectedItem.declaration?.id !== item?.id) {
                    return !!item
               }

               return false
          }

          // Shows déclaration in a modal
          function handleClickItem(e: React.MouseEvent<any>) {
               const clickedElement = e.target as HTMLElement
               // .not-concerned-by-tr-click elements are not concerned by the click
               if (!clickedElement.classList.contains("not-concerned-by-tr-click")) {
                    const isRowClickable = isTrClickable(e)

                    if (isRowClickable) {
                         setIsItemInModal(true)
                    } else {
                         toast.info("Aucune déclaration à afficher", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 1000 })
                    }
               }
          }

          return (
               <>
                    <tr className={`bg-hover-light-primary`} key={item.consultant?.id} onClick={handleClickItem}>
                         <td>{GlobalHelper.firstLetterUppercase(item.mois?.format("MMMM YYYY"))}</td>
                         <td>
                              <MyTooltip title={"Voir le suivi"}>
                                   <div onClick={() => setSuiviInModal(item)}>
                                        <EtatBadge declaration={item} />
                                   </div>
                              </MyTooltip>
                         </td>
                         <td>
                              <MyTooltip title={"Consulter cette déclaration"}>
                                   <span className={"fas fa-eye fs-1 text-dark"} />
                              </MyTooltip>
                         </td>
                    </tr>

                    {/* Modal with déclaration details */}
                    {isItemInModal && (
                         <MyModal title={<span>Déclaration de {item.consultant!.prenomNom}</span>} show={true} handleClose={() => setIsItemInModal(false)} fullscreen>
                              <ConsultantDeclarationDetails
                                   declaration={item}
                                   handleDemandeRectificationCallback={handleDemandeRectificationCallback}
                                   handleValidationCallback={handleValidationCallback}
                                   defaultStep={"cra"}
                              />
                         </MyModal>
                    )}
               </>
          )
     }

     return (
          <>
               {declarationsQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}
               {!declarationsQuery.isFetching && declarationsQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{declarationsQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => declarationsQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}
               {!declarationsQuery.isFetching && !declarationsQuery.isError && declarationsQuery.data && declarationsQuery.data.length > 0 && (
                    <>
                         <table className={`table table-rounded  gy-7 gs-7 m-0 p-0`}>
                              <thead className={`bg-light border-bottom border-secondary`}>
                                   <tr>
                                        <td>Mois</td>
                                        <td>État</td>
                                        <td></td>
                                   </tr>
                              </thead>
                              <tbody>
                                   {declarationsQuery.data.map((item: IConsultantDeclarationMensuelleModel, key) => (
                                        <DeclarationItem item={item} key={key} />
                                   ))}
                              </tbody>
                         </table>
                    </>
               )}
               {!declarationsQuery.isFetching && !declarationsQuery.isError && declarationsQuery.data?.length === 0 && (
                    <MyAlert type={"primary"}>Aucun déclaration d'activité à afficher</MyAlert>
               )}

               {/* Modal with suivi */}
               {suiviInModal && (
                    <MyModal title={<span>Suivi de la déclaration de {suiviInModal.consultant!.prenomNom}</span>} show={true} handleClose={() => setSuiviInModal(undefined)}>
                         <ConsultantDeclarationSuivi declaration={suiviInModal} />
                    </MyModal>
               )}
          </>
     )
}

export default ConsultantDetailsDeclarationsActivite
