import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { IHttpErrorResponseModel, OffreModel } from "@common-models/*"
import MyCard from "@common-utils/MyCard"
import { archiverAnnoncesRequest } from "../core/_requests"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { useMutation } from "react-query"

const AnnoncesActionArchiver = ({ annonces, callback = () => {} }: IAnnoncesActionArchiverPropsModel) => {
     const archiverAnnonceMutation = useMutation((ids: number[]) => archiverAnnoncesRequest(ids))

     function handleSubmit() {
          const ids = annonces.map(item => item.id as number)
          archiverAnnonceMutation
               .mutateAsync(ids)
               .then(() => {
                    toast.success(annonces.length > 1 ? "Les annonces que vous avez choisies ont bien été archivées." : "L'annonce a bien été archivée.")
                    callback()
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data
                    toast.error(error?.detail, { autoClose: false })
               })
     }

     return (
          <>
               <MyCard classNames={"mb-10"}>
                    <MyCard.Header isCollapseIconShown>Annonces concernées ( {annonces.length} )</MyCard.Header>
                    <MyCard.Body>
                         <>
                              {annonces.map(item => (
                                   <div key={item.intitule}>{item.intitule}</div>
                              ))}
                         </>
                    </MyCard.Body>
               </MyCard>

               {/* Submit button */}
               <div className={"d-flex justify-content-end"}>
                    <button
                         className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                         type={"button"}
                         disabled={archiverAnnonceMutation.isLoading}
                         onClick={handleSubmit}
                    >
                         {!archiverAnnonceMutation.isLoading ? <>Confirmer l'archivage</> : <MySimpleSpinner size={"sm"} />}
                    </button>
               </div>
          </>
     )
}

interface IAnnoncesActionArchiverPropsModel {
     annonces: OffreModel[]
     callback?: () => void
}

export default AnnoncesActionArchiver
