import { IGetDocumentsConsultant } from "../core/_models"
import React from "react"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { getConsultantDocuments } from "../core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import ConsultantDetailsDocumentsPerso from "./_ConsultantDetails_DocumentsPerso"
import ConsultantDetailsDocumentsFournisseur from "./_ConsultantDetails_DocumentsFournisseur"
import ConsultantDetailsDocumentsPro from "./_ConsultantDetails_DocumentsPro"
import ConsultantDetailsDocumentsAutres from "./_ConsultantDetails_DocumentsAutres"
import PtfDetailsPeriodesBonsCommandeBonsLivraison from "../../../../client/ptf/periodesBonsCommandeBonsLivraison/_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import MyCard from "@common-utils/MyCard"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import ConsultantDetails_DocumentsCraDdcNdfAvantFevrier2024 from "./_ConsultantDetails_DocumentsCraDdcNdfAvantFevrier2024"

export const REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS = "REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS"
const ConsultantDetailsDocuments = () => {
     const context = useConsultantDetailsContext()

     // Get documents query
     const documentsQuery = useQuery<IGetDocumentsConsultant, AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, async () => {
          return getConsultantDocuments(context.detailsQuery.data?.consultant.id as number).then(r => {
               return r.data
          })
     })

     return (
          <>
               <div className="p-4">
                    {documentsQuery.isFetching && (
                         <div className={"text-center p-5"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {!documentsQuery.isFetching && documentsQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{documentsQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => documentsQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}

                    {!documentsQuery.isFetching && !documentsQuery.isError && documentsQuery.data && (
                         <>
                              {/* Document fournisseur */}
                              {context.detailsQuery.data?.consultant.contrat === "CONTRAT_ST" && (
                                   <MyCard classNames={"mb-4"} defaultIsCollapsed>
                                        <MyCard.Header isCollapseIconShown>Documents "{context.detailsQuery.data?.st?.fournisseur?.raisonSociale}"</MyCard.Header>
                                        <MyCard.Body>
                                             <ConsultantDetailsDocumentsFournisseur documents={documentsQuery.data} />
                                        </MyCard.Body>
                                   </MyCard>
                              )}

                              {/* Documents perso */}
                              <MyCard classNames={"mb-4"} defaultIsCollapsed>
                                   <MyCard.Header isCollapseIconShown>Documents personnels</MyCard.Header>
                                   <MyCard.Body>
                                        <ConsultantDetailsDocumentsPerso documents={documentsQuery.data} />
                                   </MyCard.Body>
                              </MyCard>

                              <MyCard classNames={"mb-4"} defaultIsCollapsed>
                                   <MyCard.Header isCollapseIconShown>Documents professionnels</MyCard.Header>
                                   <MyCard.Body>
                                        <ConsultantDetailsDocumentsPro documents={documentsQuery.data} />
                                   </MyCard.Body>
                              </MyCard>

                              <MyCard classNames={"mb-4"} defaultIsCollapsed>
                                   <MyCard.Header isCollapseIconShown>Documents commerciaux</MyCard.Header>
                                   <MyCard.Body>
                                        {documentsQuery.data.documentsPro.missions ? (
                                             documentsQuery.data.documentsPro.missions.length > 0 && (
                                                  <PtfDetailsPeriodesBonsCommandeBonsLivraison
                                                       pFilters={{ ptfs: documentsQuery.data.documentsPro.missions.map(item => item.propal?.id as number) }}
                                                  />
                                             )
                                        ) : (
                                             <MyAlert type={"primary"}>Aucun document commercial n'est disponible</MyAlert>
                                        )}
                                   </MyCard.Body>
                              </MyCard>

                              <MyCard classNames={"mb-4"} defaultIsCollapsed>
                                   <MyCard.Header isCollapseIconShown>Autres</MyCard.Header>
                                   <MyCard.Body>
                                        <ConsultantDetailsDocumentsAutres documents={documentsQuery.data} />
                                   </MyCard.Body>
                              </MyCard>

                              {documentsQuery.data.documentsCraNdfDdcBeforeFevrier2024.length > 0 && (
                                   <>
                                        <div className="separator separator-dashed border-secondary my-5" />
                                        <MyCard classNames={"mb-4"} defaultIsCollapsed={false}>
                                             <MyCard.Header isCollapseIconShown>Déclarations avant février 2024</MyCard.Header>
                                             <MyCard.Body>
                                                  <ConsultantDetails_DocumentsCraDdcNdfAvantFevrier2024 documents={documentsQuery.data} />
                                             </MyCard.Body>
                                        </MyCard>
                                   </>
                              )}
                         </>
                    )}
               </div>
          </>
     )
}

export default ConsultantDetailsDocuments
