import React, { useEffect } from "react"
import MyCard from "@common-utils/MyCard"
import { useViviersListingContext } from "./ViviersListing"
import { IGetViviersResponseItemModel, IGetViviersResponseModel, ISetVivierRequestModel } from "./core/_models"
import { useAuth } from "../../../../AppContext"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "@common-utils/MyAlert"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { editVivierRequest } from "./core/_requests"
import { useQueryClient } from "react-query"
import MyTextareaField from "@common-utils/fields/MyTextareaField"

const Preview = () => {
     const globalContext = useViviersListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.vivier.id === selectedItemInPreview?.vivier.id) as IGetViviersResponseItemModel

     return (
          <>
               <div className="row mb-4">
                    <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Date de création</label>

                    <div className="col-sm-8">
                         <input className={"form-control form-control-sm form-control-solid"} value={itemInPreview.vivier.date?.format("DD/MM/YYYY")} readOnly />
                    </div>
               </div>

               <div className="row mb-4">
                    <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Responsable</label>

                    <div className="col-sm-8">
                         <input className={"form-control form-control-sm form-control-solid"} value={itemInPreview.vivier.personnel?.prenomNom} readOnly />
                    </div>
               </div>

               <div className="row mb-4">
                    <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Description du poste</label>

                    <div className="col-sm-8">
                         <textarea className={"form-control form-control-sm form-control-solid"} rows={8} value={itemInPreview.vivier.description} readOnly />
                    </div>
               </div>
          </>
     )
}

const FormEdit = () => {
     const queryClient = useQueryClient()
     const globalContext = useViviersListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.vivier.id === selectedItemInPreview?.vivier.id) as IGetViviersResponseItemModel

     const initialValues: ISetVivierRequestModel = {
          titre: itemInPreview.vivier.intitule,
          description: itemInPreview.vivier.description,
     }

     const schema = Yup.object().shape({
          titre: Yup.string().label(`"Titre"`).nullable().required(),
          description: Yup.string().label(`"Description"`).nullable(),
     })

     function handleSubmit(values: ISetVivierRequestModel, helpers: FormikHelpers<ISetVivierRequestModel>) {
          helpers.setStatus(null)

          editVivierRequest(itemInPreview.vivier.id as number, values)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    queryClient.setQueryData<IGetViviersResponseModel | undefined>(globalContext.REACT_QUERY_KEY_GET_LISTING, (prev: IGetViviersResponseModel | undefined) => {
                         if (prev?.data) {
                              prev?.data.map(item => {
                                   if (item.vivier.id === itemInPreview.vivier.id) {
                                        item.vivier = {
                                             ...item.vivier,
                                             ...r.data,
                                        }
                                        return item
                                   }
                              })
                         }

                         return prev
                    })
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Titre */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"titre"}
                                                  value={helpers.values.titre}
                                                  isInvalid={!!helpers.errors.titre}
                                                  onChange={val => helpers.setFieldValue("titre", val)}
                                                  placeholder={"Titre"}
                                             />
                                             {helpers.errors.titre && <div className={"text-danger"}>{helpers.errors.titre}</div>}
                                        </div>
                                   </div>

                                   {/* Description */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Description</label>

                                        <div className="col-lg-8">
                                             <MyTextareaField
                                                  name={"description"}
                                                  value={helpers.values.description}
                                                  isInvalid={!!helpers.errors.description}
                                                  onChange={val => helpers.setFieldValue("description", val)}
                                                  placeholder={"Titre"}
                                             />
                                             {helpers.errors.description && <div className={"text-danger"}>{helpers.errors.description}</div>}
                                        </div>
                                   </div>

                                   <div className="separator bg-primary my-5"></div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

/* Wrapper */
const AnnoncesListingPreview = () => {
     const { currentUser } = useAuth()
     const globalContext = useViviersListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.vivier.id === selectedItemInPreview?.vivier.id) as IGetViviersResponseItemModel

     useEffect(() => {
          globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))
     }, [itemInPreview])
     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>
                    <div className="d-flex align-items-center">
                         <span className={"fas fa-eye-slash fs-4 text-dark p-2 cursor-pointer"} onClick={() => globalContext.setItemInPreview(null)} />
                         <span className={"me-2"}>{itemInPreview?.vivier.intitule}</span>
                    </div>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <>
                         <div className={"d-flex justify-content-center p-5"}>
                              <div className={"w-100 w-md-75 w-xl-100 w-xxl-75"}>
                                   {currentUser?.teamRecrutement?.find(item => item.id === itemInPreview.vivier.personnel?.id) ? <FormEdit /> : <Preview />}
                              </div>
                         </div>
                    </>
               </MyCard.Body>
          </MyCard>
     )
}

export default AnnoncesListingPreview
