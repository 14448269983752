import { IGetDetailsConsultant } from "../core/_models"

const ConsultantDetailsInfoBaseInfoContract = ({ consultantInfoPerso }: IConsultantDetailsInfoBaseInfoContractPropsModel) => {
     const contratLabel = consultantInfoPerso.detailsDuJour?.contratLabel || consultantInfoPerso.consultant.contratLabel
     const statutLabel = consultantInfoPerso.detailsDuJour?.statutLabel || consultantInfoPerso.consultant.statutLabel
     const metier = consultantInfoPerso.detailsDuJour?.metier || consultantInfoPerso.consultant.metier
     const salaireOuTJMST = consultantInfoPerso.detailsDuJour?.coutBrutNonCharge || consultantInfoPerso.consultant.coutBrutNonCharge
     const heuresTravail = consultantInfoPerso.detailsDuJour?.heures_travail || consultantInfoPerso.consultant.heuresTravail
     const heuresTravailSupp = consultantInfoPerso.detailsDuJour?.heures_travail_supp || consultantInfoPerso.consultant.heuresTravailSupp
     const entiteRattachement = consultantInfoPerso.detailsDuJour?.entite || consultantInfoPerso.consultant.entite
     const entiteFacturation = consultantInfoPerso.detailsDuJour?.entiteFacturation || consultantInfoPerso.consultant.entiteFacturation

     return (
          <>
               {/* Date entrée */}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">{consultantInfoPerso.consultant.contrat === "CONTRAT_ST" ? "Date d'entrée" : "Date d'embauche"}</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">Le {consultantInfoPerso.consultant.dateEmbauche?.format("dddd D MMMM YYYY")}</span>
                    </div>
               </div>

               {/* Date sortie */}
               {consultantInfoPerso.consultant.dateSortie && (
                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Date de sortie</label>
                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">Le {consultantInfoPerso.consultant.dateSortie?.format("dddd D MMMM YYYY")}</span>
                         </div>
                    </div>
               )}

               {/* Statut */}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Statut</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{statutLabel}</span>
                    </div>
               </div>

               {/* Contrat */}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Contrat</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{contratLabel}</span>
                    </div>
               </div>

               {/* Métier */}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Métier</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{metier?.nom}</span>
                    </div>
               </div>

               {/* Salaire / TJMST */}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                         {consultantInfoPerso.consultant.contrat === "CONTRAT_ST" ? "Taux d'achat journalier" : "Salaire brut base 35€"}
                    </label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {consultantInfoPerso.consultant.contrat === "CONTRAT_ST" ? `${salaireOuTJMST}€ HT / jour` : `${salaireOuTJMST}€ / mois`}
                         </span>
                    </div>
               </div>

               {consultantInfoPerso.consultant.contrat !== "CONTRAT_ST" && (
                    <>
                         {/* Heures & Heures supp */}
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Heures de travail</label>
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">{heuresTravail}h / jour</span>
                              </div>
                         </div>
                         {/* Heures & Heures supp */}
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Heures supp.</label>
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">{heuresTravailSupp}h / jour</span>
                              </div>
                         </div>
                    </>
               )}

               {/* Entité de rattachement */}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Entité de rattachement</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{entiteRattachement?.nom}</span>
                    </div>
               </div>

               {/* Entité de facturation */}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Entité de facturation</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{entiteFacturation?.nom}</span>
                    </div>
               </div>
          </>
     )
}

interface IConsultantDetailsInfoBaseInfoContractPropsModel {
     consultantInfoPerso: IGetDetailsConsultant
}

export default ConsultantDetailsInfoBaseInfoContract
