const GlobalHelper = {
     separateNumber(number: any, separator: string = " "): string {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
     },
     firstLetterUppercase(string: string | undefined) {
          return string ? string[0].toUpperCase() + string.slice(1) : ""
     },

     isNumeric(str: string): boolean {
          if (str.trim() === "") {
               return false
          }

          return !Number.isNaN(Number(str))
     },

     sumTwoNumbers(one: number | null | undefined, two: number | null | undefined): number {
          if (typeof one === "number" && typeof two !== "number") return one
          if (typeof one !== "number" && typeof two === "number") return two

          if (typeof one === "number" && typeof two === "number") {
               return one + two
          } else {
               return 0
          }
     },

     randomId(length = 6) {
          return Math.random()
               .toString(36)
               .substring(2, length + 2)
     },

     incrementChar(char: string, incrementBy: number = 1, uppercaseOrLowerCase: "upper" | "lower" = "lower"): string {
          const incrementedChar = String.fromCharCode(char.charCodeAt(0) + incrementBy)
          if (uppercaseOrLowerCase === "upper") {
               return GlobalHelper.firstLetterUppercase(incrementedChar)
          }

          return incrementedChar
     },
     truncateString(str: string, maxLength: number): string {
          if (str.length > maxLength) {
               return str.substring(0, maxLength) + "..."
          } else {
               return str
          }
     },
}

export default GlobalHelper
