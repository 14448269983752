import axios from "axios"
import {
     CONST_API_PERSONNEL_TEMPLATES_DELETE_ENDPOINT,
     CONST_API_PERSONNEL_TEMPLATES_GET_DETAILS_ENDPOINT,
     CONST_API_PERSONNEL_TEMPLATES_POST_CREATE_ENDPOINT,
     CONST_API_PERSONNEL_TEMPLATES_PUT_EDIT_ENDPOINT,
} from "../../../../../endpoints"
import { IPersonnelTemplateCreateRequestModel, IPersonnelTemplateEditRequestModel } from "./_models"
import { IPersonnelTemplateModel } from "@common-models/*"

export function getDetailsPersonnelTemplate(id: number) {
     return axios.get<IPersonnelTemplateModel>(CONST_API_PERSONNEL_TEMPLATES_GET_DETAILS_ENDPOINT(id))
}

export function createPersonnelTemplate(data: IPersonnelTemplateCreateRequestModel) {
     return axios.post<IPersonnelTemplateModel>(CONST_API_PERSONNEL_TEMPLATES_POST_CREATE_ENDPOINT, data)
}

export function editPersonneltemplate(id: number, data: IPersonnelTemplateEditRequestModel) {
     return axios.put<IPersonnelTemplateModel>(CONST_API_PERSONNEL_TEMPLATES_PUT_EDIT_ENDPOINT(id), data)
}

export function deletePersonnelTemplate(id: number) {
     return axios.delete<IPersonnelTemplateModel>(CONST_API_PERSONNEL_TEMPLATES_DELETE_ENDPOINT(id))
}
