import React, { useMemo, useState } from "react"
import * as Yup from "yup"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import Split from "react-split-it"
import { Form, Formik, FormikHelpers } from "formik"
import MyEditorField from "@common-utils/fields/MyEditorField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import { toast } from "react-toastify"
import { IAddMessageRepondeurRequestModel, ICandidatAddSuiviMessageRepondeurlFormModel } from "./core/_models"
import { addSuiviMessageRepondeur } from "./core/_requests"
import MyDateDayMonthYearHoursMinutesField from "@common-utils/fields/MyDateDayMonthYearHoursMinutesField"
import moment from "moment"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"

const CandidatAddSuiviMessageRepondeurForm = ({ candidat, curriculumGoogleDriveUrl, handleSubmitCallBack }: ICandidatAddSuiviMessageRepondeurlFormModel) => {
     const [selectedTabForLgDisplayOrLess, setSelectedTabForLgDisplayOrLess] = useState<"cv" | "form">("form")

     // Form Suivi
     const FormSuivi = () => {
          const schema = Yup.object().shape({
               notes: Yup.string().label(`"Notes"`),
               voulezVousProgrammerUneDateDeRelance: Yup.boolean(),
               dateRelance: Yup.string()
                    .when("voulezVousProgrammerUneDateDeRelance", {
                         is: true,
                         then: schema => schema.required(),
                    })
                    .label(`"Date de relance"`),
          })

          interface IFormValuesModel {
               notes?: string
               voulezVousProgrammerUneDateDeRelance: boolean
               dateRelance?: string
          }

          const initialValues: IFormValuesModel = { voulezVousProgrammerUneDateDeRelance: true, dateRelance: moment().add(7, "days").toString() }

          function handleSubmit(values: IFormValuesModel, helpers: FormikHelpers<IFormValuesModel>) {
               helpers.setStatus(null)
               const requestModel: IAddMessageRepondeurRequestModel = {
                    notes: values.notes,
                    dateRelance: values.dateRelance,
               }
               addSuiviMessageRepondeur(candidat.id, requestModel)
                    .then(() => {
                         toast.success("Le suivi a bien été ajouté.")
                         if (values.dateRelance) toast.info("Un rappel a été ajouté à votre calendrier Google.")
                         handleSubmitCallBack()
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                         }

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)
                         toast.error(error?.detail, { autoClose: false })

                         // Stop submit loader
                         helpers.setSubmitting(false)
                    })
          }

          return (
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {/* Field Notes */}
                                   <div className={"mb-4"}>
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Notes</label>
                                        <MyEditorField value={helpers.values.notes} height={250} onChange={val => helpers.setFieldValue("notes", val)} placeholder={"Notes"} />
                                   </div>

                                   {/* Field voulezVousProgrammerUneDateDeRelance */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous programmer une relance?</label>

                                        <div className="col-lg-8 d-flex">
                                             <div className="d-flex align-items-center">
                                                  <MyCheckBoxField
                                                       name={"voulezVousProgrammerUneDateDeRelance"}
                                                       value={helpers.values.voulezVousProgrammerUneDateDeRelance}
                                                       isInvalid={!!helpers.errors.voulezVousProgrammerUneDateDeRelance}
                                                       onChange={val => helpers.setFieldValue("voulezVousProgrammerUneDateDeRelance", val)}
                                                  />
                                             </div>
                                             {helpers.values.voulezVousProgrammerUneDateDeRelance && (
                                                  <div className="ms-2 flex-grow-1">
                                                       <MyDateDayMonthYearHoursMinutesField
                                                            name={"dateRelance"}
                                                            isInvalid={!!helpers.errors.dateRelance}
                                                            onChange={val => helpers.setFieldValue("dateRelance", val)}
                                                            placeholder={"Date de relance"}
                                                            value={helpers.values.dateRelance}
                                                       />
                                                       {helpers.errors.dateRelance && <div className={"text-danger"}>{helpers.errors.dateRelance}</div>}
                                                  </div>
                                             )}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? "Ajouter" : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          )
     }

     // View display Xl or +
     const ViewDisplayXlOrPlus = useMemo(
          () => () => {
               return (
                    <>
                         <Split sizes={[50, 50]} direction="horizontal">
                              <div
                                   className={"pe-3"}
                                   style={{
                                        maxHeight: "82vh",
                                        overflowY: "scroll",
                                   }}
                              >
                                   <FormSuivi />
                              </div>
                              <iframe
                                   allowFullScreen={true}
                                   src={curriculumGoogleDriveUrl}
                                   style={{
                                        height: "82vh",
                                        width: "100%",
                                   }}
                                   className={"ms-2"}
                              />
                         </Split>
                    </>
               )
          },
          []
     )

     // View display LG or -
     const ViewDisplayLgOrLess = () => {
          return (
               <>
                    {/* Buttons to switch between Form & CV (only on display <=LG) */}
                    <div className={"d-flex justify-content-end d-xl-none"}>
                         <button
                              className={`btn btn-sm ${selectedTabForLgDisplayOrLess === "form" ? "btn-dark" : "btn-outline btn-outline-muted"} me-5 border-`}
                              onClick={() => setSelectedTabForLgDisplayOrLess("form")}
                         >
                              Formulaire
                         </button>
                         <button
                              className={`btn btn-sm ${selectedTabForLgDisplayOrLess === "cv" ? "btn-dark" : "btn-outline btn-outline-muted"}`}
                              onClick={() => setSelectedTabForLgDisplayOrLess("cv")}
                         >
                              CV
                         </button>
                    </div>
                    <div className="separator mt-2 mb-4" />

                    {selectedTabForLgDisplayOrLess === "form" ? (
                         <FormSuivi />
                    ) : (
                         <iframe
                              allowFullScreen={true}
                              src={curriculumGoogleDriveUrl}
                              style={{
                                   height: "82vh",
                                   width: "100%",
                              }}
                              className={"ms-2"}
                         />
                    )}
               </>
          )
     }

     return (
          <>
               {window.innerWidth >= 1200 ? (
                    <>
                         <ViewDisplayXlOrPlus />
                    </>
               ) : (
                    <ViewDisplayLgOrLess />
               )}
          </>
     )
}

export default CandidatAddSuiviMessageRepondeurForm
