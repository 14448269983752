import axios from "axios"
import { IPersonnelArretMaladieModel } from "@common-models/*"
import { CONST_API_PERSONNEL_DECLARATION_MALADIE_ANNULER_ENDPOINT, CONST_API_PERSONNEL_DECLARATION_MALADIE_EDIT_ENDPOINT } from "../../../../../endpoints"
import { IEditPersonnelMaladieRequestModel } from "./_models"

export function editPersonnelMaladieRequest(id: number, payload: IEditPersonnelMaladieRequestModel) {
     const formData = new FormData()
     formData.append("data[du]", payload.du as string)
     formData.append("data[au]", payload.au as string)
     formData.append("data[isJustificatifReplaced]", payload.isJustificatifReplaced ? "1" : "0")
     if (payload.isJustificatifReplaced && payload.justificatif) formData.append("justificatif", payload.justificatif)

     return axios.post<IPersonnelArretMaladieModel>(CONST_API_PERSONNEL_DECLARATION_MALADIE_EDIT_ENDPOINT(id), formData)
}

export function annulerPersonnelMaladieRequest(id: number, commentaire: string) {
     return axios.post<IPersonnelArretMaladieModel>(CONST_API_PERSONNEL_DECLARATION_MALADIE_ANNULER_ENDPOINT(id), { commentaire })
}
