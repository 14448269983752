import { IPtfCreateRequestModel } from "./_models"
import {
     CONST_API_CLIENT_PTF_GENERATE_CURRENT_PERIODE_NEXT_VERSION_REF_ENDPOINT,
     CONST_API_CLIENT_PTF_GET_GENERATE_NEXT_REF_ENDPOINT,
     CONST_API_CLIENT_PTF_POST_CREATE_ENDPOINT,
} from "../../../../../endpoints"
import axios from "axios"
import { IClientPropalModel } from "@common-models/*"

export function ptfCreateRequest(payload: IPtfCreateRequestModel) {
     const formData = new FormData()
     if (payload.reference) formData.append("data[reference]", payload.reference.toString())
     if (payload.categorie) formData.append("data[categorie]", payload.categorie.toString())
     if (payload.reference) formData.append("data[reference]", payload.reference.toString())
     if (payload.type) formData.append("data[type]", payload.type.toString())
     if (payload.ptfMere) formData.append("data[ptfMere]", payload.ptfMere.toString())
     if (payload.objet) formData.append("data[objet]", payload.objet.toString())
     if (payload.tjm) formData.append("data[tjm]", payload.tjm.toString())
     if (payload.entiteRattachement) formData.append("data[entiteRattachement]", payload.entiteRattachement.toString())
     if (payload.entiteFacturation) formData.append("data[entiteFacturation]", payload.entiteFacturation.toString())
     if (payload.etat) formData.append("data[etat]", payload.etat.toString())
     if (payload.dateDebut) formData.append("data[dateDebut]", payload.dateDebut.toString())
     if (payload.dateFin) formData.append("data[dateFin]", payload.dateFin.toString())
     if (payload.client) formData.append("data[client]", payload.client.toString())
     if (payload.clientAdresse) formData.append("data[clientAdresse]", payload.clientAdresse.toString())
     if (payload.clientResponsable) formData.append("data[clientResponsable]", payload.clientResponsable.toString())
     if (payload.bonCommandeType) formData.append("data[bonCommandeType]", payload.bonCommandeType.toString())
     if (payload.bonCommandeDateDebut) formData.append("data[bonCommandeDateDebut]", payload.bonCommandeDateDebut.toString())
     if (payload.bonCommandeDateFin) formData.append("data[bonCommandeDateFin]", payload.bonCommandeDateFin.toString())
     if (payload.bonCommandeDocument) formData.append("documents[bc]", payload.bonCommandeDocument)
     if (payload.documentGeneratedOrSaisieManuelle) formData.append("data[documentGeneratedOrSaisieManuelle]", payload.documentGeneratedOrSaisieManuelle.toString())
     if (payload.documentPtf) formData.append("documents[ptf]", payload.documentPtf)
     if (payload.documentCdc) formData.append("documents[cdc]", payload.documentCdc)

     return axios.post<IClientPropalModel>(CONST_API_CLIENT_PTF_POST_CREATE_ENDPOINT, formData)
}

export function ptfGenerateNextRefRequest() {
     return axios.get<{ ref: string }>(CONST_API_CLIENT_PTF_GET_GENERATE_NEXT_REF_ENDPOINT)
}

export function generateCurrentPeriodeNextVersionRefRequest(id: number) {
     return axios.get<{ ref: string }>(CONST_API_CLIENT_PTF_GENERATE_CURRENT_PERIODE_NEXT_VERSION_REF_ENDPOINT(id))
}
