import useSwal from "@common-hooks/useSwal"
import React from "react"
import { IFournisseurDocumentModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { CONST_CIVILITE_MME } from "@common-constants/*"
import { addFournisseurDocument } from "./_requests"
import { deleteFournisseurDocument, replaceFournisseurDocument } from "../document/core/_requests"
import { useAuth } from "../../../AppContext"

export const useFournisseurDocumentActions = (fournisseur_ID: number) => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()

     function add(e: React.ChangeEvent<HTMLInputElement>, type: string, callback: (val: IFournisseurDocumentModel) => void = () => {}) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info(`Envoi du document en cours ...`, { autoClose: false })

               addFournisseurDocument(fournisseur_ID, type, fileBinary)
                    .then(r => {
                         callback(r.data)
                         toast.dismiss()
                         toast.success("Le document a bien ajouté.")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function flagAsDeleted(id: number, callback: () => void = () => {}) {
          swal.fire({
               icon: "warning",
               text: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir supprimer le document?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return deleteFournisseurDocument(id)
                         .then(() => {
                              callback()
                              toast.success("Le document a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function replace(e: React.ChangeEvent<HTMLInputElement>, id: number, callback: (val: IFournisseurDocumentModel) => void = () => {}) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Remplacement du document en cours ...", { autoClose: false })

               replaceFournisseurDocument(id, fileBinary)
                    .then(r => {
                         callback(r.data)
                         toast.dismiss()
                         toast.success("Le document a bien été remplacé.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     return { add, flagAsDeleted, replace }
}
