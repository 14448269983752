import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IClientPropalModel, IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import * as Yup from "yup"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { IConsultantSuiviChangementMissionSousTraitantRequestModel } from "./core/_models"
import { consultantSuiviChangementMissionSousTraitantRequest } from "./core/_requests"
import { getClientPropalSimpleDetailsRequest } from "../../../client/ptf/core/_requests"
import { CONST_API_UTILS_GET_PTFS_NON_MERE_ENDPOINT } from "../../../../endpoints"

const ConsultantSuiviChangementMissionSousTraitant = ({ consultant, annulerCallback = () => {}, onSubmitCallback = () => {} }: IPropsModel) => {
     const [propalSimpleInfo, setPropalSimpleInfo] = useState<{ loading: boolean; data: IClientPropalModel | null }>({
          loading: false,
          data: null,
     })
     // Form schema
     const schema = Yup.object().shape({
          clientPropal: Yup.string().label(`"PTF"`).required(),
          dateDebut: Yup.string().required().label(`"Date de changement"`),
          dateFin: Yup.string().required().label(`"Date de fin"`),
     })

     // Form initial values
     const initialValues: IConsultantSuiviChangementMissionSousTraitantRequestModel = {}

     // Handle submit
     function handleSubmit(values: IConsultantSuiviChangementMissionSousTraitantRequestModel, helpers: FormikHelpers<IConsultantSuiviChangementMissionSousTraitantRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviChangementMissionSousTraitantRequest(consultant.id as number, values)
               .then(() => {
                    onSubmitCallback()
                    toast.success(`${consultant.prenomNom} a bien changé sa mission.`)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Handles change PTF
     function handleChangeClientPropal(ptf_id: number | undefined | null) {
          if (ptf_id) {
               setPropalSimpleInfo(prev => ({ ...prev, loading: true }))
               getClientPropalSimpleDetailsRequest(ptf_id)
                    .then(r => {
                         setPropalSimpleInfo({ loading: false, data: r.data })
                    })
                    .catch(() => {
                         setPropalSimpleInfo(prev => ({ ...prev, loading: false }))
                         toast.error("Un problème est survenu lors de la récupération des informations de la PTF sélectionnée.", { autoClose: false })
                    })
          }
     }

     return (
          <>
               <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                    {helpers => {
                         console.log(helpers.errors)
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de changement</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateDebut"}
                                                  value={helpers.values.dateDebut}
                                                  isInvalid={!!helpers.errors.dateDebut}
                                                  maxDate={helpers.values.dateFin}
                                                  onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                  placeholder={"Date de changement"}
                                                  classNames={"rounded-0 rounded-start"}
                                             />
                                             {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                        </div>
                                   </div>

                                   <div className="separator separator-dashed mb-6" />

                                   {/* PTF */}
                                   <div className="border border-dashed p-4 mb-4">
                                        <h4 className={"mb-4"}>N° d'affaire</h4>
                                        <div className="separator separator-dashed mb-6" />
                                        <MyAlert type={"primary"} classNames={"mb-4"}>
                                             Assurez-vous d'avoir bien créé et validé votre PTF afin qu'elle puisse appraître dans a liste.
                                        </MyAlert>
                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez la PTF</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"clientPropal"}
                                                       value={helpers.values.clientPropal}
                                                       isInvalid={!!helpers.errors.clientPropal}
                                                       onChange={val => {
                                                            helpers.setFieldValue("clientPropal", val).then(() => {
                                                                 handleChangeClientPropal(val as number)
                                                            })
                                                       }}
                                                       url={CONST_API_UTILS_GET_PTFS_NON_MERE_ENDPOINT}
                                                       placeholder={"Choisissez la PTF"}
                                                       isClearable={false}
                                                  />
                                                  {helpers.errors.clientPropal && <div className={"text-danger"}>{helpers.errors.clientPropal}</div>}
                                             </div>
                                        </div>

                                        {propalSimpleInfo.loading && (
                                             <div className={"text-center p-5"}>
                                                  <MySimpleSpinner size={"md"} />{" "}
                                             </div>
                                        )}

                                        {/* Détails PTF (loaded) */}
                                        {helpers.values.clientPropal && propalSimpleInfo.data && !propalSimpleInfo.loading && (
                                             <div className={"p-4 border border-dashed text-muted mb-4"}>
                                                  <h4 className={"mb-4 text-muted"}>
                                                       Détails de la {propalSimpleInfo.data.reference}
                                                       <i className="fas fa-info-circle fs-3 float-end" />
                                                  </h4>
                                                  <div className="separator separator-dashed mb-6" />

                                                  <p>Client: {propalSimpleInfo.data.client?.nom}</p>
                                                  <p>Responsable client: {propalSimpleInfo.data.clientResponsable?.prenomNom}</p>
                                                  {propalSimpleInfo.data.type === "TYPE_ASSISTANCE_TECHNIQUE" && <p>TJM: {propalSimpleInfo.data.tjm}€</p>}
                                                  <p>Type: {propalSimpleInfo.data.typeLabel}</p>
                                             </div>
                                        )}
                                   </div>

                                   {/* MISSION */}
                                   <div className={"p-4 border border-dashed mb-4"}>
                                        <h4 className={"mb-4"}>MISSION</h4>
                                        <div className="separator separator-dashed mb-6" />

                                        {/* Période mission */}
                                        <div className="row mb-2">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                             <div className="col-lg-8">
                                                  <div className="d-flex">
                                                       <MyDateDayMonthYearField
                                                            name={"dateDebut"}
                                                            value={helpers.values.dateDebut}
                                                            isInvalid={!!helpers.errors.dateDebut}
                                                            maxDate={helpers.values.dateFin}
                                                            onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                            placeholder={"Date de début"}
                                                            classNames={"rounded-0 rounded-start"}
                                                            isDisabled={true}
                                                       />
                                                       <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                       <MyDateDayMonthYearField
                                                            name={"dateFin"}
                                                            value={helpers.values.dateFin}
                                                            minDate={helpers.values.dateDebut}
                                                            isInvalid={!!helpers.errors.dateFin}
                                                            onChange={val => helpers.setFieldValue("dateFin", val)}
                                                            placeholder={"Date de fin"}
                                                            classNames={"rounded-0 rounded-end"}
                                                       />
                                                  </div>
                                                  {(helpers.errors.dateDebut || helpers.errors.dateFin) && <div className={"text-danger"}>Période requise ou incorrecte</div>}
                                             </div>
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button
                                             className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                             type={"button"}
                                             onClick={annulerCallback}
                                             disabled={helpers.isSubmitting}
                                        >
                                             Annuler
                                        </button>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Valider</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPropsModel {
     consultant: IConsultantModel
     annulerCallback?: () => void
     onSubmitCallback?: () => void
}

export default ConsultantSuiviChangementMissionSousTraitant
