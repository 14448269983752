import axios from "axios"
import { CONST_API_RECRUITMENT_CANDIDAT_SUIVI_GET_DETAILS_ENDPOINT, CONST_API_RECRUITMENT_CANDIDAT_SUIVI_PUT_EDIT_ENDPOINT } from "../../../../../endpoints"
import { ICandidatSuiviModel } from "@common-models/*"
import { ICandidatSuiviEditRequestModel } from "./_models"
import { CONST_DISPONIBILITE_DATE_EXACTE, CONST_MOBILITE_DEPARTEMENTAL, CONST_MOBILITE_PAR_VILLE, CONST_MOBILITE_REGIONAL } from "@common-constants/*"

export function getCandidatSuivi(id: number) {
     return axios.get<ICandidatSuiviModel>(CONST_API_RECRUITMENT_CANDIDAT_SUIVI_GET_DETAILS_ENDPOINT(id))
}

export function setCandidatSuivi(id: number, suivi: ICandidatSuiviEditRequestModel) {
     const formData = new FormData()
     formData.append("suivi[notes]", suivi.notes || "")
     if (suivi.disponibilite) formData.append("suivi[disponibilite]", suivi.disponibilite)
     if (suivi.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && suivi.disponibiliteDate) formData.append("suivi[disponibiliteDate]", suivi.disponibiliteDate)
     if (suivi.mobilite) formData.append("suivi[mobilite]", suivi.mobilite)
     if (suivi.mobilite === CONST_MOBILITE_REGIONAL && suivi.mobiliteRegions) {
          suivi.mobiliteRegions.map(item => formData.append("suivi[mobiliteRegions][]", item.toString()))
     }
     if (suivi.mobilite === CONST_MOBILITE_DEPARTEMENTAL && suivi.mobiliteDepartements) {
          suivi.mobiliteDepartements.map(item => formData.append("suivi[mobiliteDepartements][]", item.toString()))
     }
     if (suivi.mobilite === CONST_MOBILITE_PAR_VILLE && suivi.mobiliteVilles) {
          suivi.mobiliteVilles.map(item => formData.append("suivi[mobiliteVilles][]", item.toString()))
     }
     if (suivi.salaireNetMensuelSouhaite) formData.append("suivi[salaireNetMensuelSouhaite]", suivi.salaireNetMensuelSouhaite.toString())
     if (suivi.client) formData.append("suivi[client]", suivi.client.toString())
     if (suivi.document) formData.append("document", suivi.document)

     return axios.post<ICandidatSuiviModel>(CONST_API_RECRUITMENT_CANDIDAT_SUIVI_PUT_EDIT_ENDPOINT(id), formData)
}
