// ABSOLUTE PATHS (used in project)
export const ROUTING_ABSOLUTE_PATH_DASHBOARD = "/dashboard"

export const ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES = `${ROUTING_ABSOLUTE_PATH_DASHBOARD}/alertes`
export const ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_PE_EXPIRATION = `${ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES}/pe-expiration`
export const ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_DDC_PRODUCTION = `${ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES}/ddc-production`
export const ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_DDC_STRUCTURE = `${ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES}/ddc-structure`
export const ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_MISSION_EXPIRATION = `${ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES}/mission-expiration`

// RELATIVE PATHS (used in Routing.tsx only)
export const ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES = "alertes"
export const ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_PE_EXPIRATION = "pe-expiration"
export const ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_DDC_PRODUCTION = "ddc-production"
export const ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_DDC_STRUCTURE = "ddc-structure"
export const ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_MISSION_EXPIRATION = "mission-expiration"
