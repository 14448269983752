import axios from "axios"
import {
     CONST_API_DASHBOARD_POST_ALERTES_COUNTER_ENDPOINT,
     CONST_API_DASHBOARD_POST_ALERTES_MISSION_EXPIRATION_ENDPOINT,
     CONST_API_DASHBOARD_POST_ALERTES_PERIODES_ESSAI_EXPIRATION_ENDPOINT,
} from "../../../endpoints"
import { IGetAlertesCounterDashboardRequestModel, IGetAlertesCounterDashboardResponseModel } from "./_models"
import { IGetAlertesMissionsExpirationDashboardRequestModel, IGetAlertesMissionsExpirationDashboardResponseModel } from "../alertes/missionExpiration/core/_models"
import { IGetAlertesPeriodesEssaiExpirationDashboardRequestModel, IGetAlertesPeriodesEssaiExpirationDashboardResponseModel } from "../alertes/peridoeEssaiExpiration/core/_models"

export function getAlertesCounterDashboardRequest(payload: IGetAlertesCounterDashboardRequestModel) {
     return axios.post<IGetAlertesCounterDashboardResponseModel>(CONST_API_DASHBOARD_POST_ALERTES_COUNTER_ENDPOINT, payload)
}

export function getAlertesMissionExpirationDashboardRequest(payload: IGetAlertesMissionsExpirationDashboardRequestModel) {
     return axios.post<IGetAlertesMissionsExpirationDashboardResponseModel>(CONST_API_DASHBOARD_POST_ALERTES_MISSION_EXPIRATION_ENDPOINT, payload)
}

export function getAlertesPeriodesEssaiExpirationDashboardRequest(payload: IGetAlertesPeriodesEssaiExpirationDashboardRequestModel) {
     return axios.post<IGetAlertesPeriodesEssaiExpirationDashboardResponseModel>(CONST_API_DASHBOARD_POST_ALERTES_PERIODES_ESSAI_EXPIRATION_ENDPOINT, payload)
}
