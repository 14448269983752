import { IHttpErrorResponseModel, IPersonnelDeclarationMensuelleModel, IPersonnelDeclarationMensuelleSuiviModel } from "@common-models/*"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React from "react"
import { getPersonnelDeclarationConsultantSuiviRequest } from "./core/_requests"
import {
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "@common-constants/*"

const REACT_QUERY_KEY_GET_DE_SUIVI = "REACT_QUERY_KEY_GET_DE_SUIVI"
const PersonnelDeclarationSuivi = ({ declaration }: IPersonnelDeclarationSuiviPropsModel) => {
     // Query
     const suiviQuery = useQuery<IPersonnelDeclarationMensuelleSuiviModel[], AxiosError>(REACT_QUERY_KEY_GET_DE_SUIVI, () => {
          return getPersonnelDeclarationConsultantSuiviRequest(declaration.id as number)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function faIconAccordingToTypeSuivi(type: any) {
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION) return "fa-check-circle"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL) return "fa-paper-plane"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE) return "fa-signature"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE) return "fa-pencil"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION) return "fa-thumbs-down"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL) return "fa-thumbs-down"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION) return "fa-thumbs-up"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL) return "fa-thumbs-up"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION) return "fa-thumbs-down"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION) return "fa-thumbs-up"
          if (type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION) return "fa-paper-plane"
          return ""
     }

     return (
          <>
               {/* Is fetching */}
               {suiviQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {/* Is error */}
               {!suiviQuery.isFetching && suiviQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <div className={"d-flex align-items-center"}>
                              <span className={"me-2"}>{suiviQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => suiviQuery.refetch()}>
                                   Recharger
                              </button>
                         </div>
                    </MyAlert>
               )}

               {/* Without data */}
               {!suiviQuery.isFetching && !suiviQuery.isError && suiviQuery.data!.length === 0 && <MyAlert type={"primary"}>Aucun suivi à afficher</MyAlert>}

               {/* With data */}
               {!suiviQuery.isFetching && !suiviQuery.isError && suiviQuery.data!.length > 0 && (
                    <>
                         <div className="timeline">
                              {suiviQuery.data!.map((suivi, key) => (
                                   <div className="timeline-item" key={key}>
                                        <div className="timeline-line w-40px"></div>
                                        <div className="timeline-icon symbol symbol-circle symbol-40px">
                                             <div className={`symbol-label text-dark`}>
                                                  <span className={`fas ${faIconAccordingToTypeSuivi(suivi.type)} fs-4`}></span>
                                             </div>
                                        </div>
                                        <div className="timeline-content mb-10 mt-n1">
                                             <div className="pe-3 mb-5">
                                                  <div className="fs-5 fw-bold mb-2">{suivi.typeLabel}</div>
                                                  <div className="d-flex align-items-center mt-1 fs-6">
                                                       <div className="text-dark me-2 fs-7">
                                                            Le {suivi.createdAt?.format("dddd DD MMMM YYYY à H[h]m")}{" "}
                                                            {suivi.personnel ? (
                                                                 <b className={"text-center"}>par {suivi.personnel.prenomNom}</b>
                                                            ) : (
                                                                 <b className={"text-dark"}>par {declaration.personnel?.prenomNom}</b>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>

                                             {suivi.commentaire && (
                                                  <div className="overflow-auto pb-5">
                                                       <p className={"m-0 mb-2 fw-bold"}>Commentaire</p>
                                                       <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-7">
                                                            <div>{suivi.commentaire}</div>
                                                       </div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              ))}
                         </div>
                    </>
               )}
          </>
     )
}

interface IPersonnelDeclarationSuiviPropsModel {
     declaration: IPersonnelDeclarationMensuelleModel
}

export default PersonnelDeclarationSuivi
