import { IClientBonLivraisonModel, IHttpErrorResponseModel } from "@common-models/*"
import React, { createContext, useContext, useEffect } from "react"
import { useQuery } from "react-query"
import { IGetPeriodesBonsCommandeBonsLivraisonRequestModel, IGetPeriodesBonsCommandeBonsLivraisonResponseModel } from "../core/_models"
import { AxiosError } from "axios"
import { getPeriodesBonsCommandeBonsLivraisonRequest } from "../core/_requests"
import { toast } from "react-toastify"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import PtfDetailsPeriodesBonsCommandeBonsLivraison_Periodes from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison_Periodes"

export const REACT_QUERY_KEY_GET_PERIODES_BC_BL = "REACT_QUERY_KEY_GET_PERIODES_BC_BL"
const PtfDetailsPeriodesBonsCommandeBonsLivraisonContext = createContext<IPtfDetailsPeriodesBonsCommandeBonsLivraisonContextModel>(
     {} as IPtfDetailsPeriodesBonsCommandeBonsLivraisonContextModel
)
export const usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext = () => useContext(PtfDetailsPeriodesBonsCommandeBonsLivraisonContext)

const PtfDetailsPeriodesBonsCommandeBonsLivraison = ({
     pFilters = {},
     handleSubmitAddBonLivraisonCallback = () => {},
     handleOnLoadCallback = () => {},
}: IPtfDetailsPeriodesBonsCommandeBonsLivraisonPropsModel) => {
     const periodesBCsBLsQuery = useQuery<IGetPeriodesBonsCommandeBonsLivraisonResponseModel, AxiosError>(
          REACT_QUERY_KEY_GET_PERIODES_BC_BL,
          async () => {
               return getPeriodesBonsCommandeBonsLivraisonRequest(pFilters)
                    .then(r => {
                         handleOnLoadCallback()
                         return r.data
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         toast.error(error?.detail, { autoClose: false })

                         throw e
                    })
          },
          { enabled: false }
     )

     useEffect(() => {
          periodesBCsBLsQuery.refetch().then()
     }, [pFilters])

     // On fetching
     if (periodesBCsBLsQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />{" "}
               </div>
          )
     }

     // Error
     if (!periodesBCsBLsQuery.isFetching && periodesBCsBLsQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{periodesBCsBLsQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => periodesBCsBLsQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     if (!periodesBCsBLsQuery.isFetching && !periodesBCsBLsQuery.isError && periodesBCsBLsQuery.data) {
          return (
               <PtfDetailsPeriodesBonsCommandeBonsLivraisonContext.Provider
                    value={{
                         REACT_QUERY_KEY_GET_PERIODES_BC_BL,
                         handleSubmitAddBonLivraisonCallback,
                    }}
               >
                    <>
                         {periodesBCsBLsQuery.data.data.map((ptf, index) => (
                              <PtfDetailsPeriodesBonsCommandeBonsLivraison_Periodes ptf={ptf} key={index} />
                         ))}
                    </>
               </PtfDetailsPeriodesBonsCommandeBonsLivraisonContext.Provider>
          )
     }

     return <></>
}

interface IPtfDetailsPeriodesBonsCommandeBonsLivraisonPropsModel {
     pFilters?: IGetPeriodesBonsCommandeBonsLivraisonRequestModel
     handleSubmitAddBonLivraisonCallback?: (val: IClientBonLivraisonModel, livraisonTotale?: boolean) => void
     handleOnLoadCallback?: () => void
}

interface IPtfDetailsPeriodesBonsCommandeBonsLivraisonContextModel {
     REACT_QUERY_KEY_GET_PERIODES_BC_BL: string
     handleSubmitAddBonLivraisonCallback: (val: IClientBonLivraisonModel, livraisonTotale?: boolean) => void
}

export default PtfDetailsPeriodesBonsCommandeBonsLivraison
