import { AxiosError } from "axios"
import { IClientPropalModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { setDetailsPropalRequest } from "../core/_requests"
import * as Yup from "yup"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { IGetDetailsPropalRequestModel, ISetDetailsPropalRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import {
     CONST_CLIENT_PROPAL_CATEGORIE_FILLE,
     CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE,
     CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import {
     CONST_API_UTILS_GET_CLIENT_ADRESSES_ENDPOINT,
     CONST_API_UTILS_GET_CLIENT_RESPONSABLES_ENDPOINT,
     CONST_API_UTILS_GET_CLIENTS_ENDPOINT,
     CONST_API_UTILS_GET_ENTITES_ENDPOINT,
} from "../../../../endpoints"
import React, { useState } from "react"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import MyModal from "@common-utils/MyModal"
import AddClientForm from "../../../utils/_AddClientForm"
import AddResponsableClientForm from "../../../utils/_AddResponsableClientForm"
import AddAdresseClientForm from "../../../utils/_AddAdresseClientForm"

interface IPtfDetailsFormSetDetailsPropsModel {
     ptf_details: IGetDetailsPropalRequestModel
     onSubmitCallback?: (val: IClientPropalModel) => void
}

const PtfDetailsFormSetDetails = ({ ptf_details, onSubmitCallback = () => {} }: IPtfDetailsFormSetDetailsPropsModel) => {
     const [isAddingClient, setIsAddingClient] = useState<boolean>(false)
     const [isAddingRespClient, setIsAddingRespClient] = useState<boolean>(false)
     const [isAddingAdresseClient, setIsAddingAdresseClient] = useState<boolean>(false)

     function handleSubmit(values: ISetDetailsPropalRequestModel, helpers: FormikHelpers<ISetDetailsPropalRequestModel>) {
          helpers.setStatus(null)

          // Log search filters
          setDetailsPropalRequest(ptf_details.ptf.id as number, values)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    onSubmitCallback(r.data)
                    helpers.setSubmitting(false)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     const ptf = ptf_details.ptf
     const isPtfAttachedToConsultant = ptf_details.consultantsAttachesTodayToPtf.length > 0

     // Form validation schema
     const schema = Yup.object().shape({
          titre: Yup.string().label(`"Titre"`).required(),
          ...(!isPtfAttachedToConsultant && {
               ...([CONST_CLIENT_PROPAL_CATEGORIE_FILLE, CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE].includes(ptf.categorie as string) && {
                    tjm: Yup.string().label(`"TJM"`).required(),
               }),
               ...(ptf.categorie !== "CATEGORIE_FILLE" && {
                    entiteRattachement: Yup.number().label(`"Entité de rattachement"`).required(),
                    entiteFacturation: Yup.number().label(`"Entité de facturation"`).required(),
                    client: Yup.number().label(`"Client"`).required(),
                    clientAdresse: Yup.number().label(`"Adresse client"`).required(),
                    clientResponsable: Yup.number().label(`"Responsable client"`).required(),
               }),
          }),
     })

     // Form initial values
     const initialValues: ISetDetailsPropalRequestModel = {
          titre: ptf.titre,
          tjm: ptf.tjm,
          entiteRattachement: ptf.entite?.id,
          entiteFacturation: ptf.entiteFacturation?.id,
          client: ptf.client?.id,
          clientAdresse: ptf.clientAdresse?.id,
          clientResponsable: ptf.clientResponsable?.id,
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Titre */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre de la proposition</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"titre"}
                                                  value={helpers.values.titre}
                                                  isInvalid={!!helpers.errors.titre}
                                                  onChange={val => helpers.setFieldValue("titre", val)}
                                                  placeholder={"Titre"}
                                             />
                                             {helpers.errors.titre && <div className={"text-danger"}>{helpers.errors.titre}</div>}
                                        </div>
                                   </div>

                                   {/* Réf. */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 text-muted">Responsable</label>

                                        <div className="col-lg-8">
                                             <input className={"form-control form-control-solid"} value={ptf.personnel!.prenomNom} readOnly />
                                        </div>
                                   </div>

                                   {/* Réf. */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 text-muted">Référence</label>

                                        <div className="col-lg-8">
                                             <input className={"form-control form-control-solid"} value={ptf.reference} readOnly />
                                        </div>
                                   </div>

                                   {/* Type */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 text-muted">Type</label>

                                        <div className="col-lg-8">
                                             <input className={"form-control form-control-solid"} value={ptf.typeLabel} readOnly />
                                        </div>
                                   </div>

                                   {/* Entité de rattachement */}
                                   <div className="row mb-4">
                                        <label
                                             className={`col-lg-4 col-form-label fw-bold fs-6 ${
                                                  !isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? "required" : "text-muted"
                                             }`}
                                        >
                                             Entité de rattachement
                                        </label>

                                        <div className="col-lg-8">
                                             <>
                                                  {!isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? (
                                                       <>
                                                            <MySelectGetAllDataFromServer
                                                                 name={"entiteRattachement"}
                                                                 url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                                 value={helpers.values.entiteRattachement}
                                                                 defaultOptions={ptf.entite && [{ value: ptf.entite.id as number, label: ptf.entite.nom as string }]}
                                                                 onChange={val => helpers.setFieldValue("entiteRattachement", val)}
                                                                 isInvalid={!!helpers.errors.entiteRattachement}
                                                            />
                                                            {helpers.errors.entiteRattachement && <div className={"text-danger"}>{helpers.errors.entiteRattachement}</div>}
                                                       </>
                                                  ) : (
                                                       <input className={"form-control form-control-solid"} value={ptf.entite?.nom} readOnly />
                                                  )}
                                             </>
                                        </div>
                                   </div>

                                   {/* Entité de facturation */}
                                   <div className="row mb-4">
                                        <label
                                             className={`col-lg-4 col-form-label fw-bold fs-6 ${
                                                  !isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? "required" : "text-muted"
                                             }`}
                                        >
                                             Entité de facturation
                                        </label>

                                        <div className="col-lg-8">
                                             {!isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? (
                                                  <>
                                                       <MySelectGetAllDataFromServer
                                                            name={"entiteFacturation"}
                                                            url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                            value={helpers.values.entiteFacturation}
                                                            defaultOptions={
                                                                 ptf.entiteFacturation && [
                                                                      { value: ptf.entiteFacturation.id as number, label: ptf.entiteFacturation.nom as string },
                                                                 ]
                                                            }
                                                            onChange={val => helpers.setFieldValue("entiteFacturation", val)}
                                                            isInvalid={!!helpers.errors.entiteFacturation}
                                                       />
                                                       {helpers.errors.entiteFacturation && <div className={"text-danger"}>{helpers.errors.entiteFacturation}</div>}
                                                  </>
                                             ) : (
                                                  <input className={"form-control form-control-solid"} value={ptf.entiteFacturation?.nom} readOnly />
                                             )}
                                        </div>
                                   </div>

                                   {/* TJM */}
                                   {[CONST_CLIENT_PROPAL_CATEGORIE_FILLE, CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE].includes(ptf.categorie as string) &&
                                        ptf.type === CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE && (
                                             <div className="row mb-4">
                                                  <label className={`col-lg-4 col-form-label fw-bold fs-6 ${!isPtfAttachedToConsultant ? "required" : "text-muted"}`}>
                                                       Taux de vente journalier
                                                  </label>

                                                  <div className="col-lg-8">
                                                       {!isPtfAttachedToConsultant ? (
                                                            <>
                                                                 <MyInputField
                                                                      name={"tjm"}
                                                                      inputMode={"numeric"}
                                                                      type={"number"}
                                                                      value={helpers.values.tjm}
                                                                      isInvalid={!!helpers.errors.tjm}
                                                                      onChange={val => helpers.setFieldValue("tjm", val)}
                                                                      placeholder={"Taux de vente journalier"}
                                                                 />
                                                            </>
                                                       ) : (
                                                            <input className={"form-control form-control-solid"} value={ptf.tjm} readOnly />
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                   {/* INFORMATION CLIENT*/}
                                   <div className={"p-4 border border-dashed mb-4"}>
                                        <h4 className={"mb-4"}>Client</h4>
                                        <div className="separator separator-dashed mb-6" />

                                        {/* Client */}
                                        <div className="row mb-4">
                                             <label
                                                  className={`col-lg-4 col-form-label fw-bold fs-6 ${
                                                       !isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? "required" : "text-muted"
                                                  }`}
                                             >
                                                  Client
                                             </label>

                                             <div className="col-lg-8">
                                                  {!isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? (
                                                       <>
                                                            <MySelectGetAllDataFromServer
                                                                 name={"client"}
                                                                 value={helpers.values.client}
                                                                 isInvalid={!!helpers.errors.client}
                                                                 defaultOptions={ptf.client && [{ value: ptf.client.id as number, label: ptf.client.nom as string }]}
                                                                 onChange={val => {
                                                                      helpers.setFieldValue("client", val).then(() => {
                                                                           helpers.setFieldValue("clientAdresse", undefined).then()
                                                                           helpers.setFieldValue("clientResponsable", undefined).then()
                                                                      })
                                                                 }}
                                                                 url={CONST_API_UTILS_GET_CLIENTS_ENDPOINT}
                                                            />
                                                            <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                 {helpers.errors.client && <div className={"text-danger"}>{helpers.errors.client}</div>}
                                                                 <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingClient(true)}>
                                                                      Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                 </div>
                                                            </div>
                                                       </>
                                                  ) : (
                                                       <input className={"form-control form-control-solid"} value={ptf.client?.nom} readOnly />
                                                  )}
                                             </div>
                                        </div>
                                        {/* Client adresse*/}
                                        <div className="row mb-4">
                                             <label
                                                  className={`col-lg-4 col-form-label fw-bold fs-6 ${
                                                       !isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? "required" : "text-muted"
                                                  }`}
                                             >
                                                  Adresse
                                             </label>

                                             <div className="col-lg-8">
                                                  {!isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? (
                                                       <>
                                                            <MySelectGetAllDataFromServer
                                                                 name={"clientAdresse"}
                                                                 value={helpers.values.clientAdresse}
                                                                 defaultOptions={
                                                                      ptf.clientAdresse && [
                                                                           { value: ptf.clientAdresse.id as number, label: ptf.clientAdresse.adresseComplete as string },
                                                                      ]
                                                                 }
                                                                 isInvalid={!!helpers.errors.clientAdresse}
                                                                 onChange={val => helpers.setFieldValue("clientAdresse", val)}
                                                                 url={CONST_API_UTILS_GET_CLIENT_ADRESSES_ENDPOINT(helpers.values.client)}
                                                            />
                                                            <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                 {helpers.errors.clientAdresse && <div className={"text-danger"}>{helpers.errors.clientAdresse}</div>}
                                                                 <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingAdresseClient(true)}>
                                                                      Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                 </div>
                                                            </div>
                                                       </>
                                                  ) : (
                                                       <input className={"form-control form-control-solid"} value={ptf.clientAdresse?.adresseComplete} readOnly />
                                                  )}
                                             </div>
                                        </div>

                                        {/* Client responsable*/}
                                        <div className="row mb-4">
                                             <label
                                                  className={`col-lg-4 col-form-label fw-bold fs-6 ${
                                                       !isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? "required" : "text-muted"
                                                  }`}
                                             >
                                                  Responsable
                                             </label>

                                             <div className="col-lg-8">
                                                  {!isPtfAttachedToConsultant && ptf.categorie !== "CATEGORIE_FILLE" ? (
                                                       <>
                                                            <MySelectGetAllDataFromServer
                                                                 name={"clientResponsable"}
                                                                 value={helpers.values.clientResponsable}
                                                                 isInvalid={!!helpers.errors.clientResponsable}
                                                                 defaultOptions={
                                                                      ptf.clientResponsable && [
                                                                           { value: ptf.clientResponsable.id as number, label: ptf.clientResponsable.prenomNom as string },
                                                                      ]
                                                                 }
                                                                 onChange={val => helpers.setFieldValue("clientResponsable", val)}
                                                                 url={CONST_API_UTILS_GET_CLIENT_RESPONSABLES_ENDPOINT(helpers.values.client)}
                                                            />
                                                            <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                 {helpers.errors.clientResponsable && <div className={"text-danger"}>{helpers.errors.clientResponsable}</div>}
                                                                 <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingRespClient(true)}>
                                                                      Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                 </div>
                                                            </div>
                                                       </>
                                                  ) : (
                                                       <input className={"form-control form-control-solid"} value={ptf.clientResponsable?.prenomNom} readOnly />
                                                  )}
                                             </div>
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>

               {/* Modal add client */}
               {isAddingClient && (
                    <MyModal title={<>Nouveau client</>} show={true} handleClose={() => setIsAddingClient(false)}>
                         <AddClientForm callback={() => setIsAddingClient(false)} />
                    </MyModal>
               )}

               {/* Modal add resp client */}
               {isAddingRespClient && (
                    <MyModal title={<>Nouveau responsable client</>} show={true} handleClose={() => setIsAddingRespClient(false)}>
                         <AddResponsableClientForm callback={() => setIsAddingRespClient(false)} />
                    </MyModal>
               )}

               {/* Modal add adresse client */}
               {isAddingAdresseClient && (
                    <MyModal title={<>Nouvelle adresse client</>} show={true} handleClose={() => setIsAddingAdresseClient(false)} size={"lg"}>
                         <AddAdresseClientForm callback={() => setIsAddingAdresseClient(false)} />
                    </MyModal>
               )}
          </>
     )
}

export default PtfDetailsFormSetDetails
