import {
     CONST_MIME_APPLICATION_DOC,
     CONST_MIME_APPLICATION_DOCX,
     CONST_MIME_APPLICATION_GOOGLE_DOC,
     CONST_MIME_APPLICATION_GOOGLE_PRESENTATION,
     CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET,
     CONST_MIME_APPLICATION_ODP,
     CONST_MIME_APPLICATION_ODS,
     CONST_MIME_APPLICATION_ODT,
     CONST_MIME_APPLICATION_PDF,
     CONST_MIME_APPLICATION_PPT,
     CONST_MIME_APPLICATION_PPTX,
     CONST_MIME_APPLICATION_XLS,
     CONST_MIME_APPLICATION_XLSX,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
} from "@common-constants/*"

const FileAndDriveHelper = {
     getThumbnailDriveUrl(driveFileId: string) {
          return `https://drive.google.com/thumbnail?id=${driveFileId}`
     },
     getUrlOfDriveFilePreview(driveFileId: string) {
          return `https://drive.google.com/file/d/${driveFileId}/preview`
     },
     getUrlOfDriveFileEditMode(driveFileId: string) {
          return `https://docs.google.com/document/d/${driveFileId}/edit`
     },
     getUrlExportToPdfFormat: (driveFileId: string) => {
          return `https://docs.google.com/document/d/${driveFileId}/export?format=pdf`
     },
     getExtensionAccordingToMimetype(mimetype: string): string {
          const extensions = {
               [CONST_MIME_APPLICATION_DOCX]: "docx",
               [CONST_MIME_APPLICATION_DOC]: "doc",
               [CONST_MIME_APPLICATION_ODT]: "odt",
               [CONST_MIME_APPLICATION_GOOGLE_DOC]: "docx",
               [CONST_MIME_APPLICATION_PPT]: "ppt",
               [CONST_MIME_APPLICATION_PPTX]: "pptx",
               [CONST_MIME_APPLICATION_ODP]: "odp",
               [CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET]: "pptx",
               [CONST_MIME_IMAGE_JPEG]: "jpeg",
               [CONST_MIME_IMAGE_JPG]: "jpg",
               [CONST_MIME_IMAGE_PNG]: "png",
               [CONST_MIME_APPLICATION_XLS]: "xls",
               [CONST_MIME_APPLICATION_XLSX]: "xlsx",
               [CONST_MIME_APPLICATION_GOOGLE_PRESENTATION]: "xlsx",
               [CONST_MIME_APPLICATION_ODS]: "ods",
               [CONST_MIME_APPLICATION_PDF]: "pdf",
               default: "",
          }

          return extensions[mimetype]
     },
     downloadFile: (blobPart: any, fileName: string) => {
          const url = window.URL.createObjectURL(new Blob([blobPart]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("target", "_blank")
          link.setAttribute("download", fileName)
          document.body.appendChild(link)
          link.click()
     },
     downloadFileAndOpenItInNewTab: (blobPart: any, fileName: string) => {
          const url = window.URL.createObjectURL(new Blob([blobPart]))
          window.open(url)
     },
     getFontAwesomeClassAndColorAccordingToFileMimeType: (mimeType: string) => {
          switch (mimeType) {
               case CONST_MIME_APPLICATION_PDF:
                    return "fas fa-file-pdf  text-danger"
               case CONST_MIME_APPLICATION_DOC:
               case CONST_MIME_APPLICATION_DOCX:
               case CONST_MIME_APPLICATION_GOOGLE_DOC:
               case CONST_MIME_APPLICATION_ODT:
                    return "fas fa-file-word  text-primary"
               case CONST_MIME_APPLICATION_XLS:
               case CONST_MIME_APPLICATION_XLSX:
               case CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET:
               case CONST_MIME_APPLICATION_ODS:
                    return "fas fa-file-excel text-success"
               case CONST_MIME_APPLICATION_PPT:
               case CONST_MIME_APPLICATION_PPTX:
               case CONST_MIME_APPLICATION_ODP:
               case CONST_MIME_APPLICATION_GOOGLE_PRESENTATION:
                    return "fas fa-file-powerpoint text-warning"
               case CONST_MIME_IMAGE_JPEG:
               case CONST_MIME_IMAGE_JPG:
               case CONST_MIME_IMAGE_PNG:
                    return "fas fa-file-image text-info"
               default:
                    return "fas fa-file"
          }
     },
     getHoverClassAccordingToFileMimeType: (mimeType: string) => {
          switch (mimeType) {
               case CONST_MIME_APPLICATION_PDF:
                    return "btn-active-danger"
               case CONST_MIME_APPLICATION_DOC:
               case CONST_MIME_APPLICATION_DOCX:
               case CONST_MIME_APPLICATION_GOOGLE_DOC:
               case CONST_MIME_APPLICATION_ODT:
                    return "btn-active-primary"
               case CONST_MIME_APPLICATION_XLS:
               case CONST_MIME_APPLICATION_XLSX:
               case CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET:
               case CONST_MIME_APPLICATION_ODS:
                    return "btn-active-success"
               case CONST_MIME_APPLICATION_PPT:
               case CONST_MIME_APPLICATION_PPTX:
               case CONST_MIME_APPLICATION_ODP:
               case CONST_MIME_APPLICATION_GOOGLE_PRESENTATION:
                    return "btn-active-warning"
               case CONST_MIME_IMAGE_JPEG:
               case CONST_MIME_IMAGE_JPG:
               case CONST_MIME_IMAGE_PNG:
                    return "btn-active-info"
               default:
                    return "btn-active-secondary"
          }
     },
}

export default FileAndDriveHelper
