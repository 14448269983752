import axios from "axios"
import {
     CONST_API_PERSONNEL_DECLARATION_DDC_LISTING_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_DDC_REJET_PAR_RESPONSABLE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_DDC_VALIDATION_PAR_RESPONSABLE_ENDPOINT,
} from "../../../../../../endpoints"
import { IPersonnelDdcModel } from "@common-models/*"
import { IGetPersonnelDdcListingRequestModel, IGetPersonnelDdcResponseModel } from "./_models"

export function getPersonnelDdcListingRequest(payload: IGetPersonnelDdcListingRequestModel) {
     return axios.post<IGetPersonnelDdcResponseModel>(CONST_API_PERSONNEL_DECLARATION_DDC_LISTING_ENDPOINT, payload)
}

export function validationPersonnelDdcRequest(id: number) {
     return axios.post<IPersonnelDdcModel>(CONST_API_PERSONNEL_DECLARATION_DDC_VALIDATION_PAR_RESPONSABLE_ENDPOINT(id), {})
}

export function rejetPersonnelDdcRequest(id: number, raison: string) {
     return axios.post<IPersonnelDdcModel>(CONST_API_PERSONNEL_DECLARATION_DDC_REJET_PAR_RESPONSABLE_ENDPOINT(id), {
          raison,
     })
}
