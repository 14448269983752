import { IPersonnelOrganigrammeResponse } from "./PersonnelsOrganigramme"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { useThemeMode } from "@common-metronic/partials"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { Link } from "react-router-dom"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_DETAILS } from "../../../gestion/routing/routing-endpoints"

const PersonnelTreeNodeLabel = ({ node }: { node: IPersonnelOrganigrammeResponse }) => {
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const { mode } = useThemeMode()
     const poste = (node.personnel.personnelPostes || [])[0].poste?.nom || "Non renseigné"
     return (
          <>
               {/* Avatar */}
               <div>
                    {isAvatarLoading && <MySimpleSpinner classNames={"bg-black w-50px h-50px mb-2"} />}
                    <img
                         src={
                              node.personnel?.avatarGoogleDriveId
                                   ? FileAndDriveHelper.getThumbnailDriveUrl(node.personnel?.avatarGoogleDriveId)
                                   : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                         }
                         onLoad={() => setIsAvatarLoading(false)}
                         style={{ borderRadius: "50%" }}
                         className={"w-50px h-50px mb-2"}
                         alt="Avatar"
                    />
               </div>
               <Link to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_DETAILS(node.personnel.id as number)} target={"_blank"} className={"text-primary text-hover-dark fs-5"}>
                    {node.personnel.prenomNom}
               </Link>
               <div className={"text-gray-700"}>{poste}</div>
          </>
     )
}

export default PersonnelTreeNodeLabel
