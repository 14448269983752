import MyCard from "@common-utils/MyCard"
import { useState } from "react"
import InfoPersoViewOnly from "./_InfoPersoViewOnly"
import InfoProViewOnly from "./_InfoProViewOnly"
import InfoPersoEdit from "./_InfoPersoEdit"
import InfoProEdit from "./_InfoProEdit"
import Team from "./team/_Team"
import { usePersonnelDetailsContext } from "../PersonnelDetails"
import MyTooltip from "@common-utils/MyTooltip"
import MyModal from "@common-utils/MyModal"
import AddPersonnelsToTeam from "./_AddPersonnelsToTeam"
import MyAlert from "@common-utils/MyAlert"

const InfoBase = () => {
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data
     const [isInfoPersoOnEdition, setIsInfoPersoOnEdition] = useState<boolean>(false)
     const [isInfoProOnEdition, setIsInfoProOnEdition] = useState<boolean>(false)
     const [isAddingPersonnelToTeam, setIsAddingPersonnelToTeam] = useState<boolean>(false)

     return (
          <>
               <MyCard classNames={"mb-5"}>
                    <MyCard.Header
                         rightSideContent={
                              <i
                                   className={`fad fa-${isInfoPersoOnEdition ? "eye" : "edit"} text-primary fs-1 cursor-pointer text-hover-dark`}
                                   onClick={() => setIsInfoPersoOnEdition(prev => !prev)}
                              />
                         }
                    >
                         <div className={"d-flex align-items-center"}>Informations personnelles</div>
                    </MyCard.Header>
                    <MyCard.Body>{isInfoPersoOnEdition ? <InfoPersoEdit setIsInfoPersoOnEdition={setIsInfoPersoOnEdition} /> : <InfoPersoViewOnly />}</MyCard.Body>
               </MyCard>

               <MyCard classNames={"mb-5"}>
                    <MyCard.Header
                         rightSideContent={
                              <i
                                   className={`fad fa-${isInfoProOnEdition ? "eye" : "edit"} text-primary fs-1 cursor-pointer text-hover-dark`}
                                   onClick={() => setIsInfoProOnEdition(prev => !prev)}
                              />
                         }
                    >
                         <div className={"d-flex align-items-center"}>Informations professionnelles</div>
                    </MyCard.Header>
                    <MyCard.Body>{isInfoProOnEdition ? <InfoProEdit setIsInfoProOnEdition={setIsInfoProOnEdition} /> : <InfoProViewOnly />}</MyCard.Body>
               </MyCard>

               <MyCard>
                    <MyCard.Header
                         rightSideContent={<i className={`fad fa-plus-circle text-primary fs-1 cursor-pointer text-hover-dark`} onClick={() => setIsAddingPersonnelToTeam(true)} />}
                    >
                         <div className={"d-flex align-items-center"}>
                              Teamwork recrutement{" "}
                              <MyTooltip title={"Permet une meilleur synchronisation entre les membres du groupe sur le module recrutement"}>
                                   <i className={"fas fa-question-circle ms-2"} />
                              </MyTooltip>
                         </div>
                    </MyCard.Header>
                    <MyCard.Body>{details?.team && details?.team.length > 0 ? <Team /> : <MyAlert type={"info"}>Aucun personnel dans l'équipe</MyAlert>}</MyCard.Body>
               </MyCard>

               {isAddingPersonnelToTeam && (
                    <MyModal title={<>Choisissez les personnels à ajouter</>} handleClose={() => setIsAddingPersonnelToTeam(false)} show={true}>
                         <AddPersonnelsToTeam closeModal={() => setIsAddingPersonnelToTeam(false)} />
                    </MyModal>
               )}
          </>
     )
}

export default InfoBase
