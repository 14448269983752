import React from "react"
import CreatableSelect from "react-select/creatable"
import useSelectReact from "@common-hooks/useSelectReact"

type Props = {
     name: string
     options: Array<{ label: string; value: string | boolean } | any>
     value: any
     onChange: (value: any) => void
     isInvalid: boolean
     isClearable?: boolean
     placeholder?: string
     classNames?: string
     isValidNewOption?: (val: string) => boolean
}

const MySelectCreatableField: React.FC<Props> = ({ options, name, value, onChange, isClearable = true, placeholder = "", classNames = "", isInvalid, isValidNewOption }) => {
     const { formatGroupLabel, styles } = useSelectReact()

     return (
          <CreatableSelect
               menuPortalTarget={document.body}
               className={classNames}
               options={value ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
               name={name}
               formatGroupLabel={formatGroupLabel}
               value={value && value !== "" ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
               onChange={values => {
                    onChange(values.map((item: any) => item.value))
               }}
               noOptionsMessage={() => placeholder}
               isValidNewOption={val => {
                    if (isValidNewOption) {
                         return isValidNewOption(val)
                    }

                    return true
               }}
               formatCreateLabel={inputValue => `Ajouter "${inputValue}"`}
               isMulti={true}
               isClearable={isClearable}
               placeholder={placeholder}
               styles={styles(isInvalid)}
          />
     )
}

export default MySelectCreatableField
