import React, { useState } from "react"
import { useQueryClient } from "react-query"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { IPersonnelArretMaladieModel } from "@common-models/*"
import { CONST_CIVILITE_MME } from "@common-constants/*"
import MyTooltip from "@common-utils/MyTooltip"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { usePersonnelMaladiesListingContext } from "./_PersonnelMaladieListingForOne"
import useSwal from "@common-hooks/useSwal"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"
import { useAuth } from "../../../../../../AppContext"
import { annulerPersonnelMaladieRequest } from "../../core/_requests"

const Item = ({ item }: ConnectedUserMaladiesListingItemPropsModel) => {
     const { currentUser } = useAuth()
     const context = usePersonnelMaladiesListingContext()
     const { swal } = useSwal()
     const queryClient = useQueryClient()

     const [showJustifInModal, setShowJustifInModal] = useState<boolean>(false)

     function handleAnnulerMaladie(item: IPersonnelArretMaladieModel) {
          swal.fire({
               icon: "warning",
               title: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir annuler votre arrêt couvrant du ${item.du?.format(
                    "DD/MM/YYYY"
               )} au ${item.au?.format("DD/MM/YYYY")}?`,
               text: "Un mail sera envoyé pour informer de cette annulation.",
               input: "textarea",
               inputPlaceholder: "Précisez la raison de l'annulation",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Précisez la raison de l'annulation") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return annulerPersonnelMaladieRequest(item.id as number, commentaire)
                         .then(r => {
                              queryClient.setQueryData<IPersonnelArretMaladieModel[] | undefined>(
                                   context.REACT_QUERY_KEY_GET_CONNECTED_USER_MALADIE_LISTING,
                                   (prev: IPersonnelArretMaladieModel[] | undefined) => {
                                        if (prev) {
                                             const index = prev.findIndex(item => item.id === item.id)
                                             if (index >= 0) {
                                                  return prev.filter((item, i) => i !== index)
                                             }
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Votre arrêt a bien été annulée.")
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <>
               <div className="d-flex align-items-center">
                    <MyTooltip title={"Consulter le justificatif"}>
                         <div className={"w-65px d-flex justify-content-center"}>
                              <i className={"fas fa-file-medical text-hover-primary cursor-pointer"} style={{ fontSize: "4rem" }} onClick={e => setShowJustifInModal(true)} />
                         </div>
                    </MyTooltip>
                    <div className={"border-start ps-3 ms-2"}>
                         <span className={"text-dark fw-bolder fs-6 d-block py-2"}>Arrêt maladie</span>
                         <span className="text-muted d-block fw-bold mb-4">
                              <MyTooltip
                                   title={
                                        <span>
                                             Du {GlobalHelper.firstLetterUppercase(item.du?.format("dddd D MMMM YYYY"))} au{" "}
                                             {GlobalHelper.firstLetterUppercase(item.au?.format("dddd D MMMM YYYY"))}
                                        </span>
                                   }
                              >
                                   <span className="badge badge-light-primary me-1 mb-1">
                                        {item.du?.format("DD/MM/YYYY")} - {item.au?.format("DD/MM/YYYY")}
                                   </span>
                              </MyTooltip>
                         </span>
                         {context.editionMode && (
                              <div className="d-flex text-dark fw-bold">
                                   <span className={"cursor-pointer text-hover-warning d-flex align-items-center me-4"} onClick={() => context.setMaladieEditionInModal(item)}>
                                        <i className={"fas fa-edit me-2 fs-4 text-warning"} /> Editer
                                   </span>
                                   <span className={"cursor-pointer text-hover-danger d-flex align-items-center"} onClick={() => handleAnnulerMaladie(item)}>
                                        <i className={"fas fa-times-circle me-2 fs-4 text-danger"} /> Annuler
                                   </span>
                              </div>
                         )}
                    </div>
               </div>

               {/* Modal view Bon commande */}
               {showJustifInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={item.justificatifGoogleDriveID as string}
                         googleDriveMimetype={item.justificatifGoogleDriveMimeType as string}
                         modalTitle={<>Justificatif</>}
                         handleCloseModal={() => setShowJustifInModal(false)}
                         modalSize={"xl"}
                    />
               )}
          </>
     )
}

interface ConnectedUserMaladiesListingItemPropsModel {
     item: IPersonnelArretMaladieModel
}

export default Item
