import { CONST_CLIENT_BON_COMMANDE_TYPE_BC_ORIGINAL, CONST_CLIENT_BON_COMMANDE_TYPE_MAIL_CONFIRMATION, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { IAddBonCommandeToPtfPeriodeRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { IClientBonCommandeModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { addBonCommandeToPtfPeriodeRequest } from "../core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MyFileField from "@common-utils/fields/MyFileField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectField from "@common-utils/fields/MySelectField"

interface IPtfPeriodeAddBonCommandeFormPropsModel {
     ptf_periode_ID: number
     handleSubmitCallback?: (periode: IClientBonCommandeModel) => void
}

const PtfPeriodeAddBonCommandeForm = ({ ptf_periode_ID, handleSubmitCallback = () => {} }: IPtfPeriodeAddBonCommandeFormPropsModel) => {
     // Form initial values
     const initialValues: IAddBonCommandeToPtfPeriodeRequestModel = {}

     // Formik handle submit
     function handleSubmit(values: IAddBonCommandeToPtfPeriodeRequestModel, helpers: FormikHelpers<IAddBonCommandeToPtfPeriodeRequestModel>) {
          helpers.setStatus(null)

          addBonCommandeToPtfPeriodeRequest(ptf_periode_ID, values)
               .then(r => {
                    toast.success("Le bon de commande a bien été ajouté.")
                    handleSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Type */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Type</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"type"}
                                                  options={[
                                                       {
                                                            value: CONST_CLIENT_BON_COMMANDE_TYPE_MAIL_CONFIRMATION,
                                                            label: "Mail de confirmation / accord client",
                                                       },
                                                       {
                                                            value: CONST_CLIENT_BON_COMMANDE_TYPE_BC_ORIGINAL,
                                                            label: "Bon de commande original",
                                                       },
                                                  ]}
                                                  value={helpers.values.type}
                                                  isInvalid={!!helpers.errors.type}
                                                  onChange={val => helpers.setFieldValue("type", val)}
                                             />
                                             {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}
                                        </div>
                                   </div>

                                   {/* Période */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                        <div className="col-lg-8">
                                             <div className="d-flex">
                                                  <MyDateDayMonthYearField
                                                       name={"dateDebut"}
                                                       value={helpers.values.dateDebut}
                                                       isInvalid={!!helpers.errors.dateDebut}
                                                       maxDate={helpers.values.dateFin}
                                                       onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                       placeholder={"Date de début"}
                                                       classNames={"rounded-0 rounded-start"}
                                                  />
                                                  <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                  <MyDateDayMonthYearField
                                                       name={"dateFin"}
                                                       value={helpers.values.dateFin}
                                                       minDate={helpers.values.dateDebut}
                                                       isInvalid={!!helpers.errors.dateFin}
                                                       onChange={val => helpers.setFieldValue("dateFin", val)}
                                                       placeholder={"Date de fin"}
                                                       classNames={"rounded-0 rounded-end"}
                                                  />
                                             </div>
                                             {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                             {helpers.errors.dateFin && <div className={"text-danger"}>{helpers.errors.dateFin}</div>}
                                        </div>
                                   </div>

                                   {/* Document BC */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">BC / Accord client</label>
                                        <div className="col-lg-8">
                                             <MyFileField name={"document"} isInvalid={!!helpers.errors.document} onChange={val => helpers.setFieldValue("document", val)} />
                                             {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Ajouter <i className={"fas fa-plus-circle fs-4 ms-1"} />
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

export default PtfPeriodeAddBonCommandeForm
