import axios from "axios"
import {
     CONST_API_BUSINESS_CONSULTANT_GET_DE_CONTRAT_TRAVAIL_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_FOR_NAVIGATION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_GENERATE_OR_UPLOAD_CONTRAT_TRAVAIL_ENDPOINT,
} from "../../../../../endpoints"
import { IGenerationOuUploadContratTravailParSiegeRequestModel, IGetDemandeEmbaucheInfoForNavigation } from "./_models"
import { IConsultantDocumentModel } from "@common-models/*"

export function getDemandeEmbaucheInfoForNavigation(consultant_id: number) {
     return axios.get<IGetDemandeEmbaucheInfoForNavigation>(CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_FOR_NAVIGATION_ENDPOINT(consultant_id))
}

export function getDemandeEmbaucheContratTravail(consultant_id: number) {
     return axios.get<IConsultantDocumentModel>(CONST_API_BUSINESS_CONSULTANT_GET_DE_CONTRAT_TRAVAIL_ENDPOINT(consultant_id))
}

export function generateOrUploadContratTravail(consultant_id: number, payload: IGenerationOuUploadContratTravailParSiegeRequestModel) {
     const formData = new FormData()
     formData.append("data[generationOuUpload]", payload.generationOuUpload)
     if (payload.generationOuUpload === "GENERATION_OU_UPLOAD__UPLOAD") formData.append("contrat", payload.contrat as File)
     if (payload.commentaire) formData.append("data[commentaire]", payload.commentaire)

     return axios.post<IConsultantDocumentModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_GENERATE_OR_UPLOAD_CONTRAT_TRAVAIL_ENDPOINT(consultant_id), formData)
}
