import React, { createContext, useContext } from "react"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { getConnectedUserNdfRequest } from "../core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import ConnectedPersonnelNdfListingItem from "./ConnectedPersonnelNdfListingItem"
import MyCard from "@common-utils/MyCard"
import { IPersonnelNdfModel } from "@common-models/*"
import { Link } from "react-router-dom"

const ConnectedUserNdfListingContext = createContext<IConnectedUserNdfListingContextPropsModel>({} as IConnectedUserNdfListingContextPropsModel)
export const useConnectedUserNdfListingContext = () => useContext(ConnectedUserNdfListingContext)

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
const ConnectedPersonnelNdfListing = () => {
     const getListingQuery = useQuery<IPersonnelNdfModel[], AxiosError>(REACT_QUERY_KEY_GET_LISTING, () => getConnectedUserNdfRequest({}).then(r => r.data))

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={"w-100 w-lg-75 w-xxl-65"}>
                         <ConnectedUserNdfListingContext.Provider
                              value={{
                                   REACT_QUERY_KEY_GET_LISTING,
                              }}
                         >
                              <MyCard>
                                   <MyCard.Header
                                        rightSideContent={
                                             <>
                                                  <button
                                                       type={"button"}
                                                       className={`btn btn-light-primary btn-sm me-2 d-flex align-items-center`}
                                                       onClick={() => getListingQuery.refetch()}
                                                  >
                                                       <span className={`d-md-inline d-none`}>Recharger la liste</span>
                                                       <i className={"fas fa-sync ms-md-2 pe-0 fs-3"} />
                                                  </button>
                                                  <Link to={"/personnel/mes-activites/ndf/create"} className={`btn btn-primary btn-sm d-flex align-items-center`}>
                                                       <span className={`d-md-inline d-none`}>Nouvelle NDF</span>
                                                       <i className={"fas fa-plus-circle ms-md-2 pe-0 fs-3"} />
                                                  </Link>
                                             </>
                                        }
                                   >
                                        Mes notes de frais
                                   </MyCard.Header>
                                   <MyCard.Body>
                                        {/* Is fetching */}
                                        {getListingQuery.isFetching && (
                                             <div className={"text-center"}>
                                                  <MySimpleSpinner size={"xl"} />
                                             </div>
                                        )}

                                        {/* Is error */}
                                        {!getListingQuery.isFetching && getListingQuery.isError && (
                                             <MyAlert type={"danger"}>
                                                  <>
                                                       <span className={"me-2"}>{getListingQuery.error?.response?.data?.detail}</span>
                                                       <button
                                                            className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                            onClick={() => getListingQuery.refetch()}
                                                       >
                                                            Recharger
                                                       </button>
                                                  </>
                                             </MyAlert>
                                        )}

                                        {/* Without data */}
                                        {!getListingQuery.isFetching && !getListingQuery.isError && getListingQuery.data!.length === 0 && (
                                             <MyAlert type={"primary"}>Aucune NDF à afficher</MyAlert>
                                        )}

                                        {/* With data */}
                                        {!getListingQuery.isFetching && !getListingQuery.isError && getListingQuery.data && getListingQuery.data!.length > 0 && (
                                             <>
                                                  <div className="row">
                                                       {getListingQuery.data.map((item, key) => (
                                                            <div className={`col-md-6 ${key < getListingQuery.data.length - 2 && "mb-6"}`} key={key}>
                                                                 <ConnectedPersonnelNdfListingItem item={item} />
                                                            </div>
                                                       ))}
                                                  </div>
                                             </>
                                        )}
                                   </MyCard.Body>
                              </MyCard>
                         </ConnectedUserNdfListingContext.Provider>
                    </div>
               </div>
          </>
     )
}

interface IConnectedUserNdfListingContextPropsModel {
     REACT_QUERY_KEY_GET_LISTING: string
}

export default ConnectedPersonnelNdfListing
