import MyCard from "@common-utils/MyCard"
import React from "react"
import MyAlert from "@common-utils/MyAlert"
import DashboardAlertes from "./alertes/_DashboardAlertes"

const Dashboard = () => {
     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-70"}>
                    <DashboardAlertes />
                    <MyCard>
                         <MyCard.Header classNames={"bg-info"}>
                              <div className={"text-white fw-bold d-flex align-items-center"}>
                                   <span className={"fas fa-pie-chart me-2 fs-2"} /> MES INDICATEURS
                              </div>
                         </MyCard.Header>
                         <MyCard.Body>
                              <MyAlert type={"info"}>En maintenance ...</MyAlert>
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default Dashboard
