import React from "react"
import MyCard from "@common-utils/MyCard"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { useListingContext } from "./ConsultantsMaladiesListingMultiple"
import { CONST_API_UTILS_GET_ENTITES_ENDPOINT } from "../../../../../endpoints"
import { IGetConsultantsMaladiesListingRequestModel } from "./core/_models"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"

// Filters
const Filters = () => {
     const context = useListingContext()

     const initialValues: IGetConsultantsMaladiesListingRequestModel = {
          ...context.filters,
     }

     function handleSubmit(values: IGetConsultantsMaladiesListingRequestModel) {
          context.setFilters(prev => ({
               ...prev,
               ...values,
          }))
          context.setAreFiltersVisible(false)
          context.setItemInPreview(null)
     }

     function handleReset() {
          context.filtersFormikRef.current.resetForm() // Reset local form
          context.simpleSearchBarFormikRef.current.resetForm() // Reset search bar form
          context.setItemInPreview(null)
          context.setAreFiltersVisible(false)

          // Reset payload filters
          context.setFilters({
               page: 1,
               pageLength: 20,
          })

          context.filtersFormikRef.current.setSubmitting(true)
     }

     function handleClickHideFilters() {
          // Hide filters
          context.setAreFiltersVisible(prev => !prev)
          // Show preview only if it was opened before showing filters
          context.setItemInPreview(prev => {
               if (prev) {
                    return { ...prev, isPreviewVisible: true }
               }
               return null
          })
     }

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <MyTooltip title={"Cacher les filters"}>
                              <button className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={handleClickHideFilters}>
                                   <i className={"fas fa-eye-slash fs-4 text-dark"} />
                              </button>
                         </MyTooltip>
                    }
               >
                    Filtres
               </MyCard.Header>

               <MyCard.Body>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={context.filtersFormikRef} enableReinitialize>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mot clé</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"keyword"}
                                                       value={helpers.values.keyword}
                                                       onChange={val => {
                                                            helpers.setFieldValue("keyword", val).then()
                                                            context.simpleSearchBarFormikRef.current.setFieldValue("keyword", val).then()
                                                       }}
                                                       isInvalid={!!helpers.errors.keyword}
                                                       placeholder={"Rechercher ..."}
                                                  />
                                                  {helpers.errors.keyword && <div className={"text-danger"}>{helpers.errors.keyword}</div>}
                                             </div>
                                        </div>

                                        <div className="separator separator-dotted mt-4 mb-8"></div>

                                        {/* Entités */}
                                        <div className="row mb-4">
                                             <label className={`col-lg-4 col-form-label fw-bold fs-6`}>Entités</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"entites"}
                                                       url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       value={helpers.values.entites}
                                                       onChange={val => helpers.setFieldValue("entites", val)}
                                                       isInvalid={!!helpers.errors.entites}
                                                       isMulti
                                                  />
                                                  {helpers.errors.entites && <div className={"text-danger"}>{helpers.errors.entites}</div>}
                                             </div>
                                        </div>

                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Période</label>

                                             <div className="col-lg-8">
                                                  <div className="d-flex">
                                                       <MyDateDayMonthYearField
                                                            name={"dateDebut"}
                                                            value={helpers.values.dateDebut}
                                                            placeholder={"Du"}
                                                            onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                            isInvalid={!!helpers.errors.dateDebut}
                                                            maxDate={helpers.values.dateFin}
                                                            classNames={"rounded-0 rounded-start"}
                                                       />
                                                       <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                       <MyDateDayMonthYearField
                                                            name={"dateFin"}
                                                            value={helpers.values.dateFin}
                                                            placeholder={"Au"}
                                                            onChange={val => helpers.setFieldValue("dateFin", val)}
                                                            isInvalid={!!helpers.errors.dateFin}
                                                            minDate={helpers.values.dateDebut}
                                                            classNames={"rounded-0 rounded-end"}
                                                       />
                                                  </div>
                                                  {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                                  {helpers.errors.dateFin && <div className={"text-danger"}>{helpers.errors.dateFin}</div>}
                                             </div>
                                        </div>

                                        <div className={"text-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  disabled={helpers.isSubmitting}
                                                  onClick={() => handleReset()}
                                             >
                                                  <span className={"d-none d-lg-block"}>Réinitialiser</span> <i className={"fas fa-sync ms-lg-1"} />
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  <span className={"d-none d-lg-block"}>Filtrer </span>
                                                  {!helpers.isSubmitting ? (
                                                       <i className={"fas fa-filter ms-md-1"} />
                                                  ) : (
                                                       <span className="spinner-border spinner-border-sm align-middle ms-lg-1" />
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyCard.Body>
          </MyCard>
     )
}

export default Filters
