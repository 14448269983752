import React from "react"
import InputMask from "react-input-mask"

import DatePicker, { registerLocale } from "react-datepicker"
import fr from "date-fns/locale/fr"
import moment from "moment"

registerLocale("fr", fr)

type Props = {
     name: string
     minDate?: string | undefined | null
     maxDate?: string | undefined | null
     value: string | undefined | null
     placeholder: string
     onChange: (value: any) => void
     autofocus?: boolean
     isInvalid?: boolean
     classNames?: string
}

const MyDateMonthYearField: React.FC<Props> = ({ name, value, onChange, placeholder, minDate, maxDate, autofocus = false, isInvalid = false, classNames = "" }) => {
     return (
          <DatePicker
               dateFormat="MM/yyyy"
               showMonthYearPicker
               locale="fr"
               placeholderText={placeholder}
               customInput={
                    <InputMask className={"form-control"} mask="99/9999">
                         {() => (
                              <input
                                   type="text"
                                   inputMode={"numeric"}
                                   className={`form-control ${isInvalid ? "is-invalid" : ""} ${classNames}`}
                                   placeholder={placeholder}
                                   autoFocus={autofocus}
                              />
                         )}
                    </InputMask>
               }
               name={name}
               minDate={minDate ? moment(minDate).toDate() : null}
               maxDate={maxDate ? moment(maxDate).toDate() : null}
               isClearable
               selected={value ? moment(value).toDate() : null}
               onChange={(val: any) => {
                    if (val) {
                         const d = moment(val).format("YYYY-MM")
                         onChange(d)
                    } else {
                         onChange("")
                    }
               }}
          />
     )
}

export default MyDateMonthYearField
