import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_NOM_MAIL_TEL,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE,
} from "@common-constants/*"
import { ICandidatModel, IHttpErrorResponseModel, IVivierModel, OffreModel } from "@common-models/*"
import { getCandidateSearchResults } from "./core/_requests"
import {
     IDefaultFiltersFromPageState,
     IListItemInPreview,
     ISearchResultsContextPropsModel,
     ISearchResultsDataItemResponseModel,
     ISearchResultsRequestModel,
     ISearchResultsResponseModel,
} from "./core/_models"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../../models"
import { useLocation } from "react-router-dom"
import CandidatsRechercheResultsFilters from "./_CandidatsRechercheResultsFilters"
import CandidatsRechercheResultsSimpleSearchBar from "./_CandidatsRechercheResultsSimpleSearchBar"
import CandidatsRechercheResultsList from "./_list/_CandidatsRechercheResultsList"
import CandidatsRechercheResultsPreview from "./_preview/_CandidatsRechercheResultsPreview"
import { getSelectedCandidatePlusPreviousAndNextCandidate } from "./core/helpers"
import { addCandidateToListeTraitement, checkCandidateAsSeen, removeCandidateFromListeTraitement } from "../../core/_requests"
import { getOneSavedFiltersOrOneLogSearchCandidate } from "../core/_requests"
import { ILogOrSavedFiltersSearchCandidateModel, ISearchCandidatesFiltersModel } from "../core/_models"
import { getCandidates, getOffers, getViviers } from "../../../../../utils/requests"
import { useAuth } from "../../../../../AppContext"
import { AddCandidateToVivierModalForm, ProgrammerEntretienMeetModalForm, ShareCandidateModalForm } from "./CandidatsRechercheResultsHelpers"
import { SuiviFormModal } from "./_preview/helpers/_CandidatsRechercheResultsPreviewHelpers"

// Wrapper's context
const SearchResultsContext = createContext<ISearchResultsContextPropsModel>({} as ISearchResultsContextPropsModel)
export const useSearchResultsContext = () => useContext(SearchResultsContext)

// Wrapper
const REACT_QUERY_KEY_SEARCH_RESULTS = "REACT_QUERY_KEY_SEARCH_RESULTS"
const INITIAL_FILTERS_EMPTY_STATE: ISearchResultsRequestModel = {
     page: 1,
     pageLength: 20,
     filters: {
          typeHistoriqueOuSauvegarde: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE,
          searchScope: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_NOM_MAIL_TEL,
     },
}
const CandidatsRechercheResults = () => {
     const queryClient = useQueryClient()
     const { setCurrentUser } = useAuth()

     // Page states & URL parameters
     const location = useLocation() as {
          state: IDefaultFiltersFromPageState
     }
     const urlParams = new URLSearchParams(window.location.search)

     // Referencing both filters form and simple search bar form
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)
     const previewContainerRef = useRef<any>(null)

     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     // Filters used for query
     const [filters, setFilters] = useState<ISearchResultsRequestModel>(INITIAL_FILTERS_EMPTY_STATE)
     const [initialFiltersForForm, setInitialFiltersForForm] = useState<ILogOrSavedFiltersSearchCandidateModel>()
     const [itemInPreview, setItemInPreview] = useState<IListItemInPreview | null>(null)
     const [addingRemovingCandidateToFromListeTraitement, setAddingRemovingCandidateToFromListeTraitement] = useState<ISearchResultsDataItemResponseModel | null>(null)
     const [sharingCandidate, setSharingCandidate] = useState<ISearchResultsDataItemResponseModel | null>(null)
     const [addingCandidateToViviers, setAddingCandidateToViviers] = useState<ISearchResultsDataItemResponseModel | null>(null)
     const [addingSuiviToCandidate, setAddingSuiviToCandidate] = useState<{ item: ISearchResultsDataItemResponseModel | null; suiviType: string } | null>(null)
     const [schedulingEntretienMeet, setSchedulingEntretienMeet] = useState<ISearchResultsDataItemResponseModel | null>(null)
     // This state prevents the useQuery from triggering twice during the page load with default parameters. The useEffect updates the filters state, triggering a refetch of the query.
     const [isQueryEnabled, setIsQueryEnabled] = useState(false)
     // This ensures that the useQuery is refetched when the filters state remains unchanged. Without modifying this state on submit/reset, the refetch won't occur.
     // This state will be used to prevent focus and preview first item on page load multiple times (see param / page state focusFirstItemAndPreviewIt)
     const [isFirstItemAlreadyFocusedAndPreviewedOnPageLoad, setIsFirstItemAlreadyFocusedAndPreviewedOnPageLoad] = useState<boolean>(false)
     // TODO bug: when you submit twice with the same filters, the refetch will not fire
     const useListingQuery = useQuery<ISearchResultsResponseModel, AxiosError>(
          [REACT_QUERY_KEY_SEARCH_RESULTS, filters],
          async () => {
               return getCandidateSearchResults(filters)
                    .then(r => {
                         // Stop loaders (filters + simple search bar)
                         filtersFormikRef.current.setSubmitting(false)
                         simpleSearchBarFormikRef.current.setSubmitting(false)
                         setAreFiltersVisible(false)

                         // If the preview feature is active, and you switch pages, it will load either the first item or the item from the new page.
                         if (itemInPreview) {
                              if (r.data!.page > itemInPreview.selectedItemPageNumber) {
                                   const firstItem = r.data!.data[0]
                                   setItemInPreview(getSelectedCandidatePlusPreviousAndNextCandidate(firstItem, 0, r.data!.data, r.data!.page, r.data!.totalPages))
                                   if (!firstItem.connectedUserHasSeenCandidat) checkItemAsSeen(firstItem.candidat.id as number)
                              }

                              if (itemInPreview.selectedItemPageNumber > r.data!.page) {
                                   const lastItem = r.data!.data[r.data!.data.length - 1]
                                   setItemInPreview(
                                        getSelectedCandidatePlusPreviousAndNextCandidate(lastItem, r.data!.data.length - 1, r.data!.data, r.data!.page, r.data!.totalPages)
                                   )
                                   if (!lastItem.connectedUserHasSeenCandidat) checkItemAsSeen(lastItem.candidat.id as number)
                              }
                         }

                         // On page load focus first item (this will be called once)
                         if (
                              (urlParams.get("focusFirstItemAndPreviewIt") !== null || location.state?.focusFirstItemAndPreviewIt !== undefined) &&
                              !isFirstItemAlreadyFocusedAndPreviewedOnPageLoad
                         ) {
                              setIsFirstItemAlreadyFocusedAndPreviewedOnPageLoad(true)
                              const firstItem = r.data!.data[0]
                              setItemInPreview(getSelectedCandidatePlusPreviousAndNextCandidate(firstItem, 0, r.data!.data, r.data!.page, r.data!.totalPages))
                              if (!firstItem.connectedUserHasSeenCandidat) checkItemAsSeen(firstItem.candidat.id as number)
                         }
                         return r.data
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors in the filters form
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                         }

                         // Stop loaders (filters + simple search bar)
                         filtersFormikRef.current.setSubmitting(false)
                         simpleSearchBarFormikRef.current.setSubmitting(false)

                         // Toast error
                         toast.error(error?.detail, { autoClose: false })

                         throw e
                    })
          },
          { enabled: isQueryEnabled }
     )

     // Default filters: this will be executed once
     useEffect(() => {
          // log_or_saved_filters_id
          if (urlParams.get("log_or_saved_filters_id") || location.state?.log_or_saved_filters_id) {
               let id
               if (urlParams.get("log_or_saved_filters_id")) id = parseInt(urlParams.get("log_or_saved_filters_id") as string)
               if (location.state?.log_or_saved_filters_id) id = location.state?.log_or_saved_filters_id
               getOneSavedFiltersOrOneLogSearchCandidate(id).then(r => {
                    setInitialFiltersForForm(r.data)
                    const tmp: ISearchCandidatesFiltersModel = {
                         searchKeywords: r.data.searchKeywords,
                         searchScope: r.data.searchScope,
                         searchOperateurLogiqueOnlyScopeCurriculum: r.data.searchOperateurLogiqueOnlyScopeCurriculum,
                         disponibilite: r.data.disponibilite,
                         disponibiliteDate: r.data.disponibiliteDate?.format("YYYY-MM-DD"),
                         mobilite: r.data.mobilite,
                         mobiliteRegions: r.data.mobiliteRegions?.map(item => item.id as number),
                         mobiliteDepartements: r.data.mobiliteDepartements?.map(item => item.id as number),
                         mobiliteVilles: r.data.mobiliteVilles?.map(item => item.id as number),
                         niveauEtudes: r.data.niveauEtudes,
                         fraicheurCV: r.data.fraicheurCV,
                         viviers: r.data.viviers?.map(item => item.id as number),
                         annonces: r.data.annonces?.map(item => item.id as number),
                         candidats: r.data.candidats?.map(item => item.id as number),
                         areCandidatesInListeDeTraitement: r.data.areCandidatesInListeDeTraitement,
                         candidatType: r.data.candidatType,
                         personnelsAyantSaisiLeCandidat: r.data.personnelsAyantSaisiLeCandidat?.map(item => item.id as number),
                         secteursActivite: r.data.secteursActivite?.map(item => item.id as number),
                         metiers: r.data.metiers?.map(item => item.id as number),
                         seenOrNot: r.data.seenOrNot,
                         experienceMin: r.data.experienceMin,
                         // salaireNetMensuelSouhaiteMin: r.data.salaireNetMensuelSouhaiteMin,
                         // salaireNetMensuelSouhaiteMax: r.data.salaireNetMensuelSouhaiteMax,
                         avecSuivi: r.data.avecSuivi,
                         typeHistoriqueOuSauvegarde: r.data.typeHistoriqueOuSauvegarde,
                         titreSauvegarde: r.data.titreSauvegarde,
                    }
                    setFilters(prev => ({
                         ...prev,
                         filters: tmp,
                    }))
                    setIsQueryEnabled(true)
               })
          } else if (urlParams.get("areCandidatesInListeDeTraitement") || location.state?.areCandidatesInListeDeTraitement) {
               // areCandidatesInListeDeTraitement
               setInitialFiltersForForm(prev => ({ ...prev, areCandidatesInListeDeTraitement: true }))
               setFilters(prev => ({
                    ...prev,
                    filters: {
                         ...prev.filters,
                         areCandidatesInListeDeTraitement: true,
                    },
               }))
               setIsQueryEnabled(true)
          } else if (urlParams.get("candidat_ids") || location.state?.candidat_ids) {
               // candidat_ids
               let ids
               if (urlParams.getAll("candidat_ids").length > 0) ids = urlParams.getAll("candidat_ids").map(id => parseInt(id))
               if (location.state?.candidat_ids) ids = location.state.candidat_ids
               getCandidates(ids).then(r => {
                    const candidats: ICandidatModel[] = r.data.map(item => ({ id: item.value, prenomNom: item.label }))
                    setInitialFiltersForForm(prev => ({ ...prev, candidats }))
                    setFilters(prev => ({
                         ...prev,
                         filters: {
                              ...prev.filters,
                              candidats: ids,
                         },
                    }))
                    setIsQueryEnabled(true)
               })
          } else if (urlParams.get("vivier_ids") || location.state?.vivier_ids) {
               // vivier_ids
               let ids: number[] = []
               if (urlParams.getAll("vivier_ids").length > 0) ids = urlParams.getAll("vivier_ids").map(id => parseInt(id))
               if (location.state?.vivier_ids) ids = location.state.vivier_ids
               getViviers(ids).then(r => {
                    let viviers: IVivierModel[] = []
                    r.data.map(item => {
                         item.options.map(item_ => viviers.push({ id: item_.value, intitule: item_.label }))
                    })
                    setInitialFiltersForForm(prev => ({ ...prev, viviers }))
                    setFilters(prev => ({
                         ...prev,
                         filters: {
                              ...prev.filters,
                              viviers: ids,
                         },
                    }))
                    setIsQueryEnabled(true)
               })
          } else if (urlParams.get("annonce_ids") || location.state?.annonce_ids) {
               // annonce_ids
               let ids: number[] = []
               if (urlParams.getAll("annonce_ids").length > 0) ids = urlParams.getAll("annonce_ids").map(id => parseInt(id))
               if (location.state?.annonce_ids) ids = location.state.annonce_ids
               getOffers(ids).then(r => {
                    let annonces: OffreModel[] = []
                    r.data.map(item => {
                         item.options.map(item_ => annonces.push({ id: item_.value, intitule: item_.label }))
                    })
                    setInitialFiltersForForm(prev => ({
                         ...prev,
                         annonces: annonces,
                         ...(((urlParams.get("seenOrNot") || location.state?.seenOrNot) && { seenOrNot: urlParams.get("seenOrNot") || location.state?.seenOrNot }) || {}),
                    }))
                    setFilters(prev => ({
                         ...prev,
                         filters: {
                              ...prev.filters,
                              annonces: ids,
                              ...(((urlParams.get("seenOrNot") || location.state?.seenOrNot) && { seenOrNot: urlParams.get("seenOrNot") || location.state?.seenOrNot }) || {}),
                         },
                    }))
                    setIsQueryEnabled(true)
               })
          } else {
               setIsQueryEnabled(true)
          }

          return () => {}
     }, [])

     // Check candidate as seen + show eye icon in item (this will be used on page/item navigation + onclick item)
     function checkItemAsSeen(candidat_id: number) {
          checkCandidateAsSeen(candidat_id)
               .then(r => {
                    queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
                         [REACT_QUERY_KEY_SEARCH_RESULTS, filters],
                         (prev: ISearchResultsResponseModel | undefined) => {
                              if (prev?.data) {
                                   const index = prev.data.findIndex(item_ => item_.candidat.id === candidat_id)
                                   if (r.data.seen) {
                                        prev.data[index] = {
                                             ...prev.data[index],
                                             connectedUserHasSeenCandidat: r.data.seen,
                                        }
                                   }

                                   return prev
                              }

                              return prev
                         }
                    )
               })
               .catch(e => {
                    console.error("Could not check candidate as seen", e)
               })
     }

     // Handles add or delete candidate to/from liste traitement
     function handleAddOrRemoveCandidateToListeTraitement(item: ISearchResultsDataItemResponseModel) {
          setAddingRemovingCandidateToFromListeTraitement(item)
          const request = item.doesConnectedUserAddedCandidatInListeTraitement ? removeCandidateFromListeTraitement : addCandidateToListeTraitement

          request(item.candidat.id as number)
               .then(() => {
                    // Set list item state
                    queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
                         [REACT_QUERY_KEY_SEARCH_RESULTS, filters],
                         (prevData: ISearchResultsResponseModel | undefined) => {
                              if (prevData?.data) {
                                   const index = prevData.data.findIndex(item_ => item_.candidat.id === item.candidat.id)
                                   prevData.data[index] = {
                                        ...prevData.data[index],
                                        doesConnectedUserAddedCandidatInListeTraitement: !prevData.data[index].doesConnectedUserAddedCandidatInListeTraitement,
                                   }

                                   return prevData
                              }

                              return prevData
                         }
                    )

                    // Set connected user state
                    setCurrentUser(prev => {
                         if (prev) {
                              return {
                                   ...prev,
                                   nbCandidatsDansListeTraitement: item.doesConnectedUserAddedCandidatInListeTraitement
                                        ? (prev.nbCandidatsDansListeTraitement as number) - 1
                                        : (prev.nbCandidatsDansListeTraitement as number) + 1,
                              }
                         }
                         return undefined
                    })
               })
               .catch(e => {
                    console.error("Could not add candidate to liste traitement ", e)
               })
               .finally(() => setAddingRemovingCandidateToFromListeTraitement(null))
     }

     return (
          <SearchResultsContext.Provider
               value={{
                    areFiltersVisible,
                    setAreFiltersVisible,
                    isBulkSelectionEnabled,
                    setIsBulkSelectionEnabled,
                    filters,
                    setFilters,
                    useListingQuery,
                    filtersFormikRef,
                    previewContainerRef,
                    simpleSearchBarFormikRef,
                    INITIAL_FILTERS_EMPTY_STATE,
                    initialFiltersForForm,
                    setInitialFiltersForForm,
                    REACT_QUERY_KEY_SEARCH_RESULTS,
                    addingRemovingCandidateToFromListeTraitement,
                    itemInPreview,
                    setItemInPreview,
                    checkItemAsSeen,
                    handleAddOrRemoveCandidateToListeTraitement,
                    addingCandidateToViviers,
                    setAddingCandidateToViviers,
                    sharingCandidate,
                    setSharingCandidate,
                    schedulingEntretienMeet,
                    setSchedulingEntretienMeet,
                    addingSuiviToCandidate,
                    setAddingSuiviToCandidate,
               }}
          >
               {/* Adapt view to display */}
               {window.innerWidth >= 1200 ? <ViewXlOrHigher /> : <ViewLgOrLess />}

               {/* Global modals */}
               {addingCandidateToViviers && <AddCandidateToVivierModalForm />}
               {sharingCandidate && <ShareCandidateModalForm />}
               {schedulingEntretienMeet && <ProgrammerEntretienMeetModalForm />}
               {addingSuiviToCandidate && <SuiviFormModal />}
          </SearchResultsContext.Provider>
     )
}

// View display Lg or less
const ViewLgOrLess = () => {
     const context = useSearchResultsContext()

     return (
          <>
               <div className={`${context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <CandidatsRechercheResultsSimpleSearchBar />
               </div>

               {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                    <div className={"mb-2"}>
                         <CandidatsRechercheResultsPreview />
                    </div>
               )}

               <div className={`${!context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <CandidatsRechercheResultsFilters />
               </div>

               <CandidatsRechercheResultsList />
          </>
     )
}

// View display Xl or higher
const ViewXlOrHigher = () => {
     const context = useSearchResultsContext()
     return (
          <>
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-5 ${!context.areFiltersVisible ? "d-none" : ""}`}>
                         <CandidatsRechercheResultsFilters />
                    </div>
                    {/*
                     * List & preview will share the remaining width
                     * Preview will not be visible at the beginning
                     */}
                    <div className={`col ${context.itemInPreview && context.itemInPreview.isPreviewVisible ? "" : "col-7"}`}>
                         <div className={"row"}>
                              <div className={context.itemInPreview && context.itemInPreview.isPreviewVisible ? "col-5" : ""}>
                                   {/* Simple bar search will be shown only if filters are not displayed */}
                                   <div className={`mb-2 ${context.areFiltersVisible ? "d-none" : ""}`}>
                                        <CandidatsRechercheResultsSimpleSearchBar />
                                   </div>

                                   <CandidatsRechercheResultsList />
                              </div>
                              {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                                   <div className="col-7">
                                        <CandidatsRechercheResultsPreview />
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          </>
     )
}

export default CandidatsRechercheResults
