import * as Yup from "yup"
import MyModal from "@common-utils/MyModal"
import { Form, Formik } from "formik"
import { usePersonnelCraContext } from "./_PersonnelCra"
import MyAlert from "@common-utils/MyAlert"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { KTSVG } from "@common-metronic/helpers"
import useSwal from "@common-hooks/useSwal"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { IPersonnelCraModel } from "@common-models/*"
import { useAuth } from "../../../../AppContext"
import { setPersonnelDeclarationCraHeuresTravail } from "../core/_requests"
import { CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPES_OPTIONS_FOR_SELECT2 } from "@common-constants/*"

const PersonnelCraSetHeuresTravailCellFormInModal = () => {
     const context = usePersonnelCraContext()
     const { currentUser } = useAuth()
     const queryClient = useQueryClient()

     const { swal } = useSwal()

     const schema = Yup.object().shape({
          heures: Yup.number().required("Ce champ est requis"),
     })

     if (!context.selectedCellRowActivite) return <></>

     return (
          <MyModal noHeader={true} show={true} handleClose={() => context.setSelectedCellRowActivite(undefined)} size={"sm"}>
               <Formik
                    initialValues={{
                         heures: context.selectedCellRowActivite.heures?.heuresJour
                              ? context.selectedCellRowActivite.heures.heuresJour
                              : GlobalHelper.sumTwoNumbers(currentUser?.heuresTravail, currentUser?.heuresSuppTravail),
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                         setStatus(null)
                         const absence = context.selectedCellRowActivite?.craDetails?.absence
                         if (absence && values.heures > 0) {
                              swal.fire({
                                   icon: "warning",
                                   text: `Vous avez déjà saisi des heures d'absence pour la date du ${context.selectedCellRowActivite?.craDetails?.date?.format(
                                        "DD/MM/YYYY"
                                   )}. Voulez-vous continuer?`,
                                   showCancelButton: true,
                                   confirmButtonText: "Oui",
                                   cancelButtonText: "Annuler",
                                   showLoaderOnConfirm: true,
                                   preConfirm: async () => {
                                        return setPersonnelDeclarationCraHeuresTravail(context.data.id as number, {
                                             date: context.selectedCellRowActivite?.craDetails?.date?.format("YYYY-MM-DD"),
                                             heures: values.heures,
                                             type: context.selectedCellRowActivite?.type,
                                        })
                                             .then(r => {
                                                  queryClient.setQueryData<IPersonnelCraModel | undefined>(context.REACT_QUERY_KEY_GET_ACTIVE_CRA, () => {
                                                       return r.data
                                                  })
                                                  setSubmitting(false)
                                             })
                                             .catch(e => {
                                                  const error = e.response?.data?.detail
                                                  toast.error(error, { autoClose: false })
                                                  setSubmitting(false)
                                             })
                                   },
                                   allowOutsideClick: () => !swal.isLoading(),
                              }).then()
                              context.setSelectedCellRowActivite(undefined)
                         } else {
                              setPersonnelDeclarationCraHeuresTravail(context.data.id as number, {
                                   date: context.selectedCellRowActivite?.craDetails?.date?.format("YYYY-MM-DD"),
                                   heures: values.heures,
                                   type: context.selectedCellRowActivite?.type,
                              })
                                   .then(r => {
                                        queryClient.setQueryData<IPersonnelCraModel | undefined>(context.REACT_QUERY_KEY_GET_ACTIVE_CRA, () => {
                                             return r.data
                                        })
                                        setSubmitting(false)
                                        context.setSelectedCellRowActivite(undefined)
                                   })
                                   .catch(e => {
                                        const error = e.response?.data?.detail
                                        toast.error(error, { autoClose: false })
                                        setSubmitting(false)
                                   })
                         }
                    }}
                    validationSchema={schema}
               >
                    {({ values, setFieldValue, isSubmitting, status, errors, isValid }) => {
                         return (
                              <Form noValidate autoComplete={"off"} className={"text-center"}>
                                   {status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {status}
                                        </MyAlert>
                                   )}
                                   <div className="d-flex align-items-center justify-content-between">
                                        <div className={"fs-5 fw-bold"}>
                                             {GlobalHelper.firstLetterUppercase(context.selectedCellRowActivite?.craDetails?.date?.format("dddd D MMMM"))} |{" "}
                                             {
                                                  CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPES_OPTIONS_FOR_SELECT2.find(option => option.value === context.selectedCellRowActivite?.type)
                                                       ?.label
                                             }
                                        </div>
                                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => context.setSelectedCellRowActivite(undefined)}>
                                             <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-2x" />
                                        </div>
                                   </div>
                                   <div className="separator separator-dashed mt-2 mb-4" />

                                   <div className="row">
                                        <label className="col-6 fw-bold text-muted d-flex align-items-center">
                                             Heures jour <i className={"fas fa-sun fs-3 text-warning ms-2"} />
                                        </label>
                                        <div className="col-6">
                                             <input
                                                  type="text"
                                                  autoComplete={"off"}
                                                  className={"form-control input-sm"}
                                                  name={"heures"}
                                                  value={values.heures}
                                                  onChange={e => setFieldValue("heures", e.target.value.replace(",", "."))}
                                                  inputMode={"numeric"}
                                                  /*ref={input => input && input.focus({preventScroll: true})} */ onFocus={e => e.target.select()}
                                             />
                                             {errors.heures && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{errors.heures}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>

                                   <div className="d-flex justify-content-center mt-4">
                                        <button className={"btn btn-success btn-sm me-2 p-0 px-3 py-2 d-flex aling-items-center"} disabled={isSubmitting} type={"submit"}>
                                             {!isSubmitting && (
                                                  <>
                                                       Valider <span className={"fas fa-check-circle fs-3 ms-1"} />
                                                  </>
                                             )}
                                             {isSubmitting && isValid && (
                                                  <span className="indicator-progress" style={{ display: "block" }}>
                                                       <span className="spinner-border spinner-border-sm align-middle"></span>
                                                  </span>
                                             )}
                                        </button>
                                        <button
                                             className={"btn btn-danger btn-sm p-0 px-3 py-2 d-flex aling-items-center"}
                                             type={"submit"}
                                             disabled={isSubmitting}
                                             onClick={() => {
                                                  setFieldValue("heures", "0")
                                             }}
                                        >
                                             {!isSubmitting && (
                                                  <>
                                                       Supprimer <span className={"fas fa-trash fs-3 ms-1"} />
                                                  </>
                                             )}
                                             {isSubmitting && isValid && (
                                                  <span className="indicator-progress" style={{ display: "block" }}>
                                                       <span className="spinner-border spinner-border-sm align-middle"></span>
                                                  </span>
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </MyModal>
     )
}

export default PersonnelCraSetHeuresTravailCellFormInModal
