import { useQuery } from "react-query"
import { IConsultantDEsuiviModel, IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getDemandeEmbaucheSuivi } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React from "react"
import {
     CONST_CONSULTANT_DE_SUIVI_TYPE_ADMIN_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_DE_SUIVI_TYPE_ADMIN_VALIDATION,
     CONST_CONSULTANT_DE_SUIVI_TYPE_BROUILLON,
     CONST_CONSULTANT_DE_SUIVI_TYPE_DG_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_DE_SUIVI_TYPE_DG_REJET_DEFINITIF,
     CONST_CONSULTANT_DE_SUIVI_TYPE_DG_VALIDATION,
     CONST_CONSULTANT_DE_SUIVI_TYPE_IA_DEMANDE_EMBAUCHE,
     CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_ADMIN,
     CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_DG,
     CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_IARESP,
     CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_REJET_DEFINITIF,
     CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_VALIDATION,
} from "@common-constants/*"

const REACT_QUERY_KEY_GET_DE_SUIVI = "REACT_QUERY_KEY_GET_DE_SUIVI"

interface IDemandesEmbaucheListingItemSuiviPropsModel {
     consultant_ID: number
}

const DemandesEmbaucheListingItemSuivi = ({ consultant_ID }: IDemandesEmbaucheListingItemSuiviPropsModel) => {
     // Query
     const suiviQuery = useQuery<IConsultantDEsuiviModel[], AxiosError>(REACT_QUERY_KEY_GET_DE_SUIVI, async () => {
          return getDemandeEmbaucheSuivi(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function faIconAccordingToTypeSuivi(type: any) {
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_BROUILLON) return "fa-pencil"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_ADMIN) return "fa-paper-plane"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_DG) return "fa-paper-plane"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_IARESP) return "fa-paper-plane"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_IA_DEMANDE_EMBAUCHE) return "fa-paper-plane"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_VALIDATION) return "fa-thumbs-up"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_DEMANDE_RECTIFICATION) return "fa-hand-point-right"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_REJET_DEFINITIF) return "fa-thumbs-down"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_DG_VALIDATION) return "fa-thumbs-up"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_DG_DEMANDE_RECTIFICATION) return "fa-hand-point-right"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_DG_REJET_DEFINITIF) return "fa-thumbs-down"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_ADMIN_DEMANDE_RECTIFICATION) return "fa-hand-point-right"
          if (type === CONST_CONSULTANT_DE_SUIVI_TYPE_ADMIN_VALIDATION) return "fa-thumbs-up"
          return ""
     }

     return (
          <>
               {/* Is fetching */}
               {suiviQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {/* Is error */}
               {!suiviQuery.isFetching && suiviQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{suiviQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => suiviQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* Without data */}
               {!suiviQuery.isFetching && !suiviQuery.isError && suiviQuery.data!.length === 0 && <MyAlert type={"primary"}>Aucun suivi à afficher</MyAlert>}

               {/* With data */}
               {!suiviQuery.isFetching && !suiviQuery.isError && suiviQuery.data!.length > 0 && (
                    <>
                         <div className="timeline">
                              {suiviQuery.data!.map((suivi, key) => (
                                   <div className="timeline-item" key={key}>
                                        <div className="timeline-line w-40px"></div>
                                        <div className="timeline-icon symbol symbol-circle symbol-40px">
                                             <div className={`symbol-label text-dark`}>
                                                  <span className={`fas ${faIconAccordingToTypeSuivi(suivi.type)} fs-4`}></span>
                                             </div>
                                        </div>
                                        <div className="timeline-content mb-10 mt-n1">
                                             <div className="pe-3 mb-5">
                                                  <div className="fs-5 fw-bold mb-2">{suivi.typeLabel}</div>
                                                  <div className="d-flex align-items-center mt-1 fs-6">
                                                       <div className="text-dark me-2 fs-7">
                                                            Le {suivi.date?.format("dddd DD MMMM YYYY à H[h]m")}{" "}
                                                            {suivi.personnel ? (
                                                                 <b className={"text-center"}>par {suivi.personnel.prenomNom}</b>
                                                            ) : (
                                                                 <b className={"text-dark"}>par vous</b>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>

                                             {suivi.commentaire && (
                                                  <div className="overflow-auto pb-5">
                                                       <p className={"m-0 mb-2 fw-bold"}>Commentaire</p>
                                                       <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-7">
                                                            <div>{suivi.commentaire}</div>
                                                       </div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              ))}
                         </div>
                    </>
               )}
          </>
     )
}

export default DemandesEmbaucheListingItemSuivi
