import React, { useState } from "react"
import { usePersonnelDeclarationDetailsContext } from "./_PersonnelDeclarationDetails"
import PersonnelTr from "../tr/_PersonnelTr"
import { useAuth } from "../../../../AppContext"
import { CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION } from "@common-constants/*"

const TicketsRestaurant = () => {
     const context = usePersonnelDeclarationDetailsContext()
     const { currentUser } = useAuth()

     const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

     const Footer = () => {
          return (
               <>
                    <div className="d-flex justify-content-between">
                         <div>
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setNavigationStep("ndf")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> NDF
                              </button>
                         </div>

                         <div className={"d-flex"}>
                              {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                                   <>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-success d-flex align-items-center me-2"
                                             onClick={() => context.handleValidationDemandeRegul()}
                                        >
                                             <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                             <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-danger d-flex align-items-center" onClick={() => context.handleRejetDemandeRegul()}>
                                             <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                        </button>
                                   </>
                              )}

                              {currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? (
                                   <>
                                        {context.areActionsDemandeRectifOrValidationAvailable && (
                                             <>
                                                  {/* Actions admin */}
                                                  <button
                                                       type="button"
                                                       className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                       onClick={() => context.handleDemandeRectification()}
                                                  >
                                                       <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                                       <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                                  </button>

                                                  <button type="button" className="btn btn-sm btn-success d-flex align-items-center" onClick={() => context.handleValidation()}>
                                                       <span className={"d-none d-sm-flex"}>Valider et suivant</span>
                                                       <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                                  </button>
                                             </>
                                        )}
                                   </>
                              ) : (
                                   <>
                                        {/* Actions responsable */}
                                        {context.areActionsDemandeRectifOrValidationAvailable && (
                                             <>
                                                  {/* Le chargé de recrutement pourra demander une rectification mais pas valider*/}
                                                  {currentUser?.roleGroup !== "ROLE_GROUP_RECRUTEUR" ? (
                                                       <>
                                                            <button
                                                                 type="button"
                                                                 className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                                 onClick={() => context.handleDemandeRectification()}
                                                            >
                                                                 <span className={"d-none d-sm-flex"}>Demander une rectification</span>{" "}
                                                                 <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                                            </button>
                                                            <button
                                                                 type="button"
                                                                 className="btn btn-sm btn-success d-flex align-items-center"
                                                                 onClick={() => context.handleValidation()}
                                                            >
                                                                 <span className={"d-none d-sm-flex"}>
                                                                      {context.declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION
                                                                           ? "Retourner la déclaration au siège"
                                                                           : "Valider et suivant"}
                                                                 </span>
                                                                 <span
                                                                      className={`fas fa-${
                                                                           context.declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION
                                                                                ? "hand-point-right"
                                                                                : "thumbs-up"
                                                                      } fs-3 ms-1`}
                                                                 />
                                                            </button>
                                                       </>
                                                  ) : (
                                                       <>
                                                            <button
                                                                 type="button"
                                                                 className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                                 onClick={() => context.handleDemandeRectification()}
                                                            >
                                                                 <span className={"d-none d-sm-flex"}>Demander une rectification</span>{" "}
                                                                 <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                                            </button>
                                                       </>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               <div className="mb-4">
                    <PersonnelTr declaration_ID={context.declaration.id as number} editionModeEnabled={false} setIsDataLoaded={setIsDataLoaded} />
               </div>

               {isDataLoaded && <Footer />}
          </>
     )
}

export default TicketsRestaurant
