/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import * as Yup from "yup"
import clsx from "clsx"
import { Link } from "react-router-dom"
import { useFormik } from "formik"
import { AxiosError } from "axios"
import { useIntl } from "react-intl"
import { useAuth } from "../../../AppContext"
import { authWithUsernameAndPassword } from "../core/_requests"

const loginSchema = Yup.object().shape({
     email: Yup.string().email("Mauvais format").min(3, "Minimum 3 caractères").max(50, "Maximum 50 caractères").required("Ce champ est requis"),
     password: Yup.string().min(3, "Minimum 3 caractères").max(50, "Maximum 50 caractères").required("Ce champ est requis"),
})

function LoginForm() {
     const intl = useIntl()
     const { saveAuth, setCurrentUser } = useAuth()
     const formik_login = useFormik({
          initialValues: {
               email: process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOGIN_EMAIL || "" : "",
               password: process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOGIN_PWD || "" : "",
          },
          validationSchema: loginSchema,
          onSubmit: (values, { setStatus, setSubmitting }) => {
               setStatus(null)
               authWithUsernameAndPassword(values.email, values.password)
                    .then(r => {
                         setCurrentUser(r.data.user)
                         saveAuth({ token: r.data.jwt })
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.status === 400) {
                              setStatus(e.response?.data?.detail)
                         } else if (e.response?.status === 401) {
                              setStatus("Identifiant ou mot de passe incorrect.")
                         } else {
                              setStatus(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         }
                         setSubmitting(false)
                    })
          },
     })

     return (
          <form className="form" onSubmit={formik_login.handleSubmit} noValidate>
               {formik_login.status && (
                    <div className="alert alert-danger">
                         <div className="alert-text font-weight-bold">{formik_login.status}</div>
                    </div>
               )}

               {/* begin::Form group */}
               <div className="fv-row mb-5">
                    <label className="form-label fs-6 fw-bolder text-black">Email</label>
                    <input
                         placeholder="Email"
                         {...formik_login.getFieldProps("email")}
                         className={clsx(
                              "form-control bg-transparent",
                              { "is-invalid": formik_login.touched.email && formik_login.errors.email },
                              {
                                   "is-valid": formik_login.touched.email && !formik_login.errors.email,
                              }
                         )}
                         type="email"
                         name="email"
                         autoComplete="off"
                    />
                    {formik_login.touched.email && formik_login.errors.email && (
                         <div className="fv-plugins-message-container">
                              <span role="alert">{formik_login.errors.email}</span>
                         </div>
                    )}
               </div>
               {/* end::Form group */}

               {/* begin::Form group */}
               <div className="fv-row mb-3">
                    <label className="form-label fw-bolder text-black fs-6">Mot de passe</label>
                    <input
                         type="password"
                         autoComplete="off"
                         {...formik_login.getFieldProps("password")}
                         placeholder={"Mot de passe"}
                         className={clsx(
                              "form-control bg-transparent",
                              {
                                   "is-invalid": formik_login.touched.password && formik_login.errors.password,
                              },
                              {
                                   "is-valid": formik_login.touched.password && !formik_login.errors.password,
                              }
                         )}
                    />
                    {formik_login.touched.password && formik_login.errors.password && (
                         <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                   <span role="alert">{formik_login.errors.password}</span>
                              </div>
                         </div>
                    )}
               </div>
               {/* end::Form group */}

               <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5">
                    <div />

                    <Link to={`/auth/forgot-password`} state={{ email: formik_login.values.email }} className="link-primary">
                         Mot de passe oublié ?
                    </Link>
               </div>

               <div className="d-grid mb-5">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary" disabled={formik_login.isSubmitting}>
                         {!formik_login.isSubmitting && <span className="indicator-label">Continuer</span>}
                         {formik_login.isSubmitting && (
                              <span className="indicator-progress" style={{ display: "block" }}>
                                   Connexion en cours <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                         )}
                    </button>
               </div>
          </form>
     )
}

export default LoginForm
