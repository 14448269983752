import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import { IPersonnelDeclarationMensuelleModel } from "@common-models/*"
import { Header } from "./_Header"
import DDC from "./_Ddc"
import Maladie from "./_Maladie"
import CRA from "./_Cra"
import NDF from "./_Ndf"
import TicketsRestaurant from "./_Tr"
import {
     suiviDemandeRectifParRespPersonnelDeclarationRequest,
     suiviDemandeRectifParSiegePersonnelDeclarationRequest,
     suiviRejetDemandeRegulParRespPersonnelDeclarationRequest,
     suiviValidationDemandeRegulParRespPersonnelDeclarationRequest,
     suiviValidationParRespPersonnelDeclarationRequest,
     suiviValidationParSiegePersonnelDeclarationRequest,
} from "../core/_requests"
import { toast } from "react-toastify"
import { useAuth } from "../../../../AppContext"
import useSwal from "@common-hooks/useSwal"
import {
     CONST_PERSONNEL_CODE_CELINE_CORLE,
     CONST_PERSONNEL_CODE_DIDIER_BAGUR,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
} from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"

const PersonnelDeclarationDetails = ({
     declaration,
     handleDemandeRectificationCallback = () => {},
     handleValidationCallback = () => {},
     handleValidationDemandeRegulCallback = () => {},
     handleRejetDemandeRegulCallback = () => {},
     defaultStep = "arrets",
}: IPersonnelDeclarationDetailsPropsModel) => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()
     const [navigationStep, setNavigationStep] = useState<"arrets" | "ddc" | "cra" | "ndf" | "tr">(defaultStep)

     const containerClassAccordingToStep = (() => {
          if (navigationStep === "arrets") return "w-100 w-lg-75 w-xxl-65"
          if (navigationStep === "ddc") return "w-100 w-lg-75 w-xxl-65"
          if (navigationStep === "cra") return "w-100"
          if (navigationStep === "ndf") return "w-100 w-lg-90 w-xxl-65"
          if (navigationStep === "tr") return "w-100"

          return ""
     })()

     const isPersonnelSousRespDeCelineOuDidier = (() => {
          return !!(
               [CONST_PERSONNEL_CODE_DIDIER_BAGUR, CONST_PERSONNEL_CODE_CELINE_CORLE].includes(currentUser?.code as string) &&
               currentUser?.personnelHierarchyFromUpToDown?.find(personnel => personnel.id === declaration.personnel?.id)
          )
     })()

     // Handles demande rectification resp ou siège
     function handleDemandeRectification() {
          let request: any
          if (isPersonnelSousRespDeCelineOuDidier) {
               request = suiviDemandeRectifParRespPersonnelDeclarationRequest
          } else {
               request =
                    currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF"
                         ? suiviDemandeRectifParSiegePersonnelDeclarationRequest
                         : suiviDemandeRectifParRespPersonnelDeclarationRequest
          }

          swal.fire({
               icon: "warning",
               title: `Avant de procéder, merci de saisir un commentaire`,
               input: "textarea",
               inputPlaceholder: "Commentaire ...",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Envoyer la demande",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return request(declaration.id as number, commentaire)
                         .then(r => {
                              toast.info("Votre demande a bien été envoyée")
                              setNavigationStep("cra")
                              handleDemandeRectificationCallback && handleDemandeRectificationCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles validation responsable ou siège
     function handleValidation() {
          let request: any
          if (isPersonnelSousRespDeCelineOuDidier) {
               request = suiviValidationParSiegePersonnelDeclarationRequest
          } else {
               request =
                    currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? suiviValidationParSiegePersonnelDeclarationRequest : suiviValidationParRespPersonnelDeclarationRequest
          }

          swal.fire({
               icon: "info",
               title:
                    declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION
                         ? `Vous êtes sur le point de retourner la déclaration au siège`
                         : `Vous êtes sur le point de valider cette déclaration`,
               text:
                    declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION
                         ? "Cela signifie que la déclaration ne contient pas d'erreurs. Voulez-vous continuer?"
                         : `Voulez-vous continuer?`,
               input: "textarea",
               inputPlaceholder:
                    declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION ? "Précisez la raison de ce retour" : "Commentaire (optionnel)",
               inputAttributes: {
                    required: declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION ? "true" : "false",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION && value === ""
                              ? resolve("Merci de préciser la raison de ce retour")
                              : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return request(declaration.id as number, commentaire)
                         .then(r => {
                              toast.success(
                                   declaration.etat?.type === CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION
                                        ? "La déclaration a été retournée au siège."
                                        : "Vous avez bien validé cette déclaration."
                              )
                              setNavigationStep("cra")
                              handleValidationCallback && handleValidationCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles validation demande régul
     function handleValidationDemandeRegul() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de valider la demande de régularisation de ${declaration.personnel?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return suiviValidationDemandeRegulParRespPersonnelDeclarationRequest(declaration.id as number)
                         .then(r => {
                              toast.success("Vous avez bien validé cette demande.")
                              setNavigationStep("cra")
                              handleValidationDemandeRegulCallback && handleValidationDemandeRegulCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles rejet demande régul
     function handleRejetDemandeRegul() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de rejeter la demande de régularisation de ${declaration.personnel?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               input: "textarea",
               inputPlaceholder: "Merci de préciser la raison de ce rejet",
               inputAttributes: {
                    required: "true",
               },
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci de préciser la raison de ce rejet") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return suiviRejetDemandeRegulParRespPersonnelDeclarationRequest(declaration.id as number, commentaire)
                         .then(r => {
                              toast.success("Vous avez rejeté cette demande.")
                              setNavigationStep("cra")
                              handleRejetDemandeRegulCallback && handleRejetDemandeRegulCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function areActionsDemandeRectifAndValidationAvailable() {
          if (
               isPersonnelSousRespDeCelineOuDidier &&
               [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION].includes(
                    declaration.etat?.type as string
               )
          ) {
               return true
          }

          if (currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF") {
               return [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(declaration.etat?.type as string)
          } else {
               // If not siège
               return [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION].includes(
                    declaration.etat?.type as string
               )
          }
     }

     function isActionValiderDemandeRegulAvailable() {
          if (isPersonnelSousRespDeCelineOuDidier && [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL].includes(declaration.etat?.type as string)) return true

          return (
               currentUser?.roleGroup !== "ROLE_GROUP_ADMINISTRATIF" && [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL].includes(declaration.etat?.type as string)
          )
     }

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={containerClassAccordingToStep}>
                         <PersonnelDeclarationDetailsContext.Provider
                              value={{
                                   navigationStep,
                                   setNavigationStep,
                                   declaration,
                                   handleDemandeRectification,
                                   handleValidation,
                                   handleValidationDemandeRegul,
                                   handleRejetDemandeRegul,
                                   areActionsDemandeRectifOrValidationAvailable: areActionsDemandeRectifAndValidationAvailable(),
                                   isActionValiderOuRejeterDemandeRegulAvailable: isActionValiderDemandeRegulAvailable(),
                              }}
                         >
                              <>
                                   {declaration.etat?.commentaire && (
                                        <MyAlert type={"info"} customIcon={"fas fa-comment-dots"}>
                                             <div className={"d-flex  align-items-center"}>
                                                  <b className={"text-dark me-1"}>
                                                       {declaration.etat.personnel ? declaration.etat.personnel?.prenomNom : declaration.personnel?.prenomNom}:
                                                  </b>
                                                  <span>{declaration.etat?.commentaire}</span>
                                             </div>
                                        </MyAlert>
                                   )}

                                   <Header />
                                   <div className="separator separator-dashed mb-5" />

                                   {navigationStep === "arrets" && (
                                        <Maladie
                                             filtres={{
                                                  duOrAuWithThisMonthAndYear: declaration.mois!.format("YYYY-MM-DD"),
                                             }}
                                        />
                                   )}
                                   {navigationStep === "ddc" && (
                                        <DDC
                                             filtres={{
                                                  duOrAuWithThisMonthAndYear: declaration.mois?.format("YYYY-MM-DD"),
                                             }}
                                        />
                                   )}
                                   {navigationStep === "cra" && <CRA />}
                                   {navigationStep === "ndf" && <NDF />}
                                   {navigationStep === "tr" && <TicketsRestaurant />}
                              </>
                         </PersonnelDeclarationDetailsContext.Provider>
                    </div>
               </div>
          </>
     )
}

const PersonnelDeclarationDetailsContext = createContext<IPersonnelDeclarationDetailsContextPropsModel>({} as IPersonnelDeclarationDetailsContextPropsModel)
export const usePersonnelDeclarationDetailsContext = () => useContext(PersonnelDeclarationDetailsContext)

interface IPersonnelDeclarationDetailsContextPropsModel {
     navigationStep: "arrets" | "ddc" | "cra" | "ndf" | "tr"
     setNavigationStep: Dispatch<SetStateAction<"arrets" | "ddc" | "cra" | "ndf" | "tr">>
     declaration: IPersonnelDeclarationMensuelleModel
     handleDemandeRectification: () => void
     handleValidation: () => void
     handleValidationDemandeRegul: () => void
     handleRejetDemandeRegul: () => void
     areActionsDemandeRectifOrValidationAvailable: boolean
     isActionValiderOuRejeterDemandeRegulAvailable: boolean
}

interface IPersonnelDeclarationDetailsPropsModel {
     declaration: IPersonnelDeclarationMensuelleModel
     defaultStep?: "arrets" | "ddc" | "cra" | "ndf" | "tr"
     handleDemandeRectificationCallback?: (val: IPersonnelDeclarationMensuelleModel) => void
     handleValidationCallback?: (val: IPersonnelDeclarationMensuelleModel) => void
     handleValidationDemandeRegulCallback?: (val: IPersonnelDeclarationMensuelleModel) => void
     handleRejetDemandeRegulCallback?: (val: IPersonnelDeclarationMensuelleModel) => void
}

export default PersonnelDeclarationDetails
