import axios from "axios"
import { IConsultantCraModel, IConsultantDeclarationMensuelleSuiviModel, IConsultantNdfDetailModel } from "@common-models/*"
import {
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_ADD_SUIVI_REJET_DEMANDE_REGUL_PAR_RESP_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_ADD_SUIVI_VALIDATION_DEMANDE_REGUL_PAR_RESP_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_ACTIVE_CRA_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_ACTIVE_NDF_DETAILS_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_SUIVI_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_DEMANDE_RECTIF_PAR_RESPONSABLE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_DEMANDE_RECTIF_PAR_SIEGE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_VALIDATION_RESPONSABLE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_VALIDATION_SIEGE_ENDPOINT,
} from "../../../../../endpoints"

export function getDeclarationActiveCra(id: number) {
     return axios.get<IConsultantCraModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_ACTIVE_CRA_ENDPOINT(id))
}

export function getDeclarationActiveNdfDetails(id: number) {
     return axios.get<IConsultantNdfDetailModel[]>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_ACTIVE_NDF_DETAILS_ENDPOINT(id))
}

export function addSuiviDeclarationValidationResponsable(id: number, commentaire?: string | null) {
     let payload = {}
     if (commentaire) payload = { commentaire }
     return axios.post<IConsultantDeclarationMensuelleSuiviModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_VALIDATION_RESPONSABLE_ENDPOINT(id), payload)
}

export function addSuiviDeclarationValidationSiege(id: number, commentaire?: string | null) {
     let payload = {}
     if (commentaire) payload = { commentaire }
     return axios.post<IConsultantDeclarationMensuelleSuiviModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_VALIDATION_SIEGE_ENDPOINT(id), payload)
}

export function addSuiviDeclarationDemandeRectificationParResponsable(id: number, commentaire: string) {
     return axios.post<IConsultantDeclarationMensuelleSuiviModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_DEMANDE_RECTIF_PAR_RESPONSABLE_ENDPOINT(id), {
          commentaire,
     })
}

export function addSuiviDeclarationDemandeRectificationParSiege(id: number, commentaire: string) {
     return axios.post<IConsultantDeclarationMensuelleSuiviModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_DEMANDE_RECTIF_PAR_SIEGE_ENDPOINT(id), { commentaire })
}

export function addSuiviDeclarationRejetDemandeRegulParRespRequest(id: number, commentaire: string) {
     return axios.post<IConsultantDeclarationMensuelleSuiviModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_ADD_SUIVI_REJET_DEMANDE_REGUL_PAR_RESP_ENDPOINT(id), { commentaire })
}

export function addSuiviDeclarationValidationDemandeRegulParRespRequest(id: number) {
     return axios.post<IConsultantDeclarationMensuelleSuiviModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_ADD_SUIVI_VALIDATION_DEMANDE_REGUL_PAR_RESP_ENDPOINT(id), {})
}

export function getDeclarationConsultantSuivi(declaration_id: number) {
     return axios.get<IConsultantDeclarationMensuelleSuiviModel[]>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_SUIVI_ENDPOINT(declaration_id))
}
