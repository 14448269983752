import React, { useState } from "react"
import MyAlert from "@common-utils/MyAlert"
import {
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE,
} from "@common-constants/*"

import { AxiosError } from "axios"
import { useQuery } from "react-query"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyModal from "@common-utils/MyModal"
import DetailFiltersRechercheCandidat from "./DetailFiltersRechercheCandidat"
import { Link } from "react-router-dom"
import {
     ILogOrSavedFiltersSearchCandidateModel,
     ISomeSavedFiltersAndLogsSearchCandidateDataItemResponseModel,
     ISomeSavedFiltersAndLogsSearchCandidateResponseModel,
} from "./core/_models"
import { getSomeSavedFiltersAndLogsSearchCandidate } from "./core/_requests"

export const REACT_QUERY_KEY_LOGS_AND_SAVED_FILTERS = "REACT_QUERY_KEY_LOGS_AND_SAVED_FILTERS"

// Lists 5 logs & 5 saved filters
const LogsAndSavedFilters = () => {
     const [que_moi_or_moi_et_ma_team, setQue_moi_or_moi_et_ma_team] = useState<"que_moi" | "moi_et_ma_team">("que_moi")
     const [logOrSauvegardeRechercheCandidatInModal, setLogOrSauvegardeRechercheCandidatInModal] = useState<ISomeSavedFiltersAndLogsSearchCandidateDataItemResponseModel | null>(
          null
     )

     const useLogsAndSavedFiltersQuery = useQuery<ISomeSavedFiltersAndLogsSearchCandidateResponseModel, AxiosError>(REACT_QUERY_KEY_LOGS_AND_SAVED_FILTERS, () =>
          getSomeSavedFiltersAndLogsSearchCandidate(que_moi_or_moi_et_ma_team).then(r => r.data)
     )

     return (
          <>
               {useLogsAndSavedFiltersQuery.isFetching ? (
                    <div className={"text-center"}>
                         <div className="spinner-border align-middle ms-2" />
                    </div>
               ) : (
                    <>
                         <h5>
                              Filtres <i className="fas fa-filter text-dark ms-1" />
                         </h5>
                         <div className="row mb-5">
                              <div className={"col-6"}>
                                   <button
                                        className={`btn btn-sm w-100 me-2 ${que_moi_or_moi_et_ma_team === "que_moi" ? "btn-primary" : "btn-outline btn-outline-primary"}`}
                                        onClick={() => {
                                             if (que_moi_or_moi_et_ma_team === "moi_et_ma_team") setQue_moi_or_moi_et_ma_team("que_moi")
                                             useLogsAndSavedFiltersQuery.refetch().then()
                                        }}
                                        type={"button"}
                                   >
                                        Que Moi
                                   </button>
                              </div>
                              <div className="col-6">
                                   <button
                                        className={`btn btn-sm w-100 me-2 ${que_moi_or_moi_et_ma_team === "moi_et_ma_team" ? "btn-primary" : "btn-outline btn-outline-primary"}`}
                                        onClick={() => {
                                             if (que_moi_or_moi_et_ma_team === "que_moi") setQue_moi_or_moi_et_ma_team("moi_et_ma_team")
                                             useLogsAndSavedFiltersQuery.refetch().then()
                                        }}
                                        type={"button"}
                                   >
                                        Ma team Et moi
                                   </button>
                              </div>
                         </div>

                         <div className={"separator separator-dotted border-secondary mb-10"}></div>

                         {/* In case of error */}
                         {useLogsAndSavedFiltersQuery.error ? (
                              <MyAlert type={"danger"}>{useLogsAndSavedFiltersQuery.error.response?.data.detail}</MyAlert>
                         ) : (
                              <>
                                   {/* SAUVEGARDES (Max displayed = 5) */}
                                   <div id={"savedFilters"} className={"mb-8"}>
                                        <h5 className="text-dark">
                                             Sauvegardes <i className="fas fa-save text-dark ms-1" />
                                             {useLogsAndSavedFiltersQuery.data && useLogsAndSavedFiltersQuery.data.totalSauvegardes > 0 && (
                                                  <Link to={"saved-filters"} className={"text-primary cursor-pointer float-end"}>
                                                       Voir tout <i className={"fas fa-eye text-primary"} /> ({useLogsAndSavedFiltersQuery.data.totalSauvegardes})
                                                  </Link>
                                             )}
                                        </h5>

                                        {/* If no sauvegardes */}
                                        {useLogsAndSavedFiltersQuery.data &&
                                             useLogsAndSavedFiltersQuery.data.data.filter(
                                                  item => item.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE
                                             ).length === 0 && <MyAlert type={"primary"}>Aucune sauvegarde à afficher</MyAlert>}

                                        <ul>
                                             {/* If there is some sauvegardes */}
                                             {useLogsAndSavedFiltersQuery.data &&
                                                  useLogsAndSavedFiltersQuery.data.data
                                                       .filter(item => item.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE)
                                                       .map((item, key) => (
                                                            <li key={key}>
                                                                 <span className={"text-primary cursor-pointer"} onClick={() => setLogOrSauvegardeRechercheCandidatInModal(item)}>
                                                                      {GlobalHelper.firstLetterUppercase(item.titreSauvegarde)}
                                                                 </span>
                                                            </li>
                                                       ))}
                                        </ul>
                                   </div>

                                   {/* LOGS (Max displayed = 5) */}
                                   <div id={"logs"}>
                                        <h5 className="text-dark">
                                             Historique <i className="fas fa-history text-dark ms-1 mb-3" />
                                             {useLogsAndSavedFiltersQuery.data && useLogsAndSavedFiltersQuery.data.totalLogsRecherche > 0 && (
                                                  <Link to={"logs"} className={"text-primary cursor-pointer float-end"}>
                                                       Voir tout <i className={"fas fa-eye text-primary"} />
                                                  </Link>
                                             )}
                                        </h5>
                                        {/* If 0 logs */}
                                        {useLogsAndSavedFiltersQuery.data &&
                                             useLogsAndSavedFiltersQuery.data.data.filter(
                                                  item => item.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE
                                             ).length === 0 && <MyAlert type={"primary"}>Aucune recherche à afficher</MyAlert>}

                                        {/* If there is some logs */}
                                        <ul>
                                             {useLogsAndSavedFiltersQuery.data &&
                                                  useLogsAndSavedFiltersQuery.data.data
                                                       .filter(item => item.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE)
                                                       .map((item, key) => (
                                                            <li key={key}>
                                                                 <span className={"text-primary cursor-pointer"} onClick={() => setLogOrSauvegardeRechercheCandidatInModal(item)}>
                                                                      {GlobalHelper.firstLetterUppercase(item.dateCreation?.format("dddd Do MMM YYYY à HH[h]mm"))}
                                                                 </span>
                                                            </li>
                                                       ))}
                                        </ul>
                                   </div>
                              </>
                         )}
                    </>
               )}

               {logOrSauvegardeRechercheCandidatInModal && (
                    <ModalViewLogOrSavedFilter
                         activeItem={logOrSauvegardeRechercheCandidatInModal as ISomeSavedFiltersAndLogsSearchCandidateDataItemResponseModel}
                         setActiveItem={setLogOrSauvegardeRechercheCandidatInModal}
                         handleClose={() => setLogOrSauvegardeRechercheCandidatInModal(null)}
                    />
               )}
          </>
     )
}

const ModalViewLogOrSavedFilter = ({
     activeItem,
     setActiveItem,
     handleClose,
}: {
     activeItem: ISomeSavedFiltersAndLogsSearchCandidateDataItemResponseModel
     setActiveItem: React.Dispatch<React.SetStateAction<ISomeSavedFiltersAndLogsSearchCandidateDataItemResponseModel | null>>
     handleClose: () => void
}) => {
     const detail: ILogOrSavedFiltersSearchCandidateModel = {
          id: activeItem.id,
          searchKeywords: activeItem.searchKeywords,
          searchScope: activeItem.searchScope,
          searchScopeLabel: activeItem.searchScopeLabel,
          searchOperateurLogiqueOnlyScopeCurriculum: activeItem.searchOperateurLogiqueOnlyScopeCurriculum,
          searchOperateurLogiqueOnlyScopeCurriculumLabel: activeItem.searchOperateurLogiqueOnlyScopeCurriculumLabel,
          disponibilite: activeItem.disponibilite,
          disponibiliteLabel: activeItem.disponibiliteLabel,
          disponibiliteDate: activeItem.disponibiliteDate,
          mobilite: activeItem.mobilite,
          mobiliteLabel: activeItem.mobiliteLabel,
          mobiliteRegions: activeItem.mobiliteRegions,
          mobiliteDepartements: activeItem.mobiliteDepartements,
          mobiliteVilles: activeItem.mobiliteVilles,
          niveauEtudes: activeItem.niveauEtudes,
          niveauEtudesLabel: activeItem.niveauEtudesLabel,
          fraicheurCV: activeItem.fraicheurCV,
          fraicheurCVLabel: activeItem.fraicheurCVLabel,
          viviers: activeItem.viviers,
          candidatType: activeItem.candidatType,
          candidatTypeLabel: activeItem.candidatTypeLabel,
          personnelsAyantSaisiLeCandidat: activeItem.personnelsAyantSaisiLeCandidat,
          secteursActivite: activeItem.secteursActivite,
          metiers: activeItem.metiers,
          seenOrNot: activeItem.seenOrNot,
          seenOrNotLabel: activeItem.seenOrNotLabel,
          experienceMin: activeItem.experienceMin,
          experienceMinLabel: activeItem.experienceMinLabel,
          // salaireNetMensuelSouhaiteMin: activeItem.salaireNetMensuelSouhaiteMin,
          // salaireNetMensuelSouhaiteMax: activeItem.salaireNetMensuelSouhaiteMax,
          avecSuivi: activeItem.avecSuivi,
          avecSuiviLabel: activeItem.avecSuiviLabel,
          typeHistoriqueOuSauvegarde: activeItem.typeHistoriqueOuSauvegarde,
          typeHistoriqueOuSauvegardeLabel: activeItem.typeHistoriqueOuSauvegardeLabel,
          titreSauvegarde: activeItem.titreSauvegarde,
          dateCreation: activeItem.dateCreation,
     }

     return (
          <MyModal
               title={
                    <span>
                         {activeItem.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE
                              ? `Recherche du ${activeItem.dateCreation?.format("dddd Do MMM YYYY à HH[h]mm")}`
                              : activeItem.titreSauvegarde}
                    </span>
               }
               show={true}
               size={"lg"}
               handleClose={() => handleClose()}
          >
               <div>
                    <div className={"mb-2"}>
                         <DetailFiltersRechercheCandidat detail={detail} />
                    </div>

                    <div className={"text-end"}>
                         <Link
                              to={"/recrutement/candidats/search/results"}
                              state={{ log_or_saved_filters_id: activeItem.id }}
                              className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                         >
                              Lancer <i className={"fas fa-search ms-1"} />
                         </Link>
                    </div>
               </div>
          </MyModal>
     )
}

export default LogsAndSavedFilters
