import MyCard from "@common-utils/MyCard"
import CostTool from "./costTool/CostTool"
import { useState } from "react"
import { toast } from "react-toastify"

const Calculator = () => {
     const [onglet, setOnglet] = useState<"COST_TOOL" | "PE">("COST_TOOL")

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-65"}>
                    <MyCard>
                         <MyCard.Header
                              rightSideContent={
                                   <>
                                        <button
                                             type={"button"}
                                             className={`btn btn-sm me-2 ${onglet === "COST_TOOL" ? "btn-dark" : "btn-outline btn-outline-dark"}`}
                                             onClick={() => setOnglet("COST_TOOL")}
                                        >
                                             Cost tool
                                        </button>
                                        <button
                                             type={"button"}
                                             className={`btn btn-sm ${onglet === "PE" ? "btn-dark" : "btn-outline btn-outline-dark"}`}
                                             onClick={() => toast.info("Soon ...")}
                                        >
                                             Période d'essai (soon)
                                        </button>
                                   </>
                              }
                         >
                              {onglet === "COST_TOOL" ? "Cost tool" : "Simulation de période d'essai"}
                         </MyCard.Header>
                         <MyCard.Body>
                              <CostTool />
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default Calculator
