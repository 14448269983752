import { Form, Formik, FormikHelpers } from "formik"
import { IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import {
     CONST_CLIENT_BON_COMMANDE_TYPE_BC_ORIGINAL,
     CONST_CLIENT_BON_COMMANDE_TYPE_MAIL_CONFIRMATION,
     CONST_CLIENT_PROPAL_CATEGORIE_FILLE,
     CONST_CLIENT_PROPAL_CATEGORIE_MERE,
     CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE,
     CONST_CLIENT_PROPAL_ETAT_EN_COURS,
     CONST_CLIENT_PROPAL_ETAT_REFUSEE,
     CONST_CLIENT_PROPAL_ETAT_VALIDEE,
     CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE,
     CONST_CLIENT_PROPAL_TYPE_FORFAIT,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { toast } from "react-toastify"
import { IPtfCreateFormikInitValuesModel, IPtfCreateRequestModel } from "./core/_models"
import { ptfCreateRequest, ptfGenerateNextRefRequest } from "./core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import {
     CONST_API_UTILS_GET_CLIENT_ADRESSES_ENDPOINT,
     CONST_API_UTILS_GET_CLIENT_RESPONSABLES_ENDPOINT,
     CONST_API_UTILS_GET_CLIENTS_ENDPOINT,
     CONST_API_UTILS_GET_ENTITES_ENDPOINT,
     CONST_API_UTILS_GET_PTFS_MERE_ENDPOINT,
} from "../../../../endpoints"
import MyCard from "@common-utils/MyCard"
import { useQuery } from "react-query"
import MyTooltip from "@common-utils/MyTooltip"
import MyFileField from "@common-utils/fields/MyFileField"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import MySelectField from "@common-utils/fields/MySelectField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { useNavigate } from "react-router-dom"
import MyModal from "@common-utils/MyModal"
import AddClientForm from "../../../utils/_AddClientForm"
import AddResponsableClientForm from "../../../utils/_AddResponsableClientForm"
import AddAdresseClientForm from "../../../utils/_AddAdresseClientForm"

const REACT_QUERY_KEY_GET_NEXT_REF = "REACT_QUERY_KEY_GET_NEXT_REF"
const PtfCreate = () => {
     const navigate = useNavigate()
     const [isAddingClient, setIsAddingClient] = useState<boolean>(false)
     const [isAddingRespClient, setIsAddingRespClient] = useState<boolean>(false)
     const [isAddingAdresseClient, setIsAddingAdresseClient] = useState<boolean>(false)

     // Query
     const nextReferenceQuery = useQuery<{ ref: string }, AxiosError>(REACT_QUERY_KEY_GET_NEXT_REF, async () => {
          return ptfGenerateNextRefRequest()
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const initialValues: IPtfCreateFormikInitValuesModel = {}

     function handleCopyRef() {
          navigator.clipboard.writeText(`${nextReferenceQuery.data!.ref}-1A`).then(() => {
               toast.info("Référence copiée!", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 500 })
          })
     }

     function handleSubmit(values: IPtfCreateFormikInitValuesModel, helpers: FormikHelpers<IPtfCreateFormikInitValuesModel>) {
          helpers.setStatus(null)

          const payload: IPtfCreateRequestModel = {
               categorie: values.categorie as string,
               reference: nextReferenceQuery.data!.ref,
               type: values.type,
               ptfMere: values.ptfMere,
               objet: values.objet as string,
               tjm: values.tjm,
               entiteRattachement: values.entiteRattachement,
               entiteFacturation: values.entiteFacturation,
               etat: values.etat,
               dateDebut: values.dateDebut,
               dateFin: values.dateFin,
               client: values.client,
               clientAdresse: values.clientAdresse,
               clientResponsable: values.clientResponsable,
               bonCommandeType: values.bonCommandeType,
               bonCommandeDateDebut: values.bonCommandeDateDebut,
               bonCommandeDateFin: values.bonCommandeDateFin,
               bonCommandeDocument: values.bonCommandeDocument,
               documentGeneratedOrSaisieManuelle: values.documentGeneratedOrSaisieManuelle,
               documentPtf: values.documentPtf,
          }

          ptfCreateRequest(payload)
               .then(() => {
                    navigate("/client/ptf/listing")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <div className="d-flex justify-content-center">
               <MyCard classNames={"w-100 w-lg-75 w-xxl-70"}>
                    <MyCard.Body>
                         {/* Fetching ref */}
                         {nextReferenceQuery.isFetching && (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner size={"xl"} />
                              </div>
                         )}

                         {/* On error while fetching */}
                         {!nextReferenceQuery.isFetching && nextReferenceQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{nextReferenceQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => nextReferenceQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}

                         {/* Ref is fetched */}
                         {!nextReferenceQuery.isFetching && !nextReferenceQuery.isError && nextReferenceQuery.data && (
                              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                                   {helpers => {
                                        return (
                                             <Form noValidate autoComplete="off">
                                                  {helpers.status && (
                                                       <MyAlert type={"danger"} classNames={"mb-4"}>
                                                            {helpers.status}
                                                       </MyAlert>
                                                  )}

                                                  {/* Category (fille, enfant, indép) */}
                                                  <h6 className={"mb-4"}>Commencez par choisir une catégorie</h6>
                                                  <div className="row mb-4">
                                                       <div className="col-md-4 mb-2 mb-md-0">
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"categorie"}
                                                                 value={CONST_CLIENT_PROPAL_CATEGORIE_MERE}
                                                                 isChecked={helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_MERE}
                                                                 label={"Mère"}
                                                                 description={"Proposition regroupant plusieurs propositions"}
                                                                 iconFontAwesomeClassName={"folder"}
                                                                 color={"primary"}
                                                                 isInvalid={!!helpers.errors.categorie}
                                                                 onChange={val => helpers.setFieldValue("categorie", val)}
                                                            />
                                                       </div>
                                                       <div className="col-md-4 mb-2 mb-md-0">
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"categorie"}
                                                                 value={CONST_CLIENT_PROPAL_CATEGORIE_FILLE}
                                                                 isChecked={helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_FILLE}
                                                                 label={"Enfant"}
                                                                 description={"Proposition contenue dans une proposition mère"}
                                                                 iconFontAwesomeClassName={"file-export"}
                                                                 color={"success"}
                                                                 isInvalid={!!helpers.errors.categorie}
                                                                 onChange={val => helpers.setFieldValue("categorie", val)}
                                                            />
                                                       </div>
                                                       <div className="col-md-4">
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"categorie"}
                                                                 value={CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE}
                                                                 isChecked={helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE}
                                                                 label={"Indépendante"}
                                                                 description={"Proposition n'ayant aucun lien avec d'autres propositions"}
                                                                 iconFontAwesomeClassName={"file"}
                                                                 color={"info"}
                                                                 isInvalid={!!helpers.errors.categorie}
                                                                 onChange={val => helpers.setFieldValue("categorie", val)}
                                                            />
                                                       </div>
                                                  </div>

                                                  <div className="separator mb-5"></div>

                                                  {helpers.values.categorie && (
                                                       <div className={"p-4 border border-dashed mb-4"}>
                                                            <h4 className={"mb-4 d-flex align-items-center cursor-pointer"} onClick={() => handleCopyRef()}>
                                                                 <MyTooltip title={"Copier la référence"}>
                                                                      <span>
                                                                           {nextReferenceQuery.data.ref}-1A <i className={"fas fa-copy text-dark fs-4 ms-1"} />
                                                                      </span>
                                                                 </MyTooltip>
                                                            </h4>
                                                            <div className="separator separator-dashed mb-6" />

                                                            {/* Information PTF enfant */}
                                                            {helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_FILLE && (
                                                                 <MyAlert type={"primary"} classNames={"mb-4"}>
                                                                      <>Avant de continuer, assurez vous d'avoir créer la PTF mère</>
                                                                 </MyAlert>
                                                            )}

                                                            {/* Objet */}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre de la proposition</label>

                                                                 <div className="col-lg-8">
                                                                      <MyInputField
                                                                           name={"objet"}
                                                                           value={helpers.values.objet}
                                                                           isInvalid={!!helpers.errors.objet}
                                                                           onChange={val => helpers.setFieldValue("objet", val)}
                                                                           placeholder={"Titre"}
                                                                      />
                                                                      {helpers.errors.objet && <div className={"text-danger"}>{helpers.errors.objet}</div>}
                                                                 </div>
                                                            </div>

                                                            {/* Type (only for PTF mère & indép.) */}
                                                            {(helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_MERE ||
                                                                 helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE) && (
                                                                 <>
                                                                      {/* Etat */}
                                                                      <div className="row mb-4">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">État</label>

                                                                           <div className="col-lg-8">
                                                                                <MySelectField
                                                                                     name={"etat"}
                                                                                     options={[
                                                                                          {
                                                                                               value: CONST_CLIENT_PROPAL_ETAT_EN_COURS,
                                                                                               label: "En cours de négociation",
                                                                                          },
                                                                                          {
                                                                                               value: CONST_CLIENT_PROPAL_ETAT_VALIDEE,
                                                                                               label: "Validée",
                                                                                          },
                                                                                          {
                                                                                               value: CONST_CLIENT_PROPAL_ETAT_REFUSEE,
                                                                                               label: "Rejetée par le client",
                                                                                          },
                                                                                     ]}
                                                                                     value={helpers.values.etat}
                                                                                     isInvalid={!!helpers.errors.etat}
                                                                                     onChange={val => helpers.setFieldValue("etat", val)}
                                                                                />
                                                                                {helpers.errors.etat && <div className={"text-danger"}>{helpers.errors.etat}</div>}
                                                                           </div>
                                                                      </div>

                                                                      {/* Type */}
                                                                      <div className="row mb-4">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Type</label>

                                                                           <div className="col-lg-8">
                                                                                <MySelectField
                                                                                     name={"type"}
                                                                                     options={[
                                                                                          {
                                                                                               value: CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE,
                                                                                               label: "Assistance technique",
                                                                                          },
                                                                                          {
                                                                                               value: CONST_CLIENT_PROPAL_TYPE_FORFAIT,
                                                                                               label: "Forfait",
                                                                                          },
                                                                                     ]}
                                                                                     value={helpers.values.type}
                                                                                     isInvalid={!!helpers.errors.type}
                                                                                     onChange={val => helpers.setFieldValue("type", val)}
                                                                                />
                                                                                {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}
                                                                           </div>
                                                                      </div>

                                                                      {helpers.values.type && (
                                                                           <>
                                                                                {/* Choose between generate PTF or saisie manuelle in case of AT*/}
                                                                                {helpers.values.type === CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE && (
                                                                                     <div className="row mb-4">
                                                                                          <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                               Voulez-vous générer la PTF?
                                                                                          </label>

                                                                                          <div className="col-lg-8 row d-flex align-items-center">
                                                                                               <div className="col-12 mb-2">
                                                                                                    <MyRadioWithLabelAndDescriptionField
                                                                                                         name={"documentGeneratedOrSaisieManuelle"}
                                                                                                         value={"SAISIE"}
                                                                                                         isChecked={helpers.values.documentGeneratedOrSaisieManuelle === "SAISIE"}
                                                                                                         label={"Saisie manuelle"}
                                                                                                         description={"Joignez votre PTF"}
                                                                                                         iconFontAwesomeClassName={"upload"}
                                                                                                         color={"primary"}
                                                                                                         isInvalid={!!helpers.errors.documentGeneratedOrSaisieManuelle}
                                                                                                         onChange={val =>
                                                                                                              helpers.setFieldValue("documentGeneratedOrSaisieManuelle", val)
                                                                                                         }
                                                                                                    />
                                                                                               </div>
                                                                                               <div className="col-12">
                                                                                                    <MyRadioWithLabelAndDescriptionField
                                                                                                         name={"documentGeneratedOrSaisieManuelle"}
                                                                                                         value={"GENERATION"}
                                                                                                         isChecked={
                                                                                                              helpers.values.documentGeneratedOrSaisieManuelle === "GENERATION"
                                                                                                         }
                                                                                                         label={"Génération automatique (recommandé)"}
                                                                                                         description={"Générer une PTF pré-remplie éditable en ligne"}
                                                                                                         iconFontAwesomeClassName={"bolt"}
                                                                                                         color={"success"}
                                                                                                         isInvalid={!!helpers.errors.documentGeneratedOrSaisieManuelle}
                                                                                                         onChange={val =>
                                                                                                              helpers.setFieldValue("documentGeneratedOrSaisieManuelle", val)
                                                                                                         }
                                                                                                    />
                                                                                               </div>
                                                                                               {helpers.errors.documentGeneratedOrSaisieManuelle && (
                                                                                                    <div className={"text-danger"}>
                                                                                                         {helpers.errors.documentGeneratedOrSaisieManuelle}
                                                                                                    </div>
                                                                                               )}
                                                                                          </div>
                                                                                     </div>
                                                                                )}

                                                                                {/* Attach PTF doc in case of forfait or AT without generation*/}
                                                                                {helpers.values.type === CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE &&
                                                                                     helpers.values.documentGeneratedOrSaisieManuelle === "GENERATION" && (
                                                                                          <MyAlert type={"success"} classNames={"mb-4"}>
                                                                                               <>
                                                                                                    Vous avez choisi la génération automatique de la PTF. Vous pourrez visualiser et
                                                                                                    compléter celle-ci une fois le formulaire soumis.
                                                                                               </>
                                                                                          </MyAlert>
                                                                                     )}

                                                                                {/* Attach PTF doc in case of forfait or AT without generation*/}
                                                                                {(helpers.values.type === CONST_CLIENT_PROPAL_TYPE_FORFAIT ||
                                                                                     (helpers.values.type === CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE &&
                                                                                          helpers.values.documentGeneratedOrSaisieManuelle === "SAISIE")) && (
                                                                                     <>
                                                                                          <MyAlert type={"primary"} classNames={"mb-4"}>
                                                                                               <>
                                                                                                    N'oubliez pas d'utiliser la référence{" "}
                                                                                                    <span
                                                                                                         className={"fw-bold text-primary cursor-pointer"}
                                                                                                         onClick={() => handleCopyRef()}
                                                                                                    >
                                                                                                         {nextReferenceQuery.data.ref}A
                                                                                                    </span>{" "}
                                                                                                    avant de joindre votre document
                                                                                               </>
                                                                                          </MyAlert>
                                                                                          <div className="row mb-4">
                                                                                               <label className="col-lg-4 col-form-label fw-bold fs-6 required">Document PTF</label>
                                                                                               <div className="col-lg-8">
                                                                                                    <MyFileField
                                                                                                         name={"documentPtf"}
                                                                                                         isInvalid={!!helpers.errors.documentPtf}
                                                                                                         onChange={val => helpers.setFieldValue("documentPtf", val)}
                                                                                                         accept={".docx"}
                                                                                                    />
                                                                                                    {helpers.errors.documentPtf && (
                                                                                                         <div className={"text-danger"}>{helpers.errors.documentPtf}</div>
                                                                                                    )}
                                                                                               </div>
                                                                                          </div>
                                                                                     </>
                                                                                )}

                                                                                {(helpers.values.type === CONST_CLIENT_PROPAL_TYPE_FORFAIT ||
                                                                                     (helpers.values.type === CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE &&
                                                                                          helpers.values.documentGeneratedOrSaisieManuelle)) && (
                                                                                     <>
                                                                                          {/* Entité de rattachement */}
                                                                                          <div className="row mb-4">
                                                                                               <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                    Entité de rattachement
                                                                                               </label>

                                                                                               <div className="col-lg-8">
                                                                                                    <MySelectGetAllDataFromServer
                                                                                                         name={"entiteRattachement"}
                                                                                                         value={helpers.values.entiteRattachement}
                                                                                                         isInvalid={!!helpers.errors.entiteRattachement}
                                                                                                         onChange={val => helpers.setFieldValue("entiteRattachement", val)}
                                                                                                         url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                                                                    />
                                                                                                    {helpers.errors.entiteRattachement && (
                                                                                                         <div className={"text-danger"}>{helpers.errors.entiteRattachement}</div>
                                                                                                    )}
                                                                                               </div>
                                                                                          </div>

                                                                                          {/* Entité de facturation */}
                                                                                          <div className="row mb-4">
                                                                                               <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                    Entité de facturation
                                                                                               </label>

                                                                                               <div className="col-lg-8">
                                                                                                    <MySelectGetAllDataFromServer
                                                                                                         name={"entiteFacturation"}
                                                                                                         value={helpers.values.entiteFacturation}
                                                                                                         isInvalid={!!helpers.errors.entiteFacturation}
                                                                                                         onChange={val => helpers.setFieldValue("entiteFacturation", val)}
                                                                                                         url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                                                                    />
                                                                                                    {helpers.errors.entiteFacturation && (
                                                                                                         <div className={"text-danger"}>{helpers.errors.entiteFacturation}</div>
                                                                                                    )}
                                                                                               </div>
                                                                                          </div>

                                                                                          {/* Période */}
                                                                                          <div className="row mb-4">
                                                                                               <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                                                               <div className="col-lg-8">
                                                                                                    <div className="d-flex">
                                                                                                         <MyDateDayMonthYearField
                                                                                                              name={"dateDebut"}
                                                                                                              value={helpers.values.dateDebut}
                                                                                                              isInvalid={!!helpers.errors.dateDebut}
                                                                                                              maxDate={helpers.values.dateFin}
                                                                                                              onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                                                                              placeholder={"Date de début"}
                                                                                                              classNames={"rounded-0 rounded-start"}
                                                                                                         />
                                                                                                         <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                                                         <MyDateDayMonthYearField
                                                                                                              name={"dateFin"}
                                                                                                              value={helpers.values.dateFin}
                                                                                                              minDate={helpers.values.dateDebut}
                                                                                                              isInvalid={!!helpers.errors.dateFin}
                                                                                                              onChange={val => helpers.setFieldValue("dateFin", val)}
                                                                                                              placeholder={"Date de fin"}
                                                                                                              classNames={"rounded-0 rounded-end"}
                                                                                                         />
                                                                                                    </div>
                                                                                                    {helpers.errors.dateDebut && (
                                                                                                         <div className={"text-danger"}>{helpers.errors.dateDebut}</div>
                                                                                                    )}
                                                                                                    {helpers.errors.dateFin && (
                                                                                                         <div className={"text-danger"}>{helpers.errors.dateFin}</div>
                                                                                                    )}
                                                                                               </div>
                                                                                          </div>

                                                                                          {/* TJM */}
                                                                                          {helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE &&
                                                                                               helpers.values.type === CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE && (
                                                                                                    <div className="row mb-4">
                                                                                                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                              Taux de vente journalier
                                                                                                         </label>

                                                                                                         <div className="col-lg-8">
                                                                                                              <MyInputField
                                                                                                                   name={"tjm"}
                                                                                                                   type={"number"}
                                                                                                                   inputMode={"numeric"}
                                                                                                                   value={helpers.values.tjm}
                                                                                                                   isInvalid={!!helpers.errors.tjm}
                                                                                                                   onChange={val => helpers.setFieldValue("tjm", val)}
                                                                                                                   placeholder={"Taux de vente journalier"}
                                                                                                              />
                                                                                                              {helpers.errors.tjm && (
                                                                                                                   <div className={"text-danger"}>{helpers.errors.tjm}</div>
                                                                                                              )}
                                                                                                         </div>
                                                                                                    </div>
                                                                                               )}

                                                                                          {/* INFORMATION CLIENT*/}
                                                                                          <div className={"p-4 border border-dashed mb-4"}>
                                                                                               <h4 className={"mb-4"}>Informations client</h4>
                                                                                               <div className="separator separator-dashed mb-6" />

                                                                                               {/* Client */}
                                                                                               <div className="row mb-4">
                                                                                                    <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                         Choisissez le client
                                                                                                    </label>

                                                                                                    <div className="col-lg-8">
                                                                                                         <MySelectGetAllDataFromServer
                                                                                                              name={"client"}
                                                                                                              value={helpers.values.client}
                                                                                                              isInvalid={!!helpers.errors.client}
                                                                                                              onChange={val => {
                                                                                                                   helpers.setFieldValue("client", val).then(() => {
                                                                                                                        helpers.setFieldValue("clientAdresse", undefined).then()
                                                                                                                        helpers.setFieldValue("clientResponsable", undefined).then()
                                                                                                                   })
                                                                                                              }}
                                                                                                              url={CONST_API_UTILS_GET_CLIENTS_ENDPOINT}
                                                                                                         />
                                                                                                         <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                                              {helpers.errors.client && (
                                                                                                                   <div className={"text-danger"}>{helpers.errors.client}</div>
                                                                                                              )}
                                                                                                              <div
                                                                                                                   className="text-primary fw-bold cursor-pointer"
                                                                                                                   onClick={() => setIsAddingClient(true)}
                                                                                                              >
                                                                                                                   Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                                                              </div>
                                                                                                         </div>
                                                                                                    </div>
                                                                                               </div>

                                                                                               {helpers.values.client && (
                                                                                                    <>
                                                                                                         {/* Client adresse*/}
                                                                                                         <div className="row mb-4">
                                                                                                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                                   Adresse
                                                                                                              </label>

                                                                                                              <div className="col-lg-8">
                                                                                                                   <MySelectGetAllDataFromServer
                                                                                                                        name={"clientAdresse"}
                                                                                                                        value={helpers.values.clientAdresse}
                                                                                                                        isInvalid={!!helpers.errors.clientAdresse}
                                                                                                                        onChange={val =>
                                                                                                                             helpers.setFieldValue("clientAdresse", val)
                                                                                                                        }
                                                                                                                        url={CONST_API_UTILS_GET_CLIENT_ADRESSES_ENDPOINT(
                                                                                                                             helpers.values.client
                                                                                                                        )}
                                                                                                                   />
                                                                                                                   <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                                                        {helpers.errors.clientAdresse && (
                                                                                                                             <div className={"text-danger"}>
                                                                                                                                  {helpers.errors.clientAdresse}
                                                                                                                             </div>
                                                                                                                        )}
                                                                                                                        <div
                                                                                                                             className="text-primary fw-bold cursor-pointer"
                                                                                                                             onClick={() => setIsAddingAdresseClient(true)}
                                                                                                                        >
                                                                                                                             Ajouter{" "}
                                                                                                                             <i className={"fas fa-plus-circle text-primary"} />
                                                                                                                        </div>
                                                                                                                   </div>
                                                                                                              </div>
                                                                                                         </div>

                                                                                                         {/* Client responsable*/}
                                                                                                         <div className="row mb-4">
                                                                                                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                                   Responsable
                                                                                                              </label>

                                                                                                              <div className="col-lg-8">
                                                                                                                   <MySelectGetAllDataFromServer
                                                                                                                        name={"clientResponsable"}
                                                                                                                        value={helpers.values.clientResponsable}
                                                                                                                        isInvalid={!!helpers.errors.clientResponsable}
                                                                                                                        onChange={val =>
                                                                                                                             helpers.setFieldValue("clientResponsable", val)
                                                                                                                        }
                                                                                                                        url={CONST_API_UTILS_GET_CLIENT_RESPONSABLES_ENDPOINT(
                                                                                                                             helpers.values.client
                                                                                                                        )}
                                                                                                                   />
                                                                                                                   <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                                                        {helpers.errors.clientResponsable && (
                                                                                                                             <div className={"text-danger"}>
                                                                                                                                  {helpers.errors.clientResponsable}
                                                                                                                             </div>
                                                                                                                        )}
                                                                                                                        <div
                                                                                                                             className="text-primary fw-bold cursor-pointer"
                                                                                                                             onClick={() => setIsAddingRespClient(true)}
                                                                                                                        >
                                                                                                                             Ajouter{" "}
                                                                                                                             <i className={"fas fa-plus-circle text-primary"} />
                                                                                                                        </div>
                                                                                                                   </div>
                                                                                                              </div>
                                                                                                         </div>
                                                                                                    </>
                                                                                               )}
                                                                                          </div>

                                                                                          {/* BON DE COMMANDE*/}
                                                                                          {helpers.values.etat === CONST_CLIENT_PROPAL_ETAT_VALIDEE && (
                                                                                               <div className={"p-4 border border-dashed mb-4"}>
                                                                                                    <h4 className={"mb-4"}>Bon de commande / Accord client</h4>
                                                                                                    <div className="separator separator-dashed mb-6" />

                                                                                                    {/* Type */}
                                                                                                    <div className="row mb-4">
                                                                                                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                              Type
                                                                                                         </label>

                                                                                                         <div className="col-lg-8">
                                                                                                              <MySelectField
                                                                                                                   name={"bonCommandeType"}
                                                                                                                   options={[
                                                                                                                        {
                                                                                                                             value: CONST_CLIENT_BON_COMMANDE_TYPE_MAIL_CONFIRMATION,
                                                                                                                             label: "Mail de confirmation / accord client",
                                                                                                                        },
                                                                                                                        {
                                                                                                                             value: CONST_CLIENT_BON_COMMANDE_TYPE_BC_ORIGINAL,
                                                                                                                             label: "Bon de commande original",
                                                                                                                        },
                                                                                                                   ]}
                                                                                                                   value={helpers.values.bonCommandeType}
                                                                                                                   isInvalid={!!helpers.errors.bonCommandeType}
                                                                                                                   onChange={val => helpers.setFieldValue("bonCommandeType", val)}
                                                                                                              />
                                                                                                              {helpers.errors.bonCommandeType && (
                                                                                                                   <div className={"text-danger"}>
                                                                                                                        {helpers.errors.bonCommandeType}
                                                                                                                   </div>
                                                                                                              )}
                                                                                                         </div>
                                                                                                    </div>

                                                                                                    {/* Période */}
                                                                                                    <div className="row mb-4">
                                                                                                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                              Période
                                                                                                         </label>

                                                                                                         <div className="col-lg-8">
                                                                                                              <div className="d-flex">
                                                                                                                   <MyDateDayMonthYearField
                                                                                                                        name={"bonCommandeDateDebut"}
                                                                                                                        value={helpers.values.bonCommandeDateDebut}
                                                                                                                        isInvalid={!!helpers.errors.bonCommandeDateDebut}
                                                                                                                        minDate={helpers.values.dateDebut}
                                                                                                                        maxDate={helpers.values.bonCommandeDateFin}
                                                                                                                        onChange={val =>
                                                                                                                             helpers.setFieldValue("bonCommandeDateDebut", val)
                                                                                                                        }
                                                                                                                        placeholder={"Date de début"}
                                                                                                                        classNames={"rounded-0 rounded-start"}
                                                                                                                   />
                                                                                                                   <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                                                                   <MyDateDayMonthYearField
                                                                                                                        name={"bonCommandeDateFin"}
                                                                                                                        value={helpers.values.bonCommandeDateFin}
                                                                                                                        minDate={helpers.values.bonCommandeDateDebut}
                                                                                                                        maxDate={helpers.values.dateFin}
                                                                                                                        isInvalid={!!helpers.errors.bonCommandeDateFin}
                                                                                                                        onChange={val =>
                                                                                                                             helpers.setFieldValue("bonCommandeDateFin", val)
                                                                                                                        }
                                                                                                                        placeholder={"Date de fin"}
                                                                                                                        classNames={"rounded-0 rounded-end"}
                                                                                                                   />
                                                                                                              </div>
                                                                                                              {helpers.errors.bonCommandeDateDebut && (
                                                                                                                   <div className={"text-danger"}>
                                                                                                                        {helpers.errors.bonCommandeDateDebut}
                                                                                                                   </div>
                                                                                                              )}
                                                                                                              {helpers.errors.bonCommandeDateFin && (
                                                                                                                   <div className={"text-danger"}>
                                                                                                                        {helpers.errors.bonCommandeDateFin}
                                                                                                                   </div>
                                                                                                              )}
                                                                                                         </div>
                                                                                                    </div>

                                                                                                    {/* Document BC */}
                                                                                                    <div className="row mb-4">
                                                                                                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                                              BC / Accord client
                                                                                                         </label>
                                                                                                         <div className="col-lg-8">
                                                                                                              <MyFileField
                                                                                                                   name={"bonCommandeDocument"}
                                                                                                                   isInvalid={!!helpers.errors.bonCommandeDocument}
                                                                                                                   onChange={val =>
                                                                                                                        helpers.setFieldValue("bonCommandeDocument", val)
                                                                                                                   }
                                                                                                              />
                                                                                                              {helpers.errors.bonCommandeDocument && (
                                                                                                                   <div className={"text-danger"}>
                                                                                                                        {helpers.errors.bonCommandeDocument}
                                                                                                                   </div>
                                                                                                              )}
                                                                                                         </div>
                                                                                                    </div>

                                                                                                    {/* CDC  */}
                                                                                                    <div className="row mb-4">
                                                                                                         <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                                                                              Cahier des charges
                                                                                                         </label>
                                                                                                         <div className="col-lg-8">
                                                                                                              <MyFileField
                                                                                                                   name={"documentCdc"}
                                                                                                                   isInvalid={!!helpers.errors.documentCdc}
                                                                                                                   onChange={val => helpers.setFieldValue("documentCdc", val)}
                                                                                                              />
                                                                                                              {helpers.errors.documentCdc && (
                                                                                                                   <div className={"text-danger"}>{helpers.errors.documentCdc}</div>
                                                                                                              )}
                                                                                                         </div>
                                                                                                    </div>
                                                                                               </div>
                                                                                          )}
                                                                                     </>
                                                                                )}
                                                                           </>
                                                                      )}
                                                                 </>
                                                            )}

                                                            {/* PTF Fille: fields TJM & PTF mère */}
                                                            {helpers.values.categorie === CONST_CLIENT_PROPAL_CATEGORIE_FILLE && (
                                                                 <>
                                                                      <div className="row mb-4">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Taux de vente journalier</label>

                                                                           <div className="col-lg-8">
                                                                                <MyInputField
                                                                                     name={"tjm"}
                                                                                     type={"number"}
                                                                                     inputMode={"numeric"}
                                                                                     value={helpers.values.tjm}
                                                                                     isInvalid={!!helpers.errors.tjm}
                                                                                     onChange={val => helpers.setFieldValue("tjm", val)}
                                                                                     placeholder={"Taux de vente journalier"}
                                                                                />
                                                                                {helpers.errors.tjm && <div className={"text-danger"}>{helpers.errors.tjm}</div>}
                                                                           </div>
                                                                      </div>
                                                                      <div className="row mb-4">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">PTF mère</label>

                                                                           <div className="col-lg-8">
                                                                                <MySelectGetAllDataFromServer
                                                                                     name={"ptfMere"}
                                                                                     value={helpers.values.ptfMere}
                                                                                     isInvalid={!!helpers.errors.ptfMere}
                                                                                     onChange={val => helpers.setFieldValue("ptfMere", val)}
                                                                                     url={CONST_API_UTILS_GET_PTFS_MERE_ENDPOINT}
                                                                                     isClearable={false}
                                                                                />
                                                                                {helpers.errors.ptfMere && <div className={"text-danger"}>{helpers.errors.ptfMere}</div>}
                                                                           </div>
                                                                      </div>
                                                                 </>
                                                            )}
                                                       </div>
                                                  )}

                                                  {helpers.values.categorie && (
                                                       <>
                                                            {/* Submit button */}
                                                            <div className={"d-flex justify-content-end"}>
                                                                 <button
                                                                      className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                                      type={"submit"}
                                                                      disabled={helpers.isSubmitting}
                                                                 >
                                                                      {!helpers.isSubmitting ? (
                                                                           <>
                                                                                Créer <i className={"fas fa-plus fs-4 ms-1"} />
                                                                           </>
                                                                      ) : (
                                                                           <MySimpleSpinner size={"sm"} />
                                                                      )}
                                                                 </button>
                                                            </div>
                                                       </>
                                                  )}
                                             </Form>
                                        )
                                   }}
                              </Formik>
                         )}
                    </MyCard.Body>
               </MyCard>

               {/* Modal add client */}
               {isAddingClient && (
                    <MyModal title={<>Nouveau client</>} show={true} handleClose={() => setIsAddingClient(false)}>
                         <AddClientForm callback={() => setIsAddingClient(false)} />
                    </MyModal>
               )}

               {/* Modal add resp client */}
               {isAddingRespClient && (
                    <MyModal title={<>Nouveau responsable client</>} show={true} handleClose={() => setIsAddingRespClient(false)}>
                         <AddResponsableClientForm callback={() => setIsAddingRespClient(false)} />
                    </MyModal>
               )}

               {/* Modal add adresse client */}
               {isAddingAdresseClient && (
                    <MyModal title={<>Nouvelle adresse client</>} show={true} handleClose={() => setIsAddingAdresseClient(false)} size={"lg"}>
                         <AddAdresseClientForm callback={() => setIsAddingAdresseClient(false)} />
                    </MyModal>
               )}
          </div>
     )
}

export default PtfCreate
