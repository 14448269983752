import React from "react"
import MyTooltip from "@common-utils/MyTooltip"

type Props = {
     page: number // Current Page
     pageStart: number // First element of the current page
     pageEnd: number // Last element of the current page
     totalPages: number // Number of total pages available
     totalElementsInAllPages: number // Total elements in all pages
     onPreviousPageCallBack: () => void // Callback when clicking previous page
     onNextPageCallBack: () => void // Callback when clicking next page
}

// This is the standard pagination. You should not use it if you want to a custom one
const MyPagination: React.FC<Props> = ({ page, pageStart, pageEnd, totalPages, totalElementsInAllPages, onPreviousPageCallBack, onNextPageCallBack }) => {
     return (
          <>
               {/* Represented like this: Page start - Page length sur Total records available ---> 1 - 20 sur 54*/}
               <MyTooltip title={<>Page {page}</>}>
                    <div className={"me-3"}>
                         {pageStart} - {pageEnd} sur {totalElementsInAllPages}
                    </div>
               </MyTooltip>

               {/* Navigates to first page & previous page */}
               <MyTooltip title={"Page précédente"}>
                    <button className={"btn btn-sm p-0 py-3 px-4"} onClick={() => onPreviousPageCallBack()} disabled={page == 1}>
                         <i className={"fas fa-angle-left text-dark fs-1"} />
                    </button>
               </MyTooltip>
               {/* Navigates to next page & last page */}
               <MyTooltip title={"Page suivante"}>
                    <button className={"btn btn-sm btn-active-light-primary p-0 py-3 px-4"} onClick={() => onNextPageCallBack()} disabled={page == totalPages}>
                         <i className={"fas fa-angle-right fs-1 text-dark"} />
                    </button>
               </MyTooltip>
          </>
     )
}

export default MyPagination
