import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { IHttpErrorResponseModel } from "@common-models/*"
import SavedFiltersSearchCandidateSimpleSearchBar from "./_SavedFiltersSearchCandidateSimpleSearchBar"
import SavedFiltersSearchCandidateFilters from "./_SavedFiltersSearchCandidateFilters"
import SavedFiltersSearchCandidateList from "./_list/_SavedFiltersSearchCandidateList"
import { ISavedFiltersSearchCandidateContextPropsModel, ISavedFiltersSearchCandidateRequestModel, ISavedFiltersSearchCandidateResponseModel } from "./core/_models"
import { getSavedFiltersSearchCandidate } from "./core/_requests"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../../../models"

// Wrapper's context
const SavedFiltersSearchCandidateContext = createContext<ISavedFiltersSearchCandidateContextPropsModel>({} as ISavedFiltersSearchCandidateContextPropsModel)
export const useSavedFiltersSearchCandidateContext = () => useContext(SavedFiltersSearchCandidateContext)

// Wrapper
const REACT_QUERY_KEY_SAVED_FILTERS = "REACT_QUERY_KEY_SAVED_FILTERS"
const INITIAL_FILTERS_STATE: ISavedFiltersSearchCandidateRequestModel = {
     page: 1,
     pageLength: 20,
}
const SavedFiltersSearchCandidate = () => {
     // Referencing both filters form and simple search bar form
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)

     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [filters, setFilters] = useState<ISavedFiltersSearchCandidateRequestModel>(INITIAL_FILTERS_STATE)

     // Main listing query
     const useSavedFiltersQuery = useQuery<ISavedFiltersSearchCandidateResponseModel, AxiosError>(REACT_QUERY_KEY_SAVED_FILTERS, () => {
          return getSavedFiltersSearchCandidate(filters)
               .then(r => {
                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters fomr
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Re-fetch main listing query on filters change
     useEffect(() => {
          useSavedFiltersQuery.refetch().then()
     }, [filters])

     return (
          <SavedFiltersSearchCandidateContext.Provider
               value={{
                    areFiltersVisible,
                    setAreFiltersVisible,
                    isBulkSelectionEnabled,
                    setIsBulkSelectionEnabled,
                    filters,
                    setFilters,
                    useSavedFiltersQuery,
                    filtersFormikRef,
                    simpleSearchBarFormikRef,
                    INITIAL_FILTERS_STATE,
                    REACT_QUERY_KEY_SAVED_FILTERS,
               }}
          >
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-xl-5 mb-4 mb-xl-0 ${!areFiltersVisible ? "d-none" : ""}`}>
                         <SavedFiltersSearchCandidateFilters />
                    </div>
                    <div className="col-xl col-xl-7">
                         {/* Simple bar search will be shown only if filters are not displayed */}
                         <div className={`mb-2 ${areFiltersVisible ? "d-none" : ""}`}>
                              <SavedFiltersSearchCandidateSimpleSearchBar />
                         </div>

                         <SavedFiltersSearchCandidateList />
                    </div>
               </div>
          </SavedFiltersSearchCandidateContext.Provider>
     )
}

export default SavedFiltersSearchCandidate
