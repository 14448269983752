import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useConnectedUserNdfDepensesContext } from "./_PersonnelNdfDepenses"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import MyAlert from "@common-utils/MyAlert"
import MyTooltip from "@common-utils/MyTooltip"
import { IPersonnelNdfDetailModel, IPersonnelNdfDocumentModel } from "@common-models/*"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"
import { deletePersonnelNdfDepenseRequest } from "../core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI } from "@common-constants/*"

export const PersonnelNdfDepensesListing = () => {
     const MySwal = withReactContent(Swal)
     const context = useConnectedUserNdfDepensesContext()
     const queryClient = useQueryClient()

     const deleteDetailsMutation = useMutation((id: number) => deletePersonnelNdfDepenseRequest(id))

     const [justifInModal, setJustifInModal] = useState<IPersonnelNdfDocumentModel>()

     function handleDeleteDetails(id: number) {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer cette dépense?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deleteDetailsMutation
                         .mutateAsync(id)
                         .then(() => {
                              queryClient.setQueryData([context.REACT_QUERY_KEY_GET_LISTING, context.ndfId], (details: IPersonnelNdfDetailModel[] | undefined) => {
                                   const dataLeft = details!.filter(item => item.id !== id)
                                   context.computeTotalExpenses(dataLeft)
                                   return dataLeft
                              })
                              toast.info("La dépense a bien été supprimée")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <>
               {!context.getNdfDepensesQuery.isFetching && context.getNdfDepensesQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{context.getNdfDepensesQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => context.getNdfDepensesQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {context.getNdfDepensesQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {!context.getNdfDepensesQuery.isFetching &&
                    !context.getNdfDepensesQuery.isError &&
                    context.getNdfDepensesQuery.data &&
                    context.getNdfDepensesQuery.data.length === 0 && (
                         <MyAlert type={"primary"} classNames={"mb-4"}>
                              <div>Aucune dépenses</div>
                         </MyAlert>
                    )}

               {!context.getNdfDepensesQuery.isFetching &&
                    !context.getNdfDepensesQuery.isError &&
                    context.getNdfDepensesQuery.data &&
                    context.getNdfDepensesQuery.data.length > 0 && (
                         <>
                              <div className={"row p-3"}>
                                   {context.getNdfDepensesQuery.data.map((item, key) => (
                                        <div className="card shadow-sm col-sm-6 col-lg-4 mb-4 p-0 pe-sm-2" key={key}>
                                             <div className="card-header bg-info border border-info" style={{ minHeight: "2rem" }}>
                                                  <h3 className="card-title text-white align-items-center">
                                                       <span className={"fas fa-file-invoice me-2"}></span>
                                                       {item.isParent ? `Dépenses groupées ( ${item.enfants?.length} )` : item.categorieLabel}
                                                  </h3>
                                             </div>
                                             <div className="card-body border-info border">
                                                  {!item.isParent && item.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                       <>
                                                            {!item.invites && (
                                                                 <MyAlert type={"danger"} classNames={"mb-4"}>
                                                                      <span className={"fw-bold"}>Repas seul</span>
                                                                 </MyAlert>
                                                            )}

                                                            {item.invites && (
                                                                 <MyAlert type={"primary"} classNames={"mb-4"}>
                                                                      <span className={"fw-bold"}>Repas en groupe</span>
                                                                 </MyAlert>
                                                            )}
                                                       </>
                                                  )}
                                                  <div className="d-flex justify-content-center">
                                                       {item.personnelNdfDocuments?.map((item_, key_) => (
                                                            <MyTooltip title={"Voir la pièce jointe"} key={key_}>
                                                                 <div
                                                                      className={`mb-4 d-flex justify-content-center fas fa-file-invoice cursor-pointer ${key_ > 0 ? "ms-4" : ""}`}
                                                                      style={{ fontSize: "2rem" }}
                                                                      onClick={() => setJustifInModal(item_)}
                                                                 />
                                                            </MyTooltip>
                                                       ))}
                                                  </div>

                                                  {/* Justificatif simple */}
                                                  {!item.isParent && (
                                                       <div className={"mb-4"}>
                                                            <div className={"block"}>
                                                                 <span className={"fw-bold"}>Date : </span> {item.date?.format("DD/MM/YYYY")}
                                                            </div>
                                                            {item.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && item.invites && (
                                                                 <div className={"block"}>
                                                                      <span className={"fw-bold"}>Invités : </span> {item.invites.map(item => item).join(", ")}
                                                                 </div>
                                                            )}
                                                            {item.description && (
                                                                 <MyTooltip title={item.description}>
                                                                      <div className={"block d-flex align-items-center cursor-default text-hover-dark"}>
                                                                           <span className={"fw-bold"}>Description : </span> <i className={"fas fa-eye fs-5 ms-2"} />
                                                                      </div>
                                                                 </MyTooltip>
                                                            )}
                                                       </div>
                                                  )}

                                                  {/* Justificatif groupée */}
                                                  {item.isParent && (
                                                       <>
                                                            {item.enfants && item.enfants.length > 0 && (
                                                                 <>
                                                                      {item.enfants.map((child, key) => (
                                                                           <div className={"mb-4"} key={key}>
                                                                                <div className={"block fs-5 border-bottom border-dark mb-1 fw-bold align-items-center"}>
                                                                                     Ticket n°{child.numeroTicket}
                                                                                </div>
                                                                                {child.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                                                     <div className={"my-2"}>
                                                                                          {!child.invites && (
                                                                                               <MyAlert type={"danger"}>
                                                                                                    <span className={"fw-bold"}>Repas seul</span>
                                                                                               </MyAlert>
                                                                                          )}

                                                                                          {child.invites && (
                                                                                               <MyAlert type={"primary"}>
                                                                                                    <span className={"fw-bold"}>Repas en groupe</span>
                                                                                               </MyAlert>
                                                                                          )}
                                                                                     </div>
                                                                                )}
                                                                                <div className={"block"}>
                                                                                     <span className={"fw-bold"}>Date : </span> {child.date!.format("dddd DD MMMM YYYY")}
                                                                                </div>
                                                                                <div className={"block"}>
                                                                                     <span className={"fw-bold"}>Catégorie : </span> {child.categorieLabel}
                                                                                </div>
                                                                                <div className={"block"}>
                                                                                     <span className={"fw-bold"}>Montant TTC : </span> {child.montantTtc} €
                                                                                </div>
                                                                                {child.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && child.invites && (
                                                                                     <div className={"block"}>
                                                                                          <span className={"fw-bold"}>Invités : </span>{" "}
                                                                                          {child.invites?.map(item => item).join(", ")}
                                                                                     </div>
                                                                                )}
                                                                                {child.description && (
                                                                                     <MyTooltip title={child.description}>
                                                                                          <div className={"block d-flex align-items-center cursor-default text-hover-dark"}>
                                                                                               <span className={"fw-bold"}>Description : </span>{" "}
                                                                                               <i className={"fas fa-eye fs-5 ms-2"} />
                                                                                          </div>
                                                                                     </MyTooltip>
                                                                                )}
                                                                           </div>
                                                                      ))}
                                                                 </>
                                                            )}
                                                       </>
                                                  )}

                                                  <div className="row border-top-dashed border-info pt-3">
                                                       <div className="col-6 p-0">
                                                            {context.editionMode && (
                                                                 <>
                                                                      <button
                                                                           type="button"
                                                                           className="btn btn-sm btn-warning p-0 px-3 py-1 fs-4 me-3"
                                                                           onClick={() => context.setCurrentDepenseInEdition(item)}
                                                                      >
                                                                           <span className={"fas fa-edit"} />
                                                                      </button>
                                                                      <button
                                                                           type="button"
                                                                           className="btn btn-sm btn-danger p-0 px-3 py-1 fs-4"
                                                                           onClick={() => handleDeleteDetails(item.id as number)}
                                                                      >
                                                                           <span className={"fas fa-trash"} />
                                                                      </button>
                                                                 </>
                                                            )}
                                                       </div>
                                                       <div className="col-6 fw-bold fs-6 d-flex align-items-center p-0 justify-content-end">
                                                            Total :{" "}
                                                            {item.isParent
                                                                 ? item.enfants
                                                                      ? Object.values(item.enfants.map(child => child.montantTtc as number))
                                                                             .reduce((acc, val) => acc + val, 0)
                                                                             .toFixed(2)
                                                                      : 0
                                                                 : item.montantTtc}{" "}
                                                            €
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   ))}
                              </div>
                              {context.getNdfDepensesQuery.data.length > 1 && (
                                   <div className="d-flex justify-content-end me-2">
                                        <div className={"alert alert-info px-2 py-1 text-dark fw-bold m-0"}>TOTAL: {context.totalDepenses.toFixed(2)} €</div>
                                   </div>
                              )}
                         </>
                    )}

               {/* Modal view justificatif*/}
               {justifInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={justifInModal.googleDriveID as string}
                         googleDriveMimetype={justifInModal.googleDriveMimeType as string}
                         modalTitle={<span>Justificatif</span>}
                         handleCloseModal={() => setJustifInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}
