import { PageTitle } from "@common-metronic/layout/core"
import { Outlet, Route, Routes } from "react-router-dom"
import Dashboard from "../Dashboard"
import DashboardAlertesMissionsExpirationListing from "../alertes/missionExpiration/DashboardAlertesMissionsExpirationListing"
import DashboardAlertesPeriodesEssaiExpirationListing from "../alertes/peridoeEssaiExpiration/DashboardAlertesPeriodesEssaiExpirationListing"
import DashboardAlertesDdcProductionListing from "../alertes/ddc/DashboardAlertesDdcProductionListing"
import {
     ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES,
     ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_DDC_PRODUCTION,
     ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_DDC_STRUCTURE,
     ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_MISSION_EXPIRATION,
     ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_PE_EXPIRATION,
} from "./routing-endpoints"
import { ROUTING_BREADCRUMBS_DASHBOARD, ROUTING_BREADCRUMBS_DASHBOARD_ALERTES } from "./routing-breadcrumbs"
import DashboardAlertesDdcStructureListing from "../alertes/ddc/DashboardAlertesDdcStructureListing"

const DashboardRouting = () => {
     return (
          <Routes>
               <Route element={<Outlet />}>
                    <Route
                         index
                         element={
                              <>
                                   <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_DASHBOARD}></PageTitle>
                                   <Dashboard />
                              </>
                         }
                    />

                    {/* BEGIN:: ALERTES */}
                    <Route path={ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES}>
                         <Route
                              path={ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_MISSION_EXPIRATION}
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_DASHBOARD_ALERTES}>Missions</PageTitle>
                                        <DashboardAlertesMissionsExpirationListing />
                                   </>
                              }
                         />
                         <Route
                              path={ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_PE_EXPIRATION}
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_DASHBOARD_ALERTES}>PE</PageTitle>
                                        <DashboardAlertesPeriodesEssaiExpirationListing />
                                   </>
                              }
                         />
                         <Route
                              path={ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_DDC_PRODUCTION}
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_DASHBOARD_ALERTES}>DDC (production)</PageTitle>
                                        <DashboardAlertesDdcProductionListing />
                                   </>
                              }
                         />
                         <Route
                              path={ROUTING_RELATIVE_PATH_DASHBOARD_ALERTES_DDC_STRUCTURE}
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_DASHBOARD_ALERTES}>DDC (structure)</PageTitle>
                                        <DashboardAlertesDdcStructureListing />
                                   </>
                              }
                         />
                    </Route>

                    {/* END:: ALERTES */}
               </Route>
          </Routes>
     )
}

export default DashboardRouting
