import axios from "axios"
import { CONST_API_UTILS_GET_CANDIDATES_URL_ENDPOINT, CONST_API_UTILS_GET_OFFERS_ENDPOINT, CONST_API_UTILS_GET_VIVIERS_ENDPOINT } from "../endpoints"

export function getCandidates(ids: number[]) {
     return axios.get<{ label: string; value: number }[]>(CONST_API_UTILS_GET_CANDIDATES_URL_ENDPOINT, {
          params: { ids },
     })
}

export function getViviers(ids: number[]) {
     return axios.get<{ label: string; options: { label: string; value: number }[] }[]>(CONST_API_UTILS_GET_VIVIERS_ENDPOINT, {
          params: { ids },
     })
}

export function getOffers(ids: number[]) {
     return axios.get<{ label: string; options: { label: string; value: number }[] }[]>(CONST_API_UTILS_GET_OFFERS_ENDPOINT, {
          params: { ids },
     })
}
