import axios from "axios"
import {
     IAddAdresseClientRequestModel,
     IAddClientRequestModel,
     IAddFournisseurRequestModel,
     IAddMachineRequestModel,
     IAddMetierRequestModel,
     IAddResponsableClientRequestModel,
     IAddResponsableFournisseurRequestModel,
} from "./_models"
import {
     CONST_API_UTILS_POST_ADD_ADRESSE_CLIENT_ENDPOINT,
     CONST_API_UTILS_POST_ADD_CLIENT_ENDPOINT,
     CONST_API_UTILS_POST_ADD_FOURNISSEUR_ENDPOINT,
     CONST_API_UTILS_POST_ADD_FOURNISSEUR_RESP_ENDPOINT,
     CONST_API_UTILS_POST_ADD_MACHINE_ENDPOINT,
     CONST_API_UTILS_POST_ADD_METIER_ENDPOINT,
     CONST_API_UTILS_POST_ADD_RESPONSABLE_CLIENT_ENDPOINT,
} from "../../../endpoints"

export function addMetierRequest(payload: IAddMetierRequestModel) {
     return axios.post(CONST_API_UTILS_POST_ADD_METIER_ENDPOINT, payload)
}

export function addMachineRequest(payload: IAddMachineRequestModel) {
     return axios.post(CONST_API_UTILS_POST_ADD_MACHINE_ENDPOINT, payload)
}

export function addFournisseurRequest(payload: IAddFournisseurRequestModel) {
     return axios.post(CONST_API_UTILS_POST_ADD_FOURNISSEUR_ENDPOINT, payload)
}

export function addClientRequest(payload: IAddClientRequestModel) {
     return axios.post(CONST_API_UTILS_POST_ADD_CLIENT_ENDPOINT, payload)
}

export function addResponsableClientRequest(payload: IAddResponsableClientRequestModel) {
     return axios.post(CONST_API_UTILS_POST_ADD_RESPONSABLE_CLIENT_ENDPOINT, payload)
}

export function addResponsableFournisseurRequest(payload: IAddResponsableFournisseurRequestModel) {
     return axios.post(CONST_API_UTILS_POST_ADD_FOURNISSEUR_RESP_ENDPOINT, payload)
}

export function addAdresseClientRequest(payload: IAddAdresseClientRequestModel) {
     return axios.post(CONST_API_UTILS_POST_ADD_ADRESSE_CLIENT_ENDPOINT, payload)
}
