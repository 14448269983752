import React from "react"
import { useConnectedUserDeclarationDetails } from "../ConnectedPersonnelDeclarationDetails"
import Footer from "./_Footer"
import { useMutation, useQuery } from "react-query"
import { IPersonnelNdfModel } from "@common-models/*"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { toast } from "react-toastify"
import { getPersonnelDeclarationActiveNdfRequest } from "../../../../declaration/core/_requests"
import { createConnectedUserNdfRequest } from "../../../ndf/core/_requests"
import { PersonnelNdfDepenses } from "../../../../declaration/ndf/depenses/_PersonnelNdfDepenses"

const REACT_QUERY_KEY_GET_ACTIVE_NDF = "REACT_QUERY_KEY_GET_ACTIVE_NDF"
export const ConnectedUserDeclarationDetails_ndf = () => {
     const context = useConnectedUserDeclarationDetails()

     const initNdfMutation = useMutation(({ mois, annee }: { mois: string; annee: string }) => createConnectedUserNdfRequest({ mois, annee }))

     const activeNdfQuery = useQuery<IPersonnelNdfModel, AxiosError>(REACT_QUERY_KEY_GET_ACTIVE_NDF, () =>
          getPersonnelDeclarationActiveNdfRequest(context.data.declaration?.id as number).then(r => r.data)
     )

     // Init NDF if not created
     function handleInitNdf() {
          initNdfMutation
               .mutateAsync({ mois: context.data.declaration?.mois?.format("MM") as string, annee: context.data.declaration?.mois?.format("YYYY") as string })
               .then(() => {
                    activeNdfQuery.refetch().then()
               })
               .catch(e => {
                    toast.error(e?.response?.data?.detail, { autoClose: false })
               })
     }

     return (
          <>
               {/* loader */}
               {activeNdfQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!activeNdfQuery.isFetching && activeNdfQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{activeNdfQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => activeNdfQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!activeNdfQuery.isFetching && !activeNdfQuery.isError && (
                    <>
                         {!activeNdfQuery.data && (
                              <>
                                   {context.data.declaration?.modifiable && (
                                        <div className={"text-center"}>
                                             <h5 className={"mb-2"}>Avez-vous une note de frais à déclarer?</h5>
                                             <button type={"button"} className={"btn btn-sm btn-primary me-4"} onClick={() => handleInitNdf()} disabled={initNdfMutation.isLoading}>
                                                  {!initNdfMutation.isLoading ? "OUI" : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                             <button
                                                  type={"button"}
                                                  className={"btn btn-sm btn-outline btn-outline-primary"}
                                                  onClick={() => context.setCurrentStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ticket_resto")}
                                                  disabled={initNdfMutation.isLoading}
                                             >
                                                  NON
                                             </button>
                                        </div>
                                   )}

                                   {!context.data.declaration?.modifiable && <MyAlert type={"primary"}>Aucune NDF n'a été déclarée</MyAlert>}
                              </>
                         )}

                         {activeNdfQuery.data && (
                              <>
                                   <PersonnelNdfDepenses ndfId={activeNdfQuery.data.id as number} editionMode={context.editionModeEnabled} />
                              </>
                         )}
                    </>
               )}

               <div className="separator separator-dashed my-6" />

               <Footer />
          </>
     )
}
