import axios from "axios"
import {
     CONST_API_RECRUITMENT_OFFERS_GET_DETAILS_ENDPOINT,
     CONST_API_RECRUITMENT_OFFERS_GET_GENERATE_TALENTPLUG_URL_CREATION_ENDPOINT,
     CONST_API_RECRUITMENT_OFFERS_GET_GENERATE_TALENTPLUG_URL_EDITION_ENDPOINT,
     CONST_API_RECRUITMENT_OFFERS_PUT_ARCHIVE_ENDPOINT,
     CONST_API_RECRUITMENT_OFFERS_PUT_CHANGER_RESP_ENDPOINT,
     CONST_API_RECRUITMENT_OFFERS_PUT_ENABLE_ENDPOINT,
     CONST_API_RECRUITMENT_OFFERS_PUT_REPUBLISH_ENDPOINT,
} from "../../../../endpoints"
import { IGenerateTalentplugUrlForOfferCreationRequestModel } from "./_models"
import { IPersonnelModel, OffreModel } from "@common-models/*"

export function generateTalentplugUrlCreationAnnonceRequest(values: IGenerateTalentplugUrlForOfferCreationRequestModel) {
     return axios.post<{ redirectUrl: string }>(CONST_API_RECRUITMENT_OFFERS_GET_GENERATE_TALENTPLUG_URL_CREATION_ENDPOINT, values)
}

export function enableAnnonceRequest(id: number) {
     return axios.put(CONST_API_RECRUITMENT_OFFERS_PUT_ENABLE_ENDPOINT(id), {})
}

export function detailsAnnonceRequest(id: number) {
     return axios.get<OffreModel>(CONST_API_RECRUITMENT_OFFERS_GET_DETAILS_ENDPOINT(id))
}

export function generateTalentplugUrlEditionAnnonceRequest(id: number) {
     return axios.get<{ redirectUrl: string }>(CONST_API_RECRUITMENT_OFFERS_GET_GENERATE_TALENTPLUG_URL_EDITION_ENDPOINT(id))
}

export function republishAnnoncesRequest(ids: number[]) {
     return axios.put(CONST_API_RECRUITMENT_OFFERS_PUT_REPUBLISH_ENDPOINT, { ids })
}

export function archiverAnnoncesRequest(ids: number[]) {
     return axios.put(CONST_API_RECRUITMENT_OFFERS_PUT_ARCHIVE_ENDPOINT, { ids })
}

export function changerResponsableAnnoncesRequest(ids: number[], newResponsable: number) {
     return axios.put<IPersonnelModel>(CONST_API_RECRUITMENT_OFFERS_PUT_CHANGER_RESP_ENDPOINT, { ids, newResponsable })
}
