import React, { useMemo } from "react"
import MyCard from "@common-utils/MyCard"
import { useConsultantsListingContext } from "./ConsultantsListing"
import PtfDetailsPeriodesBonsCommandeBonsLivraison from "../../../client/ptf/periodesBonsCommandeBonsLivraison/_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import MyAlert from "@common-utils/MyAlert"
import { Link } from "react-router-dom"

/* Wrapper */
const ConsultantsListingPreview = () => {
     const globalContext = useConsultantsListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const ptf = selectedItemInPreview?.detailsDuJour.clientPropal || selectedItemInPreview.consultant.clientPropal

     const PtfDetailsPeriodesBonsCommandeBonsLivraisonMemo = useMemo(() => {
          const stopLoadingPreview = () => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : null))
          if (ptf) {
               return <PtfDetailsPeriodesBonsCommandeBonsLivraison pFilters={{ ptfs: [ptf?.id as number] }} handleOnLoadCallback={() => stopLoadingPreview()} />
          } else {
               stopLoadingPreview()
               return <MyAlert type={"primary"}>Aucune PTF n'est attachée à ce jour</MyAlert>
          }
     }, [selectedItemInPreview.consultant?.id])

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>
                    <div className="d-flex align-items-center">
                         <span className={"fas fa-eye-slash fs-4 text-dark p-2 cursor-pointer"} onClick={() => globalContext.setItemInPreview(null)} />
                         {!ptf ? (
                              <span>Aucune PTF</span>
                         ) : (
                              <>
                                   {ptf.categorie === "CATEGORIE_FILLE" ? (
                                        <Link to={`/client/ptf/${ptf.propalMere?.id}/details`} target={"_blank"}>
                                             {ptf.propalMere?.reference}
                                        </Link>
                                   ) : (
                                        <Link to={`/client/ptf/${ptf?.id}/details`} target={"_blank"}>
                                             {ptf?.reference}
                                        </Link>
                                   )}
                              </>
                         )}
                    </div>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <>{PtfDetailsPeriodesBonsCommandeBonsLivraisonMemo}</>
               </MyCard.Body>
          </MyCard>
     )
}

export default ConsultantsListingPreview
