import React from "react"
import { IFormikInitialValuesSavedFiltersSearchCandidateFiltersModel } from "./core/_models"
import MyCard from "@common-utils/MyCard"
import { useSavedFiltersSearchCandidateContext } from "./SavedFiltersSearchCandidate"
import * as Yup from "yup"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT } from "../../../../../../endpoints"

// Filters
const SavedFiltersSearchCandidateFilters = () => {
     const context = useSavedFiltersSearchCandidateContext()

     const schema = Yup.object().shape({
          simpleSearch: Yup.string().label(`"Mot clé"`),
          dateCreationDebut: Yup.string()
               .test("salaireMinMax", function (min) {
                    const max = this.parent.dateCreationFin
                    if (min === undefined || max === undefined || min === null || max === null) return true // No need to test
                    return new Date(min) <= new Date(max)
               })
               .label(`"Période recherche début"`),
          dateCreationFin: Yup.string()
               .test("salaireMinMax", function (max) {
                    const min = this.parent.dateCreationDebut
                    if (min === undefined || max === undefined || min === null || max === null) return true // No need to test
                    return new Date(max) >= new Date(min)
               })
               .label(`"Période recherche fin"`),
          responsables: Yup.array().label(`"Responsables"`),
     })

     const initialValues: IFormikInitialValuesSavedFiltersSearchCandidateFiltersModel = {}

     function handleSubmit(values: IFormikInitialValuesSavedFiltersSearchCandidateFiltersModel) {
          context.setFilters(prev => ({
               ...prev,
               simpleSearch: values.simpleSearch,
               dateCreationDebut: values.dateCreationDebut,
               dateCreationFin: values.dateCreationFin,
               responsables: values.responsables,
          }))
     }

     function handleReset() {
          context.filtersFormikRef.current.resetForm() // Reset local form
          context.simpleSearchBarFormikRef.current.resetForm() // Reset search bar form
          context.setFilters(() => ({ ...context.INITIAL_FILTERS_STATE })) // Reset payload filters

          context.filtersFormikRef.current.setSubmitting(true)
     }

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <MyTooltip title={"Cacher les filters"}>
                              <button className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={() => context.setAreFiltersVisible(prev => !prev)}>
                                   <i className={"fas fa-eye-slash fs-4 text-dark"} />
                              </button>
                         </MyTooltip>
                    }
               >
                    Filtres
               </MyCard.Header>

               <MyCard.Body>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} innerRef={context.filtersFormikRef}>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mot clé</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"simpleSearch"}
                                                       value={helpers.values.simpleSearch}
                                                       onChange={val => {
                                                            helpers.setFieldValue("simpleSearch", val).then()
                                                            context.simpleSearchBarFormikRef.current.setFieldValue("simpleSearch", val).then()
                                                       }}
                                                       isInvalid={!!helpers.errors.simpleSearch}
                                                       placeholder={"Rechercher ..."}
                                                  />
                                                  {helpers.errors.simpleSearch && <div className={"text-danger"}>{helpers.errors.simpleSearch}</div>}
                                             </div>
                                        </div>

                                        <div className="separator separator-dotted mt-4 mb-8"></div>

                                        {/* Date début & fin création */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Période</label>
                                             <div className="col-lg-8">
                                                  <div className="d-flex">
                                                       <MyDateDayMonthYearField
                                                            name={"dateCreationDebut"}
                                                            value={helpers.values.dateCreationDebut}
                                                            onChange={val => helpers.setFieldValue("dateCreationDebut", val)}
                                                            placeholder={"Début"}
                                                            isInvalid={!!helpers.errors.dateCreationDebut}
                                                            maxDate={helpers.values.dateCreationFin}
                                                            classNames={"rounded-0 rounded-start"}
                                                       />
                                                       <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0"></span>
                                                       <MyDateDayMonthYearField
                                                            name={"dateCreationFin"}
                                                            value={helpers.values.dateCreationFin}
                                                            onChange={val => helpers.setFieldValue("dateCreationFin", val)}
                                                            placeholder={"Fin"}
                                                            minDate={helpers.values.dateCreationDebut}
                                                            isInvalid={!!helpers.errors.dateCreationFin}
                                                            classNames={"rounded-0 rounded-end"}
                                                       />
                                                  </div>
                                                  {helpers.errors.dateCreationDebut && <div className={"text-danger"}>{helpers.errors.dateCreationDebut}</div>}
                                                  {helpers.errors.dateCreationFin && <div className={"text-danger"}>{helpers.errors.dateCreationFin}</div>}
                                             </div>
                                        </div>

                                        {/* Responsables */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Responsables</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MySelectSearchDataFromServer
                                                       name={"responsables"}
                                                       url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                       value={helpers.values.responsables}
                                                       onChange={val => helpers.setFieldValue("responsables", val)}
                                                       minimumLengthSearch={3}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.responsables}
                                                       isMulti
                                                  />
                                                  {helpers.errors.responsables && <div className={"text-danger"}>{helpers.errors.responsables}</div>}
                                             </div>
                                        </div>

                                        <div className={"text-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  disabled={helpers.isSubmitting}
                                                  onClick={() => handleReset()}
                                             >
                                                  <span className={"d-none d-lg-block"}>Réinitialiser</span> <i className={"fas fa-sync ms-lg-1"} />
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  <span className={"d-none d-lg-block"}>Filtrer </span>
                                                  {!helpers.isSubmitting ? (
                                                       <i className={"fas fa-filter ms-md-1"} />
                                                  ) : (
                                                       <span className="spinner-border spinner-border-sm align-middle ms-lg-1" />
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyCard.Body>
          </MyCard>
     )
}

export default SavedFiltersSearchCandidateFilters
