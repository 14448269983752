import React from "react"
import FormRechercheCandidat from "./FormRechercheCandidat"
import { useQueryClient } from "react-query"
import { REACT_QUERY_KEY_LOGS_AND_SAVED_FILTERS } from "./CandidatsRechercheSauvegardesEtLogs"
import { useNavigate } from "react-router-dom"
import { createLogSearchCandidate } from "./core/_requests"
import { ISearchCandidatesFiltersModel } from "./core/_models"
import { FormikHelpers } from "formik"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"

const CandidatsRechercheFormNouvelleRecherche = () => {
     const queryClient = useQueryClient()
     const navigate = useNavigate()

     // Refresh sauvegardes & historique (not the best way but we will stick to it)
     function onSaveFilters() {
          queryClient.refetchQueries(REACT_QUERY_KEY_LOGS_AND_SAVED_FILTERS).then()
     }

     // On submit: Log search filters & redirects to the search results
     function onSearch(values: ISearchCandidatesFiltersModel, helpers: FormikHelpers<ISearchCandidatesFiltersModel>) {
          createLogSearchCandidate(values)
               .then(r => {
                    navigate("results", {
                         relative: "path",
                         state: { log_or_saved_filters_id: r.data.sauvegarde_or_historique_id },
                    })
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error.errors && error.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return <FormRechercheCandidat handleSaveFiltersCallback={onSaveFilters} handleSubmitCallback={onSearch} />
}

export default CandidatsRechercheFormNouvelleRecherche
