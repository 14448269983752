import React from "react"

interface Props {
     name: string
     isInvalid: boolean
     className?: string
     onChange: (val) => void
     classNames?: string
     accept?: string
}

const MyFileField: React.FC<Props> = ({ name, className = "", onChange, isInvalid, classNames = "", accept = "" }) => {
     return (
          <input
               type="file"
               accept={accept}
               name={name}
               onChange={e => onChange(e.target.files![0])}
               className={`form-control ${className} ${isInvalid ? "is-invalid" : ""} ${classNames}`}
          />
     )
}

export default MyFileField
