import React from "react"
import InputMask from "react-input-mask"

import DatePicker, { registerLocale } from "react-datepicker"
import fr from "date-fns/locale/fr"
import moment from "moment"

registerLocale("fr", fr)

type Props = {
     name: string
     minDate?: string | undefined | null
     maxDate?: string | undefined | null
     value: string | undefined | null
     placeholder
     onChange: (value: any) => void
     autofocus?: boolean
     isInvalid?: boolean
     classNames?: string
}

const MyDateDayMonthYearHoursMinutesField: React.FC<Props> = ({ name, value, onChange, placeholder, minDate, maxDate, autofocus = false, isInvalid = false, classNames = "" }) => {
     const filterPassedTime = time => {
          const currentDate = new Date()
          const selectedDate = new Date(time)

          return currentDate.getTime() < selectedDate.getTime()
     }

     return (
          <DatePicker
               dateFormat="dd/MM/yyyy HH:mm"
               showTimeSelect
               locale="fr"
               placeholderText={placeholder}
               filterTime={filterPassedTime}
               customInput={
                    <InputMask className={"form-control"} mask="99/99/9999 à 99h99">
                         {() => (
                              <input
                                   type="text"
                                   inputMode={"numeric"}
                                   className={`form-control ${isInvalid ? "is-invalid" : ""} ${classNames}`}
                                   placeholder={placeholder}
                                   autoFocus={autofocus}
                              />
                         )}
                    </InputMask>
               }
               name={name}
               minDate={minDate ? moment(minDate).toDate() : null}
               maxDate={maxDate ? moment(maxDate).toDate() : null}
               isClearable
               selected={value ? moment(value).toDate() : null}
               onChange={(val: any) => {
                    if (val) {
                         const d = moment(val).format("YYYY-MM-DD HH:mm")
                         onChange(d)
                    } else {
                         onChange("")
                    }
               }}
          />
     )
}

export default MyDateDayMonthYearHoursMinutesField
