import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { IGetConsultantsListingRequestModel, IGetConsultantsListingResponseModel } from "../core/_models"
import { getConsultantsListingRequest } from "../core/_requests"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../models"
import { IConsultantsListingContextPropsModel, IItemInPreviewModel } from "./core/_models"
import ConsultantsListingSearchBar from "./_ConsultantsListingSearchBar"
import moment from "moment"
import ConsultantsListingFilters from "./_ConsultantsListingFilters"
import ConsultantsListingPreview from "./_ConsultantsListingPreview"
import ConsultantListingList from "./_ConsultantsListingList"
import MyModal from "@common-utils/MyModal"
import ConsultantSuiviSelectEvent from "../suivi/_ConsultantSuiviSelectEvent"

const REACT_QUERY_KEY_GET_LISTING_CONSULTANTS = "REACT_QUERY_KEY_GET_LISTING_CONSULTANTS"
const ConsultantsListingContext = createContext<IConsultantsListingContextPropsModel>({} as IConsultantsListingContextPropsModel)
export const useConsultantsListingContext = () => useContext(ConsultantsListingContext)
const ConsultantsListing = () => {
     const queryClient = useQueryClient()

     // Referencing filters form, simple search bar form & preview container
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)
     const previewContainerRef = useRef<any>(null)

     // Filters, item in preview & bulk selection
     const [filters, setFilters] = useState<IGetConsultantsListingRequestModel>({
          page: 1,
          pageLength: 20,
          dateFiltrage: moment().format("YYYY-MM-DD"),
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [itemInPreview, setItemInPreview] = useState<IItemInPreviewModel | null>(null)
     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })
     const [selectedConsultantsForSuivi, setSelectedConsultantsForSuivi] = useState<{
          consultants: IConsultantModel[]
          modalSize: "lg" | "xl" | "fullscreen"
          modalNoPadding: boolean
          modalTitle?: JSX.Element
     }>({
          consultants: [],
          modalSize: "lg",
          modalNoPadding: false,
     })
     const [consultantsBeingUpdated, setConsultantsBeingUpdated] = useState<IConsultantModel[]>([])

     // Main listing query
     const listingQuery = useQuery<IGetConsultantsListingResponseModel, AxiosError>(REACT_QUERY_KEY_GET_LISTING_CONSULTANTS, async () => {
          return getConsultantsListingRequest(filters)
               .then(r => {
                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters form
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     useEffect(() => {
          listingQuery.refetch().then()
     }, [filters])

     function onSubmitSuiviCallback() {
          // Loading overlay for consultants being updated
          setConsultantsBeingUpdated(selectedConsultantsForSuivi.consultants)

          const ids = selectedConsultantsForSuivi.consultants.map(item => item.id as number)

          // Fetch fresh data of the selected consultants for suivi in order to their info in the listing
          getConsultantsListingRequest({ ids, dateFiltrage: filters.dateFiltrage })
               .then(r => {
                    // If there is a null item i r.data.data, refresh the list (this means that the consultant does not belong anymore to the connected user)
                    // Example: the consultant has been transferred to another user
                    const not_returned_ids = ids.filter(id => !r.data.data.map(item => item.consultant.id).includes(id))
                    if (not_returned_ids.length > 0) {
                         listingQuery.refetch().then()
                    } else {
                         // Update the listing query data with the given ids
                         queryClient.setQueryData<IGetConsultantsListingResponseModel | undefined>(
                              REACT_QUERY_KEY_GET_LISTING_CONSULTANTS,
                              (prev: IGetConsultantsListingResponseModel | undefined) => {
                                   if (prev) {
                                        prev.data = prev.data.map(item => {
                                             if (ids.includes(item.consultant.id as number)) {
                                                  const updatedItem = r.data.data.find(updatedItem => updatedItem.consultant.id === item.consultant.id)
                                                  if (updatedItem) return updatedItem
                                             }
                                             return item
                                        })

                                        const not_returned_ids = ids.filter(id => !r.data.data.map(item => item.consultant.id).includes(id))
                                        prev.data = prev.data.filter(item => !not_returned_ids.includes(item.consultant.id as number))
                                   }
                                   return prev
                              }
                         )
                         setItemInPreview(null)
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters form
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
               .finally(() => {
                    setConsultantsBeingUpdated([])
               })
     }

     return (
          <>
               <ConsultantsListingContext.Provider
                    value={{
                         areFiltersVisible,
                         setAreFiltersVisible,
                         filters,
                         setFilters,
                         itemInPreview,
                         setItemInPreview,
                         REACT_QUERY_KEY_GET_LISTING_CONSULTANTS,
                         listingQuery,
                         filtersFormikRef,
                         previewContainerRef,
                         simpleSearchBarFormikRef,
                         isBulkSelectionEnabled,
                         setIsBulkSelectionEnabled,
                         selectedConsultantsForSuivi,
                         setSelectedConsultantsForSuivi,
                         consultantsBeingUpdated,
                         setConsultantsBeingUpdated,
                    }}
               >
                    {/* Adapt view to display */}
                    {window.innerWidth >= 1200 ? <ViewXlOrHigher /> : <ViewLgOrLess />}

                    {/* Modal to add suivi */}
                    {selectedConsultantsForSuivi.consultants.length > 0 && (
                         <MyModal
                              title={selectedConsultantsForSuivi.modalTitle === undefined ? <>Choisissez un suivi</> : selectedConsultantsForSuivi.modalTitle}
                              show={selectedConsultantsForSuivi.consultants.length > 0}
                              handleClose={() => setSelectedConsultantsForSuivi({ consultants: [], modalSize: "lg", modalNoPadding: false })}
                              size={["lg", "xl"].includes(selectedConsultantsForSuivi.modalSize) ? (selectedConsultantsForSuivi.modalSize as "lg" | "xl") : "lg"}
                              fullscreen={selectedConsultantsForSuivi.modalSize === "fullscreen" ? true : undefined}
                              noPadding={selectedConsultantsForSuivi.modalNoPadding}
                         >
                              <ConsultantSuiviSelectEvent
                                   consultants={selectedConsultantsForSuivi.consultants}
                                   setModalSizeCallback={modalSize => setSelectedConsultantsForSuivi(prev => ({ ...prev, modalSize }))}
                                   setModalNoPaddingCallback={val => setSelectedConsultantsForSuivi(prev => ({ ...prev, modalNoPadding: val }))}
                                   setModalTitle={modalTitle => setSelectedConsultantsForSuivi(prev => ({ ...prev, modalTitle }))}
                                   onSubmitCallback={() => {
                                        onSubmitSuiviCallback()
                                        setSelectedConsultantsForSuivi({ consultants: [], modalSize: "lg", modalNoPadding: false })
                                   }}
                              />
                         </MyModal>
                    )}
               </ConsultantsListingContext.Provider>
          </>
     )
}

// View display Lg or less
const ViewLgOrLess = () => {
     const context = useConsultantsListingContext()

     return (
          <>
               <div className={`${context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <ConsultantsListingSearchBar />
               </div>

               {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                    <div className={"mb-2"}>
                         <ConsultantsListingPreview />
                    </div>
               )}

               <div className={`${!context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <ConsultantsListingFilters />
               </div>

               <ConsultantListingList />
          </>
     )
}

// View display Xl or higher
const ViewXlOrHigher = () => {
     const context = useConsultantsListingContext()
     return (
          <>
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-5 ${!context.areFiltersVisible ? "d-none" : ""}`}>
                         <ConsultantsListingFilters />
                    </div>
                    {/*
                     * List & preview will share the remaining width
                     * Preview will not be visible at the beginning
                     */}
                    <div className={`col ${context.itemInPreview && context.itemInPreview.isPreviewVisible ? "" : "col-7"}`}>
                         <div className={"row"}>
                              <div className={context.itemInPreview && context.itemInPreview.isPreviewVisible ? "col-5" : ""}>
                                   {/* Simple bar search will be shown only if filters are not displayed */}
                                   <div className={`mb-2 ${context.areFiltersVisible ? "d-none" : ""}`}>
                                        <ConsultantsListingSearchBar />
                                   </div>

                                   <ConsultantListingList />
                              </div>
                              {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                                   <div className="col-7">
                                        <ConsultantsListingPreview />
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          </>
     )
}

export default ConsultantsListing
