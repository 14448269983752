import React, { useState } from "react"
import { IGetPersonnelDdcRequestModel } from "../../core/_models"
import { PersonnelMaladieListingForOne } from "../maladie/listing/listing-only-one/_PersonnelMaladieListingForOne"
import { usePersonnelDeclarationDetailsContext } from "./_PersonnelDeclarationDetails"

export function Maladie({ filtres }: { filtres: IGetPersonnelDdcRequestModel }) {
     const context = usePersonnelDeclarationDetailsContext()
     const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

     const Footer = () => {
          return (
               <>
                    <div className="d-flex justify-content-end">
                         {context.areActionsDemandeRectifOrValidationAvailable && (
                              <button type="button" className="btn btn-sm btn-warning d-flex align-items-center me-2" onClick={() => context.handleDemandeRectification()}>
                                   <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                   <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                              </button>
                         )}
                         {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                              <>
                                   <button type="button" className="btn btn-sm btn-success d-flex align-items-center me-2" onClick={() => context.handleValidationDemandeRegul()}>
                                        <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                        <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                   </button>
                                   <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                        <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                   </button>
                              </>
                         )}
                         <button type="button" className="btn btn-sm btn-primary" onClick={() => context.setNavigationStep("ddc")}>
                              DDC
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>
               </>
          )
     }

     return (
          <>
               <div className="mb-4">
                    <PersonnelMaladieListingForOne
                         personnel_ID={context.declaration.personnel?.id as number}
                         editionMode={false}
                         filtres={filtres}
                         setIsDataLoaded={setIsDataLoaded}
                    />
               </div>

               {isDataLoaded && <Footer />}
          </>
     )
}

export default Maladie
