import { PageLink } from "@common-metronic/layout/core"
import {
     ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_ORGANIGRAMME,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF,
} from "./routing-endpoints"

export const ROUTING_BREADCRUMBS_GESTION: Array<PageLink> = [
     {
          title: "Gestion",
     },
]

export const ROUTING_BREADCRUMBS_GESTION_STRUCTURE: Array<PageLink> = [
     ...ROUTING_BREADCRUMBS_GESTION,
     {
          title: "Structure",
     },
]

export const ROUTING_BREADCRUMBS_GESTION_STRUCTURE_STAFF: Array<PageLink> = [
     ...ROUTING_BREADCRUMBS_GESTION,
     {
          title: "Staff",
          path: ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF,
     },
]

export const ROUTING_BREADCRUMBS_GESTION_STRUCTURE_ORGANIGRAMME: Array<PageLink> = [
     ...ROUTING_BREADCRUMBS_GESTION,
     {
          title: "Organigramme",
          path: ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_ORGANIGRAMME,
     },
]

export const ROUTING_BREADCRUMBS_GESTION_STRUCTURE_DECLARATIONS_VOYANTS: Array<PageLink> = [
     ...ROUTING_BREADCRUMBS_GESTION_STRUCTURE,
     {
          title: "Déclarations ( voyants )",
          path: ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS,
     },
]

export const ROUTING_BREADCRUMBS_GESTION_PRODUCTION: Array<PageLink> = [
     ...ROUTING_BREADCRUMBS_GESTION,
     {
          title: "Production",
     },
]

export const ROUTING_BREADCRUMBS_GESTION_PRODUCTION_DECLARATIONS_VOYANTS: Array<PageLink> = [
     ...ROUTING_BREADCRUMBS_GESTION_PRODUCTION,
     {
          title: "Déclarations ( voyants )",
          path: ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS,
     },
]
