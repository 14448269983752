import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT } from "../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { personnelAddPersonnelsToTeamRequest } from "../../core/_requests"
import { usePersonnelDetailsContext } from "../PersonnelDetails"
import { IGetPersonnelDetailsResponseModel } from "../../core/_models"
import { useQueryClient } from "react-query"

const AddPersonnelsToTeam = ({ closeModal = () => {} }: IPropsModel) => {
     const queryClient = useQueryClient()
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data

     // Form schema
     const schema = Yup.object().shape({
          personnelsToAdd: Yup.array().of(Yup.number()).label(`"Personnels à ajouter"`).min(1).required(),
     })

     // Init values
     const initialValues: IPersonnelAddPersonnelsToTeamRequestModel = {}

     // Handle submit
     function handleSubmit(values: IPersonnelAddPersonnelsToTeamRequestModel, helpers: FormikHelpers<IPersonnelAddPersonnelsToTeamRequestModel>) {
          helpers.setStatus(null)

          personnelAddPersonnelsToTeamRequest(details?.personnel.id as number, values.personnelsToAdd as number[])
               .then(r => {
                    queryClient.setQueryData<IGetPersonnelDetailsResponseModel | undefined>(
                         context.REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                         (prev: IGetPersonnelDetailsResponseModel | undefined) => {
                              if (prev) {
                                   prev = r.data
                              }
                              return prev
                         }
                    )
                    closeModal()
                    toast.success("Ajout avec succès.", { autoClose: 5000 })
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <div className="mb-4">
                                        <MySelectGetAllDataFromServer
                                             name={"personnelsToAdd"}
                                             url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                             value={helpers.values.personnelsToAdd}
                                             onChange={val => helpers.setFieldValue("personnelsToAdd", val)}
                                             isInvalid={!!helpers.errors.personnelsToAdd}
                                             payload={{ except_ids: [details?.personnel?.id, ...(details?.team?.map(x => x.id) || [])] }}
                                             placeholder={"Choisissez les personnels à ajouter"}
                                             isMulti
                                        />
                                        {helpers.errors.personnelsToAdd && <div className={"text-danger"}>{helpers.errors.personnelsToAdd}</div>}
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button
                                             className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                             type={"button"}
                                             onClick={closeModal}
                                             disabled={helpers.isSubmitting}
                                        >
                                             Annuler
                                        </button>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Confirmer</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPersonnelAddPersonnelsToTeamRequestModel {
     personnelsToAdd?: number[]
}

interface IPropsModel {
     closeModal?: () => void
}

export default AddPersonnelsToTeam
