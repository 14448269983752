import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../models"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { IConsultantMissionModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import { getAlertesMissionExpirationDashboardRequest } from "../../core/_requests"
import {
     IDashboardAlertesMissionsExpirationListingContextPropsModel,
     IGetAlertesMissionsExpirationDashboardRequestModel,
     IGetAlertesMissionsExpirationDashboardResponseModel,
     IItemInPreviewModel,
} from "./core/_models"
import moment from "moment/moment"
import DashboardAlertesMissionsExpirationListingSearchBar from "./_DashboardAlertesMissionsExpirationListingSearchBar"
import DashboardAlertesMissionsExpirationListingList from "./_DashboardAlertesMissionsExpirationListingList"
import DashboardAlertesMissionsExpirationListingFilters from "./_DashboardAlertesMissionsExpirationListingFilters"
import DashboardAlertesMissionsExpirationListingPreview from "./_DashboardAlertesMissionsExpirationListingPreview"
import MyModal from "@common-utils/MyModal"
import ConsultantSuiviProlongementMissionSalarie from "../../../business/consultants/suivi/prolongementMission/_ConsultantSuiviProlongementMissionSalarie"
import ConsultantSuiviProlongementMissionNonSalarie from "../../../business/consultants/suivi/prolongementMission/_ConsultantSuiviProlongementMissionNonSalarie"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
const DashboardAlertesMissionsExpirationListingContext = createContext<IDashboardAlertesMissionsExpirationListingContextPropsModel>(
     {} as IDashboardAlertesMissionsExpirationListingContextPropsModel
)
export const useDashboardAlertesMissionsExpirationListingContext = () => useContext(DashboardAlertesMissionsExpirationListingContext)
const DashboardAlertesMissionsExpirationListing = () => {
     // Referencing filters form, simple search bar form & preview container
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)
     const previewContainerRef = useRef<any>(null)

     // Filters, item in preview & bulk selection
     const [filters, setFilters] = useState<IGetAlertesMissionsExpirationDashboardRequestModel>({
          dateAlerte: moment().add(15, "days").format("YYYY-MM-DD"),
          page: 1,
          pageLength: 20,
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [itemInPreview, setItemInPreview] = useState<IItemInPreviewModel | null>(null)
     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })
     const [itemEnProlongementMissionSalarieInModal, setItemEnProlongementMissionSalarieInModal] = useState<{
          item: IConsultantMissionModel | null
          modalSize: "lg" | "xl" | "fullscreen"
          modalNoPadding: boolean
     }>({
          item: null,
          modalSize: "lg",
          modalNoPadding: false,
     })
     const [itemEnProlongementMissionNonSalarieInModal, setItemEnProlongementMissionNonSalarieInModal] = useState<IConsultantMissionModel>()

     // Main listing query
     const listingQuery = useQuery<IGetAlertesMissionsExpirationDashboardResponseModel, AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return getAlertesMissionExpirationDashboardRequest(filters)
               .then(r => {
                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters form
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     useEffect(() => {
          listingQuery.refetch().then()
     }, [filters])

     function handleProlongementMissionNonSalarieCallback() {
          setItemInPreview(null)
          listingQuery.refetch().then()
          setItemEnProlongementMissionNonSalarieInModal(undefined)
     }

     return (
          <>
               <DashboardAlertesMissionsExpirationListingContext.Provider
                    value={{
                         areFiltersVisible,
                         setAreFiltersVisible,
                         filters,
                         setFilters,
                         itemInPreview,
                         setItemInPreview,
                         REACT_QUERY_KEY_GET_LISTING,
                         listingQuery,
                         filtersFormikRef,
                         previewContainerRef,
                         simpleSearchBarFormikRef,
                         isBulkSelectionEnabled,
                         setIsBulkSelectionEnabled,
                         itemEnProlongementMissionSalarieInModal,
                         setItemEnProlongementMissionSalarieInModal,
                         itemEnProlongementMissionNonSalarieInModal,
                         setItemEnProlongementMissionNonSalarieInModal,
                    }}
               >
                    {/* Adapt view to display */}
                    {window.innerWidth >= 1200 ? <ViewXlOrHigher /> : <ViewLgOrLess />}

                    {/* Prolongement de mission salarié */}
                    {itemEnProlongementMissionSalarieInModal.item && (
                         <MyModal
                              title={<>Prolongement de mission de {itemEnProlongementMissionSalarieInModal.item.consultant?.prenomNom}</>}
                              show={true}
                              handleClose={() => setItemEnProlongementMissionSalarieInModal({ item: null, modalSize: "lg", modalNoPadding: false })}
                              size={
                                   ["lg", "xl"].includes(itemEnProlongementMissionSalarieInModal.modalSize)
                                        ? (itemEnProlongementMissionSalarieInModal.modalSize as "lg" | "xl")
                                        : "lg"
                              }
                              fullscreen={itemEnProlongementMissionSalarieInModal.modalSize === "fullscreen" ? true : undefined}
                              noPadding={itemEnProlongementMissionSalarieInModal.modalNoPadding}
                         >
                              <ConsultantSuiviProlongementMissionSalarie
                                   consultant_ID={itemEnProlongementMissionSalarieInModal.item.consultant?.id as number}
                                   setModalSizeCallback={modalSize => setItemEnProlongementMissionSalarieInModal(prev => ({ ...prev, modalSize }))}
                                   setModalNoPaddingCallback={val => setItemEnProlongementMissionSalarieInModal(prev => ({ ...prev, modalNoPadding: val }))}
                                   onSubmitCallback={() => {
                                        setItemInPreview(null)
                                        setItemEnProlongementMissionSalarieInModal({ item: null, modalSize: "lg", modalNoPadding: false })
                                        listingQuery.refetch().then()
                                   }}
                              />
                         </MyModal>
                    )}

                    {/* Prolongement de mission non salarié */}
                    {itemEnProlongementMissionNonSalarieInModal && (
                         <MyModal
                              title={<>Prolongement de mission de {itemEnProlongementMissionNonSalarieInModal.consultant?.prenomNom}</>}
                              show={true}
                              handleClose={() => setItemEnProlongementMissionNonSalarieInModal(undefined)}
                              size={"lg"}
                         >
                              <ConsultantSuiviProlongementMissionNonSalarie
                                   consultant_ID={itemEnProlongementMissionNonSalarieInModal.consultant?.id as number}
                                   onSubmitCallback={() => handleProlongementMissionNonSalarieCallback()}
                              />
                         </MyModal>
                    )}
               </DashboardAlertesMissionsExpirationListingContext.Provider>
          </>
     )
}

// View display Lg or less
const ViewLgOrLess = () => {
     const context = useDashboardAlertesMissionsExpirationListingContext()

     return (
          <>
               <div className={`${context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <DashboardAlertesMissionsExpirationListingSearchBar />
               </div>

               {context.itemInPreview && context.itemInPreview.isPreviewVisible && context.itemInPreview.selectedItem.consultant?.contrat !== "CONTRAT_ST" && (
                    <div className={"mb-2"}>
                         <DashboardAlertesMissionsExpirationListingPreview />
                    </div>
               )}

               <div className={`${!context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <DashboardAlertesMissionsExpirationListingFilters />
               </div>

               <DashboardAlertesMissionsExpirationListingList />
          </>
     )
}

// View display Xl or higher
const ViewXlOrHigher = () => {
     const context = useDashboardAlertesMissionsExpirationListingContext()
     return (
          <>
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-5 ${!context.areFiltersVisible ? "d-none" : ""}`}>
                         <DashboardAlertesMissionsExpirationListingFilters />
                    </div>
                    {/*
                     * List & preview will share the remaining width
                     * Preview will not be visible at the beginning
                     */}
                    <div className={`col ${context.itemInPreview && context.itemInPreview.isPreviewVisible ? "" : "col-7"}`}>
                         <div className={"row"}>
                              <div className={context.itemInPreview && context.itemInPreview.isPreviewVisible ? "col-5" : ""}>
                                   {/* Simple bar search will be shown only if filters are not displayed */}
                                   <div className={`mb-2 ${context.areFiltersVisible ? "d-none" : ""}`}>
                                        <DashboardAlertesMissionsExpirationListingSearchBar />
                                   </div>

                                   <DashboardAlertesMissionsExpirationListingList />
                              </div>
                              {context.itemInPreview && context.itemInPreview.isPreviewVisible && context.itemInPreview.selectedItem.consultant?.contrat !== "CONTRAT_ST" && (
                                   <div className="col-7">
                                        <DashboardAlertesMissionsExpirationListingPreview />
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          </>
     )
}

export default DashboardAlertesMissionsExpirationListing
