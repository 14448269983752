import axios from "axios"
import { CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CLIENT_ENDPOINT, CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CLIENT_ENDPOINT } from "../../../../../../endpoints"
import { IGetDemandeEmbaucheInfoClientResponseModel, ISetDemandeEmbaucheInfoClientNonSalarieRequestModel, ISetDemandeEmbaucheInfoClientSalarieRequestModel } from "./_models"

export function getDemandeEmbaucheInfoClient(consultant_id: number) {
     return axios.get<IGetDemandeEmbaucheInfoClientResponseModel>(CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CLIENT_ENDPOINT(consultant_id))
}

export function setDemandeEmbaucheInfoClientSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoClientSalarieRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CLIENT_ENDPOINT(consultant_id), payload)
}

export function setDemandeEmbaucheInfoClientNonSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoClientNonSalarieRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CLIENT_ENDPOINT(consultant_id), payload)
}
