import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getDemandeEmbaucheInfoPerso } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { IGetDemandeEmbaucheInfoPersoResponseModel } from "./core/_models"
import DemandeEmbaucheDetailsInfoPersoSalarie from "./_DemandeEmbaucheDetailsInfoPersoSalarie"
import DemandeEmbaucheDetailsInfoPersoNonSalarie from "./_DemandeEmbaucheDetailsInfoPersoNonSalarie"

const REACT_QUERY_KEY_GET_DE_INFO_PERSO_QUERY = "REACT_QUERY_KEY_GET_DE_INFO_PERSO_QUERY"
const DemandeEmbaucheDetailsInfoPerso = () => {
     const consultant_ID = parseInt(useParams<{ consultant_id: string }>().consultant_id as string)

     const infoPersoQuery = useQuery<IGetDemandeEmbaucheInfoPersoResponseModel, AxiosError>(REACT_QUERY_KEY_GET_DE_INFO_PERSO_QUERY, () => {
          return getDemandeEmbaucheInfoPerso(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Is fetching
     if (infoPersoQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />
               </div>
          )
     }

     // Error
     if (!infoPersoQuery.isFetching && infoPersoQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{infoPersoQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => infoPersoQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     if (!infoPersoQuery.isFetching && infoPersoQuery.data) {
          // Render form salarié
          if (infoPersoQuery.data.salarie) {
               return <DemandeEmbaucheDetailsInfoPersoSalarie data={infoPersoQuery.data} />
          } else {
               // render form non salarié
               return <DemandeEmbaucheDetailsInfoPersoNonSalarie data={infoPersoQuery.data} />
          }
     }

     return <></>
}

export default DemandeEmbaucheDetailsInfoPerso
