import React, { useEffect, useRef, useState } from "react"

import { Editor } from "@tinymce/tinymce-react"

interface Props {
     height: number
     onChange: (val: string) => void
     value?: string
     placeholder: string
     forceEditorToRefreshContent?: { value: string; random: string } | undefined
}

const MyEditorField: React.FC<Props> = ({ height, value, forceEditorToRefreshContent, onChange, placeholder }) => {
     const editorRef = useRef<any>(null)
     const [initialValue, setInitialValue] = useState<string>(value || "")

     useEffect(() => {
          // Update the component's state when the prop changes
          if (forceEditorToRefreshContent) setInitialValue(forceEditorToRefreshContent.value)
     }, [forceEditorToRefreshContent]) // Dependency array to watch for changes in myProp

     return (
          <Editor
               apiKey="wfpwyzgdaawv07lctznk41hhcpvh1y4r6hqp2p4twhqe3big"
               onInit={(evt, editor) => (editorRef.current = editor)}
               initialValue={initialValue}
               onEditorChange={onChange}
               init={{
                    height,
                    menubar: false,
                    branding: false,
                    plugins: ["advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor", "visualblocks", "code", "fullscreen", "insertdatetime", "table"],
                    toolbar: "blocks bold italic forecolor | alignleft aligncenter alignjustify | bullist numlist | fullscreen",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
               }}
          />
     )

     // const [editorState, setEditorState] = useState(value ? prepareDraft(value) : EditorState.createEmpty())
     //
     // function prepareDraft(value: string) {
     //      const draft = htmlToDraft(value)
     //      const contentState = ContentState.createFromBlockArray(draft.contentBlocks)
     //      return EditorState.createWithContent(contentState)
     // }
     //
     // function handleEditoStateChange(editorState: EditorState) {
     //      const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()))
     //      onChange(forFormik)
     //      setEditorState(editorState)
     // }
     //
     // console.log(value)
     // return (
     //      <Editor
     //           editorState={editorState}
     //           editorStyle={{ width: "100%", minHeight: `${height}px`, resize: "both", overflow: "auto" }}
     //           onEditorStateChange={handleEditoStateChange}
     //           toolbar={{
     //                options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker"],
     //                inline: {
     //                     inDropdown: true,
     //                     options: ["bold", "italic", "underline", "strikethrough", "monospace", "superscript", "subscript"],
     //                },
     //                list: { inDropdown: true },
     //                textAlign: { inDropdown: true },
     //                link: { inDropdown: true },
     //                history: { inDropdown: true },
     //           }}
     //           placeholder={placeholder}
     //           editorClassName="custom-editor"
     //           localization={{
     //                locale: "fr",
     //           }}
     //      />
     // )
}

export default MyEditorField
