import {
     ICreateNewLogSearchCandidatRequestModel,
     ICreateNewSavedFiltersSearchCandidatRequestModel,
     IOneSavedFiltersOrOneLogSearchCandidateResponseModel,
     ISearchCandidatesFiltersModel,
     ISomeSavedFiltersAndLogsSearchCandidateResponseModel,
     IUpdateSavedFiltersSearchCandidatRequestModel,
} from "./_models"
import axios from "axios"
import {
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_DELETE_SAVED_FILTERS_ENDPOINT,
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_GET_ONE_SAVED_FILTERS_OR_ONE_LOG_ENDPOINT,
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_GET_SOME_SAVED_FILTERS_AND_LOGS_ENDPOINT,
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_POST_CREATE_NEW_SAVED_FILTERS_OR_NEW_LOG_ENDPOINT,
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_PUT_DUPLICATE_SAVED_FILTERS_ENDPOINT,
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_PUT_UPDATE_SAVED_FILTERS_ENDPOINT,
} from "../../../../../endpoints"
import {
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE,
} from "@common-constants/*"
import { ISavedFiltersSearchCandidateRequestModel } from "../logsAndSavedFiltersSearchCandidate/savedFilters/core/_models"

// Create saved filters search candidate
export function createSavedFiltersSearchCandidate(titreSauvegarde: string, data: ISearchCandidatesFiltersModel) {
     const savedFilters: ICreateNewSavedFiltersSearchCandidatRequestModel = {
          searchKeywords: data.searchKeywords,
          searchScope: data.searchScope,
          searchOperateurLogiqueOnlyScopeCurriculum: data.searchOperateurLogiqueOnlyScopeCurriculum,
          disponibilite: data.disponibilite,
          disponibiliteDate: data.disponibiliteDate,
          mobilite: data.mobilite,
          mobiliteRegions: data.mobiliteRegions,
          mobiliteDepartements: data.mobiliteDepartements,
          mobiliteVilles: data.mobiliteVilles,
          niveauEtudes: data.niveauEtudes,
          fraicheurCV: data.fraicheurCV,
          viviers: data.viviers,
          annonces: data.annonces,
          candidats: data.candidats,
          areCandidatesInListeDeTraitement: data.areCandidatesInListeDeTraitement,
          candidatType: data.candidatType,
          personnelsAyantSaisiLeCandidat: data.personnelsAyantSaisiLeCandidat,
          secteursActivite: data.secteursActivite,
          metiers: data.metiers,
          seenOrNot: data.seenOrNot,
          experienceMin: data.experienceMin,
          // salaireNetMensuelSouhaiteMin: data.salaireNetMensuelSouhaiteMin,
          // salaireNetMensuelSouhaiteMax: data.salaireNetMensuelSouhaiteMax,
          avecSuivi: data.avecSuivi,
          titreSauvegarde,
     }

     return axios.post<{ sauvegarde_or_historique_id: number; message: string }>(
          CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_POST_CREATE_NEW_SAVED_FILTERS_OR_NEW_LOG_ENDPOINT,
          {
               ...savedFilters,
               typeHistoriqueOuSauvegarde: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE,
          }
     )
}

// Duplicate one saved filters search candidate
export function duplicateSavedFiltersSearchCandidate(id: number, titre: string) {
     return axios.put<{ id: number }>(CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_PUT_DUPLICATE_SAVED_FILTERS_ENDPOINT(id), {
          titre,
     })
}

// Update saved filters search candidate
export function updateSavedFiltersSearchCandidate(id: number, data: ISearchCandidatesFiltersModel) {
     const savedFilters: IUpdateSavedFiltersSearchCandidatRequestModel = {
          searchKeywords: data.searchKeywords,
          searchScope: data.searchScope,
          searchOperateurLogiqueOnlyScopeCurriculum: data.searchOperateurLogiqueOnlyScopeCurriculum,
          disponibilite: data.disponibilite,
          disponibiliteDate: data.disponibiliteDate,
          mobilite: data.mobilite,
          mobiliteRegions: data.mobiliteRegions,
          mobiliteDepartements: data.mobiliteDepartements,
          mobiliteVilles: data.mobiliteVilles,
          niveauEtudes: data.niveauEtudes,
          fraicheurCV: data.fraicheurCV,
          viviers: data.viviers,
          annonces: data.annonces,
          candidats: data.candidats,
          areCandidatesInListeDeTraitement: data.areCandidatesInListeDeTraitement,
          candidatType: data.candidatType,
          personnelsAyantSaisiLeCandidat: data.personnelsAyantSaisiLeCandidat,
          secteursActivite: data.secteursActivite,
          metiers: data.metiers,
          seenOrNot: data.seenOrNot,
          experienceMin: data.experienceMin,
          // salaireNetMensuelSouhaiteMin: data.salaireNetMensuelSouhaiteMin,
          // salaireNetMensuelSouhaiteMax: data.salaireNetMensuelSouhaiteMax,
          avecSuivi: data.avecSuivi,
          titreSauvegarde: data.titreSauvegarde,
     }

     return axios.put<{ message: string }>(CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_PUT_UPDATE_SAVED_FILTERS_ENDPOINT(id), savedFilters)
}

// Delete one or many saved filters search candidate
export function deleteSavedFiltersSearchCandidate(filters: ISavedFiltersSearchCandidateRequestModel) {
     return axios.delete<{ message: string }>(CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_DELETE_SAVED_FILTERS_ENDPOINT, {
          data: filters,
     })
}

// Logs search candidate
export function createLogSearchCandidate(data: ISearchCandidatesFiltersModel) {
     const log: ICreateNewLogSearchCandidatRequestModel = {
          searchKeywords: data.searchKeywords,
          searchScope: data.searchScope,
          searchOperateurLogiqueOnlyScopeCurriculum: data.searchOperateurLogiqueOnlyScopeCurriculum,
          disponibilite: data.disponibilite,
          disponibiliteDate: data.disponibiliteDate,
          mobilite: data.mobilite,
          mobiliteRegions: data.mobiliteRegions,
          mobiliteDepartements: data.mobiliteDepartements,
          mobiliteVilles: data.mobiliteVilles,
          niveauEtudes: data.niveauEtudes,
          fraicheurCV: data.fraicheurCV,
          viviers: data.viviers,
          annonces: data.annonces,
          candidats: data.candidats,
          areCandidatesInListeDeTraitement: data.areCandidatesInListeDeTraitement,
          candidatType: data.candidatType,
          personnelsAyantSaisiLeCandidat: data.personnelsAyantSaisiLeCandidat,
          secteursActivite: data.secteursActivite,
          metiers: data.metiers,
          seenOrNot: data.seenOrNot,
          experienceMin: data.experienceMin,
          // salaireNetMensuelSouhaiteMin: data.salaireNetMensuelSouhaiteMin,
          // salaireNetMensuelSouhaiteMax: data.salaireNetMensuelSouhaiteMax,
          avecSuivi: data.avecSuivi,
     }

     return axios.post<{ sauvegarde_or_historique_id: number; message: string }>(
          CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_POST_CREATE_NEW_SAVED_FILTERS_OR_NEW_LOG_ENDPOINT,
          {
               ...log,
               typeHistoriqueOuSauvegarde: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE,
          }
     )
}

// Get some saved filters & some logs search candidate
export function getSomeSavedFiltersAndLogsSearchCandidate(que_moi_ou_moi_et_ma_team: "que_moi" | "moi_et_ma_team") {
     return axios.get<ISomeSavedFiltersAndLogsSearchCandidateResponseModel>(CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_GET_SOME_SAVED_FILTERS_AND_LOGS_ENDPOINT, {
          params: { que_moi_ou_moi_et_ma_team },
     })
}

// Get log or saved filters search candidate with the given id
export function getOneSavedFiltersOrOneLogSearchCandidate(id: number) {
     return axios.get<IOneSavedFiltersOrOneLogSearchCandidateResponseModel>(
          CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_GET_ONE_SAVED_FILTERS_OR_ONE_LOG_ENDPOINT(id)
     )
}
