import axios from "axios"
import { IPersonnelNdfModel } from "@common-models/*"
import {
     CONST_API_PERSONNEL_DECLARATION_GET_NDF_BY_DATE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_CREATE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_NDF_GET_LISTING_ENDPOINT,
} from "../../../../../endpoints"
import { ICreateConnectedUserNdfRequestModel, IGetConnectedUserNdfRequestModel } from "./_models"

export function getConnectedUserNdfRequest(payload: IGetConnectedUserNdfRequestModel) {
     return axios.post<IPersonnelNdfModel[]>(CONST_API_PERSONNEL_DECLARATION_NDF_GET_LISTING_ENDPOINT, payload)
}

export function createConnectedUserNdfRequest(payload: ICreateConnectedUserNdfRequestModel) {
     return axios.post<IPersonnelNdfModel>(CONST_API_PERSONNEL_DECLARATION_NDF_CREATE_ENDPOINT, payload)
}

export function getConnectedUserNdfByDateRequest(mois: string, annee: string) {
     return axios.post<IPersonnelNdfModel | null>(CONST_API_PERSONNEL_DECLARATION_GET_NDF_BY_DATE_ENDPOINT, { mois, annee })
}
