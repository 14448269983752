import "./style.scss"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { ISimulationRentabiliteConsultantResponseModel } from "../../../business/consultants/core/_models"

const CostToolResults = ({ results, version }: ICostToolResultsPropsModel) => {
     const TableSalaire = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "550px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td scope="col" colSpan={2} className="text-center border bg-primary border-white title_fixed_width">
                                        <b className="text-white" style={{ fontSize: "15px" }}>
                                             SALAIRE
                                        </b>
                                   </td>
                                   <th scope="col">Base 35h</th>
                                   <th scope="col">Heures supplémentaires</th>
                                   <th scope="col">Base 35h + HS</th>
                              </tr>
                              <tr>
                                   <th rowSpan={2} className="align-middle">
                                        Brut
                                   </th>
                                   <th>Mensuel</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <span>{GlobalHelper.separateNumber(results.btc.salaireBrutMensuel.toFixed())}</span>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutHeuresSuppMensuel.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.base35hPlusHeuresSuppMensuelBrut!.toFixed())}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Annuel</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.salaireBrutAnnuel.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutHeuresSuppAnnuel.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.base35hPlusHeuresSuppAnnuelBrut!.toFixed())}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th rowSpan={2} className="align-middle">
                                        Net
                                   </th>
                                   <th>Mensuel</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.salaireChargeSansHeuresSuppMensuel.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.salaireQueLesHeuresSuppMensuelCharge.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.base35hPlusHeuresSuppMensuelNet!.toFixed())}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Annuel</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.salaireChargeSansHeuresSuppAnnuel.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.salaireQueLesHeuresSuppAnnuelCharge.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.base35hPlusHeuresSuppAnnuelNet!.toFixed())}</div>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableAvantage = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "600px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td
                                        rowSpan={2}
                                        className="text-center border bg-primary border-white align-self-center title_fixed_width"
                                        style={{ fontSize: "15px", width: "159px", maxWidth: "159px" }}
                                   >
                                        <b className="text-white">AVANTAGES</b>
                                   </td>
                                   <th colSpan={3}>Tickets Restaurant</th>
                                   <th rowSpan={2}>Salaire net versé sur le bulletin de salaire</th>
                              </tr>
                              <tr>
                                   <th>Part Employeur</th>
                                   <th>Part Salarié</th>
                                   <th>Total versé sur le compte Apetiz</th>
                              </tr>
                              <tr>
                                   <th>Mensuel</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.partMensuelleTicketsRestoEmployeur.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.partMensuelleTicketsRestoSalarie.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.totalVerseSurCompteApetizMensuel!.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.salaireNetVerseSurBulletingSalaireMensuel!.toFixed())}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Annuel</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.partAnnuelleTicketsRestoEmployeur.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.partAnnuelleTicketsRestoSalarie.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.totalVerseSurCompteApetizAnnuel!.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td className={"pe-2"}>
                                        <div className="d-flex justify-content-center">
                                             <span>{GlobalHelper.separateNumber(results.btc.salaireNetVerseSurBulletingSalaireAnnuel!.toFixed())}</span>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableEquivalentBrut = () => {
          return (
               <table className="table js-calculationsResults">
                    <tbody className="thead-light">
                         <tr>
                              <td className="bg-primary title_fixed_width" style={{ fontSize: "15px" }}>
                                   <b className="text-white">Équivalent brut</b>
                              </td>
                              <th>Annuel</th>
                              <th>Mensuel</th>
                         </tr>
                         <tr>
                              <th>Avec tickets restaurant</th>
                              <td>
                                   <div className="d-flex justify-content-center">
                                        <div>{GlobalHelper.separateNumber(results.btc.equivalentBrutAvecTicketRestoAnnuel!.toFixed())}</div>
                                   </div>
                              </td>
                              <td>
                                   <div className="d-flex justify-content-center">
                                        <div>{GlobalHelper.separateNumber(results.btc.equivalentBrutAvecTicketRestoMensuel!.toFixed())}</div>
                                   </div>
                              </td>
                         </tr>
                         <tr>
                              <th>Sans tickets restaurant</th>
                              <td>
                                   <div className="d-flex justify-content-center">
                                        <div>{GlobalHelper.separateNumber(results.btc.equivalentBrutSansTicketRestoAnnuel!.toFixed())}</div>
                                   </div>
                              </td>
                              <td>
                                   <div className="d-flex justify-content-center">
                                        <div>{GlobalHelper.separateNumber(results.btc.equivalentBrutSansTicketRestoMensuel!.toFixed())}</div>
                                   </div>
                              </td>
                         </tr>
                    </tbody>
               </table>
          )
     }

     const TableCout = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "550px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td scope="col" className="bg-primary  title_fixed_width" style={{ fontSize: "15px" }}>
                                        <b className="text-white">COÛTS</b>
                                   </td>
                                   <th>Annuel</th>
                                   <th>Mensuel</th>
                                   <th>Journalier</th>
                              </tr>
                              <tr>
                                   <th>Salaire Mensuel 35h</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutSalaireAnnuelCharge.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutSalaireMensuelCharge.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutSalaireJournalierCharge.toFixed(2))}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Heures Supplémentairers</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutHeuresSuppAnnuelCharge.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutHeuresSuppMensuelCharge.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutHeuresSuppJournalierCharge.toFixed(2))}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Tickets Restaurant</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.partAnnuelleTicketsRestoEmployeur.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.partMensuelleTicketsRestoEmployeur.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>{GlobalHelper.separateNumber(results.btc.partJournaliereTicketsRestoEmployeur)}</td>
                              </tr>
                              <tr className="fw-bold">
                                   <th>Coût Total</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutTotalSalarieAnnuel!.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutTotalSalarieMensuel!.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.coutTotalSalarieJournalier!.toFixed(2))}</div>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableMarge = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "550px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td className="bg-primary title_fixed_width" style={{ fontSize: "15px" }}>
                                        <b className="text-white">MARGE</b>
                                   </td>
                                   <th>Annuel</th>
                                   <th>Mensuel</th>
                                   <th>Journalier</th>
                              </tr>
                              <tr className={"fw-bold"}>
                                   <th>Chiffre d'affaires</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.chiffreAffairesAnnuel.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.chiffreAffairesMensuel.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.chiffreAffairesJournalier.toFixed(2))}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>MBP</th>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.margeBrutProductionAnnuelle!.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center">
                                             <div>{GlobalHelper.separateNumber(results.btc.margeBrutProductionMensuelle!.toFixed())}</div>
                                        </div>
                                   </td>
                                   <td>
                                        <div className="d-flex justify-content-center pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.margeBrutProductionJournaliere!.toFixed(2))}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>MBP en %</th>
                                   <td colSpan={3}>
                                        <div className="d-flex justify-content-center">
                                             <div className="fs-1 me-10">
                                                  <span className={"me-2"}>{GlobalHelper.separateNumber(results.btc.margeBrutProductionEnPourcentage.toFixed(2))}</span>
                                                  {(results.btc.margeBrutProductionEnPourcentage as number) > 31 ? (
                                                       <span className={"fas fa-thumbs-up text-success"} />
                                                  ) : (
                                                       <>
                                                            {(results.btc.margeBrutProductionEnPourcentage as number) >= 25 ? (
                                                                 <span className={"fas fa-face-smile text-primary"} />
                                                            ) : (
                                                                 <span className={"fas fa-thumbs-down text-danger"} />
                                                            )}
                                                       </>
                                                  )}
                                             </div>
                                        </div>
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     const TableInfoSupp = () => {
          return (
               <div style={{ overflowX: "scroll" }}>
                    <table className="table js-calculationsResults" style={{ minWidth: "450px" }}>
                         <tbody className="thead-light">
                              <tr>
                                   <td
                                        scope="col"
                                        colSpan={2}
                                        className="text-center border bg-primary border-white"
                                        style={{ fontSize: "15px", width: "213px", maxWidth: "213px" }}
                                   >
                                        <b className="text-white">INFO. SUPPLÉMENTAIRES</b>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Salaire horaire brut (35h)</th>
                                   <td>
                                        <div className="d-flex justify-content-center pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.salaireHoraireBrut?.toFixed(2))}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Coeff. charges salariales (35h)</th>
                                   <td>
                                        <div className="d-flex justify-content-center pe-2">
                                             <div>{GlobalHelper.separateNumber(results.btc.coeffChargesSalariales.toFixed(2))}</div>
                                        </div>
                                   </td>
                              </tr>
                              <tr>
                                   <th>Ticket restaurant part salarié</th>
                                   <td>{GlobalHelper.separateNumber(results.btc.partJournaliereTicketsRestoSalarie)}</td>
                              </tr>
                              <tr>
                                   <th>Ticket restaurant part employeur</th>
                                   <td>{GlobalHelper.separateNumber(results.btc.partJournaliereTicketsRestoEmployeur)}</td>
                              </tr>
                              <tr>
                                   <th>Ticket restaurant total</th>
                                   <td>{(results.btc.partJournaliereTicketsRestoEmployeur + results.btc.partJournaliereTicketsRestoSalarie).toFixed(2)}</td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          )
     }

     return (
          <>
               <TableSalaire />
               <TableAvantage />
               <TableEquivalentBrut />
               {version === "BM" && (
                    <>
                         <TableCout />
                         <TableMarge />
                    </>
               )}
               <TableInfoSupp />
          </>
     )
}

interface ICostToolResultsPropsModel {
     results: { btc: ISimulationRentabiliteConsultantResponseModel }
     version: "BM" | "CANDIDAT"
}

export default CostToolResults
