import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IBusinessUnitModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import * as Yup from "yup"
import { IAddMetierRequestModel } from "./core/_models"
import { CONST_API_UTILS_GET_BUSINESS_UNITS_ENDPOINT } from "../../endpoints"
import { addMetierRequest } from "./core/_requests"

const AddMetierForm = ({ callback = () => {} }: IAddMetierFormPropsModel) => {
     // Schema
     const schema = Yup.object().shape({
          titre: Yup.string().required().nullable().label(`"Titre"`),
          bu: Yup.number().required().nullable().label(`"Catégorie"`),
     })

     // Init values
     const initialValues: IAddMetierRequestModel = {}

     // Handle submit
     function handleSubmit(values: IAddMetierRequestModel, helpers: FormikHelpers<IAddMetierRequestModel>) {
          helpers.setStatus(null)

          addMetierRequest(values)
               .then(r => {
                    toast.success("Le métier a bien été ajouté")

                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         console.log(helpers.errors)
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Titre */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"titre"}
                                                  value={helpers.values.titre}
                                                  onChange={val => helpers.setFieldValue("titre", val)}
                                                  placeholder={"Titre"}
                                                  isInvalid={!!helpers.errors.titre}
                                                  autoFocus={true}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.titre && <div className={"text-danger"}>{helpers.errors.titre}</div>}</div>
                                        </div>
                                   </div>

                                   {/* BU */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Secteur d'activité</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"bu"}
                                                  url={CONST_API_UTILS_GET_BUSINESS_UNITS_ENDPOINT}
                                                  value={helpers.values.bu}
                                                  onChange={val => helpers.setFieldValue("bu", val)}
                                                  isInvalid={!!helpers.errors.bu}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.bu && <div className={"text-danger"}>{helpers.errors.bu}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Ajouter</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IAddMetierFormPropsModel {
     callback?: (val: IBusinessUnitModel) => void
}

export default AddMetierForm
