import React, { createContext, useContext, useState } from "react"
import {
     IModalViewAndEditSavedFiltersDetailsPropsModel,
     ISavedFilterSearchCandidateInModalModel,
     ISavedFiltersSearchCandidateDataItemResponseModel,
     ISavedFiltersSearchCandidateListContextPropsModel,
     ISavedFiltersSearchCandidateRequestModel,
     ISavedFiltersSearchCandidateResponseModel,
} from "../core/_models"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import { toast } from "react-toastify"
import { CONST_DEFAULT_TOAST_OPTIONS, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyPagination from "@common-utils/MyPagination"
import { useSavedFiltersSearchCandidateContext } from "../SavedFiltersSearchCandidate"
import MyModal from "@common-utils/MyModal"
import DetailFiltersRechercheCandidat from "../../../DetailFiltersRechercheCandidat"
import FormRechercheCandidat from "../../../FormRechercheCandidat"
import { AxiosError } from "axios"
import { useMutation, useQueryClient } from "react-query"
import useSwal from "@common-hooks/useSwal"
import SavedFiltersSearchCandidateListItem from "./_SavedFiltersSearchCandidateListItem"
import BulkSelectionContainerForListings from "../../../../../../../utils/BulkSelectionContainerForListings"
import { IHttpErrorResponseModel } from "@common-models/*"
import { ILogOrSavedFiltersSearchCandidateModel, ISearchCandidatesFiltersModel } from "../../../core/_models"
import {
     createLogSearchCandidate,
     deleteSavedFiltersSearchCandidate,
     duplicateSavedFiltersSearchCandidate,
     getOneSavedFiltersOrOneLogSearchCandidate,
} from "../../../core/_requests"
import { Link, useNavigate } from "react-router-dom"
import { FormikHelpers } from "formik"

// View & edit saved filters
const ModalViewAndEditSavedFiltersDetails = ({ itemDetails, setItemDetails, handleClose, defaultMode = "READ_ONLY" }: IModalViewAndEditSavedFiltersDetailsPropsModel) => {
     const navigate = useNavigate()
     const globalContext = useSavedFiltersSearchCandidateContext()
     const listContext = useSavedFiltersSearchCandidateListContext()
     const queryClient = useQueryClient()

     const [isEditionModeEnabled, setIsEditionModeEnabled] = useState<boolean>(defaultMode === "WRITE")

     // IOneSavedFiltersOrOneLogSearchCandidateResponseModel --> ILogOrSavedFiltersSearchCandidateModel
     const detail: ILogOrSavedFiltersSearchCandidateModel = {
          id: itemDetails.id,
          searchKeywords: itemDetails.searchKeywords,
          searchScope: itemDetails.searchScope,
          searchScopeLabel: itemDetails.searchScopeLabel,
          searchOperateurLogiqueOnlyScopeCurriculum: itemDetails.searchOperateurLogiqueOnlyScopeCurriculum,
          searchOperateurLogiqueOnlyScopeCurriculumLabel: itemDetails.searchOperateurLogiqueOnlyScopeCurriculumLabel,
          disponibilite: itemDetails.disponibilite,
          disponibiliteLabel: itemDetails.disponibiliteLabel,
          disponibiliteDate: itemDetails.disponibiliteDate,
          mobilite: itemDetails.mobilite,
          mobiliteLabel: itemDetails.mobiliteLabel,
          mobiliteRegions: itemDetails.mobiliteRegions,
          mobiliteDepartements: itemDetails.mobiliteDepartements,
          mobiliteVilles: itemDetails.mobiliteVilles,
          niveauEtudes: itemDetails.niveauEtudes,
          niveauEtudesLabel: itemDetails.niveauEtudesLabel,
          fraicheurCV: itemDetails.fraicheurCV,
          fraicheurCVLabel: itemDetails.fraicheurCVLabel,
          viviers: itemDetails.viviers,
          candidatType: itemDetails.candidatType,
          candidatTypeLabel: itemDetails.candidatTypeLabel,
          personnelsAyantSaisiLeCandidat: itemDetails.personnelsAyantSaisiLeCandidat,
          secteursActivite: itemDetails.secteursActivite,
          metiers: itemDetails.metiers,
          seenOrNot: itemDetails.seenOrNot,
          seenOrNotLabel: itemDetails.seenOrNotLabel,
          experienceMin: itemDetails.experienceMin,
          experienceMinLabel: itemDetails.experienceMinLabel,
          // salaireNetMensuelSouhaiteMin: itemDetails.salaireNetMensuelSouhaiteMin,
          // salaireNetMensuelSouhaiteMax: itemDetails.salaireNetMensuelSouhaiteMax,
          avecSuivi: itemDetails.avecSuivi,
          avecSuiviLabel: itemDetails.avecSuiviLabel,
          typeHistoriqueOuSauvegarde: itemDetails.typeHistoriqueOuSauvegarde,
          typeHistoriqueOuSauvegardeLabel: itemDetails.typeHistoriqueOuSauvegardeLabel,
          titreSauvegarde: itemDetails.titreSauvegarde,
          dateCreation: itemDetails.dateCreation,
     }

     // On modify saved filters
     function onModifySavedFiltersCallBack() {
          // Switch back to view mode
          setIsEditionModeEnabled(false)

          // Obtain updated data from the server (there is a more efficient way to do it but, for the sake of simplicity, we'll stick with this approach)
          getOneSavedFiltersOrOneLogSearchCandidate(itemDetails.id as number)
               .then(r => {
                    queryClient.setQueryData<ISavedFiltersSearchCandidateResponseModel | undefined>(
                         globalContext.REACT_QUERY_KEY_SAVED_FILTERS,
                         (prev: ISavedFiltersSearchCandidateResponseModel | undefined) => {
                              if (prev?.data) {
                                   const targetIndex = prev.data.findIndex(item_ => item_.id === itemDetails.id)
                                   let target = prev.data[targetIndex]
                                   prev.data[targetIndex] = {
                                        ...target,
                                        ...r.data,
                                   }
                                   setItemDetails({
                                        ...itemDetails,
                                        ...r.data,
                                   })

                                   return prev
                              }

                              return prev
                         }
                    )
               })
               .catch((e: AxiosError) => {
                    toast.error(e?.response?.data.detail, { autoClose: false })
               })
     }

     function onSearch(values: ISearchCandidatesFiltersModel, helpers: FormikHelpers<ISearchCandidatesFiltersModel>) {
          createLogSearchCandidate(values)
               .then(r => {
                    navigate("/recrutement/candidats/search/results", {
                         relative: "path",
                         state: { log_or_saved_filters_id: r.data.sauvegarde_or_historique_id },
                    })
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error.errors && error.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <MyModal title={<span>{itemDetails.titreSauvegarde}</span>} show={true} size={"lg"} handleClose={() => handleClose()}>
               <>
                    {/* Switches between view mode and edit mode */}
                    <div className={"mb-2"}>
                         {isEditionModeEnabled ? (
                              /* Edition mode */
                              <FormRechercheCandidat pInialValues={detail} handleSaveFiltersCallback={onModifySavedFiltersCallBack} handleSubmitCallback={onSearch} />
                         ) : (
                              /* View mode */
                              <DetailFiltersRechercheCandidat detail={detail} />
                         )}
                    </div>

                    {isEditionModeEnabled ? (
                         /* Edition mode */
                         <button
                              className={"btn btn-sm btn-outline btn-outline-warning d-inline-flex align-items-center"}
                              type={"button"}
                              onClick={() => setIsEditionModeEnabled(prev => !prev)}
                         >
                              Quitter le mode édition
                         </button>
                    ) : (
                         /* View mode */
                         <div className="row mt-5">
                              <div className="col-6">
                                   <button
                                        className={"btn btn-sm btn-outline btn-outline-warning d-inline-flex align-items-center"}
                                        type={"button"}
                                        onClick={() => setIsEditionModeEnabled(prev => !prev)}
                                   >
                                        Editer la sauvegarde <i className={"fas fa-edit ms-1"} />
                                   </button>
                              </div>
                              <div className={"col-6 text-end"}>
                                   <button
                                        className={"btn btn-sm btn-outline btn-outline-danger d-inline-flex align-items-center me-2"}
                                        type={"button"}
                                        onClick={() => {
                                             listContext.handleDeleteItems({ ids: [itemDetails.id as number] }).then(() => handleClose())
                                        }}
                                   >
                                        <span className={"d-none d-lg-block"}>Supprimer</span> <i className={"fas fa-trash ms-lg-1"} />
                                   </button>
                                   <Link
                                        to={"/recrutement/candidats/search/results"}
                                        state={{ log_or_saved_filters_id: itemDetails.id }}
                                        className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                   >
                                        <span className={"d-none d-lg-block"}>Lancer</span> <i className={"fas fa-search ms-lg-1"} />
                                   </Link>
                              </div>
                         </div>
                    )}
               </>
          </MyModal>
     )
}

// List context
const SavedFiltersSearchCandidateListContext = createContext<ISavedFiltersSearchCandidateListContextPropsModel>({} as ISavedFiltersSearchCandidateListContextPropsModel)
export const useSavedFiltersSearchCandidateListContext = () => useContext(SavedFiltersSearchCandidateListContext)
const SavedFiltersSearchCandidateList = () => {
     const { swal } = useSwal()
     const globalContext = useSavedFiltersSearchCandidateContext()
     const queryClient = useQueryClient()

     // This will be used as a loader for the item that is being duplicated
     const [itemIdBeingDuplicated, setItemIdBeingDuplicated] = useState<number | null>(null)

     // Used to view details of an item (you can edit / delete the item inside the modal)
     const [itemDetailsInModal, setItemDetailsInModal] = useState<ISavedFilterSearchCandidateInModalModel>({
          loading: false,
          id: null,
          data: null,
          defaultMode: "READ_ONLY",
     })

     // Deletes an item of the list
     const useDeleteItemMutation = useMutation((filters: ISavedFiltersSearchCandidateRequestModel) => deleteSavedFiltersSearchCandidate(filters))

     // Retrieves list's element details
     function handleShowElementDetails(id: number, defaultMode: "READ_ONLY" | "WRITE" = "READ_ONLY") {
          /* This will enable the loader */
          setItemDetailsInModal(prev => ({
               ...prev,
               loading: true,
               id,
               defaultMode,
          }))
          /* Get the data from server and hydrate the state */
          getOneSavedFiltersOrOneLogSearchCandidate(id)
               .then(r => {
                    setItemDetailsInModal(prev => ({
                         ...prev,
                         data: r.data,
                         loading: false,
                    }))
               })
               .catch(() => {
                    setItemDetailsInModal(prev => ({
                         ...prev,
                         loading: false,
                         data: null,
                         id: null,
                    }))
                    toast.error("Echec lors de la récupération des détails.", {
                         ...CONST_DEFAULT_TOAST_OPTIONS,
                         autoClose: false,
                    })
               })
     }

     // Deletes items from list
     function handleDeleteItems(filters: ISavedFiltersSearchCandidateRequestModel) {
          // Set the title based on whether we are deleting a single item, multiple items, all items on the current page, or all items across all pages.
          // -- Variables that determine whether all items on the current page or across all pages have been marked as checked.
          const areAllElementsInAllPagesChecked =
               globalContext.isBulkSelectionEnabled.areAllElementsInAllPagesChecked &&
               globalContext.isBulkSelectionEnabled.checkedElementsInPage.length === globalContext.useSavedFiltersQuery.data!.totalElementsInPage
          const areAllElementsInPageChecked =
               !globalContext.isBulkSelectionEnabled.areAllElementsInAllPagesChecked &&
               globalContext.isBulkSelectionEnabled.checkedElementsInPage.length === globalContext.useSavedFiltersQuery.data!.totalElementsInPage
          // -- Title
          let title
          if (areAllElementsInAllPagesChecked) {
               // All items across all pages
               title = "Vous êtes sur le point de supprimer tous les éléments correspondant à votre recherche."
          } else if (areAllElementsInPageChecked) {
               // All items on the current page
               title = "Vous êtes sur le point de supprimer tous les éléments de cette page."
          } else {
               title = `Vous êtes sur le point de supprimer ${filters.ids!.length > 1 ? `${filters.ids!.length} sauvegardes` : `une sauvegarde`}.`
          }

          // Sweet alert confirmation
          return swal.fire({
               icon: "warning",
               title,
               text: "Voulez-vous continuer?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return useDeleteItemMutation
                         .mutateAsync(filters)
                         .then(() => {
                              if (areAllElementsInAllPagesChecked || areAllElementsInPageChecked) {
                                   // Condition: All elements on the current page or across all pages have been checked
                                   globalContext.useSavedFiltersQuery.refetch().then()
                              } else {
                                   // Condition: A few elements of the page has been checked (not all)
                                   // -- Update the list state (make elements disappear from list)
                                   queryClient.setQueryData<ISavedFiltersSearchCandidateResponseModel | undefined>(
                                        globalContext.REACT_QUERY_KEY_SAVED_FILTERS,
                                        (prev: ISavedFiltersSearchCandidateResponseModel | undefined) => {
                                             if (prev?.data) {
                                                  let totalDeletedItems = 0
                                                  prev.data = prev.data.filter(item => {
                                                       const shouldBeDeleted = filters.ids!.includes(item.id)
                                                       if (shouldBeDeleted) totalDeletedItems++
                                                       return !shouldBeDeleted
                                                  })
                                                  // Update counters (elements in page and across all pages)
                                                  prev.totalElementsInPage -= totalDeletedItems
                                                  prev.totalElementsInAllPages -= totalDeletedItems
                                                  prev.pageEnd = prev.pageStart + prev.totalElementsInPage - 1

                                                  // Refresh data if there are no items remaining on the current page after deletion, but there are still items on other pages.
                                                  if (prev.totalElementsInPage === 0 && prev.totalElementsInAllPages > 0) {
                                                       globalContext.useSavedFiltersQuery.refetch().then()
                                                  }
                                                  return prev
                                             }

                                             return prev
                                        }
                                   )
                              }
                              // Disable bulk selection
                              globalContext.setIsBulkSelectionEnabled({
                                   enabled: false,
                                   checkedElementsInPage: [],
                                   areAllElementsInAllPagesChecked: false,
                              })

                              // Display a confirmation message to the user.
                              toast.success("La suppression a été effectuée avec succès.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          })
     }

     // Duplicates an item
     function handleDuplication(item: ISavedFiltersSearchCandidateDataItemResponseModel) {
          swal.fire({
               icon: "info",
               title: `Veuillez choisir un titre pour votre sauvegarde.`,
               text: `Cette sauvegarde sera identique à "${item.titreSauvegarde}"`,
               input: "text",
               inputPlaceholder: "Votre titre",
               inputAttributes: {
                    required: "true",
                    autoComplete: "off",
               },
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Veuillez renseigner un titre.") : resolve()
                    })
               },
               preConfirm: async (titre: string) => {
                    return duplicateSavedFiltersSearchCandidate(item.id, titre)
                         .then(r => {
                              // Prepend new duplicated element to the list
                              queryClient.setQueryData<ISavedFiltersSearchCandidateResponseModel | undefined>(
                                   globalContext.REACT_QUERY_KEY_SAVED_FILTERS,
                                   (prev: ISavedFiltersSearchCandidateResponseModel | undefined) => {
                                        if (prev?.data) {
                                             prev.data = [
                                                  {
                                                       ...item,
                                                       id: r.data.id,
                                                       titreSauvegarde: titre,
                                                  },
                                                  ...prev.data,
                                             ]
                                             return prev
                                        }

                                        return prev
                                   }
                              )
                              toast.success("La duplication a été effectuée avec succès.")

                              return r
                         })
                         .then(r => {
                              // Open it in a modal in edition mode
                              handleShowElementDetails(r.data.id, "WRITE")
                         })
                         .catch((e: AxiosError) => {
                              const error: IHttpErrorResponseModel = e.response?.data
                              toast.error(error?.detail, { autoClose: false })
                         })
                         .finally(() => setItemIdBeingDuplicated(null))
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // BULK SELECTION: handling the "check all elements" button click
     function handleCheckAllElementsInPageCallBack() {
          const ids = globalContext.useSavedFiltersQuery.data!.data.map(item => item.id)
          globalContext.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               checkedElementsInPage: ids,
          }))
     }

     // BULK SELECTION: handling the "check all elements across all pages" button click
     function handleCheckAllElementsAcrossAllPagesCallBack() {
          globalContext.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               areAllElementsInAllPagesChecked: true,
          }))
     }

     // BULK SELECTION: handling the "Quit" button click
     function handleQuitBulkSelectionModeCallBack() {
          globalContext.setIsBulkSelectionEnabled({
               enabled: false,
               checkedElementsInPage: [],
               areAllElementsInAllPagesChecked: false,
          })
     }

     return (
          <SavedFiltersSearchCandidateListContext.Provider
               value={{
                    handleDeleteItems,
                    handleDuplication,
                    itemDetailsInModal,
                    handleShowElementDetails,
                    itemIdBeingDuplicated,
               }}
          >
               <MyCard>
                    <MyCard.Header
                         isCollapseIconShown={false}
                         rightSideContent={
                              <>
                                   {globalContext.useSavedFiltersQuery.isFetching ? (
                                        <MySimpleSpinner size={"sm"} />
                                   ) : (
                                        <>
                                             {globalContext.useSavedFiltersQuery.data && globalContext.useSavedFiltersQuery.data.totalElementsInPage > 0 && (
                                                  <div className={"d-flex align-items-center"}>
                                                       {/* Pagination component*/}
                                                       <MyPagination
                                                            page={globalContext.filters.page as number}
                                                            pageStart={globalContext.useSavedFiltersQuery.data.pageStart}
                                                            pageEnd={globalContext.useSavedFiltersQuery.data.pageEnd}
                                                            totalPages={globalContext.useSavedFiltersQuery.data.totalPages}
                                                            totalElementsInAllPages={globalContext.useSavedFiltersQuery.data.totalElementsInAllPages}
                                                            onPreviousPageCallBack={() => {
                                                                 globalContext.setFilters(prev => ({
                                                                      ...prev,
                                                                      page: (prev.page as number) - 1,
                                                                 }))
                                                            }}
                                                            onNextPageCallBack={() => {
                                                                 globalContext.setFilters(prev => ({
                                                                      ...prev,
                                                                      page: (prev.page as number) + 1,
                                                                 }))
                                                            }}
                                                       />
                                                  </div>
                                             )}
                                        </>
                                   )}
                              </>
                         }
                    >
                         Mes filtres
                    </MyCard.Header>

                    <MyCard.Body noPadding={true}>
                         <>
                              {/* Fetching */}
                              {globalContext.useSavedFiltersQuery.isFetching ? (
                                   <div className={"text-center p-5"}>
                                        <MySimpleSpinner classNames={"ms-2"} />
                                   </div>
                              ) : (
                                   <>
                                        {/* In case of error */}
                                        {globalContext.useSavedFiltersQuery.error ? (
                                             <MyAlert type={"danger"}>{globalContext.useSavedFiltersQuery.error.response?.data.detail}</MyAlert>
                                        ) : (
                                             <>
                                                  {/* CASE: no data */}
                                                  {globalContext.useSavedFiltersQuery.data && globalContext.useSavedFiltersQuery.data.data.length === 0 && (
                                                       <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>
                                                  )}

                                                  {/* CASE: If some data */}
                                                  {globalContext.useSavedFiltersQuery.data && globalContext.useSavedFiltersQuery.data.data.length > 0 && (
                                                       <>
                                                            {/* Mass selection actions component */}
                                                            {globalContext.isBulkSelectionEnabled.enabled && (
                                                                 <BulkSelectionContainerForListings
                                                                      areAllElementsInAllPagesChecked={globalContext.isBulkSelectionEnabled.areAllElementsInAllPagesChecked}
                                                                      checkedElementIdsInPage={globalContext.isBulkSelectionEnabled.checkedElementsInPage}
                                                                      totalElementsInPage={globalContext.useSavedFiltersQuery.data.totalElementsInPage}
                                                                      totalElementsInAllPages={globalContext.useSavedFiltersQuery.data.totalElementsInAllPages}
                                                                      handleCheckAllElementsInPageCallBack={handleCheckAllElementsInPageCallBack}
                                                                      handleCheckAllElementsAcrossAllPagesCallBack={handleCheckAllElementsAcrossAllPagesCallBack}
                                                                      handleQuitBulkSelectionModeCallBack={handleQuitBulkSelectionModeCallBack}
                                                                      AllElementsCheckedAcrossAllPagesActionsChildren={
                                                                           <>
                                                                                <button
                                                                                     className={"btn btn-sm btn-outline btn-outline-danger p-0 px-3 py-2 me-2"}
                                                                                     onClick={() => handleDeleteItems(globalContext.filters).then()}
                                                                                >
                                                                                     <i className={"fas fa-trash fs-5"} />
                                                                                </button>
                                                                           </>
                                                                      }
                                                                      AllOrFewElementsCheckedInCurrentPageActionsChildren={
                                                                           <>
                                                                                <button
                                                                                     className={"btn btn-sm btn-outline btn-outline-danger p-0 px-3 py-2 me-2"}
                                                                                     onClick={() =>
                                                                                          handleDeleteItems({
                                                                                               ids: globalContext.isBulkSelectionEnabled.checkedElementsInPage,
                                                                                          }).then()
                                                                                     }
                                                                                >
                                                                                     <i className={"fas fa-trash fs-5"} />
                                                                                </button>
                                                                           </>
                                                                      }
                                                                 />
                                                            )}
                                                            {/* List elements */}
                                                            <div
                                                                 className={"hover-scroll-overlay-y"}
                                                                 style={{
                                                                      maxHeight: globalContext.isBulkSelectionEnabled.enabled ? "59vh" : "65vh",
                                                                 }}
                                                            >
                                                                 {globalContext.useSavedFiltersQuery.data.data.map((item: ISavedFiltersSearchCandidateDataItemResponseModel) => (
                                                                      <div className="border border-bottom" key={item.id}>
                                                                           <SavedFiltersSearchCandidateListItem item={item} />
                                                                      </div>
                                                                 ))}
                                                            </div>
                                                       </>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}

                              {/* The modal will be displayed whenever we click the eye icon to view the details. */}
                              {itemDetailsInModal.data && (
                                   <ModalViewAndEditSavedFiltersDetails
                                        itemDetails={itemDetailsInModal.data}
                                        setItemDetails={data =>
                                             setItemDetailsInModal(prev => ({
                                                  ...prev,
                                                  data,
                                             }))
                                        }
                                        handleClose={() =>
                                             setItemDetailsInModal({
                                                  loading: false,
                                                  id: null,
                                                  data: null,
                                             })
                                        }
                                        defaultMode={itemDetailsInModal.defaultMode}
                                   />
                              )}
                         </>
                    </MyCard.Body>
               </MyCard>
          </SavedFiltersSearchCandidateListContext.Provider>
     )
}

export default SavedFiltersSearchCandidateList
