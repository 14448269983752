import React from "react"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { CandidatureModel, ICandidatCommentaireModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { getCandidatures } from "../../../../core/_requests"
import { useSearchResultsContext } from "../../CandidatsRechercheResults"
import MyAlert from "@common-utils/MyAlert"
import MyTooltip from "@common-utils/MyTooltip"
import { useSearchResultsPreviewContext } from "../_CandidatsRechercheResultsPreview"
import GlobalHelper from "@common-helpers/GlobalHelper"

const REACT_QUERY_KEY_GET_CANDIDATURES_QUERY = "REACT_QUERY_KEY_GET_CANDIDATURES_QUERY"

export const CandidatsRechercheResultsPreviewHelpersCandidatures = () => {
     const globalContext = useSearchResultsContext()
     const previewContext = useSearchResultsPreviewContext()

     const candidaturesQuery = useQuery<CandidatureModel[], AxiosError>([REACT_QUERY_KEY_GET_CANDIDATURES_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id], () => {
          return getCandidatures(globalContext.itemInPreview?.selectedItem.candidat.id as number)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
               .finally(() => {
                    // Stop loading preview
                    previewContext.setIsPreviewBodyLoading(false)
                    globalContext.setItemInPreview(prev =>
                         prev
                              ? {
                                     ...prev,
                                     isSelectedItemLoading: false,
                                }
                              : null
                    )
               })
     })

     const CandidatureItem = ({ item }: { item: CandidatureModel }) => {
          return (
               <tr>
                    <td>{item.date ? GlobalHelper.firstLetterUppercase(item.date.format("dddd Do MMM YYYY")) : ""}</td>
                    <td>
                         <MyTooltip title={<span>Posté par {item.annonce?.personnel?.prenomNom}</span>}>
                              <span>{item.annonce?.intitule}</span>
                         </MyTooltip>
                    </td>
                    <td>{item.jobBoard}</td>
               </tr>
          )
     }

     return (
          <>
               <div className={"preview-height"}>
                    {/* In case of error */}
                    {candidaturesQuery.error ? (
                         <MyAlert type={"danger"}>{candidaturesQuery.error.response?.data.detail}</MyAlert>
                    ) : (
                         <>
                              <>
                                   {candidaturesQuery.data && candidaturesQuery.data.length > 0 && (
                                        <table className={"table table-rounded table-striped  gy-7 gs-7 m-0 p-0"}>
                                             <thead className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                                  <tr>
                                                       <th>Date de candidature</th>
                                                       <th>Annonce</th>
                                                       <th>Job board</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {candidaturesQuery.data.map((item: ICandidatCommentaireModel, index) => (
                                                       <CandidatureItem item={item} key={index} />
                                                  ))}
                                             </tbody>
                                        </table>
                                   )}
                              </>
                         </>
                    )}
               </div>
          </>
     )
}
