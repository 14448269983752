import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { IConsultantDocumentModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { generateOrUploadContratTravail, getDemandeEmbaucheContratTravail } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import MyAlert from "@common-utils/MyAlert"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { IGenerationOuUploadContratTravailParSiegeFormikInitValuesModel, IGenerationOuUploadContratTravailParSiegeRequestModel } from "./core/_models"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyFileField from "@common-utils/fields/MyFileField"
import MyTextareaField from "@common-utils/fields/MyTextareaField"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

const REACT_QUERY_KEY_GET_CONTRAT = "REACT_QUERY_KEY_GET_CONTRAT"
export const DemandeEmbaucheDetailsValidationSiege = ({
     consultant_ID,
     onContractLoadCallback = () => {},
     onSubmitCallback = () => {},
}: IDemandeEmbaucheDetailsValidationSiegePropsModel) => {
     const queryClient = useQueryClient()

     const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(true)

     // Query
     const contratQuery = useQuery<IConsultantDocumentModel | null, AxiosError>(REACT_QUERY_KEY_GET_CONTRAT, () => {
          return getDemandeEmbaucheContratTravail(consultant_ID)
               .then(r => {
                    onContractLoadCallback(r.data)
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const schema = Yup.object().shape({
          generationOuUpload: Yup.string().label(`"Contrat"`).required(),
          contrat: Yup.string()
               .when("generationOuUpload", {
                    is: "GENERATION_OU_UPLOAD__UPLOAD",
                    then: schema => schema.required(),
               })
               .label(`"Contrat"`),
          commentaire: Yup.string().label(`"Contrat"`),
     })

     const initialValues: IGenerationOuUploadContratTravailParSiegeFormikInitValuesModel = {}

     function handleSubmit(
          values: IGenerationOuUploadContratTravailParSiegeFormikInitValuesModel,
          helpers: FormikHelpers<IGenerationOuUploadContratTravailParSiegeFormikInitValuesModel>
     ) {
          helpers.setStatus(null)

          const payload: IGenerationOuUploadContratTravailParSiegeRequestModel = {
               generationOuUpload: values.generationOuUpload as "GENERATION_OU_UPLOAD__GENERATION" | "GENERATION_OU_UPLOAD__UPLOAD",
               contrat: values.contrat,
               commentaire: values.commentaire,
          }

          generateOrUploadContratTravail(consultant_ID, payload)
               .then(r => {
                    queryClient.setQueryData<IConsultantDocumentModel | null | undefined>(REACT_QUERY_KEY_GET_CONTRAT, () => {
                         return r.data
                    })
                    onSubmitCallback(values.generationOuUpload as "GENERATION_OU_UPLOAD__GENERATION" | "GENERATION_OU_UPLOAD__UPLOAD")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               {contratQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {!contratQuery.isFetching && contratQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{contratQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => contratQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* IF NO CONTRAT DE TRAVAIL */}
               {!contratQuery.isFetching && !contratQuery.isError && contratQuery.data === null && (
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        {helpers.status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {helpers.status}
                                             </MyAlert>
                                        )}

                                        {/* generationOuUpload */}
                                        <div className="row">
                                             <div className="col-md-6 mb-2">
                                                  <MyRadioWithLabelAndDescriptionField
                                                       name={"generationOuUpload"}
                                                       value={"GENERATION_OU_UPLOAD__UPLOAD"}
                                                       isChecked={helpers.values.generationOuUpload === "GENERATION_OU_UPLOAD__UPLOAD"}
                                                       label={"Saisie manuelle"}
                                                       description={"Joignez un contrat de travail"}
                                                       iconFontAwesomeClassName={"upload"}
                                                       color={"primary"}
                                                       isInvalid={!!helpers.errors.generationOuUpload}
                                                       onChange={val => helpers.setFieldValue("generationOuUpload", val)}
                                                  />
                                             </div>
                                             <div className="col-md-6 mb-2">
                                                  <MyRadioWithLabelAndDescriptionField
                                                       name={"generationOuUpload"}
                                                       value={"GENERATION_OU_UPLOAD__GENERATION"}
                                                       isChecked={helpers.values.generationOuUpload === "GENERATION_OU_UPLOAD__GENERATION"}
                                                       label={"Génération automatique (recommandé)"}
                                                       description={"Générer un contrat pré-rempli éditable en ligne"}
                                                       iconFontAwesomeClassName={"bolt"}
                                                       color={"success"}
                                                       isInvalid={!!helpers.errors.generationOuUpload}
                                                       onChange={val => helpers.setFieldValue("generationOuUpload", val)}
                                                  />
                                             </div>
                                        </div>

                                        <div className="separator mb-5"></div>

                                        {/* Document */}
                                        {helpers.values.generationOuUpload === "GENERATION_OU_UPLOAD__UPLOAD" && (
                                             <>
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez un document</label>

                                                       <div className="col-lg-8">
                                                            <MyFileField
                                                                 name={"contrat"}
                                                                 isInvalid={!!helpers.errors.contrat}
                                                                 onChange={val => helpers.setFieldValue("contrat", val)}
                                                            />
                                                            {helpers.errors.contrat && <div className={"text-danger"}>{helpers.errors.contrat}</div>}
                                                       </div>
                                                  </div>

                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6">Commentaire</label>

                                                       <div className="col-lg-8">
                                                            <MyTextareaField
                                                                 name={"contrat"}
                                                                 value={helpers.values.commentaire}
                                                                 isInvalid={!!helpers.errors.commentaire}
                                                                 onChange={val => helpers.setFieldValue("commentaire", val)}
                                                                 placeholder={"Commentaire (optionnel)"}
                                                            />
                                                            {helpers.errors.commentaire && <div className={"text-danger"}>{helpers.errors.commentaire}</div>}
                                                       </div>
                                                  </div>
                                             </>
                                        )}

                                        {helpers.values.generationOuUpload && (
                                             <>
                                                  {/* Submit button */}
                                                  <div className={"d-flex justify-content-end"}>
                                                       <button
                                                            className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                            type={"submit"}
                                                            disabled={helpers.isSubmitting}
                                                       >
                                                            {!helpers.isSubmitting ? (
                                                                 <>
                                                                      {helpers.values.generationOuUpload === "GENERATION_OU_UPLOAD__GENERATION" ? (
                                                                           <>
                                                                                Générer <i className={"fas fa-bolt fs-4 ms-1"} />
                                                                           </>
                                                                      ) : (
                                                                           <>
                                                                                Envoyer <i className={"fas fa-upload fs-4 ms-1"} />
                                                                           </>
                                                                      )}
                                                                 </>
                                                            ) : (
                                                                 <MySimpleSpinner size={"sm"} />
                                                            )}
                                                       </button>
                                                  </div>
                                             </>
                                        )}
                                   </Form>
                              )
                         }}
                    </Formik>
               )}

               {/* IF CONTRAT DE TRAVAIL EXISTS */}
               {!contratQuery.isFetching && !contratQuery.isError && contratQuery.data && (
                    <MyLoadingOverlay isActive={isDocumentLoading}>
                         <iframe
                              onLoad={() => setIsDocumentLoading(false)}
                              src={FileAndDriveHelper.getUrlOfDriveFileEditMode(contratQuery.data.googleDriveID as string)}
                              className={"vh-100 w-100"}
                         />
                    </MyLoadingOverlay>
               )}
          </>
     )
}

interface IDemandeEmbaucheDetailsValidationSiegePropsModel {
     consultant_ID: number
     onSubmitCallback: (generationOuUpload: "GENERATION_OU_UPLOAD__GENERATION" | "GENERATION_OU_UPLOAD__UPLOAD") => void
     onContractLoadCallback?: (val: IConsultantDocumentModel) => void
}

export default DemandeEmbaucheDetailsValidationSiege
