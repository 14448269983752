import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { Navigate, Route, Routes } from "react-router-dom"
import Calculator from "./calculator/Calculator"
import PdfUtils from "./pdf-utils/PdfUtils"

const ToolsRouting = () => {
     return (
          <Routes>
               <Route
                    path="/calculator/"
                    element={
                         <>
                              <PageTitle breadcrumbs={toolsCalculatorBreadcrumbs} />
                              <Calculator />
                         </>
                    }
               ></Route>
               <Route
                    path="/pdf-utils/"
                    element={
                         <>
                              <PageTitle breadcrumbs={toolsPdfUtilsBreadcrumbs} />
                              <PdfUtils />
                         </>
                    }
               ></Route>

               <Route index element={<Navigate to="/tools/calculator/" />} />
          </Routes>
     )
}

const toolsBreadcrumbs: Array<PageLink> = [
     {
          title: "Outils",
          path: "/tools/",
     },
]
const toolsCalculatorBreadcrumbs: Array<PageLink> = [
     ...toolsBreadcrumbs,
     {
          title: "Calculatrice",
          path: `/tools/calculator/`,
     },
]

const toolsPdfUtilsBreadcrumbs: Array<PageLink> = [
     ...toolsBreadcrumbs,
     {
          title: "PDF Utils",
          path: `/tools/pdf-utils/`,
     },
]
export default ToolsRouting
