import React, { useEffect, useState } from "react"
import MyCard from "@common-utils/MyCard"
import { useBonsLivraisonNonFacturesOfGivenMonthListingContext } from "./BonsLivraisonNonFacturesOfGivenMonthListing"
import { IBonsLivraisonNonFacturesOfGivenMonthItemResponseModel } from "./core/_models"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { Link } from "react-router-dom"
import { IConsultantCraNdfDcSignesDetailsModel, IConsultantDeclarationMensuelleModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"
import MyModal from "@common-utils/MyModal"
import ConsultantDeclarationDetails from "../../../business/consultants/declaration/details/_ConsultantDeclarationDetails"

const BonsLivraisonManquantsOfGivenMonthListingPreview = () => {
     const globalContext = useBonsLivraisonNonFacturesOfGivenMonthListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.bl.id === selectedItemInPreview.bl.id) as IBonsLivraisonNonFacturesOfGivenMonthItemResponseModel
     const ptf = itemInPreview.bl.clientBonCommande?.propalPeriode?.propal

     // States
     const [selectedTab, setSelectedTab] = useState<"BL" | "BC" | "CRA">("BL")
     const [consultantCraExcelInModal, setConsultantCraExcelInModal] = useState<{ isLoading: boolean; item: IConsultantCraNdfDcSignesDetailsModel }>()
     const [declarationInModal, setDeclarationInModal] = useState<IConsultantDeclarationMensuelleModel>()

     useEffect(() => {
          setSelectedTab("BL")
     }, [itemInPreview])

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <>
                              <button
                                   type={"button"}
                                   className={`btn btn-sm ${selectedTab === "BL" ? "btn-dark" : "btn-outline btn-outline-dark"} me-2 px-3 py-2 d-flex align-items-center`}
                                   onClick={() => {
                                        if (selectedTab === "BL") return
                                        setSelectedTab("BL")
                                        globalContext.setItemInPreview(prev => prev && { ...prev, isSelectedItemLoading: true })
                                   }}
                              >
                                   BL <i className={"fas fa-file-alt ms-1 fs-6"} />
                              </button>
                              <button
                                   type={"button"}
                                   className={`btn btn-sm ${selectedTab === "BC" ? "btn-dark" : "btn-outline btn-outline-dark"} me-2 px-3 py-2 d-flex align-items-center`}
                                   onClick={() => {
                                        if (selectedTab === "BC") return
                                        setSelectedTab("BC")
                                        globalContext.setItemInPreview(prev => prev && { ...prev, isSelectedItemLoading: true })
                                   }}
                              >
                                   BC <i className={"fas fa-file-alt ms-1 fs-6"} />
                              </button>
                              {(itemInPreview.consultantsCraExcel.length > 0 || itemInPreview.declarations.length > 0) && (
                                   <button
                                        type={"button"}
                                        className={`btn btn-sm ${selectedTab === "CRA" ? "btn-dark" : "btn-outline btn-outline-dark"} px-3 py-2 d-flex align-items-center`}
                                        onClick={() => {
                                             if (selectedTab === "CRA") return
                                             setSelectedTab("CRA")
                                             globalContext.setItemInPreview(prev => prev && { ...prev, isSelectedItemLoading: false })
                                        }}
                                   >
                                        CRA <i className={"fas fa-file-alt ms-1 fs-6"} />
                                   </button>
                              )}
                         </>
                    }
               >
                    {ptf?.client?.nom} |{" "}
                    <Link to={`/client/ptf/${ptf?.id}/details`} target={"_blank"}>
                         {ptf?.reference}
                    </Link>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <MyLoadingOverlay isActive={globalContext.itemInPreview?.isSelectedItemLoading as boolean}>
                         <>
                              {selectedTab === "BL" && (
                                   <iframe
                                        src={FileAndDriveHelper.getUrlOfDriveFilePreview(itemInPreview.bl.googleDriveID as string)}
                                        style={{ width: "100%", minHeight: "75.1vh", overflowY: "scroll" }}
                                        onLoad={() => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))}
                                   />
                              )}
                              {selectedTab === "BC" && (
                                   <iframe
                                        src={FileAndDriveHelper.getUrlOfDriveFilePreview(itemInPreview.bl.clientBonCommande?.googleDriveID as string)}
                                        style={{ width: "100%", minHeight: "75.1vh", overflowY: "scroll" }}
                                        onLoad={() => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))}
                                   />
                              )}
                              {selectedTab === "CRA" && (
                                   <div className={"p-8"}>
                                        {/* CASE: aucun CRA */}
                                        {itemInPreview.consultantsCraExcel.length === 0 && itemInPreview.declarations.length === 0 && (
                                             <MyAlert type={"primary"}>Aucun CRA de disponible</MyAlert>
                                        )}

                                        {/* CASE: CRA (old system) */}
                                        {itemInPreview.consultantsCraExcel.length > 0 && (
                                             <div className="row">
                                                  {/* CRA Excel (old system) */}
                                                  {itemInPreview.consultantsCraExcel.map((item, key) => (
                                                       <div className={`col-12 col-sm-4 d-flex justify-content-center  mt-4`}>
                                                            <button
                                                                 type={"button"}
                                                                 key={key}
                                                                 className={`btn btn-sm btn-outline btn-outline-dark d-flex align-items-center`}
                                                                 onClick={() =>
                                                                      setConsultantCraExcelInModal({
                                                                           isLoading: true,
                                                                           item,
                                                                      })
                                                                 }
                                                            >
                                                                 CRA de {item.mois?.consultant?.prenomNom} <i className={"fas fa-file-alt ms-2"} />
                                                            </button>
                                                       </div>
                                                  ))}

                                                  {itemInPreview.consultantsCraExcel.length > 0 && itemInPreview.declarations.length > 0 && (
                                                       <div className={"separator separator-dashed my-4"} />
                                                  )}

                                                  {/* Déclaration (new system) */}
                                                  {itemInPreview.declarations.map((item, key) => (
                                                       <div className={`col-12 col-sm-4 d-flex justify-content-center  mt-4`}>
                                                            <button
                                                                 type={"button"}
                                                                 key={key}
                                                                 className={`btn btn-sm btn-outline btn-outline-dark d-flex align-items-center`}
                                                                 onClick={() => setDeclarationInModal(item)}
                                                            >
                                                                 CRA de {item?.consultant?.prenomNom} <i className={"fas fa-file-alt ms-2"} />
                                                            </button>
                                                       </div>
                                                  ))}
                                             </div>
                                        )}

                                        {/* CASE: CRA (new system) */}
                                   </div>
                              )}

                              {/* selected CRA Excel */}
                              {consultantCraExcelInModal && (
                                   <MyGoogleDriveFileViewerInModal
                                        googleDriveID={consultantCraExcelInModal.item.googleDriveId as string}
                                        googleDriveMimetype={consultantCraExcelInModal.item.googleDriveMimeType as string}
                                        modalTitle={<>CRA de {consultantCraExcelInModal.item.mois?.consultant?.prenomNom}</>}
                                        handleCloseModal={() => setConsultantCraExcelInModal(undefined)}
                                        modalSize={"lg"}
                                   />
                              )}

                              {/* Selected déclaration */}
                              {declarationInModal && (
                                   <MyModal
                                        title={<>Déclaration de {declarationInModal.consultant?.prenomNom}</>}
                                        show={true}
                                        handleClose={() => setDeclarationInModal(undefined)}
                                        fullscreen
                                   >
                                        <ConsultantDeclarationDetails declaration={declarationInModal} defaultStep={"cra"} />
                                   </MyModal>
                              )}
                         </>
                    </MyLoadingOverlay>
               </MyCard.Body>
          </MyCard>
     )
}

export default BonsLivraisonManquantsOfGivenMonthListingPreview
