import { CONST_DISPONIBILITE_DATE_EXACTE, CONST_MOBILITE_DEPARTEMENTAL, CONST_MOBILITE_PAR_VILLE, CONST_MOBILITE_REGIONAL } from "@common-constants/*"
import axios from "axios"
import {
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_AGENCE_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_CLIENT_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_MESSAGE_REPONDEUR_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_TEL_DMS_ENDPOINT,
} from "../../../../../endpoints"
import { IAddEntretienAgenceRequestModel, IAddEntretienClientRequestModel, IAddEntretienTelRequestModel, IAddMessageRepondeurRequestModel } from "./_models"
import { ICandidatModel, ICandidatSuiviModel } from "@common-models/*"

export function addSuiviMessageRepondeur(id: number, payload: IAddMessageRepondeurRequestModel) {
     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_MESSAGE_REPONDEUR_ENDPOINT(id), payload)
}

export function addSuiviEntretienTel(id: number, suivi: IAddEntretienTelRequestModel) {
     const formData = new FormData()
     formData.append("suivi[notes]", suivi.notes || "")
     if (suivi.disponibilite) formData.append("suivi[disponibilite]", suivi.disponibilite)
     if (suivi.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && suivi.disponibiliteDate) formData.append("suivi[disponibiliteDate]", suivi.disponibiliteDate)
     if (suivi.mobilite) formData.append("suivi[mobilite]", suivi.mobilite)
     if (suivi.mobilite === CONST_MOBILITE_REGIONAL && suivi.mobiliteRegions) {
          suivi.mobiliteRegions.map(item => formData.append("suivi[mobiliteRegions][]", item.toString()))
     }
     if (suivi.mobilite === CONST_MOBILITE_DEPARTEMENTAL && suivi.mobiliteDepartements) {
          suivi.mobiliteDepartements.map(item => formData.append("suivi[mobiliteDepartements][]", item.toString()))
     }
     if (suivi.mobilite === CONST_MOBILITE_PAR_VILLE && suivi.mobiliteVilles) {
          suivi.mobiliteVilles.map(item => formData.append("suivi[mobiliteVilles][]", item.toString()))
     }
     if (suivi.salaireNetMensuelSouhaite) formData.append("suivi[salaireNetMensuelSouhaite]", suivi.salaireNetMensuelSouhaite.toString())
     if (suivi.document) formData.append("document", suivi.document)

     return axios.post<{ candidat: ICandidatModel; suivi: ICandidatSuiviModel }>(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_TEL_DMS_ENDPOINT(id), formData)
}

export function addSuiviEntretienAgence(id: number, suivi: IAddEntretienAgenceRequestModel) {
     const formData = new FormData()
     formData.append("suivi[notes]", suivi.notes || "")
     if (suivi.document) formData.append("document", suivi.document)

     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_AGENCE_ENDPOINT(id), formData)
}

export function addSuiviEntretienClient(id: number, suivi: IAddEntretienClientRequestModel) {
     const formData = new FormData()
     formData.append("suivi[client]", suivi.client.toString())
     formData.append("suivi[notes]", suivi.notes || "")
     if (suivi.document) formData.append("document", suivi.document)

     return axios.post(CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_CLIENT_ENDPOINT(id), formData)
}
