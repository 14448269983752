import { IConsultantSuiviReportingRequestModel } from "./core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import {
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_EN_AGENCE,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_SUR_SITE,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_VIA_TEL,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_APPLICATION_DOCX,
     CONST_MIME_APPLICATION_ODT,
     CONST_MIME_APPLICATION_PDF,
} from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import { consultantSuiviReportingRequest } from "./core/_requests"
import * as Yup from "yup"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyFileField from "@common-utils/fields/MyFileField"
import MySelectField from "@common-utils/fields/MySelectField"

const ConsultantSuiviReporting = ({
     consultant,
     annulerCallback = () => {},
     onSubmitCallback = () => {},
     setModalNoPaddingCallback = () => {},
     setModalSizeCallback = () => {},
}: IPropsModel) => {
     const [generatedReporting, setGeneratedReporting] = useState<{
          loading: boolean
          googleDriveID: string
     }>()

     // Form schema
     const schema = Yup.object().shape({
          type: Yup.string().required().label(`"Type"`),
          documentUploadedOrGenerated: Yup.string().required().nullable().label(`"Génération ou upload"`),
          document: Yup.mixed()
               .test("fileFormat", "Seul le format Word et PDF est autorisé.", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_DOCX, CONST_MIME_APPLICATION_ODT, CONST_MIME_APPLICATION_PDF].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Document reporting"`)
               .when("documentUploadedOrGenerated", {
                    is: "DOCUMENT_UPLOADED",
                    then: schema => schema.required(),
               }),
     })

     // Form initial values
     const initialValues: IConsultantSuiviReportingRequestModel = {}

     // Handle submit
     function handleSubmit(values: IConsultantSuiviReportingRequestModel, helpers: FormikHelpers<IConsultantSuiviReportingRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviReportingRequest(consultant.id as number, values)
               .then(r => {
                    if (values.documentUploadedOrGenerated === "DOCUMENT_UPLOADED") {
                         onSubmitCallback()
                         toast.success("Le document de reporting a bien été uploadé.")
                    } else {
                         setModalNoPaddingCallback(true)
                         setModalSizeCallback("fullscreen")
                         setGeneratedReporting({ loading: true, googleDriveID: r.data.reportingGoogleDriveID })
                         toast.info("Chargement du squelette en cours ...", { autoClose: false })
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     if (generatedReporting) {
          return (
               <>
                    <div className="text-center mt-2">
                         <button
                              type={"button"}
                              className={"btn btn-sm btn-primary"}
                              onClick={() => {
                                   onSubmitCallback()
                                   toast.success(`Le changement de TJM a bien été enregistré.`)
                              }}
                         >
                              TERMINER <i className={"fas fa-check-circle"} />
                         </button>
                    </div>
                    <MyLoadingOverlay isActive={generatedReporting.loading}>
                         <iframe
                              onLoad={() => setGeneratedReporting(prev => prev && { ...prev, loading: false })}
                              src={FileAndDriveHelper.getUrlOfDriveFileEditMode(generatedReporting.googleDriveID)}
                              className={"vh-100 w-100"}
                         />
                    </MyLoadingOverlay>
               </>
          )
     } else {
          return (
               <>
                    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                         {helpers => {
                              console.log(helpers.errors)
                              return (
                                   <Form noValidate autoComplete="off">
                                        {helpers.status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {helpers.status}
                                             </MyAlert>
                                        )}

                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Type de suivi</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"type"}
                                                       options={[
                                                            { value: CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_VIA_TEL, label: "Par tél." },
                                                            { value: CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_SUR_SITE, label: "Sur site client" },
                                                            { value: CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_EN_AGENCE, label: "En agence" },
                                                       ]}
                                                       value={helpers.values.type}
                                                       isInvalid={!!helpers.errors.type}
                                                       onChange={val => helpers.setFieldValue("type", val)}
                                                  />
                                                  {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}
                                             </div>
                                        </div>

                                        {/* Upload ou Génération*/}
                                        <div className="border border-dashed p-4 mb-4">
                                             <h4 className={"mb-4"}>Document</h4>
                                             <div className="separator separator-dashed mb-6" />
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MyRadioWithLabelAndDescriptionField
                                                            name={"documentUploadedOrGenerated"}
                                                            value={"DOCUMENT_UPLOADED"}
                                                            isChecked={helpers.values.documentUploadedOrGenerated === "DOCUMENT_UPLOADED"}
                                                            label={"Saisie manuelle"}
                                                            description={"Joignez votre propre reporting"}
                                                            iconFontAwesomeClassName={"upload"}
                                                            color={"primary"}
                                                            isInvalid={!!helpers.errors.documentUploadedOrGenerated}
                                                            onChange={val => helpers.setFieldValue("documentUploadedOrGenerated", val)}
                                                       />
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyRadioWithLabelAndDescriptionField
                                                            name={"documentUploadedOrGenerated"}
                                                            value={"DOCUMENT_GENERATED"}
                                                            isChecked={helpers.values.documentUploadedOrGenerated === "DOCUMENT_GENERATED"}
                                                            label={
                                                                 <span>
                                                                      Génération automatique <br /> ( recommandé )
                                                                 </span>
                                                            }
                                                            description={"Générer un squelette de reporting pré-rempli éditable en ligne"}
                                                            iconFontAwesomeClassName={"bolt"}
                                                            color={"success"}
                                                            isInvalid={!!helpers.errors.documentUploadedOrGenerated}
                                                            onChange={val => helpers.setFieldValue("documentUploadedOrGenerated", val)}
                                                       />
                                                  </div>
                                             </div>

                                             {helpers.values.documentUploadedOrGenerated === "DOCUMENT_UPLOADED" && (
                                                  <div className="row mt-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Document</label>

                                                       <div className="col-lg-8">
                                                            <MyFileField
                                                                 name={"document"}
                                                                 onChange={val => helpers.setFieldValue("document", val)}
                                                                 isInvalid={!!helpers.errors.document}
                                                                 accept={`${CONST_MIME_APPLICATION_DOCX}, ${CONST_MIME_APPLICATION_PDF}`}
                                                            />
                                                            <div className={"mb-2"}>
                                                                 {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                                            </div>
                                                       </div>
                                                  </div>
                                             )}

                                             {helpers.values.documentUploadedOrGenerated === "DOCUMENT_GENERATED" && (
                                                  <MyAlert type={"primary"} classNames={"mt-4"}>
                                                       <b className={"text-dark"}>Vous avez opter pour une génération automatique</b>. Une fois que vous aurez validé le formulaire,
                                                       un document sera généré et vous pourrez ensuite le modifier en ligne.
                                                  </MyAlert>
                                             )}
                                        </div>
                                        <div className={"mb-2"}>
                                             {helpers.errors.documentUploadedOrGenerated && <div className={"text-danger"}>{helpers.errors.documentUploadedOrGenerated}</div>}
                                        </div>

                                        {/* Submit button */}
                                        <div className={"d-flex justify-content-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  onClick={annulerCallback}
                                                  disabled={helpers.isSubmitting}
                                             >
                                                  Annuler
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? <>Valider</> : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </>
          )
     }
}

interface IPropsModel {
     consultant: IConsultantModel
     annulerCallback?: () => void
     onSubmitCallback?: () => void
     setModalNoPaddingCallback?: (noPadding: boolean) => void
     setModalSizeCallback?: (size: "lg" | "xl" | "fullscreen") => void
}

export default ConsultantSuiviReporting
