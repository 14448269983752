import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { addCandidatesToViviers } from "../core/_requests"
import { toast } from "react-toastify"
import MyAlert from "@common-utils/MyAlert"
import MyRadioField from "@common-utils/fields/MyRadioField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_VIVIERS_ENDPOINT } from "../../../../endpoints"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { ICandidatAddToViviersFormModel } from "./core/_models"

const CandidatAddToViviersForm = ({ candidat, handleSubmitCallBack }: ICandidatAddToViviersFormModel) => {
     const schema = Yup.object().shape({
          vivierExistantOrNew: Yup.string().label(`"Existant ou nouveau"`),
          viviers: Yup.array()
               .when("vivierExistantOrNew", {
                    is: "existant",
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Viviers"`),
          intituleNewVivier: Yup.string()
               .when("vivierExistantOrNew", {
                    is: "new",
                    then: schema => schema.required(),
               })
               .label(`"Titre"`),
     })

     const initialValues: { vivierExistantOrNew?: "new" | "existant"; viviers?: string[]; intituleNewVivier?: string } = {}

     function handleSubmit(
          values: { vivierExistantOrNew?: "new" | "existant"; viviers?: string[]; intituleNewVivier?: string },
          helpers: FormikHelpers<{ vivierExistantOrNew?: "new" | "existant"; viviers?: string[]; intituleNewVivier?: string }>
     ) {
          helpers.setStatus(null)
          addCandidatesToViviers(
               [candidat.id],
               values.vivierExistantOrNew === "new" ? (values.intituleNewVivier as string) : null,
               values.vivierExistantOrNew === "existant" ? (values.viviers?.map(vv => parseInt(vv)) as number[]) : null
          )
               .then(r => {
                    handleSubmitCallBack(r.data)

                    // UI message
                    if (values.vivierExistantOrNew === "new") {
                         toast.success("Le candidat a bien été ajouté au nouveau vivier.")
                    } else {
                         toast.success(`Le candidat a bien été ajouté ${values.viviers!.length > 1 ? "aux viviers" : "au vivier"}.`)
                    }
               })
               .catch(() => {
                    helpers.setStatus("Erreur inattendue. veuillez réessayer.")
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}
                              {/* Field vivierExistantOrNew */}
                              <div className="row">
                                   <div className="col-6">
                                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                                             <span className="d-flex align-items-center me-2">
                                                  <span className="symbol symbol-50px me-6">
                                                       <span className="symbol-label bg-light-primary">
                                                            <i className={"fas fa-database fs-2 text-primary"} />
                                                       </span>
                                                  </span>
                                                  <span className="d-flex flex-column">
                                                       <span className="fw-bolder fs-6">Vivier existant</span>
                                                       <span className="fs-7 text-muted">Ajouter le candidat à un vivier existant</span>
                                                  </span>
                                             </span>
                                             <MyRadioField
                                                  name={"vivierExistantOrNew"}
                                                  value={"existant"}
                                                  isChecked={helpers.values.vivierExistantOrNew === "existant"}
                                                  isInvalid={!!helpers.errors.vivierExistantOrNew}
                                                  onChange={val => helpers.setFieldValue("vivierExistantOrNew", val)}
                                             />
                                        </label>
                                   </div>
                                   <div className="col-6">
                                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                                             <span className="d-flex align-items-center me-2">
                                                  <span className="symbol symbol-50px me-6">
                                                       <span className="symbol-label bg-light-success">
                                                            <i className={"fas fa-plus fs-2 text-success"} />
                                                       </span>
                                                  </span>
                                                  <span className="d-flex flex-column">
                                                       <span className="fw-bolder fs-6">Nouveau vivier</span>
                                                       <span className="fs-7 text-muted">Ajouter le candidat à un nouveau vivier</span>
                                                  </span>
                                             </span>
                                             <MyRadioField
                                                  name={"vivierExistantOrNew"}
                                                  value={"new"}
                                                  isChecked={helpers.values.vivierExistantOrNew === "new"}
                                                  isInvalid={!!helpers.errors.vivierExistantOrNew}
                                                  onChange={val => helpers.setFieldValue("vivierExistantOrNew", val)}
                                             />
                                        </label>
                                   </div>
                              </div>

                              {helpers.errors.vivierExistantOrNew && <div className={"text-danger"}>{helpers.errors.vivierExistantOrNew}</div>}

                              {/* Field viviers */}
                              {helpers.values.vivierExistantOrNew === "existant" && (
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Viviers</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"viviers"}
                                                  url={CONST_API_UTILS_GET_VIVIERS_ENDPOINT}
                                                  payload={{
                                                       includeMyOwnViviers: true,
                                                       includeTeamViviers: true,
                                                  }}
                                                  value={helpers.values.viviers}
                                                  onChange={val => helpers.setFieldValue("viviers", val)}
                                                  isInvalid={!!helpers.errors.viviers}
                                                  isMulti
                                             />
                                             {helpers.errors.viviers && <div className={"text-danger"}>{helpers.errors.viviers}</div>}
                                        </div>
                                   </div>
                              )}

                              {/* Field intituleNewVivier */}
                              {helpers.values.vivierExistantOrNew === "new" && (
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Saisissez le titre du nouveau vivier</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"intituleNewVivier"}
                                                  value={helpers.values.intituleNewVivier}
                                                  isInvalid={!!helpers.errors.intituleNewVivier}
                                                  onChange={val => helpers.setFieldValue("intituleNewVivier", val)}
                                             />
                                             {helpers.errors.intituleNewVivier && <div className={"text-danger"}>{helpers.errors.intituleNewVivier}</div>}
                                        </div>
                                   </div>
                              )}

                              {/* Submit button */}
                              <div className={"text-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? "Ajouter" : <MySimpleSpinner size={"sm"} />}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default CandidatAddToViviersForm
