import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../../models"
import DdcListingSearchBar from "./_DdcListingSearchBar"
import DdcListingFilters from "./_DdcListingFilters"
import DdcListingList from "./_DdcListingList"
import { useLocation } from "react-router-dom"
import { IDdcListingContextPropsModel, IItemInPreviewModel } from "./core/_models"
import { IGetDemandesCongesListingRequestModel, IGetDemandesCongesResponseModel } from "../core/_models"
import { getDdcListingRequest } from "../core/_requests"
import DdcListingPreview from "./_DdcListingPreview"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
const DdcListingContext = createContext<IDdcListingContextPropsModel>({} as IDdcListingContextPropsModel)
export const useDDdcListingContext = () => useContext(DdcListingContext)
const ConsultantDdcListing = ({ title, isSearchBarVisible = true, isFilterIconVisible = true, isResetIconVisible = true }: IDdcListingPropsModel) => {
     // Page states & URL parameters
     const location = useLocation() as {
          state: {
               etat?: "ETAT_EN_COURS" | "ETAT_VALIDE" | "ETAT_REJETE"
               keyword?: string
               dateDebut?: string
               dateFin?: string
          }
     }
     const urlParams = new URLSearchParams(window.location.search)
     const urlParamsEtat = urlParams.get("etat") as "ETAT_EN_COURS" | "ETAT_VALIDE" | "ETAT_REJETE" | null
     const urlParamsDateDebut = urlParams.get("dateDebut") as string | null
     const urlParamsDateFin = urlParams.get("dateFin") as string | null
     const urlParamsKeyword = urlParams.get("keyword") as string | null

     // Referencing filters form, simple search bar form & preview container
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)
     const previewContainerRef = useRef<any>(null)

     // Filters, item in preview & bulk selection
     const [filters, setFilters] = useState<IGetDemandesCongesListingRequestModel>({
          etat: location.state?.etat || urlParamsEtat || undefined,
          keyword: location.state?.keyword || urlParamsKeyword || undefined,
          dateDebut: location.state?.dateDebut || urlParamsDateDebut || undefined,
          dateFin: location.state?.dateFin || urlParamsDateFin || undefined,
          page: 1,
          pageLength: 20,
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [itemInPreview, setItemInPreview] = useState<IItemInPreviewModel | null>(null)
     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })

     // Main listing query
     const listingQuery = useQuery<IGetDemandesCongesResponseModel, AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return getDdcListingRequest(filters)
               .then(r => {
                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters form
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     useEffect(() => {
          listingQuery.refetch().then()
     }, [filters])

     return (
          <>
               <DdcListingContext.Provider
                    value={{
                         areFiltersVisible,
                         setAreFiltersVisible,
                         filters,
                         setFilters,
                         itemInPreview,
                         setItemInPreview,
                         REACT_QUERY_KEY_GET_LISTING,
                         listingQuery,
                         filtersFormikRef,
                         previewContainerRef,
                         simpleSearchBarFormikRef,
                         isBulkSelectionEnabled,
                         setIsBulkSelectionEnabled,
                         title,
                         isFilterIconVisible,
                         isSearchBarVisible,
                         isResetIconVisible,
                    }}
               >
                    {/* Adapt view to display */}
                    {window.innerWidth >= 1200 ? <ViewXlOrHigher /> : <ViewLgOrLess />}
               </DdcListingContext.Provider>
          </>
     )
}

// View display Lg or less
const ViewLgOrLess = () => {
     const context = useDDdcListingContext()

     return (
          <>
               <div className={`${context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <DdcListingSearchBar />
               </div>

               {context.itemInPreview?.selectedItem.consultantDdc.justificatifGoogleDriveID && context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                    <div className={"mb-2"}>
                         <DdcListingPreview />
                    </div>
               )}

               <div className={`${!context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <DdcListingFilters />
               </div>

               <DdcListingList />
          </>
     )
}

// View display Xl or higher
const ViewXlOrHigher = () => {
     const context = useDDdcListingContext()
     return (
          <div className={"row"}>
               <div className={`col-5 ${!context.areFiltersVisible ? "d-none" : ""}`}>
                    <DdcListingFilters />
               </div>
               <div className={`col ${context.itemInPreview && context.itemInPreview.isPreviewVisible ? "" : "col-7"}`}>
                    <div className={"row"}>
                         <div className={context.itemInPreview && context.itemInPreview.isPreviewVisible ? "col-5" : ""}>
                              {/* Simple bar search will be shown only if filters are not displayed */}
                              <div className={`mb-2 ${context.areFiltersVisible ? "d-none" : ""}`}>
                                   <DdcListingSearchBar />
                              </div>

                              <DdcListingList />
                         </div>
                         {context.itemInPreview?.selectedItem.consultantDdc.justificatifGoogleDriveID && context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                              <div className="col-7">
                                   <DdcListingPreview />
                              </div>
                         )}
                    </div>
               </div>
          </div>
     )
}

interface IDdcListingPropsModel {
     title: string
     isFilterIconVisible?: boolean
     isSearchBarVisible?: boolean
     isResetIconVisible?: boolean
}

export default ConsultantDdcListing
