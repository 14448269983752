import React from "react"
import { IConsultantSuiviGeneralModel } from "@common-models/*"
import MyTooltip from "@common-utils/MyTooltip"
import { Link } from "react-router-dom"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyModal from "@common-utils/MyModal"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"

const ListItem = ({ item }: IListItemPropsModel) => {
     const [showCommentInModal, setShowCommentInModal] = React.useState<boolean>(false)
     const [documentInModal, setDocumentInModal] = React.useState<{
          driveID: string
          driveMime: string
     }>()

     return (
          <>
               <tr>
                    <td>
                         <MyTooltip title={<>{item.date_suivi?.format("[Le] dddd D MMMM YYYY à H[h]m")}</>}>
                              <div>{item.date_suivi?.format("DD/MM/YYYY")}</div>
                         </MyTooltip>
                    </td>
                    <td>
                         <MyTooltip title={<>{item.date_effective?.format("[Le] dddd D MMMM YYYY à H[h]m")}</>}>
                              <div>{item.date_effective?.format("DD/MM/YYYY")}</div>
                         </MyTooltip>
                    </td>
                    <td>{item.typeLabel}</td>
                    <td>
                         <Link to={`/business/consultants/${item.consultant?.id}/details`} target={"_blank"}>
                              {item.consultant?.prenomNom}
                         </Link>
                    </td>
                    <td>{item.personnel?.prenomNom}</td>
                    {item.commentaire ? (
                         <>
                              {item.commentaire.length > 20 ? (
                                   <td className={"cursor-pointer"} onClick={() => setShowCommentInModal(true)}>
                                        <MyTooltip title={"Voir le commentaire entier"}>
                                             <div>{GlobalHelper.truncateString(item.commentaire || "", 20)}</div>
                                        </MyTooltip>
                                   </td>
                              ) : (
                                   <td>
                                        <div>{item.commentaire}</div>
                                   </td>
                              )}
                         </>
                    ) : (
                         <td></td>
                    )}
                    <td>
                         {item.consultantSuiviGeneralDocuments?.map((document, index) => {
                              return (
                                   <MyTooltip title={"Voir le document"}>
                                        <span
                                             className={"fas fa-file fs-1 text-dark me-4 not-concerned-by-preview text-hover-danger cursor-pointer"}
                                             onClick={() => setDocumentInModal({ driveID: document.googleDriveID as string, driveMime: document.googleDriveMimeType as string })}
                                        />
                                   </MyTooltip>
                              )
                         })}
                    </td>
               </tr>

               {showCommentInModal && (
                    <MyModal show={true} handleClose={() => setShowCommentInModal(false)} title={<>Commentaire</>}>
                         {item.commentaire}
                    </MyModal>
               )}

               {documentInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={documentInModal.driveID as string}
                         googleDriveMimetype={documentInModal.driveMime as string}
                         modalTitle={<>Document</>}
                         handleCloseModal={() => setDocumentInModal(undefined)}
                         modalFullScreen
                    />
               )}
          </>
     )
}

const _ConsultantsSuivisListingListItem = ({ item }: { item: IConsultantSuiviGeneralModel }) => {
     return <ListItem item={item} />
}

interface IListItemPropsModel {
     item: IConsultantSuiviGeneralModel
}

export default _ConsultantsSuivisListingListItem
