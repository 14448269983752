import {
     CONST_CANDIDAT_TYPE_SAISI,
     CONST_DISPONIBILITE_DATE_EXACTE,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_CV,
} from "@common-constants/*"
import React from "react"
import { ILogOrSavedFiltersSearchCandidateModel } from "./core/_models"

const DetailFiltersRechercheCandidat = ({ detail }: { detail: ILogOrSavedFiltersSearchCandidateModel }) => {
     return (
          <>
               {/* keywords */}
               {detail.searchKeywords && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Mots clé</label>

                         <div className="col-lg-8">
                              <>
                                   <div className={"d-flex align-items-center"}>
                                        {detail.searchKeywords
                                             .map(item => item)
                                             .join(
                                                  detail.searchScope === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_CV
                                                       ? ` ${detail.searchOperateurLogiqueOnlyScopeCurriculumLabel} `
                                                       : ", "
                                             )}
                                   </div>
                                   <div className={"d-flex align-items-center"}>Recherche dans {detail.searchScopeLabel}</div>
                              </>
                         </div>
                    </div>
               )}

               {/* Dispo */}
               {detail.disponibilite && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Disponibilité</label>

                         <div className="col-lg-8 d-flex align-items-center">
                              {detail.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE ? detail.disponibiliteDate?.format("[Le] dddd Do MMM YYYY") : detail.disponibiliteLabel}
                         </div>
                    </div>
               )}

               {/* Mobilité */}
               {detail.mobilite && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Mobilité</label>

                         <div className="col-lg-8 d-flex align-items-center">
                              {![CONST_MOBILITE_REGIONAL, CONST_MOBILITE_DEPARTEMENTAL, CONST_MOBILITE_PAR_VILLE].includes(detail.mobilite) && detail.mobiliteLabel}

                              {detail.mobilite === CONST_MOBILITE_REGIONAL && detail.mobiliteRegions && detail.mobiliteRegions.map(item => item.nom).join(", ")}
                              {detail.mobilite === CONST_MOBILITE_DEPARTEMENTAL && detail.mobiliteDepartements && detail.mobiliteDepartements.map(item => item.nom).join(", ")}
                              {detail.mobilite === CONST_MOBILITE_PAR_VILLE && detail.mobiliteVilles && detail.mobiliteVilles.map(item => item.nom).join(", ")}
                         </div>
                    </div>
               )}

               {/* Niveau d'études */}
               {detail.niveauEtudes && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Niveau d'études</label>
                         <div className="col-lg-8 d-flex align-items-center"> {detail.niveauEtudesLabel}</div>
                    </div>
               )}

               {/* Fraîcheur CV */}
               {detail.fraicheurCV && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Fraîcheur du CV</label>
                         <div className="col-lg-8 d-flex align-items-center"> {detail.fraicheurCVLabel}</div>
                    </div>
               )}

               {/* Viviers */}
               {detail.viviers && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Viviers</label>

                         <div className="col-lg-8 d-flex align-items-center"> {detail.viviers.map(item => item.intitule)}</div>
                    </div>
               )}

               {/* Type candidat */}
               {detail.candidatType && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Type candidat</label>

                         <div className="col-lg-8 d-flex align-items-center">
                              {detail.candidatType === CONST_CANDIDAT_TYPE_SAISI
                                   ? detail.personnelsAyantSaisiLeCandidat && `Saisi par ${detail.personnelsAyantSaisiLeCandidat.map(item => item.prenomNom).join(", ")}`
                                   : detail.candidatTypeLabel}
                         </div>
                    </div>
               )}

               {/* Secteurs activité */}
               {detail.secteursActivite && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Secteurs d'activité</label>

                         <div className="col-lg-8 d-flex align-items-center"> {detail.secteursActivite.map(item => item.nom).join(", ")}</div>
                    </div>
               )}

               {/* Métier */}
               {detail.metiers && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Métiers</label>

                         <div className="col-lg-8 d-flex align-items-center"> {detail.metiers.map(item => item.nom).join(", ")}</div>
                    </div>
               )}

               {/* Vu */}
               {detail.seenOrNot && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Vu</label>

                         <div className="col-lg-8 d-flex align-items-center"> {detail.seenOrNotLabel}</div>
                    </div>
               )}

               {/* Experience */}
               {detail.experienceMin && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Expérience min.</label>

                         <div className="col-lg-8 d-flex align-items-center"> {detail.experienceMinLabel}</div>
                    </div>
               )}

               {/* Salaire NET mensuel souhaité */}
               {/*{(detail.salaireNetMensuelSouhaiteMin !== null || detail.salaireNetMensuelSouhaiteMax !== null) && (*/}
               {/*     <div className="row mb-5">*/}
               {/*          <label className="col-lg-4 col-form-label fw-bold fs-6">Salaire NET mensuel</label>*/}
               {/*          <div className="col-lg-8 d-flex align-items-center">*/}
               {/*               {detail.salaireNetMensuelSouhaiteMin !== null && detail.salaireNetMensuelSouhaiteMax === null && `Minimum ${detail.salaireNetMensuelSouhaiteMin}€`}*/}
               {/*               {detail.salaireNetMensuelSouhaiteMax !== null && detail.salaireNetMensuelSouhaiteMin === null && `Maximum ${detail.salaireNetMensuelSouhaiteMax}€`}*/}
               {/*               {detail.salaireNetMensuelSouhaiteMax !== null &&*/}
               {/*                    detail.salaireNetMensuelSouhaiteMin !== null &&*/}
               {/*                    `De ${detail.salaireNetMensuelSouhaiteMin}€ à ${detail.salaireNetMensuelSouhaiteMax}€`}*/}
               {/*          </div>*/}
               {/*     </div>*/}
               {/*)}*/}

               {/* Avec suivi */}
               {detail.avecSuivi && (
                    <div className="row mb-5">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Avec suivi</label>

                         <div className="col-lg-8 d-flex align-items-center"> {detail.avecSuiviLabel}</div>
                    </div>
               )}
          </>
     )
}

export default DetailFiltersRechercheCandidat
