import React, { createContext, CSSProperties, Dispatch, SetStateAction, useContext, useState } from "react"
import MyTooltip from "./MyTooltip"
import { KTSVG } from "@common-metronic/helpers"

// Create a context in the whole card
type MyCardContextProps = {
     collapsed: boolean
     setIsCollapsed: Dispatch<SetStateAction<boolean>>
     isCardFullScreen: boolean
     setIsCardFullScreen: Dispatch<SetStateAction<boolean>>
}
const MyCardContext = createContext<MyCardContextProps>({} as MyCardContextProps)

type MyCardProps = {
     children: React.ReactNode
     classNames?: string
     styles?: React.CSSProperties
     defaultIsCollapsed?: boolean
     setIsCollapsed?: Dispatch<SetStateAction<boolean>>
}
const MyCard = ({ children, classNames, styles, defaultIsCollapsed = false }: MyCardProps) => {
     const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultIsCollapsed)
     const [isCardFullScreen, setIsCardFullScreen] = useState<boolean>(false)

     return (
          <MyCardContext.Provider
               value={{
                    collapsed: isCollapsed,
                    setIsCollapsed,
                    isCardFullScreen,
                    setIsCardFullScreen,
               }}
          >
               <div className={`card ${classNames} ${isCardFullScreen ? "full-screen" : ""}`} style={styles}>
                    {children}
               </div>
          </MyCardContext.Provider>
     )
}

const Header = ({
     children,
     classNames = "",
     styles = {},
     rightSideContent,
     isCollapseIconShown = false,
     refHeader,
     isFullScreenIconShown = false,
     onClick = () => {},
}: {
     children: React.ReactNode
     classNames?: string
     styles?: React.CSSProperties
     rightSideContent?: React.ReactNode
     isCollapseIconShown?: boolean
     refHeader?: React.MutableRefObject<any>
     isFullScreenIconShown?: boolean
     onClick?: () => void
}) => {
     const context = useContext(MyCardContext)
     return (
          <div
               className={`card-header ${isCollapseIconShown ? "cursor-pointer" : ""}  rounded-3 ${classNames}`}
               style={{
                    ...styles,
                    minHeight: "4rem",
               }}
               ref={refHeader}
               onClick={e => {
                    const clickedElement = e.target as HTMLElement
                    if (isCollapseIconShown && !clickedElement.classList.contains("not-concerned-collapse-action")) {
                         context.setIsCollapsed(prev => !prev)
                    }
                    onClick()
               }}
          >
               <div className="card-title m-0">
                    <h4 className="fw-bolder m-0">{children}</h4>
               </div>

               <div className="card-toolbar">
                    {rightSideContent}
                    {isCollapseIconShown && (
                         <MyTooltip title={context.collapsed ? "Afficher contenu" : "Cacher contenu"}>
                              <div className={"align-self-center text-hover-primary"}>
                                   <KTSVG
                                        path={context.collapsed ? "/media/icons/duotune/arrows/arr073.svg" : "/media/icons/duotune/arrows/arr072.svg"}
                                        className="svg-icon-1 svg-icon-3x"
                                   />
                              </div>
                         </MyTooltip>
                    )}
                    {isFullScreenIconShown && (
                         <>
                              <MyTooltip title={"Plein écran"}>
                                   <div
                                        className={"align-self-center ms-1 cursor-pointer not-concerned-collapse-action text-hover-primary"}
                                        onClick={() => context.setIsCardFullScreen(prev => !prev)}
                                   >
                                        <i className={"fas fa-expand fs-1"} />
                                   </div>
                              </MyTooltip>
                         </>
                    )}
               </div>
          </div>
     )
}
MyCard.Header = Header

const Body = ({ children, classNames = "", noPadding = false, styles = {} }: { children: React.ReactNode; classNames?: string; noPadding?: boolean; styles?: CSSProperties }) => {
     const context = useContext(MyCardContext)
     return (
          <div className={`card-body  ${noPadding ? "p-0 m-0" : "p-5"} ${context.collapsed && "d-none"} ${classNames}`} style={styles}>
               {children}
          </div>
     )
}
MyCard.Body = Body

const Footer = ({ children, classNames = "" }: { children: React.ReactNode; classNames?: string }) => {
     return <div className={`card-footer ${classNames ? classNames : ""} p-0 p-2`}>{children}</div>
}
MyCard.Footer = Footer

export default MyCard
