import React from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import LoginForm from "./LoginForm"
import LoginWithGoogle from "./LoginWithGoogle"

const LoginWrapper = () => {
     const urlParams = new URLSearchParams(window.location.search)
     const urlParamsTmpCodeToGetJwt = urlParams.get("code")

     return (
          <div
               className="d-flex flex-column align-items-center justify-content-center"
               style={{
                    backgroundSize: "cover",
                    backgroundImage: `url(${toAbsoluteUrl("/media/misc/auth-bg.jpg")})`,
                    height: "100vh",
                    color: "black",
               }}
          >
               <div
                    className="w-85 w-sm-75 w-md-50 w-xxl-30 px-5 py-5"
                    style={{
                         opacity: "0.90",
                         backgroundColor: "white",
                         borderRadius: "20px",
                         boxShadow: "0px 0px 10px -2px rgba(0, 0, 0, 0.42)",
                         // overflow: "scroll",
                         // maxHeight: "95%",
                    }}
               >
                    <div className={"mb-10 text-center"}>
                         <img src={toAbsoluteUrl("/media/logos/logo-talent-structure.png")} alt="Logo" className={"w-75"} />
                    </div>

                    {!urlParamsTmpCodeToGetJwt ? (
                         <>
                              <LoginForm />
                              <div className="d-flex align-items-center mb-5">
                                   <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                                   <span className="fw-bold text-gray-400 fs-7 mx-2">OU</span>
                                   <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                              </div>
                              <LoginWithGoogle />
                              <div className="d-flex flex-column fw-bold text-primary flex-center mt-8">
                                   <img
                                        style={{
                                             height: "40px",
                                             width: "55px",
                                        }}
                                        src={toAbsoluteUrl("/media/logos/logos-cts-1.png")}
                                        alt={""}
                                   />
                              </div>
                         </>
                    ) : (
                         <LoginWithGoogle />
                    )}
               </div>
          </div>
     )
}

export { LoginWrapper }
