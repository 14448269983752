import { IClientPropalModel, IClientPropalPeriodeModel } from "@common-models/*"
import React, { useState } from "react"
import MyCard from "@common-utils/MyCard"
import MyTooltip from "@common-utils/MyTooltip"
import PtfDetailsPeriodesBonsCommandeBonsLivraison_VersionsPtf from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison_VersionsPtf"
import PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommande from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommande"
import MyModal from "@common-utils/MyModal"
import PtfProlongementForm from "../actions/_PtfProlongementForm"
import { IGetPeriodesBonsCommandeBonsLivraisonResponseModel } from "../core/_models"
import { usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext } from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import { useQueryClient } from "react-query"
import { deletePropalPeriodeRequest } from "../core/_requests"
import { toast } from "react-toastify"
import swal from "sweetalert2"
import PtfPeriodeEditForm from "../actions/_PtfPeriodeEditForm"

const PtfDetailsPeriodesBonsCommandeBonsLivraison_Periodes = ({ ptf }: IPtfDetailsPeriodesBonsCommandeBonsLivraison_PeriodesPropsModel) => {
     const context = usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext()
     const queryClient = useQueryClient()

     const [prolongementPtf, setProlongementPtf] = useState<{
          ptf: IClientPropalModel
          modalTitle: JSX.Element
          isModalFullScreen?: true
     }>()

     const [editPeriodeInModal, setEditPeriodeInModal] = useState<IClientPropalPeriodeModel>()

     function handleSubmitProlongementCallback(val: IClientPropalPeriodeModel) {
          setProlongementPtf(prev =>
               prev
                    ? {
                           ...prev,
                           isModalFullScreen: true,
                           modalTitle: (
                                <div className={"d-flex"}>
                                     <div>Édition de la {val.reference}A</div>
                                     <button className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"} onClick={() => setProlongementPtf(undefined)}>
                                          Terminer <i className={"fas fa-check-cirle ms-1"} />
                                     </button>
                                </div>
                           ),
                      }
                    : undefined
          )
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              if (item.id === ptf.id) {
                                   item.clientPropalPeriodes = [val, ...(item.clientPropalPeriodes || [])]
                                   return item
                              }
                         })
                         return prev
                    }
                    return prev
               }
          )
     }

     function handleRemovePeriode(id: number) {
          swal.fire({
               icon: "warning",
               text: `Vous-vous vraiment supprimer cette période?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deletePropalPeriodeRequest(id)
                         .then(() => {
                              queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
                                   context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
                                   (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                                        if (prev?.data) {
                                             prev.data.map(item => {
                                                  item.clientPropalPeriodes?.map(item_ => {
                                                       if (item_.id === id) {
                                                            item.clientPropalPeriodes = item.clientPropalPeriodes?.filter(item__ => item__.id !== id)
                                                       }
                                                  })
                                                  return item
                                             })
                                             return prev
                                        }
                                        return prev
                                   }
                              )
                              toast.info("Le période a bien été supprimée.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function handleSubmitEditPeriodeCallback(val: IClientPropalPeriodeModel) {
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              item.clientPropalPeriodes?.map(item_ => {
                                   if (item_.id === editPeriodeInModal?.id) {
                                        item_.dateDebutValidite = val.dateDebutValidite
                                        item_.dateFinValidite = val.dateFinValidite
                                        return item_
                                   }
                              })
                         })
                    }
                    return prev
               }
          )

          setEditPeriodeInModal(undefined)
     }

     return (
          <>
               {ptf.clientPropalPeriodes?.map((periode, index) => (
                    <MyCard key={index}>
                         <MyCard.Header
                              rightSideContent={
                                   <div className={"d-flex align-items-center"}>
                                        <MyTooltip title={"Supprimer"}>
                                             <span className={"fas fa-trash text-dark fs-3 cursor-pointer me-2"} onClick={() => handleRemovePeriode(periode.id as number)} />
                                        </MyTooltip>
                                        <MyTooltip title={"Modifier"}>
                                             <span className={"fas fa-edit text-dark fs-3 cursor-pointer"} onClick={() => setEditPeriodeInModal(periode)} />
                                        </MyTooltip>
                                        {index === 0 && (
                                             <MyTooltip title={"Prolonger"}>
                                                  <span
                                                       className={"fas fa-plus-circle text-dark fs-3 cursor-pointer ms-3"}
                                                       onClick={() =>
                                                            setProlongementPtf({
                                                                 ptf,
                                                                 modalTitle: (
                                                                      <span>
                                                                           {`${ptf.reference}-${ptf.clientPropalPeriodes!.length}`} {"-->"}
                                                                           {`${ptf.reference}-${ptf.clientPropalPeriodes!.length + 1}`}
                                                                      </span>
                                                                 ),
                                                            })
                                                       }
                                                  />
                                             </MyTooltip>
                                        )}
                                   </div>
                              }
                         >
                              <div className={"d-flex"}>
                                   <div className={"d-flex align-items-center me-3 fs-1"}>
                                        <span className={"fas fa-calendar-day"} />
                                   </div>
                                   <div>
                                        <div className={""}>
                                             {periode.dateDebutValidite?.format("D MMM YYYY")} - {periode.dateFinValidite?.format("D MMM YYYY")}
                                        </div>
                                        <div className={"text-muted fs-7 fw-normal fst-italic"}>{periode.reference}</div>
                                   </div>
                              </div>
                         </MyCard.Header>
                         <MyCard.Body>
                              <>
                                   <PtfDetailsPeriodesBonsCommandeBonsLivraison_VersionsPtf periode={periode} />

                                   {/* Contains BLs */}
                                   <PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommande periode={periode} ptf_type={ptf.type as string} />
                              </>
                         </MyCard.Body>
                    </MyCard>
               ))}

               {/* Modal add version PTF */}
               {prolongementPtf && (
                    <MyModal
                         title={prolongementPtf.modalTitle}
                         show={true}
                         handleClose={() => setProlongementPtf(undefined)}
                         size={"lg"}
                         fullscreen={prolongementPtf.isModalFullScreen}
                         noPadding={prolongementPtf.isModalFullScreen}
                    >
                         <PtfProlongementForm ptf_ID={ptf!.id as number} handleSubmitCallback={handleSubmitProlongementCallback} />
                    </MyModal>
               )}

               {/* Modal edit PÉRIODE */}
               {editPeriodeInModal && (
                    <MyModal
                         title={
                              <>
                                   Période du {editPeriodeInModal.dateDebutValidite?.format("D MMM YYYY")} au {editPeriodeInModal.dateFinValidite?.format("D MMM YYYY")}
                              </>
                         }
                         show={true}
                         handleClose={() => setEditPeriodeInModal(undefined)}
                         size={"lg"}
                    >
                         <PtfPeriodeEditForm periode={editPeriodeInModal} handleSubmitCallback={handleSubmitEditPeriodeCallback} />
                    </MyModal>
               )}
          </>
     )
}

interface IPtfDetailsPeriodesBonsCommandeBonsLivraison_PeriodesPropsModel {
     ptf: IClientPropalModel
}

export default PtfDetailsPeriodesBonsCommandeBonsLivraison_Periodes
