import MyTooltip from "@common-utils/MyTooltip"
import React from "react"
import { usePersonnelDetailsContext } from "./PersonnelDetails"

const Navigation = () => {
     const context = usePersonnelDetailsContext()

     return (
          <>
               <div className="d-flex justify-content-center rounded mb-5 border-bottom">
                    <MyTooltip title={"Informations de base"}>
                         <span
                              className={`${
                                   context.selectedNavigationItem === "INFO_BASE" ? "text-primary border-bottom border-primary" : "text-hover-primary cursor-pointer"
                              } me-10 pb-2`}
                              onClick={() => context.setSelectedNavigationItem("INFO_BASE")}
                         >
                              <span className={"fad fa-user"} style={{ fontSize: "2rem" }} />
                         </span>
                    </MyTooltip>
                    <MyTooltip title={"KPI"}>
                         <span
                              className={`${context.selectedNavigationItem === "KPI" ? "text-primary border-bottom border-primary" : "text-hover-primary cursor-pointer"} me-10`}
                              onClick={() => context.setSelectedNavigationItem("KPI")}
                         >
                              <span className={"fad fa-chart-pie"} style={{ fontSize: "2rem" }} />
                         </span>
                    </MyTooltip>
                    <MyTooltip title={"Carte NFC"}>
                         <span
                              className={`${context.selectedNavigationItem === "NFC" ? "text-primary border-bottom border-primary" : "text-hover-primary cursor-pointer"} me-10`}
                              onClick={() => context.setSelectedNavigationItem("NFC")}
                         >
                              <span className={"fad fa-nfc-signal"} style={{ fontSize: "2rem" }} />
                         </span>
                    </MyTooltip>
                    <MyTooltip title={"Paramètres du compte"}>
                         <span
                              className={`${context.selectedNavigationItem === "COMPTE" ? "text-primary border-bottom border-primary" : "text-hover-primary cursor-pointer"}`}
                              onClick={() => context.setSelectedNavigationItem("COMPTE")}
                         >
                              <span className={"fad fa-power-off"} style={{ fontSize: "2rem" }} />
                         </span>
                    </MyTooltip>
               </div>
          </>
     )
}

export default Navigation
