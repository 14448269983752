import * as Yup from "yup"
import {
     CONST_CONSULTANT_CONTRAT_CDD,
     CONST_CONSULTANT_CONTRAT_CDI,
     CONST_CONSULTANT_CONTRAT_CDIC,
     CONST_CONSULTANT_STATUT_AGENT_MAITRISE,
     CONST_CONSULTANT_STATUT_CADRE,
     CONST_CONSULTANT_STATUT_ETAM,
     CONST_FOURNISSEUR_TYPE_FREELANCE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { IDemanderInformationsPreEmbaucheParMailAuCandidatRequestModel } from "../core/_models"
import { demanderInformationsPreEmbaucheParMail } from "../core/_requests"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { ICandidatModel, IConsultantDEModel, IHttpErrorResponseModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MySelectField from "@common-utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ENTITES_ENDPOINT, CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT, CONST_API_UTILS_GET_FOURNISSEURS_RESP_ENDPOINT } from "../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import MyModal from "@common-utils/MyModal"
import AddFournisseurForm from "../../../utils/core/_AddFournisseurForm"
import AddResponsableFournisseurForm from "../../../utils/_AddResponsableFournisseurForm"

interface CandidatDemanderInfoPreEmbaucheParMailProps {
     candidat: ICandidatModel
     callBackOnSubmit: (val: IConsultantDEModel) => void
}

const CandidatDemanderInfoPreEmbaucheParMail = ({ candidat, callBackOnSubmit = () => {} }: CandidatDemanderInfoPreEmbaucheParMailProps) => {
     const [isAddingFournisseur, setIsAddingFournisseur] = useState<boolean>(false)
     const [isAddingFournisseurResp, setIsAddingFournisseurResp] = useState<boolean>(false)

     const schema = Yup.object().shape({
          typeContrat: Yup.string().label(`"Type de contrat"`).required(),
          contrat: Yup.string()
               .when("typeContrat", {
                    is: "TYPE_CONTRAT_SALARIE",
                    then: schema => schema.required(),
               })
               .label(`"Contrat"`),
          fournisseur: Yup.string()
               .when("typeContrat", {
                    is: (val: any) => val === "TYPE_CONTRAT_PORTAGE" || val === "TYPE_CONTRAT_SOCIETE_SERVICE",
                    then: schema => schema.required(),
               })
               .label(`"Fournisseur"`),
          fournisseurResp: Yup.string()
               .when("typeContrat", {
                    is: (val: any) => {},
                    then: schema => schema.required(),
               })
               .label(`"Responsable fournisseur"`),
          statut: Yup.string()
               .when("typeContrat", {
                    is: "TYPE_CONTRAT_SALARIE",
                    then: schema => schema.required(),
               })
               .label(`"Contrat"`),
          nationalite: Yup.string().label(`"Nationalité"`).required(),
          entiteRattrachement: Yup.number().label(`"Entité d'embauche"`).required(),
     })

     const initialValues: IDemanderInfoPreEmbaucheParEmailFormValuesModel = {}

     function handleSubmit(values: IDemanderInfoPreEmbaucheParEmailFormValuesModel, helpers: FormikHelpers<IDemanderInfoPreEmbaucheParEmailFormValuesModel>) {
          helpers.setStatus(null)

          const payload: IDemanderInformationsPreEmbaucheParMailAuCandidatRequestModel = {
               typeContrat: values.typeContrat as string,
               contrat: values.contrat,
               statut: values.statut,
               entiteRattrachement: values.entiteRattrachement as number,
               nationalite: values.nationalite as string,
               fournisseur: values.fournisseur,
               fournisseurResp: values.fournisseurResp,
          }
          demanderInformationsPreEmbaucheParMail(candidat?.id as number, payload)
               .then(r => {
                    toast.success("Le mail vient d'être envoyé au candidat.")
                    callBackOnSubmit(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <MyAlert type={"primary"} classNames={"mb-5"} isDismissible={false}>
                    <>
                         Après validation, le candidat <span className={"text-dark"}>{candidat.prenomNom}</span> recevra un courriel sur l'adresse{" "}
                         <a href={`mailto:${candidat.email}`} target={"_blank"}>
                              {candidat.email}
                         </a>
                         . Ce courriel l'invitera à fournir ses informations personnelles, marquant ainsi le début du processus d'embauche.
                    </>
               </MyAlert>

               <MyAlert type={"primary"} classNames={"mb-5"} isDismissible={false}>
                    <>Si le candidat possède plusieurs nationalités dont une française, veuillez sélectionner la nationalité française.</>
               </MyAlert>

               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         console.log(helpers.errors, helpers.values)
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}
                                   {/* Field typeContrat */}
                                   <label className="fw-bold fs-6 mb-4 required">Choisissez le type de contrat</label>
                                   <div className="row mb-4 g-4">
                                        <div className="col-xl-6">
                                             <MyRadioWithLabelAndDescriptionField
                                                  name={"typeContrat"}
                                                  value={"TYPE_CONTRAT_SALARIE"}
                                                  isChecked={helpers.values.typeContrat === "TYPE_CONTRAT_SALARIE"}
                                                  label={"Contrat salarié"}
                                                  iconFontAwesomeClassName={"user-tie"}
                                                  color={"primary"}
                                                  isInvalid={!!helpers.errors.typeContrat}
                                                  onChange={val => {
                                                       helpers.setFieldValue("typeContrat", val).then(() => {
                                                            helpers.setFieldValue("fournisseur", undefined).then()
                                                            helpers.setFieldValue("fournisseurResp", undefined).then()
                                                       })
                                                  }}
                                             />
                                        </div>
                                        <div className="col-xl-6">
                                             <MyRadioWithLabelAndDescriptionField
                                                  name={"typeContrat"}
                                                  value={"TYPE_CONTRAT_FREELANCE"}
                                                  isChecked={helpers.values.typeContrat === "TYPE_CONTRAT_FREELANCE"}
                                                  label={"Contrat Freelance"}
                                                  iconFontAwesomeClassName={"user-astronaut"}
                                                  color={"primary"}
                                                  isInvalid={!!helpers.errors.typeContrat}
                                                  onChange={val => {
                                                       helpers.setFieldValue("typeContrat", val).then(() => {
                                                            helpers.setFieldValue("fournisseur", undefined).then()
                                                            helpers.setFieldValue("fournisseurResp", undefined).then()
                                                       })
                                                  }}
                                             />
                                        </div>
                                        <div className="col-xl-6">
                                             <MyRadioWithLabelAndDescriptionField
                                                  name={"typeContrat"}
                                                  value={"TYPE_CONTRAT_SOCIETE_SERVICE"}
                                                  isChecked={helpers.values.typeContrat === "TYPE_CONTRAT_SOCIETE_SERVICE"}
                                                  label={"Contrat Société de service"}
                                                  iconFontAwesomeClassName={"building"}
                                                  color={"primary"}
                                                  isInvalid={!!helpers.errors.typeContrat}
                                                  onChange={val => {
                                                       helpers.setFieldValue("typeContrat", val).then(() => {
                                                            helpers.setFieldValue("fournisseur", undefined).then()
                                                            helpers.setFieldValue("fournisseurResp", undefined).then()
                                                       })
                                                  }}
                                             />
                                        </div>
                                        <div className="col-xl-6">
                                             <MyRadioWithLabelAndDescriptionField
                                                  name={"typeContrat"}
                                                  value={"TYPE_CONTRAT_PORTAGE"}
                                                  isChecked={helpers.values.typeContrat === "TYPE_CONTRAT_PORTAGE"}
                                                  label={"Contrat de portage"}
                                                  description={"Contrat tripartite avec une société de portage"}
                                                  iconFontAwesomeClassName={"user"}
                                                  color={"primary"}
                                                  isInvalid={!!helpers.errors.typeContrat}
                                                  onChange={val => {
                                                       helpers.setFieldValue("typeContrat", val).then(() => {
                                                            helpers.setFieldValue("fournisseur", undefined).then()
                                                            helpers.setFieldValue("fournisseurResp", undefined).then()
                                                       })
                                                  }}
                                             />
                                        </div>
                                   </div>

                                   {helpers.errors.typeContrat && <div className={"text-danger"}>{helpers.errors.typeContrat}</div>}

                                   {/* When typeContrat is filled*/}
                                   {helpers.values.typeContrat && (
                                        <>
                                             <div className="separator separator-dashed mb-4" />

                                             {/* Préciser le fournisseur dans le cas d'un freelance*/}
                                             {["TYPE_CONTRAT_FREELANCE", "TYPE_CONTRAT_PORTAGE", "TYPE_CONTRAT_SOCIETE_SERVICE"].includes(helpers.values.typeContrat) && (
                                                  <>
                                                       <div className="row mb-5">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Fournisseur</label>

                                                            <div className="col-lg-8">
                                                                 <MySelectGetAllDataFromServer
                                                                      name={"fournisseur"}
                                                                      value={helpers.values.fournisseur}
                                                                      url={CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT}
                                                                      method={"GET"}
                                                                      payload={(() => {
                                                                           if (helpers.values.typeContrat === "TYPE_CONTRAT_FREELANCE") {
                                                                                return { types: [CONST_FOURNISSEUR_TYPE_FREELANCE] }
                                                                           } else if (helpers.values.typeContrat === "TYPE_CONTRAT_PORTAGE") {
                                                                                return { types: [CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE] }
                                                                           } else {
                                                                                // Société de service
                                                                                return { types: [CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE] }
                                                                           }
                                                                      })()}
                                                                      isInvalid={!!helpers.errors.fournisseur}
                                                                      onChange={val => helpers.setFieldValue("fournisseur", val)}
                                                                 />
                                                                 <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                      {helpers.errors.fournisseur && <div className={"text-danger"}>{helpers.errors.fournisseur}</div>}
                                                                      <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingFournisseur(true)}>
                                                                           Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>

                                                       {helpers.values.fournisseur &&
                                                            ["TYPE_CONTRAT_PORTAGE", "TYPE_CONTRAT_SOCIETE_SERVICE"].includes(helpers.values.typeContrat) && (
                                                                 <div className="row mb-5">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Responsable fournisseur</label>

                                                                      <div className="col-lg-8">
                                                                           <MySelectGetAllDataFromServer
                                                                                name={"fournisseurResp"}
                                                                                value={helpers.values.fournisseurResp}
                                                                                url={CONST_API_UTILS_GET_FOURNISSEURS_RESP_ENDPOINT}
                                                                                method={"GET"}
                                                                                payload={{ fournisseur: helpers.values.fournisseur }}
                                                                                isInvalid={!!helpers.errors.fournisseurResp}
                                                                                onChange={val => helpers.setFieldValue("fournisseurResp", val)}
                                                                           />
                                                                           <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                {helpers.errors.fournisseurResp && (
                                                                                     <div className={"text-danger"}>{helpers.errors.fournisseurResp}</div>
                                                                                )}
                                                                                <div
                                                                                     className="text-primary fw-bold cursor-pointer"
                                                                                     onClick={() => setIsAddingFournisseurResp(true)}
                                                                                >
                                                                                     Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            )}
                                                  </>
                                             )}

                                             {/* Précisez contrat & statut if typeContrat = "TYPE_CONTRAT_SALARIE"*/}
                                             {helpers.values.typeContrat === "TYPE_CONTRAT_SALARIE" && (
                                                  <>
                                                       <div className="row mb-5">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le contrat</label>

                                                            <div className="col-lg-8">
                                                                 <MySelectField
                                                                      name={"contrat"}
                                                                      options={[
                                                                           { label: "CDI C", value: CONST_CONSULTANT_CONTRAT_CDD },
                                                                           { label: "CDD", value: CONST_CONSULTANT_CONTRAT_CDI },
                                                                           { label: "CDI", value: CONST_CONSULTANT_CONTRAT_CDIC },
                                                                      ]}
                                                                      value={helpers.values.contrat}
                                                                      onChange={val => helpers.setFieldValue("contrat", val)}
                                                                      isInvalid={!!helpers.errors.contrat}
                                                                 />
                                                                 {helpers.errors.contrat && <div className="text-danger">{helpers.errors.contrat}</div>}
                                                            </div>
                                                       </div>

                                                       <div className="row mb-5">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le statut</label>

                                                            <div className="col-lg-8">
                                                                 <MySelectField
                                                                      name={"statut"}
                                                                      options={[
                                                                           { label: "ETAM", value: CONST_CONSULTANT_STATUT_ETAM },
                                                                           { label: "Agent de maîtrise", value: CONST_CONSULTANT_STATUT_AGENT_MAITRISE },
                                                                           { label: "Cadre", value: CONST_CONSULTANT_STATUT_CADRE },
                                                                      ]}
                                                                      value={helpers.values.statut}
                                                                      onChange={val => helpers.setFieldValue("statut", val)}
                                                                      isInvalid={!!helpers.errors.statut}
                                                                 />
                                                                 {helpers.errors.statut && <div className="text-danger">{helpers.errors.statut}</div>}
                                                            </div>
                                                       </div>
                                                  </>
                                             )}

                                             {/* Field nationalité */}
                                             <div className="row mb-5">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nationalité</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            name={"nationalite"}
                                                            options={[
                                                                 { label: "Française", value: "NATIONALITE_FR" },
                                                                 { label: "Etrangère", value: "NATIONALITE_ETRANGERE" },
                                                            ]}
                                                            value={helpers.values.nationalite}
                                                            onChange={val => helpers.setFieldValue("nationalite", val)}
                                                            isInvalid={!!helpers.errors.nationalite}
                                                       />
                                                       {helpers.errors.nationalite && <div className="text-danger">{helpers.errors.nationalite}</div>}
                                                  </div>
                                             </div>

                                             {/* Field entite */}
                                             <div className="row mb-5">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de rattachement</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"entiteRattrachement"}
                                                            url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                            value={helpers.values.entiteRattrachement}
                                                            onChange={val => helpers.setFieldValue("entiteRattrachement", val)}
                                                            isInvalid={!!helpers.errors.entiteRattrachement}
                                                       />
                                                       {helpers.errors.entiteRattrachement && <div className="text-danger">{helpers.errors.entiteRattrachement}</div>}
                                                  </div>
                                             </div>
                                        </>
                                   )}

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Envoyer la demande <i className={"fas fa-paper-plane ms-1"} />
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>

               {/* Modal add fournisseur */}
               {isAddingFournisseur && (
                    <MyModal title={<>Nouveau fournisseur</>} show={true} handleClose={() => setIsAddingFournisseur(false)}>
                         <AddFournisseurForm callback={() => setIsAddingFournisseur(false)} />
                    </MyModal>
               )}

               {/* Modal add resp client */}
               {isAddingFournisseurResp && (
                    <MyModal title={<>Nouveau responsable fournisseur</>} show={true} handleClose={() => setIsAddingFournisseurResp(false)}>
                         <AddResponsableFournisseurForm callback={() => setIsAddingFournisseurResp(false)} />
                    </MyModal>
               )}
          </>
     )
}

interface IDemanderInfoPreEmbaucheParEmailFormValuesModel {
     typeContrat?: "TYPE_CONTRAT_SOCIETE_SERVICE" | "TYPE_CONTRAT_PORTAGE" | "TYPE_CONTRAT_SALARIE" | "TYPE_CONTRAT_FREELANCE"
     contrat?: typeof CONST_CONSULTANT_CONTRAT_CDI | typeof CONST_CONSULTANT_CONTRAT_CDD | typeof CONST_CONSULTANT_CONTRAT_CDIC
     statut?: typeof CONST_CONSULTANT_STATUT_ETAM | typeof CONST_CONSULTANT_STATUT_AGENT_MAITRISE | typeof CONST_CONSULTANT_STATUT_CADRE
     nationalite?: "NATIONALITE_FR" | "NATIONALITE_ETRANGERE"
     fournisseur?: number
     fournisseurResp?: number
     entiteRattrachement?: number
}

export default CandidatDemanderInfoPreEmbaucheParMail
