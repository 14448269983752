import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React, { useState } from "react"
import MyCard from "@common-utils/MyCard"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { Link } from "react-router-dom"
import moment from "moment"
import { getTotalBonsLivraisonManquantsOfGivenYearRequest } from "../core/_requests"
import { toast } from "react-toastify"

const REACT_QUERY_KEY_GET_DECLARATIONS_VOYANTS = "REACT_QUERY_KEY_GET_DECLARATIONS_VOYANTS_MANQUANTS"

const VoyantsMonth = ({ total, mois }: { total: number; mois: moment.Moment }) => {
     return (
          <div className={"text-center"}>
               <Link
                    to={"/client/bl/manquants"}
                    state={{
                         mois: mois.format("YYYY-MM-DD"),
                    }}
                    className={`btn btn-icon btn-${total > 0 ? "danger" : "success"}`}
                    onClick={e => {
                         if (total === 0) {
                              e.preventDefault()
                              toast.success("Aucune action requise.")
                         }
                    }}
               >
                    {total > 0 ? total : <i className={"fas fa-check-circle"} />}
               </Link>
          </div>
     )
}

const BonsLivraisonDashboardVoyantsManquantsOfGivenYear = () => {
     const [selectedYear, setSelectedYear] = useState<string>(moment().format("YYYY"))

     // Request voyants
     const blManquantsVoyantsQuery = useQuery<{ mois: moment.Moment; total: number }[], AxiosError>([REACT_QUERY_KEY_GET_DECLARATIONS_VOYANTS, selectedYear], () =>
          getTotalBonsLivraisonManquantsOfGivenYearRequest({ year: selectedYear }).then(r => r.data)
     )

     const HeaderYearSwitcher = () => {
          return (
               <div className="d-flex justify-content-between align-items-center">
                    <button
                         className={"btn btn-sm btn-outline btn-outline-dark d-flex align-items-center"}
                         onClick={() => setSelectedYear(moment(`${selectedYear}-01-01`).subtract("1", "year").format("YYYY"))}
                    >
                         <i className={"fas fa-arrow-left me-1"} /> {moment(`${selectedYear}-01-01`).subtract("1", "year").format("YYYY")}
                    </button>
                    <h2>{selectedYear}</h2>
                    <button
                         className={"btn btn-sm btn-outline btn-outline-dark d-flex align-items-center"}
                         onClick={() => setSelectedYear(moment(`${selectedYear}-01-01`).add("1", "year").format("YYYY"))}
                    >
                         {moment(`${selectedYear}-01-01`).add("1", "year").format("YYYY")} <i className={"fas fa-arrow-right ms-1"} />
                    </button>
               </div>
          )
     }

     return (
          <>
               <HeaderYearSwitcher />

               <div className="separator separator-dashed my-4" />

               {blManquantsVoyantsQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {!blManquantsVoyantsQuery.isFetching && blManquantsVoyantsQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{blManquantsVoyantsQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => blManquantsVoyantsQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* Month cards */}
               {!blManquantsVoyantsQuery.isFetching && !blManquantsVoyantsQuery.isError && blManquantsVoyantsQuery.data && (
                    <>
                         <div className={"row"}>
                              {blManquantsVoyantsQuery.data?.map((item, key) => (
                                   <div className={"col-12 col-sm-6 col-md-4 col-xl-3 mb-4"} key={key}>
                                        <MyCard>
                                             <MyCard.Header isCollapseIconShown={false}>
                                                  <span className={`fas fa-calendar-day me-2 fs-3 ${item.total > 0 ? "text-danger" : "text-success"}`} />
                                                  <Link
                                                       to={"/client/bl/manquants"}
                                                       state={{ mois: item.mois.format("YYYY-MM-DD") }}
                                                       className={`${item.total > 0 ? "text-danger" : "text-success"}`}
                                                  >
                                                       {GlobalHelper.firstLetterUppercase(item.mois.format("MMM YYYY"))}
                                                  </Link>
                                             </MyCard.Header>
                                             <MyCard.Body>
                                                  <VoyantsMonth total={item.total} mois={item.mois} />
                                             </MyCard.Body>
                                        </MyCard>
                                   </div>
                              ))}
                         </div>
                    </>
               )}
          </>
     )
}

export default BonsLivraisonDashboardVoyantsManquantsOfGivenYear
