import React from "react"
import MyRadioField from "@common-utils/fields/MyRadioField"

interface Props {
     name: string
     value: any
     isChecked: boolean
     label: string | JSX.Element
     description?: string | JSX.Element
     iconFontAwesomeClassName: string
     color: "primary" | "danger" | "warning" | "info" | "success" | "dark"
     isInvalid: boolean
     onChange: (val: any) => void
     onClickCallback?: () => void
}

const MyRadioWithLabelAndDescriptionField: React.FC<Props> = ({
     name,
     value,
     label,
     description,
     iconFontAwesomeClassName,
     color,
     onChange,
     isInvalid,
     isChecked,
     onClickCallback = () => {},
}) => {
     return (
          <label className="d-flex flex-stack cursor-pointer" onClick={() => onClickCallback()}>
               <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-50px me-6">
                         <span className={`symbol-label bg-light-${color}`}>
                              <i className={`fas fa-${iconFontAwesomeClassName} fs-2 text-${color}`} />
                         </span>
                    </span>
                    <span className="d-flex flex-column">
                         <span className="fw-bolder fs-6">{label}</span>
                         {description && <span className="fs-7 text-muted">{description}</span>}
                    </span>
               </span>
               <MyRadioField name={name} value={value} isInvalid={isInvalid} onChange={onChange} isChecked={isChecked} />
          </label>
     )
}

export default MyRadioWithLabelAndDescriptionField
