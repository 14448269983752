import { IClientPropalPeriodeModel } from "@common-models/*"
import React, { useState } from "react"
import MyTooltip from "@common-utils/MyTooltip"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyModal from "@common-utils/MyModal"
import PtfPeriodeAjoutVersionDocumentForm from "../actions/_PtfPeriodeAjoutVersionDocumentForm"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext } from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import { useQueryClient } from "react-query"
import { IGetPeriodesBonsCommandeBonsLivraisonResponseModel } from "../core/_models"
import "./styles.scss"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"

const PtfDetailsPeriodesBonsCommandeBonsLivraison_VersionsPtf = ({ periode }: IPtfDetailsPeriodesBonsCommandeBonsLivraison_VersionsPtfPropsModel) => {
     const context = usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext()
     const queryClient = useQueryClient()

     const [currentVersionInModal, setCurrentVersionInModal] = useState<{
          reference: string
          googleDriveID: string
          googleMimeType: string
          loading: boolean
          mode: "edit" | "view"
     }>()

     const [addVersionDocumentPtf, setAddVersionDocumentPtf] = useState<{
          periode: IClientPropalPeriodeModel
          modalTitle: JSX.Element
          isModalFullScreen?: true
     }>()

     function currentVersionRef() {
          return periode.reference + GlobalHelper.incrementChar("a", periode.clientPropalPeriodeSuivis?.length || 0, "upper")
     }

     function nextVersionRef() {
          return periode.reference + GlobalHelper.incrementChar("a", (periode.clientPropalPeriodeSuivis?.length || 0) + 1, "upper")
     }

     function handleSubmitAddVersionDocumentCallback(val: IClientPropalPeriodeModel) {
          setAddVersionDocumentPtf(prev =>
               prev
                    ? {
                           ...prev,
                           isModalFullScreen: true,
                           modalTitle: (
                                <div className={"d-flex"}>
                                     <div>Édition de la {nextVersionRef()}</div>
                                     <button className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"} onClick={() => setAddVersionDocumentPtf(undefined)}>
                                          Terminer <i className={"fas fa-check-cirle ms-1"} />
                                     </button>
                                </div>
                           ),
                      }
                    : undefined
          )
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         const currentVersionRefBeforeAdd = currentVersionRef()

                         prev.data.map((item, index) => {
                              item.clientPropalPeriodes?.map((item_, index_) => {
                                   // Locate the periode among the big object
                                   if (item_.id === periode.id) {
                                        // Last ref becomes penultimate ref
                                        item_.clientPropalPeriodeSuivis = [
                                             {
                                                  googleDriveOldFileID: item_.googleDriveId,
                                                  oldReference: currentVersionRefBeforeAdd,
                                             },
                                             ...(item_.clientPropalPeriodeSuivis || []),
                                        ]

                                        // Last ref becomes the new ref
                                        item_["googleDriveId"] = val.googleDriveId

                                        return item_
                                   }
                              })
                         })
                         return prev
                    }
                    return prev
               }
          )
     }

     return (
          <>
               <div className={"p-4 border border-dashed border-success mb-4"}>
                    <div className="d-flex justify-content-between">
                         <div className={"mb-2"}>
                              <div className={"fs-5 text-success fw-bold"}>Versions de la {periode.reference}</div>
                         </div>
                         <div>
                              <MyTooltip title={"Ajouter une version"}>
                                   <span
                                        className={"fas fa-plus-circle text-success fs-3 cursor-pointer"}
                                        onClick={() =>
                                             setAddVersionDocumentPtf({
                                                  periode: periode,
                                                  modalTitle: (
                                                       <span>
                                                            {currentVersionRef()} {"-->"} {nextVersionRef()}
                                                       </span>
                                                  ),
                                             })
                                        }
                                   />
                              </MyTooltip>
                         </div>
                    </div>
                    <div className="separator separator-dashed border-success mb-6" />
                    <div className="row">
                         <div
                              className="col-sm-6 col-md-4 col-xxl-3 d-flex align-items-center mb-2 cursor-pointer"
                              onClick={() =>
                                   setCurrentVersionInModal({
                                        googleDriveID: periode.googleDriveId as string,
                                        googleMimeType: periode.googleDriveMimeType as string,
                                        loading: true,
                                        reference: currentVersionRef(),
                                        mode: "view",
                                   })
                              }
                         >
                              <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="w-30px me-sm-3" alt="" />
                              <div className="ms-1 fw-bold">
                                   <span className="fs-6 text-hover-primary text-dark fw-bolder">{currentVersionRef()}</span>
                              </div>
                         </div>
                         {periode.clientPropalPeriodeSuivis?.map((periodeSuivi, periodeSuivi_suivi) => {
                              return (
                                   <div
                                        className="col-sm-6 col-md-4 col-xxl-3 d-flex align-items-center mb-2 cursor-pointer"
                                        key={periodeSuivi_suivi}
                                        onClick={() =>
                                             setCurrentVersionInModal({
                                                  googleDriveID: periodeSuivi.googleDriveOldFileID as string,
                                                  googleMimeType: periodeSuivi.googleDriveOldFileMimeType as string,
                                                  loading: true,
                                                  reference: `${periodeSuivi.oldReference}`,
                                                  mode: "view",
                                             })
                                        }
                                   >
                                        <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="w-30px me-sm-3" alt="" />
                                        <div className="ms-1 fw-bold">
                                             <span className="fs-6 text-hover-primary fw-bolder text-dark">{periodeSuivi.oldReference}</span>
                                        </div>
                                   </div>
                              )
                         })}
                    </div>
               </div>

               {/* Modal add version PTF */}
               {addVersionDocumentPtf && (
                    <MyModal
                         title={addVersionDocumentPtf.modalTitle}
                         show={true}
                         handleClose={() => setAddVersionDocumentPtf(undefined)}
                         size={"lg"}
                         fullscreen={addVersionDocumentPtf.isModalFullScreen}
                         noPadding={addVersionDocumentPtf.isModalFullScreen}
                    >
                         <PtfPeriodeAjoutVersionDocumentForm
                              ptf_periode_ID={addVersionDocumentPtf.periode!.id as number}
                              handleSubmitCallback={handleSubmitAddVersionDocumentCallback}
                              nextReference={`${nextVersionRef()}`}
                              currentVersionRef={`${currentVersionRef()}`}
                         />
                    </MyModal>
               )}

               {/* Modal view version PTF */}
               {currentVersionInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={currentVersionInModal.googleDriveID}
                         googleDriveMimetype={currentVersionInModal.googleMimeType as string}
                         modalTitle={<>{currentVersionInModal.reference}</>}
                         handleCloseModal={() => setCurrentVersionInModal(undefined)}
                         modalFullScreen
                    />
               )}
          </>
     )
}

interface IPtfDetailsPeriodesBonsCommandeBonsLivraison_VersionsPtfPropsModel {
     periode: IClientPropalPeriodeModel
}

export default PtfDetailsPeriodesBonsCommandeBonsLivraison_VersionsPtf
