import axios from "axios"
import { CONST_API_RECRUITMENT_VIVIERS_POST_EDIT_ENDPOINT, CONST_API_RECRUITMENT_VIVIERS_POST_LISTING_ENDPOINT } from "../../../../../endpoints"
import { IGetViviersRequestModel, IGetViviersResponseModel, ISetVivierRequestModel } from "./_models"
import { IVivierModel } from "@common-models/*"

export function getAnnoncesListingRequest(payload: IGetViviersRequestModel) {
     return axios.post<IGetViviersResponseModel>(CONST_API_RECRUITMENT_VIVIERS_POST_LISTING_ENDPOINT, payload)
}

export function editVivierRequest(id: number, payload: ISetVivierRequestModel) {
     return axios.post<IVivierModel>(CONST_API_RECRUITMENT_VIVIERS_POST_EDIT_ENDPOINT(id), payload)
}
