import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import { usePersonnelDetailsContext } from "../PersonnelDetails"
import moment from "moment"
import clsx from "clsx"
import MyTooltip from "@common-utils/MyTooltip"
import React, { useState } from "react"
import MyDateDayMonthYearHoursMinutesField from "@common-utils/fields/MyDateDayMonthYearHoursMinutesField"
import MyModal from "@common-utils/MyModal"
import { personnelDisableAccountRequest, personnelEnableAccountRequest } from "../../core/_requests"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { IGetPersonnelDetailsResponseModel } from "../../core/_models"
import { useQueryClient } from "react-query"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import swal from "sweetalert2"

const Compte = () => {
     const queryClient = useQueryClient()
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data
     const [isGoingToBlockAccess, setIsGoingToBlockAccess] = useState<boolean>(false)

     const FormDisableAccessInModal = () => {
          interface IPersonnelDisableAccessRequestModel {
               dateFinAccess?: string
          }

          // Form schema
          const schema = Yup.object().shape({
               dateFinAccess: Yup.string().required().label(`"Date de fin d'accès"`),
          })

          // Init values
          const initialValues: IPersonnelDisableAccessRequestModel = {
               dateFinAccess: moment().format("YYYY-MM-DDTHH:mm"),
          }

          // Handle submit
          function handleSubmit(values: IPersonnelDisableAccessRequestModel, helpers: FormikHelpers<IPersonnelDisableAccessRequestModel>) {
               helpers.setStatus(null)

               personnelDisableAccountRequest(details?.personnel.id as number, values.dateFinAccess as string)
                    .then(() => {
                         queryClient.setQueryData<IGetPersonnelDetailsResponseModel | undefined>(
                              context.REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                              (prev: IGetPersonnelDetailsResponseModel | undefined) => {
                                   if (prev) {
                                        prev.personnelCompte = {
                                             ...prev.personnelCompte,
                                             dateFinAcces: moment(values.dateFinAccess),
                                        }
                                   }
                                   return prev
                              }
                         )
                         toast.success(
                              moment(values.dateFinAccess).isAfter(moment())
                                   ? `Les accès seront coupés à partir du ${moment(values.dateFinAccess).format("dddd D MMMM YYYY")}, soit ${moment(
                                          values.dateFinAccess
                                     ).fromNow()}`
                                   : "Les accès ont été coupés avec succès."
                         )
                         setIsGoingToBlockAccess(false)
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                         }

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)
                         toast.error(error?.detail, { autoClose: false })

                         // Stop submit loader
                         helpers.setSubmitting(false)
                    })
          }

          return (
               <>
                    <MyModal show={true} handleClose={() => setIsGoingToBlockAccess(false)} title={<>Renseigner la date de fin d'accès</>}>
                         <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                              {helpers => {
                                   return (
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}

                                             <MyDateDayMonthYearHoursMinutesField
                                                  name={"dateFinAccess"}
                                                  value={helpers.values.dateFinAccess}
                                                  placeholder={"Date de fin d'accès"}
                                                  onChange={val => helpers.setFieldValue("dateFinAccess", val)}
                                                  minDate={moment().format("YYYY-MM-DD HH:mm")}
                                             />

                                             <div className="d-flex justify-content-end mt-5">
                                                  <button
                                                       type={"button"}
                                                       className={"btn btn-outline btn-active-secondary btn-sm me-2"}
                                                       onClick={() => setIsGoingToBlockAccess(false)}
                                                       disabled={helpers.isSubmitting}
                                                  >
                                                       Annuler
                                                  </button>
                                                  <button type={"submit"} className={"btn btn-primary btn-sm"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? <>Confirmer</> : <MySimpleSpinner size={"sm"} />}
                                                  </button>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </MyModal>
               </>
          )
     }

     function enableAccount() {
          swal.fire({
               icon: "warning",
               text: details?.personnelCompte?.dateFinAcces?.isAfter(moment())
                    ? `Le compte est prévu pour être désactivé le ${details?.personnelCompte?.dateFinAcces?.format(
                           "dddd D MMMM YYYY à H[h]mm"
                      )}. Désirez-vous annuler cette opération?`
                    : `Voulez-vous vraiment réactiver ce compte ?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return personnelEnableAccountRequest(details?.personnel.id as number)
                         .then(() => {
                              queryClient.setQueryData<IGetPersonnelDetailsResponseModel | undefined>(
                                   context.REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                                   (prev: IGetPersonnelDetailsResponseModel | undefined) => {
                                        if (prev) {
                                             prev.personnelCompte = {
                                                  ...prev.personnelCompte,
                                                  dateFinAcces: undefined,
                                             }
                                        }
                                        return prev
                                   }
                              )
                              toast.success("Compte réactivé avec succès.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <>
               <MyCard classNames={"mb-5"}>
                    <MyCard.Header
                         rightSideContent={
                              <MyTooltip
                                   title={
                                        details?.personnelCompte?.dateFinAcces && moment().isAfter(details?.personnelCompte?.dateFinAcces)
                                             ? "Réouvrir les accès"
                                             : "Couper les accès"
                                   }
                              >
                                   <i
                                        className={clsx(`fad fa-power-off fs-1 cursor-pointer`, {
                                             "text-danger text-hover-success": moment().isAfter(details?.personnelCompte?.dateFinAcces),
                                             "text-success text-hover-danger": !details?.personnelCompte?.dateFinAcces || moment().isBefore(details?.personnelCompte?.dateFinAcces),
                                        })}
                                        onClick={() => (!details?.personnelCompte?.dateFinAcces ? setIsGoingToBlockAccess(true) : enableAccount())}
                                   />
                              </MyTooltip>
                         }
                    >
                         <div className={"d-flex align-items-center"}>Compte</div>
                    </MyCard.Header>
                    <MyCard.Body>
                         {details?.personnelCompte?.dateFinAcces && (
                              <div className={"mb-5"}>
                                   {moment().isAfter(details?.personnelCompte?.dateFinAcces) ? (
                                        <MyAlert type={"danger"}>
                                             Ce compte a été désactivé le <b className={"text-dark"}>{details?.personnelCompte?.dateFinAcces.format("dddd D MMMM YYYY à H[h]m")}</b>
                                        </MyAlert>
                                   ) : (
                                        <MyAlert type={"warning"}>Ce compte sera désactivé le {details?.personnelCompte?.dateFinAcces.format("dddd D MMMM YYYY à H[h]m")}</MyAlert>
                                   )}
                              </div>
                         )}
                         <div className="row">
                              <label className="col-lg-4 fw-bold text-muted">Dernière connexion</label>
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">
                                        {details?.personnelCompte?.dateDerniereConnexion
                                             ? details?.personnelCompte?.dateDerniereConnexion?.format("dddd D MMMM YYYY à HH[h]mm")
                                             : "Jamais connecté"}
                                   </span>
                              </div>
                         </div>
                    </MyCard.Body>
               </MyCard>

               <MyCard classNames={"mb-5"}>
                    <MyCard.Header>Historique</MyCard.Header>
                    <MyCard.Body>
                         <MyAlert type={"primary"}>Soon ...</MyAlert>
                    </MyCard.Body>
               </MyCard>

               <MyCard classNames={"mb-5"}>
                    <MyCard.Header>Logs</MyCard.Header>
                    <MyCard.Body>
                         <MyAlert type={"primary"}>Soon ...</MyAlert>
                    </MyCard.Body>
               </MyCard>

               {isGoingToBlockAccess && <FormDisableAccessInModal />}
          </>
     )
}

export default Compte
