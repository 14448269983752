import axios from "axios"
import { CONST_API_PERSONNEL_DECLARATION_LISTING_ENDPOINT, CONST_API_PERSONNEL_RELANCE_DECLARATION_ENDPOINT } from "../../../../../../endpoints"
import { IGetStructureDeclarationsRequestModel, IGetStructureDeclarationsResponseModel, IRelanceDeclarationActiviteRequestModel } from "./_models"

export function getStructureDeclarationsListingRequest(payload: IGetStructureDeclarationsRequestModel) {
     return axios.post<IGetStructureDeclarationsResponseModel>(CONST_API_PERSONNEL_DECLARATION_LISTING_ENDPOINT, payload)
}

export function relancePersonnelsDeclarationActiviteRequest(payload: IRelanceDeclarationActiviteRequestModel) {
     return axios.post(CONST_API_PERSONNEL_RELANCE_DECLARATION_ENDPOINT, payload)
}
