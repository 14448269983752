import React from "react"

type Props = {
     title?: React.ReactNode | string
     children: React.ReactNode | string
     type: "warning" | "danger" | "info" | "primary" | "success" | "secondary"
     classNames?: string
     borderDashed?: boolean
     customIcon?: string
     isDismissible?: boolean
}

const MyAlert: React.FC<Props> = ({ title, children, type, classNames, borderDashed, customIcon, isDismissible = false }) => {
     const faIcon = (() => {
          if (type == "success") return "fas fa-check-circle"
          if (type == "primary") return "fas fa-info-circle"
          if (type == "info") return "fas fa-info-circle"
          if (type == "warning") return "fas fa-exclamation-triangle"
          if (type == "danger") return "fas fa-exclamation-triangle"
          if (type == "secondary") return "fas fa-info-circle"

          return "fas fa-exclamation-triangle"
     })()

     return (
          <div
               className={`alert ${isDismissible ? "alert-dismissible" : ""} bg-light-${type} border-${type} ${borderDashed ? "border-dashed " : "border"} m-0 ${
                    classNames ? classNames : ""
               } d-flex align-items-center p-0 px-4 py-3`}
          >
               <span className={`me-2 d-flex align-items-center`}>
                    <i className={`${customIcon ? customIcon : faIcon} text-${type}`} style={{ fontSize: "1.5rem" }} />
               </span>

               <div className={`d-flex flex-stack flex-grow-1`}>
                    <div className="fw-bold">
                         {title && <h4 className="text-gray-900 fw-bolder">{title}</h4>}

                         <div className="fs-6 text-gray-700">{children}</div>
                    </div>
               </div>

               {isDismissible && (
                    <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                         <i className={`bi bi-x fs-1 text-${type}`} />
                    </button>
               )}
          </div>
     )
}

export default MyAlert
