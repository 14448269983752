import React from "react"
import MyCard from "@common-utils/MyCard"
import * as Yup from "yup"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import { IStructureDeclarationsListingFiltersFormikValuesModel } from "./core/_models"
import MySelectField from "@common-utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { useAuth } from "../../../../../AppContext"
import { useStructureDeclarationsListingContext } from "./GestionStructureDeclarationsListing"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT, CONST_API_UTILS_GET_AGENCES_ENDPOINT, CONST_API_UTILS_GET_ENTITES_ENDPOINT } from "../../../../../endpoints"
import { ETATS_FOR_ADMIN_DECLARATION_PRODUCTION_OPTIONS_SELECT2_FIELD, ETATS_FOR_NON_ADMIN_DECLARATION_PRODUCTION_OPTIONS_SELECT2_FIELD } from "./core/consts"
import { useLocation } from "react-router-dom"
import { CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF } from "@common-constants/*"

// Filters
const PersonnelsDeclarationsListingFilters = () => {
     const location = useLocation() as {
          state: {
               etat?:
                    | "ETAT_ADMIN__EN_ATTENTE_RECEPTION"
                    | "ETAT_ADMIN__EN_COURS_RECTIFICATION"
                    | "ETAT_ADMIN__A_VALIDER"
                    | "ETAT_ADMIN__VALIDE_POUR_PAIE"
                    | "ETAT_NON_ADMIN__EN_ATTENTE_RECEPTION"
                    | "ETAT_NON_ADMIN__EN_COURS_RECTIFICATION"
                    | "ETAT_NON_ADMIN__A_ENVOYER_AU_SIEGE"
                    | "ETAT_NON_ADMIN__A_CONTROLER"
                    | "ETAT_NON_ADMIN__ENVOYE_AU_SIEGE"
                    | "ETAT_NON_ADMIN__VALIDE_POUR_PAIE"
               mois?: string
               keyword?: string
          }
     }

     const { currentUser } = useAuth()
     const context = useStructureDeclarationsListingContext()
     const doesConnectedUserHaveAccessToModuleAdmin = (() => {
          return currentUser?.authorizedAppModules?.find(item => item === CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF) !== undefined
     })()

     const schema = Yup.object().shape({
          simpleSearch: Yup.string().label(`"Mot clé"`),
          dateCreationDebut: Yup.string()
               .test("salaireMinMax", function (min) {
                    const max = this.parent.dateCreationFin
                    if (min === undefined || max === undefined || min === null || max === null) return true // No need to test
                    return new Date(min) <= new Date(max)
               })
               .label(`"Période recherche début"`),
          dateCreationFin: Yup.string()
               .test("salaireMinMax", function (max) {
                    const min = this.parent.dateCreationDebut
                    if (min === undefined || max === undefined || min === null || max === null) return true // No need to test
                    return new Date(max) >= new Date(min)
               })
               .label(`"Période recherche fin"`),
          responsables: Yup.array().label(`"Responsables"`),
          agences: Yup.array().label(`"Agences"`),
     })

     const initialValues: IStructureDeclarationsListingFiltersFormikValuesModel = {
          etat: context.filters.etat,
          keyword: context.filters.keyword,
     }

     function handleSubmit(values: IStructureDeclarationsListingFiltersFormikValuesModel) {
          context.setFilters(prev => ({
               ...prev,
               keyword: values.keyword,
               entites: values.entites,
               agences: values.agences,
               responsables: values.responsables,
               etat: values.etat,
          }))
     }

     function handleReset() {
          context.filtersFormikRef.current.resetForm() // Reset local form
          context.simpleSearchBarFormikRef.current.resetForm() // Reset search bar form
          // Reset payload filters
          context.setFilters(prev => ({
               page: 1,
               pageLength: 20,
               mois: prev.mois,
          }))

          context.filtersFormikRef.current.setSubmitting(true)
     }

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <MyTooltip title={"Cacher les filters"}>
                              <button className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={() => context.setAreFiltersVisible(prev => !prev)}>
                                   <i className={"fas fa-eye-slash fs-4 text-dark"} />
                              </button>
                         </MyTooltip>
                    }
               >
                    Filtres
               </MyCard.Header>

               <MyCard.Body>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} innerRef={context.filtersFormikRef} enableReinitialize>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mot clé</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"keyword"}
                                                       value={helpers.values.keyword}
                                                       onChange={val => {
                                                            helpers.setFieldValue("keyword", val).then()
                                                            context.simpleSearchBarFormikRef.current.setFieldValue("keyword", val).then()
                                                       }}
                                                       isInvalid={!!helpers.errors.keyword}
                                                       placeholder={"Rechercher ..."}
                                                  />
                                                  {helpers.errors.keyword && <div className={"text-danger"}>{helpers.errors.keyword}</div>}
                                             </div>
                                        </div>

                                        <div className="separator separator-dotted mt-4 mb-8"></div>

                                        {/* Entités */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Entités</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"entites"}
                                                       url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       value={helpers.values.entites}
                                                       onChange={val => helpers.setFieldValue("entites", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.entites}
                                                       isMulti
                                                  />
                                                  {helpers.errors.entites && <div className={"text-danger"}>{helpers.errors.entites}</div>}
                                             </div>
                                        </div>

                                        {/* Agences */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Agences</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"agences"}
                                                       url={CONST_API_UTILS_GET_AGENCES_ENDPOINT}
                                                       value={helpers.values.agences}
                                                       onChange={val => helpers.setFieldValue("agences", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.agences}
                                                       isMulti
                                                  />
                                                  {helpers.errors.agences && <div className={"text-danger"}>{helpers.errors.agences}</div>}
                                             </div>
                                        </div>

                                        {/* Responsables */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Responsables</label>

                                             {/* Responsables */}
                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"responsables"}
                                                       url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                       value={helpers.values.responsables}
                                                       onChange={val => helpers.setFieldValue("responsables", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.responsables}
                                                       isMulti
                                                  />
                                                  {helpers.errors.responsables && <div className={"text-danger"}>{helpers.errors.responsables}</div>}
                                             </div>
                                        </div>

                                        {/* Responsables */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Etat</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"etat"}
                                                       options={
                                                            doesConnectedUserHaveAccessToModuleAdmin
                                                                 ? ETATS_FOR_ADMIN_DECLARATION_PRODUCTION_OPTIONS_SELECT2_FIELD
                                                                 : ETATS_FOR_NON_ADMIN_DECLARATION_PRODUCTION_OPTIONS_SELECT2_FIELD
                                                       }
                                                       value={helpers.values.etat}
                                                       onChange={val => helpers.setFieldValue("etat", val)}
                                                       isInvalid={!!helpers.errors.etat}
                                                  />
                                                  {helpers.errors.etat && <div className={"text-danger"}>{helpers.errors.etat}</div>}
                                             </div>
                                        </div>

                                        <div className={"text-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  disabled={helpers.isSubmitting}
                                                  onClick={() => handleReset()}
                                             >
                                                  <span className={"d-none d-lg-block"}>Réinitialiser</span> <i className={"fas fa-sync ms-lg-1"} />
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  <span className={"d-none d-lg-block"}>Filtrer </span>
                                                  {!helpers.isSubmitting ? (
                                                       <i className={"fas fa-filter ms-md-1"} />
                                                  ) : (
                                                       <span className="spinner-border spinner-border-sm align-middle ms-lg-1" />
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyCard.Body>
          </MyCard>
     )
}

export default PersonnelsDeclarationsListingFilters
