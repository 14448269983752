import React from "react"
import MyCard from "@common-utils/MyCard"
import CandidatsRechercheFormNouvelleRecherche from "./CandidatsRechercheFormNouvelleRecherche"
import CandidatRechercheLogsAndSavedFilters from "./CandidatsRechercheSauvegardesEtLogs"

const CandidatsRecherche = () => {
     return (
          <div className={"row"}>
               <div className="col-12 col-xl-7 mb-5 mb-xl-0">
                    <MyCard>
                         <MyCard.Header>
                              <i className={"fas fa-search fs-4 text-dark me-2"} /> Nouvelle recherche
                         </MyCard.Header>
                         <MyCard.Body>
                              <div className={"w-xxl-80"}>
                                   <CandidatsRechercheFormNouvelleRecherche />
                              </div>
                         </MyCard.Body>
                    </MyCard>
               </div>
               <div className="col-12 col-xl-5">
                    <MyCard>
                         <MyCard.Header>
                              <i className={"fas  fa-list-alt fs-4 text-dark me-2"} />
                              Sauvegardes et historique de recherche
                         </MyCard.Header>
                         <MyCard.Body>
                              <CandidatRechercheLogsAndSavedFilters />
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default CandidatsRecherche
