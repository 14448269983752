import { useRef, useState } from "react"
import { ISimulationRentabiliteConsultantResponseModel } from "../../../business/consultants/core/_models"
import MyCard from "@common-utils/MyCard"
import CostToolForm from "./CostTool_Form"
import CostToolResults from "./CostTool_Results"

const CostTool = () => {
     const [simulationResults, setSimulationResults] = useState<{ btc: ISimulationRentabiliteConsultantResponseModel }>()
     const calculatriceHeaderRef = useRef<any>()
     const [resultatsVersion, setResultatsVersion] = useState<"BM" | "CANDIDAT">("BM")

     function handleSubmitSimulation(results: { btc: ISimulationRentabiliteConsultantResponseModel }) {
          // For column salaire + hs
          results.btc.base35hPlusHeuresSuppMensuelBrut = results.btc.salaireBrutMensuel + results.btc.coutHeuresSuppMensuel
          results.btc.base35hPlusHeuresSuppAnnuelBrut = results.btc.salaireBrutAnnuel + results.btc.coutHeuresSuppAnnuel
          results.btc.base35hPlusHeuresSuppMensuelNet = results.btc.salaireChargeSansHeuresSuppMensuel + results.btc.salaireQueLesHeuresSuppMensuelCharge
          results.btc.base35hPlusHeuresSuppAnnuelNet = results.btc.salaireChargeSansHeuresSuppAnnuel + results.btc.salaireQueLesHeuresSuppAnnuelCharge

          // For column “salaire net versé sur bulletin de salaire“
          results.btc.salaireNetVerseSurBulletingSalaireMensuel =
               results.btc.salaireChargeSansHeuresSuppMensuel + results.btc.salaireQueLesHeuresSuppMensuelCharge - results.btc.partMensuelleTicketsRestoSalarie
          results.btc.salaireNetVerseSurBulletingSalaireAnnuel =
               results.btc.salaireChargeSansHeuresSuppAnnuel + results.btc.salaireQueLesHeuresSuppAnnuelCharge - results.btc.partAnnuelleTicketsRestoSalarie

          // For column “Equivalent brut annuel & mensuel“
          results.btc.equivalentBrutAvecTicketRestoAnnuel =
               (results.btc.salaireChargeSansHeuresSuppAnnuel + results.btc.salaireQueLesHeuresSuppAnnuelCharge + results.btc.partAnnuelleTicketsRestoEmployeur) /
               results.btc.coeffChargesSalariales
          results.btc.equivalentBrutAvecTicketRestoMensuel =
               (results.btc.salaireChargeSansHeuresSuppMensuel + results.btc.salaireQueLesHeuresSuppMensuelCharge + results.btc.partMensuelleTicketsRestoEmployeur) /
               results.btc.coeffChargesSalariales
          results.btc.equivalentBrutSansTicketRestoAnnuel =
               (results.btc.salaireChargeSansHeuresSuppAnnuel + results.btc.salaireQueLesHeuresSuppAnnuelCharge) / results.btc.coeffChargesSalariales
          results.btc.equivalentBrutSansTicketRestoMensuel =
               (results.btc.salaireChargeSansHeuresSuppMensuel + results.btc.salaireQueLesHeuresSuppMensuelCharge) / results.btc.coeffChargesSalariales

          // Add pairs for "coûts" table
          // -- Row coût total
          results.btc.coutTotalSalarieAnnuel = results.btc.coutSalaireAnnuelCharge + results.btc.coutHeuresSuppAnnuelCharge + results.btc.partAnnuelleTicketsRestoEmployeur
          results.btc.coutTotalSalarieMensuel = results.btc.coutSalaireMensuelCharge + results.btc.coutHeuresSuppMensuelCharge + results.btc.partMensuelleTicketsRestoEmployeur
          results.btc.coutTotalSalarieJournalier =
               results.btc.coutSalaireJournalierCharge + results.btc.coutHeuresSuppJournalierCharge + results.btc.partJournaliereTicketsRestoEmployeur
          // Add pairs for "marge" table
          // -- MBP en chiffre
          results.btc.margeBrutProductionAnnuelle = results.btc.chiffreAffairesAnnuel - results.btc.coutTotalSalarieAnnuel
          results.btc.margeBrutProductionMensuelle = results.btc.chiffreAffairesMensuel - results.btc.coutTotalSalarieMensuel
          results.btc.margeBrutProductionJournaliere = results.btc.chiffreAffairesJournalier - results.btc.coutTotalSalarieJournalier
          // -- MBP en pourcentage
          results.btc.margeBrutProductionEnPourcentage = ((results.btc.chiffreAffairesAnnuel - results.btc.coutTotalSalarieAnnuel) / results.btc.chiffreAffairesAnnuel) * 100

          // results.btc.margeBrutProductionEnPourcentage = results.btc.margeBrutProductionEnPourcentage.toFixed(2)
          //
          // results.btc.coeffChargesSalariales = GlobalHelper.separateNumber((results.btc.coeffChargesSalariales.toFixed(2))

          setSimulationResults(results)

          calculatriceHeaderRef.current?.click()
     }

     return (
          <>
               <MyCard classNames={"mb-4"}>
                    <MyCard.Header isCollapseIconShown refHeader={calculatriceHeaderRef}>
                         Paramètres
                    </MyCard.Header>
                    <MyCard.Body>
                         <CostToolForm callback={handleSubmitSimulation} />
                    </MyCard.Body>
               </MyCard>

               {simulationResults && (
                    <MyCard classNames={"mb-4"}>
                         <MyCard.Header
                              rightSideContent={
                                   <button type={"button"} className={"btn btn-sm btn-primary"} onClick={() => setResultatsVersion(prev => (prev === "BM" ? "CANDIDAT" : "BM"))}>
                                        {resultatsVersion === "BM" ? "Version candidat" : "Version BM"}
                                   </button>
                              }
                         >
                              Résultats
                         </MyCard.Header>
                         <MyCard.Body>
                              <CostToolResults results={simulationResults} version={resultatsVersion} />
                         </MyCard.Body>
                    </MyCard>
               )}
          </>
     )
}

export default CostTool
