import { IGetDetailsConsultant } from "../core/_models"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { getSimulationRentabiliteAvantApres } from "../../core/_requests"
import {
     CONST_CONSULTANT_STATUT_ST_FREELANCE,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_CONSULTANT_STATUTS_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { ISimulationRentabiliteConsultantAvantApresResponseModel, ISimulationRentabiliteConsultantRequestModel } from "../../core/_models"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectField from "@common-utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_BASES_FACTU_ENDPOINT } from "../../../../../endpoints"

const ConsultantDetailsSimulateurRentabiliteForm = ({ consultantDetails, callback = () => {} }: IConsultantDetailsSimulateurRentabiliteFormPropsModel) => {
     const schema = Yup.object().shape({
          statut: Yup.string().label(`"Statut"`).required(),
          salaireMensuelBrutBase35h: Yup.number().label(`"Salaire"`).required(),
          heuresSuppHebdo: Yup.number().label(`"Heures supp. hebdo."`).required(),
          baseFacturationAnnuelle: Yup.number().label(`"Base facturation"`).required(),
          tjm: Yup.number().label(`"TJM"`).required(),
     })

     // Form initial values
     const initialValues: ISimulationRentabiliteConsultantRequestModel = {
          statut: consultantDetails.consultant.statut as string,
          salaireMensuelBrutBase35h: consultantDetails.consultant.coutBrutNonCharge,
          heuresSuppHebdo: consultantDetails.consultant.heuresTravailSupp ? consultantDetails.consultant.heuresTravailSupp * 5 : consultantDetails.consultant.heuresTravailSupp,
          tjm: consultantDetails.consultant.tjm,
          baseFacturationAnnuelle: consultantDetails.consultant.baseFacturationAnnuelle?.id,
     }

     // Formik handle submit
     function handleSubmit(values: ISimulationRentabiliteConsultantRequestModel, helpers: FormikHelpers<ISimulationRentabiliteConsultantRequestModel>) {
          helpers.setStatus(null)

          getSimulationRentabiliteAvantApres(consultantDetails.consultant.id as number, values)
               .then(r => {
                    callback(r.data)
                    toast.success("Simulation effectuée.")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Statut */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Statut</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"statut"}
                                                  value={helpers.values.statut}
                                                  options={CONST_CONSULTANT_STATUTS_OPTIONS_FOR_SELECT2.filter(
                                                       ({ value }) =>
                                                            ![
                                                                 CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
                                                                 CONST_CONSULTANT_STATUT_ST_FREELANCE,
                                                                 CONST_CONSULTANT_STATUT_ST_PORTAGE,
                                                            ].includes(value)
                                                  )}
                                                  isInvalid={!!helpers.errors.statut}
                                                  onChange={val => helpers.setFieldValue("statut", val)}
                                             />
                                             {helpers.errors.statut && <div className={"text-danger"}>{helpers.errors.statut}</div>}
                                        </div>
                                   </div>

                                   {/* Salaire brut mensuel base 35h */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Salaire brut mensuel base 35h</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  type={"number"}
                                                  name={"salaireMensuelBrutBase35h"}
                                                  value={helpers.values.salaireMensuelBrutBase35h}
                                                  isInvalid={!!helpers.errors.salaireMensuelBrutBase35h}
                                                  inputMode={"numeric"}
                                                  onChange={val => helpers.setFieldValue("salaireMensuelBrutBase35h", val)}
                                             />
                                             {helpers.errors.salaireMensuelBrutBase35h && <div className={"text-danger"}>{helpers.errors.salaireMensuelBrutBase35h}</div>}
                                        </div>
                                   </div>

                                   {/* Heures supp hebdo*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Heures supp. / semaine</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  type={"number"}
                                                  name={"heuresSuppHebdo"}
                                                  inputMode={"numeric"}
                                                  value={helpers.values.heuresSuppHebdo}
                                                  isInvalid={!!helpers.errors.heuresSuppHebdo}
                                                  onChange={val => helpers.setFieldValue("heuresSuppHebdo", val)}
                                             />
                                             {helpers.errors.heuresSuppHebdo && <div className={"text-danger"}>{helpers.errors.heuresSuppHebdo}</div>}
                                        </div>
                                   </div>

                                   {/* Base de facturation */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Base de facturation annuelle</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"baseFacturationAnnuelle"}
                                                  value={helpers.values.baseFacturationAnnuelle}
                                                  defaultOptions={
                                                       consultantDetails.consultant.baseFacturationAnnuelle
                                                            ? [
                                                                   {
                                                                        value: consultantDetails.consultant?.baseFacturationAnnuelle?.id as number,
                                                                        label: consultantDetails.consultant?.baseFacturationAnnuelle?.nbJoursPlusPlus!.toString(),
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  url={CONST_API_UTILS_GET_BASES_FACTU_ENDPOINT}
                                                  isInvalid={!!helpers.errors.baseFacturationAnnuelle}
                                                  onChange={val => helpers.setFieldValue("baseFacturationAnnuelle", val)}
                                             />
                                             {helpers.errors.baseFacturationAnnuelle && <div className={"text-danger"}>{helpers.errors.baseFacturationAnnuelle}</div>}
                                        </div>
                                   </div>

                                   {/* TJM */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Taux de vente journalier</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  type={"number"}
                                                  name={"tjm"}
                                                  inputMode={"numeric"}
                                                  value={helpers.values.tjm}
                                                  isInvalid={!!helpers.errors.tjm}
                                                  onChange={val => helpers.setFieldValue("tjm", val)}
                                             />
                                             {helpers.errors.tjm && <div className={"text-danger"}>{helpers.errors.tjm}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Calculer la rentabilité <i className={"fas fa-calculator fs-4 ms-1"} />
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IConsultantDetailsSimulateurRentabiliteFormPropsModel {
     consultantDetails: IGetDetailsConsultant
     callback: (results: ISimulationRentabiliteConsultantAvantApresResponseModel) => void
}

export default ConsultantDetailsSimulateurRentabiliteForm
