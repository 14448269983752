import * as Yup from "yup"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG, CONST_MIME_IMAGE_PNG, CONST_PAYS_ID_FRANCE } from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel, IPersonnelCompteModel } from "@common-models/*"
import { toast } from "react-toastify"
import { ISetInfoPersoPersonnelCompteRequestModel } from "../core/_models"
import { useCompteInfoPersoContext } from "./CompteInfoPerso"
import MyAlert from "@common-utils/MyAlert"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT, CONST_API_UTILS_GET_PAYS_ENDPOINT, CONST_API_UTILS_GET_VILLES_URL_ENDPOINT } from "../../../../endpoints"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { setInfoPersoPersonnelCompteRequest } from "../core/_requests"
import { useQueryClient } from "react-query"
import { useAuth } from "../../../../AppContext"
import { useNavigate } from "react-router-dom"

function CompteInfoPersoEdit() {
     const queryClient = useQueryClient()
     const navigate = useNavigate()
     const { setCurrentUser, currentUser } = useAuth()
     const context = useCompteInfoPersoContext()
     const data = context.infoPersoQuery.data as IPersonnelCompteModel

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const [avatarPreview, setAvatarPreview] = useState<any>(
          data.personnel?.avatarGoogleDriveId ? FileAndDriveHelper.getThumbnailDriveUrl(data.personnel?.avatarGoogleDriveId) : toAbsoluteUrl("/media/svg/avatars/blank.svg")
     )

     const schema = Yup.object().shape({
          email: Yup.string().email().label(`"Email"`).required().nullable(),
          dateNaissance: Yup.string().label(`"Date de naissance"`).required().nullable(),
          telIndicatif: Yup.number().label(`"Indicatif tél."`).required().nullable(),
          tel: Yup.string().label(`"Indicatif tél."`).required().nullable(),
          nSecuriteSociale: Yup.string().label(`"N° de sécurité sociale"`).nullable().required().min(21, "Ce champ doit contenir 15 chiffres"),
          domicileAdresse: Yup.string().label(`"Adresse de résidence"`).nullable().required(),
          domicilePays: Yup.number().label(`"Pays de résidence"`).nullable().required(),
          domicileVille: Yup.string()
               .label(`"Ville de résidence"`)
               .nullable()
               .when("domicilePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    otherwise: schema => schema.required(),
               }),
          domicileVilleFrance: Yup.number()
               .label(`"Ville de résidence"`)
               .nullable()
               .when("domicilePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          domicileCodePostal: Yup.number().label(`"Code postal de résidence"`).nullable().required(),
          contactUrgenceNom: Yup.string().label(`"Nom"`).nullable().required(),
          contactUrgencePrenom: Yup.string().label(`"Prénom"`).nullable().required(),
          contactUrgenceTelIndicatif: Yup.number().label(`"Indicatif tél."`).nullable().required(),
          contactUrgenceTel: Yup.string().label(`"Téléphone"`).nullable().required(),
          avatar: Yup.mixed()
               .test("fileFormat", "Seuls les formats JPEG ou PNG sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Avatar"`)
               .nullable(),
     })

     const initialValues: ISetInfoPersoPersonnelCompteRequestModel = {
          telIndicatif: data.personnel?.telPersonnelIndicatif?.id,
          tel: data.personnel?.telPersonnel,
          email: data.personnel?.mailPersonnel,
          dateNaissance: data.personnel?.dateNaissance?.format("YYYY-MM-DD"),
          nSecuriteSociale: data.personnel?.nSecuriteSociale,
          domicileAdresse: data.personnel?.residenceAdresse,
          domicilePays: data.personnel?.residencePays?.id,
          domicileVille: data.personnel?.residenceVille,
          domicileVilleFrance: data.personnel?.residenceVilleFrance?.id,
          domicileCodePostal: data.personnel?.residenceCodePostal,
          contactUrgenceNom: data.personnel?.personneToContactUrgenceNom,
          contactUrgencePrenom: data.personnel?.personneToContactUrgencePrenom,
          contactUrgenceTelIndicatif: data.personnel?.personneToContactUrgenceTelIndicatif?.id,
          contactUrgenceTel: data.personnel?.personneToContactUrgenceTel,
     }

     function handleSubmit(values: ISetInfoPersoPersonnelCompteRequestModel, helpers: FormikHelpers<ISetInfoPersoPersonnelCompteRequestModel>) {
          helpers.setStatus(null)

          setInfoPersoPersonnelCompteRequest(values)
               .then(r => {
                    queryClient.setQueryData<IPersonnelCompteModel | undefined>(context.REACT_QUERY_KEY_GET_INFO_PERSO, (prev: IPersonnelCompteModel | undefined) => {
                         if (prev) {
                              prev.personnel = {
                                   ...prev.personnel,
                                   ...r.data.personnel,
                              }
                         }
                         return prev
                    })
                    setCurrentUser(prev => prev && { ...prev, avatarGoogleDriveID: r.data.personnel?.avatarGoogleDriveId, isProfilCompleted: true })

                    if (!currentUser?.isProfilCompleted) {
                         toast.success("Vos informatios ont bien été transmises.")
                         navigate("/dashboard")
                    } else {
                         toast.success("Vos modifications ont bien été prises en compte")
                    }
                    context.setMode("preview")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              {/* Field Avatar */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Photo</label>
                                   <div className="col-lg-8">
                                        <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${avatarPreview})` }}>
                                             <MyLoadingOverlay isActive={isAvatarLoading}>
                                                  <img
                                                       className="image-input-wrapper w-125px h-125px"
                                                       src={avatarPreview}
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       alt={"Avatar"}
                                                  ></img>
                                             </MyLoadingOverlay>

                                             <label
                                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                  data-kt-image-input-action="change"
                                                  data-bs-original-title="Change avatar"
                                             >
                                                  <i className="bi bi-pencil-fill fs-7"></i>
                                                  <input
                                                       type="file"
                                                       name="avatar"
                                                       accept=".png, .jpg, .jpeg"
                                                       onChange={e => {
                                                            const file = e.target.files![0]
                                                            helpers.setFieldValue("avatar", file)
                                                            const reader = new FileReader()
                                                            reader.onloadend = () => {
                                                                 // Set the image preview URL
                                                                 setAvatarPreview(reader.result as string)
                                                            }
                                                            reader.readAsDataURL(file)
                                                       }}
                                                  />
                                             </label>
                                        </div>
                                   </div>
                              </div>
                              {helpers.errors.avatar && <div className={"text-danger"}>{helpers.errors.avatar}</div>}

                              {/*Date de naissance*/}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                        <span>Date de naissance</span>
                                   </label>

                                   <div className="col-lg-8">
                                        <MyDateDayMonthYearField
                                             name={"dateNaissance"}
                                             onChange={date => helpers.setFieldValue("dateNaissance", date)}
                                             value={helpers.values.dateNaissance}
                                             isInvalid={!!helpers.errors.dateNaissance}
                                             placeholder={"Date de naissance"}
                                        />
                                        {helpers.errors.dateNaissance && <div className={"text-danger"}>{helpers.errors.dateNaissance}</div>}
                                   </div>
                              </div>

                              {/* Email */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email perso.</label>

                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"email"}
                                             value={helpers.values.email}
                                             isInvalid={!!helpers.errors.email}
                                             onChange={val => helpers.setFieldValue("email", val)}
                                             placeholder={"Email"}
                                        />
                                        {helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}
                                   </div>
                              </div>

                              {/* Field Telindicatif + Tél*/}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone perso.</label>

                                   <div className="col-lg-8">
                                        <div className="row">
                                             <div className="col-lg-6 mb-2 mb-lg-0">
                                                  <MySelectGetAllDataFromServer
                                                       name={"telIndicatif"}
                                                       value={helpers.values.telIndicatif}
                                                       url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                       defaultOptions={
                                                            data.personnel?.telPersonnelIndicatif
                                                                 ? [
                                                                        {
                                                                             value: data.personnel.telPersonnelIndicatif.id as number,
                                                                             label: data.personnel.telPersonnelIndicatif.phoneCodeWithPlusPrefixAndCountry as string,
                                                                        },
                                                                   ]
                                                                 : []
                                                       }
                                                       placeholder={"Choisissez un indicatif"}
                                                       isInvalid={!!helpers.errors.telIndicatif}
                                                       onChange={val => helpers.setFieldValue("telIndicatif", val)}
                                                  />
                                                  {helpers.errors.telIndicatif && <div className={"text-danger"}>{helpers.errors.telIndicatif}</div>}
                                             </div>
                                             <div className="col-lg-6">
                                                  <MyInptuMaskField
                                                       name={"tel"}
                                                       value={helpers.values.tel}
                                                       isInvalid={!!helpers.errors.tel}
                                                       mask={"99 9999999999999999999999999"}
                                                       placeholder={"Téléphone"}
                                                       inputMode={"numeric"}
                                                       onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                                  />
                                                  {helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              {/* Numéro de sécurité sociale */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                        <span>Numéro de sécurité sociale</span>
                                   </label>

                                   <div className="col-lg-8">
                                        <MyInptuMaskField
                                             name={"nSecuriteSociale"}
                                             mask={"9 99 99 99 999 999 99"}
                                             value={helpers.values.nSecuriteSociale}
                                             placeholder={"N° de sécurité sociale"}
                                             inputMode={"numeric"}
                                             onChange={e => helpers.setFieldValue("nSecuriteSociale", e.target.value)}
                                             isInvalid={!!helpers.errors.nSecuriteSociale}
                                        />
                                        {helpers.errors.nSecuriteSociale && <div className={"text-danger"}>{helpers.errors.nSecuriteSociale}</div>}
                                   </div>
                              </div>

                              {/*RÉSIDENCE*/}
                              <div className={"pb-6"}>
                                   <h3 className={"mt-8 mb-4"}>Résidence</h3>
                                   <div className="separator separator-dashed mb-6" />

                                   {/*Adresse*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Adresse</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"domicileAdresse"}
                                                  onChange={date => helpers.setFieldValue("domicileAdresse", date)}
                                                  value={helpers.values.domicileAdresse}
                                                  isInvalid={!!helpers.errors.domicileAdresse}
                                                  placeholder={"Adresse"}
                                             />
                                             {helpers.errors.domicileAdresse && <div className={"text-danger"}>{helpers.errors.domicileAdresse}</div>}
                                        </div>
                                   </div>

                                   {/* Pays*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Pays</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"domicilePays"}
                                                  value={helpers.values.domicilePays}
                                                  url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                  defaultOptions={
                                                       data.personnel?.residencePays
                                                            ? [
                                                                   {
                                                                        value: data.personnel.residencePays.id as number,
                                                                        label: data.personnel.residencePays.nomFr as string,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  isInvalid={!!helpers.errors.domicilePays}
                                                  onChange={val => helpers.setFieldValue("domicilePays", val)}
                                             />
                                             {helpers.errors.domicilePays && <div className={"text-danger"}>{helpers.errors.domicilePays}</div>}
                                        </div>
                                   </div>

                                   {/*Ville et code postal*/}
                                   {helpers.values.domicilePays && (
                                        <div className="row">
                                             <label className="col-lg-4 col-form-label required fw-bold fs-6">Ville et code postal</label>

                                             <div className="col-lg-8">
                                                  <div className="row">
                                                       <div className="col-6">
                                                            {helpers.values.domicilePays === CONST_PAYS_ID_FRANCE ? (
                                                                 <>
                                                                      <MySelectSearchDataFromServer
                                                                           name={"domicileVilleFrance"}
                                                                           value={helpers.values.domicileVilleFrance}
                                                                           url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                           method={"GET"}
                                                                           minimumLengthSearch={2}
                                                                           placeholder={"Choisissez la ville"}
                                                                           defaultOptions={
                                                                                data.personnel?.residenceVilleFrance
                                                                                     ? [
                                                                                            {
                                                                                                 value: data.personnel.residenceVilleFrance.id as number,
                                                                                                 label: data.personnel.residenceVilleFrance.nom as string,
                                                                                            },
                                                                                       ]
                                                                                     : []
                                                                           }
                                                                           isInvalid={!!helpers.errors.domicileVilleFrance}
                                                                           onChange={val => helpers.setFieldValue("domicileVilleFrance", val)}
                                                                      />
                                                                      {helpers.errors.domicileVilleFrance && (
                                                                           <div className={"text-danger"}>{helpers.errors.domicileVilleFrance}</div>
                                                                      )}
                                                                 </>
                                                            ) : (
                                                                 <>
                                                                      <MyInputField
                                                                           name={"domicileVille"}
                                                                           value={helpers.values.domicileVille}
                                                                           isInvalid={!!helpers.errors.domicileVille}
                                                                           placeholder={"Ville"}
                                                                           onChange={val => helpers.setFieldValue("domicileVille", val)}
                                                                      />
                                                                      {helpers.errors.domicileVille && <div className={"text-danger"}>{helpers.errors.domicileVille}</div>}
                                                                 </>
                                                            )}
                                                       </div>
                                                       <div className="col-6">
                                                            <MyInputField
                                                                 name={"domicileCodePostal"}
                                                                 value={helpers.values.domicileCodePostal}
                                                                 isInvalid={!!helpers.errors.domicileCodePostal}
                                                                 placeholder={"Code postal"}
                                                                 inputMode={"numeric"}
                                                                 onChange={val => helpers.setFieldValue("domicileCodePostal", val)}
                                                            />
                                                            {helpers.errors.domicileCodePostal && <div className={"text-danger"}>{helpers.errors.domicileCodePostal}</div>}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )}
                              </div>

                              {/*PERSONNE A CONTACTER CAS URGENCE*/}
                              <div className={"pb-6"}>
                                   <h3 className={"mt-8 mb-4"}>Personne à contacter en cas d'urgence</h3>
                                   <div className="separator separator-dashed mb-6" />

                                   {/* Field Prénom & nom */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom NOM</label>

                                        <div className="col-lg-8">
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MyInputField
                                                            name={"contactUrgencePrenom"}
                                                            value={helpers.values.contactUrgencePrenom}
                                                            isInvalid={!!helpers.errors.contactUrgencePrenom}
                                                            placeholder={"Prénom"}
                                                            onChange={val => helpers.setFieldValue("contactUrgencePrenom", val)}
                                                       />
                                                       {helpers.errors.contactUrgencePrenom && <div className={"text-danger"}>{helpers.errors.contactUrgencePrenom}</div>}
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyInputField
                                                            name={"contactUrgenceNom"}
                                                            value={helpers.values.contactUrgenceNom}
                                                            isInvalid={!!helpers.errors.contactUrgencePrenom}
                                                            placeholder={"Nom"}
                                                            onChange={val => helpers.setFieldValue("contactUrgenceNom", val)}
                                                       />
                                                       {helpers.errors.contactUrgenceNom && <div className={"text-danger"}>{helpers.errors.contactUrgenceNom}</div>}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="row">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                                        <div className="col-lg-8">
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MySelectGetAllDataFromServer
                                                            name={"contactUrgenceTelIndicatif"}
                                                            value={helpers.values.contactUrgenceTelIndicatif}
                                                            url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                            defaultOptions={
                                                                 data.personnel?.personneToContactUrgenceTelIndicatif
                                                                      ? [
                                                                             {
                                                                                  value: data.personnel.personneToContactUrgenceTelIndicatif.id as number,
                                                                                  label: data.personnel.personneToContactUrgenceTelIndicatif
                                                                                       .phoneCodeWithPlusPrefixAndCountry as string,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            placeholder={"Choisissez un indicatif"}
                                                            isInvalid={!!helpers.errors.contactUrgenceTelIndicatif}
                                                            onChange={val => helpers.setFieldValue("contactUrgenceTelIndicatif", val)}
                                                       />
                                                       {helpers.errors.contactUrgenceTelIndicatif && (
                                                            <div className={"text-danger"}>{helpers.errors.contactUrgenceTelIndicatif}</div>
                                                       )}
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyInptuMaskField
                                                            name={"contactUrgenceTel"}
                                                            mask={"99 9999999999999999999999999"}
                                                            value={helpers.values.contactUrgenceTel}
                                                            isInvalid={!!helpers.errors.contactUrgenceTel}
                                                            placeholder={"Téléphone"}
                                                            onChange={e => helpers.setFieldValue("contactUrgenceTel", e.target.value)}
                                                       />
                                                       {helpers.errors.contactUrgenceTel && <div className={"text-danger"}>{helpers.errors.contactUrgenceTel}</div>}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="separator bg-primary my-5"></div>

                              {/* Submit button */}
                              <div className={"text-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? (
                                             <>
                                                  Appliquer les modifications <span className={"fas fa-arrow-right fs-4 ms-1"}></span>
                                             </>
                                        ) : (
                                             <MySimpleSpinner size={"sm"} />
                                        )}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default CompteInfoPersoEdit
