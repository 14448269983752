import React, { useState } from "react"
import { IConsultantDdcModel } from "@common-models/*"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MyTooltip from "@common-utils/MyTooltip"
import {
     CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE,
     CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE,
     CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE,
     CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM,
     CONST_DDC_SUIVI_TYPE_REJET_DEMANDE,
} from "@common-constants/*"
import MyModal from "@common-utils/MyModal"
import MyAlert from "@common-utils/MyAlert"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useConsultantDeclarationDetailsContext } from "./_ConsultantDeclarationDetails"
import { getConsultantDdcRequest } from "../../core/_requests"
import { IGetConsultantDdcRequestModel } from "../../core/_models"
import { Link } from "react-router-dom"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"
import useDateHelper from "@common-helpers/useDateHelper"
import moment from "moment/moment"

const REACT_QUERY_KEY_GET_CONSULTANT_DDC = "REACT_QUERY_KEY_GET_CONSULTANT_DDC"

export function ConsultantDeclarationDetailsDDC({ consultant_id, filtres }: { consultant_id: number; filtres: IGetConsultantDdcRequestModel }) {
     const { countWeekdaysBetweenDates } = useDateHelper()

     const context = useConsultantDeclarationDetailsContext()
     const [modalSuivi, setModalSuivi] = useState<{ isShown: boolean; ddc: IConsultantDdcModel | null }>({
          isShown: false,
          ddc: null,
     })
     const [justificatifInModal, setJustificatifInModal] = useState<IConsultantDdcModel>()

     const getDdcListingQuery = useQuery<IConsultantDdcModel[], AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_DDC, () =>
          getConsultantDdcRequest(consultant_id, filtres).then(r => r.data)
     )

     const BadgeAccordingToEtat = ({ ddc }: { ddc: IConsultantDdcModel }) => {
          return (
               <MyTooltip title={"Voir le suivi"}>
                    <div
                         className={"d-inline cursor-pointer"}
                         onClick={() =>
                              setModalSuivi({
                                   ddc: ddc,
                                   isShown: true,
                              })
                         }
                    >
                         {[CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM, CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE].includes(ddc.etat?.type as string) && (
                              <span className="badge badge-light-warning">À valider</span>
                         )}
                         {ddc.etat?.type === CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE && <span className="badge badge-light-success">Validée</span>}
                         {ddc.etat?.type === CONST_DDC_SUIVI_TYPE_REJET_DEMANDE && <span className="badge badge-light-danger">Rejetée</span>}
                         {ddc.etat?.type === CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE && <span className="badge badge-secondary">Annulée</span>}
                    </div>
               </MyTooltip>
          )
     }

     const SuiviInModal = () => {
          function faIconAccordingToTypeSuivi(type: string) {
               if (type === CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE) return "fa-paper-plane"
               if (type === CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM) return "fa-edit"
               if (type === CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE) return "fa-thumbs-up"
               if (type === CONST_DDC_SUIVI_TYPE_REJET_DEMANDE) return "fa-thumbs-down"
               if (type === CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE) return "fa-times"
               return ""
          }

          if (!modalSuivi?.ddc?.suivis) return <></>
          const suiviTotal = modalSuivi.ddc.suivis.length

          return (
               <MyModal
                    title={<span>{modalSuivi.ddc?.typeLabel}</span>}
                    show={modalSuivi.isShown}
                    handleClose={() =>
                         setModalSuivi(prev => ({
                              ...prev,
                              isShown: false,
                         }))
                    }
               >
                    <div className="timeline">
                         {modalSuivi.ddc.suivis.map((suivi, key) => (
                              <div className="timeline-item" key={key}>
                                   <div className="timeline-line w-40px"></div>
                                   <div className="timeline-icon symbol symbol-circle symbol-40px">
                                        <div className={`symbol-label text-dark`}>
                                             <span className={`fas ${faIconAccordingToTypeSuivi(suivi.type as string)} fs-4`}></span>
                                        </div>
                                   </div>
                                   <div className="timeline-content mt-n1">
                                        <div className="pe-3 mb-5">
                                             <div className="fs-5 fw-bold mb-2">{suivi.typeLabel}</div>
                                             <div className="d-flex align-items-center mt-1 fs-6">
                                                  <div className="text-dark me-2 fs-7">
                                                       Le {suivi.createdAt?.format("dddd DD MMMM YYYY à H[h]m")}{" "}
                                                       {suivi.personnel ? (
                                                            <b className={"text-center"}>par {suivi.personnel.prenomNom}</b>
                                                       ) : (
                                                            <b className={"text-dark"}>par vous</b>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>

                                        {key === suiviTotal - 1 && modalSuivi.ddc!.motif && (
                                             <div className="overflow-auto pb-5">
                                                  <p className={"m-0 mb-2 fw-bold"}>Motif</p>
                                                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-7">
                                                       {modalSuivi.ddc!.justificatifGoogleDriveID && (
                                                            <MyTooltip title={"Voir le justificatif"}>
                                                                 <div className={"fas fa-file-alt fs-2 me-2 cursor-pointer text-center"} />
                                                            </MyTooltip>
                                                       )}
                                                       <div>{modalSuivi.ddc!.motif}</div>
                                                  </div>
                                             </div>
                                        )}

                                        {suivi.commentaire && (
                                             <div className="overflow-auto pb-5">
                                                  <p className={"m-0 mb-2 fw-bold"}>Commentaire</p>
                                                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-7">
                                                       <div>{suivi.commentaire}</div>
                                                  </div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         ))}
                    </div>
               </MyModal>
          )
     }

     const NavigationFooter = () => {
          return (
               <>
                    <div className="d-flex justify-content-between">
                         <div>
                              <button type="button" className="btn btn-sm btn-light-primary d-flex align-items-center me-3" onClick={() => context.setNavigationStep("arrets")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Arrêts
                              </button>
                         </div>

                         <div className={"d-flex"}>
                              {context.isActionDemandeRectifAvailable && (
                                   <button type="button" className="btn btn-sm btn-warning d-flex align-items-center me-2" onClick={() => context.handleDemandeRectification()}>
                                        <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                        <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                   </button>
                              )}
                              {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                                   <>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-success d-flex align-items-center me-2"
                                             onClick={() => context.handleValidationDemandeRegul()}
                                        >
                                             <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                             <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                             <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                        </button>
                                   </>
                              )}
                              <button type="button" className="btn btn-sm btn-primary d-flex align-items-center " onClick={() => context.setNavigationStep("cra")}>
                                   CRA <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                              </button>
                         </div>
                    </div>
               </>
          )
     }

     return (
          <div>
               {/* loader */}
               {getDdcListingQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!getDdcListingQuery.isFetching && getDdcListingQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{getDdcListingQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => getDdcListingQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* no data found */}
               {!getDdcListingQuery.isFetching && !getDdcListingQuery.isError && getDdcListingQuery.data?.length === 0 && (
                    <div className={"text-center p-4"}>
                         <p style={{ fontWeight: 500 }}>Aucune DDC n'a été ajoutée ...</p>
                    </div>
               )}

               {/* with DDC*/}
               {!getDdcListingQuery.isFetching && !getDdcListingQuery.isError && getDdcListingQuery.data && getDdcListingQuery.data.length > 0 && (
                    <>
                         <MyAlert type={"primary"} classNames={"mb-4"}>
                              <>
                                   Les Demandes De Congés non validées le seront par défaut lors de la validation de cette déclaration. <br />
                                   Si vous voulez rejeter une demande, veuillez cliquer sur le lien ci-après:{" "}
                                   <Link to={"/dashboard/alertes/ddc?etat=ETAT_EN_COURS"} target={"_blank"}>
                                        LIEN
                                   </Link>
                              </>
                         </MyAlert>
                         <div className={"mb-4"}>
                              <div className={"row"}>
                                   {getDdcListingQuery.data.map((item, key) => {
                                        const totalDemiJournees = item.demiJournees?.length || 0
                                        const totalJourneesCompletes = countWeekdaysBetweenDates(item.du as moment.Moment, item.au as moment.Moment)
                                        const dureeAbsenceEnJours = totalJourneesCompletes - totalDemiJournees * 0.5

                                        return (
                                             <div className="col-md-6 g-5" key={key}>
                                                  <div className="d-flex align-items-center">
                                                       <div className={"w-65px text-center"}>
                                                            <BadgeAccordingToEtat ddc={item} />
                                                            {item.justificatifGoogleDriveID && (
                                                                 <MyTooltip title={"Voir le justificatif"}>
                                                                      <div className="text-center mt-4 cursor-pointer" onClick={() => setJustificatifInModal(item)}>
                                                                           <i className={"fas fa-file-alt fs-1"} />
                                                                      </div>
                                                                 </MyTooltip>
                                                            )}
                                                       </div>
                                                       <div className={"border-start ps-3 ms-2"}>
                                                            <span className={"text-primary fw-bolder d-block py-2 fs-5"}>{item.typeLabel}</span>
                                                            <span className="d-block fw-bold">
                                                                 <MyTooltip
                                                                      title={
                                                                           <>
                                                                                <span>
                                                                                     <div className={"fw-bold text-dark"}>Période</div>
                                                                                     <div>Du {GlobalHelper.firstLetterUppercase(item.du?.format("dddd D MMMM YYYY"))} </div>

                                                                                     <div>au {GlobalHelper.firstLetterUppercase(item.au?.format("dddd D MMMM YYYY"))}</div>
                                                                                     {item.demiJournees && item.demiJournees.length > 0 && (
                                                                                          <>
                                                                                               <div className={"fw-bold mt-2 text-dark"}>
                                                                                                    Dont {item.demiJournees.length} demi journée
                                                                                                    {item.demiJournees.length > 1 && "s"}
                                                                                               </div>
                                                                                               {item.demiJournees.map(dj => (
                                                                                                    <div>{GlobalHelper.firstLetterUppercase(dj.format("dddd D MMMM YYYY"))}</div>
                                                                                               ))}
                                                                                          </>
                                                                                     )}
                                                                                </span>
                                                                           </>
                                                                      }
                                                                 >
                                                                      <span className="me-1 mb-1 cursor-default">
                                                                           {item.du?.format("DD/MM/YYYY")} - {item.au?.format("DD/MM/YYYY")}
                                                                      </span>
                                                                 </MyTooltip>
                                                            </span>
                                                            <span className={"text-gray-600"}>
                                                                 Durée: {dureeAbsenceEnJours} {dureeAbsenceEnJours > 1 ? "jours" : "jour"}{" "}
                                                            </span>
                                                       </div>
                                                  </div>
                                             </div>
                                        )
                                   })}
                              </div>
                         </div>
                    </>
               )}

               <NavigationFooter />

               {modalSuivi.isShown && <SuiviInModal />}

               {/* Document in modal */}
               {justificatifInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={justificatifInModal.justificatifGoogleDriveID as string}
                         googleDriveMimetype={justificatifInModal.justificatifGoogleDriveMimeType as string}
                         modalTitle={<span>Justificatif</span>}
                         handleCloseModal={() => setJustificatifInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </div>
     )
}

export default ConsultantDeclarationDetailsDDC
