import React from "react"

interface Props {
     name: string
     value: any
     isInvalid: boolean
     rows?: number
     className?: string
     placeholder?: string
     onChange: (val) => void
     maxLength?: number
     autoFocus?: boolean
}

const MyTextareaField: React.FC<Props> = ({ name, className = "", value, onChange, placeholder, isInvalid, rows = 5, maxLength, autoFocus }) => {
     return (
          <textarea
               name={name}
               rows={rows}
               placeholder={placeholder}
               onChange={e => {
                    const minRows = rows // Minimum number of rows to start with
                    const lineBreaks = e.target.value.split("\n").length
                    const calculatedRows = Math.max(minRows, lineBreaks + 1)

                    const maxRows = 20
                    const newRows = Math.min(calculatedRows, maxRows)

                    e.target.rows = newRows
                    onChange(e.target.value)
               }}
               maxLength={maxLength}
               value={value === null || value === undefined ? "" : value}
               className={`form-control ${className} ${isInvalid ? "is-invalid" : ""}`}
               autoFocus={autoFocus}
          />
     )
}

export default MyTextareaField
