import { useAuth } from "../../../../AppContext"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { programmerEntretienMeet } from "../core/_requests"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, REGEXP_EMAIL } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MyRadioField from "@common-utils/fields/MyRadioField"
import MyInputField from "@common-utils/fields/MyInputField"
import MyDateDayMonthYearHoursMinutesField from "@common-utils/fields/MyDateDayMonthYearHoursMinutesField"
import moment from "moment/moment"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MySelectCreatableField from "@common-utils/fields/MySelectCreatableField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { ICandidatProgrammerEntretienMeetFormModel } from "./core/_models"

const CandidatProgrammerEntretienMeetForm = ({ candidat, handleSubmitCallBack }: ICandidatProgrammerEntretienMeetFormModel) => {
     const { currentUser } = useAuth()

     const schema = Yup.object().shape({
          type: Yup.string().label(`"Type"`).required(),
          reunionDate: Yup.string().label(`"Date de réunion"`).required(),
          reunionDureeEnMinutes: Yup.number().label(`"Durée la réunion en minutes"`).required(),
          doesMeetingHaveExtraParticipants: Yup.boolean().label(`"Voulez-vous ajouter d'autres participants?"`).required(),
          extraParticipantMails: Yup.array()
               .label(`"Mails des autres participants"`)
               .when("doesMeetingHaveExtraParticipants", {
                    is: true,
                    then: schema => schema.min(1),
               }),
     })

     interface IFormValuesModel {
          type: string | "TYPE_ENTRETIEN_CANDIDAT" | "TYPE_ENTRETIEN_CLIENT"
          reunionTitre: string
          reunionDate: string
          reunionDureeEnMinutes: number
          doesMeetingHaveExtraParticipants: boolean
          extraParticipantMails?: string[]
     }

     const initialValues: IFormValuesModel = {
          type: "",
          reunionTitre: "",
          reunionDate: "",
          reunionDureeEnMinutes: 60,
          doesMeetingHaveExtraParticipants: false,
     }

     function handleSubmit(values: IFormValuesModel, helpers: FormikHelpers<IFormValuesModel>) {
          helpers.setStatus(null)
          programmerEntretienMeet(
               candidat.id as number,
               values.type,
               values.reunionTitre,
               values.reunionDate,
               values.reunionDureeEnMinutes,
               values.doesMeetingHaveExtraParticipants,
               values.extraParticipantMails
          )
               .then(() => {
                    // Close modal
                    handleSubmitCallBack()

                    toast.success(
                         `L'entretien a bien été programmé et ajouté à votre agenda. Un mail sera envoyé au candidat ${
                              values.doesMeetingHaveExtraParticipants ? "et autres participants" : ""
                         }.`
                    )
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error.errors && error.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     function getTitreReunionAccordingToType(type: string) {
          if (type === "TYPE_ENTRETIEN_CANDIDAT") {
               return `[${currentUser?.mainEntite?.nom}] Entretien candidat - ${currentUser?.prenomNom} / ${candidat.prenomNom}`
          } else {
               return `[${currentUser?.mainEntite?.nom}] Entretien de qualitification - ${currentUser?.prenomNom} / ${candidat.prenomNom}`
          }
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              {/* Field type */}
                              <div className="row">
                                   <div className="col-6">
                                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                                             <span className="d-flex align-items-center me-2">
                                                  <span className="symbol symbol-50px me-6">
                                                       <span className="symbol-label bg-light-primary">
                                                            <i className={"fas fa-user fs-2 text-primary"} />
                                                       </span>
                                                  </span>
                                                  <span className="d-flex flex-column">
                                                       <span className="fw-bolder fs-6">Entretien client</span>
                                                  </span>
                                             </span>
                                             <MyRadioField
                                                  name={"type"}
                                                  value={"TYPE_ENTRETIEN_CANDIDAT"}
                                                  isChecked={helpers.values.type === "TYPE_ENTRETIEN_CANDIDAT"}
                                                  isInvalid={!!helpers.errors.type}
                                                  onChange={val => {
                                                       helpers.setFieldValue("type", val).then(() => {
                                                            helpers.setFieldValue("reunionTitre", getTitreReunionAccordingToType(val)).then()
                                                       })
                                                  }}
                                             />
                                        </label>
                                   </div>
                                   <div className="col-6">
                                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                                             <span className="d-flex align-items-center me-2">
                                                  <span className="symbol symbol-50px me-6">
                                                       <span className="symbol-label bg-light-success">
                                                            <i className={"fas fa-building fs-2 text-success"} />
                                                       </span>
                                                  </span>
                                                  <span className="d-flex flex-column">
                                                       <span className="fw-bolder fs-6">Entretien de qualification</span>
                                                  </span>
                                             </span>
                                             <MyRadioField
                                                  name={"type"}
                                                  value={"TYPE_ENTRETIEN_CLIENT"}
                                                  isChecked={helpers.values.type === "TYPE_ENTRETIEN_CLIENT"}
                                                  isInvalid={!!helpers.errors.type}
                                                  onChange={val => {
                                                       helpers.setFieldValue("type", val).then(() => {
                                                            helpers.setFieldValue("reunionTitre", getTitreReunionAccordingToType(val)).then()
                                                       })
                                                  }}
                                             />
                                        </label>
                                   </div>
                              </div>
                              {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}

                              {helpers.values.type && (
                                   <>
                                        <MyAlert type={"primary"} classNames={"mb-4"}>
                                             Le candidat recevra une notification par e-mail concernant la date de l'entretien, et un événement sera automatiquement ajouté à votre
                                             calendrier Google.
                                        </MyAlert>
                                        {/* Field reunionTitre */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre de la réunion</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"reunionTitre"}
                                                       value={helpers.values.reunionTitre}
                                                       placeholder={"Saisissez le titre de la réunion"}
                                                       isInvalid={!!helpers.errors.reunionTitre}
                                                       onChange={val => helpers.setFieldValue("reunionTitre", val)}
                                                  />
                                                  {helpers.errors.reunionTitre && <div className={"text-danger"}>{helpers.errors.reunionTitre}</div>}
                                             </div>
                                        </div>

                                        {/* Field reunionDureeEnMinutes */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de la réunion</label>

                                             <div className="col-lg-8">
                                                  <MyDateDayMonthYearHoursMinutesField
                                                       name={"reunionDate"}
                                                       value={helpers.values.reunionDate}
                                                       placeholder={"Saisissez une date"}
                                                       minDate={moment().format()}
                                                       isInvalid={!!helpers.errors.reunionDate}
                                                       onChange={val => helpers.setFieldValue("reunionDate", val)}
                                                  />
                                                  {helpers.errors.reunionDate && <div className={"text-danger"}>{helpers.errors.reunionDate}</div>}
                                             </div>
                                        </div>

                                        {/* Field reunionDureeEnMinutes */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Durée de la réunion</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"reunionDureeEnMinutes"}
                                                       value={helpers.values.reunionDureeEnMinutes}
                                                       placeholder={"Saisissez le titre de la réunion"}
                                                       inputMode={"numeric"}
                                                       isInvalid={!!helpers.errors.reunionDureeEnMinutes}
                                                       onChange={val => helpers.setFieldValue("reunionDureeEnMinutes", val)}
                                                  />
                                                  {helpers.errors.reunionDureeEnMinutes && <div className={"text-danger"}>{helpers.errors.reunionDureeEnMinutes}</div>}
                                             </div>
                                        </div>

                                        {/* Field doesMeetingHaveExtraParticipants */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous ajouter d'autres participants?</label>

                                             <div className="col-lg-8 d-flex align-items-center">
                                                  <MyCheckBoxField
                                                       name={"doesMeetingHaveExtraParticipants"}
                                                       value={helpers.values.doesMeetingHaveExtraParticipants}
                                                       isInvalid={!!helpers.errors.doesMeetingHaveExtraParticipants}
                                                       onChange={val => helpers.setFieldValue("doesMeetingHaveExtraParticipants", val)}
                                                  />
                                                  {helpers.errors.doesMeetingHaveExtraParticipants && (
                                                       <div className={"text-danger"}>{helpers.errors.doesMeetingHaveExtraParticipants}</div>
                                                  )}
                                             </div>
                                        </div>

                                        {/* Field doesMeetingHaveExtraParticipants */}
                                        {helpers.values.doesMeetingHaveExtraParticipants && (
                                             <div className="row mb-5">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Mails des autres participants</label>

                                                  <div className="col-lg-8">
                                                       <MySelectCreatableField
                                                            name={"extraParticipantMails"}
                                                            options={[]}
                                                            value={helpers.values.extraParticipantMails}
                                                            onChange={val => helpers.setFieldValue("extraParticipantMails", val)}
                                                            isInvalid={!!helpers.errors.extraParticipantMails}
                                                            isValidNewOption={val => REGEXP_EMAIL.test(val)}
                                                            placeholder={"Saisissez les @ mails"}
                                                       />
                                                       {helpers.errors.extraParticipantMails && <div className={"text-danger"}>{helpers.errors.extraParticipantMails}</div>}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Submit button */}
                                        <div className={"text-end"}>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? "Programmer" : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </>
                              )}
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default CandidatProgrammerEntretienMeetForm
