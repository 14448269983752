import React, { useState } from "react"
import { useQueryClient } from "react-query"
import MyCard from "@common-utils/MyCard"
import PersonnelMaladieCreate from "../../../declaration/maladie/create/_PersonnelMaladieCreate"
import { toast } from "react-toastify"
import MyModal from "@common-utils/MyModal"
import { IPersonnelArretMaladieModel } from "@common-models/*"
import { PersonnelMaladieListingForOne, REACT_QUERY_KEY_GET_PERSONNEL_MALADIES } from "../../../declaration/maladie/listing/listing-only-one/_PersonnelMaladieListingForOne"
import { useAuth } from "../../../../../AppContext"

const ConnectedPersonnelMaladiesListing = () => {
     const queryClient = useQueryClient()
     const { currentUser } = useAuth()
     const [addMaladieInModal, setAddMaladieInModal] = useState<boolean>(false)

     function handleCreateMaladieCallback(newItem: IPersonnelArretMaladieModel) {
          queryClient.setQueryData<IPersonnelArretMaladieModel[] | undefined>(REACT_QUERY_KEY_GET_PERSONNEL_MALADIES, (prev: IPersonnelArretMaladieModel[] | undefined) => {
               if (prev) {
                    return [newItem, ...prev]
               }

               return prev
          })
          setAddMaladieInModal(false)
          toast.success("Votre arrêt maladie a bien été enregistré.")
          toast.info(
               "Merci de vérifier, lors de l'étape de Compte Rendu d'Activité (CRA), si les heures liées à l'absence ainsi que celles de travail pour la période concernée par votre demande sont corrects.",
               {
                    autoClose: false,
               }
          )
     }

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={"w-100 w-lg-75 w-xxl-65"}>
                         <MyCard>
                              <MyCard.Header
                                   rightSideContent={
                                        <>
                                             <button type={"button"} className={`btn btn-primary btn-sm d-flex align-items-center`} onClick={() => setAddMaladieInModal(true)}>
                                                  <span className={`d-md-inline d-none`}>Nouvel arrêt</span>
                                                  <i className={"fas fa-plus-circle ms-md-2 pe-0 fs-3"} />
                                             </button>
                                        </>
                                   }
                              >
                                   Mes arrêts maladie
                              </MyCard.Header>
                              <MyCard.Body>
                                   <PersonnelMaladieListingForOne personnel_ID={currentUser?.id as number} />
                              </MyCard.Body>
                         </MyCard>
                    </div>
               </div>

               {addMaladieInModal && (
                    <MyModal show={true} handleClose={() => setAddMaladieInModal(false)} title={<>Nouvel arrêt</>} size={"lg"}>
                         <PersonnelMaladieCreate callback={handleCreateMaladieCallback} />
                    </MyModal>
               )}
          </>
     )
}

export default ConnectedPersonnelMaladiesListing
