import { useQueryClient } from "react-query"
import { useSearchResultsContext } from "./CandidatsRechercheResults"
import React from "react"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { partagerProfilParMail } from "../../core/_requests"
import { ISearchResultsDataItemResponseModel, ISearchResultsResponseModel } from "./core/_models"
import { toast } from "react-toastify"
import MyModal from "@common-utils/MyModal"
import MyAlert from "@common-utils/MyAlert"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT } from "../../../../../endpoints"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyTextareaField from "@common-utils/fields/MyTextareaField"
import { ICandidatVivierModel } from "@common-models/*"
import CandidatProgrammerEntretienMeetForm from "../../actions/_CandidatProgrammerEntretienMeetForm"
import CandidatAddToViviersForm from "../../actions/_CandidatAddToViviers"

export const AddCandidateToVivierModalForm = () => {
     const queryClient = useQueryClient()
     const globalContext = useSearchResultsContext()
     const item = globalContext.useListingQuery.data?.data.find(
          item_ => item_.candidat.id === globalContext.addingCandidateToViviers!.candidat.id
     ) as ISearchResultsDataItemResponseModel

     function handleSubmitCallBack(items: ICandidatVivierModel[]) {
          // Set list state
          queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
               [globalContext.REACT_QUERY_KEY_SEARCH_RESULTS, globalContext.filters],
               (prev: ISearchResultsResponseModel | undefined) => {
                    if (prev?.data) {
                         const index = prev.data.findIndex(item_ => item_.candidat.id === item.candidat.id)
                         prev.data[index] = {
                              ...prev.data[index],
                              connectedUserAddedCandidatToViviers: [...prev.data[index].connectedUserAddedCandidatToViviers, ...items],
                         }

                         return prev
                    }

                    return prev
               }
          )
          // Close modal
          globalContext.setAddingCandidateToViviers(null)
     }

     return (
          <MyModal title={<span>Ajout de {item.candidat?.prenomNom} à un vivier</span>} show={true} handleClose={() => globalContext.setAddingCandidateToViviers(null)} size={"lg"}>
               <CandidatAddToViviersForm
                    candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                    handleSubmitCallBack={handleSubmitCallBack}
               />
          </MyModal>
     )
}

export const ShareCandidateModalForm = () => {
     const globalContext = useSearchResultsContext()
     const candidat = globalContext.sharingCandidate!.candidat

     const schema = Yup.object().shape({
          destinataires: Yup.array().label(`"Destinataires"`).required(),
          objet: Yup.string().label(`"Objet"`),
          commentaire: Yup.string().label(`"Intitulé"`),
     })

     const initialValues: { destinataires: number[]; objet: string; commentaire: string } = {
          destinataires: [],
          objet: `Partage du candidat ${candidat.prenomNom}`,
          commentaire: "",
     }

     function handleSubmit(
          values: { destinataires: number[]; objet: string; commentaire: string },
          helpers: FormikHelpers<{ destinataires: number[]; objet: string; commentaire: string }>
     ) {
          helpers.setStatus(null)
          partagerProfilParMail(candidat.id as number, values.destinataires, values.objet, values.commentaire)
               .then(r => {
                    // Close modal
                    globalContext.setSharingCandidate(null)

                    toast.success("Le profil du candidat a bien été partagé par mail.")
               })
               .catch(() => {
                    helpers.setStatus("Erreur inattendue. veuillez réessayer.")
                    helpers.setSubmitting(false)
               })
     }

     return (
          <MyModal title={<span>Partage du profil de {candidat.prenomNom}</span>} show={true} handleClose={() => globalContext.setSharingCandidate(null)} size={"lg"}>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}
                                   {/* Field destinataires */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Destinataires</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"destinataires"}
                                                  url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                  value={helpers.values.destinataires}
                                                  onChange={val => helpers.setFieldValue("destinataires", val)}
                                                  isInvalid={!!helpers.errors.destinataires}
                                                  isMulti
                                             />
                                             {helpers.errors.destinataires && <div className={"text-danger"}>{helpers.errors.destinataires}</div>}
                                        </div>
                                   </div>

                                   {/* Field objet */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Objet</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"objet"}
                                                  value={helpers.values.objet}
                                                  placeholder={"Saisissez votre objet"}
                                                  isInvalid={!!helpers.errors.objet}
                                                  onChange={val => helpers.setFieldValue("objet", val)}
                                             />
                                             {helpers.errors.objet && <div className={"text-danger"}>{helpers.errors.objet}</div>}
                                        </div>
                                   </div>

                                   {/* Field commentaire */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Commentaire</label>

                                        <div className="col-lg-8">
                                             <MyTextareaField
                                                  name={"commentaire"}
                                                  placeholder={"Saisissez votre commentaire (optionnel)"}
                                                  value={helpers.values.commentaire}
                                                  isInvalid={!!helpers.errors.commentaire}
                                                  onChange={val => helpers.setFieldValue("commentaire", val)}
                                             />
                                             {helpers.errors.commentaire && <div className={"text-danger"}>{helpers.errors.commentaire}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? "Partager" : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </MyModal>
     )
}

export const ProgrammerEntretienMeetModalForm = () => {
     const globalContext = useSearchResultsContext()
     const item = globalContext.useListingQuery.data?.data.find(
          item_ => item_.candidat.id === globalContext.schedulingEntretienMeet!.candidat.id
     ) as ISearchResultsDataItemResponseModel

     return (
          <MyModal title={<span>Entretien Meet avec {item.candidat?.prenomNom}</span>} show={true} handleClose={() => globalContext.setSchedulingEntretienMeet(null)} size={"lg"}>
               <CandidatProgrammerEntretienMeetForm
                    candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                    handleSubmitCallBack={() => globalContext.setSchedulingEntretienMeet(null)}
               />
          </MyModal>
     )
}
