import ConsultantDdcListing from "../../../business/consultants/ddc/listing/ConsultantDdcListing"

const DashboardAlertesDdcProductionListing = () => {
     return (
          <>
               <ConsultantDdcListing title={"Alertes DDC"} isFilterIconVisible={false} isResetIconVisible={false} />
          </>
     )
}

export default DashboardAlertesDdcProductionListing
