import React, { useEffect } from "react"
import { enableAnnonceRequest } from "../core/_requests"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import MyCard from "@common-utils/MyCard"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useMutation } from "react-query"

const AnnonceActivation = () => {
     const navigate = useNavigate()

     const annonce_id = new URLSearchParams(window.location.search).get("idOfOfferThatMustBeEnabled")

     const enableAnnonceMutation = useMutation((id: number) => enableAnnonceRequest(id))

     useEffect(() => {
          if (annonce_id) {
               toast.info("Finalisation en cours, veuillez patienter ...", { autoClose: false })

               const id = parseInt(annonce_id)
               enableAnnonceMutation
                    .mutateAsync(id)
                    .then(() => {
                         toast.dismiss()
                         toast.success("Votre annonce a bien été ajoutée à la liste. Elle sera publiée dans les plus brefs délais.", { autoClose: false })
                         setTimeout(() => {
                              navigate("/recrutement/annonces/listing")
                         }, 2000)
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
          }
     }, [])

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-65"}>
                    <MyCard>
                         <MyCard.Body>
                              {!annonce_id ? (
                                   <MyAlert type={"danger"}>URL incorrect. Veuillez contacter votre administrateur.</MyAlert>
                              ) : (
                                   <>
                                        {enableAnnonceMutation.isLoading && (
                                             <div className="text-center">
                                                  <MySimpleSpinner size={"xl"} />
                                             </div>
                                        )}

                                        {enableAnnonceMutation.isSuccess && <MyAlert type={"success"}>Votre offre a bien été ajoutée.</MyAlert>}

                                        {enableAnnonceMutation.isError && <MyAlert type={"danger"}>Impossible d'activer l'annonce</MyAlert>}
                                   </>
                              )}
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default AnnonceActivation
