import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { LoginWrapper } from "../pages/auth/login/Login"
import ForgotPassword from "../pages/auth/login/ForgotPassword"
import { ResetPassword } from "../pages/auth/login/ResetPassword"

const PublicRoutes = () => {
     const location = useLocation()

     // location.pathname does not contain auth in the string
     if (!location.pathname.includes("auth")) localStorage.setItem("prevUrl", location.pathname + location.search)

     return (
          <Routes>
               <Route path="auth/*" element={<LoginWrapper />} />
               <Route path="auth/forgot-password" element={<ForgotPassword />} />
               <Route path="auth/reset-password" element={<ResetPassword />} />
               <Route path="*" element={<Navigate to={`/auth`} replace />} />
          </Routes>
     )
}

export default PublicRoutes
