import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getDemandeEmbaucheInfoContract } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { IGetDemandeEmbaucheInfoContractResponseModel } from "./core/_models"
import DemandeEmbaucheDetailsInfoContractSalarie from "./_DemandeEmbaucheDetailsInfoContractSalarie"
import DemandeEmbaucheDetailsInfoContractNonSalarie from "./_DemandeEmbaucheDetailsInfoContractNonSalarie"

const REACT_QUERY_KEY_GET_DE_INFO_CONTRACT_QUERY = "REACT_QUERY_KEY_GET_DE_INFO_CONTRACT_QUERY"
const DemandeEmbaucheDetailsInfoContract = () => {
     const consultant_ID = parseInt(useParams<{ consultant_id: string }>().consultant_id as string)

     const infoContractQuery = useQuery<IGetDemandeEmbaucheInfoContractResponseModel, AxiosError>(REACT_QUERY_KEY_GET_DE_INFO_CONTRACT_QUERY, () => {
          return getDemandeEmbaucheInfoContract(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Is fetching
     if (infoContractQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />
               </div>
          )
     }

     // Error
     if (!infoContractQuery.isFetching && infoContractQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{infoContractQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => infoContractQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     if (!infoContractQuery.isFetching && infoContractQuery.data) {
          // Render form salarié
          if (infoContractQuery.data.salarie) {
               return <DemandeEmbaucheDetailsInfoContractSalarie data={infoContractQuery.data} />
          } else {
               // render form non salarié
               return <DemandeEmbaucheDetailsInfoContractNonSalarie data={infoContractQuery.data} />
          }
     }

     return <></>
}

export default DemandeEmbaucheDetailsInfoContract
