import axios from "axios"
import { CONST_API_PERSONNEL_COMPTE_GET_INFO_PERSO_ENDPOINT, CONST_API_PERSONNEL_COMPTE_POST_SET_INFO_PERSO_ENDPOINT } from "../../../../endpoints"
import { IPersonnelCompteModel } from "@common-models/*"
import { ISetInfoPersoPersonnelCompteRequestModel } from "./_models"

export function getInfoPersoPersonnelCompteRequest() {
     return axios.get<IPersonnelCompteModel>(CONST_API_PERSONNEL_COMPTE_GET_INFO_PERSO_ENDPOINT)
}

export function setInfoPersoPersonnelCompteRequest(payload: ISetInfoPersoPersonnelCompteRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key !== "avatar") formData.append(`data[${key}]`, value)
          if (key === "avatar") formData.append(`avatar`, value)
     }

     return axios.post<IPersonnelCompteModel>(CONST_API_PERSONNEL_COMPTE_POST_SET_INFO_PERSO_ENDPOINT, formData)
}
