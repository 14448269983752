import React from "react"
import MyCard from "@common-utils/MyCard"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { useConsultantsSuivisListingContext } from "./ConsultantsSuivisListing"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { IConsultantsSuivisListingRequestModel } from "./core/_models"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT, CONST_API_UTILS_GET_CONSULTANT_ALL_SUIVIS_ENDPOINT } from "../../../../../endpoints"

// Filters
const ConsultantsSuivisListingFilters = () => {
     const context = useConsultantsSuivisListingContext()

     const initialValues: IConsultantsSuivisListingRequestModel = {}

     function handleSubmit(values: IConsultantsSuivisListingRequestModel) {
          context.setFilters(prev => ({
               ...prev,
               ...values,
          }))
          context.setAreFiltersVisible(false)
     }

     function handleReset() {
          context.filtersFormikRef.current.resetForm() // Reset local form
          context.simpleSearchBarFormikRef.current.resetForm() // Reset search bar form
          context.setAreFiltersVisible(false)

          // Reset payload filters
          context.setFilters({
               page: 1,
               pageLength: 20,
          })

          context.filtersFormikRef.current.setSubmitting(true)
     }

     function handleClickHideFilters() {
          // Hide filters
          context.setAreFiltersVisible(prev => !prev)
     }

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <MyTooltip title={"Cacher les filters"}>
                              <button className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={handleClickHideFilters}>
                                   <i className={"fas fa-eye-slash fs-4 text-dark"} />
                              </button>
                         </MyTooltip>
                    }
               >
                    Filtres
               </MyCard.Header>

               <MyCard.Body>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={context.filtersFormikRef}>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mot clé</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"keyword"}
                                                       value={helpers.values.keyword}
                                                       onChange={val => {
                                                            helpers.setFieldValue("keyword", val).then()
                                                            context.simpleSearchBarFormikRef.current.setFieldValue("keyword", val).then()
                                                       }}
                                                       isInvalid={!!helpers.errors.keyword}
                                                       placeholder={"Rechercher ..."}
                                                  />
                                                  {helpers.errors.keyword && <div className={"text-danger"}>{helpers.errors.keyword}</div>}
                                             </div>
                                        </div>

                                        <div className="separator separator-dotted mt-4 mb-8"></div>

                                        {/* Date suivi*/}
                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Date suivi</label>

                                             <div className="col-lg-8">
                                                  <div className="d-flex">
                                                       <MyDateDayMonthYearField
                                                            name={"dateSuiviDebut"}
                                                            value={helpers.values.dateSuiviDebut}
                                                            placeholder={"Du"}
                                                            onChange={val => helpers.setFieldValue("dateSuiviDebut", val)}
                                                            isInvalid={!!helpers.errors.dateSuiviDebut}
                                                            maxDate={helpers.values.dateSuiviFin}
                                                            classNames={"rounded-0 rounded-start"}
                                                       />
                                                       <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                       <MyDateDayMonthYearField
                                                            name={"dateSuiviFin"}
                                                            value={helpers.values.dateSuiviFin}
                                                            placeholder={"Au"}
                                                            onChange={val => helpers.setFieldValue("dateSuiviFin", val)}
                                                            isInvalid={!!helpers.errors.dateSuiviFin}
                                                            minDate={helpers.values.dateSuiviDebut}
                                                            classNames={"rounded-0 rounded-end"}
                                                       />
                                                  </div>
                                                  {helpers.errors.dateSuiviDebut && <div className={"text-danger"}>{helpers.errors.dateSuiviDebut}</div>}
                                                  {helpers.errors.dateSuiviFin && <div className={"text-danger"}>{helpers.errors.dateSuiviFin}</div>}
                                             </div>
                                        </div>

                                        {/* Date saisie*/}
                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Date saisie</label>

                                             <div className="col-lg-8">
                                                  <div className="d-flex">
                                                       <MyDateDayMonthYearField
                                                            name={"dateSaisiDebut"}
                                                            value={helpers.values.dateSaisiDebut}
                                                            placeholder={"Du"}
                                                            onChange={val => helpers.setFieldValue("dateSaisiDebut", val)}
                                                            isInvalid={!!helpers.errors.dateSaisiDebut}
                                                            maxDate={helpers.values.dateSaisiFin}
                                                            classNames={"rounded-0 rounded-start"}
                                                       />
                                                       <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                       <MyDateDayMonthYearField
                                                            name={"dateSaisiFin"}
                                                            value={helpers.values.dateSaisiFin}
                                                            placeholder={"Au"}
                                                            onChange={val => helpers.setFieldValue("dateSaisiFin", val)}
                                                            isInvalid={!!helpers.errors.dateSaisiFin}
                                                            minDate={helpers.values.dateSaisiDebut}
                                                            classNames={"rounded-0 rounded-end"}
                                                       />
                                                  </div>
                                                  {helpers.errors.dateSuiviDebut && <div className={"text-danger"}>{helpers.errors.dateSuiviDebut}</div>}
                                                  {helpers.errors.dateSuiviFin && <div className={"text-danger"}>{helpers.errors.dateSuiviFin}</div>}
                                             </div>
                                        </div>

                                        {/* Responsables */}
                                        <div className="row mb-4">
                                             <label className={`col-lg-4 col-form-label fw-bold fs-6`}>Responsables</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"responsables"}
                                                       url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                       value={helpers.values.responsables}
                                                       onChange={val => helpers.setFieldValue("responsables", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.responsables}
                                                       isMulti
                                                  />
                                                  {helpers.errors.responsables && <div className={"text-danger"}>{helpers.errors.responsables}</div>}
                                             </div>
                                        </div>

                                        {/* Types */}
                                        <div className="row mb-4">
                                             <label className={`col-lg-4 col-form-label fw-bold fs-6`}>Types</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"types"}
                                                       url={CONST_API_UTILS_GET_CONSULTANT_ALL_SUIVIS_ENDPOINT}
                                                       value={helpers.values.types}
                                                       onChange={val => helpers.setFieldValue("types", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.types}
                                                       isMulti
                                                  />
                                                  {helpers.errors.types && <div className={"text-danger"}>{helpers.errors.types}</div>}
                                             </div>
                                        </div>

                                        <div className={"text-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  disabled={helpers.isSubmitting}
                                                  onClick={() => handleReset()}
                                             >
                                                  <span className={"d-none d-lg-block"}>Réinitialiser</span> <i className={"fas fa-sync ms-lg-1"} />
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  <span className={"d-none d-lg-block"}>Filtrer </span>
                                                  {!helpers.isSubmitting ? (
                                                       <i className={"fas fa-filter ms-md-1"} />
                                                  ) : (
                                                       <span className="spinner-border spinner-border-sm align-middle ms-lg-1" />
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyCard.Body>
          </MyCard>
     )
}

export default ConsultantsSuivisListingFilters
