import * as Yup from "yup"
import MyModal from "@common-utils/MyModal"
import { ErrorMessage, Form, Formik } from "formik"
import { usePersonnelCraContext } from "./_PersonnelCra"
import MyAlert from "@common-utils/MyAlert"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { IPersonnelCraModel } from "@common-models/*"
import React from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useAuth } from "../../../../AppContext"
import { autoCompletePersonnelDeclarationCraHeuresTravail } from "../core/_requests"
import { CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPES_OPTIONS_FOR_SELECT2 } from "@common-constants/*"

const PersonnelCraAutocompleteHeuresTravailFormInModal = () => {
     const context = usePersonnelCraContext()
     const { currentUser } = useAuth()
     const queryClient = useQueryClient()

     const schema = Yup.object().shape({
          heures: Yup.number().required("Ce champ est requis"),
     })

     if (!context.autocompleteHeuresActivite) return <></>

     const typeLabel = CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPES_OPTIONS_FOR_SELECT2.find(option => option.value === context.autocompleteHeuresActivite)?.label

     return (
          <MyModal title={<span>Auto-complétion de la ligne "{typeLabel}"</span>} show={true} handleClose={() => context.setAutocompleteHeuresActivite(undefined)}>
               <>
                    <MyAlert type={"primary"} classNames={"mb-4"}>
                         Après validation, la ligne "{typeLabel}" sera automatiquement remplie.
                    </MyAlert>
                    <Formik
                         initialValues={{
                              heures: GlobalHelper.sumTwoNumbers(currentUser?.heuresTravail, currentUser?.heuresSuppTravail),
                         }}
                         onSubmit={(values, { setStatus, setSubmitting }) => {
                              setStatus(null)
                              autoCompletePersonnelDeclarationCraHeuresTravail(context.data.id as number, {
                                   heures: values.heures,
                                   type: context.autocompleteHeuresActivite,
                              })
                                   .then(r => {
                                        queryClient.setQueryData<IPersonnelCraModel | undefined>(context.REACT_QUERY_KEY_GET_ACTIVE_CRA, () => {
                                             return r.data
                                        })
                                        setSubmitting(false)
                                        context.setAutocompleteHeuresActivite(undefined)
                                   })
                                   .catch(e => {
                                        const error = e.response?.data?.detail
                                        toast.error(error, { autoClose: false })
                                        setSubmitting(false)
                                   })
                         }}
                         validationSchema={schema}
                    >
                         {({ values, setFieldValue, isSubmitting, status }) => {
                              return (
                                   <Form noValidate autoComplete={"off"}>
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}

                                        <div>
                                             <label className={"form-label"}>Heures</label>
                                             <input
                                                  type="text"
                                                  autoComplete={"off"}
                                                  className={"form-control input-sm"}
                                                  name={"heures"}
                                                  value={values.heures}
                                                  inputMode={"numeric"}
                                                  onChange={e => setFieldValue("heures", e.target.value.replace(",", "."))}
                                                  ref={input => input && input.focus({ preventScroll: true })}
                                                  onFocus={e => e.target.select()}
                                             />
                                             <ErrorMessage name={`heures`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                        </div>

                                        {/* Submit button */}
                                        <div className={"d-flex justify-content-end mt-4"}>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={isSubmitting}>
                                                  {!isSubmitting ? <>Confirmer</> : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </>
          </MyModal>
     )
}

export default PersonnelCraAutocompleteHeuresTravailFormInModal
