import React from "react"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { ICandidatModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { getDetails, setDetails } from "../../../../core/_requests"
import { useSearchResultsContext } from "../../CandidatsRechercheResults"
import MyAlert from "@common-utils/MyAlert"
import { useSearchResultsPreviewContext } from "../_CandidatsRechercheResultsPreview"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import * as Yup from "yup"
import {
     CONST_CANDIDAT_EXPERIENCES_OPTIONS_FOR_SELECT2,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_DISPONIBILITE_DATE_EXACTE,
     CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
     CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2,
     CONST_PAYS_ID_FRANCE,
} from "@common-constants/*"
import { ICandidatRechcherResultsPreviewHelpersDetailsFormikModel, ICandidatRechcherResultsPreviewHelpersDetailsRequestModel } from "./core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import MySelectField from "@common-utils/fields/MySelectField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import {
     CONST_API_UTILS_GET_DEPARTEMENTS_URL_ENDPOINT,
     CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT,
     CONST_API_UTILS_GET_METIERS_URL_ENDPOINT,
     CONST_API_UTILS_GET_PAYS_ENDPOINT,
     CONST_API_UTILS_GET_REGIONS_URL_ENDPOINT,
     CONST_API_UTILS_GET_VILLES_URL_ENDPOINT,
} from "../../../../../../../endpoints"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import MyInputField from "@common-utils/fields/MyInputField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"

const REACT_QUERY_KEY_GET_CANDIDAT_DETAILS_QUERY = "REACT_QUERY_KEY_GET_CANDIDAT_DETAILS_QUERY"

export const CandidatsRechercheResultsPreviewHelpersDetails = ({ handleSubmitCallBack }: { handleSubmitCallBack: (modifiedCandidat: ICandidatModel) => void }) => {
     const globalContext = useSearchResultsContext()
     const previewContext = useSearchResultsPreviewContext()

     const detailsQuery = useQuery<ICandidatModel, AxiosError>([REACT_QUERY_KEY_GET_CANDIDAT_DETAILS_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id], () => {
          return getDetails(globalContext.itemInPreview?.selectedItem.candidat.id as number)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
               .finally(() => {
                    // Stop loading preview
                    previewContext.setIsPreviewBodyLoading(false)
                    globalContext.setItemInPreview(prev =>
                         prev
                              ? {
                                     ...prev,
                                     isSelectedItemLoading: false,
                                }
                              : null
                    )
               })
     })

     // Schema
     const schema = Yup.object().shape({
          civilite: Yup.string().nullable().label(`"Civilité"`).required(),
          nom: Yup.string().nullable().label(`"Nom"`).required(),
          prenom: Yup.string().nullable().label(`"Prénom"`).required(),
          dateNaissance: Yup.string().nullable().label(`"Date de naissance"`),
          metiers: Yup.array().nullable().label(`"Métiers"`),
          experience: Yup.string().nullable().label(`"Experience"`),
          niveauEtudes: Yup.string().nullable().label(`"Niveau d'études"`),
          nationalite: Yup.number().nullable().label(`"Nationalité"`),
          disponibilite: Yup.string().nullable().label(`"Disponibilité"`),
          disponibiliteDate: Yup.string()
               .nullable()
               .when("disponibilite", {
                    is: CONST_DISPONIBILITE_DATE_EXACTE,
                    then: schema => schema.required(),
               })
               .label(`"Date de disponibilité"`),
          mobilite: Yup.string().nullable().label(`"Mobilité"`),
          mobiliteRegions: Yup.array()
               .nullable()
               .when("mobilite", {
                    is: CONST_MOBILITE_REGIONAL,
                    then: schema => schema.required(),
               })
               .label(`"Régions"`),
          mobiliteDepartements: Yup.array()
               .nullable()
               .when("mobilite", {
                    is: CONST_MOBILITE_DEPARTEMENTAL,
                    then: schema => schema.required(),
               })
               .label(`"Départements"`),
          mobiliteVilles: Yup.array()
               .nullable()
               .when("mobilite", {
                    is: CONST_MOBILITE_PAR_VILLE,
                    then: schema => schema.required(),
               })
               .label(`"Villes"`),
          salaireNetMensuelSouhaite: Yup.number().nullable().label(`"Salaire Net mensuel souhaité"`),
          tel: Yup.string().nullable().label(`"N° de téléphone"`),
          telIndicatif: Yup.number().nullable().label(`"Indicatif téléphonique"`),
          email: Yup.string().email().nullable().label(`"Email"`).required(),
          adresseResidence: Yup.string().nullable().label(`"Adresse postale"`),
          villeResidence: Yup.string().nullable().label(`"Ville"`),
          villeFranceResidence: Yup.number().nullable().label(`"Ville"`),
          codePostalResidence: Yup.number().nullable().label(`"Code postal"`),
          paysResidence: Yup.number().nullable().label(`"Pays"`),
          habilitable: Yup.string().nullable().label(`"Est-il habilitable?"`),
          isDejaEuUneHabilitation: Yup.boolean()
               .nullable()
               .when("habilitable", {
                    is: "OUI",
                    then: schema => schema.required(),
               })
               .label(`"A-il déjà eu une habilitation"`),
          dateFinHabilitation: Yup.string()
               .nullable()
               .when("isDejaEuUneHabilitation", {
                    is: true,
                    then: schema => schema.required(),
               })
               .label(`"Date de fin d'habilitation"`),
     })

     // Init values
     const initialValues: ICandidatRechcherResultsPreviewHelpersDetailsFormikModel = {
          civilite: detailsQuery.data?.civilite,
          nom: detailsQuery.data?.nom,
          prenom: detailsQuery.data?.prenom,
          dateNaissance: detailsQuery.data?.dateNaissance?.format("YYYY-MM-DD"),
          metiers: detailsQuery.data?.candidatMetiers?.map(item => item.metier?.id as number),
          experience: detailsQuery.data?.experience,
          niveauEtudes: detailsQuery.data?.niveauEtudes,
          nationalite: detailsQuery.data?.nationalite?.id,
          disponibilite: detailsQuery.data?.disponibilite,
          disponibiliteDate: detailsQuery.data?.disponibiliteDate?.format("YYYY-MM-DD"),
          mobilite: detailsQuery.data?.mobilite,
          mobiliteRegions: detailsQuery.data?.candidatMobiliteRegions?.map(item => item.region?.id as number),
          mobiliteDepartements: detailsQuery.data?.candidatMobiliteDepartements?.map(item => item.departement?.id as number),
          mobiliteVilles: detailsQuery.data?.candidatMobiliteVilles?.map(item => item.ville?.id as number),
          salaireNetMensuelSouhaite: detailsQuery.data?.salaireNetSouhaite,
          tel: detailsQuery.data?.tel,
          telIndicatif: detailsQuery.data?.telIndicatif?.id,
          email: detailsQuery.data?.email,
          adresseResidence: detailsQuery.data?.adresse,
          villeResidence: detailsQuery.data?.ville,
          villeFranceResidence: detailsQuery.data?.villeFr?.id,
          codePostalResidence: detailsQuery.data?.codePostal,
          paysResidence: detailsQuery.data?.pays?.id,
          habilitable: (() => {
               if (detailsQuery.data?.isHabilitableDefense === true) return "OUI"
               if (detailsQuery.data?.isHabilitableDefense === false) return "NON"
               return "UNKNOWN"
          })(),
          isDejaEuUneHabilitation: !!detailsQuery.data?.isDejaHabiliteDefense,
          dateFinHabilitation: detailsQuery.data?.dateFinHabilitationDefense?.format("YYYY-MM-DD"),
     }

     // Handle submit
     function handleSubmit(values: ICandidatRechcherResultsPreviewHelpersDetailsFormikModel, helpers: FormikHelpers<ICandidatRechcherResultsPreviewHelpersDetailsFormikModel>) {
          helpers.setStatus(null)

          const requestModel: ICandidatRechcherResultsPreviewHelpersDetailsRequestModel = {
               ...values,
          }

          setDetails(globalContext.itemInPreview?.selectedItem.candidat.id as number, requestModel)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    handleSubmitCallBack(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <div className={"preview-height p-5"}>
               {!detailsQuery.isFetching && detailsQuery.error && <MyAlert type={"danger"}>{detailsQuery.error.response?.data.detail}</MyAlert>}
               {!detailsQuery.isFetching && detailsQuery.data && (
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        {helpers.status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {helpers.status}
                                             </MyAlert>
                                        )}
                                        <h4 className={"text-primary mb-4"}>Informations de base</h4>
                                        {/* Civilité */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"civilite"}
                                                       options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                       value={helpers.values.civilite}
                                                       onChange={val => helpers.setFieldValue("civilite", val)}
                                                       isInvalid={!!helpers.errors.civilite}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}</div>
                                             </div>
                                        </div>

                                        {/* Nom */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"nom"}
                                                       value={helpers.values.nom}
                                                       onChange={val => helpers.setFieldValue("nom", val)}
                                                       placeholder={"Nom"}
                                                       isInvalid={!!helpers.errors.nom}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}</div>
                                             </div>
                                        </div>

                                        {/* Prénom */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"prenom"}
                                                       value={helpers.values.prenom}
                                                       onChange={val => helpers.setFieldValue("prenom", val)}
                                                       placeholder={"Prénom"}
                                                       isInvalid={!!helpers.errors.prenom}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}</div>
                                             </div>
                                        </div>

                                        {/* Date naissance */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Date de naissance</label>

                                             <div className="col-lg-8">
                                                  <MyDateDayMonthYearField
                                                       name={"dateNaissance"}
                                                       value={helpers.values.dateNaissance}
                                                       onChange={val => helpers.setFieldValue("dateNaissance", val)}
                                                       placeholder={"Date de naissance"}
                                                       isInvalid={!!helpers.errors.dateNaissance}
                                                  />
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.dateNaissance && <div className={"text-danger"}>{helpers.errors.dateNaissance}</div>}
                                                  </div>
                                             </div>
                                        </div>

                                        {/* Métier */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Métiers</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"metiers"}
                                                       url={CONST_API_UTILS_GET_METIERS_URL_ENDPOINT}
                                                       value={helpers.values.metiers}
                                                       onChange={val => helpers.setFieldValue("metiers", val)}
                                                       defaultOptions={(
                                                            (detailsQuery.data.candidatMetiers &&
                                                                 detailsQuery.data.candidatMetiers.length > 0 &&
                                                                 detailsQuery.data.candidatMetiers) ||
                                                            []
                                                       ).map(item => ({
                                                            label: item.metier?.nom as string,
                                                            value: item.metier?.id as number,
                                                       }))}
                                                       isInvalid={!!helpers.errors.metiers}
                                                       isMulti
                                                  />
                                                  {helpers.errors.metiers && <div className={"text-danger"}>{helpers.errors.metiers}</div>}
                                             </div>
                                        </div>

                                        {/* Experience */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Experience</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"experience"}
                                                       options={CONST_CANDIDAT_EXPERIENCES_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.experience}
                                                       onChange={val => helpers.setFieldValue("experience", val)}
                                                       isInvalid={!!helpers.errors.experience}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.experience && <div className={"text-danger"}>{helpers.errors.experience}</div>}</div>
                                             </div>
                                        </div>

                                        {/* Niveau d'études */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Niveau d'études</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"niveauEtudes"}
                                                       options={CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.niveauEtudes}
                                                       onChange={val => helpers.setFieldValue("niveauEtudes", val)}
                                                       isInvalid={!!helpers.errors.niveauEtudes}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.niveauEtudes && <div className={"text-danger"}>{helpers.errors.niveauEtudes}</div>}</div>
                                             </div>
                                        </div>

                                        <h4 className={"mt-10  mb-4 text-primary"}>DMS</h4>

                                        {/* Dispo */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Disponibilité</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"disponibilite"}
                                                       options={CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.disponibilite}
                                                       onChange={val => helpers.setFieldValue("disponibilite", val)}
                                                       isInvalid={!!helpers.errors.disponibilite}
                                                       isSearchable={false}
                                                  />
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.disponibilite && <div className={"text-danger"}>{helpers.errors.disponibilite}</div>}
                                                  </div>

                                                  {helpers.values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && (
                                                       <>
                                                            <MyDateDayMonthYearField
                                                                 name={"disponibiliteDate"}
                                                                 value={helpers.values.disponibiliteDate}
                                                                 placeholder={"Choisissez votre date"}
                                                                 onChange={val => helpers.setFieldValue("disponibiliteDate", val)}
                                                                 isInvalid={!!helpers.errors.disponibiliteDate}
                                                            />
                                                            {helpers.errors.disponibiliteDate && <div className={"text-danger"}>{helpers.errors.disponibiliteDate}</div>}
                                                       </>
                                                  )}
                                             </div>
                                        </div>

                                        {/* Mobilité */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mobilité</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"mobilite"}
                                                       options={CONST_MOBILITE_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.mobilite}
                                                       isInvalid={!!helpers.errors.mobilite}
                                                       onChange={val => helpers.setFieldValue("mobilite", val)}
                                                       isSearchable={false}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.mobilite && <div className={"text-danger"}>{helpers.errors.mobilite}</div>}</div>

                                                  {helpers.values.mobilite === CONST_MOBILITE_DEPARTEMENTAL && (
                                                       <>
                                                            <MySelectSearchDataFromServer
                                                                 name={"mobiliteDepartements"}
                                                                 value={helpers.values.mobiliteDepartements}
                                                                 onChange={val => helpers.setFieldValue("mobiliteDepartements", val)}
                                                                 defaultOptions={(
                                                                      (detailsQuery.data.candidatMobiliteDepartements &&
                                                                           detailsQuery.data.candidatMobiliteDepartements.length > 0 &&
                                                                           detailsQuery.data.candidatMobiliteDepartements) ||
                                                                      []
                                                                 ).map(item => ({
                                                                      label: item.departement?.nom as string,
                                                                      value: item.departement?.id as number,
                                                                 }))}
                                                                 url={CONST_API_UTILS_GET_DEPARTEMENTS_URL_ENDPOINT}
                                                                 isInvalid={!!helpers.errors.mobiliteDepartements}
                                                                 method={"GET"}
                                                                 minimumLengthSearch={3}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.mobiliteDepartements && <div className={"text-danger"}>{helpers.errors.mobiliteDepartements}</div>}
                                                       </>
                                                  )}

                                                  {helpers.values.mobilite === CONST_MOBILITE_REGIONAL && (
                                                       <>
                                                            <MySelectGetAllDataFromServer
                                                                 name={"mobiliteRegions"}
                                                                 value={helpers.values.mobiliteRegions}
                                                                 defaultOptions={(
                                                                      (detailsQuery.data.candidatMobiliteRegions &&
                                                                           detailsQuery.data.candidatMobiliteRegions.length > 0 &&
                                                                           detailsQuery.data.candidatMobiliteRegions) ||
                                                                      []
                                                                 ).map(item => ({
                                                                      label: item.region?.nom as string,
                                                                      value: item.region?.id as number,
                                                                 }))}
                                                                 onChange={val => helpers.setFieldValue("mobiliteRegions", val)}
                                                                 isInvalid={!!helpers.errors.mobiliteRegions}
                                                                 url={CONST_API_UTILS_GET_REGIONS_URL_ENDPOINT}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.mobiliteRegions && <div className={"text-danger"}>{helpers.errors.mobiliteRegions}</div>}
                                                       </>
                                                  )}
                                                  {helpers.values.mobilite === CONST_MOBILITE_PAR_VILLE && (
                                                       <>
                                                            <MySelectSearchDataFromServer
                                                                 name={"mobiliteVilles"}
                                                                 value={helpers.values.mobiliteVilles}
                                                                 onChange={val => helpers.setFieldValue("mobiliteVilles", val)}
                                                                 defaultOptions={(
                                                                      (detailsQuery.data.candidatMobiliteVilles &&
                                                                           detailsQuery.data.candidatMobiliteVilles.length > 0 &&
                                                                           detailsQuery.data.candidatMobiliteVilles) ||
                                                                      []
                                                                 ).map(item => ({
                                                                      label: item.ville?.nom as string,
                                                                      value: item.ville?.id as number,
                                                                 }))}
                                                                 url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                 isInvalid={!!helpers.errors.mobiliteVilles}
                                                                 method={"GET"}
                                                                 minimumLengthSearch={3}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.mobiliteVilles && <div className={"text-danger"}>{helpers.errors.mobiliteVilles}</div>}
                                                       </>
                                                  )}
                                             </div>
                                        </div>

                                        {/* Salaire */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Salaire net mensuel souhaité</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"salaireNetMensuelSouhaite"}
                                                       value={helpers.values.salaireNetMensuelSouhaite}
                                                       onChange={val => helpers.setFieldValue("salaireNetMensuelSouhaite", !GlobalHelper.isNumeric(val) ? "" : parseInt(val))}
                                                       placeholder={"Salaire net souhaité"}
                                                       isInvalid={!!helpers.errors.salaireNetMensuelSouhaite}
                                                       inputMode={"numeric"}
                                                  />
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.salaireNetMensuelSouhaite && <div className={"text-danger"}>{helpers.errors.salaireNetMensuelSouhaite}</div>}
                                                  </div>
                                             </div>
                                        </div>

                                        <h4 className={"mt-10  mb-4 text-primary"}>Coordonnées</h4>

                                        {/* Niveau d'études */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Indicatif tél.</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"telIndicatif"}
                                                       url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                       defaultOptions={
                                                            detailsQuery?.data && detailsQuery?.data.telIndicatif
                                                                 ? [
                                                                        {
                                                                             value: detailsQuery?.data.telIndicatif.id as number,
                                                                             label: detailsQuery?.data.telIndicatif.phoneCodeWithPlusPrefixAndCountry as string,
                                                                        },
                                                                   ]
                                                                 : []
                                                       }
                                                       value={helpers.values.telIndicatif}
                                                       onChange={val => helpers.setFieldValue("telIndicatif", val)}
                                                       isInvalid={!!helpers.errors.telIndicatif}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.telIndicatif && <div className={"text-danger"}>{helpers.errors.telIndicatif}</div>}</div>
                                             </div>
                                        </div>

                                        {/* Tél */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Téléphone</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"tel"}
                                                       value={helpers.values.tel}
                                                       onChange={val => helpers.setFieldValue("tel", val)}
                                                       placeholder={"Téléphone"}
                                                       isInvalid={!!helpers.errors.tel}
                                                       inputMode={"numeric"}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}</div>
                                             </div>
                                        </div>

                                        {/* Email */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"email"}
                                                       value={helpers.values.email}
                                                       onChange={val => helpers.setFieldValue("email", val)}
                                                       placeholder={"Email"}
                                                       isInvalid={!!helpers.errors.email}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}</div>
                                             </div>
                                        </div>

                                        {/* Adresse postale */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Adresse postale</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"adresseResidence"}
                                                       value={helpers.values.adresseResidence}
                                                       onChange={val => helpers.setFieldValue("adresseResidence", val)}
                                                       placeholder={"Adresse de résidence"}
                                                       isInvalid={!!helpers.errors.adresseResidence}
                                                  />
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.adresseResidence && <div className={"text-danger"}>{helpers.errors.adresseResidence}</div>}
                                                  </div>
                                             </div>
                                        </div>

                                        {/* Pays residence */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Pays</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"paysResidence"}
                                                       url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                       defaultOptions={
                                                            detailsQuery?.data && detailsQuery?.data.pays
                                                                 ? [
                                                                        {
                                                                             value: detailsQuery?.data.pays.id as number,
                                                                             label: detailsQuery?.data.pays.nomFr as string,
                                                                        },
                                                                   ]
                                                                 : []
                                                       }
                                                       value={helpers.values.paysResidence}
                                                       onChange={val => helpers.setFieldValue("paysResidence", val)}
                                                       isInvalid={!!helpers.errors.paysResidence}
                                                  />
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.paysResidence && <div className={"text-danger"}>{helpers.errors.paysResidence}</div>}
                                                  </div>
                                             </div>
                                        </div>

                                        {helpers.values.paysResidence && (
                                             <>
                                                  {helpers.values.paysResidence === CONST_PAYS_ID_FRANCE ? (
                                                       <div className="row mb-5">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                            <div className="col-lg-8">
                                                                 <MySelectSearchDataFromServer
                                                                      name={"villeFranceResidence"}
                                                                      value={helpers.values.villeFranceResidence}
                                                                      onChange={val => helpers.setFieldValue("villeFranceResidence", val)}
                                                                      defaultOptions={
                                                                           detailsQuery.data.villeFr
                                                                                ? [
                                                                                       {
                                                                                            value: detailsQuery.data.villeFr.id as number,
                                                                                            label: detailsQuery.data.villeFr.nom as string,
                                                                                       },
                                                                                  ]
                                                                                : []
                                                                      }
                                                                      url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                      isInvalid={!!helpers.errors.villeFranceResidence}
                                                                      method={"GET"}
                                                                      minimumLengthSearch={3}
                                                                 />
                                                                 {helpers.errors.villeFranceResidence && <div className={"text-danger"}>{helpers.errors.villeFranceResidence}</div>}
                                                            </div>
                                                       </div>
                                                  ) : (
                                                       <>
                                                            {/* Ville hors france */}
                                                            <div className="row mb-5">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                                 <div className="col-lg-8">
                                                                      <MyInputField
                                                                           name={"villeResidence"}
                                                                           value={helpers.values.villeResidence}
                                                                           onChange={val => helpers.setFieldValue("villeResidence", val)}
                                                                           placeholder={"Ville"}
                                                                           isInvalid={!!helpers.errors.villeResidence}
                                                                      />
                                                                      <div className={"mb-2"}>
                                                                           {helpers.errors.villeResidence && <div className={"text-danger"}>{helpers.errors.villeResidence}</div>}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </>
                                                  )}

                                                  {/* Code postal */}
                                                  <div className="row mb-5">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Code postal</label>

                                                       <div className="col-lg-8">
                                                            <MyInputField
                                                                 name={"codePostalResidence"}
                                                                 value={helpers.values.codePostalResidence}
                                                                 onChange={val => helpers.setFieldValue("codePostalResidence", val)}
                                                                 placeholder={"Code postal"}
                                                                 isInvalid={!!helpers.errors.codePostalResidence}
                                                                 inputMode={"numeric"}
                                                            />
                                                            <div className={"mb-2"}>
                                                                 {helpers.errors.codePostalResidence && <div className={"text-danger"}>{helpers.errors.codePostalResidence}</div>}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </>
                                        )}

                                        <h4 className={"mt-10  mb-4 text-primary"}>Secret Défense</h4>

                                        {/* Is habilitable */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Est-il habilitable?</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"habilitable"}
                                                       options={[
                                                            { value: "OUI", label: "Oui" },
                                                            { value: "NON", label: "Non" },
                                                            { value: "UNKNOWN", label: "Inconnu" },
                                                       ]}
                                                       isClearable={false}
                                                       value={helpers.values.habilitable}
                                                       onChange={val => helpers.setFieldValue("habilitable", val)}
                                                       isInvalid={!!helpers.errors.habilitable}
                                                  />
                                                  {helpers.errors.habilitable && <div className={"text-danger"}>{helpers.errors.habilitable}</div>}
                                             </div>
                                        </div>

                                        {helpers.values.habilitable === "OUI" && (
                                             <>
                                                  <div className="row mb-5">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">A-il déjà eu une habilitation?</label>

                                                       <div className="col-lg-8 d-flex">
                                                            <div className="d-flex align-items-center">
                                                                 <MyCheckBoxField
                                                                      name={"isDejaEuUneHabilitation"}
                                                                      value={helpers.values.isDejaEuUneHabilitation}
                                                                      isInvalid={!!helpers.errors.isDejaEuUneHabilitation}
                                                                      onChange={val => helpers.setFieldValue("isDejaEuUneHabilitation", val)}
                                                                 />
                                                            </div>
                                                            {helpers.values.isDejaEuUneHabilitation && (
                                                                 <div className="flex-grow-1 ms-2">
                                                                      <MyDateDayMonthYearField
                                                                           name={"dateFinHabilitation"}
                                                                           value={helpers.values.dateFinHabilitation}
                                                                           onChange={val => helpers.setFieldValue("dateFinHabilitation", val)}
                                                                           isInvalid={!!helpers.errors.dateFinHabilitation}
                                                                           placeholder={"Date de fin d'habilitation"}
                                                                      />
                                                                      {helpers.errors.dateFinHabilitation && (
                                                                           <div className={"text-danger"}>{helpers.errors.dateFinHabilitation}</div>
                                                                      )}
                                                                 </div>
                                                            )}
                                                            {helpers.errors.isDejaEuUneHabilitation && (
                                                                 <div className={"text-danger"}>{helpers.errors.isDejaEuUneHabilitation}</div>
                                                            )}
                                                       </div>
                                                  </div>
                                             </>
                                        )}

                                        {/* Submit button */}
                                        <div className={"text-end"}>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? "Appliquer les modifications" : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               )}
          </div>
     )
}
