import React from "react"
import MyCard from "@common-utils/MyCard"
import { useListingContext } from "./PersonnelDdcListingMultiplePersonnel"
import "./styles.scss"
import MyGoogleDriveFileViewer from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewer"
import { IGetDdcResponseItemModel } from "./core/_models"

/* Wrapper */
const ListPreview = () => {
     const globalContext = useListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.personnelDdc.id === selectedItemInPreview.personnelDdc.id) as IGetDdcResponseItemModel

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>Justificatif</MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <MyGoogleDriveFileViewer
                         googleDriveID={itemInPreview.personnelDdc.justificatifGoogleDriveID as string}
                         googleDriveMimetype={itemInPreview.personnelDdc.justificatifGoogleDriveMimeType as string}
                    />
               </MyCard.Body>
          </MyCard>
     )
}

export default ListPreview
