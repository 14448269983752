import { OverlayTrigger, Tooltip } from "react-bootstrap"
import React from "react"
import GlobalHelper from "../helpers/GlobalHelper"
import { isBrowser } from "react-device-detect"

const MyTooltip = ({ title, placement, children, trigger = ["click", "hover"] }: IMyTooltipPropsModel) => {
     const id = `tooltip_${GlobalHelper.randomId()}`
     return isBrowser ? (
          <OverlayTrigger
               overlay={<Tooltip id={id}>{title}</Tooltip>}
               defaultShow={undefined}
               delay={{
                    show: 100,
                    hide: 100,
               }}
               flip={undefined}
               onHide={undefined}
               placement={placement}
               popperConfig={undefined}
               target={undefined}
               rootClose={true}
               onToggle={undefined}
               trigger={trigger}
               show={undefined}
          >
               {children}
          </OverlayTrigger>
     ) : (
          children
     )
}

interface IMyTooltipPropsModel {
     title: JSX.Element | string
     placement?: "top" | "bottom" | "right" | "left"
     children: JSX.Element
     trigger?: "click" | "hover" | ["click", "hover"]
}

export default MyTooltip
