import React, { useState } from "react"
import "./styles.scss"
import MyAlert from "@common-utils/MyAlert"
import { toAbsoluteUrl } from "@common-metronic/helpers"

const PersonnelNdfDepensesNoticeUtilisation = ({ finaliserCallback }: { finaliserCallback: () => void }) => {
     const [step, setStep] = useState<"one" | "two" | "tres">("one")
     return (
          <>
               {step === "one" && (
                    <div className={"p-5"}>
                         <div className={"fs-5 mb-4"} style={{ textAlign: "justify" }}>
                              Vous pouvez ajouter vos justificatifs à l'aide du bouton <span className="fw-bold text-primary">"Ajouter une dépense"</span>. <br />
                              Vous pourrez choisir ensuite entre un seul justificatif, qui concerne un seul élément (par exemple, une note de restaurant), ou une dépense groupée,
                              qui regroupe plusieurs justificatifs (par exemple, 4 factures de carburant et 2 notes de restaurant) <br />
                              L'ajout d'une dépense groupée vous permettra de gagner du temps. Par exemple, au lieu de joindre les justificatifs un par un, vous pouvez les agrafer
                              sur une seule feuille, les numéroter, puis les scanner. <br />
                              <br />
                              En cliquant sur "Suivant", vous trouverez un exemple concret d'une dépense groupée.
                         </div>

                         <div className="d-flex justify-content-end">
                              <button type={"button"} className={"btn btn-primary btn-sm"} onClick={() => setStep("two")}>
                                   Suivant {"->"}
                              </button>
                         </div>
                    </div>
               )}

               {step === "two" && (
                    <>
                         <MyAlert type={"primary"}>Voici à quoi devrait ressembler votre justificatif</MyAlert>
                         <iframe src={toAbsoluteUrl("/media/tutorial-examples/ndf-depense-groupee-1.pdf")} style={{ width: "100%", height: "70vh" }} />

                         <div className="d-flex justify-content-between">
                              <button type={"button"} className={"btn btn-primary btn-sm"} onClick={() => setStep("one")}>
                                   {"<-"} Précédant
                              </button>

                              <button type={"button"} className={"btn btn-primary btn-sm"} onClick={() => setStep("tres")}>
                                   Suivant {"->"}
                              </button>
                         </div>
                    </>
               )}

               {step === "tres" && (
                    <>
                         <MyAlert type={"primary"}>Voici à quoi devrait ressembler votre dépense groupée</MyAlert>
                         <img src={toAbsoluteUrl("/media/tutorial-examples/ndf-depense-groupee-2.png")} style={{ width: "100%", height: "60vh" }} />

                         <div className="d-flex justify-content-between">
                              <button type={"button"} className={"btn btn-primary btn-sm"} onClick={() => setStep("two")}>
                                   {"<-"} Précédant
                              </button>

                              <button type={"button"} className={"btn btn-primary btn-sm"} onClick={() => finaliserCallback()}>
                                   J'ai compris!
                              </button>
                         </div>
                    </>
               )}
          </>
     )
}

export default PersonnelNdfDepensesNoticeUtilisation
