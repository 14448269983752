import MyAlert from "@common-utils/MyAlert"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { useConsultantsDeclarationsListingContext } from "../../declaration/listing/ConsultantsDeclarationsListing"
import React from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { IGetDemandesCongesResponseItemModel, IGetDemandesCongesResponseModel } from "../../ddc/core/_models"
import { getDdcListingRequest } from "../../ddc/core/_requests"
import { useDdcActions } from "../../ddc/listing/core/_hooks"
import MyTooltip from "@common-utils/MyTooltip"
import { IConsultantDdcModel } from "@common-models/*"

const REACT_QUERY_KEY_GET_CONSULTANT_DDC = "REACT_QUERY_KEY_GET_CONSULTANT_DDC"
const ConsultantDetailsDDC = () => {
     const context = useConsultantDetailsContext()
     const ddcActions = useDdcActions()

     const ddcQuery = useQuery<IGetDemandesCongesResponseModel, AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_DDC, async () => {
          return getDdcListingRequest({ consultants: [context.detailsQuery.data?.consultant.id as number] }).then(r => {
               return r.data
          })
     })

     const DdcItem = ({ item }: { item: IGetDemandesCongesResponseItemModel }) => {
          const queryClient = useQueryClient()
          const context = useConsultantsDeclarationsListingContext()

          function validationCallback(val: IConsultantDdcModel) {
               queryClient.setQueryData<IGetDemandesCongesResponseModel | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DDC, (prev: IGetDemandesCongesResponseModel | undefined) => {
                    if (prev?.data) {
                         const index = prev.data.findIndex(item_ => item_.consultantDdc.id === item.consultantDdc.id)
                         prev.data[index] = {
                              ...prev.data[index],
                              consultantDdc: {
                                   ...prev.data[index].consultantDdc,
                                   ...val,
                              },
                         }
                    }

                    return prev
               })
          }

          function rejetCallback(val: IConsultantDdcModel) {
               queryClient.setQueryData<IGetDemandesCongesResponseModel | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DDC, (prev: IGetDemandesCongesResponseModel | undefined) => {
                    if (prev?.data) {
                         const index = prev.data.findIndex(item_ => item_.consultantDdc.id === item.consultantDdc.id)
                         prev.data[index] = {
                              ...prev.data[index],
                              consultantDdc: {
                                   ...prev.data[index].consultantDdc,
                                   ...val,
                              },
                         }
                    }

                    return prev
               })
          }

          const EtatBadge = () => {
               if (item.consultantDdc.etat?.type === "TYPE_ENVOI_DEMANDE" || item.consultantDdc.etat?.type === "TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM") {
                    return <div className={"badge badge-warning text-black"}>En attente de validation</div>
               }

               if (item.consultantDdc.etat?.type === "TYPE_ACCEPTATION_DEMANDE") {
                    return <div className={"badge badge-success"}>Validée</div>
               }

               if (item.consultantDdc.etat?.type === "TYPE_REJET_DEMANDE") {
                    return <div className={"badge badge-danger"}>Rejetée</div>
               }

               if (item.consultantDdc.etat?.type === "TYPE_ANNULATION_DEMANDE") {
                    return <div className={"badge badge-info"}>Annulée</div>
               }

               return <div className={"badge badge-dark"}>-</div>
          }

          return (
               <>
                    <div className={"border-dashed px-4 pt-4 pb-2"}>
                         <div className="d-flex align-items-center justify-content-between mb-4">
                              <div className={"fs-5 fw-bold"}>{item.consultantDdc.typeLabel}</div>
                              <EtatBadge />
                         </div>
                         <div className="separator separator-dashed mb-6" />

                         <div className="text-center">
                              {/* Date de la demande */}
                              <div className={"mb-1"}>
                                   <span className={"fw-bold me-2"}>Date de la demande: </span> {item.consultantDdc.createdAt?.format("dddd D MMMM YYYY")}
                              </div>

                              {/* Période */}
                              <MyTooltip
                                   title={
                                        <div>
                                             du {item.consultantDdc.du?.format("dddd D MMMM YYYY")} au {item.consultantDdc.au?.format("dddd D MMMM YYYY")}
                                        </div>
                                   }
                              >
                                   <div className={"mb-4"}>
                                        <span className={"fw-bold me-2"}>Période: </span> du {item.consultantDdc.du?.format("DD/MM/YYYY")} au{" "}
                                        {item.consultantDdc.au?.format("DD/MM/YYYY")}
                                   </div>
                              </MyTooltip>

                              {(item.consultantDdc.etat?.type === "TYPE_ENVOI_DEMANDE" ||
                                   item.consultantDdc.etat?.type === "TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM") && (
                                   <>
                                        <div className="row">
                                             <div className="col-6 d-grid">
                                                  <button
                                                       className={"btn btn-sm btn-success d-flex align-items-center justify-content-center"}
                                                       onClick={() => ddcActions.validation(item.consultantDdc.id as number, validationCallback)}
                                                  >
                                                       Valider <i className={"fas fa-thumbs-up ms-2"} />
                                                  </button>
                                             </div>
                                             <div className="col-6 d-grid">
                                                  <button
                                                       className={"btn btn-sm btn-danger d-flex align-items-center justify-content-center"}
                                                       onClick={() => ddcActions.rejet(item.consultantDdc.id as number, rejetCallback)}
                                                  >
                                                       Rejeter <i className={"fas fa-thumbs-down ms-2"} />
                                                  </button>
                                             </div>
                                        </div>
                                   </>
                              )}
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               <div className="p-4">
                    {ddcQuery.isFetching && (
                         <div className={"text-center p-5"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}
                    {!ddcQuery.isFetching && ddcQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{ddcQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => ddcQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}
                    {!ddcQuery.isFetching && !ddcQuery.isError && ddcQuery.data?.data && ddcQuery.data.data.length > 0 && (
                         <div className={"row"}>
                              {ddcQuery.data.data.map((item: IGetDemandesCongesResponseItemModel, key) => (
                                   <div className="col-sm-6" key={key}>
                                        <DdcItem item={item} />
                                   </div>
                              ))}
                         </div>
                    )}
                    {!ddcQuery.isFetching && !ddcQuery.isError && ddcQuery.data?.data.length === 0 && <MyAlert type={"primary"}>Aucune DDC à afficher</MyAlert>}
               </div>
          </>
     )
}

export default ConsultantDetailsDDC
