import React, { createContext, useContext } from "react"
import { IPersonnelCraModel } from "@common-models/*"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import "./styles.scss"
import PersonnelTrTableTheadRows from "./_PersonnelTrTableTheadRows"
import PersonnelTrTableTbodyRow from "./_PersonnelTrTableTbodyRow"
import { getPersonnelDeclarationActiveCra } from "../core/_requests"

const REACT_QUERY_KEY_GET_ACTIVE_CRA = "REACT_QUERY_KEY_GET_ACTIVE_CRA"
const PersonnelTrContext = createContext<PersonnelTrContextPropsModel>({} as PersonnelTrContextPropsModel)
export const usePersonnelTrContext = () => useContext(PersonnelTrContext)

const PersonnelTr = ({ declaration_ID, editionModeEnabled, setIsDataLoaded }: IPersonnelTrPropsModel) => {
     const activeCraQuery = useQuery<IPersonnelCraModel, AxiosError>(REACT_QUERY_KEY_GET_ACTIVE_CRA, () =>
          getPersonnelDeclarationActiveCra(declaration_ID).then(r => {
               if (setIsDataLoaded) setIsDataLoaded(true)

               return r.data
          })
     )

     return (
          <>
               {/* loader */}
               {activeCraQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!activeCraQuery.isFetching && activeCraQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{activeCraQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => activeCraQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!activeCraQuery.isFetching && !activeCraQuery.isError && activeCraQuery.data && (
                    <PersonnelTrContext.Provider
                         value={{
                              data: activeCraQuery.data,
                              REACT_QUERY_KEY_GET_ACTIVE_CRA,
                              editionModeEnabled,
                         }}
                    >
                         <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
                              <table id={"cra-table"} className={`table m-0 ${editionModeEnabled && "cra-table-edition-mode"}`}>
                                   {/* Begin::thead */}
                                   <thead className="thead-dark border-bottom border-top">
                                        <PersonnelTrTableTheadRows />
                                   </thead>
                                   {/* End::thead */}

                                   {/* Begin::tbody */}
                                   <tbody className={"border-top border-bottom"}>
                                        <PersonnelTrTableTbodyRow />
                                   </tbody>
                                   {/* End::tbody */}
                              </table>
                         </div>

                         <div className="text-primary d-flex justify-content-end fs-6">
                              <span className={"fw-bold mt-2"}>
                                   {activeCraQuery.data.personnelCraDetails?.filter(item => item.ticketResto).length === 0 && "Aucun ticket restaurant"}
                                   {activeCraQuery.data.personnelCraDetails?.filter(item => item.ticketResto).length === 1 && "1 ticket restaurant"}
                                   {activeCraQuery.data.personnelCraDetails!.filter(item => item.ticketResto).length > 1 &&
                                        `${activeCraQuery.data.personnelCraDetails?.filter(item => item.ticketResto).length} tickets restaurant`}
                              </span>
                         </div>
                    </PersonnelTrContext.Provider>
               )}
          </>
     )
}

interface IPersonnelTrPropsModel {
     declaration_ID: number
     editionModeEnabled: boolean
     setIsDataLoaded?: React.Dispatch<React.SetStateAction<boolean>>
}

interface PersonnelTrContextPropsModel {
     data: IPersonnelCraModel
     REACT_QUERY_KEY_GET_ACTIVE_CRA: string
     editionModeEnabled: boolean
}

export default PersonnelTr
