import { IGetDetailsConsultant } from "../core/_models"
import MyTooltip from "@common-utils/MyTooltip"
import React, { useState } from "react"
import MyModal from "@common-utils/MyModal"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

const ConsultantDetailsInfoBaseMission = ({ consultantInfoPerso }: IConsultantDetailsInfoBaseMissionPropsModel) => {
     const [currentOrdreMissionInModal, setCurrentOrdreMissionInModal] = useState<{ loading: boolean }>()

     const clientPropal = consultantInfoPerso.detailsDuJour?.clientPropal || consultantInfoPerso.consultant.clientPropal
     const tjm = consultantInfoPerso.detailsDuJour?.tjm || consultantInfoPerso.consultant.clientPropal?.tjm
     const baseFacturationAnnuelle = consultantInfoPerso.detailsDuJour?.baseFacturation || consultantInfoPerso.consultant.baseFacturationAnnuelle
     const responsable = consultantInfoPerso.detailsDuJour?.personnel || consultantInfoPerso.consultant.personnel
     const mission = consultantInfoPerso.missionDuJour?.fileSigne || consultantInfoPerso.missionDuJour

     return (
          <>
               <div className="row">
                    <div className={"col-9"}>
                         {/* Client */}
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Type</label>
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">{clientPropal?.typeLabel}</span>
                              </div>
                         </div>

                         {/* Client */}
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Client</label>
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">{clientPropal?.client?.nom}</span>
                              </div>
                         </div>

                         {/* TJM */}
                         {clientPropal?.type === "TYPE_ASSISTANCE_TECHNIQUE" && (
                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Taux journalier de vente</label>
                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">{tjm}€ / jour</span>
                                   </div>
                              </div>
                         )}

                         {/* Base de facturation */}
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Base de facturation</label>
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">{baseFacturationAnnuelle?.nbJoursPlusPlus}</span>
                              </div>
                         </div>

                         {/* Responsable client */}
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Responsable client</label>
                              <div className="col-lg-8">
                                   <MyTooltip
                                        title={
                                             <div className={"d-flex justify-content-center flex-column"}>
                                                  <div className={"fw-bold me-2"}>Email:</div>
                                                  <a href={`mailto:${clientPropal?.clientResponsable?.email}`}>{clientPropal?.clientResponsable?.email}</a>
                                                  <div className={"fw-bold me-2"}>Tél.:</div>
                                                  <a href={`tel:${clientPropal?.clientResponsable?.tel}`}>{clientPropal?.clientResponsable?.tel}</a>
                                             </div>
                                        }
                                        trigger={"click"}
                                   >
                                        <span className="fw-bolder fs-6 text-dark" style={{ textDecoration: "underline dotted", cursor: "pointer" }}>
                                             {clientPropal?.clientResponsable?.prenomNom}
                                        </span>
                                   </MyTooltip>
                              </div>
                         </div>

                         {/* Manager */}
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Manager</label>
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">{responsable?.prenomNom}</span>
                              </div>
                         </div>
                    </div>
                    {consultantInfoPerso.consultant.contrat !== "CONTRAT_ST" && mission?.googleDriveID && (
                         <div className="col-3 d-flex align-items-center">
                              <div className="cursor-pointer text-hover-primary" onClick={() => setCurrentOrdreMissionInModal({ loading: true })}>
                                   <span
                                        className={`${FileAndDriveHelper.getFontAwesomeClassAndColorAccordingToFileMimeType(mission.googleDriveMimeType as string)} text-dark mb-1`}
                                        style={{ fontSize: "4rem" }}
                                   />
                                   <div className={"fw-bold text-center"}>ODM</div>
                              </div>
                         </div>
                    )}
               </div>

               {currentOrdreMissionInModal && consultantInfoPerso.consultant.contrat !== "CONTRAT_ST" && mission?.googleDriveID && (
                    <MyModal title={<span>Ordre de mission</span>} show={true} handleClose={() => setCurrentOrdreMissionInModal(undefined)} noPadding size={"xl"}>
                         <MyLoadingOverlay isActive={currentOrdreMissionInModal.loading}>
                              <iframe
                                   onLoad={() => setCurrentOrdreMissionInModal(prev => (prev ? { ...prev, loading: false } : undefined))}
                                   src={FileAndDriveHelper.getUrlOfDriveFilePreview(mission?.googleDriveID as string)}
                                   className={"vh-100 w-100"}
                              />
                         </MyLoadingOverlay>
                    </MyModal>
               )}
          </>
     )
}

interface IConsultantDetailsInfoBaseMissionPropsModel {
     consultantInfoPerso: IGetDetailsConsultant
}

export default ConsultantDetailsInfoBaseMission
