import axios from "axios"
import { IFournisseurDocumentModel } from "@common-models/*"
import { CONST_API_FOURNISSEUR_DOCUMENT_DELETE_ENDPOINT, CONST_API_FOURNISSEUR_DOCUMENT_REPLACE_ENDPOINT } from "../../../../endpoints"

export function replaceFournisseurDocument(id: number, fichier: File) {
     const formData = new FormData()
     formData.append("fichier", fichier)

     return axios.post<IFournisseurDocumentModel>(CONST_API_FOURNISSEUR_DOCUMENT_REPLACE_ENDPOINT(id), formData)
}

export function deleteFournisseurDocument(id: number) {
     return axios.delete(CONST_API_FOURNISSEUR_DOCUMENT_DELETE_ENDPOINT(id))
}
