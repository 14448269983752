import { IConsultantSuiviAjoutOuChangementMachineRequestModel } from "./core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IConsultantModel, IEntiteModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_MACHINES_ENDPOINT } from "../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import { consultantSuiviAjoutOuChangementMachineRequest } from "./core/_requests"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import * as Yup from "yup"
import moment from "moment"
import MyModal from "@common-utils/MyModal"
import AddMachineForm from "../../../utils/_AddMachineForm"

const ConsultantSuiviAjoutOuChangementMachine = ({ consultant, onSubmitCallback = () => {}, annulerCallback = () => {} }: IPropsModel) => {
     const [isAddingMachine, setIsAddingMachine] = useState<boolean>(false)

     // Form schema
     const schema = Yup.object().shape({
          date: Yup.string().required().label(`"Date de prise d'effet"`),
          machine: Yup.string().required().label(`"Machine"`),
     })

     // Init values
     const initialValues: IConsultantSuiviAjoutOuChangementMachineRequestModel = {}

     // Handle submit
     function handleSubmit(values: IConsultantSuiviAjoutOuChangementMachineRequestModel, helpers: FormikHelpers<IConsultantSuiviAjoutOuChangementMachineRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviAjoutOuChangementMachineRequest(consultant.id as number, values)
               .then(r => {
                    toast.success("Le changement sera pris en compte à partir du " + moment(values.date).format("dddd D MMMM YYYY"), { autoClose: 5000 })
                    onSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Date */}
                                   <div className="row mb-4">
                                        <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Date de prise d'effet</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"date"}
                                                  value={helpers.values.date}
                                                  onChange={val => helpers.setFieldValue("date", val)}
                                                  isInvalid={!!helpers.errors.date}
                                                  placeholder={"Date de prise d'effet"}
                                                  autofocus
                                             />
                                             {helpers.errors.date && <div className={"text-danger"}>{helpers.errors.date}</div>}
                                        </div>
                                   </div>

                                   {/* Machine */}
                                   <div className="row mb-4">
                                        <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Machine</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"machine"}
                                                  url={CONST_API_UTILS_GET_MACHINES_ENDPOINT}
                                                  value={helpers.values.machine}
                                                  onChange={val => helpers.setFieldValue("machine", val)}
                                                  isInvalid={!!helpers.errors.machine}
                                             />
                                             <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                  {helpers.errors.machine && <div className={"text-danger"}>{helpers.errors.machine}</div>}
                                                  <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingMachine(true)}>
                                                       Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button
                                             className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                             type={"button"}
                                             onClick={annulerCallback}
                                             disabled={helpers.isSubmitting}
                                        >
                                             Annuler
                                        </button>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Valider</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>

               {/* Modal add machine */}
               {isAddingMachine && (
                    <MyModal title={<>Nouvelle machine</>} show={true} handleClose={() => setIsAddingMachine(false)}>
                         <AddMachineForm callback={() => setIsAddingMachine(false)} />
                    </MyModal>
               )}
          </>
     )
}

interface IPropsModel {
     consultant: IConsultantModel
     annulerCallback?: () => void
     onSubmitCallback?: (val: IEntiteModel) => void
}

export default ConsultantSuiviAjoutOuChangementMachine
