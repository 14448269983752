import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MyTooltip from "@common-utils/MyTooltip"
import { useBonsLivraisonManquantsOfGivenMonthListingListContext } from "./_BonsLivraisonNonFacturesOfGivenMonthListingList"
import { useBonsLivraisonNonFacturesOfGivenMonthListingContext } from "./BonsLivraisonNonFacturesOfGivenMonthListing"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { IBonsLivraisonNonFacturesOfGivenMonthItemResponseModel, IBonsLivraisonNonFacturesOfGivenMonthResponseModel } from "./core/_models"
import { Link } from "react-router-dom"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import useSwal from "@common-hooks/useSwal"
import { checkBonsLivraisonAsFactureRequest } from "./core/_requests"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const queryClient = useQueryClient()
     const { swal } = useSwal()
     const globalContext = useBonsLivraisonNonFacturesOfGivenMonthListingContext()
     const listContext = useBonsLivraisonManquantsOfGivenMonthListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const ptf = item.bl.clientBonCommande?.propalPeriode?.propal

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.bl.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.bl.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.bl.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     function handleMarquerCommeFacture() {
          swal.fire({
               icon: "warning",
               text: `Veuillez confirmer`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return checkBonsLivraisonAsFactureRequest([item.bl.id as number])
                         .then(() => {
                              globalContext.setItemInPreview(null)
                              queryClient.setQueryData<IBonsLivraisonNonFacturesOfGivenMonthResponseModel | undefined>(
                                   globalContext.REACT_QUERY_KEY_GET_LISTING,
                                   (prev: IBonsLivraisonNonFacturesOfGivenMonthResponseModel | undefined) => {
                                        if (prev?.data && prev.data.length > 0) {
                                             prev.pageEnd -= 1
                                             prev.totalElementsInAllPages -= 1
                                             prev.totalElementsInPage -= 1
                                             prev.data = prev.data.filter(item_ => item_.bl.id !== item.bl.id)
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Facturé")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, avatar, left & right side*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.bl.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   <div className={"d-flex align-items-center"}>
                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            ptf?.client?.googleDriveIdAvatar
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(ptf.client.googleDriveIdAvatar as string)
                                                                 : toAbsoluteUrl("/media/svg/files/blank-image.svg")
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <span className={"fw-bold fs-5"}>{ptf?.client?.nom}</span>
                                             </div>
                                             <div>
                                                  <Link to={`/client/ptf/${ptf?.id}/details`} target={"_blank"}>
                                                       {ptf?.reference}
                                                  </Link>{" "}
                                                  <span>( {ptf?.typeLabelShort} )</span>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex align-items-center">
                                        <span className={"badge badge-dark"}>Facturable par {ptf?.entiteFacturation?.nom}</span>
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"row align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" /> {ptf?.personnel?.prenomNom}
                                             </div>

                                             {/* Consultants */}
                                             <div className={`d-flex align-items-center flex-wrap`}>
                                                  <span className="fas fa-users fs-5 w-25px" />
                                                  {item.consultantsAttachesToPtf.length > 0 ? (
                                                       item.consultantsAttachesToPtf?.map((item_, key_) => (
                                                            <>
                                                                 <Link to={`/business/consultants/${item_.id}/details`} target={"_blank"}>
                                                                      {item_.prenomNom}
                                                                 </Link>{" "}
                                                                 {key_ + 1 < item.consultantsAttachesToPtf.length && <span className={"me-1"}>,</span>}
                                                            </>
                                                       ))
                                                  ) : (
                                                       <span className={"text-muted"}>Aucun consultant</span>
                                                  )}
                                             </div>
                                        </div>
                                        <div className="col text-end">
                                             <div>
                                                  <MyTooltip title={"Marquer ce BL comme facturé"}>
                                                       <button
                                                            type={"button"}
                                                            className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                                            onClick={() => handleMarquerCommeFacture()}
                                                       >
                                                            <i className="fas fa-check-circle fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const BonsLivraisonManquantsOfGivenMonthListingListItem = ({ item, index }: { item: IBonsLivraisonNonFacturesOfGivenMonthItemResponseModel; index: number }) => {
     const context = useBonsLivraisonNonFacturesOfGivenMonthListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.bl.id === item.bl.id
     const isItemLoading: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.bl.id === item.bl.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.bl.id !== item.bl.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview({
                    ...getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages),
               })
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IBonsLivraisonNonFacturesOfGivenMonthItemResponseModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default BonsLivraisonManquantsOfGivenMonthListingListItem
