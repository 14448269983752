import React from "react"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useConsultantsDeclarationsListingContext } from "./ConsultantsDeclarationsListing"
import { useLocation } from "react-router-dom"

// Simple search bar
const ConsultantsDeclarationsListingSearchBar = () => {
     const globalContext = useConsultantsDeclarationsListingContext()
     const location = useLocation() as {
          state: {
               etat?:
                    | "ETAT_ADMIN__EN_ATTENTE_RECEPTION"
                    | "ETAT_ADMIN__EN_COURS_RECTIFICATION"
                    | "ETAT_ADMIN__A_VALIDER"
                    | "ETAT_ADMIN__VALIDE_POUR_PAIE"
                    | "ETAT_NON_ADMIN__EN_ATTENTE_RECEPTION"
                    | "ETAT_NON_ADMIN__EN_COURS_RECTIFICATION"
                    | "ETAT_NON_ADMIN__A_ENVOYER_AU_SIEGE"
                    | "ETAT_NON_ADMIN__A_CONTROLER"
                    | "ETAT_NON_ADMIN__ENVOYE_AU_SIEGE"
                    | "ETAT_NON_ADMIN__VALIDE_POUR_PAIE"
               mois?: string
               keyword?: string
          }
     }

     function handleSubmit(values: { keyword?: string }) {
          globalContext.setFilters(prev => ({
               ...prev,
               keyword: values.keyword,
          }))
     }

     function handleReset() {
          globalContext.simpleSearchBarFormikRef.current.resetForm() // Reset local form
          globalContext.filtersFormikRef.current.resetForm() // Reset search bar form
          globalContext.setFilters(prev => ({
               page: 1,
               pageLength: 20,
               mois: prev.mois,
               etat: location.state?.etat,
          })) // Reset payload filters

          globalContext.simpleSearchBarFormikRef.current.setSubmitting(true)
     }

     return (
          <Formik<{ keyword?: string }>
               initialValues={{
                    keyword: globalContext.filters.keyword,
               }}
               onSubmit={handleSubmit}
               innerRef={globalContext.simpleSearchBarFormikRef}
          >
               {helpers => {
                    return (
                         <Form noValidate autoComplete={"off"}>
                              <div className="input-group">
                                   <MyTooltip title={"Voir les filtres"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-secondary "}
                                             onClick={() => globalContext.setAreFiltersVisible(prev => !prev)}
                                        >
                                             <i className={"fas fa-filter fs-4 text-dark"} />
                                        </button>
                                   </MyTooltip>
                                   {/* Button that activates mass selection */}
                                   {globalContext.listingQuery.data && globalContext.listingQuery.data.totalElementsInAllPages > 1 && (
                                        <MyTooltip
                                             title={globalContext.isBulkSelectionEnabled.enabled ? "Quitter le mode sélection multiple" : "Activer le mode sélection multiple"}
                                        >
                                             <button
                                                  type={"button"}
                                                  className={`btn btn-sm ${globalContext.isBulkSelectionEnabled.enabled ? "btn-secondary" : "btn-outline btn-outline-secondary"}`}
                                                  onClick={() =>
                                                       globalContext.setIsBulkSelectionEnabled(prev =>
                                                            prev.enabled
                                                                 ? {
                                                                        enabled: false,
                                                                        checkedElementsInPage: [],
                                                                        areAllElementsInAllPagesChecked: false,
                                                                   }
                                                                 : {
                                                                        ...prev,
                                                                        enabled: true,
                                                                   }
                                                       )
                                                  }
                                             >
                                                  <i
                                                       className={`fas ${
                                                            globalContext.isBulkSelectionEnabled.enabled ? "fa-times text-danger fs-2" : "fa-list-check text-dark fs-4"
                                                       }`}
                                                  />
                                             </button>
                                        </MyTooltip>
                                   )}
                                   <MyInputField
                                        name={"keyword"}
                                        value={helpers.values.keyword}
                                        isInvalid={false}
                                        placeholder={"Rechercher ..."}
                                        onChange={val => {
                                             helpers.setFieldValue("keyword", val).then()
                                             globalContext.filtersFormikRef.current.setFieldValue("keyword", val).then()
                                        }}
                                        classNames={"border"}
                                   />
                                   <button type={"submit"} className={"btn btn-sm btn-outline btn-outline-secondary"} disabled={helpers.isSubmitting}>
                                        {helpers.isSubmitting ? <MySimpleSpinner size={"sm"} classNames={"text-dark"} /> : <i className={"fas fa-search text-dark"} />}
                                   </button>
                                   <MyTooltip title={"Réinitialiser les filtres"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-secondary"}
                                             onClick={() => handleReset()}
                                             disabled={helpers.isSubmitting}
                                        >
                                             <i className={"fas fa-sync text-dark"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default ConsultantsDeclarationsListingSearchBar
