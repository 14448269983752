import axios from "axios"
import { CONST_API_PERSONNEL_DECLARATION_CREATE_ENDPOINT, CONST_API_PERSONNEL_DECLARATION_GET_BY_DATE_ENDPOINT } from "../../../../endpoints"
import { ICreateConnectedUserDeclarationRequestModel } from "./_models"
import { IPersonnelDeclarationMensuelleModel } from "@common-models/*"

export function getConnectedUserDeclarationByDateRequest(mois: string, annee: string) {
     return axios.post<IPersonnelDeclarationMensuelleModel | null>(CONST_API_PERSONNEL_DECLARATION_GET_BY_DATE_ENDPOINT, { mois, annee })
}

export function createConnectedUserDeclarationRequest(payload: ICreateConnectedUserDeclarationRequestModel) {
     return axios.post<IPersonnelDeclarationMensuelleModel>(CONST_API_PERSONNEL_DECLARATION_CREATE_ENDPOINT, payload)
}
