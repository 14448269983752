import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import MyEditorField from "@common-utils/fields/MyEditorField"
import MyInputField from "@common-utils/fields/MyInputField"
import React from "react"
import { IPersonnelTemplateCreatePropsModel, IPersonnelTemplateCreateRequestModel } from "./core/_models"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_ENVOYER_DOCO_VIERGE_PAR_MAIL_A_REMPLIR_PAR_CANDIDAT,
     CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL,
     CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PERSONNEL_MAIL_TEMPLATE_TYPE_CANDIDAT_REFUS,
} from "@common-constants/*"
import { createPersonnelTemplate } from "./core/_request"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"

const PersonnelTemplateCreateForm = ({ categorie, handleOnSubmitCallBack = () => {} }: IPersonnelTemplateCreatePropsModel) => {
     const schema = Yup.object().shape({
          titre: Yup.string().label(`"Titre du template"`).required(),
          objet: Yup.string().label(`"Objet"`),
          body: Yup.string().label(`"Corps"`).required(),
     })

     interface IFormValuesModel {
          titre: string
          objet?: string
          body: string
     }

     const initialValues: IFormValuesModel = {
          titre: "",
          body: "",
     }

     function handleSubmit(values: IFormValuesModel, helpers: FormikHelpers<IFormValuesModel>) {
          const data: IPersonnelTemplateCreateRequestModel = {
               titre: values.titre,
               body: values.body,
               objet: values.objet,
               categorie,
          }
          createPersonnelTemplate(data)
               .then(r => {
                    toast.success("Votre template a bien été ajouté.")
                    handleOnSubmitCallBack(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off" className={"w-xl-70 w-xxl-50"}>
                              {/* Titre field */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre</label>

                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"titre"}
                                             value={helpers.values.titre}
                                             onChange={val => helpers.setFieldValue("titre", val)}
                                             placeholder={"Titre du template"}
                                             isInvalid={!!helpers.errors.titre}
                                        />
                                        <div className={"mb-2"}>{helpers.errors.titre && <div className={"text-danger"}>{helpers.errors.titre}</div>}</div>
                                   </div>
                              </div>

                              {/* Objet field */}
                              {[
                                   CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PERSONNEL_MAIL_TEMPLATE_TYPE_CANDIDAT_REFUS,
                                   CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL,
                                   CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_ENVOYER_DOCO_VIERGE_PAR_MAIL_A_REMPLIR_PAR_CANDIDAT,
                              ].includes(categorie) && (
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Objet</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"objet"}
                                                  value={helpers.values.objet}
                                                  onChange={val => helpers.setFieldValue("objet", val)}
                                                  placeholder={"Objet"}
                                                  isInvalid={!!helpers.errors.objet}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.objet && <div className={"text-danger"}>{helpers.errors.objet}</div>}</div>
                                        </div>
                                   </div>
                              )}

                              {/* Body field */}
                              <>
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Contenu</label>
                                   <MyEditorField
                                        value={helpers.values.body}
                                        height={250}
                                        onChange={val => helpers.setFieldValue("body", val)}
                                        placeholder={"Contenu du template"}
                                   />
                                   <div className={"mb-2"}>{helpers.errors.body && <div className={"text-danger"}>{helpers.errors.body}</div>}</div>
                              </>

                              {/* Submit button */}
                              <div className={"text-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? "Créer" : <MySimpleSpinner size={"sm"} />}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default PersonnelTemplateCreateForm
