import { Link, useParams } from "react-router-dom"
import PtfDetailsFormSetDetails from "./_PtfDetailsFormSetDetails"
import MyCard from "@common-utils/MyCard"
import { IGetDetailsPropalRequestModel, IGetPeriodesBonsCommandeBonsLivraisonResponseModel } from "../core/_models"
import React, { useState } from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyTooltip from "@common-utils/MyTooltip"
import PtfDetailsConsultantsAttachesToday from "./_PtfDetailsConsultantsAttachesToday"
import PtfDetailsPeriodesBonsCommandeBonsLivraison, { REACT_QUERY_KEY_GET_PERIODES_BC_BL } from "../periodesBonsCommandeBonsLivraison/_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import MyAlert from "@common-utils/MyAlert"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { getClientPropalDetailsRequest, setEtatClientPropalRequest } from "../core/_requests"
import { IClientBonCommandeModel, IClientBonLivraisonModel, IClientPropalModel, IHttpErrorResponseModel, IPersonnelModel } from "@common-models/*"
import { toast } from "react-toastify"
import useSwal from "@common-hooks/useSwal"
import MyModal from "@common-utils/MyModal"
import PtfsChangementResponsableForm from "../actions/_PtfsChangementResponsableForm"
import PtfValidationAffaireForm from "../actions/_PtfValidationAffaireForm"
import PtfDetailsCdc from "./_PtfDetailsCdc"

const REACT_QUERY_KEY_GET_PTF_DETAILS = "REACT_QUERY_KEY_GET_PTF_DETAILS"
const PtfDetails = () => {
     const queryClient = useQueryClient()
     const ptf_ID = parseInt(useParams<{ ptf_id: string }>().ptf_id as string)
     const { swal } = useSwal()

     const detailsQuery = useQuery<IGetDetailsPropalRequestModel, AxiosError>(REACT_QUERY_KEY_GET_PTF_DETAILS, async () => {
          return getClientPropalDetailsRequest(ptf_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const [changementResponsableInModal, setChangementResponsableInModal] = useState<IClientPropalModel>()
     const [validationAffaireParClientInModal, setValidationAffaireParClientInModal] = useState<IClientPropalModel>()

     // Periodes, BC, BL callback (used for forfait in order to set PTF state)
     function handleSubmitAddBonLivraisonCallback(val: IClientBonLivraisonModel, livraisonTotale?: boolean) {
          // ON ADD LIVRABLE (FORFAIT): Set état of PTF détails
          if (detailsQuery.data?.ptf.type === "TYPE_FORFAIT") {
               queryClient.setQueryData<IGetDetailsPropalRequestModel | undefined>(REACT_QUERY_KEY_GET_PTF_DETAILS, (prev: IGetDetailsPropalRequestModel | undefined) => {
                    if (prev) {
                         if (!["ETAT_REFUSEE", "ETAT_DISABLED", "ETAT_LIVRE"].includes(prev.ptf.etat as string)) {
                              // Set PTF état to "En production" only if livrable is état = "En production"
                              if (val.etat === "ETAT_EN_COURS" && !["ETAT_EN_PROD", "ETAT_PARTIELLEMENT_LIVRE"].includes(prev.ptf.etat as string)) {
                                   prev.ptf.etat = "ETAT_EN_PROD"
                                   prev.ptf.etatLabel = "En production"
                                   return prev
                              }

                              if (val.etat === "ETAT_ENVOYE_AU_CLIENT") {
                                   prev.ptf.etat = livraisonTotale ? "ETAT_LIVRE" : "ETAT_PARTIELLEMENT_LIVRE"
                                   prev.ptf.etatLabel = livraisonTotale ? "Livré" : "Partiellement livré"
                                   return prev
                              }
                         }
                    }
                    return prev
               })
          }
     }

     // Used for actions such as archiver, passer en prod ...
     function handleSetPropalState(etat: string) {
          swal.fire({
               icon: "warning",
               title: `Veuillez confirmer votre action`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return setEtatClientPropalRequest(detailsQuery.data?.ptf.id as number, etat)
                         .then(r => {
                              queryClient.setQueryData<IGetDetailsPropalRequestModel | undefined>(
                                   REACT_QUERY_KEY_GET_PTF_DETAILS,
                                   (prev: IGetDetailsPropalRequestModel | undefined) => {
                                        if (prev) {
                                             return {
                                                  ...prev,
                                                  ptf: { ...prev.ptf, ...r.data },
                                             }
                                        }
                                        return prev
                                   }
                              )

                              toast.success("Votre action a bien été prise en compte.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Changement responsable callback: used to set responsable and set responsable of PTF details component
     function handleSubmitChangementResponsableCallback(responsable: IPersonnelModel) {
          queryClient.setQueryData<IGetDetailsPropalRequestModel | undefined>(REACT_QUERY_KEY_GET_PTF_DETAILS, (prev: IGetDetailsPropalRequestModel | undefined) => {
               if (prev) {
                    return {
                         ...prev,
                         ptf: { ...prev.ptf, personnel: { ...prev.ptf.personnel, ...responsable } },
                    }
               }
               return prev
          })
          setChangementResponsableInModal(undefined)
     }

     // Acceptation affaire par client callback: utilisée pour passer l'affaire de "en cours" to "validée"
     function handleSubmitAcceptationAffaireParClientCallback(bc: IClientBonCommandeModel) {
          // Add BC to "Périodes BC BL" listing
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              if (item.id === detailsQuery.data?.ptf.id) {
                                   item.clientPropalPeriodes?.map(item_ => {
                                        item_.clientBonCommandes = [bc]
                                        return item_
                                   })
                              }
                         })
                         return prev
                    }
                    return prev
               }
          )

          // Set état
          queryClient.setQueryData<IGetDetailsPropalRequestModel | undefined>(REACT_QUERY_KEY_GET_PTF_DETAILS, (prev: IGetDetailsPropalRequestModel | undefined) => {
               if (prev) {
                    return {
                         ...prev,
                         ptf: { ...prev.ptf, etat: "ETAT_VALIDEE", etatLabel: "Validé" },
                    }
               }
               return prev
          })

          setValidationAffaireParClientInModal(undefined)
     }

     // Actions icons (archiver, rejeter, passer en prod ...)
     const ActionsIcons = () => {
          if (!detailsQuery.data) return <></>
          return (
               <div className={"d-flex justify-content-center"}>
                    {detailsQuery.data.ptf.etat === "ETAT_EN_COURS" && (
                         <>
                              <div>
                                   <MyTooltip title={"J'ai remporté cette affaire"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => setValidationAffaireParClientInModal(detailsQuery.data.ptf)}
                                        >
                                             <i className="fas fa-handshake fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                              <div className={"ms-2"}>
                                   <MyTooltip title={"J'ai perdu cette affaire"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => handleSetPropalState("ETAT_REFUSEE")}
                                        >
                                             <i className="fas fa-sad-tear fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </>
                    )}

                    {detailsQuery.data.ptf.type === "TYPE_FORFAIT" && detailsQuery.data.ptf.etat === "ETAT_VALIDEE" && (
                         <div className={"ms-2"}>
                              <MyTooltip title={"Démarrage du projet et passage en production"}>
                                   <button
                                        type={"button"}
                                        className={`btn btn-outline btn-outline-info btn-sm btn-icon rounded-circle mb-2`}
                                        onClick={() => handleSetPropalState("ETAT_EN_PROD")}
                                   >
                                        <i className="fas fa-hard-hat fs-4" />
                                   </button>
                              </MyTooltip>
                         </div>
                    )}

                    {detailsQuery.data.ptf.type === "TYPE_FORFAIT" &&
                         ["ETAT_VALIDEE", "ETAT_EN_PROD", "ETAT_PARTIELLEMENT_LIVRE"].includes(detailsQuery.data.ptf.etat as string) && (
                              <div className={"ms-2"}>
                                   <MyTooltip title={"Le projet a été livré dans son intégralité"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => handleSetPropalState("ETAT_LIVRE")}
                                        >
                                             <i className="fas fa-check fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                         )}

                    {detailsQuery.data.ptf.etat !== "ETAT_DISABLED" && (
                         <>
                              <div className={"ms-2"}>
                                   <MyTooltip title={"Changer de responsable"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-primary btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => setChangementResponsableInModal(detailsQuery.data.ptf)}
                                        >
                                             <i className="fas fa-user-pen fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                              <div className={"ms-2"}>
                                   <MyTooltip title={"Archiver"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => handleSetPropalState("ETAT_DISABLED")}
                                        >
                                             <i className="fas fa-archive fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </>
                    )}
               </div>
          )
     }

     // Référence + badge
     const CardHeaderFormDetails = () => {
          if (!detailsQuery.data) return <></>

          const BadgeMere = () => {
               const tooltipTitle =
                    detailsQuery.data.propositionsEnfant.length > 0 ? (
                         <div>
                              <p className={"fw-bold"}>Enfants</p>
                              {detailsQuery.data.propositionsEnfant.map((item_enfant, index) => (
                                   <Link to={`/client/ptf/${item_enfant.id}/details`} className={"d-flex"} key={index} target={"_blank"}>
                                        {item_enfant.reference}
                                   </Link>
                              ))}
                         </div>
                    ) : (
                         "Aucun enfant"
                    )
               return (
                    <div>
                         <MyTooltip title={<div className={"d-flex justify-content-center flex-column"}>{tooltipTitle}</div>} placement={"bottom"} trigger={"click"}>
                              <span className={"badge badge-primary fs-9 ms-1 cursor-pointer"}>Mère</span>
                         </MyTooltip>
                    </div>
               )
          }

          const BadgeEnfant = () => {
               const tooltipTitle = detailsQuery.data.propositionMere ? <></> : "Sans PTF mère"
               return (
                    <div>
                         <span className={"badge badge-primary fs-9 ms-1 cursor-pointer not-concerned-by-preview"}>
                              {detailsQuery.data.propositionMere ? (
                                   <>
                                        Hérite de la{" "}
                                        <Link
                                             to={`/client/ptf/${detailsQuery.data.propositionMere?.id}/details`}
                                             target={"_blank"}
                                             className={"ms-1 text-white text-decoration-underline"}
                                        >
                                             {detailsQuery.data.propositionMere?.reference}
                                        </Link>
                                   </>
                              ) : (
                                   "Sans PTF mère"
                              )}
                         </span>
                    </div>
               )
          }

          const BadgeEtat = () => {
               return (
                    <div className={"ms-1"}>
                         {detailsQuery.data.ptf.etat === "ETAT_EN_COURS" && <div className={`badge badge-warning fs-9`}>{detailsQuery.data.ptf.etatLabel}</div>}
                         {detailsQuery.data.ptf.etat === "ETAT_VALIDEE" && <div className={`badge badge-success fs-9`}>{detailsQuery.data.ptf.etatLabel}</div>}
                         {detailsQuery.data.ptf.etat === "ETAT_REFUSEE" && <div className={`badge badge-danger fs-9`}>{detailsQuery.data.ptf.etatLabel}</div>}
                         {detailsQuery.data.ptf.etat === "ETAT_DISABLED" && <div className={`badge badge-secondary fs-9`}>{detailsQuery.data.ptf.etatLabel}</div>}
                         {detailsQuery.data.ptf.etat === "ETAT_EN_PROD" && <div className={`badge badge-primary fs-9`}>{detailsQuery.data.ptf.etatLabel}</div>}
                         {detailsQuery.data.ptf.etat === "ETAT_PARTIELLEMENT_LIVRE" && <div className={`badge badge-info fs-9`}>{detailsQuery.data.ptf.etatLabel}</div>}
                         {detailsQuery.data.ptf.etat === "ETAT_LIVRE" && <div className={`badge badge-success fs-9`}>{detailsQuery.data.ptf.etatLabel}</div>}
                    </div>
               )
          }

          return (
               <div className={"d-flex align-items-center"}>
                    {detailsQuery.data.ptf.reference} <BadgeEtat />
                    {detailsQuery.data.ptf.categorie === "CATEGORIE_FILLE" && <BadgeEnfant />}
                    {detailsQuery.data.ptf.categorie === "CATEGORIE_MERE" && <BadgeMere />}
               </div>
          )
     }

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-65"}>
                    {detailsQuery.isFetching && (
                         <div className={"text-center p-5"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {!detailsQuery.isFetching && detailsQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}

                    {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                         <>
                              {/* Form set details */}
                              <MyCard classNames={"mb-4"}>
                                   <MyCard.Header isCollapseIconShown>
                                        <CardHeaderFormDetails />
                                   </MyCard.Header>
                                   <MyCard.Body>
                                        <ActionsIcons />
                                        <div className="separator separator-dotted mb-4"></div>
                                        <PtfDetailsFormSetDetails ptf_details={detailsQuery.data} />
                                   </MyCard.Body>
                              </MyCard>

                              {/* Consultants attachés */}
                              <MyCard classNames={"mb-4"}>
                                   <MyCard.Header isCollapseIconShown>Consultants attachés à ce jour</MyCard.Header>
                                   <MyCard.Body>
                                        <PtfDetailsConsultantsAttachesToday consultants={detailsQuery.data.consultantsAttachesTodayToPtf} />
                                   </MyCard.Body>
                              </MyCard>

                              {/* Périodes, versions, BC, BL & livrables */}
                              <MyCard classNames={"mb-4"}>
                                   <MyCard.Header isCollapseIconShown>Périodes, versions, BC, BL & livrables</MyCard.Header>
                                   <MyCard.Body>
                                        <>
                                             {detailsQuery.data.ptf.categorie !== "CATEGORIE_FILLE" && (
                                                  <PtfDetailsPeriodesBonsCommandeBonsLivraison
                                                       pFilters={{ ptfs: [ptf_ID] }}
                                                       handleSubmitAddBonLivraisonCallback={handleSubmitAddBonLivraisonCallback}
                                                  />
                                             )}
                                             {detailsQuery.data.ptf.categorie === "CATEGORIE_FILLE" && (
                                                  <MyAlert type={"info"}>
                                                       <>
                                                            Veuillez vous référer à la{" "}
                                                            <a href={`/client/ptf/${detailsQuery.data.propositionMere?.id}/details`}>
                                                                 {detailsQuery.data.propositionMere?.reference}
                                                            </a>{" "}
                                                            ( PTF mère ) pour gérer les bons de commande et bons de livraison.
                                                       </>
                                                  </MyAlert>
                                             )}
                                        </>
                                   </MyCard.Body>
                              </MyCard>

                              {/* CDC */}
                              <MyCard>
                                   <MyCard.Header isCollapseIconShown>Cahiers des charges</MyCard.Header>
                                   <MyCard.Body>
                                        {detailsQuery.data.ptf.categorie !== "CATEGORIE_FILLE" && <PtfDetailsCdc ptf_ID={detailsQuery.data.ptf.id as number} />}

                                        {detailsQuery.data.ptf.categorie === "CATEGORIE_FILLE" && (
                                             <MyAlert type={"info"}>
                                                  <>
                                                       Veuillez vous référer à la{" "}
                                                       <a href={`/client/ptf/${detailsQuery.data.propositionMere?.id}/details`}>{detailsQuery.data.propositionMere?.reference}</a> (
                                                       PTF mère ) pour les gérer les cahiers des charges.
                                                  </>
                                             </MyAlert>
                                        )}
                                   </MyCard.Body>
                              </MyCard>
                         </>
                    )}
               </div>

               {/* Modal: changement responsable */}
               {changementResponsableInModal && (
                    <MyModal title={<span>Changement de responsable</span>} show={true} handleClose={() => setChangementResponsableInModal(undefined)} size={"lg"}>
                         <PtfsChangementResponsableForm ptfs={[changementResponsableInModal]} onSubmitCallBack={handleSubmitChangementResponsableCallback} />
                    </MyModal>
               )}

               {/* Modal: validation affaire par client*/}
               {validationAffaireParClientInModal && (
                    <MyModal title={<span>Victoire!</span>} show={true} handleClose={() => setValidationAffaireParClientInModal(undefined)} size={"lg"}>
                         <PtfValidationAffaireForm ptf={validationAffaireParClientInModal} onSubmitCallBack={handleSubmitAcceptationAffaireParClientCallback} />
                    </MyModal>
               )}
          </div>
     )
}

export default PtfDetails
