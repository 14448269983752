import React, { useState } from "react"
import MyAlert from "@common-utils/MyAlert"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyCard from "@common-utils/MyCard"
import { toast } from "react-toastify"
import { CONST_DEFAULT_TOAST_OPTIONS } from "@common-constants/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyPagination from "@common-utils/MyPagination"
import { useLogsSearchCandidateListeContext } from "./LogsSearchCandidate"
import MyModal from "@common-utils/MyModal"
import DetailFiltersRechercheCandidat from "../../DetailFiltersRechercheCandidat"
import MyTooltip from "@common-utils/MyTooltip"
import { ILogsSearchCandidateDataItemResponseModel } from "./core/_models"
import { ILogOrSavedFiltersSearchCandidateModel, IOneSavedFiltersOrOneLogSearchCandidateResponseModel } from "../../core/_models"
import { getOneSavedFiltersOrOneLogSearchCandidate } from "../../core/_requests"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { Link } from "react-router-dom"

// View log detail
const ModalViewLogDetail = ({ log, handleClose }: { log: IOneSavedFiltersOrOneLogSearchCandidateResponseModel; handleClose: () => void }) => {
     // IOneSavedFiltersOrOneLogSearchCandidateResponseModel --> ILogOrSavedFiltersSearchCandidateModel
     const detail: ILogOrSavedFiltersSearchCandidateModel = {
          id: log.id,
          searchKeywords: log.searchKeywords,
          searchScope: log.searchScope,
          searchScopeLabel: log.searchScopeLabel,
          searchOperateurLogiqueOnlyScopeCurriculum: log.searchOperateurLogiqueOnlyScopeCurriculum,
          searchOperateurLogiqueOnlyScopeCurriculumLabel: log.searchOperateurLogiqueOnlyScopeCurriculumLabel,
          disponibilite: log.disponibilite,
          disponibiliteLabel: log.disponibiliteLabel,
          disponibiliteDate: log.disponibiliteDate,
          mobilite: log.mobilite,
          mobiliteLabel: log.mobiliteLabel,
          mobiliteRegions: log.mobiliteRegions,
          mobiliteDepartements: log.mobiliteDepartements,
          mobiliteVilles: log.mobiliteVilles,
          niveauEtudes: log.niveauEtudes,
          niveauEtudesLabel: log.niveauEtudesLabel,
          fraicheurCV: log.fraicheurCV,
          fraicheurCVLabel: log.fraicheurCVLabel,
          viviers: log.viviers,
          candidatType: log.candidatType,
          candidatTypeLabel: log.candidatTypeLabel,
          personnelsAyantSaisiLeCandidat: log.personnelsAyantSaisiLeCandidat,
          secteursActivite: log.secteursActivite,
          metiers: log.metiers,
          seenOrNot: log.seenOrNot,
          seenOrNotLabel: log.seenOrNotLabel,
          experienceMin: log.experienceMin,
          experienceMinLabel: log.experienceMinLabel,
          // salaireNetMensuelSouhaiteMin: log.salaireNetMensuelSouhaiteMin,
          // salaireNetMensuelSouhaiteMax: log.salaireNetMensuelSouhaiteMax,
          avecSuivi: log.avecSuivi,
          avecSuiviLabel: log.avecSuiviLabel,
          typeHistoriqueOuSauvegarde: log.typeHistoriqueOuSauvegarde,
          typeHistoriqueOuSauvegardeLabel: log.typeHistoriqueOuSauvegardeLabel,
          titreSauvegarde: log.titreSauvegarde,
          dateCreation: log.dateCreation,
     }

     return (
          <MyModal title={<span>Recherche du {log.dateCreation?.format("dddd Do MMM YYYY à HH[h]mm")}</span>} show={true} size={"lg"} handleClose={() => handleClose()}>
               <>
                    <div className={"mb-2"}>
                         <DetailFiltersRechercheCandidat detail={detail} />
                    </div>

                    <div className={"text-end"}>
                         <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"button"}>
                              Lancer cette recherche <i className={"fas fa-search ms-1"} />
                         </button>
                    </div>
               </>
          </MyModal>
     )
}

// List
const LogsSearchCandidateList = () => {
     const context = useLogsSearchCandidateListeContext()

     const [logSearchCandidateInModal, setLogSearchCandidateInModal] = useState<{
          loading: boolean
          id: number | null
          data: IOneSavedFiltersOrOneLogSearchCandidateResponseModel | null
     }>({
          loading: false,
          id: null,
          data: null,
     })

     // Retrieves log details and displays it within a modal
     function handleRetrieveLogDetail(id: number) {
          setLogSearchCandidateInModal(prev => ({
               ...prev,
               loading: true,
               id,
          }))
          getOneSavedFiltersOrOneLogSearchCandidate(id)
               .then(r => {
                    setLogSearchCandidateInModal(prev => ({
                         ...prev,
                         data: r.data,
                         loading: false,
                    }))
               })
               .catch(() => {
                    toast.error("Echec lors de la récupération des détails.", {
                         ...CONST_DEFAULT_TOAST_OPTIONS,
                         autoClose: false,
                    })
               })
     }

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <>
                              {context.useLogsQuery.isFetching ? (
                                   <MySimpleSpinner size={"sm"} />
                              ) : (
                                   <>
                                        {context.useLogsQuery.data && context.useLogsQuery.data.totalElementsInPage > 0 && (
                                             <div className={"d-flex align-items-center"}>
                                                  <MyPagination
                                                       page={context.filters.page}
                                                       pageStart={context.useLogsQuery.data.pageStart}
                                                       pageEnd={context.useLogsQuery.data.pageEnd}
                                                       totalPages={context.useLogsQuery.data.totalPages}
                                                       totalElementsInAllPages={context.useLogsQuery.data.totalElementsInAllPages}
                                                       onPreviousPageCallBack={() => {
                                                            context.setFilters(prev => ({
                                                                 ...prev,
                                                                 page: prev.page - 1,
                                                            }))
                                                       }}
                                                       onNextPageCallBack={() => {
                                                            context.setFilters(prev => ({
                                                                 ...prev,
                                                                 page: prev.page + 1,
                                                            }))
                                                       }}
                                                  />
                                             </div>
                                        )}
                                   </>
                              )}
                         </>
                    }
               >
                    Historique
               </MyCard.Header>

               <MyCard.Body
                    noPadding={true}
                    classNames={"hover-scroll-overlay-y"}
                    styles={{
                         maxHeight: "65vh",
                    }}
               >
                    <>
                         {/* Fetching */}
                         {context.useLogsQuery.isFetching ? (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner classNames={"ms-2"} />
                              </div>
                         ) : (
                              <>
                                   {/* In case of error */}
                                   {context.useLogsQuery.error ? (
                                        <MyAlert type={"danger"}>{context.useLogsQuery.error.response?.data.detail}</MyAlert>
                                   ) : (
                                        <>
                                             {/* If 0 data */}
                                             {context.useLogsQuery.data && context.useLogsQuery.data.data.length === 0 && (
                                                  <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>
                                             )}

                                             {/* If some data */}
                                             {context.useLogsQuery.data && context.useLogsQuery.data.data.length > 0 && (
                                                  <div>
                                                       {context.useLogsQuery.data.data.map((item: ILogsSearchCandidateDataItemResponseModel) => (
                                                            <div className="border border-bottom" key={item.id}>
                                                                 <div className={"d-flex align-items-center justify-content-between p-4"}>
                                                                      <div className={"d-flex align-items-center"}>
                                                                           <MyTooltip title={<>{item.responsable.prenomNom}</>}>
                                                                                <img
                                                                                     src={
                                                                                          item.responsable.avatarGoogleDriveId
                                                                                               ? FileAndDriveHelper.getThumbnailDriveUrl(item.responsable.avatarGoogleDriveId)
                                                                                               : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                                                                     }
                                                                                     className="rounded-circle me-4"
                                                                                     style={{ width: "50px" }}
                                                                                     alt="Avatar"
                                                                                />
                                                                           </MyTooltip>
                                                                           <span className={"fs-4"}>
                                                                                {GlobalHelper.firstLetterUppercase(item.dateCreation.format("dddd Do MMM YYYY à HH[h]mm"))}
                                                                           </span>
                                                                      </div>
                                                                      <div className={"d-flex align-items-center"}>
                                                                           <button
                                                                                className={"btn btn-sm btn-outline btn-outline-dark me-2 p-0 py-2 px-3"}
                                                                                onClick={() => handleRetrieveLogDetail(item.id)}
                                                                                disabled={logSearchCandidateInModal.loading}
                                                                           >
                                                                                {logSearchCandidateInModal.id === item.id && logSearchCandidateInModal.loading ? (
                                                                                     <MySimpleSpinner size={"sm"} />
                                                                                ) : (
                                                                                     <i className={"fas fa-eye"} />
                                                                                )}
                                                                           </button>
                                                                           <Link
                                                                                to={"/recrutement/candidats/search/results"}
                                                                                state={{ log_or_saved_filters_id: item.id }}
                                                                                className={"btn btn-sm btn-outline btn-outline-primary me-2 p-0 py-2 px-3"}
                                                                           >
                                                                                <i className={"fas fa-search"} />
                                                                           </Link>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       ))}
                                                  </div>
                                             )}
                                        </>
                                   )}
                              </>
                         )}

                         {logSearchCandidateInModal.data && (
                              <ModalViewLogDetail
                                   log={logSearchCandidateInModal.data}
                                   handleClose={() =>
                                        setLogSearchCandidateInModal({
                                             loading: false,
                                             id: null,
                                             data: null,
                                        })
                                   }
                              />
                         )}
                    </>
               </MyCard.Body>
          </MyCard>
     )
}

export default LogsSearchCandidateList
