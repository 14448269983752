import React from "react"
import MyCard from "@common-utils/MyCard"
import { useConnectedUserDeclarationDetails } from "../ConnectedPersonnelDeclarationDetails"
import PersonnelCra from "../../../../declaration/cra/_PersonnelCra"
import { useQuery } from "react-query"
import { IPersonnelCraModel } from "@common-models/*"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import PersonnelTr from "../../../../declaration/tr/_PersonnelTr"
import Footer from "./_Footer"
import { getPersonnelDeclarationActiveNdfRequest } from "../../../../declaration/core/_requests"
import { PersonnelNdfDepenses } from "../../../../declaration/ndf/depenses/_PersonnelNdfDepenses"
import { PersonnelMaladieListingForOne } from "../../../../declaration/maladie/listing/listing-only-one/_PersonnelMaladieListingForOne"
import { useAuth } from "../../../../../../AppContext"
import PersonnelDdcListingForOne from "../../../../declaration/ddc/listing-one-personnel/PersonnelDdcListingForOne"

const urlParams = new URLSearchParams(window.location.search)
const REACT_QUERY_KEY_GET_ACTIVE_NDF = "REACT_QUERY_KEY_GET_ACTIVE_NDF"

const ConnectedPersonnelDeclarationDetails_recap = () => {
     const context = useConnectedUserDeclarationDetails()
     const { currentUser } = useAuth()

     const activeNdfQuery = useQuery<IPersonnelCraModel, AxiosError>(REACT_QUERY_KEY_GET_ACTIVE_NDF, () =>
          getPersonnelDeclarationActiveNdfRequest(context.data.declaration?.id as number).then(r => r.data)
     )

     return (
          <>
               {/* BEGIN: MALADIE */}
               <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                    <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                         <i className={"fas fa-file-medical fs-4 text-dark me-2"} /> Arrêts
                    </MyCard.Header>
                    <MyCard.Body>
                         {context.editionModeEnabled && (
                              <div className="text-center mb-8">
                                   <button
                                        className={"btn btn-sm btn-light-warning"}
                                        onClick={() => {
                                             context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                             context.setCurrentStepHeader("arrets")
                                        }}
                                   >
                                        Apporter une modification
                                   </button>
                              </div>
                         )}
                         <PersonnelMaladieListingForOne
                              personnel_ID={currentUser?.id as number}
                              filtres={{ duOrAuWithThisMonthAndYear: context.data.declaration?.mois?.startOf("month").format("YYYY-MM-DD") as string }}
                              editionMode={false}
                         />
                    </MyCard.Body>
               </MyCard>

               {/* END: MALADIE */}

               {/* BEGIN: DDC */}
               <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                    <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                         <i className={"fas fa-umbrella-beach fs-4 text-dark me-2"} /> Demandes de congés
                    </MyCard.Header>
                    <MyCard.Body>
                         {context.editionModeEnabled && (
                              <div className="text-center mb-8">
                                   <button
                                        className={"btn btn-sm btn-light-warning"}
                                        onClick={() => {
                                             context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                             context.setCurrentStepHeader("ddc")
                                        }}
                                   >
                                        Apporter une modification
                                   </button>
                              </div>
                         )}
                         <PersonnelDdcListingForOne
                              personnel_ID={currentUser?.id as number}
                              filtres={{ duOrAuWithThisMonthAndYear: context.data.declaration?.mois?.startOf("month").format("YYYY-MM-DD") as string }}
                              editionMode={false}
                         />
                    </MyCard.Body>
               </MyCard>

               {/* END: DDC */}

               {/* BEGIN: CRA */}
               <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                    <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                         <i className={"fas fa-calendar-days fs-4 text-dark me-2"} /> CRA
                    </MyCard.Header>
                    <MyCard.Body>
                         {context.editionModeEnabled && (
                              <div className="text-center mb-8">
                                   <button
                                        className={"btn btn-sm btn-light-warning"}
                                        onClick={() => {
                                             context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                             context.setCurrentStepHeader("cra")
                                        }}
                                   >
                                        Apporter une modification
                                   </button>
                              </div>
                         )}
                         <PersonnelCra declaration_ID={context.data.declaration?.id as number} editionModeEnabled={false} />
                    </MyCard.Body>
               </MyCard>

               {/* END: CRA */}

               {/* BEGIN: NDF */}
               <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                    <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                         <i className={"fas fa-file-invoice-dollar fs-4 text-dark me-2"} /> Note de frais
                    </MyCard.Header>
                    <MyCard.Body>
                         {context.editionModeEnabled && (
                              <div className="text-center mb-8">
                                   <button
                                        className={"btn btn-sm btn-light-warning"}
                                        onClick={() => {
                                             context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                             context.setCurrentStepHeader("ndf")
                                        }}
                                   >
                                        Apporter une modification
                                   </button>
                              </div>
                         )}
                         {/* loader */}
                         {activeNdfQuery.isFetching && (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner size={"xl"} />{" "}
                              </div>
                         )}

                         {/* error */}
                         {!activeNdfQuery.isFetching && activeNdfQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{activeNdfQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => activeNdfQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}

                         {!activeNdfQuery.isFetching && !activeNdfQuery.isError && (
                              <>
                                   {!activeNdfQuery.data && <MyAlert type={"primary"}>Aucune NDF n'a été déclarée</MyAlert>}

                                   {activeNdfQuery.data && (
                                        <>
                                             <PersonnelNdfDepenses ndfId={activeNdfQuery.data.id as number} editionMode={false} />
                                        </>
                                   )}
                              </>
                         )}
                    </MyCard.Body>
               </MyCard>

               {/* END: NDF */}

               {/* BEGIN: TR */}
               <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                    <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                         <i className={"fas fa-utensils fs-4 text-dark me-2"} /> Tickets restaurant
                    </MyCard.Header>
                    <MyCard.Body>
                         {context.editionModeEnabled && (
                              <div className="text-center mb-8">
                                   <button
                                        className={"btn btn-sm btn-light-warning"}
                                        onClick={() => {
                                             context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                             context.setCurrentStepHeader("ticket_resto")
                                        }}
                                   >
                                        Apporter une modification
                                   </button>
                              </div>
                         )}
                         <PersonnelTr declaration_ID={context.data.declaration?.id as number} editionModeEnabled={false} />
                    </MyCard.Body>
               </MyCard>

               {/* END: TR */}

               <div className="separator separator-dashed my-6" />

               <Footer />
          </>
     )
}

export default ConnectedPersonnelDeclarationDetails_recap
