import React, { useState } from "react"
import MyCard from "@common-utils/MyCard"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { IGetPersonnelsDeclarationsVoyantsResponseModel } from "./core/_models"
import { getPersonnelsDeclarationsVoyantsRequest } from "./core/_requests"
import { toast } from "react-toastify"
import MyAlert from "@common-utils/MyAlert"
import { Link, useNavigate } from "react-router-dom"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING } from "../../../routing/routing-endpoints"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyDateMonthYearField from "@common-utils/fields/MyDateMonthYearField"
import VoyantsMonthVueResp from "./_VoyantsMonthVueResp"
import { useAuth } from "../../../../../AppContext"
import VoyantsMonthVueSiege from "./_VoyantsMonthVueSiege"

const REACT_QUERY_KEY_GET_DECLARATIONS_VOYANTS = "REACT_QUERY_KEY_GET_DECLARATIONS_VOYANTS"
const Voyants = () => {
     const navigate = useNavigate()
     const { currentUser } = useAuth()
     const [date, setDate] = useState<string>()

     // Request voyants
     const declarationsVoyantsQuery = useQuery<IGetPersonnelsDeclarationsVoyantsResponseModel[], AxiosError>(REACT_QUERY_KEY_GET_DECLARATIONS_VOYANTS, () =>
          getPersonnelsDeclarationsVoyantsRequest()
               .then(r => r.data)
               .catch(e => {
                    toast.error(e.response?.data?.detail, { autoClose: false })
                    throw e
               })
     )

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={"w-100 w-lg-75 w-xxl-65"}>
                         <MyCard>
                              <MyCard.Header>Déclarations du personnel interne</MyCard.Header>
                              <MyCard.Body>
                                   <>
                                        {declarationsVoyantsQuery.isFetching && (
                                             <div className={"text-center p-5"}>
                                                  <MySimpleSpinner size={"xl"} />
                                             </div>
                                        )}

                                        {!declarationsVoyantsQuery.isFetching && declarationsVoyantsQuery.isError && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  <>
                                                       <span className={"me-2"}>{declarationsVoyantsQuery.error?.response?.data?.detail}</span>
                                                       <button className={"btn btn-sm btn-danger"} onClick={() => declarationsVoyantsQuery.refetch()}>
                                                            Recharger
                                                       </button>
                                                  </>
                                             </MyAlert>
                                        )}

                                        {/* Month cards */}
                                        {!declarationsVoyantsQuery.isFetching && !declarationsVoyantsQuery.isError && declarationsVoyantsQuery.data && (
                                             <>
                                                  <div className={"row"}>
                                                       {declarationsVoyantsQuery.data?.map((item, key) => (
                                                            <div className={"col-6 col-sm-3 col-md-3 mb-xl-0 mb-4"} key={key}>
                                                                 <MyCard>
                                                                      <MyCard.Header classNames={"d-flex justify-content-center"} isCollapseIconShown={false}>
                                                                           <span className={"fas fa-calendar-day me-2 fs-3"}></span>
                                                                           <Link
                                                                                to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                                                                                state={{
                                                                                     mois: item.mois.format("YYYY-MM-DD"),
                                                                                     vueSiegeOrResp: currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? "siege" : "resp",
                                                                                }}
                                                                                className={"text-dark text-hover-primary"}
                                                                           >
                                                                                {GlobalHelper.firstLetterUppercase(item.mois.format("MMM YYYY"))}
                                                                           </Link>
                                                                      </MyCard.Header>
                                                                      <MyCard.Body>
                                                                           {currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? (
                                                                                <VoyantsMonthVueSiege item={item.voyantsAdministratif} mois={item.mois} />
                                                                           ) : (
                                                                                <VoyantsMonthVueResp item={item.voyantsResponsableBm} mois={item.mois} />
                                                                           )}
                                                                      </MyCard.Body>
                                                                 </MyCard>
                                                            </div>
                                                       ))}
                                                  </div>

                                                  <div className="d-flex align-items-center my-5">
                                                       <div className="border-bottom border-secondary mw-50 w-100"></div>
                                                       <span className="fw-bold border-secondary fs-7 mx-2">OU</span>
                                                       <div className="border-bottom border-secondary mw-50 w-100"></div>
                                                  </div>

                                                  {/* Choose month */}
                                                  <div className="d-flex justify-content-center">
                                                       <div className={"w-100 w-sm-50 w-lg-30 d-flex"}>
                                                            <MyDateMonthYearField name={"date"} value={date} onChange={setDate} placeholder={"Choisissez un mois"} />
                                                            <button
                                                                 type="submit"
                                                                 className="btn btn-sm btn-outline btn-outline-dark ms-2"
                                                                 onClick={() => {
                                                                      if (!date || date === "") {
                                                                           toast.error("Veuillez saisir un mois")
                                                                      } else {
                                                                           navigate(ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING, {
                                                                                state: {
                                                                                     mois: date + "-01",
                                                                                     vueSiegeOrResp: currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? "siege" : "resp",
                                                                                },
                                                                           })
                                                                      }
                                                                 }}
                                                            >
                                                                 <i className="fas fa-search text-dark"></i>
                                                            </button>
                                                       </div>
                                                  </div>
                                             </>
                                        )}
                                   </>
                              </MyCard.Body>
                         </MyCard>
                    </div>
               </div>
          </>
     )
}

export default Voyants
