import React, { useState } from "react"
import { IGetPersonnelDdcRequestModel } from "../../core/_models"
import { usePersonnelDeclarationDetailsContext } from "./_PersonnelDeclarationDetails"
import PersonnelDdcListingForOne from "../ddc/listing-one-personnel/PersonnelDdcListingForOne"
import MyAlert from "@common-utils/MyAlert"
import { Link } from "react-router-dom"

export function DDC({ filtres }: { filtres: IGetPersonnelDdcRequestModel }) {
     const context = usePersonnelDeclarationDetailsContext()
     const [dataLoaded, setDataLoaded] = useState<{ isLoaded: boolean; totalData: number } | undefined>()

     const Footer = () => {
          return (
               <>
                    <div className="d-flex justify-content-between">
                         <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                   context.setNavigationStep("arrets")
                              }}
                         >
                              <i className={"fas fa-arrow-left me-1"} />
                              Arrêts
                         </button>

                         <div className={"d-flex"}>
                              {context.areActionsDemandeRectifOrValidationAvailable && (
                                   <button type="button" className="btn btn-sm btn-warning d-flex align-items-center me-2" onClick={() => context.handleDemandeRectification()}>
                                        <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                        <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                   </button>
                              )}
                              {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                                   <>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-success d-flex align-items-center me-2"
                                             onClick={() => context.handleValidationDemandeRegul()}
                                        >
                                             <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                             <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                             <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                        </button>
                                   </>
                              )}
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary"
                                   onClick={() => {
                                        context.setNavigationStep("cra")
                                   }}
                              >
                                   CRA
                                   <i className={"fas fa-arrow-right ms-1"} />
                              </button>
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               <div className="mb-4">
                    {dataLoaded && dataLoaded?.totalData > 0 && (
                         <MyAlert type={"primary"} classNames={"mb-4"}>
                              <>
                                   Les DDC en état <b className={"text-warning"}>"En cours"</b> seront automatiquement validées lors de la validation de cette déclaration. Si vous
                                   souhaitez rejeter une demande, veuillez cliquer sur le lien suivant :{" "}
                                   <Link to={"/dashboard/alertes/ddc-structure?etat=ETAT_EN_COURS"} target={"_blank"} className={"text-decoration-underline"}>
                                        GESTION DES DDC
                                   </Link>
                              </>
                         </MyAlert>
                    )}
                    <PersonnelDdcListingForOne personnel_ID={context.declaration.personnel?.id as number} editionMode={false} filtres={filtres} setDataLoaded={setDataLoaded} />
               </div>

               {dataLoaded?.isLoaded && <Footer />}
          </>
     )
}

export default DDC
