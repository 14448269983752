import React, { createContext, useContext, useEffect, useRef } from "react"
import { ISearchResultsDataItemResponseModel, ISearchResultsListContextPropsModel } from "../core/_models"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyPagination from "@common-utils/MyPagination"
import BulkSelectionContainerForListings from "../../../../../../utils/BulkSelectionContainerForListings"
import CandidatsRechercheResultsListItem from "./_CandidatsRechercheResultsListItem"
import { useSearchResultsContext } from "../CandidatsRechercheResults"

// List context
const SearchResultsListContext = createContext<ISearchResultsListContextPropsModel>({} as ISearchResultsListContextPropsModel)
export const useSearchResultsListContext = () => useContext(SearchResultsListContext)
const CandidatsRechercheResultsList = () => {
     const globalContext = useSearchResultsContext()
     const selectedItemRef = useRef<any>(null)

     // BULK SELECTION: handling the "check all elements" button click
     function handleCheckAllElementsInPageCallBack() {
          const ids = globalContext.useListingQuery.data!.data.map(item => item.candidat.id as number)
          globalContext.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               checkedElementsInPage: ids,
          }))
     }

     // BULK SELECTION: handling the "check all elements across all pages" button click
     function handleCheckAllElementsAcrossAllPagesCallBack() {
          globalContext.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               areAllElementsInAllPagesChecked: true,
          }))
     }

     // BULK SELECTION: handling the "Quit" button click
     function handleQuitBulkSelectionModeCallBack() {
          globalContext.setIsBulkSelectionEnabled({
               enabled: false,
               checkedElementsInPage: [],
               areAllElementsInAllPagesChecked: false,
          })
     }

     useEffect(() => {
          if (selectedItemRef.current) {
               if (window.innerWidth >= 1200) {
                    /**
                     * THIS BEHAVIOR IS ONLY APPLICABLE ON DISPLAYS OF SIZE XL OR LARGER (it will not work for mobile because we didn't find a solution yet)
                     * This will cause the list to scroll and bring the selected item into focus.
                     * For example, as you navigate from one item to another, reaching the last visible item in the list will trigger a downward scroll to reveal the next item.
                     */
                    selectedItemRef.current.scrollIntoView({
                         block: "nearest",
                         inline: "nearest",
                         behavior: "smooth",
                    })
               } else {
                    // On <= display, this will focus the preview container when an item is clicked
                    globalContext.previewContainerRef.current.scrollIntoView({
                         behavior: "smooth",
                    })
               }
          }
     }, [globalContext.itemInPreview])

     return (
          <SearchResultsListContext.Provider value={{ selectedItemRef }}>
               <MyCard>
                    <MyCard.Header
                         isCollapseIconShown={false}
                         rightSideContent={
                              <>
                                   {globalContext.useListingQuery.isFetching ? (
                                        <MySimpleSpinner size={"sm"} />
                                   ) : (
                                        <>
                                             {globalContext.useListingQuery.data && globalContext.useListingQuery.data.totalElementsInPage > 0 && (
                                                  <div className={"d-flex align-items-center"}>
                                                       {/* Pagination component*/}
                                                       <MyPagination
                                                            page={globalContext.filters.page as number}
                                                            pageStart={globalContext.useListingQuery.data.pageStart}
                                                            pageEnd={globalContext.useListingQuery.data.pageEnd}
                                                            totalPages={globalContext.useListingQuery.data.totalPages}
                                                            totalElementsInAllPages={globalContext.useListingQuery.data.totalElementsInAllPages}
                                                            onPreviousPageCallBack={() => {
                                                                 globalContext.setFilters(prev => ({
                                                                      ...prev,
                                                                      page: (prev.page as number) - 1,
                                                                 }))
                                                            }}
                                                            onNextPageCallBack={() => {
                                                                 globalContext.setFilters(prev => ({
                                                                      ...prev,
                                                                      page: (prev.page as number) + 1,
                                                                 }))
                                                            }}
                                                       />
                                                  </div>
                                             )}
                                        </>
                                   )}
                              </>
                         }
                    >
                         Candidats
                    </MyCard.Header>

                    <MyCard.Body noPadding={true}>
                         <div id={"list-items"}>
                              {/* Fetching */}
                              {globalContext.useListingQuery.isFetching ? (
                                   <div className={"text-center p-5"}>
                                        <MySimpleSpinner classNames={"ms-2"} />
                                   </div>
                              ) : (
                                   <>
                                        {/* In case of error */}
                                        {globalContext.useListingQuery.error ? (
                                             <MyAlert type={"danger"}>{globalContext.useListingQuery.error.response?.data.detail}</MyAlert>
                                        ) : (
                                             <>
                                                  {/* CASE: no data */}
                                                  {globalContext.useListingQuery.data && globalContext.useListingQuery.data.data.length === 0 && (
                                                       <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>
                                                  )}

                                                  {/* CASE: If some data */}
                                                  {globalContext.useListingQuery.data && globalContext.useListingQuery.data.data.length > 0 && (
                                                       <>
                                                            {/* Mass selection actions component */}
                                                            {globalContext.isBulkSelectionEnabled.enabled && (
                                                                 <BulkSelectionContainerForListings
                                                                      areAllElementsInAllPagesChecked={globalContext.isBulkSelectionEnabled.areAllElementsInAllPagesChecked}
                                                                      checkedElementIdsInPage={globalContext.isBulkSelectionEnabled.checkedElementsInPage}
                                                                      totalElementsInPage={globalContext.useListingQuery.data.totalElementsInPage}
                                                                      totalElementsInAllPages={globalContext.useListingQuery.data.totalElementsInAllPages}
                                                                      handleCheckAllElementsInPageCallBack={handleCheckAllElementsInPageCallBack}
                                                                      handleCheckAllElementsAcrossAllPagesCallBack={handleCheckAllElementsAcrossAllPagesCallBack}
                                                                      handleQuitBulkSelectionModeCallBack={handleQuitBulkSelectionModeCallBack}
                                                                      AllElementsCheckedAcrossAllPagesActionsChildren={<></>}
                                                                      AllOrFewElementsCheckedInCurrentPageActionsChildren={<></>}
                                                                 />
                                                            )}
                                                            {/* List elements */}
                                                            <div
                                                                 // className={"hover-scroll-overlay-y"}
                                                                 style={{
                                                                      maxHeight: globalContext.isBulkSelectionEnabled.enabled ? "59vh" : "68vh",
                                                                      overflowY: "auto",
                                                                 }}
                                                            >
                                                                 {globalContext.useListingQuery.data.data.map((item: ISearchResultsDataItemResponseModel, index) => (
                                                                      <div className="border border-bottom" key={item.candidat.id}>
                                                                           <CandidatsRechercheResultsListItem item={item} index={index} />
                                                                      </div>
                                                                 ))}
                                                            </div>
                                                       </>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}
                         </div>
                    </MyCard.Body>
               </MyCard>
          </SearchResultsListContext.Provider>
     )
}

export default CandidatsRechercheResultsList
