import React from "react"

interface Props {
     name: string
     value: boolean
     isInvalid: boolean
     className?: string
     onChange: (val) => void
     classNames?: string
}

const MyCheckBoxField: React.FC<Props> = ({ name, className = "", value, onChange, isInvalid, classNames = "" }) => {
     return (
          <div className="form-check form-check-custom form-check-solid">
               <input
                    type="checkbox"
                    name={name}
                    onChange={() => {
                         if (value === undefined || !value) onChange(true)
                         if (value) onChange(false)
                    }}
                    checked={value}
                    className={`form-check-input cursor-pointer border border-secondary ${className} ${isInvalid ? "is-invalid" : ""} ${classNames}`}
               />
          </div>
     )
}

export default MyCheckBoxField
