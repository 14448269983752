import React, { createContext, useContext, useRef } from "react"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyPagination from "@common-utils/MyPagination"
import { IConsultantsSuivisListingListContextPropsModel } from "./core/_models"
import { useConsultantsSuivisListingContext } from "./ConsultantsSuivisListing"
import _ConsultantsSuivisListingListItem from "./_ConsultantsSuivisListingListItem"
import { IConsultantSuiviGeneralModel } from "@common-models/*"

const ConsultantsSuivisListingListContext = createContext<IConsultantsSuivisListingListContextPropsModel>({} as IConsultantsSuivisListingListContextPropsModel)
export const useConsultantsSuivisListingListContext = () => useContext(ConsultantsSuivisListingListContext)
const ConsultantsSuivisListingList = () => {
     const context = useConsultantsSuivisListingContext()
     const selectedItemRef = useRef<any>(null)

     const CardHeaderRightSide = () => {
          return (
               <>
                    {context.listingQuery.isFetching ? (
                         <MySimpleSpinner size={"sm"} />
                    ) : (
                         <>
                              {context.listingQuery.data && context.listingQuery.data.totalElementsInPage > 0 && (
                                   <div className={"d-flex align-items-center"}>
                                        {/* Pagination component*/}
                                        <MyPagination
                                             page={context.filters.page as number}
                                             pageStart={context.listingQuery.data.pageStart}
                                             pageEnd={context.listingQuery.data.pageEnd}
                                             totalPages={context.listingQuery.data.totalPages}
                                             totalElementsInAllPages={context.listingQuery.data.totalElementsInAllPages}
                                             onPreviousPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) - 1,
                                                  }))
                                             }}
                                             onNextPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) + 1,
                                                  }))
                                             }}
                                        />
                                   </div>
                              )}
                         </>
                    )}
               </>
          )
     }

     return (
          <ConsultantsSuivisListingListContext.Provider value={{ selectedItemRef }}>
               <MyCard>
                    {(context.isPaginationVisible || context.isTitleVisible) && (
                         <MyCard.Header isCollapseIconShown={false} rightSideContent={context.isPaginationVisible ? <CardHeaderRightSide /> : <></>}>
                              {context.isTitleVisible && "Suivis"}
                         </MyCard.Header>
                    )}

                    <MyCard.Body noPadding={true}>
                         <>
                              {/* Fetching */}
                              {context.listingQuery.isFetching ? (
                                   <div className={"text-center p-5"}>
                                        <MySimpleSpinner classNames={"ms-2"} />
                                   </div>
                              ) : (
                                   <>
                                        {/* In case of error */}
                                        {context.listingQuery.error ? (
                                             <MyAlert type={"danger"}>{context.listingQuery.error.response?.data.detail}</MyAlert>
                                        ) : (
                                             <>
                                                  {/* CASE: no data */}
                                                  {context.listingQuery.data && context.listingQuery.data.data.length === 0 && (
                                                       <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>
                                                  )}

                                                  {/* CASE: If some data */}
                                                  {context.listingQuery.data && context.listingQuery.data.data.length > 0 && (
                                                       <>
                                                            {/* List elements */}
                                                            <div
                                                                 style={{
                                                                      maxHeight: "68vh",
                                                                      overflowY: "auto",
                                                                 }}
                                                            >
                                                                 <table className={`table table-rounded gy-7 gs-7 m-0 p-0`}>
                                                                      <thead className={`sticky-top bg-light border-bottom border-secondary`} style={{ opacity: 1, zIndex: 990 }}>
                                                                           <tr className={"align-middle"}>
                                                                                <td>Date suivi</td>
                                                                                <td>Date saisie</td>
                                                                                <td>Type</td>
                                                                                <td>Consultant</td>
                                                                                <td>Responsable</td>
                                                                                <td>Commentaire</td>
                                                                                <td>Documents</td>
                                                                           </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                           {context.listingQuery.data.data.map((item: IConsultantSuiviGeneralModel, key) => (
                                                                                <_ConsultantsSuivisListingListItem item={item} key={key} />
                                                                           ))}
                                                                      </tbody>
                                                                 </table>
                                                            </div>
                                                       </>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}
                         </>
                    </MyCard.Body>

                    {/*<MyCard.Body noPadding={true}>*/}
                    {/*     <>*/}
                    {/*           Fetching */}
                    {/*          {context.listingQuery.isFetching ? (*/}
                    {/*               <div className={"text-center p-5"}>*/}
                    {/*                    <MySimpleSpinner classNames={"ms-2"} />*/}
                    {/*               </div>*/}
                    {/*          ) : (*/}
                    {/*               <table>*/}
                    {/*                     In case of error */}
                    {/*                    {context.listingQuery.error ? (*/}
                    {/*                         <MyAlert type={"danger"}>{context.listingQuery.error.response?.data.detail}</MyAlert>*/}
                    {/*                    ) : (*/}
                    {/*                         <>*/}
                    {/*                               CASE: no data */}
                    {/*                              {context.listingQuery.data && context.listingQuery.data.data.length === 0 && (*/}
                    {/*                                   <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>*/}
                    {/*                              )}*/}

                    {/*                               CASE: If some data */}
                    {/*                              {context.listingQuery.data && context.listingQuery.data.data.length > 0 && (*/}
                    {/*                                   <>*/}
                    {/*                                         List elements */}
                    {/*                                        <div*/}
                    {/*                                             // className={"hover-scroll-overlay-y"}*/}
                    {/*                                             style={{*/}
                    {/*                                                  maxHeight: context.isBulkSelectionEnabled.enabled ? "59vh" : "68vh",*/}
                    {/*                                                  overflowY: "auto",*/}
                    {/*                                             }}*/}
                    {/*                                        >*/}
                    {/*                                             {context.listingQuery.data.data.map((item: IConsultantSuiviGeneralModel, index) => (*/}
                    {/*                                                  <div className="border border-bottom" key={item.consultant?.id}>*/}
                    {/*                                                       <_ConsultantsSuivisListingListItem item={item} index={index} />*/}
                    {/*                                                  </div>*/}
                    {/*                                             ))}*/}
                    {/*                                        </div>*/}
                    {/*                                   </>*/}
                    {/*                              )}*/}
                    {/*                         </>*/}
                    {/*                    )}*/}
                    {/*               </table>*/}
                    {/*          )}*/}
                    {/*     </>*/}
                    {/*</MyCard.Body>*/}
               </MyCard>
          </ConsultantsSuivisListingListContext.Provider>
     )
}

export default ConsultantsSuivisListingList
