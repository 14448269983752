import React from "react"

interface Props {
     name: string
     value: any
     isInvalid: boolean
     className?: string
     placeholder?: string
     onChange: (val) => void
     classNames?: string
     isChecked: boolean
}

const MyRadioField: React.FC<Props> = ({ name, className = "", value, onChange, placeholder, isInvalid, classNames = "", isChecked }) => {
     return (
          <span className="form-check form-check-custom form-check-solid">
               <input
                    type="radio"
                    name={name}
                    placeholder={placeholder}
                    onChange={e => onChange(e.target.value)}
                    value={value === null || value === undefined ? "" : value}
                    checked={isChecked}
                    className={`form-check-input border border-secondary ${className} ${isInvalid ? "is-invalid" : ""} ${classNames}`}
               />
          </span>
     )
}

export default MyRadioField
