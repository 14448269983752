import PersonnelDdcListingMultiplePersonnel from "../../../personnel/declaration/ddc/listing-multiple-personnel/PersonnelDdcListingMultiplePersonnel"

const DashboardAlertesDdcProductionListing = () => {
     return (
          <>
               <PersonnelDdcListingMultiplePersonnel title={"Alertes DDC"} isFilterIconVisible={false} isResetIconVisible={false} />
          </>
     )
}

export default DashboardAlertesDdcProductionListing
