import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "@common-utils/MyAlert"
import MySelectField from "@common-utils/fields/MySelectField"
import {
     CONST_CANDIDAT_EXPERIENCES_OPTIONS_FOR_SELECT2,
     CONST_CANDIDAT_TYPE_SAISI,
     CONST_CANDIDAT_TYPES_FOR_SELECT2,
     CONST_DISPONIBILITE_DATE_EXACTE,
     CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
     CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEURS_CV_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORD_TARGETS_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_CV,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_NOM_MAIL_TEL,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEUR_LOGIQUE_OU,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEURS_LOGIQUE_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVIS_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATIONS_OPTIONS_FOR_SELECT2,
} from "@common-constants/*"
import MySelectCreatableField from "@common-utils/fields/MySelectCreatableField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import {
     CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT,
     CONST_API_UTILS_GET_BUSINESS_UNITS_ENDPOINT,
     CONST_API_UTILS_GET_CANDIDATES_URL_ENDPOINT,
     CONST_API_UTILS_GET_DEPARTEMENTS_URL_ENDPOINT,
     CONST_API_UTILS_GET_METIERS_URL_ENDPOINT,
     CONST_API_UTILS_GET_OFFERS_ENDPOINT,
     CONST_API_UTILS_GET_REGIONS_URL_ENDPOINT,
     CONST_API_UTILS_GET_VILLES_URL_ENDPOINT,
     CONST_API_UTILS_GET_VIVIERS_ENDPOINT,
} from "../../../../endpoints"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import MyInputField from "@common-utils/fields/MyInputField"
import { toast } from "react-toastify"
import { IHttpErrorResponseModel } from "@common-models/*"
import { FormikProps } from "formik/dist/types"
import useSwal from "@common-hooks/useSwal"
import { AxiosError } from "axios"
import { ILogOrSavedFiltersSearchCandidateModel, ISearchCandidatesFiltersModel } from "./core/_models"
import { createSavedFiltersSearchCandidate, updateSavedFiltersSearchCandidate } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"

interface IFormRechercheCandidatProps {
     pInialValues?: ILogOrSavedFiltersSearchCandidateModel
     handleSubmitCallback: (values: ISearchCandidatesFiltersModel, helpers: FormikHelpers<ISearchCandidatesFiltersModel>) => void
     handleChangeKeywordsField?: (value) => void
     callBackOnReset?: () => void
     handleSaveFiltersCallback?: () => void
     formRef?: MutableRefObject<any>
}

const FormRechercheCandidat = ({
     pInialValues = {},
     handleSubmitCallback,
     callBackOnReset = () => {},
     handleChangeKeywordsField = (value: string[]) => {},
     handleSaveFiltersCallback = () => {},
     formRef,
}: IFormRechercheCandidatProps) => {
     const { swal } = useSwal()
     const moreFiltersDivScrollTo = useRef<HTMLDivElement | null>(null)
     const lessFiltersDivScrollTo = useRef<HTMLDivElement | null>(null)
     const [moreFiltersVisible, setMoreFiltersVisible] = useState<boolean>(false)
     const [isSavingFilters, setIsSavingFilters] = useState<boolean>(false)

     // Scroll to "more filters" hidden container. We need to use the "scrollIntoView" in useEffect otherwise it will not work.
     useEffect(() => {
          if (moreFiltersVisible) {
               moreFiltersDivScrollTo.current?.scrollIntoView({ behavior: "smooth" })
          } else {
               lessFiltersDivScrollTo.current?.scrollIntoView({ behavior: "smooth" })
          }
     }, [moreFiltersVisible])

     // Validation schema
     const schema = Yup.object().shape({
          searchKeywords: Yup.array().nullable().label(`"Mots clé"`),
          searchScope: Yup.string()
               .when("searchKeywords", {
                    is: val => val && val.length > 0,
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Périmètre de recherche"`),
          searchOperateurLogiqueOnlyScopeCurriculum: Yup.string()
               .when("searchScope", {
                    is: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_CV,
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Opérateur logique"`),
          disponibilite: Yup.string().nullable().label(`"Disponibilité"`),
          disponibiliteDate: Yup.string()
               .when("disponibilite", {
                    is: CONST_DISPONIBILITE_DATE_EXACTE,
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Date de disponibilité"`),
          mobilite: Yup.string().nullable().label(`"Mobilité"`),
          mobiliteRegions: Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_REGIONAL,
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Régions"`),
          mobiliteDepartements: Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_DEPARTEMENTAL,
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Départements"`),
          mobiliteVilles: Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_PAR_VILLE,
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Villes"`),
          niveauEtudes: Yup.string().nullable().label(`"Niveau d'études"`),
          fraicheurCV: Yup.string().nullable().label(`Fraîcheur du CV`),
          viviers: Yup.array().nullable().label(`viviers`),
          annonces: Yup.array().nullable().label(`annonces`),
          candidatType: Yup.string().nullable().label(`"Type de candidat"`),
          personnelsAyantSaisiLeCandidat: Yup.array()
               .when("candidatType", {
                    is: CONST_CANDIDAT_TYPE_SAISI,
                    then: schema => schema.required(),
               })
               .nullable()
               .label(`"Personnes ayant saisi le candidat"`),
          secteursActivite: Yup.array().nullable().label(`"Secteurs d'activité"`),
          metiers: Yup.array().nullable().label(`"Métiers"`),
          seenOrNot: Yup.string().nullable().label(`"Vu"`),
          experienceMin: Yup.string().nullable().label(`"Expérience min."`),
          // salaireNetMensuelSouhaiteMin: Yup.number()
          //      .test("salaireMinMax", function (min) {
          //           const max = this.parent.salaireNetMensuelSouhaiteMax
          //           if (min === undefined || max === undefined || min === null || max === null) return true // No need to test
          //           return min < max
          //      })
          //      .nullable()
          //      .label(`"Salaire min."`),
          // salaireNetMensuelSouhaiteMax: Yup.number()
          //      .test("salaireMinMax", function (max) {
          //           const min = this.parent.salaireNetMensuelSouhaiteMin
          //           if (min === undefined || max === undefined || min === null || max === null) return true // No need to test
          //           return max > min
          //      })
          //      .nullable()
          //      .label(`"Salaire max."`),
          avecSuivi: Yup.string().nullable().label(`"Avec suivi"`),
          // Add extra property only in case of "sauvegarde"
          ...(pInialValues?.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE
               ? {
                      titreSauvegarde: Yup.string().nullable().label(`"Titre de la sauvegarde"`).required(),
                 }
               : {}),
     })

     // Initial values
     const initialValues: ISearchCandidatesFiltersModel = {
          searchScope: pInialValues.searchScope || CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_NOM_MAIL_TEL,
          searchOperateurLogiqueOnlyScopeCurriculum:
               pInialValues.searchOperateurLogiqueOnlyScopeCurriculum || CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEUR_LOGIQUE_OU,
          searchKeywords: pInialValues.searchKeywords,
          disponibilite: pInialValues.disponibilite,
          disponibiliteDate: pInialValues.disponibiliteDate?.format("YYYY-MM-DD"),
          mobilite: pInialValues.mobilite,
          mobiliteRegions: pInialValues.mobiliteRegions?.map(item => item.id),
          mobiliteDepartements: pInialValues.mobiliteDepartements?.map(item => item.id),
          mobiliteVilles: pInialValues.mobiliteVilles?.map(item => item.id as number),
          niveauEtudes: pInialValues.niveauEtudes,
          fraicheurCV: pInialValues.fraicheurCV,
          viviers: pInialValues.viviers?.map(item => item.id as number),
          annonces: pInialValues?.annonces?.map(item => item.id as number),
          candidats: pInialValues?.candidats?.map(item => item.id as number),
          areCandidatesInListeDeTraitement: pInialValues?.areCandidatesInListeDeTraitement,
          candidatType: pInialValues.candidatType,
          personnelsAyantSaisiLeCandidat: pInialValues.personnelsAyantSaisiLeCandidat?.map(item => item.id as number),
          secteursActivite: pInialValues.secteursActivite?.map(item => item.id as number),
          metiers: pInialValues.metiers?.map(item => item.id as number),
          seenOrNot: pInialValues.seenOrNot,
          experienceMin: pInialValues.experienceMin,
          // salaireNetMensuelSouhaiteMin: pInialValues.salaireNetMensuelSouhaiteMin,
          // salaireNetMensuelSouhaiteMax: pInialValues.salaireNetMensuelSouhaiteMax,
          avecSuivi: pInialValues.avecSuivi,
          ...(pInialValues?.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE
               ? {
                      titreSauvegarde: pInialValues?.titreSauvegarde,
                 }
               : {}),
     }

     // Handle form submit
     function handleSubmit(values: ISearchCandidatesFiltersModel, helpers: FormikHelpers<ISearchCandidatesFiltersModel>) {
          helpers.setStatus(null)

          handleSubmitCallback(values, helpers)
     }

     // Handle sauvegarde
     function handleSauvegarde(helpers: FormikProps<ISearchCandidatesFiltersModel>) {
          // If pValues is a saved search and with ID then update it instead of adding a new saved search
          if (pInialValues?.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE && pInialValues.id) {
               helpers.setStatus(null)
               setIsSavingFilters(true)
               updateSavedFiltersSearchCandidate(pInialValues.id, helpers.values)
                    .then(() => {
                         toast.success("Filtres sauvegardés.")
                         handleSaveFiltersCallback()
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data
                         // Set form errors
                         if (error.errors && error.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR) {
                              for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                         }

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)
                         toast.error(error?.detail, { autoClose: false })
                    })
                    .finally(() => setIsSavingFilters(false))
          } else {
               swal.fire({
                    icon: "info",
                    title: `Veuillez choisir un titre pour votre sauvegarde.`,
                    text: "Cette sauvegarde vous permettra d'accélérer vos prochaines recherches candidat.",
                    input: "text",
                    inputPlaceholder: "Votre titre",
                    inputAttributes: {
                         required: "true",
                         autoComplete: "off",
                    },
                    showCancelButton: true,
                    confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
                    showLoaderOnConfirm: true,
                    inputValidator: value => {
                         return new Promise((resolve: any) => {
                              value === "" ? resolve("Veuillez renseigner un titre.") : resolve()
                         })
                    },
                    preConfirm: async (titre: string) => {
                         return createSavedFiltersSearchCandidate(titre, helpers.values)
                              .then(r => {
                                   helpers.setStatus(null)
                                   // Notify user about redirect
                                   toast.info(r.data.message)
                                   handleSaveFiltersCallback()
                              })
                              .catch((e: AxiosError) => {
                                   const error: IHttpErrorResponseModel = e.response?.data
                                   // Set form errors
                                   if (error.errors && error.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR) {
                                        for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                                   }

                                   // Set form global status and notify user using a toast
                                   helpers.setStatus(error?.detail)
                                   toast.error(error?.detail, { autoClose: false })
                              })
                              .finally(() => setIsSavingFilters(false))
                    },
                    allowOutsideClick: () => !swal.isLoading(),
               }).then()
          }
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} innerRef={formRef} enableReinitialize={true}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {/* In case of errors */}
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              {/* Titre sauvegarde */}
                              {pInialValues?.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE && pInialValues.id && (
                                   <>
                                        <div className="row">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre de la sauvegarde</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"titreSauvegarde"}
                                                       value={helpers.values.titreSauvegarde}
                                                       isInvalid={!!helpers.errors.titreSauvegarde}
                                                       onChange={val => helpers.setFieldValue("titreSauvegarde", val)}
                                                  />
                                                  {helpers.errors.titreSauvegarde && <div className={"text-danger"}>{helpers.errors.titreSauvegarde}</div>}
                                             </div>
                                        </div>
                                        <div className="separator bg-primary my-5"></div>
                                   </>
                              )}

                              {/* keywords */}
                              <div className="row mb-5" ref={lessFiltersDivScrollTo}>
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Mots clé</label>

                                   <div className="col-lg-8">
                                        <MySelectCreatableField
                                             name={"searchKeywords"}
                                             options={[]}
                                             value={helpers.values.searchKeywords}
                                             onChange={val => {
                                                  helpers.setFieldValue("searchKeywords", val).then()
                                                  handleChangeKeywordsField(val)
                                             }}
                                             isInvalid={!!helpers.errors.searchKeywords}
                                             placeholder={"Tapez vos mots clés"}
                                        />
                                        {helpers.errors.searchKeywords && <div className={"text-danger mb-2"}>{helpers.errors.searchKeywords}</div>}

                                        {helpers.values.searchKeywords && helpers.values.searchKeywords.length > 0 && (
                                             <>
                                                  <MySelectField
                                                       name={"searchScope"}
                                                       options={CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORD_TARGETS_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.searchScope}
                                                       onChange={val => helpers.setFieldValue("searchScope", val)}
                                                       isInvalid={!!helpers.errors.searchScope}
                                                       isSearchable={false}
                                                       placeholder={"Choisissez le périmètre de recherche"}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.searchScope && <div className={"text-danger"}>{helpers.errors.searchScope}</div>}</div>

                                                  {helpers.values.searchScope === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_CV && (
                                                       <>
                                                            <MySelectField
                                                                 name={"searchOperateurLogiqueOnlyScopeCurriculum"}
                                                                 options={CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEURS_LOGIQUE_OPTIONS_FOR_SELECT2}
                                                                 value={helpers.values.searchOperateurLogiqueOnlyScopeCurriculum}
                                                                 onChange={val => helpers.setFieldValue("searchOperateurLogiqueOnlyScopeCurriculum", val)}
                                                                 isInvalid={!!helpers.errors.searchOperateurLogiqueOnlyScopeCurriculum}
                                                                 isSearchable={false}
                                                                 placeholder={"Choisissez un opérateur logique"}
                                                            />
                                                            {helpers.errors.searchOperateurLogiqueOnlyScopeCurriculum && (
                                                                 <div className={"text-danger"}>{helpers.errors.searchOperateurLogiqueOnlyScopeCurriculum}</div>
                                                            )}
                                                       </>
                                                  )}
                                             </>
                                        )}
                                   </div>
                              </div>

                              {/* Dispo */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Disponibilité</label>

                                   <div className="col-lg-8">
                                        <MySelectField
                                             name={"disponibilite"}
                                             options={CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2}
                                             value={helpers.values.disponibilite}
                                             onChange={val => helpers.setFieldValue("disponibilite", val)}
                                             isInvalid={!!helpers.errors.disponibilite}
                                             isSearchable={false}
                                        />
                                        <div className={"mb-2"}>{helpers.errors.disponibilite && <div className={"text-danger"}>{helpers.errors.disponibilite}</div>}</div>

                                        {helpers.values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && (
                                             <>
                                                  <MyDateDayMonthYearField
                                                       name={"disponibiliteDate"}
                                                       value={helpers.values.disponibiliteDate}
                                                       placeholder={"Choisissez votre date"}
                                                       onChange={val => helpers.setFieldValue("disponibiliteDate", val)}
                                                       isInvalid={!!helpers.errors.disponibiliteDate}
                                                  />
                                                  {helpers.errors.disponibiliteDate && <div className={"text-danger"}>{helpers.errors.disponibiliteDate}</div>}
                                             </>
                                        )}
                                   </div>
                              </div>

                              {/* Mobilité */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Mobilité</label>

                                   <div className="col-lg-8">
                                        <MySelectField
                                             name={"mobilite"}
                                             options={CONST_MOBILITE_OPTIONS_FOR_SELECT2}
                                             value={helpers.values.mobilite}
                                             isInvalid={!!helpers.errors.mobilite}
                                             onChange={val => helpers.setFieldValue("mobilite", val)}
                                             isSearchable={false}
                                        />
                                        <div className={"mb-2"}>{helpers.errors.mobilite && <div className={"text-danger"}>{helpers.errors.mobilite}</div>}</div>

                                        {helpers.values.mobilite === CONST_MOBILITE_DEPARTEMENTAL && (
                                             <>
                                                  <MySelectSearchDataFromServer
                                                       name={"mobiliteDepartements"}
                                                       value={helpers.values.mobiliteDepartements}
                                                       onChange={val => helpers.setFieldValue("mobiliteDepartements", val)}
                                                       defaultOptions={(pInialValues?.mobiliteDepartements || []).map(item => ({
                                                            label: item.nom,
                                                            value: item.id,
                                                       }))}
                                                       url={CONST_API_UTILS_GET_DEPARTEMENTS_URL_ENDPOINT}
                                                       isInvalid={!!helpers.errors.mobiliteDepartements}
                                                       method={"GET"}
                                                       minimumLengthSearch={3}
                                                       isMulti
                                                  />
                                                  {helpers.errors.mobiliteDepartements && <div className={"text-danger"}>{helpers.errors.mobiliteDepartements}</div>}
                                             </>
                                        )}

                                        {helpers.values.mobilite === CONST_MOBILITE_REGIONAL && (
                                             <>
                                                  <MySelectGetAllDataFromServer
                                                       name={"mobiliteRegions"}
                                                       value={helpers.values.mobiliteRegions}
                                                       onChange={val => helpers.setFieldValue("mobiliteRegions", val)}
                                                       defaultOptions={(pInialValues.mobiliteRegions || []).map(item => ({
                                                            label: item.nom,
                                                            value: item.id,
                                                       }))}
                                                       isInvalid={!!helpers.errors.mobiliteRegions}
                                                       url={CONST_API_UTILS_GET_REGIONS_URL_ENDPOINT}
                                                       isMulti
                                                  />
                                                  {helpers.errors.mobiliteRegions && <div className={"text-danger"}>{helpers.errors.mobiliteRegions}</div>}
                                             </>
                                        )}
                                        {helpers.values.mobilite === CONST_MOBILITE_PAR_VILLE && (
                                             <>
                                                  <MySelectSearchDataFromServer
                                                       name={"mobiliteVilles"}
                                                       value={helpers.values.mobiliteVilles}
                                                       onChange={val => helpers.setFieldValue("mobiliteVilles", val)}
                                                       defaultOptions={(pInialValues?.mobiliteVilles || []).map(item => ({
                                                            label: item.nom as string,
                                                            value: item.id as number,
                                                       }))}
                                                       url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                       isInvalid={!!helpers.errors.mobiliteVilles}
                                                       method={"GET"}
                                                       minimumLengthSearch={3}
                                                       isMulti
                                                  />
                                                  {helpers.errors.mobiliteVilles && <div className={"text-danger"}>{helpers.errors.mobiliteVilles}</div>}
                                             </>
                                        )}
                                   </div>
                              </div>

                              {/* Niveau d'études */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Niveau d'études</label>

                                   <div className="col-lg-8">
                                        <MySelectField
                                             name={"niveauEtudes"}
                                             options={CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2}
                                             value={helpers.values.niveauEtudes}
                                             onChange={val => helpers.setFieldValue("niveauEtudes", val)}
                                             isInvalid={!!helpers.errors.niveauEtudes}
                                             isSearchable={false}
                                        />
                                        {helpers.errors.niveauEtudes && <div className={"text-danger"}>{helpers.errors.niveauEtudes}</div>}
                                   </div>
                              </div>

                              {/* Fraîcheur CV */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Fraîcheur du CV</label>

                                   <div className="col-lg-8">
                                        <MySelectField
                                             name={"fraicheurCV"}
                                             options={CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEURS_CV_OPTIONS_FOR_SELECT2}
                                             value={helpers.values.fraicheurCV}
                                             onChange={val => helpers.setFieldValue("fraicheurCV", val)}
                                             isInvalid={!!helpers.errors.fraicheurCV}
                                             isSearchable={false}
                                        />
                                        {helpers.errors.fraicheurCV && <div className={"text-danger"}>{helpers.errors.fraicheurCV}</div>}
                                   </div>
                              </div>

                              {/* Viviers */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Viviers</label>

                                   <div className="col-lg-8">
                                        <MySelectGetAllDataFromServer
                                             name={"viviers"}
                                             url={CONST_API_UTILS_GET_VIVIERS_ENDPOINT}
                                             defaultOptions={(pInialValues?.viviers || []).map(item => ({
                                                  label: item.intitule as string,
                                                  value: item.id as number,
                                             }))}
                                             payload={{ includeMyOwnViviers: true, includeTeamViviers: true }}
                                             value={helpers.values.viviers}
                                             onChange={val => helpers.setFieldValue("viviers", val)}
                                             isInvalid={!!helpers.errors.viviers}
                                             isMulti
                                        />
                                        {helpers.errors.viviers && <div className={"text-danger"}>{helpers.errors.viviers}</div>}
                                   </div>
                              </div>

                              <div className="separator border-primary w-25 mb-2" ref={moreFiltersDivScrollTo}></div>

                              <div className="text-primary font-weight-bold cursor-pointer" onClick={() => setMoreFiltersVisible(prev => !prev)}>
                                   {moreFiltersVisible ? "-" : "+"} de filtres
                              </div>

                              {moreFiltersVisible && (
                                   <div className={"animation-when-something-becomes-visible mt-8"}>
                                        {/* Offers */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Annonces</label>

                                             <div className="col-lg-8">
                                                  <MySelectSearchDataFromServer
                                                       name={"annonces"}
                                                       url={CONST_API_UTILS_GET_OFFERS_ENDPOINT}
                                                       value={helpers.values.annonces}
                                                       onChange={val => helpers.setFieldValue("annonces", val)}
                                                       defaultOptions={(pInialValues?.annonces || []).map(item => ({
                                                            label: item.intitule as string,
                                                            value: item.id as number,
                                                       }))}
                                                       minimumLengthSearch={3}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.annonces}
                                                       isMulti
                                                  />
                                                  {helpers.errors.annonces && <div className={"text-danger"}>{helpers.errors.annonces}</div>}
                                             </div>
                                        </div>

                                        {/* Offers */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Candidats</label>

                                             <div className="col-lg-8">
                                                  <MySelectSearchDataFromServer
                                                       name={"candidats"}
                                                       url={CONST_API_UTILS_GET_CANDIDATES_URL_ENDPOINT}
                                                       value={helpers.values.candidats}
                                                       onChange={val => helpers.setFieldValue("candidats", val)}
                                                       defaultOptions={(pInialValues?.candidats || []).map(item => ({
                                                            label: item.prenomNom as string,
                                                            value: item.id as number,
                                                       }))}
                                                       minimumLengthSearch={3}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.candidats}
                                                       isMulti
                                                  />
                                                  {helpers.errors.candidats && <div className={"text-danger"}>{helpers.errors.candidats}</div>}
                                             </div>
                                        </div>

                                        {/* Liste traitement */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Liste traitement</label>
                                             <div className="col-lg-8 d-flex align-items-center">
                                                  <div className={"form-check form-check-custom form-check-solid"}>
                                                       <input
                                                            className={"form-check-input border border-secondary cursor-pointer"}
                                                            type="checkbox"
                                                            onChange={() => {
                                                                 if (
                                                                      helpers.values.areCandidatesInListeDeTraitement === undefined ||
                                                                      helpers.values.areCandidatesInListeDeTraitement === null
                                                                 ) {
                                                                      helpers.setFieldValue("areCandidatesInListeDeTraitement", true).then()
                                                                 } else {
                                                                      helpers
                                                                           .setFieldValue("areCandidatesInListeDeTraitement", !helpers.values.areCandidatesInListeDeTraitement)
                                                                           .then()
                                                                 }
                                                            }}
                                                            checked={
                                                                 helpers.values.areCandidatesInListeDeTraitement === undefined ||
                                                                 helpers.values.areCandidatesInListeDeTraitement === null
                                                                      ? false
                                                                      : helpers.values.areCandidatesInListeDeTraitement
                                                            }
                                                       />
                                                       {helpers.errors.areCandidatesInListeDeTraitement && (
                                                            <div className={"text-danger"}>{helpers.errors.areCandidatesInListeDeTraitement}</div>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>

                                        {/* Type candidat */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Type candidat</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"candidatType"}
                                                       options={CONST_CANDIDAT_TYPES_FOR_SELECT2}
                                                       value={helpers.values.candidatType}
                                                       onChange={val => helpers.setFieldValue("candidatType", val)}
                                                       isInvalid={!!helpers.errors.candidatType}
                                                       isSearchable={false}
                                                  />
                                                  {helpers.errors.candidatType && <div className={"text-danger"}>{helpers.errors.candidatType}</div>}
                                                  {helpers.values.candidatType === CONST_CANDIDAT_TYPE_SAISI && (
                                                       <>
                                                            <MySelectSearchDataFromServer
                                                                 name={"personnelsAyantSaisiLeCandidat"}
                                                                 url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                                 value={helpers.values.personnelsAyantSaisiLeCandidat}
                                                                 onChange={val => helpers.setFieldValue("personnelsAyantSaisiLeCandidat", val)}
                                                                 defaultOptions={(pInialValues?.personnelsAyantSaisiLeCandidat || []).map(item => ({
                                                                      label: item.prenomNom as string,
                                                                      value: item.id as number,
                                                                 }))}
                                                                 minimumLengthSearch={3}
                                                                 method={"GET"}
                                                                 isInvalid={!!helpers.errors.personnelsAyantSaisiLeCandidat}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.personnelsAyantSaisiLeCandidat && (
                                                                 <div className={"text-danger"}>{helpers.errors.personnelsAyantSaisiLeCandidat}</div>
                                                            )}
                                                       </>
                                                  )}
                                             </div>
                                        </div>

                                        {/* Secteurs activité */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Secteurs d'activité</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"secteursActivite"}
                                                       url={CONST_API_UTILS_GET_BUSINESS_UNITS_ENDPOINT}
                                                       value={helpers.values.secteursActivite}
                                                       onChange={val => helpers.setFieldValue("secteursActivite", val)}
                                                       defaultOptions={(pInialValues?.secteursActivite || []).map(item => ({
                                                            label: item.nom as string,
                                                            value: item.id as number,
                                                       }))}
                                                       isInvalid={!!helpers.errors.secteursActivite}
                                                       isMulti
                                                  />
                                                  {helpers.errors.secteursActivite && <div className={"text-danger"}>{helpers.errors.secteursActivite}</div>}
                                             </div>
                                        </div>

                                        {/* Métier */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Métiers</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"metiers"}
                                                       url={CONST_API_UTILS_GET_METIERS_URL_ENDPOINT}
                                                       value={helpers.values.metiers}
                                                       onChange={val => helpers.setFieldValue("metiers", val)}
                                                       defaultOptions={(pInialValues?.metiers || []).map(item => ({
                                                            label: item.nom as string,
                                                            value: item.id as number,
                                                       }))}
                                                       isInvalid={!!helpers.errors.metiers}
                                                       isMulti
                                                  />
                                                  {helpers.errors.metiers && <div className={"text-danger"}>{helpers.errors.metiers}</div>}
                                             </div>
                                        </div>

                                        {/* Vu */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Vu</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"seenOrNot"}
                                                       options={CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATIONS_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.seenOrNot}
                                                       onChange={val => helpers.setFieldValue("seenOrNot", val)}
                                                       isInvalid={!!helpers.errors.seenOrNot}
                                                       isSearchable={false}
                                                  />
                                                  {helpers.errors.seenOrNot && <div className={"text-danger"}>{helpers.errors.seenOrNot}</div>}
                                             </div>
                                        </div>

                                        {/* Experience */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Expérience min.</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"experienceMin"}
                                                       options={CONST_CANDIDAT_EXPERIENCES_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.experienceMin}
                                                       onChange={val => helpers.setFieldValue("experienceMin", val)}
                                                       isInvalid={!!helpers.errors.experienceMin}
                                                       isSearchable={false}
                                                  />
                                                  {helpers.errors.experienceMin && <div className={"text-danger"}>{helpers.errors.experienceMin}</div>}
                                             </div>
                                        </div>

                                        {/* Salaire NET mensuel souhaité */}
                                        {/*<div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Salaire NET mensuel</label>
                                             <div className="col-lg-8">
                                                  <div className="input-group">
                                                       <MyInputField
                                                            name={"salaireNetMensuelSouhaiteMin"}
                                                            value={helpers.values.salaireNetMensuelSouhaiteMin}
                                                            onChange={val =>
                                                                 helpers.setFieldValue("salaireNetMensuelSouhaiteMin", !GlobalHelper.isNumeric(val) ? "" : parseInt(val))
                                                            }
                                                            inputMode={"numeric"}
                                                            placeholder={"Min."}
                                                            isInvalid={!!helpers.errors.salaireNetMensuelSouhaiteMin}
                                                       />
                                                       <span className="input-group-text fas fa-ellipsis d-flex align-items-center"></span>
                                                       <MyInputField
                                                            name={"salaireNetMensuelSouhaiteMax"}
                                                            value={helpers.values.salaireNetMensuelSouhaiteMax}
                                                            onChange={val =>
                                                                 helpers.setFieldValue("salaireNetMensuelSouhaiteMax", !GlobalHelper.isNumeric(val) ? "" : parseInt(val))
                                                            }
                                                            inputMode={"numeric"}
                                                            placeholder={"Max."}
                                                            isInvalid={!!helpers.errors.salaireNetMensuelSouhaiteMax}
                                                       />
                                                  </div>
                                                  {helpers.errors.salaireNetMensuelSouhaiteMin && (
                                                       <div className={"text-danger"}>{helpers.errors.salaireNetMensuelSouhaiteMin}</div>
                                                  )}
                                                  {helpers.errors.salaireNetMensuelSouhaiteMax && (
                                                       <div className={"text-danger"}>{helpers.errors.salaireNetMensuelSouhaiteMax}</div>
                                                  )}
                                             </div>
                                        </div>*/}

                                        {/* Avec suivi */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Avec suivi</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"avecSuivi"}
                                                       options={CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVIS_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.avecSuivi}
                                                       onChange={val => helpers.setFieldValue("avecSuivi", val)}
                                                       isInvalid={!!helpers.errors.avecSuivi}
                                                       isSearchable={false}
                                                  />
                                                  {helpers.errors.avecSuivi && <div className={"text-danger"}>{helpers.errors.avecSuivi}</div>}
                                             </div>
                                        </div>
                                   </div>
                              )}

                              <div className="row mt-5">
                                   <div className="col-6">
                                        <button
                                             className={"btn btn-sm btn-outline btn-outline-info d-inline-flex align-items-center"}
                                             type={"button"}
                                             disabled={helpers.isSubmitting || isSavingFilters}
                                             onClick={() => handleSauvegarde(helpers)}
                                        >
                                             {pInialValues?.typeHistoriqueOuSauvegarde === CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE &&
                                             pInialValues.id ? (
                                                  <>
                                                       <span className={"d-none d-md-block"}>Appliquer les modifications</span>
                                                       {!isSavingFilters ? (
                                                            <i className={"fas fa-check-circle ms-md-1"} />
                                                       ) : (
                                                            <span className="spinner-border spinner-border-sm align-middle ms-md-1" />
                                                       )}
                                                  </>
                                             ) : (
                                                  <>
                                                       <span className={"d-none d-md-block"}>Créer une sauvegarde</span>
                                                       {!isSavingFilters ? (
                                                            <i className={"fas fa-save ms-md-1"} />
                                                       ) : (
                                                            <span className="spinner-border spinner-border-sm align-middle ms-md-1" />
                                                       )}
                                                  </>
                                             )}
                                        </button>
                                   </div>
                                   <div className={"col-6 text-end"}>
                                        <button
                                             className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                             type={"button"}
                                             disabled={helpers.isSubmitting || isSavingFilters}
                                             onClick={() => {
                                                  helpers.resetForm()
                                                  callBackOnReset()
                                             }}
                                        >
                                             <i className={"fas fa-sync"} />
                                        </button>
                                        <button
                                             className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                             type={"submit"}
                                             disabled={helpers.isSubmitting || isSavingFilters}
                                        >
                                             {!helpers.isSubmitting ? <i className={"fas fa-search"} /> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default FormRechercheCandidat
