import React, { useMemo, useRef, useState } from "react"
import * as Yup from "yup"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import Split from "react-split-it"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_APPLICATION_DOC,
     CONST_MIME_APPLICATION_DOCX,
     CONST_MIME_APPLICATION_GOOGLE_DOC,
     CONST_MIME_APPLICATION_PDF,
     CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_CANDIDAT_REUNION_QUALIF,
} from "@common-constants/*"
import { CONST_API_UTILS_GET_CLIENTS_ENDPOINT, CONST_API_UTILS_GET_TEMPLATES_ENDPOINT } from "../../../../endpoints"
import { Form, Formik, FormikHelpers } from "formik"
import MyModal from "@common-utils/MyModal"
import MyRadioField from "@common-utils/fields/MyRadioField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyEditorField from "@common-utils/fields/MyEditorField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MyFileField from "@common-utils/fields/MyFileField"
import MyTooltip from "@common-utils/MyTooltip"
import PersonnelTemplateCreateForm from "../../../personnel/mon-compte/templates/_PersonnelTemplateCreateForm"
import PersonnelTemplateEditForm from "../../../personnel/mon-compte/templates/_PersonnelTemplateEditForm"
import { toast } from "react-toastify"
import useSwal from "@common-hooks/useSwal"
import { deletePersonnelTemplate, getDetailsPersonnelTemplate } from "../../../personnel/mon-compte/templates/core/_request"
import { AxiosError } from "axios"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { IAddEntretienClientRequestModel, ICandidatAddSuiviEntretienClientFormModel } from "./core/_models"
import { addSuiviEntretienClient } from "./core/_requests"
import { IHttpErrorResponseModel } from "@common-models/*"

const CandidatAddSuiviEntretienClientForm = ({ candidat, curriculumGoogleDriveUrl, handleSubmitCallBack }: ICandidatAddSuiviEntretienClientFormModel) => {
     const { swal } = useSwal()

     const [selectedTabForLgDisplayOrLess, setSelectedTabForLgDisplayOrLess] = useState<"cv" | "form">("form")
     const [isAddingNewTemplate, setIsAddingNewTemplate] = useState<boolean>(false)
     const [templateInEdition, setTemplateInEdition] = useState<{ id: number; title?: string } | null>(null)
     const formRef = useRef<any>(null)

     // Form Suivi
     const FormSuivi = () => {
          const [forceEditorToRefreshContent, setForceEditorToRefreshContent] = useState<{ value: string; random: string }>()
          const [isFetchingContent, setIsFetchingTemplateContent] = useState<boolean>(false)

          const schema = Yup.object().shape({
               createSuiviSansTemplateOuAvecTemplate: Yup.string().label(`"Template"`).required(),
               template: Yup.number()
                    .label(`"Template"`)
                    .when("createSuiviSansTemplateOuAvecTemplate", {
                         is: "AVEC_TEMPLATE",
                         then: schema => schema.required(),
                    }),
               notes: Yup.string().label(`"Notes"`),
               client: Yup.number().label(`"Client"`).required(),
               voulezVousJoindreUnDocumentToThisSuivi: Yup.boolean(),
               document: Yup.mixed()
                    .when("voulezVousJoindreUnDocumentToThisSuivi", {
                         is: true,
                         then: schema => schema.required(),
                    })
                    .test("fileFormat", "Seuls les formats word ou PDF sont acceptés", (value: any) => {
                         if (!value) return true // If no file is provided, skip the test
                         return [CONST_MIME_APPLICATION_PDF, CONST_MIME_APPLICATION_DOCX, CONST_MIME_APPLICATION_DOC, CONST_MIME_APPLICATION_GOOGLE_DOC].includes(value.type)
                    })
                    .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                         if (!value) return true // If no file is provided, skip the test
                         return value.size <= 10 * 1024 * 1024
                    })
                    .label(`"Document"`),
          })

          interface IFormValuesModel {
               createSuiviSansTemplateOuAvecTemplate: string | "AVEC_TEMPLATE" | "SANS_TEMPLATE"
               template?: number
               notes?: string
               client?: number
               voulezVousJoindreUnDocumentToThisSuivi: boolean
               document?: File
          }

          const initialValues: IFormValuesModel = {
               createSuiviSansTemplateOuAvecTemplate: "",
               voulezVousJoindreUnDocumentToThisSuivi: false,
          }

          function handleSubmit(values: IFormValuesModel, helpers: FormikHelpers<IFormValuesModel>) {
               helpers.setStatus(null)
               const requestModel: IAddEntretienClientRequestModel = {
                    notes: values.notes,
                    document: values.document,
                    client: values.client as number,
               }
               addSuiviEntretienClient(candidat.id, requestModel)
                    .then(() => {
                         toast.success("Le suivi a bien été ajouté.")
                         handleSubmitCallBack()
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                         }

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)
                         toast.error(error?.detail, { autoClose: false })

                         // Stop submit loader
                         helpers.setSubmitting(false)
                    })
          }

          return (
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} innerRef={formRef} enableReinitialize={true}>
                    {helpers => {
                         const NotesClientEtDocumentFields = (
                              <>
                                   {/* Notes */}
                                   <>
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Notes</label>
                                        <MyLoadingOverlay isActive={isFetchingContent}>
                                             <MyEditorField
                                                  value={helpers.values.notes}
                                                  height={250}
                                                  onChange={val => helpers.setFieldValue("notes", val)}
                                                  placeholder={"Notes"}
                                                  forceEditorToRefreshContent={forceEditorToRefreshContent}
                                             />
                                        </MyLoadingOverlay>
                                        <div className={"mb-2"}>{helpers.errors.notes && <div className={"text-danger"}>{helpers.errors.notes}</div>}</div>
                                   </>
                                   {/* Field client */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Client</label>
                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"client"}
                                                  value={helpers.values.client}
                                                  onChange={val => helpers.setFieldValue("client", val)}
                                                  url={CONST_API_UTILS_GET_CLIENTS_ENDPOINT}
                                                  isInvalid={!!helpers.errors.client}
                                             />
                                             {helpers.errors.client && <div className={"text-danger"}>{helpers.errors.client}</div>}
                                        </div>
                                   </div>
                                   {/* Field voulezVousJoindreUnDocumentToThisSuivi */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous joindre un document à ce suivi?</label>

                                        <div className="col-lg-8 d-flex">
                                             <div className="d-flex align-items-center">
                                                  <MyCheckBoxField
                                                       name={"voulezVousJoindreUnDocumentToThisSuivi"}
                                                       value={helpers.values.voulezVousJoindreUnDocumentToThisSuivi}
                                                       isInvalid={!!helpers.errors.voulezVousJoindreUnDocumentToThisSuivi}
                                                       onChange={val => helpers.setFieldValue("voulezVousJoindreUnDocumentToThisSuivi", val)}
                                                  />
                                             </div>
                                             {helpers.values.voulezVousJoindreUnDocumentToThisSuivi && (
                                                  <div className="flex-grow-1 ms-2">
                                                       <MyFileField
                                                            name={"document"}
                                                            isInvalid={!!helpers.errors.document}
                                                            onChange={val => helpers.setFieldValue("document", val)}
                                                       />
                                                       {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              </>
                         )

                         const TemplateField = (
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Template</label>
                                   <div className="col-lg-8">
                                        <MySelectGetAllDataFromServer
                                             name={"template"}
                                             value={helpers.values.template}
                                             onChange={val => {
                                                  helpers.setFieldValue("template", val).then(() => {
                                                       // Hydrate content
                                                       if (val) {
                                                            setIsFetchingTemplateContent(true)
                                                            getDetailsPersonnelTemplate(val as number)
                                                                 .then(r => setForceEditorToRefreshContent({ value: r.data.contenu as string, random: GlobalHelper.randomId() }))
                                                                 .catch((e: AxiosError) => toast.error(e?.response?.data.detail, { autoClose: false }))
                                                                 .finally(() => setIsFetchingTemplateContent(false))
                                                       }
                                                  })
                                             }}
                                             url={CONST_API_UTILS_GET_TEMPLATES_ENDPOINT}
                                             payload={{
                                                  includeTeam: true,
                                                  categorie: CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_CANDIDAT_REUNION_QUALIF,
                                             }}
                                             isInvalid={!!helpers.errors.template}
                                        />
                                        <div className="row">
                                             <div className="col">
                                                  {helpers.values.template && (
                                                       <div>
                                                            <MyTooltip title={"Supprimer le template sélectionné"}>
                                                                 <i
                                                                      className={"fas fa-trash text-danger cursor-pointer"}
                                                                      onClick={() =>
                                                                           deleteTemplate(helpers.values.template as number, () => helpers.setFieldValue("template", undefined))
                                                                      }
                                                                 />
                                                            </MyTooltip>
                                                            <MyTooltip title={"Editer le template sélectionné"}>
                                                                 <i
                                                                      className={"fas fa-edit text-warning ms-4 ms-sm-2 cursor-pointer"}
                                                                      onClick={() => setTemplateInEdition({ id: helpers.values.template as number })}
                                                                 />
                                                            </MyTooltip>
                                                       </div>
                                                  )}
                                             </div>
                                             <div className="col text-end">
                                                  <MyTooltip title={"Créer un nouveau template"}>
                                                       <span className={"text-success fw-bold cursor-pointer"} onClick={() => setIsAddingNewTemplate(true)}>
                                                            nouveau <i className={"fas fa-plus-circle text-success"} />
                                                       </span>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                        {helpers.errors.template && <div className="text-danger">{helpers.errors.template}</div>}
                                   </div>
                              </div>
                         )

                         return (
                              <Form noValidate autoComplete="off">
                                   {/* Field createSuiviSansTemplateOuAvecTemplate */}
                                   <div className="row">
                                        <div className="col-6">
                                             <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                  <span className="d-flex align-items-center me-2">
                                                       <span className="symbol symbol-50px me-6">
                                                            <span className="symbol-label bg-light-primary">
                                                                 <i className={"fas fa-file-lines fs-2 text-primary"} />
                                                            </span>
                                                       </span>
                                                       <span className="d-flex flex-column">
                                                            <span className="fw-bolder fs-6">Suivi avec pré-saisie</span>
                                                            <span className="fs-7 text-muted">Ajouter mes notes à l'aide du template de mon choix (recommandé)</span>
                                                       </span>
                                                  </span>
                                                  <MyRadioField
                                                       name={"createSuiviSansTemplateOuAvecTemplate"}
                                                       value={"AVEC_TEMPLATE"}
                                                       isChecked={helpers.values.createSuiviSansTemplateOuAvecTemplate === "AVEC_TEMPLATE"}
                                                       isInvalid={!!helpers.errors.createSuiviSansTemplateOuAvecTemplate}
                                                       onChange={val => helpers.setFieldValue("createSuiviSansTemplateOuAvecTemplate", val)}
                                                  />
                                             </label>
                                        </div>
                                        <div className="col-6">
                                             <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                  <span className="d-flex align-items-center me-2">
                                                       <span className="symbol symbol-50px me-6">
                                                            <span className="symbol-label bg-light-success">
                                                                 <i className={"fas fa-file fs-2 text-success"} />
                                                            </span>
                                                       </span>
                                                       <span className="d-flex flex-column">
                                                            <span className="fw-bolder fs-6">Suivi vierge</span>
                                                            <span className="fs-7 text-muted">Ajouter mes notes sans template</span>
                                                       </span>
                                                  </span>
                                                  <MyRadioField
                                                       name={"createSuiviSansTemplateOuAvecTemplate"}
                                                       value={"SANS_TEMPLATE"}
                                                       isChecked={helpers.values.createSuiviSansTemplateOuAvecTemplate === "SANS_TEMPLATE"}
                                                       isInvalid={!!helpers.errors.createSuiviSansTemplateOuAvecTemplate}
                                                       onChange={val => helpers.setFieldValue("createSuiviSansTemplateOuAvecTemplate", val)}
                                                  />
                                             </label>
                                        </div>
                                   </div>

                                   {helpers.values.createSuiviSansTemplateOuAvecTemplate && (
                                        <>
                                             <div className={"border p-4 mb-2"}>
                                                  {helpers.values.createSuiviSansTemplateOuAvecTemplate === "AVEC_TEMPLATE" ? (
                                                       <>
                                                            {/* Field template */}
                                                            {TemplateField}

                                                            {/* other fields (DMS, programmer réunion & document) */}
                                                            {helpers.values.template && NotesClientEtDocumentFields}
                                                       </>
                                                  ) : (
                                                       <>{NotesClientEtDocumentFields}</>
                                                  )}
                                             </div>
                                             {/* Submit button */}
                                             <div className={"text-end"}>
                                                  <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? "Ajouter" : <MySimpleSpinner size={"sm"} />}
                                                  </button>
                                             </div>
                                        </>
                                   )}
                              </Form>
                         )
                    }}
               </Formik>
          )
     }

     // View display Xl or +
     const ViewDisplayXlOrPlus = useMemo(
          () => () => {
               return (
                    <>
                         <Split sizes={[50, 50]} direction="horizontal">
                              <div
                                   className={"pe-3"}
                                   style={{
                                        maxHeight: "82vh",
                                        overflowY: "scroll",
                                   }}
                              >
                                   <FormSuivi />
                              </div>
                              <iframe
                                   allowFullScreen={true}
                                   src={curriculumGoogleDriveUrl}
                                   style={{
                                        height: "82vh",
                                        width: "100%",
                                   }}
                                   className={"ms-2"}
                              />
                         </Split>
                    </>
               )
          },
          []
     )

     // View display LG or -
     const ViewDisplayLgOrLess = () => {
          return (
               <>
                    {/* Buttons to switch between Form & CV (only on display <=LG) */}
                    <div className={"d-flex justify-content-end d-xl-none"}>
                         <button
                              className={`btn btn-sm ${selectedTabForLgDisplayOrLess === "form" ? "btn-dark" : "btn-outline btn-outline-muted"} me-5 border-`}
                              onClick={() => setSelectedTabForLgDisplayOrLess("form")}
                         >
                              Formulaire
                         </button>
                         <button
                              className={`btn btn-sm ${selectedTabForLgDisplayOrLess === "cv" ? "btn-dark" : "btn-outline btn-outline-muted"}`}
                              onClick={() => setSelectedTabForLgDisplayOrLess("cv")}
                         >
                              CV
                         </button>
                    </div>
                    <div className="separator mt-2 mb-4" />

                    {selectedTabForLgDisplayOrLess === "form" ? (
                         <FormSuivi />
                    ) : (
                         <iframe
                              allowFullScreen={true}
                              src={curriculumGoogleDriveUrl}
                              style={{
                                   height: "82vh",
                                   width: "100%",
                              }}
                              className={"ms-2"}
                         />
                    )}
               </>
          )
     }

     // Template create
     const CreateTemplateFormInModal = () => {
          return (
               <MyModal title={<span>Création d'un template "RQ"</span>} show={true} handleClose={() => setIsAddingNewTemplate(false)} fullscreen>
                    <PersonnelTemplateCreateForm
                         categorie={CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_CANDIDAT_REUNION_QUALIF}
                         handleOnSubmitCallBack={() => setIsAddingNewTemplate(false)}
                    />
               </MyModal>
          )
     }

     // Template edit
     const EditTemplateFormInModal = () => {
          return (
               <MyModal title={<span>Edition du template</span>} show={true} handleClose={() => setTemplateInEdition(null)} fullscreen>
                    <PersonnelTemplateEditForm template_ID={templateInEdition!.id} handleOnSubmitCallBack={() => setTemplateInEdition(null)} />
               </MyModal>
          )
     }

     // Template delete
     function deleteTemplate(id: number, callBack: () => void) {
          // Sweet alert confirmation
          return swal.fire({
               icon: "warning",
               title: "Vous êtes sur le point de supprimer le template sélectionné",
               text: "Voulez-vous continuer?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return deletePersonnelTemplate(id)
                         .then(() => {
                              toast.info("Le template a bien été supprimé")
                              callBack()
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          })
     }

     return (
          <>
               {window.innerWidth >= 1200 ? (
                    <>
                         <ViewDisplayXlOrPlus />
                    </>
               ) : (
                    <ViewDisplayLgOrLess />
               )}

               {isAddingNewTemplate && <CreateTemplateFormInModal />}
               {templateInEdition && <EditTemplateFormInModal />}
          </>
     )
}

export default CandidatAddSuiviEntretienClientForm
