import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { IPersonnelDeclarationMensuelleModel } from "@common-models/*"
import { toast } from "react-toastify"
import MyCard from "@common-utils/MyCard"
import { ConnectedUserDeclarationDetails_NavigationHeader } from "./_ConnectedUserDeclarationDetails_NavigationHeader"
import { ConnectedUserDeclarationDetails_arrets } from "./_ConnectedUserDeclarationDetails_arrets"
import { ConnectedUserDeclarationDetails_ddc } from "./_ConnectedUserDeclarationDetails_ddc"
import { ConnectedUserDeclarationDetails_craWrapper } from "./cra/_ConnectedUserDeclarationDetailsCraWrapper"
import { ConnectedUserDeclarationDetailsTrWrapper } from "./tr/_ConnectedPersonnelDeclarationDetailsTr"
import { ConnectedUserDeclarationDetails_ndf } from "./ndf/_ConnectedUserDeclarationDetails_ndf"
import ConnectedPersonnelDeclarationDetails_recap from "./recap/ConnectedPersonnelDeclarationDetails_recap"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { getPersonnelDeclarationByIdRequest } from "../../../declaration/core/_requests"
import MyAlert from "@common-utils/MyAlert"
import {
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
} from "@common-constants/*"

const urlParams = new URLSearchParams(window.location.search)

const ConnectedUserDeclarationDetailsContext = createContext<IConnectedUserDeclarationDetailsContextPropsModel>({} as IConnectedUserDeclarationDetailsContextPropsModel)
export const useConnectedUserDeclarationDetails = () => useContext(ConnectedUserDeclarationDetailsContext)

const ConnectedPersonnelDeclarationDetails = ({ editionModeEnabled }: IConnectedUserDeclarationDetailsPropsModel) => {
     const navigate = useNavigate()
     const location_state = useLocation().state as { default_step: "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap" }

     const declaration_id = parseInt(useParams<{ declaration_id: string }>().declaration_id as string)
     const default_step = location_state?.default_step || (urlParams.get("default_step") as string)

     const [data, setData] = useState<{ loading: boolean; error: false | string; declaration: IPersonnelDeclarationMensuelleModel | null }>({
          loading: true,
          error: false,
          declaration: null,
     })

     const [enCoursDeRectificationEnabledDepuisRecapitulatif, setEnCoursDeRectificationEnabledDepuisRecapitulatif] = useState<boolean>(false)
     const [currentStepHeader, setCurrentStepHeader] = useState<"periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap">(default_step || "arrets")

     useEffect(() => {
          if (declaration_id) {
               getPersonnelDeclarationByIdRequest(declaration_id)
                    .then(r => {
                         setData(prev => ({ ...prev, loading: false, declaration: r.data }))
                    })
                    .catch(e => {
                         const error = e.response?.data?.detail
                         toast.error(error, { autoClose: false })
                         setData(prev => ({ ...prev, loading: false, error: error }))
                    })
          } else {
               navigate(`/personnel/mes-activites/declarations/create`)
          }
     }, [])

     const containerClassAccordingToStep = (() => {
          if (currentStepHeader === "arrets") return "w-100 w-lg-75 w-xxl-65"
          if (currentStepHeader === "ddc") return "w-100 w-lg-75 w-xxl-65"
          if (currentStepHeader === "cra") return "w-100"
          if (currentStepHeader === "ndf") return "w-100 w-lg-90 w-xxl-65"
          if (currentStepHeader === "ticket_resto") return "w-100"
          if (currentStepHeader === "recap") return "w-100 w-lg-90 w-xxl-65"

          return ""
     })()

     return (
          <>
               <ConnectedUserDeclarationDetailsContext.Provider
                    value={{
                         data,
                         setData,
                         currentStepHeader,
                         setCurrentStepHeader,
                         enCoursDeRectificationEnabledDepuisRecapitulatif,
                         setEnCoursDeRectificationEnabledDepuisRecapitulatif,
                         editionModeEnabled: editionModeEnabled !== undefined ? editionModeEnabled : (data.declaration?.modifiable as boolean),
                    }}
               >
                    <div className={"d-flex justify-content-center"}>
                         <div className={containerClassAccordingToStep}>
                              <MyCard>
                                   <MyCard.Body>
                                        {data.declaration &&
                                             data.declaration.etat &&
                                             data.declaration.etat?.commentaire &&
                                             [
                                                  CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
                                                  CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                                                  CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
                                                  CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
                                             ].includes(data.declaration.etat.type as string) && (
                                                  <MyAlert type={"info"} customIcon={"fas fa-comment-dots"} classNames={"mb-2"}>
                                                       <b className={"text-dark"}>{data.declaration.etat.personnel ? data.declaration.etat.personnel.prenomNom : "Moi"}: </b>{" "}
                                                       {data.declaration.etat?.commentaire}
                                                  </MyAlert>
                                             )}

                                        <ConnectedUserDeclarationDetails_NavigationHeader />
                                        <div className="separator separator-dashed mb-5" />

                                        {data.loading && (
                                             <div className={"text-center p-5"}>
                                                  <MySimpleSpinner size={"xl"} />
                                             </div>
                                        )}

                                        {data.declaration && (
                                             <>
                                                  {currentStepHeader === "arrets" && <ConnectedUserDeclarationDetails_arrets />}
                                                  {currentStepHeader === "ddc" && <ConnectedUserDeclarationDetails_ddc />}
                                                  {currentStepHeader === "cra" && <ConnectedUserDeclarationDetails_craWrapper />}
                                                  {currentStepHeader === "ndf" && <ConnectedUserDeclarationDetails_ndf />}
                                                  {currentStepHeader === "ticket_resto" && <ConnectedUserDeclarationDetailsTrWrapper />}
                                                  {currentStepHeader === "recap" && <ConnectedPersonnelDeclarationDetails_recap />}
                                             </>
                                        )}
                                   </MyCard.Body>
                              </MyCard>
                         </div>
                    </div>
               </ConnectedUserDeclarationDetailsContext.Provider>
          </>
     )
}

interface IConnectedUserDeclarationDetailsPropsModel {
     editionModeEnabled?: boolean
}

interface IConnectedUserDeclarationDetailsContextPropsModel {
     data: { loading: boolean; error: false | string; declaration: IPersonnelDeclarationMensuelleModel | null }
     setData: Dispatch<SetStateAction<{ loading: boolean; error: false | string; declaration: IPersonnelDeclarationMensuelleModel | null }>>
     currentStepHeader: "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap"
     setCurrentStepHeader: Dispatch<SetStateAction<"periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap">>
     enCoursDeRectificationEnabledDepuisRecapitulatif: boolean
     setEnCoursDeRectificationEnabledDepuisRecapitulatif: Dispatch<SetStateAction<boolean>>
     editionModeEnabled: boolean
}

export default ConnectedPersonnelDeclarationDetails
