import axios from "axios"
import { IAuthenticatedUserModel } from "../../../models"
import { IPersonnelModel } from "@common-models/*"

const API_URL = process.env.REACT_APP_API_URL

// Auth Alternative with username & password
export function authWithUsernameAndPassword(username: string, password: string) {
     return axios.post<{ jwt: string; user: IAuthenticatedUserModel }>(`${API_URL}/email-password-auth`, {
          username,
          password,
     })
}

// Used for Google Oauth2. Process summary: STEP 1. API-CORE returns code STEP 2. API-CORE needs code to return jwt STEP 3. AGORA uses JWT to authenticate
export function getJwt(code: string) {
     return axios.post<{ jwt: string; user: IAuthenticatedUserModel }>(`${API_URL}/get-jwt`, { code: `${code}` })
}

export function forgotPasswordRequest(email: string) {
     return axios.post(`${API_URL}/forgot-password`, {
          email,
     })
}

export function getUserByToken(token: string) {
     return axios.get<IPersonnelModel>(`${API_URL}/user-info`, {
          headers: {
               "Authorization-api": `Bearer ${token}`,
          },
     })
}

export function resetPassword(password: string, token: string) {
     return axios.put<IPersonnelModel>(
          `${API_URL}/reset-password`,
          {
               password,
          },
          {
               headers: {
                    "Authorization-api": `Bearer ${token}`,
               },
          }
     )
}
