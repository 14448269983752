import * as Yup from "yup"
import MyModal from "@common-utils/MyModal"
import { ErrorMessage, Form, Formik } from "formik"
import { usePersonnelCraContext } from "./_PersonnelCra"
import MyAlert from "@common-utils/MyAlert"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { IPersonnelCraModel } from "@common-models/*"
import React from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { createPersonnelDeclarationCraLigneActivite } from "../core/_requests"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_TYPES_LIGNE_CRA_PERSONNEL_ENDPOINT } from "../../../../endpoints"

const PersonnelCraCreateLigneActiviteFormInModal = () => {
     const context = usePersonnelCraContext()
     const queryClient = useQueryClient()

     const schema = Yup.object().shape({
          type: Yup.string().required("Ce champ est requis"),
     })

     if (!context.setCreateLigneActivite) return <></>

     return (
          <MyModal title={<span>Ajouter un ligne d'activité</span>} show={true} handleClose={() => context.setCreateLigneActivite(false)}>
               <>
                    <MyAlert type={"primary"} classNames={"mb-4"}>
                         Choisissez le libellé de la ligne à ajouter.
                    </MyAlert>
                    <Formik
                         initialValues={{ type: "" }}
                         onSubmit={(values, { setStatus, setSubmitting }) => {
                              setStatus(null)
                              createPersonnelDeclarationCraLigneActivite(context.data.id as number, values.type)
                                   .then(r => {
                                        queryClient.setQueryData<IPersonnelCraModel | undefined>(context.REACT_QUERY_KEY_GET_ACTIVE_CRA, () => {
                                             return r.data
                                        })
                                        setSubmitting(false)
                                        context.setCreateLigneActivite(false)
                                   })
                                   .catch(e => {
                                        const error = e.response?.data?.detail
                                        toast.error(error, { autoClose: false })
                                        setSubmitting(false)
                                   })
                         }}
                         validationSchema={schema}
                    >
                         {({ values, setFieldValue, isSubmitting, status, errors, setStatus }) => {
                              return (
                                   <Form noValidate autoComplete={"off"}>
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}

                                        <div>
                                             <label className={"form-label"}>Libellé</label>
                                             <MySelectGetAllDataFromServer
                                                  name={"type"}
                                                  value={values.type}
                                                  isInvalid={!!errors.type}
                                                  onChange={val => setFieldValue("type", val)}
                                                  url={CONST_API_UTILS_GET_TYPES_LIGNE_CRA_PERSONNEL_ENDPOINT}
                                                  isClearable={false}
                                                  onFetch={totalOptions => {
                                                       if (totalOptions === 0) {
                                                            setStatus("Vous ne pouvez pas ajouter de ligne d'activité")
                                                            toast.error("Vous ne pouvez pas ajouter de ligne d'activité")
                                                       }
                                                  }}
                                             />
                                             <ErrorMessage name={`type`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                        </div>

                                        {/* Submit button */}
                                        <div className={"d-flex justify-content-end mt-4"}>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={isSubmitting}>
                                                  {!isSubmitting ? <>Confirmer</> : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </>
          </MyModal>
     )
}

export default PersonnelCraCreateLigneActiviteFormInModal
