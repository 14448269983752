import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IClientResponsableModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_PAYS_ID_FRANCE } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyInputField from "@common-utils/fields/MyInputField"
import * as Yup from "yup"
import { IAddResponsableClientRequestModel } from "./core/_models"
import { addResponsableClientRequest } from "./core/_requests"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_CLIENTS_ENDPOINT, CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT } from "../../endpoints"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"

const AddResponsableClientForm = ({ callback = () => {} }: IAddRespClientFormPropsModel) => {
     // Schema
     const schema = Yup.object().shape({
          client: Yup.number().required().nullable().label(`"Client"`),
          nom: Yup.string().required().nullable().label(`"Nom"`),
          prenom: Yup.string().required().nullable().label(`"Prénom"`),
          mail: Yup.string().email().required().nullable().label(`E-mail`),
          telIndicatif: Yup.number().required().nullable().label(`"Indicatif"`),
          tel: Yup.string().required().nullable().label(`"Tél."`),
     })

     // Init values
     const initialValues: IAddResponsableClientRequestModel = {
          telIndicatif: CONST_PAYS_ID_FRANCE,
     }

     // Handle submit
     function handleSubmit(values: IAddResponsableClientRequestModel, helpers: FormikHelpers<IAddResponsableClientRequestModel>) {
          helpers.setStatus(null)

          addResponsableClientRequest(values)
               .then(r => {
                    toast.success("Le responsable a bien été ajouté")

                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Client */}
                                   <div className="row">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Client</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"client"}
                                                  url={CONST_API_UTILS_GET_CLIENTS_ENDPOINT}
                                                  value={helpers.values.client}
                                                  onChange={val => helpers.setFieldValue("client", val)}
                                                  isInvalid={!!helpers.errors.client}
                                             />
                                             <div>{helpers.errors.client && <div className={"text-danger"}>{helpers.errors.client}</div>}</div>
                                        </div>
                                   </div>

                                   <div className="separator my-5" />

                                   {/* Nom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"nom"}
                                                  value={helpers.values.nom}
                                                  onChange={val => helpers.setFieldValue("nom", val)}
                                                  placeholder={"Nom"}
                                                  isInvalid={!!helpers.errors.nom}
                                             />
                                             <div>{helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Prénom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"prenom"}
                                                  value={helpers.values.prenom}
                                                  onChange={val => helpers.setFieldValue("prenom", val)}
                                                  placeholder={"Prénom"}
                                                  isInvalid={!!helpers.errors.prenom}
                                             />
                                             <div>{helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Mail */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">E-mail</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"mail"}
                                                  value={helpers.values.mail}
                                                  onChange={val => helpers.setFieldValue("mail", val)}
                                                  placeholder={"E-mail"}
                                                  isInvalid={!!helpers.errors.mail}
                                             />
                                             <div>{helpers.errors.mail && <div className={"text-danger"}>{helpers.errors.mail}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Indicatif */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Indicatif tél.</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"telIndicatif"}
                                                  url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                  value={helpers.values.telIndicatif}
                                                  onChange={val => helpers.setFieldValue("telIndicatif", val)}
                                                  defaultOptions={[{ value: CONST_PAYS_ID_FRANCE, label: "+33 - France" }]}
                                                  isInvalid={!!helpers.errors.telIndicatif}
                                             />
                                             <div>{helpers.errors.telIndicatif && <div className={"text-danger"}>{helpers.errors.telIndicatif}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Tél */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Tél.</label>

                                        <div className="col-lg-8">
                                             <MyInptuMaskField
                                                  name={"tel"}
                                                  value={helpers.values.tel}
                                                  isInvalid={!!helpers.errors.tel}
                                                  mask={"99 9999999999999999999999999"}
                                                  placeholder={"Téléphone"}
                                                  inputMode={"numeric"}
                                                  onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                             />
                                             <div>{helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Ajouter</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IAddRespClientFormPropsModel {
     callback?: (val: IClientResponsableModel) => void
}

export default AddResponsableClientForm
