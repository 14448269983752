import React, { useState } from "react"
import { KTSVG, toAbsoluteUrl } from "@common-metronic/helpers"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import { useAuth } from "../../../AppContext"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MyCard from "@common-utils/MyCard"

const CompteHeader: React.FC = () => {
     const location = useLocation()
     const { currentUser, completionInfoProfilEnPourcentage } = useAuth()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     return (
          <MyCard classNames={"mb-5 mb-xl-5"}>
               <MyCard.Body classNames={"pt-9 pb-0"}>
                    <div className="d-flex flex-md-row flex-column text-center mb-3 justify-content-md-start justify-content-center">
                         <div className="me-md-7 mb-4">
                              <MyLoadingOverlay isActive={isAvatarLoading} classNames={"symbol symbol-110px symbol-fixed position-relative"}>
                                   <>
                                        <img
                                             src={
                                                  currentUser?.avatarGoogleDriveID
                                                       ? FileAndDriveHelper.getThumbnailDriveUrl(currentUser.avatarGoogleDriveID)
                                                       : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                             }
                                             onLoad={() => setIsAvatarLoading(false)}
                                             alt={"Avatar"}
                                        />
                                        <div className="position-absolute translate-middle bottom-0 start-100 mb-2 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                                   </>
                              </MyLoadingOverlay>
                         </div>

                         <div className="flex-md-grow-1">
                              <div className="d-flex justify-content-center justify-content-md-between align-items-start flex-wrap mb-2">
                                   <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-center justify-content-md-start align-items-center mb-2">
                                             <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1" style={{ cursor: "default" }}>
                                                  {currentUser?.prenom} {currentUser?.nom}
                                             </span>
                                             <span style={{ cursor: "default" }}>
                                                  <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-1 svg-icon-primary" />
                                             </span>
                                        </div>

                                        <div className="d-flex flex-wrap fw-bold fs-6">
                                             <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                  <i className={"fas fa-phone me-1"} /> {currentUser?.telProfessionnel}
                                             </span>
                                             <span className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                  <KTSVG path="/media/icons/duotune/communication/com011.svg" className="svg-icon-4 me-1" />
                                                  {currentUser?.mailProfessionnel}
                                             </span>
                                        </div>
                                   </div>
                              </div>

                              <div className="d-flex flex-wrap flex-stack justify-content-md-start justify-content-center">
                                   <div className="d-flex align-items-center w-200px w-sm-300px flex-column">
                                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                             <span className="fw-bold fs-6 text-gray-400">Complétion du profil</span>
                                             <span className="fw-bolder fs-6">{completionInfoProfilEnPourcentage}%</span>
                                        </div>
                                        <div className="h-5px mx-3 w-100 bg-light mb-3">
                                             <div
                                                  className="bg-success rounded h-5px"
                                                  role="progressbar"
                                                  style={{
                                                       width: `${completionInfoProfilEnPourcentage}%`,
                                                  }}
                                             ></div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="d-flex justify-content-center justify-content-md-start overflow-auto h-55px">
                         <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                              <li className="nav-item">
                                   <Link
                                        className={
                                             `nav-link text-active-primary ` +
                                             ((location.pathname === "/personnel/mon-compte/profil" || location.pathname === "/personnel/mon-compte/profil/edit") && "active")
                                        }
                                        to="profil"
                                   >
                                        <span className={"d-none d-md-block"}>Mes informations perso.</span>
                                        <span className={"d-md-none d-block"}>
                                             <i className={"fas fa-info-circle"} style={{ fontSize: "2rem" }}></i>
                                        </span>
                                   </Link>
                              </li>

                              {currentUser?.isProfilCompleted && (
                                   <>
                                        <li className="nav-item">
                                             <Link
                                                  className={`nav-link text-active-primary  ` + (location.pathname === "/personnel/mon-compte/mes-documents" && "active")}
                                                  to="mes-documents"
                                             >
                                                  <>
                                                       <span className={"d-none d-md-block"}>Mes documents</span>
                                                       <span className={"d-md-none d-block"}>
                                                            <i className={"fas fa-file-alt"} style={{ fontSize: "2rem" }}></i>
                                                       </span>
                                                  </>
                                             </Link>
                                        </li>

                                        <li className="nav-item">
                                             <Link className={`nav-link text-active-primary ` + (location.pathname === "/personnel/mon-compte/settings" && "active")} to="settings">
                                                  <>
                                                       <span className={"d-none d-md-block"}>Paramètres du compte</span>
                                                       <span className={"d-md-none d-block"}>
                                                            <i className={"fas fa-cog"} style={{ fontSize: "2rem" }}></i>
                                                       </span>
                                                  </>
                                             </Link>
                                        </li>
                                   </>
                              )}
                         </ul>
                    </div>
               </MyCard.Body>
          </MyCard>
     )
}

export { CompteHeader }
