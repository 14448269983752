import React from "react"
import Footer from "./_Footer"
import PersonnelTr from "../../../../declaration/tr/_PersonnelTr"
import { useConnectedUserDeclarationDetails } from "../ConnectedPersonnelDeclarationDetails"

export const ConnectedUserDeclarationDetailsTrWrapper = () => {
     const context = useConnectedUserDeclarationDetails()
     return (
          <>
               <PersonnelTr declaration_ID={context.data.declaration?.id as number} editionModeEnabled={context.editionModeEnabled} />

               <div className="separator separator-dashed my-6" />

               <Footer />
          </>
     )
}
