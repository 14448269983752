import { useConsultantDeclarationDetailsContext } from "./_ConsultantDeclarationDetails"

// Menu permettant de naviguer d'un élément à un autre (Exemple de CRA to NDF)
export const ConsultantDeclarationDetailsNavigationHeader = () => {
     const detailsInModalContext = useConsultantDeclarationDetailsContext()

     return (
          <div className="stepper stepper-links">
               <div className="stepper-nav">
                    {/* begin::Step Arrêts */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.navigationStep === "arrets" && "current"} ${
                              ["ddc", "cra", "ndf", "tr"].includes(detailsInModalContext.navigationStep) && "completed"
                         }  ${!["arrets"].includes(detailsInModalContext.navigationStep) && "cursor-pointer"}`}
                         onClick={() => !["arrets"].includes(detailsInModalContext.navigationStep) && detailsInModalContext.setNavigationStep("arrets")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>1. Arrêts</span>
                              <span className={"d-block d-sm-none fas fa-file-medical"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step Arrêts */}

                    {/* begin::Step DDC */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.navigationStep === "ddc" && "current"} ${
                              ["cra", "ndf", "tr"].includes(detailsInModalContext.navigationStep) && "completed"
                         }  ${!["ddc"].includes(detailsInModalContext.navigationStep) && "cursor-pointer"}`}
                         onClick={() => !["ddc"].includes(detailsInModalContext.navigationStep) && detailsInModalContext.setNavigationStep("ddc")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>2. DDC</span>
                              <span className={"d-block d-sm-none fas fa-umbrella-beach"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step DDC */}

                    {/* begin::Step CRA */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.navigationStep === "cra" && "current"} ${
                              ["ndf", "tr"].includes(detailsInModalContext.navigationStep) && "completed"
                         } ${!["cra"].includes(detailsInModalContext.navigationStep) && "cursor-pointer"}`}
                         onClick={() => {
                              if (!["cra"].includes(detailsInModalContext.navigationStep)) detailsInModalContext.setNavigationStep("cra")
                         }}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>3. CRA</span>
                              <span className={"d-block d-sm-none fas fa-calendar-days"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step CRA */}

                    {/* begin::Step NDF */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${["ndf"].includes(detailsInModalContext.navigationStep) && "current"} ${
                              ["tr"].includes(detailsInModalContext.navigationStep) && "completed"
                         } ${!["ndf"].includes(detailsInModalContext.navigationStep) && "cursor-pointer"}`}
                         onClick={() => !["ndf"].includes(detailsInModalContext.navigationStep) && detailsInModalContext.setNavigationStep("ndf")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>4. NDF</span>
                              <span className={"d-block d-sm-none fas fa-file-invoice-dollar"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step NDF */}

                    {/* begin::Step Tickets restaurant */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${detailsInModalContext.navigationStep === "tr" && "current"} ${
                              !["tr"].includes(detailsInModalContext.navigationStep) && "cursor-pointer"
                         }`}
                         onClick={() => !["tr"].includes(detailsInModalContext.navigationStep) && detailsInModalContext.setNavigationStep("tr")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>5. Tickets restaurant</span>
                              <span className={"d-block d-sm-none fas fa-utensils"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step Tickets restaurant */}
               </div>
          </div>
     )
}
