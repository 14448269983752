import React from "react"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useAnnoncesListingContext } from "./AnnoncesListing"

// Simple search bar
const AnnoncesListingSearchBar = () => {
     const globalContext = useAnnoncesListingContext()

     function handleSubmit(values: { keyword?: string }) {
          globalContext.setFilters(prev => ({
               ...prev,
               keyword: values.keyword,
          }))
          globalContext.setItemInPreview(null)
     }

     function handleReset() {
          globalContext.simpleSearchBarFormikRef.current.resetForm() // Reset local form
          globalContext.filtersFormikRef.current.resetForm() // Reset search bar form
          globalContext.setFilters({
               page: 1,
               pageLength: 20,
          }) // Reset payload filters

          globalContext.simpleSearchBarFormikRef.current.setSubmitting(true)
          globalContext.setItemInPreview(null)
     }

     function handleClickShowFilters() {
          globalContext.setAreFiltersVisible(prev => !prev)
          // Hide preview if it was opened before showing filters.
          globalContext.setItemInPreview(prev => {
               if (prev) {
                    return { ...prev, isPreviewVisible: false }
               }
               return null
          })
     }

     return (
          <Formik<{ keyword?: string }> initialValues={{}} onSubmit={handleSubmit} innerRef={globalContext.simpleSearchBarFormikRef}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete={"off"}>
                              <div className="input-group">
                                   <MyTooltip title={"Voir les filtres"}>
                                        <button type={"button"} className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={handleClickShowFilters}>
                                             <i className={"fas fa-filter fs-4 text-dark"} />
                                        </button>
                                   </MyTooltip>
                                   {/* Button that activates mass selection */}
                                   {globalContext.listingQuery.data && globalContext.listingQuery.data.totalElementsInAllPages > 1 && (
                                        <MyTooltip
                                             title={globalContext.isBulkSelectionEnabled.enabled ? "Quitter le mode sélection multiple" : "Activer le mode sélection multiple"}
                                        >
                                             <button
                                                  type={"button"}
                                                  className={`btn btn-sm ${globalContext.isBulkSelectionEnabled.enabled ? "btn-secondary" : "btn-outline btn-outline-secondary"}`}
                                                  onClick={() =>
                                                       globalContext.setIsBulkSelectionEnabled(prev =>
                                                            prev.enabled
                                                                 ? {
                                                                        enabled: false,
                                                                        checkedElementsInPage: [],
                                                                        areAllElementsInAllPagesChecked: false,
                                                                   }
                                                                 : {
                                                                        ...prev,
                                                                        enabled: true,
                                                                   }
                                                       )
                                                  }
                                             >
                                                  <i
                                                       className={`fas ${
                                                            globalContext.isBulkSelectionEnabled.enabled ? "fa-times text-danger fs-2" : "fa-list-check text-dark fs-4"
                                                       }`}
                                                  />
                                             </button>
                                        </MyTooltip>
                                   )}
                                   <MyInputField
                                        name={"keyword"}
                                        value={helpers.values.keyword}
                                        isInvalid={false}
                                        placeholder={"Rechercher ..."}
                                        onChange={val => {
                                             helpers.setFieldValue("keyword", val).then()
                                             globalContext.filtersFormikRef.current.setFieldValue("keyword", val).then()
                                        }}
                                        classNames={"border"}
                                   />
                                   <button type={"submit"} className={"btn btn-sm btn-outline btn-outline-secondary"} disabled={helpers.isSubmitting}>
                                        {helpers.isSubmitting ? <MySimpleSpinner size={"sm"} classNames={"text-dark"} /> : <i className={"fas fa-search text-dark"} />}
                                   </button>
                                   <MyTooltip title={"Réinitialiser les filtres"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-secondary"}
                                             onClick={() => handleReset()}
                                             disabled={helpers.isSubmitting}
                                        >
                                             <i className={"fas fa-sync text-dark"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default AnnoncesListingSearchBar
