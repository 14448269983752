import React from "react"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyPagination from "@common-utils/MyPagination"
import moment from "moment"
import { useStructureDeclarationsListingContext } from "../GestionStructureDeclarationsListing"
import { IGetStructureDeclarationsDataItemResponseModel } from "../core/_models"
import ListItem from "./_ListItem"
import { toast } from "react-toastify"
import { CONST_DEFAULT_TOAST_OPTIONS } from "@common-constants/*"
import BulkSelectionContainerForListings from "../../../../../../utils/BulkSelectionContainerForListings"
import MyTooltip from "@common-utils/MyTooltip"

const PersonnelsDeclarationsListingList = () => {
     const context = useStructureDeclarationsListingContext()

     const CardHeaderRightSide = () => {
          return (
               <>
                    {context.listingQuery.isFetching ? (
                         <MySimpleSpinner size={"sm"} />
                    ) : (
                         <>
                              {context.listingQuery.data && context.listingQuery.data.totalElementsInPage > 0 && (
                                   <div className={"d-flex align-items-center"}>
                                        {/* Pagination component*/}
                                        <MyPagination
                                             page={context.filters.page as number}
                                             pageStart={context.listingQuery.data.pageStart}
                                             pageEnd={context.listingQuery.data.pageEnd}
                                             totalPages={context.listingQuery.data.totalPages}
                                             totalElementsInAllPages={context.listingQuery.data.totalElementsInAllPages}
                                             onPreviousPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) - 1,
                                                  }))
                                             }}
                                             onNextPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) + 1,
                                                  }))
                                             }}
                                        />
                                   </div>
                              )}
                         </>
                    )}
               </>
          )
     }

     // BULK SELECTION: handling the "check all elements" button click
     function handleCheckAllElementsInPageCallBack() {
          const items = context.listingQuery.data!.data
          context.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               checkedElementsInPage: items,
          }))
          toast.info("Tous les éléments de cette page ont été cochés.", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 1000 })
     }

     // BULK SELECTION: handling the "check all elements across all pages" button click
     function handleCheckAllElementsAcrossAllPagesCallBack() {
          context.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               areAllElementsInAllPagesChecked: true,
          }))
          toast.info("Tous les éléments correspondant à votre recherche ont été cochés.", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 1000 })
     }

     // BULK SELECTION: handling the "Quit" button click
     function handleQuitBulkSelectionModeCallBack() {
          context.setIsBulkSelectionEnabled({
               enabled: false,
               checkedElementsInPage: [],
               areAllElementsInAllPagesChecked: false,
          })
     }

     const areAllElementsInPageChecked = context.isBulkSelectionEnabled.checkedElementsInPage.length === context.listingQuery.data?.totalElementsInPage

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false} rightSideContent={<CardHeaderRightSide />}>
                    Déclarations de {moment(context.filters.mois).format("MMMM YYYY")}
               </MyCard.Header>

               <MyCard.Body noPadding={true}>
                    <>
                         {/* Fetching */}
                         {context.listingQuery.isFetching ? (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner classNames={"ms-2"} />
                              </div>
                         ) : (
                              <>
                                   {/* In case of error */}
                                   {context.listingQuery.error ? (
                                        <MyAlert type={"danger"}>{context.listingQuery.error.response?.data.detail}</MyAlert>
                                   ) : (
                                        <>
                                             {/* CASE: no data */}
                                             {context.listingQuery.data && context.listingQuery.data.data.length === 0 && (
                                                  <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>
                                             )}

                                             {/* CASE: If some data */}
                                             {context.listingQuery.data && context.listingQuery.data.data.length > 0 && (
                                                  <>
                                                       {/* Mass selection actions component */}
                                                       {context.isBulkSelectionEnabled.enabled && (
                                                            <BulkSelectionContainerForListings
                                                                 areAllElementsInAllPagesChecked={context.isBulkSelectionEnabled.areAllElementsInAllPagesChecked}
                                                                 checkedElementIdsInPage={context.isBulkSelectionEnabled.checkedElementsInPage}
                                                                 totalElementsInPage={context.listingQuery.data.totalElementsInPage}
                                                                 totalElementsInAllPages={context.listingQuery.data.totalElementsInAllPages}
                                                                 allowCheckAllElementInAllPages={false}
                                                                 handleCheckAllElementsInPageCallBack={handleCheckAllElementsInPageCallBack}
                                                                 handleCheckAllElementsAcrossAllPagesCallBack={handleCheckAllElementsAcrossAllPagesCallBack}
                                                                 handleQuitBulkSelectionModeCallBack={handleQuitBulkSelectionModeCallBack}
                                                                 AllOrFewElementsCheckedInCurrentPageActionsChildren={
                                                                      <>
                                                                           <MyTooltip title={"Télécharger"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle me-2`}
                                                                                     onClick={() =>
                                                                                          context.handleDownloadDeclarations(context.isBulkSelectionEnabled.checkedElementsInPage)
                                                                                     }
                                                                                >
                                                                                     <i className="fas fa-download fs-4" />
                                                                                </button>
                                                                           </MyTooltip>
                                                                           <MyTooltip title={"Relance par mail"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={`btn btn-outline btn-outline-warning btn-sm btn-icon rounded-circle`}
                                                                                     onClick={() => {
                                                                                          context.handleRelance(
                                                                                               context.isBulkSelectionEnabled.checkedElementsInPage.map(item => item)
                                                                                          )
                                                                                     }}
                                                                                >
                                                                                     <i className="fas fa-bell fs-4" />
                                                                                </button>
                                                                           </MyTooltip>
                                                                      </>
                                                                 }
                                                            />
                                                       )}
                                                       {/* List elements */}
                                                       <div
                                                            style={{
                                                                 maxHeight: "68vh",
                                                                 overflowY: "auto",
                                                            }}
                                                       >
                                                            <table className={`table table-rounded  gy-7 gs-7 m-0 p-0`}>
                                                                 <thead className={`sticky-top bg-light border-bottom border-secondary`} style={{ opacity: 1, zIndex: 990 }}>
                                                                      <tr className={"align-middle"}>
                                                                           {context.isBulkSelectionEnabled.enabled && (
                                                                                <td>
                                                                                     <div
                                                                                          className={`form-check form-check-custom form-check-solid form-check-sm not-concerned-by-preview cursor-pointer`}
                                                                                     >
                                                                                          <input
                                                                                               type="checkbox"
                                                                                               className={`form-check-input border border-secondary not-concerned-by-preview me-2`}
                                                                                               style={{ height: "2rem", width: "2rem" }}
                                                                                               checked={areAllElementsInPageChecked}
                                                                                               onChange={() =>
                                                                                                    areAllElementsInPageChecked
                                                                                                         ? handleQuitBulkSelectionModeCallBack()
                                                                                                         : handleCheckAllElementsInPageCallBack()
                                                                                               }
                                                                                          />
                                                                                     </div>
                                                                                </td>
                                                                           )}
                                                                           <td>Entité</td>
                                                                           <td>Staff</td>
                                                                           <td>Responsable</td>
                                                                           <td>État</td>
                                                                           <td>Relance</td>
                                                                           <td>Actions</td>
                                                                      </tr>
                                                                 </thead>
                                                                 <tbody>
                                                                      {context.listingQuery.data.data.map((item: IGetStructureDeclarationsDataItemResponseModel, key) => (
                                                                           <ListItem item={item} index={key} key={key} />
                                                                      ))}
                                                                 </tbody>
                                                            </table>
                                                       </div>
                                                  </>
                                             )}
                                        </>
                                   )}
                              </>
                         )}
                    </>
               </MyCard.Body>
          </MyCard>
     )
}

export default PersonnelsDeclarationsListingList
