import { useCompteInfoPersoContext } from "./CompteInfoPerso"
import { IPersonnelCompteModel } from "@common-models/*"
import { CONST_PAYS_CODE_FRANCE } from "@common-constants/*"

function CompteInfoPersoPreview() {
     const context = useCompteInfoPersoContext()
     const data = context.infoPersoQuery.data as IPersonnelCompteModel

     return (
          <>
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Civilité</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.civiliteLabel}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.prenomNom}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Date de naissance</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.dateNaissance?.format("DD/MM/YYYY")}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Email perso.</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.mailPersonnel}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Téléphone</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {data.personnel?.telPersonnelIndicatif?.phoneCodeWithPlusPrefix} {data.personnel?.telPersonnel}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">N° de Sécurité Sociale</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.nSecuriteSociale}</span>
                    </div>
               </div>

               <h3 className="mt-8 mb-4">Résidence</h3>
               <div className="separator separator-dashed mb-6"></div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Adresse</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.residenceAdresse}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Ville et code postal</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {data.personnel?.residencePays?.code === CONST_PAYS_CODE_FRANCE ? <>{data.personnel?.residenceVilleFrance?.nom}</> : data.personnel?.residenceVille}{" "}
                              {data.personnel?.residenceCodePostal}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Pays</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.residencePays?.nomFr}</span>
                    </div>
               </div>

               <h3 className="mt-8 mb-4">Personne à contacter en cas d'urgence</h3>
               <div className="separator separator-dashed mb-6"></div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.personnel?.personneToContactUrgencePrenomNom}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Téléphone</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {data.personnel?.personneToContactUrgenceTelIndicatif?.phoneCodeWithPlusPrefix} {data.personnel?.personneToContactUrgenceTel}
                         </span>
                    </div>
               </div>
          </>
     )
}

export default CompteInfoPersoPreview
