import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { IAnnoncesListingContextPropsModel, IGetAnnoncesRequestModel, IGetAnnoncesResponseModel, IItemInPreviewModel } from "./core/_models"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../models"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel, IPersonnelModel, OffreModel } from "@common-models/*"
import { CONST_ANNONCE_ETAT_ACTIVE, CONST_ANNONCE_ETAT_ARCHIVED, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import { annoncesListingRequest } from "./core/_requests"
import AnnoncesListingSearchBar from "./_AnnoncesListingSearchBar"
import AnnoncesListingFilters from "./_AnnoncesListingFilters"
import { useAuth } from "../../../../AppContext"
import AnnoncesListingList from "./_AnnoncesListingList"
import AnnoncesListingPreview from "./_AnnoncesListingPreview"
import MyModal from "@common-utils/MyModal"
import AnnoncesActionArchiver from "../actions/_AnnoncesActionArchiver"
import moment from "moment"
import AnnoncesActionRepublier from "../actions/_AnnoncesActionRepublier"
import AnnoncesActionChangerResponsable from "../actions/_AnnoncesActionChangerResponsable"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
const AnnoncesListingContext = createContext<IAnnoncesListingContextPropsModel>({} as IAnnoncesListingContextPropsModel)
export const useAnnoncesListingContext = () => useContext(AnnoncesListingContext)
const AnnoncesListing = () => {
     const queryClient = useQueryClient()
     const { currentUser } = useAuth()

     // Referencing filters form, simple search bar form & preview container
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)
     const previewContainerRef = useRef<any>(null)

     // Filters, item in preview & bulk selection
     const [filters, setFilters] = useState<IGetAnnoncesRequestModel>({
          etat: CONST_ANNONCE_ETAT_ACTIVE,
          responsables: currentUser?.teamRecrutement?.map(item => item.id as number),
          page: 1,
          pageLength: 20,
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [itemInPreview, setItemInPreview] = useState<IItemInPreviewModel | null>(null)
     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })
     const [annoncesChangementResponsable, setAnnoncesChangementResponsable] = useState<OffreModel[]>()
     const [annoncesToArchiver, setAnnoncesToArchiver] = useState<OffreModel[]>()
     const [annoncesToRepublish, setAnnoncesToRepublish] = useState<OffreModel[]>()

     // Main listing query
     const listingQuery = useQuery<IGetAnnoncesResponseModel, AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return annoncesListingRequest(filters)
               .then(r => {
                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters form
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function handleArchivage() {
          queryClient.setQueryData<IGetAnnoncesResponseModel | undefined>(REACT_QUERY_KEY_GET_LISTING, (prev: IGetAnnoncesResponseModel | undefined) => {
               if (prev?.data) {
                    prev.data.map(item => {
                         if (annoncesToArchiver?.find(item_ => item_.id === item.annonce.id)) {
                              item.annonce = {
                                   ...item.annonce,
                                   etat: CONST_ANNONCE_ETAT_ARCHIVED,
                                   etatLabel: "Archivée",
                              }
                         }
                         return item
                    })
               }
               return prev
          })
          setAnnoncesToArchiver(undefined)
     }

     function handleChangementDeResponsable(val: IPersonnelModel) {
          queryClient.setQueryData<IGetAnnoncesResponseModel | undefined>(REACT_QUERY_KEY_GET_LISTING, (prev: IGetAnnoncesResponseModel | undefined) => {
               if (prev?.data) {
                    annoncesChangementResponsable?.map(item => {
                         const index = prev.data.findIndex(item_ => item_.annonce.id === item.id)
                         if (index >= 0) {
                              prev.data[index].annonce.personnel = { ...prev.data[index].annonce.personnel, ...val }
                         }
                    })
               }
               return prev
          })
          setAnnoncesChangementResponsable(undefined)
     }

     function handleRepublication() {
          queryClient.setQueryData<IGetAnnoncesResponseModel | undefined>(REACT_QUERY_KEY_GET_LISTING, (prev: IGetAnnoncesResponseModel | undefined) => {
               if (prev?.data) {
                    prev.data.map(item => {
                         if (annoncesToRepublish?.find(item_ => item_.id === item.annonce.id)) {
                              item.annonce = {
                                   ...item.annonce,
                                   publishedAt: moment(),
                              }
                         }
                         return item
                    })
               }
               return prev
          })
          setAnnoncesToRepublish(undefined)
     }

     useEffect(() => {
          listingQuery.refetch().then()
     }, [filters])

     return (
          <>
               <AnnoncesListingContext.Provider
                    value={{
                         areFiltersVisible,
                         setAreFiltersVisible,
                         filters,
                         setFilters,
                         itemInPreview,
                         setItemInPreview,
                         REACT_QUERY_KEY_GET_LISTING,
                         listingQuery,
                         filtersFormikRef,
                         previewContainerRef,
                         simpleSearchBarFormikRef,
                         isBulkSelectionEnabled,
                         setIsBulkSelectionEnabled,
                         setAnnoncesToArchiver,
                         setAnnoncesToRepublish,
                         setAnnoncesChangementResponsable,
                    }}
               >
                    {/* Adapt view to display */}
                    {window.innerWidth >= 1200 ? <ViewXlOrHigher /> : <ViewLgOrLess />}

                    {/* Republish */}
                    {annoncesToRepublish && (
                         <MyModal title={<>Republication</>} show={true} handleClose={() => setAnnoncesToRepublish(undefined)}>
                              <AnnoncesActionRepublier annonces={annoncesToRepublish as OffreModel[]} callback={handleRepublication} />
                         </MyModal>
                    )}

                    {/* Archiver */}
                    {annoncesToArchiver && (
                         <MyModal title={<>Archivage</>} show={true} handleClose={() => setAnnoncesToArchiver(undefined)}>
                              <AnnoncesActionArchiver annonces={annoncesToArchiver as OffreModel[]} callback={handleArchivage} />
                         </MyModal>
                    )}

                    {/* Changement de responsable */}
                    {annoncesChangementResponsable && (
                         <MyModal title={<>Changement de responsable</>} show={true} handleClose={() => setAnnoncesChangementResponsable(undefined)} size={"lg"}>
                              <AnnoncesActionChangerResponsable annonces={annoncesChangementResponsable as OffreModel[]} callback={handleChangementDeResponsable} />
                         </MyModal>
                    )}
               </AnnoncesListingContext.Provider>
          </>
     )
}

// View display Lg or less
const ViewLgOrLess = () => {
     const context = useAnnoncesListingContext()

     return (
          <>
               <div className={`${context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <AnnoncesListingSearchBar />
               </div>

               {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                    <div className={"mb-2"}>
                         <AnnoncesListingPreview />
                    </div>
               )}

               <div className={`${!context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <AnnoncesListingFilters />
               </div>

               <AnnoncesListingList />
          </>
     )
}

// View display Xl or higher
const ViewXlOrHigher = () => {
     const context = useAnnoncesListingContext()
     return (
          <>
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-5 ${!context.areFiltersVisible ? "d-none" : ""}`}>
                         <AnnoncesListingFilters />
                    </div>
                    {/*
                     * List & preview will share the remaining width
                     * Preview will not be visible at the beginning
                     */}
                    <div className={`col ${context.itemInPreview && context.itemInPreview.isPreviewVisible ? "" : "col-7"}`}>
                         <div className={"row"}>
                              <div className={context.itemInPreview && context.itemInPreview.isPreviewVisible ? "col-5" : ""}>
                                   {/* Simple bar search will be shown only if filters are not displayed */}
                                   <div className={`mb-2 ${context.areFiltersVisible ? "d-none" : ""}`}>
                                        <AnnoncesListingSearchBar />
                                   </div>

                                   <AnnoncesListingList />
                              </div>
                              {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                                   <div className="col-7">
                                        <AnnoncesListingPreview />
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          </>
     )
}

export default AnnoncesListing
