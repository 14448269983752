import React, { createContext, useContext, useState } from "react"
import { IPersonnelCraDetailsHeureModel, IPersonnelCraDetailsModel, IPersonnelCraModel } from "@common-models/*"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import "./styles.scss"
import PersonnelCraAutocompleteHeuresTravailFormInModal from "./_PersonnelCra_autocompleteHeuresTravailFormInModal"
import PersonnelCraSetHeuresAbsenceCellFormInModal from "./_PersonnelCra_setHeuresAbsenceCellFormInModal"
import { getPersonnelDeclarationActiveCra } from "../core/_requests"
import PersonnelCraTableTheadRows from "./_PersonnelCra_tableTheadRows"
import PersonnelCraSetHeuresTravailCellFormInModal from "./_PersonnelCra_setHeuresTravailCellFormInModal"
import PersonnelCraTableTbodyHeuresTravailRow from "./_PersonnelCra_tableTbodyHeuresTravailRow"
import PersonnelCraTableTbodyAbsencesRows from "./_PersonnelCra_tableTbodyAbsencesRows"
import PersonnelCraCreateLigneActiviteFormInModal from "./_PersonnelCra_createLigneActiviteFormInModal"

const REACT_QUERY_KEY_GET_ACTIVE_CRA = "REACT_QUERY_KEY_GET_ACTIVE_CRA"
const PersonnelCraContext = createContext<IPersonnelCraContextPropsModel>({} as IPersonnelCraContextPropsModel)
export const usePersonnelCraContext = () => useContext(PersonnelCraContext)

const PersonnelCra = ({ declaration_ID, editionModeEnabled, setIsDataLoaded }: IPersonnelCraPropsModel) => {
     const [selectedCellRowActivite, setSelectedCellRowActivite] = useState<
          { craDetails: IPersonnelCraDetailsModel; heures: IPersonnelCraDetailsHeureModel; type: string } | undefined
     >()
     const [selectedCellRowAbsence, setSelectedCellRowAbsence] = useState<{ item: IPersonnelCraDetailsModel; absenceType: string; absenceTypeLabel: string } | undefined>()
     const [autocompleteHeuresActivite, setAutocompleteHeuresActivite] = useState<string | undefined>()
     const [createLigneActivite, setCreateLigneActivite] = useState<boolean>(false)

     const activeCraQuery = useQuery<IPersonnelCraModel, AxiosError>(REACT_QUERY_KEY_GET_ACTIVE_CRA, () =>
          getPersonnelDeclarationActiveCra(declaration_ID).then(r => {
               if (setIsDataLoaded) setIsDataLoaded(true)
               return r.data
          })
     )

     return (
          <>
               {/* loader */}
               {activeCraQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!activeCraQuery.isFetching && activeCraQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{activeCraQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => activeCraQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!activeCraQuery.isFetching && !activeCraQuery.isError && activeCraQuery.data && (
                    <PersonnelCraContext.Provider
                         value={{
                              data: activeCraQuery.data,
                              selectedCellRowActivite,
                              setSelectedCellRowActivite,
                              selectedCellRowAbsence,
                              setSelectedCellRowAbsence,
                              autocompleteHeuresActivite,
                              setAutocompleteHeuresActivite,
                              createLigneActivite,
                              setCreateLigneActivite,
                              REACT_QUERY_KEY_GET_ACTIVE_CRA,
                              editionModeEnabled,
                         }}
                    >
                         <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
                              <table id={"cra-table"} className={`table m-0 ${editionModeEnabled && "cra-table-edition-mode"}`}>
                                   {/* Begin::thead */}
                                   <thead className="thead-dark border-bottom border-top">
                                        <PersonnelCraTableTheadRows />
                                   </thead>
                                   {/* End::thead */}

                                   {/* Begin::tbody */}
                                   <tbody className={"border-top border-bottom"}>
                                        {/* Begin::Heures de travail */}
                                        <PersonnelCraTableTbodyHeuresTravailRow />
                                        {/* End::Heures de travail */}

                                        {/* Begin::Absences */}
                                        <PersonnelCraTableTbodyAbsencesRows />

                                        {/* End::Absences */}
                                   </tbody>
                                   {/* End::tbody */}
                              </table>

                              {selectedCellRowActivite && <PersonnelCraSetHeuresTravailCellFormInModal />}
                              {selectedCellRowAbsence && <PersonnelCraSetHeuresAbsenceCellFormInModal />}
                              {autocompleteHeuresActivite && <PersonnelCraAutocompleteHeuresTravailFormInModal />}
                              {createLigneActivite && <PersonnelCraCreateLigneActiviteFormInModal />}
                         </div>
                    </PersonnelCraContext.Provider>
               )}
          </>
     )
}

interface IPersonnelCraPropsModel {
     declaration_ID: number
     editionModeEnabled: boolean
     setIsDataLoaded?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IPersonnelCraContextPropsModel {
     data: IPersonnelCraModel
     selectedCellRowActivite: { craDetails: IPersonnelCraDetailsModel; heures: IPersonnelCraDetailsHeureModel; type: string } | undefined
     setSelectedCellRowActivite: React.Dispatch<React.SetStateAction<{ craDetails: IPersonnelCraDetailsModel; heures: IPersonnelCraDetailsHeureModel; type: string } | undefined>>
     selectedCellRowAbsence: { item: IPersonnelCraDetailsModel; absenceType: string; absenceTypeLabel: string } | undefined
     setSelectedCellRowAbsence: React.Dispatch<React.SetStateAction<{ item: IPersonnelCraDetailsModel; absenceType: string; absenceTypeLabel: string } | undefined>>
     autocompleteHeuresActivite: string | undefined
     setAutocompleteHeuresActivite: React.Dispatch<React.SetStateAction<string | undefined>>
     createLigneActivite: boolean
     setCreateLigneActivite: React.Dispatch<React.SetStateAction<boolean>>
     REACT_QUERY_KEY_GET_ACTIVE_CRA: string
     editionModeEnabled: boolean
}

export default PersonnelCra
