import axios from "axios"
import {
     CONST_API_BUSINESS_CONSULTANT_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_COLLAB_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_DETAILS_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_DOCUMENTS_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_GET_DECLARATIONS_ACTIVITE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_GET_DECLARATIONS_ACTIVITE_OLD_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_PUT_DISABLE_ESPACE_CONSULTANT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_PUT_ENABLE_ESPACE_CONSULTANT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SET_INFO_PERSO_DETAILS_ENDPOINT,
} from "../../../../../endpoints"
import { IGetDetailsConsultant, IGetDocumentsConsultant, ISetInfoPersoNonSalarieRequestModel, ISetInfoPersoSalarieRequestModel } from "./_models"
import { IConsultantCraNdfDcSignesModel, IConsultantDeclarationMensuelleModel } from "@common-models/*"

export function getConsultantDetails(id: number) {
     return axios.get<IGetDetailsConsultant>(CONST_API_BUSINESS_CONSULTANT_GET_DETAILS_ENDPOINT(id))
}

export function enableEspaceConsultant(id: number) {
     return axios.put(CONST_API_BUSINESS_CONSULTANT_POST_PUT_ENABLE_ESPACE_CONSULTANT_ENDPOINT(id), {})
}

export function disableEspaceConsultant(id: number) {
     return axios.put(CONST_API_BUSINESS_CONSULTANT_POST_PUT_DISABLE_ESPACE_CONSULTANT_ENDPOINT(id), {})
}

export function envoyerInstructionsConnexionEspaceCollabRequest(id: number) {
     return axios.put(CONST_API_BUSINESS_CONSULTANT_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_COLLAB_ENDPOINT(id), {})
}

export function getConsultantDeclarationsActiviteRequest(id: number) {
     return axios.get<IConsultantDeclarationMensuelleModel[]>(CONST_API_BUSINESS_CONSULTANT_POST_GET_DECLARATIONS_ACTIVITE_ENDPOINT(id), {})
}

export function getConsultantDeclarationsActiviteOldRequest(id: number) {
     return axios.get<IConsultantCraNdfDcSignesModel[]>(CONST_API_BUSINESS_CONSULTANT_POST_GET_DECLARATIONS_ACTIVITE_OLD_ENDPOINT(id), {})
}

export function getConsultantDocuments(id: number) {
     return axios.get<IGetDocumentsConsultant>(CONST_API_BUSINESS_CONSULTANT_GET_DOCUMENTS_ENDPOINT(id))
}

export function setConsultantInfoPersoSalarie(id: number, payload: ISetInfoPersoSalarieRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key === "avatar" && value) {
               formData.append("avatar", value)
          } else if (key === "isNationaliteEtrangere") {
               formData.append(`data[${key}]`, value ? "1" : "0")
          } else {
               formData.append(`data[${key}]`, value)
          }
     }

     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SET_INFO_PERSO_DETAILS_ENDPOINT(id), formData)
}

export function setConsultantInfoPersoNonSalarie(id: number, payload: ISetInfoPersoNonSalarieRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key === "avatar" && value) {
               formData.append("avatar", value)
          } else if (key === "isNationaliteEtrangere") {
               formData.append(`data[${key}]`, value ? "1" : "0")
          } else {
               formData.append(`data[${key}]`, value)
          }
     }

     if (payload.avatar) formData.append("avatar", payload.avatar)

     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SET_INFO_PERSO_DETAILS_ENDPOINT(id), formData)
}
