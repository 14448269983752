import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getDemandeEmbaucheInfoClient } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import { IGetDemandeEmbaucheInfoClientResponseModel } from "./core/_models"
import DemandeEmbaucheDetailsInfoClientSalarie from "./_DemandeEmbaucheDetailsInfoClientSalarie"
import { CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION } from "@common-constants/*"
import { useDemandeEmbaucheDetailsContext } from "../DemandeEmbaucheDetails"
import DemandeEmbaucheDetailsInfoClientNonSalarie from "./_DemandeEmbaucheDetailsInfoClientNonSalarie"

const REACT_QUERY_KEY_GET_DE_INFO_CLIENT_QUERY = "REACT_QUERY_KEY_GET_DE_INFO_CLIENT_QUERY"
const DemandeEmbaucheDetailsInfoClient = () => {
     const context = useDemandeEmbaucheDetailsContext()
     const consultant_ID = parseInt(useParams<{ consultant_id: string }>().consultant_id as string)

     const infoClientQuery = useQuery<IGetDemandeEmbaucheInfoClientResponseModel, AxiosError>(REACT_QUERY_KEY_GET_DE_INFO_CLIENT_QUERY, async () => {
          return getDemandeEmbaucheInfoClient(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Is fetching
     if (infoClientQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />
               </div>
          )
     }

     // Error
     if (!infoClientQuery.isFetching && infoClientQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{infoClientQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => infoClientQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     if (!infoClientQuery.isFetching && infoClientQuery.data) {
          // Render form salarié
          if (infoClientQuery.data.consultant.contrat !== "CONTRAT_ST") {
               return infoClientQuery.data.consultant.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION ? (
                    <DemandeEmbaucheDetailsInfoClientSalarie data={infoClientQuery.data} />
               ) : (
                    <div className={"d-flex flex-column"}>
                         <MyAlert type={"primary"}>Aucune information à saisir dans cette partie. Vous pouvez passer à l'étape suivant</MyAlert>
                         <div className="separator my-5" />
                         <div className="d-flex justify-content-end">
                              <button className={"btn btn-primary btn-sm d-flex align-items-center"} onClick={() => context.setNavigationStep("odm")}>
                                   Suivant <i className={"fas fa-arrow-right ms-1"} />
                              </button>
                         </div>
                    </div>
               )
          } else {
               // render form non salarié
               return <DemandeEmbaucheDetailsInfoClientNonSalarie data={infoClientQuery.data} />
          }
     }

     return <></>
}

export default DemandeEmbaucheDetailsInfoClient
