import axios from "axios"
import {
     CONST_API_BUSINESS_CONSULTANT_DDC_POST_LISTING_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DDC_POST_REJET_PAR_RESPONSABLE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_DDC_POST_VALIDATION_PAR_RESPONSABLE_ENDPOINT,
} from "../../../../../endpoints"
import { IConsultantDdcModel } from "@common-models/*"
import { IGetDemandesCongesListingRequestModel, IGetDemandesCongesResponseModel } from "./_models"

export function getDdcListingRequest(payload: IGetDemandesCongesListingRequestModel) {
     return axios.post<IGetDemandesCongesResponseModel>(CONST_API_BUSINESS_CONSULTANT_DDC_POST_LISTING_ENDPOINT, payload)
}

export function validationDdcRequest(id: number) {
     return axios.post<IConsultantDdcModel>(CONST_API_BUSINESS_CONSULTANT_DDC_POST_VALIDATION_PAR_RESPONSABLE_ENDPOINT(id), {})
}

export function rejetDdcRequest(id: number, raison) {
     return axios.post<IConsultantDdcModel>(CONST_API_BUSINESS_CONSULTANT_DDC_POST_REJET_PAR_RESPONSABLE_ENDPOINT(id), {
          raison,
     })
}
