import axios from "axios"
import { IPersonnelArretMaladieModel } from "@common-models/*"
import { CONST_API_PERSONNEL_DECLARATION_MALADIE_CREATE_ENDPOINT } from "../../../../../endpoints"
import { ICreateConnectedPersonnelMaladieRequestModel } from "./_models"

export function createConnectedPersonnelMaladieRequest(payload: ICreateConnectedPersonnelMaladieRequestModel) {
     const formData = new FormData()
     formData.append("data[du]", payload.du as string)
     formData.append("data[au]", payload.au as string)
     if (payload.justificatif) formData.append("justificatif", payload.justificatif)
     return axios.post<IPersonnelArretMaladieModel>(CONST_API_PERSONNEL_DECLARATION_MALADIE_CREATE_ENDPOINT, formData)
}
