import React, { useState } from "react"
import * as Yup from "yup"
import { FieldArray, Form, Formik, FormikHelpers } from "formik"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useConnectedUserNdfDepensesContext } from "../_PersonnelNdfDepenses"
import { AxiosError } from "axios"
import { ICreateConnectedUserNdfDepenseSimpleRequestModel } from "../../../../mes-activites/ndf/core/_models"
import MyAlert from "@common-utils/MyAlert"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
} from "@common-constants/*"
import MySelectField from "@common-utils/fields/MySelectField"
import { IHttpErrorResponseModel, IPersonnelNdfDetailModel } from "@common-models/*"
import MyInputField from "@common-utils/fields/MyInputField"
import { createPersonnelNdfDepenseSimpleRequest } from "../../core/_requests"
import MySelectCreatableField from "@common-utils/fields/MySelectCreatableField"
import MyTextareaField from "@common-utils/fields/MyTextareaField"

export const ConnectedUserNdfDepenseCreateSimple = () => {
     const queryClient = useQueryClient()
     const context = useConnectedUserNdfDepensesContext()
     const [actionAfterSubmitForm, setActionAfterSubmitForm] = useState<"close" | "keepAddingDetails">("close")

     // Schema
     const schema = Yup.object().shape({
          date: Yup.string().required("Champ requis"),
          categorie: Yup.string().required("Champ requis"),
          montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect."),
          documents: Yup.array().of(
               Yup.mixed()
                    .required("PJ requise")
                    .test("fileSize", "La taille de la PJ doit être inférieure ou égale à 10MB", (value: any) => {
                         return value && value.size <= 10000000
                    })
                    .test("fileFormat", "La PJ doit être au format JPEG, PNG ou PDF", (value: any) => {
                         return value && ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                    })
          ),
          repasSeulOuEnGroupe: Yup.string()
               .label(`"Seul ou en groupe"`)
               .when("categorie", {
                    is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                    then: schema => schema.required(),
               }),
          invites: Yup.array()
               .label(`"Invités"`)
               .when("repasSeulOuEnGroupe", {
                    is: "REPAS_MIDI_EN_GROUPE",
                    then: schema => schema.required(),
               }),
          description: Yup.string().required().label(`"Description"`),
     })

     const initialValues: ICreateConnectedUserNdfDepenseSimpleRequestModel = {
          documents: [new File([new Blob()], "")],
     }

     function handleSubmit(values: ICreateConnectedUserNdfDepenseSimpleRequestModel, helpers: FormikHelpers<ICreateConnectedUserNdfDepenseSimpleRequestModel>) {
          helpers.setStatus(null)
          createPersonnelNdfDepenseSimpleRequest(context.ndfId, values)
               .then(r => {
                    queryClient.setQueryData([context.REACT_QUERY_KEY_GET_LISTING, context.ndfId], (details: IPersonnelNdfDetailModel[] | undefined) => {
                         if (details) {
                              const dataWithNewDepense = [r.data, ...details]
                              context.computeTotalExpenses(dataWithNewDepense || [])
                              return dataWithNewDepense
                         }

                         return []
                    })

                    helpers.setSubmitting(false)

                    if (actionAfterSubmitForm === "close") {
                         toast.success("Votre justificatif a bien été ajouté.")
                         context.setIsAddDepenseModalShown(false)
                         context.setTypeAddDepense(null)
                         context.setIsCardDepenseSimpleCollapsed(false)
                    } else {
                         toast.success("Votre justificatif a bien été ajouté. Vous pouvez continuer à ajouter un autre si vous le souhaitez.")
                         helpers.resetForm()
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)

                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {({ values, setFieldValue, isSubmitting, status, errors }) => {
                    return (
                         <Form noValidate autoComplete="off">
                              {status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {status}
                                   </MyAlert>
                              )}
                              <div className={"mb-4"}>
                                   <label className="form-label fw-bolder  fs-6">Justificatifs</label>
                                   <FieldArray name={"documents"}>
                                        {({ push, remove }) => (
                                             <div className={"row"}>
                                                  {values.documents!.map((_, index) => {
                                                       return (
                                                            <div className={"col-md-6 col-lg-4 mb-4"} key={index}>
                                                                 <div className="d-flex">
                                                                      <input
                                                                           type={"file"}
                                                                           name={`documents[${index}]`}
                                                                           onChange={e => setFieldValue(`documents[${index}]`, e.target.files![0])}
                                                                           className={"form-control me-2"}
                                                                      />
                                                                      {index > 0 && (
                                                                           <button className={"btn btn-light-danger btn-sm"} type={"button"} onClick={() => remove(index)}>
                                                                                <span className={"fas fa-minus-circle"}></span>
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                                 {errors.documents && errors.documents[index] && <div className={"text-danger"}>{errors.documents[index]}</div>}
                                                                 <div className={"d-flex justify-content-sm-start justify-content-center mt-4"}>
                                                                      {index === values.documents!.length - 1 && (
                                                                           <button
                                                                                className={`btn btn-light-primary btn-sm ${index > 0 && "me-2"}`}
                                                                                type={"button"}
                                                                                onClick={() => push({})}
                                                                           >
                                                                                Ajouter un autre justificatif <i className={"fas fa-plus-circle ps-1 pe-0"} />
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       )
                                                  })}
                                             </div>
                                        )}
                                   </FieldArray>
                              </div>

                              <label className="form-label fw-bolder fs-6">Détails</label>
                              <div className={"row mb-4"}>
                                   <div className={"col-md-4 mb-md-0 mb-2"}>
                                        <MyDateDayMonthYearField
                                             onChange={date => setFieldValue(`date`, date)}
                                             value={values.date}
                                             name={`date`}
                                             placeholder={"Date"}
                                             isInvalid={!!errors.date}
                                        />
                                        {errors.date && <div className={"text-danger"}>{errors.date}</div>}
                                   </div>
                                   <div className={"col-md-4 mb-md-0 mb-2"}>
                                        <MySelectField
                                             options={CONST_PERSONNEL_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                             name={`categorie`}
                                             isSearchable={false}
                                             isClearable={false}
                                             placeholder={"Catégorie"}
                                             value={values.categorie}
                                             onChange={value => setFieldValue(`categorie`, value)}
                                             isInvalid={!!errors.categorie}
                                        />
                                        {errors.categorie && <div className={"text-danger"}>{errors.categorie}</div>}
                                   </div>
                                   <div className={"col-md-4 mb-md-0 mb-2"}>
                                        <MyInputField
                                             name={"montantTTC"}
                                             value={values.montantTTC}
                                             isInvalid={!!errors.montantTTC}
                                             onChange={val => setFieldValue("montantTTC", val)}
                                             inputMode={"numeric"}
                                             type={"number"}
                                             placeholder={"Montant TTC"}
                                        />
                                        {errors.montantTTC && <div className={"text-danger"}>{errors.montantTTC}</div>}
                                   </div>
                                   {values.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                        <>
                                             <div className={"col-12 mt-5"}>
                                                  <MyAlert type={"primary"} classNames={"mb-2"}>
                                                       Veuillez indiquer s'il s'agit d'un repas seul ou en groupe
                                                  </MyAlert>
                                                  <MySelectField
                                                       name={"repasSeulOuEnGroupe"}
                                                       options={[
                                                            {
                                                                 value: "REPAS_MIDI_SEUL",
                                                                 label: "Repas seul",
                                                            },
                                                            {
                                                                 value: "REPAS_MIDI_EN_GROUPE",
                                                                 label: "Repas en groupe",
                                                            },
                                                       ]}
                                                       value={values.repasSeulOuEnGroupe}
                                                       onChange={val => setFieldValue("repasSeulOuEnGroupe", val)}
                                                       isInvalid={!!errors.repasSeulOuEnGroupe}
                                                       placeholder={"Choisissez si seul ou en groupe"}
                                                  />
                                                  {errors.repasSeulOuEnGroupe && <div className={"text-danger"}>{errors.repasSeulOuEnGroupe}</div>}
                                             </div>

                                             {values.repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                  <>
                                                       <div className={"col-12 mt-2"}>
                                                            <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                 Veuillez fournir les noms et prénoms de vos invités. S'il s'agit d'un client, ajoutez le nom de la société en plus.
                                                            </MyAlert>
                                                            <MySelectCreatableField
                                                                 name={"invites"}
                                                                 options={[]}
                                                                 value={values.invites}
                                                                 onChange={val => setFieldValue("invites", val)}
                                                                 isInvalid={!!errors.invites}
                                                                 placeholder={"Saisissez les invités"}
                                                            />
                                                            {errors.invites && <div className={"text-danger"}>{errors.invites}</div>}
                                                       </div>
                                                  </>
                                             )}
                                        </>
                                   )}

                                   <div className={"col-12 mt-5"}>
                                        <MyTextareaField
                                             name={"description"}
                                             value={values.description}
                                             isInvalid={!!errors.description}
                                             rows={2}
                                             onChange={val => setFieldValue("description", val)}
                                             placeholder={"Description"}
                                        />
                                        {errors.description && <div className={"text-danger"}>{errors.description}</div>}
                                   </div>
                              </div>

                              <div className="separator separator-dashed bg-primary my-4" />
                              <div className="text-center pt-6 d-flex flex-sm-row flex-column justify-content-center">
                                   <button
                                        className={"btn btn-sm btn-primary me-2 mb-sm-0 mb-2"}
                                        type={"submit"}
                                        disabled={isSubmitting}
                                        onClick={() => setActionAfterSubmitForm("close")}
                                   >
                                        {!isSubmitting && (
                                             <>
                                                  Finaliser l'ajout <i className={"fas fa-check-circle align-middle"} />
                                             </>
                                        )}
                                        {isSubmitting && (
                                             <span className="indicator-progress" style={{ display: "block" }}>
                                                  Ajout en cours
                                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                             </span>
                                        )}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}
