import React from "react"
import withReactContent, { ReactSweetAlert } from "sweetalert2-react-content"
import type swal from "sweetalert2"
import Swal from "sweetalert2"

type SweetAlert2 = typeof swal

interface ISwalHookReturn {
     swal: SweetAlert2 & ReactSweetAlert
}

const useSwal = (): ISwalHookReturn => {
     const swal = withReactContent(Swal)

     return { swal }
}

export default useSwal
