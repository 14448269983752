import axios from "axios"
import { CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_LISTING_ENDPOINT, CONST_API_BUSINESS_CONSULTANT_RELANCE_DECLARATION_ENDPOINT } from "../../../../../../endpoints"
import { IGetConsultantsDeclarationsRequestModel, IGetConsultantsDeclarationsResponseModel, IRelanceCollabDeclarationActiviteRequestModel } from "./_models"

export function getConsultantsDeclarationsListing(payload: IGetConsultantsDeclarationsRequestModel) {
     return axios.post<IGetConsultantsDeclarationsResponseModel>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_LISTING_ENDPOINT, payload)
}

export function relanceConsultantsDeclarationActiviteRequest(payload: IRelanceCollabDeclarationActiviteRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_RELANCE_DECLARATION_ENDPOINT, payload)
}
