import React, { useState } from "react"
import MyCard from "@common-utils/MyCard"
import BonsLivraisonDashboardVoyantsManquantsOfGivenYear from "./_BonsLivraisonDashboardVoyantsManquantsOfGivenYear"
import BonsLivraisonDashboardVoyantsNonFacturesOfGivenYear from "./_BonsLivraisonDashboardVoyantsNonFacturesOfGivenYear"
import { useAuth } from "../../../../AppContext"
import MyAlert from "@common-utils/MyAlert"

const BonsLivraisonDashboard = () => {
     const { currentUser } = useAuth()
     const [onglet, setOnglet] = useState<"NON_FACTURES" | "MANQUANTS" | "FACTURES">(currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? "NON_FACTURES" : "MANQUANTS")

     const ButtonsManquantsAndFactures = () => {
          return (
               <>
                    {currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" && (
                         <button className={`btn btn-sm ${onglet === "NON_FACTURES" ? "btn-dark" : "btn-outline btn-outline-dark"} me-2`} onClick={() => setOnglet("NON_FACTURES")}>
                              Non facturés
                         </button>
                    )}
                    <button className={`btn btn-sm ${onglet === "MANQUANTS" ? "btn-dark" : "btn-outline btn-outline-dark"} me-2`} onClick={() => setOnglet("MANQUANTS")}>
                         Manquants
                    </button>
                    <button className={`btn btn-sm ${onglet === "FACTURES" ? "btn-dark" : "btn-outline btn-outline-dark"}`} onClick={() => setOnglet("FACTURES")}>
                         Facturés
                    </button>
               </>
          )
     }

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-70"}>
                    <MyCard>
                         <MyCard.Header rightSideContent={<ButtonsManquantsAndFactures />}>Gestion des BL</MyCard.Header>
                         <MyCard.Body>
                              {onglet === "NON_FACTURES" && <BonsLivraisonDashboardVoyantsNonFacturesOfGivenYear />}
                              {onglet === "MANQUANTS" && <BonsLivraisonDashboardVoyantsManquantsOfGivenYear />}
                              {onglet === "FACTURES" && <MyAlert type={"primary"}>Temporairement indisponible</MyAlert>}
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default BonsLivraisonDashboard
