import {
     CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_APPLICATION_DOCX,
     CONST_MIME_APPLICATION_PDF,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
} from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { IConsultantDocumentModel, IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import MyAlert from "@common-utils/MyAlert"
import MyFileField from "@common-utils/fields/MyFileField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { addConsultantDocumentRequest } from "../../../core/_requests"
import * as Yup from "yup"

const ConsultantAddAvenantContratForm = ({ consultant, callback = () => {} }: IConsultantAddContratFormPropsModel) => {
     // Form schema
     const schema = Yup.object().shape({
          dateDebutValidite: Yup.string().label(`"Date début de validité"`).required(),
          document: Yup.mixed()
               .required()
               .test("fileFormat", "Seuls les formats Word, PDF ou JPEG sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_DOCX, CONST_MIME_APPLICATION_PDF, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG, CONST_MIME_IMAGE_PNG].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Document"`),
     })

     // Form initial values
     const initialValues: IConsultantAddContratFormikValues = {}

     // Formik handle submit
     function handleSubmit(values: IConsultantAddContratFormikValues, helpers: FormikHelpers<IConsultantAddContratFormikValues>) {
          helpers.setStatus(null)

          addConsultantDocumentRequest(consultant.id as number, CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT, values.document as File, values.dateDebutValidite)
               .then(r => {
                    toast.success("L'avenant a bien été ajouté")
                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Date début de validité */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de début de validité</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateDebutValidite"}
                                                  value={helpers.values.dateDebutValidite}
                                                  isInvalid={!!helpers.errors.dateDebutValidite}
                                                  onChange={val => helpers.setFieldValue("dateDebutValidite", val)}
                                                  placeholder={"Date de début"}
                                             />
                                             {helpers.errors.dateDebutValidite && <div className={"text-danger"}>{helpers.errors.dateDebutValidite}</div>}
                                        </div>
                                   </div>

                                   {/* Document */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Document</label>
                                        <div className="col-lg-8">
                                             <MyFileField name={"document"} isInvalid={!!helpers.errors.document} onChange={val => helpers.setFieldValue("document", val)} />
                                             {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? (
                                                  <>
                                                       Ajouter <i className={"fas fa-plus-circle fs-4 ms-1"} />
                                                  </>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IConsultantAddContratFormikValues {
     document?: File
     dateDebutValidite?: string
}

interface IConsultantAddContratFormPropsModel {
     consultant: IConsultantModel
     callback: (val: IConsultantDocumentModel) => void
}

export default ConsultantAddAvenantContratForm
