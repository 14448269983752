import { CONST_CONSULTANT_MISSION_TYPE_PERMANENT, CONST_CONSULTANT_MISSION_TYPE_PONCTUEL, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { IConsultantMissionModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { setConsultantMissionDetails } from "../../../core/_requests"
import * as Yup from "yup"
import { ISetConsultantMissionDetailsRequestModel } from "../../../core/_models"
import MySelectField from "@common-utils/fields/MySelectField"

const ConsultantEditMissionDetailsForm = ({ mission, callback = () => {} }: IConsultantEditMissionFormPropsModel) => {
     // Form schema
     const schema = Yup.object().shape({
          type: Yup.string().label(`"Type"`).required(),
          dateDebutValidite: Yup.string().label(`"Date début de validité"`).required(),
          dateFinValidite: Yup.string().label(`"Date fin de validité"`).required(),
     })

     // Form initial values
     const initialValues: ISetConsultantMissionDetailsRequestModel = {
          dateDebutValidite: mission.dateDebutValidite?.format("YYYY-MM-DD"),
          dateFinValidite: mission.dateFinValidite?.format("YYYY-MM-DD"),
          type: mission.type,
     }

     // Formik handle submit
     function handleSubmit(values: ISetConsultantMissionDetailsRequestModel, helpers: FormikHelpers<ISetConsultantMissionDetailsRequestModel>) {
          helpers.setStatus(null)

          setConsultantMissionDetails(mission.id as number, values)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Type */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Type</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"type"}
                                                  value={helpers.values.type}
                                                  options={[
                                                       { value: CONST_CONSULTANT_MISSION_TYPE_PERMANENT, label: "Permanent" },
                                                       { value: CONST_CONSULTANT_MISSION_TYPE_PONCTUEL, label: "Ponctuel" },
                                                  ]}
                                                  isInvalid={!!helpers.errors.dateFinValidite}
                                                  onChange={val => helpers.setFieldValue("type", val)}
                                                  placeholder={"Type"}
                                             />
                                             {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}
                                        </div>
                                   </div>

                                   {/* Date début de validité */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de début de validité</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateDebutValidite"}
                                                  value={helpers.values.dateDebutValidite}
                                                  isInvalid={!!helpers.errors.dateDebutValidite}
                                                  maxDate={helpers.values.dateFinValidite}
                                                  onChange={val => helpers.setFieldValue("dateDebutValidite", val)}
                                                  placeholder={"Date de début"}
                                             />
                                             {helpers.errors.dateDebutValidite && <div className={"text-danger"}>{helpers.errors.dateDebutValidite}</div>}
                                        </div>
                                   </div>

                                   {/* Date fin de validité */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de fin de validité</label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateFinValidite"}
                                                  value={helpers.values.dateFinValidite}
                                                  minDate={helpers.values.dateDebutValidite}
                                                  isInvalid={!!helpers.errors.dateFinValidite}
                                                  onChange={val => helpers.setFieldValue("dateFinValidite", val)}
                                                  placeholder={"Date de début"}
                                             />
                                             {helpers.errors.dateFinValidite && <div className={"text-danger"}>{helpers.errors.dateFinValidite}</div>}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IConsultantEditMissionFormPropsModel {
     mission: IConsultantMissionModel
     callback: (val: IConsultantMissionModel) => void
}

export default ConsultantEditMissionDetailsForm
