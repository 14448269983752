import React, { createContext, useContext, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { ICandidatDocumentModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { getDocos } from "../../../../core/_requests"
import { useSearchResultsContext } from "../../CandidatsRechercheResults"
import { useSearchResultsPreviewContext } from "../_CandidatsRechercheResultsPreview"
import MyAlert from "@common-utils/MyAlert"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyTooltip from "@common-utils/MyTooltip"
import { ISearchResultsDataItemResponseModel, ISearchResultsPreviewDocoListingContextPropsModel } from "../../core/_models"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MyModal from "@common-utils/MyModal"
import useSwal from "@common-hooks/useSwal"
import { deleteDoco, lockDoco, unlockDoco } from "../../../../doco/core/_requests"
import CandidatDocoPartagerParMailForm from "../../../../doco/_CandidatDocoPartagerParMailForm"
import CandidatGenererDocoEtNotifierCandidatParMailForm from "../../../../actions/_CandidatGenererDocoEtNotifierCandidatParMailForm"

const REACT_QUERY_KEY_GET_DOCOS_QUERY = "REACT_QUERY_KEY_GET_DOCOS_QUERY"

const DocoItem = ({ item, index }: { item: ICandidatDocumentModel; index: number }) => {
     const { swal } = useSwal()
     const globalContext = useSearchResultsContext()
     const docoListingContext = useDocoListingContext()
     const queryClient = useQueryClient()

     // Template item
     function deleteItem() {
          return swal.fire({
               icon: "warning",
               title: `Vous êtes sur le point de supprimer le DoCo "${item.intitule ? item.intitule : "DoCo n°${index + 1}"}"`,
               text: "Voulez-vous continuer?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deleteDoco(item.id as number)
                         .then(() => {
                              queryClient.setQueryData<ICandidatDocumentModel[] | undefined>(
                                   [REACT_QUERY_KEY_GET_DOCOS_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id],
                                   (prev: ICandidatDocumentModel[] | undefined) => {
                                        if (prev) {
                                             return prev.filter(item_ => item_.id !== item.id)
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Le DoCo a bien été supprimé. il n'apparaitra plus dans l'espace du candidat. ")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          })
     }

     // Lock item
     function lockItem() {
          lockDoco(item.id as number)
               .then(() => {
                    queryClient.setQueryData<ICandidatDocumentModel[] | undefined>(
                         [REACT_QUERY_KEY_GET_DOCOS_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id],
                         (prev: ICandidatDocumentModel[] | undefined) => {
                              if (prev) {
                                   const index = prev.findIndex(item_ => item_.id === item.id)
                                   prev[index] = { ...prev[index], isLocked: true }
                                   return prev
                              }

                              return prev
                         }
                    )
                    toast.success("Le DoCo a bien été verouillé.")
               })
               .catch(e => {
                    toast.error(e?.response?.data?.detail, { autoClose: false })
               })
     }

     // Unlock item
     function unlockItem() {
          unlockDoco(item.id as number)
               .then(() => {
                    queryClient.setQueryData<ICandidatDocumentModel[] | undefined>(
                         [REACT_QUERY_KEY_GET_DOCOS_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id],
                         (prev: ICandidatDocumentModel[] | undefined) => {
                              if (prev) {
                                   const index = prev.findIndex(item_ => item_.id === item.id)
                                   prev[index] = { ...prev[index], isLocked: false }
                                   return prev
                              }

                              return prev
                         }
                    )
                    toast.success("Le DoCo a bien été déverouillé.")
               })
               .catch(e => {
                    toast.error(e?.response?.data?.detail, { autoClose: false })
               })
     }

     return (
          <div className={"d-flex justify-content-center text-center flex-column"}>
               <span
                    className="text-gray-800 text-hover-primary d-flex flex-column cursor-pointer"
                    onClick={() => docoListingContext.setSelectedDocoInPreview({ item: item, mode: "view", loading: true })}
               >
                    <div className="symbol symbol-60px mb-5">
                         {item.isLocked && (
                              <span
                                   className={"fas fa-lock fs-3"}
                                   style={{
                                        left: "43%",
                                        top: "-12%",
                                        position: "absolute",
                                        color: "lightslategrey",
                                   }}
                              />
                         )}
                         <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-light-show" alt="" />
                         <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-dark-show" alt="" />
                    </div>
                    <div className="fs-5 fw-bold mb-3">{item.intitule ? item.intitule : `DoCo n°${index + 1}`}</div>
               </span>
               <div className={"mb-4 d-flex justify-content-center"}>
                    <MyTooltip title={"Voir / Editer"}>
                         <button
                              type={"button"}
                              className={"btn btn-outline btn-outline-success d-flex align-items-center p-0 px-2 py-1 me-2"}
                              onClick={() => docoListingContext.setSelectedDocoInPreview({ item: item, mode: "view", loading: true })}
                         >
                              <span className={"fas fa-eye fs-4"} />
                         </button>
                    </MyTooltip>
                    <MyTooltip title={"Partager par mail"}>
                         <button
                              type={"button"}
                              className={"btn btn-outline btn-outline-primary d-flex align-items-center p-0 px-2 py-1 me-2"}
                              onClick={() => docoListingContext.setDocoPartagerParMail(item)}
                         >
                              <span className={"fas fa-share fs-4"} />
                         </button>
                    </MyTooltip>
                    <MyTooltip title={"Télécharger au format PDF"}>
                         <a
                              href={FileAndDriveHelper.getUrlExportToPdfFormat(item.googleDriveID as string)}
                              target={"_blank"}
                              className={"btn btn-outline btn-outline-dark d-flex align-items-center p-0 px-2 py-1 me-2"}
                         >
                              <span className={"fas fa-file-pdf fs-4"} />
                         </a>
                    </MyTooltip>
                    <MyTooltip title={item.isLocked ? "Déverrouiller" : "Verrouiller"}>
                         <button
                              type={"button"}
                              className={`btn ${item.isLocked ? "btn-warning" : "btn-outline btn-outline-warning"} d-flex align-items-center p-0 px-2 py-1 me-2`}
                              onClick={() => (item.isLocked ? unlockItem() : lockItem())}
                         >
                              <span className={`fas fa-${item.isLocked ? "lock" : "lock-open"} fs-4`} />
                         </button>
                    </MyTooltip>
                    <MyTooltip title={"Supprimer"}>
                         <button type={"button"} className={"btn btn-outline btn-outline-danger d-flex align-items-center p-0 px-2 py-1 me-2"} onClick={deleteItem}>
                              <span className={"fas fa-trash fs-4"} />
                         </button>
                    </MyTooltip>
               </div>
               <div className="fs-7 fw-semibold text-dark mb-2">
                    <div className={"mb-1"}>
                         Ajouté le <b>{item.createdAt?.format("DD/MM/YYYY")}</b> {item.autoGeneratedPar && <b>par {item.autoGeneratedPar.prenomNom}</b>}
                    </div>
                    <div>
                         {item.remplisTerminadoParCandidat ? (
                              <span className={"text-success"}>
                                   Complété et envoyé <i className={"fas fa-check-circle text-success fs-3"} />{" "}
                              </span>
                         ) : (
                              <span className={"text-warning"}>
                                   En attente de réception <i className={"fas fa-exclamation-circle text-warning fs-3"} />{" "}
                              </span>
                         )}
                    </div>
               </div>
          </div>
     )
}

const DocoListingContext = createContext<ISearchResultsPreviewDocoListingContextPropsModel>({} as ISearchResultsPreviewDocoListingContextPropsModel)
const useDocoListingContext = () => useContext(DocoListingContext)
export const CandidatsRechercheResultsPreviewHelpersDocos = () => {
     const queryClient = useQueryClient()
     const globalContext = useSearchResultsContext()
     const previewContext = useSearchResultsPreviewContext()
     const previewItem = globalContext.useListingQuery.data?.data.find(
          item_ => item_.candidat.id === globalContext.itemInPreview?.selectedItem!.candidat.id
     ) as ISearchResultsDataItemResponseModel
     const [selectedDocoInPreview, setSelectedDocoInPreview] = useState<{ item: ICandidatDocumentModel; mode: "view" | "edit"; loading: boolean } | null>(null)
     const [docoPartagerParMail, setDocoPartagerParMail] = useState<ICandidatDocumentModel | null>(null)
     const [isGenererDocoEtNotifierCandidatModalShown, setIsGenererDocoEtNotifierCandidatModalShown] = useState<boolean>(false)
     const docosQuery = useQuery<ICandidatDocumentModel[], AxiosError>([REACT_QUERY_KEY_GET_DOCOS_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id], () => {
          return getDocos(globalContext.itemInPreview?.selectedItem.candidat.id as number)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
               .finally(() => {
                    // Stop loading preview
                    previewContext.setIsPreviewBodyLoading(false)
                    globalContext.setItemInPreview(prev =>
                         prev
                              ? {
                                     ...prev,
                                     isSelectedItemLoading: false,
                                }
                              : null
                    )
               })
     })

     // Preview & edit Doco in modal
     const ItemPreviewInModal = useMemo(() => {
          return selectedDocoInPreview ? (
               <MyModal title={<span>{selectedDocoInPreview?.item.intitule}</span>} show={true} handleClose={() => setSelectedDocoInPreview(null)} size={"xl"} noPadding>
                    <>
                         <MyLoadingOverlay isActive={selectedDocoInPreview!.loading}>
                              <iframe
                                   onLoad={() => setSelectedDocoInPreview(prev => (prev ? { ...prev, loading: false } : null))}
                                   src={
                                        selectedDocoInPreview!.mode === "view"
                                             ? FileAndDriveHelper.getUrlOfDriveFilePreview(selectedDocoInPreview!.item.googleDriveID as string)
                                             : FileAndDriveHelper.getUrlOfDriveFileEditMode(selectedDocoInPreview!.item.googleDriveID as string)
                                   }
                                   style={{ height: "80vh", overflowY: "scroll", width: "100%" }}
                              />
                         </MyLoadingOverlay>
                         {/* Utils: Doco */}
                         <div className={"vertical-container-of-button-on-iframe bg-secondary rounded text-center"}>
                              {/* View / Edit Mode */}
                              <MyTooltip title={selectedDocoInPreview!.mode === "view" ? "Passer en mode édition" : "Passer en mode visualisation"} placement={"right"}>
                                   <button
                                        type={"button"}
                                        className={"btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle"}
                                        onClick={() => setSelectedDocoInPreview(prev => (prev ? { ...prev, mode: prev.mode === "view" ? "edit" : "view", loading: true } : null))}
                                   >
                                        {selectedDocoInPreview!.mode === "view" ? <i className={"fas fa-edit"} /> : <i className={"fas fa-eye"} />}
                                   </button>
                              </MyTooltip>
                         </div>
                    </>
               </MyModal>
          ) : (
               <></>
          )
     }, [selectedDocoInPreview])

     // Form partager DoCo par mail
     const PartagerDocoParMailFormInModal = useMemo(() => {
          return docoPartagerParMail ? (
               <MyModal title={<span>Partage du DoCo "{docoPartagerParMail?.intitule}" par mail</span>} show={true} handleClose={() => setDocoPartagerParMail(null)} size={"lg"}>
                    <CandidatDocoPartagerParMailForm
                         candidatDoco={{ id: docoPartagerParMail.id as number, intitule: docoPartagerParMail.intitule as string }}
                         handleSubmitCallBack={() => setDocoPartagerParMail(null)}
                    />
               </MyModal>
          ) : (
               <></>
          )
     }, [docoPartagerParMail])

     // Form générer DoCo et notifier candidat par mail
     const GenererDocoEtNotifierCandidatParMailFormInModal = useMemo(() => {
          function handleSubmitCallBack(newDoco: ICandidatDocumentModel) {
               queryClient.setQueryData<ICandidatDocumentModel[] | undefined>(
                    [REACT_QUERY_KEY_GET_DOCOS_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id],
                    (prev: ICandidatDocumentModel[] | undefined) => {
                         if (prev) {
                              return [newDoco, ...prev]
                         }

                         return prev
                    }
               )
               setIsGenererDocoEtNotifierCandidatModalShown(false)
          }
          return isGenererDocoEtNotifierCandidatModalShown ? (
               <MyModal
                    title={<span>Générer un DoCo vierge et notifier le candidat par mail</span>}
                    show={true}
                    handleClose={() => setIsGenererDocoEtNotifierCandidatModalShown(false)}
                    size={"lg"}
               >
                    <CandidatGenererDocoEtNotifierCandidatParMailForm
                         candidat={{ id: previewItem.candidat?.id as number, prenomNom: previewItem.candidat?.prenomNom as string, email: previewItem.candidat?.email as string }}
                         handleSubmitCallBack={handleSubmitCallBack}
                    />
               </MyModal>
          ) : (
               <></>
          )
     }, [isGenererDocoEtNotifierCandidatModalShown])

     return (
          <DocoListingContext.Provider value={{ selectedDocoInPreview, setSelectedDocoInPreview, docosQuery, docoPartagerParMail, setDocoPartagerParMail }}>
               <>
                    <div className={"preview-height text-center py-8 px-4"}>
                         <button
                              className={"btn btn-sm btn-outline btn-outline-primary fw-bold"}
                              type={"button"}
                              onClick={() => setIsGenererDocoEtNotifierCandidatModalShown(true)}
                         >
                              Générer un DoCo vierge et l'envoyer au candidat <i className={"fas fa-paper-plane ms-1"} />
                         </button>
                         <div className="separator separator-dotted border-primary mt-4 mb-4" />
                         {/* In case of error */}
                         {docosQuery.error ? (
                              <MyAlert type={"danger"}>{docosQuery.error.response?.data.detail}</MyAlert>
                         ) : (
                              <>
                                   {/* CASE: If some data */}
                                   {docosQuery.data && docosQuery.data.length > 0 && (
                                        <div className="row">
                                             {docosQuery.data.map((item: ICandidatDocumentModel, index) => (
                                                  <div className="col-12 col-md-6 mb-8" key={index}>
                                                       <DocoItem item={item} index={index} />
                                                  </div>
                                             ))}
                                        </div>
                                   )}
                              </>
                         )}
                    </div>

                    {selectedDocoInPreview && <>{ItemPreviewInModal}</>}
                    {docoPartagerParMail && <>{PartagerDocoParMailFormInModal}</>}
                    {isGenererDocoEtNotifierCandidatModalShown && <>{GenererDocoEtNotifierCandidatParMailFormInModal}</>}
               </>
          </DocoListingContext.Provider>
     )
}
