import { CONST_CIVILITE_MME } from "@common-constants/*"
import Swal from "sweetalert2"
import useSwal from "@common-hooks/useSwal"
import { useAuth } from "../../../../../../AppContext"
import { IPersonnelDdcModel } from "@common-models/*"
import { rejetPersonnelDdcRequest, validationPersonnelDdcRequest } from "./_requests"
import { toast } from "react-toastify"

export const useDdcActions = () => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()

     function validation(ddc_ID: number, callback: (val: IPersonnelDdcModel) => void) {
          swal.fire({
               icon: "warning",
               title: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir valider cette demande?`,
               text: "Un mail de confirmation sera envoyé au collaborateur",
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return validationPersonnelDdcRequest(ddc_ID)
                         .then(r => {
                              toast.success("La demande a bien été acceptée et un mail sera envoyé au collaborateur.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function rejet(ddc_ID: number, callback: (val: IPersonnelDdcModel) => void) {
          swal.fire({
               icon: "warning",
               title: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir rejeter cette demande?`,
               text: "Un mail de rejet sera envoyé au collaborateur",
               input: "textarea",
               inputPlaceholder: "Raison du rejet ...",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter la raison du rejet") : resolve()
                    })
               },
               preConfirm: async (raison: string) => {
                    return rejetPersonnelDdcRequest(ddc_ID, raison)
                         .then(r => {
                              toast.success("La demande a bien été rejetée et un mail sera envoyé au collaborateur.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return { validation, rejet }
}
