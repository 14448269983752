import React, { useState } from "react"
import MyTooltip from "@common-utils/MyTooltip"
import {
     CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE,
     CONST_CANDIDAT_TYPE_SAISI,
     CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL,
     CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_FRANCE,
     CONST_MOBILITE_FRANCE_HORS_IDF,
     CONST_MOBILITE_IDF,
     CONST_MOBILITE_INTERNATIONAL,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
} from "@common-constants/*"
import { useSearchResultsListItemContext } from "./_CandidatsRechercheResultsListItem"
import MyModal from "@common-utils/MyModal"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useSearchResultsContext } from "../CandidatsRechercheResults"
import { useAuth } from "../../../../../../AppContext"
import { ICandidatSuiviModel } from "@common-models/*"
import CandidatSuiviEditForm from "../../../candidatSuivi/_CandidatSuiviEditForm"

/* Statuts contacté & non retunu (connected user)*/
export function StatutsIconConnectedUser() {
     const listItemContext = useSearchResultsListItemContext()
     const item = listItemContext.item

     return (
          <>
               {item.connectedUserAddedStatusToCandidat && item.connectedUserAddedStatusToCandidat.length > 0 && (
                    <MyTooltip
                         placement={"right"}
                         title={
                              <>
                                   <p className={"fw-bold fs-4"}>Mes actions rapides</p>
                                   {item.connectedUserAddedStatusToCandidat.map((val, index) => {
                                        return (
                                             <span key={index}>
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL && (
                                                       <p>
                                                            <span className="me-2 fas fa-envelope" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL && (
                                                       <p>
                                                            <span className="me-2 fas fa-phone" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE && (
                                                       <p>
                                                            <span className="me-2 fas fa-globe" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES && (
                                                       <p>
                                                            <span className="me-2 fas fa-brain" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO && (
                                                       <p>
                                                            <span className="me-2 fas fa-calendar-check" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION && (
                                                       <p>
                                                            <span className="me-2 fas fa-euro-sign" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT && (
                                                       <p>
                                                            <span className="me-2 fas fa-running" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")}`}
                                                       </p>
                                                  )}
                                             </span>
                                        )
                                   })}
                              </>
                         }
                    >
                         <span className="ms-1 fas fa-info-circle text-primary fs-6" />
                    </MyTooltip>
               )}
          </>
     )
}

/* Statuts contacté & non retunu (other users)*/
export function StatutsIconOtherUsers() {
     const listItemContext = useSearchResultsListItemContext()
     const item = listItemContext.item

     return (
          <>
               {item.otherUsersAddedStatusToCandidat && item.otherUsersAddedStatusToCandidat.length > 0 && (
                    <MyTooltip
                         placement={"right"}
                         title={
                              <>
                                   <p className={"fw-bold fs-4"}>Autres actions rapides</p>
                                   {item.otherUsersAddedStatusToCandidat.map((val, index) => {
                                        return (
                                             <span key={index}>
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL && (
                                                       <p>
                                                            <span className="me-2 fas fa-envelope" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")} par ${val.personnel?.prenomNom}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL && (
                                                       <p>
                                                            <span className="me-2 fas fa-phone" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")} par ${val.personnel?.prenomNom}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE && (
                                                       <p>
                                                            <span className="me-2 fas fa-globe" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")} par ${val.personnel?.prenomNom}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES && (
                                                       <p>
                                                            <span className="me-2 fas fa-brain" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")} par ${val.personnel?.prenomNom}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO && (
                                                       <p>
                                                            <span className="me-2 fas fa-calendar-check" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")} par ${val.personnel?.prenomNom}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION && (
                                                       <p>
                                                            <span className="me-2 fas fa-euro-sign" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")} par ${val.personnel?.prenomNom}`}
                                                       </p>
                                                  )}
                                                  {val.contacteOrNotRetenuRaison === CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT && (
                                                       <p>
                                                            <span className="me-2 fas fa-running" />
                                                            {`${val.contacteOrNotRetenuRaisonLabel} le ${val.date?.format("DD/MM/YYYY")} par ${val.personnel?.prenomNom}`}
                                                       </p>
                                                  )}
                                             </span>
                                        )
                                   })}
                              </>
                         }
                    >
                         <span className="ms-1 fas fa-info-circle text-muted" />
                    </MyTooltip>
               )}
          </>
     )
}

/* Seen (eye), suivi (list), vivier (heart), liste traitement (inbox) icons (connected user & other users) */
export const SeenSuiviVivierListeTraitementStatutsIcons = () => {
     const listItemContext = useSearchResultsListItemContext()

     return (
          <>
               {/* Connected user icons */}
               <div>
                    {/* Liste traitement */}
                    {listItemContext.item.doesConnectedUserAddedCandidatInListeTraitement && (
                         <MyTooltip placement={"top"} title={`Dans ma liste de traitement`}>
                              <span className="fas fa-inbox text-warning fs-5" />
                         </MyTooltip>
                    )}
                    {/* Seen */}
                    {listItemContext.item.connectedUserHasSeenCandidat && (
                         <MyTooltip placement={"top"} title={`Vu le ${listItemContext.item.connectedUserHasSeenCandidat.date?.format("DD/MM/YYYY")}`}>
                              <span className="fas fa-eye text-success ms-1 fs-5" />
                         </MyTooltip>
                    )}
                    {/* Suivis */}
                    {listItemContext.item.doesConnectedUserAddedSuiviToCandidat && (
                         <MyTooltip placement={"top"} title={`Voir mes suivis`}>
                              <span className="fas fa-list text-success ms-1 fs-5" style={{ cursor: "pointer" }} onClick={() => listItemContext.setIsSuivisModalOpen(true)} />
                         </MyTooltip>
                    )}
                    {/* Vivier */}
                    {listItemContext.item.connectedUserAddedCandidatToViviers.length > 0 && (
                         <MyTooltip
                              placement={"top"}
                              title={
                                   <>
                                        <div className={"fs-5 fw-bold mb-4"}>Viviers</div>
                                        <div className="m-separator m-separator--light" />
                                        {listItemContext.item.connectedUserAddedCandidatToViviers.map(item => (
                                             <p key={item.vivier?.intitule}>
                                                  <span>- {item.vivier?.intitule}</span>
                                             </p>
                                        ))}
                                   </>
                              }
                         >
                              <span className="fas fa-heart text-success ms-1 fs-5" />
                         </MyTooltip>
                    )}
                    {/* Connected user statuts */}
                    <StatutsIconConnectedUser />
               </div>

               {/* Events other users*/}
               <div>
                    {/* Seen icon */}
                    {listItemContext.item.otherUsersWhoHaveSeenCandidat.length > 0 && (
                         <MyTooltip
                              placement={"bottom"}
                              title={
                                   <>
                                        <p className={"text-center font-weight-bold"}>Vu par</p>
                                        <div className="m-separator m-separator--light" />
                                        {listItemContext.item.otherUsersWhoHaveSeenCandidat.map(item => (
                                             <p key={item.personnel?.prenomNom}>
                                                  {item.personnel?.prenomNom} le {item.date?.format("DD/MM/YYYY")}
                                             </p>
                                        ))}
                                   </>
                              }
                         >
                              <span className="fas fa-eye fs-5" />
                         </MyTooltip>
                    )}
                    {/* Suivi icon */}
                    {listItemContext.item.doesOtherUsersAddedSuiviToCandidat && (
                         <MyTooltip placement={"bottom"} title={`Voir les suivis des autres membres de CTS`}>
                              <span className="fas fa-list ms-1 fs-5" style={{ cursor: "pointer" }} onClick={() => listItemContext.setIsSuivisModalOpen(true)} />
                         </MyTooltip>
                    )}
                    <OtherUsersVivierIcon />
                    {/* other users statuts */}
                    <StatutsIconOtherUsers />
               </div>
          </>
     )
}

// Other users icon vivier + modal
export const OtherUsersVivierIcon = () => {
     const listItemContext = useSearchResultsListItemContext()
     const [isModalShown, setIsModalShown] = useState<boolean>(false)

     return (
          <>
               {listItemContext.item.doesOtherUsersAddedCandidatToViviers && (
                    <MyTooltip placement={"bottom"} title={`Voir dans quels viviers ce candidat a été ajouté par les autres membres de CTS`}>
                         <span className={`fas fa-heart fs-5 ms-1`} style={{ cursor: "pointer" }} onClick={() => setIsModalShown(true)} />
                    </MyTooltip>
               )}

               {listItemContext.item.doesOtherUsersAddedCandidatToViviers && (
                    <MyModal
                         title={<span>Viviers contenant {listItemContext.item.candidat.prenomNom}</span>}
                         show={isModalShown}
                         handleClose={() => setIsModalShown(false)}
                         size={"lg"}
                         noPadding
                    >
                         <table className={"table table-rounded table-striped  gy-7 gs-7 m-0 p-0"}>
                              <thead className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                   <tr>
                                        <th>Date</th>
                                        <th>Vivier</th>
                                        <th>Responsable</th>
                                   </tr>
                              </thead>
                              <tbody>
                                   {listItemContext.item.candidat.candidatViviers?.map((item, key) => (
                                        <tr key={key}>
                                             <td>{item.date?.format("DD/MM/YYYY")}</td>
                                             <td>{item.vivier?.intitule}</td>
                                             <td>{item.responsable?.prenomNom}</td>
                                        </tr>
                                   ))}
                              </tbody>
                         </table>
                    </MyModal>
               )}
          </>
     )
}

/* Métiers */
export const Metiers = ({ classNames = "" }: { classNames?: string }) => {
     const listItemContext = useSearchResultsListItemContext()
     const candidatMetiers = listItemContext.item.candidat.candidatMetiers

     if (candidatMetiers === undefined || candidatMetiers.length === 0) return <span className={classNames ? classNames : ""}>Aucun métier</span>
     if (candidatMetiers.length > 1) {
          const TitleTooltip = (
               <div className={"text-center"}>
                    <div className={"fs-5 fw-bold"}>Métiers</div>
                    <div className={"separator border-secondary mb-5"} />
                    {candidatMetiers.map((item, key) => (
                         <div key={key}>- {item.metier?.nom}</div>
                    ))}
               </div>
          )
          return (
               <>
                    <MyTooltip title={TitleTooltip} placement={"bottom"}>
                         <span className={classNames ? classNames : ""}>
                              {candidatMetiers[0].metier?.nom}
                              <sup className={"fw-bold fs-9 text-primary"}>+{candidatMetiers.length - 1}</sup>
                         </span>
                    </MyTooltip>
               </>
          )
     } else {
          return <span className={classNames ? classNames : ""}>{candidatMetiers[0].metier?.nom}</span>
     }
}

/* Date candidature / date saisie */
export const DateLastCandidatureOrDateSaisi = ({ classNames = "" }: { classNames?: string }) => {
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat

     // Manually entEred candidate
     if (candidat.type === CONST_CANDIDAT_TYPE_SAISI) {
          return (
               <MyTooltip
                    placement={"bottom"}
                    title={
                         <>
                              Saisi par <b>{candidat.personnel?.prenomNom}</b> le <b>{candidat.dateSaisi?.format("dddd Do MMM YYYY")}</b>
                         </>
                    }
               >
                    <span className={classNames ? classNames : ""}>
                         <i className="fas fa-calendar-alt me-1 fs-5 text-dark" />
                         {candidat.dateSaisi?.format("DD/MM/YYYY")}
                    </span>
               </MyTooltip>
          )
     }

     // Candidate who applied to an offer
     if (candidat.candidatures !== undefined && candidat.candidatures.length > 0) {
          return (
               <MyTooltip
                    placement={"top"}
                    title={
                         <span>
                              Dernière candidature: le <b>{candidat.dateDerniereCandidature?.format("dddd Do MMM YYYY")}</b>
                         </span>
                    }
               >
                    <span className={classNames ? classNames : ""}>
                         <i className="fas fa-calendar-alt me-1 fs-5 text-dark" />
                         {candidat.dateDerniereCandidature?.format("DD/MM/YYYY")}
                    </span>
               </MyTooltip>
          )
     }

     return <></>
}

/* Email */
export const Mail = ({ classNames = "" }) => {
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat

     return (
          <div className={`d-flex align-items-center ${classNames ? classNames : ""}`}>
               <i className="fas fa-envelope fs-5 w-25px" />
               {candidat.email ? (
                    <a href={`mailto:${candidat.email}`} target={"_blank"}>
                         {candidat.email}
                    </a>
               ) : (
                    <span>A définir</span>
               )}
          </div>
     )
}

/* Phone number (indicatif + phone) */
export const Phone = ({ classNames = "" }) => {
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat

     return (
          <div className={`d-flex align-items-center ${classNames ? classNames : ""}`}>
               <i className="fas fa-phone fs-5 w-25px" />
               {candidat.tel ? (
                    <a href={`tel:${candidat.telIndicatif?.phoneCodeWithPlusPrefix} ${candidat.tel}`}>
                         {candidat.telIndicatif?.phoneCodeWithPlusPrefix} {candidat.tel}
                    </a>
               ) : (
                    <span>A définir</span>
               )}
          </div>
     )
}

/* Dispo */
export const Disponibilite = ({ classNames = "" }) => {
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat

     return (
          <div className={`d-flex align-items-center ${classNames ? classNames : ""}`}>
               <i className="fas fa-calendar-check fs-5 w-25px" />
               {candidat.disponibilite ? (
                    <>
                         {candidat.disponibilite === CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE && <span>Le {candidat.disponibiliteDate?.format("dddd Do MMM YYYY")}</span>}
                         {candidat.disponibilite !== CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE && <span>{candidat.disponibiliteLabel}</span>}
                    </>
               ) : (
                    <span>A définir</span>
               )}
          </div>
     )
}

/* Mobi */
export const Mobilite = ({ classNames = "" }) => {
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat

     const Regions = () => {
          if (!candidat.candidatMobiliteRegions) return <></>

          if (candidat.candidatMobiliteRegions.length > 1) {
               const TitleTooltip = (
                    <div className={"text-center"}>
                         <div className={"fs-5 fw-bold"}>Régions</div>
                         <div className={"separator border-secondary mb-5"} />
                         {candidat.candidatMobiliteRegions.map((item, key) => (
                              <div key={key}>- {item.region?.nom}</div>
                         ))}
                    </div>
               )
               return (
                    <>
                         <MyTooltip title={TitleTooltip} placement={"bottom"}>
                              <span>
                                   {candidat.candidatMobiliteRegions[0].region?.nom}
                                   <sup className={"fw-bold fs-9 text-primary"}>+{candidat.candidatMobiliteRegions.length - 1}</sup>
                              </span>
                         </MyTooltip>
                    </>
               )
          }
          if (candidat.candidatMobiliteRegions.length === 1) {
               return <span>{candidat.candidatMobiliteRegions[0].region?.nom}</span>
          }

          return <></>
     }

     const Departements = () => {
          if (!candidat.candidatMobiliteDepartements) return <></>

          if (candidat.candidatMobiliteDepartements.length > 1) {
               const TitleTooltip = (
                    <div className={"text-center"}>
                         <div className={"fs-5 fw-bold"}>Départements</div>
                         <div className={"separator border-secondary mb-5"} />
                         {candidat.candidatMobiliteDepartements.map((item, key) => (
                              <div key={key}>- {item.departement?.nom}</div>
                         ))}
                    </div>
               )
               return (
                    <>
                         <MyTooltip title={TitleTooltip} placement={"bottom"}>
                              <span>
                                   {candidat.candidatMobiliteDepartements[0].departement?.nom}
                                   <sup className={"fw-bold fs-9 text-primary"}>+{candidat.candidatMobiliteDepartements.length - 1}</sup>
                              </span>
                         </MyTooltip>
                    </>
               )
          }
          if (candidat.candidatMobiliteDepartements.length === 1) {
               return <span>{candidat.candidatMobiliteDepartements[0].departement?.nom}</span>
          }

          return <></>
     }

     const Villes = () => {
          if (!candidat.candidatMobiliteVilles) return <></>

          if (candidat.candidatMobiliteVilles.length > 1) {
               const TitleTooltip = (
                    <div className={"text-center"}>
                         <div className={"fs-5 fw-bold"}>Villes</div>
                         <div className={"separator border-secondary mb-5"} />
                         {candidat.candidatMobiliteVilles.map((item, key) => (
                              <div key={key}>- {item.ville?.nom}</div>
                         ))}
                    </div>
               )
               return (
                    <>
                         <MyTooltip title={TitleTooltip} placement={"bottom"}>
                              <span>
                                   {candidat.candidatMobiliteVilles[0].ville?.nom}
                                   <sup className={"fw-bold fs-9 text-primary"}>+{candidat.candidatMobiliteVilles.length - 1}</sup>
                              </span>
                         </MyTooltip>
                    </>
               )
          }
          if (candidat.candidatMobiliteVilles.length === 1) {
               return <span>{candidat.candidatMobiliteVilles[0].ville?.nom}</span>
          }

          return <></>
     }

     return (
          <div className={`d-flex align-items-center ${classNames ? classNames : ""}`}>
               <i className="fas fa-globe fs-5 w-25px" />
               {candidat.mobilite ? (
                    <>
                         {/* Mobilité france, IDF, hors IDF & internationale */}
                         {[CONST_MOBILITE_FRANCE, CONST_MOBILITE_FRANCE_HORS_IDF, CONST_MOBILITE_IDF, CONST_MOBILITE_INTERNATIONAL].includes(candidat.mobilite) && (
                              <span>{candidat.mobiliteLabel}</span>
                         )}

                         {/* Mobilité départementale + régionale + citadine */}
                         {candidat.mobilite === CONST_MOBILITE_DEPARTEMENTAL && <Departements />}
                         {candidat.mobilite === CONST_MOBILITE_REGIONAL && <Regions />}
                         {candidat.mobilite === CONST_MOBILITE_PAR_VILLE && <Villes />}
                    </>
               ) : (
                    <span>A définir</span>
               )}
          </div>
     )
}

/* Salaire */
export const SalaireNetMensuel = ({ classNames = "" }) => {
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat

     return (
          <div className={`d-flex align-items-center ${classNames ? classNames : ""}`}>
               <i className="fas fa-euro-sign fs-5 w-25px" />
               {candidat.salaireNetSouhaite ? (
                    <>
                         {candidat.salaireNetSouhaite} € <span className={"fw-bold"}> net souhaité / mois</span>
                    </>
               ) : (
                    <span>A définir</span>
               )}
          </div>
     )
}

/* Tag candidature spon */
export const TagCandidatureSpon = () => {
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat
     const [isModalShown, setIsModalShown] = useState<boolean>(false)

     return (
          <>
               <MyTooltip title={"Candidature spontanée"}>
                    <div className={`badge badge-primary cursor-pointer mt-1 d-flex justify-content-center fs-8`} onClick={() => setIsModalShown(true)}>
                         Spon.
                    </div>
               </MyTooltip>

               {isModalShown && (
                    <MyModal title={<span>Candidature spontanée</span>} show={isModalShown} handleClose={() => setIsModalShown(false)}>
                         <div className={"text-center"}>
                              <div className={"fs-5 fw-bold mb-4"}>Intéréssé par les secteurs</div>
                              {candidat.candidatBusinessUnits?.map(buc => buc.businessUnit?.nom).join(", ")}
                              {candidat.commentaireCandidatureSponParCandidat && (
                                   <>
                                        <div className={"fs-5 fw-bold mb-4 mt-4"}>Message du candidat</div>
                                        {candidat.commentaireCandidatureSponParCandidat}
                                   </>
                              )}
                         </div>
                    </MyModal>
               )}
          </>
     )
}

/* Tag embauché */
export const TagEmbauche = () => {
     return (
          <div className={`badge badge-outline badge-dark mt-1 d-flex justify-content-center fs-10`}>
               Embauché <span className={"fas fa-check-circle ms-1"} />
          </div>
     )
}

/* Card footer DMS info */
export const BottomCardDivInfoDms = ({ classNames = "" }) => {
     const { currentUser } = useAuth()
     const listItemContext = useSearchResultsListItemContext()
     const candidat = listItemContext.item.candidat
     const [isConnectedUserDmsInModal, setIsConnectedUserDmsInModal] = useState<boolean>(false)

     const connectedUserDms: ICandidatSuiviModel | null = (() => {
          const index = listItemContext.item.entretienTelOfConnectedUserAndHisTeam.findIndex(item => item.personnel?.id === currentUser?.id)
          if (index >= 0) {
               return listItemContext.item.entretienTelOfConnectedUserAndHisTeam[index]
          }
          return null
     })()

     const ConnectedUserDmsInModal = () => {
          return connectedUserDms ? (
               <MyModal title={<span>Ma DMS de {candidat?.prenomNom}</span>} show={true} handleClose={() => setIsConnectedUserDmsInModal(false)} fullscreen>
                    <CandidatSuiviEditForm id={connectedUserDms.id as number} handleSubmitCallBack={() => setIsConnectedUserDmsInModal(false)} />
               </MyModal>
          ) : (
               <></>
          )
     }

     return (
          <>
               {listItemContext.item.entretienTelOfConnectedUserAndHisTeam.length > 0 ? (
                    <>
                         {connectedUserDms !== null && (
                              <div
                                   className={`text-center bg-success text-light p-1 rounded-0 cursor-pointer ${classNames ? classNames : ""}`}
                                   onClick={() => setIsConnectedUserDmsInModal(true)}
                              >
                                   Vous avez saisi une DMS
                              </div>
                         )}
                         {connectedUserDms === null && (
                              <div
                                   className={`text-center bg-primary text-light p-1 rounded-0 cursor-pointer ${classNames ? classNames : ""}`}
                                   onClick={() => listItemContext.setIsSuivisModalOpen(true)}
                              >
                                   {listItemContext.item.entretienTelOfConnectedUserAndHisTeam.length > 1 && (
                                        <>
                                             {listItemContext.item.entretienTelOfConnectedUserAndHisTeam.map(item => item.personnel?.prenomNom).slice(0, -1)} et{" "}
                                             {listItemContext.item.entretienTelOfConnectedUserAndHisTeam
                                                  .map<React.ReactNode>(item => <span className={"fw-bold"}>{item.personnel?.prenomNom}</span>)
                                                  .slice(-1)
                                                  .reduce((prev, curr) => [prev, ", ", curr])}{" "}
                                             ont ajouté leurs <span className={"fw-bold"}>DMS</span>
                                        </>
                                   )}
                                   {listItemContext.item.entretienTelOfConnectedUserAndHisTeam.length === 1 && (
                                        <>
                                             {listItemContext.item.entretienTelOfConnectedUserAndHisTeam.map(item => (
                                                  <span className={"fw-bold"}>{item.personnel?.prenomNom}</span>
                                             ))}{" "}
                                             a ajouté sa <span className={"fw-bold"}>DMS</span>
                                        </>
                                   )}
                              </div>
                         )}
                    </>
               ) : (
                    <>
                         {listItemContext.item.entretienTelOfOtherUsers.length > 0 && (
                              <div
                                   className={`text-center bg-gray-500 text-light p-1 rounded-0 cursor-pointer ${classNames ? classNames : ""}`}
                                   onClick={() => listItemContext.setIsSuivisModalOpen(true)}
                              >
                                   {listItemContext.item.entretienTelOfOtherUsers.length > 1 && (
                                        <>
                                             {listItemContext.item.entretienTelOfOtherUsers
                                                  .map<React.ReactNode>(item => <span className={"fw-bold"}>{item.personnel?.prenomNom}</span>)
                                                  .slice(0, -1)
                                                  .reduce((prev, curr) => [prev, ", ", curr])}{" "}
                                             et{" "}
                                             {listItemContext.item.entretienTelOfOtherUsers.map(item => <span className={"fw-bold"}>{item.personnel?.prenomNom}</span>).slice(-1)}{" "}
                                             ont ajouté leurs <span className={"fw-bold"}>DMS</span>
                                        </>
                                   )}
                                   {listItemContext.item.entretienTelOfOtherUsers.length === 1 && (
                                        <>
                                             {listItemContext.item.entretienTelOfOtherUsers.map(item => (
                                                  <span className={"fw-bold"}>{item.personnel?.prenomNom}</span>
                                             ))}{" "}
                                             a ajouté sa <span className={"fw-bold"}>DMS</span>
                                        </>
                                   )}
                              </div>
                         )}
                    </>
               )}

               {isConnectedUserDmsInModal && <ConnectedUserDmsInModal />}
          </>
     )
}

/* Add / remove to / from liste traitement */
export const AddOrRemoveToFromListeTraitementButton = ({ classNames = "" }) => {
     const globalContent = useSearchResultsContext()
     const listItemContext = useSearchResultsListItemContext()
     const item = listItemContext.item

     return (
          <div className={`${classNames ? classNames : ""}`}>
               <MyTooltip title={item.doesConnectedUserAddedCandidatInListeTraitement ? "Supprimer de ma liste de traitement" : "Ajouter à ma liste de traitement"}>
                    <button
                         type={"button"}
                         className={`btn ${
                              item.doesConnectedUserAddedCandidatInListeTraitement ? "btn-warning" : "btn-outline btn-outline-warning"
                         } btn-sm btn-icon rounded-circle`}
                         onClick={() => globalContent.handleAddOrRemoveCandidateToListeTraitement(listItemContext.item)}
                         disabled={!!globalContent.addingRemovingCandidateToFromListeTraitement}
                    >
                         {globalContent.addingRemovingCandidateToFromListeTraitement ? (
                              <MySimpleSpinner />
                         ) : (
                              <>{item.doesConnectedUserAddedCandidatInListeTraitement ? <i className="fas fa-minus fs-2" /> : <i className="fas fa-plus fs-2" />}</>
                         )}
                    </button>
               </MyTooltip>
          </div>
     )
}

/* Add to viviers */
export const AddToViviersButton = ({ classNames = "" }) => {
     const globalContext = useSearchResultsContext()
     const listItemContext = useSearchResultsListItemContext()

     return (
          <div className={`${classNames ? classNames : ""}`}>
               <MyTooltip title={"Ajouter à un vivier"}>
                    <button
                         type={"button"}
                         className={`btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle`}
                         onClick={() => globalContext.setAddingCandidateToViviers(listItemContext.item)}
                    >
                         <i className="fas fa-heart fs-4"></i>
                    </button>
               </MyTooltip>
          </div>
     )
}

/* Add to viviers */
export const PartagerProfilParMailButton = ({ classNames = "" }) => {
     const globalContext = useSearchResultsContext()
     const listItemContext = useSearchResultsListItemContext()

     return (
          <div className={`${classNames ? classNames : ""}`}>
               <MyTooltip title={"Partager le profil par mail"}>
                    <button
                         type={"button"}
                         className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle`}
                         onClick={() => globalContext.setSharingCandidate(listItemContext.item)}
                    >
                         <i className="fas fa-share fs-4"></i>
                    </button>
               </MyTooltip>
          </div>
     )
}
