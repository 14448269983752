import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { useQuery, UseQueryResult } from "react-query"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { Link, useNavigate, useParams } from "react-router-dom"
import { PersonnelNdfDepensesListing } from "./_PersonnelNdfDepensesListing"
import ConnectedUserNdfDepenseSimpleEdit from "./edit/_ConnectedUserNdfDepenseSimpleEdit"
import ConnectedUserNdfDepenseGroupedEdit from "./edit/_ConnectedUserNdfDepenseGroupedEdit"
import "./styles.scss"
import PersonnelNdfDepensesNoticeUtilisation from "./_PersonnelNdfDepensesNoticeUtilisation"
import { IPersonnelNdfDetailModel, IPersonnelNdfModel } from "@common-models/*"
import MyModal from "@common-utils/MyModal"
import ConnectedUserNdfDepenseCreateForm from "./create/_ConnectedUserNdfDepenseCreateForm"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { BrowserView, MobileView } from "react-device-detect"
import { KTSVG } from "@common-metronic/helpers"
import { getPersonnelNdfByIdRequest, getPersonnelNdfDepensesRequest } from "../core/_requests"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
const ConnectedUserNdfDepensesContext = createContext<IPersonnelNdfDepensesContextPropsModel>({} as IPersonnelNdfDepensesContextPropsModel)
export const useConnectedUserNdfDepensesContext = () => useContext(ConnectedUserNdfDepensesContext)
export const PersonnelNdfDepenses = ({ ndfId, editionMode = true, setIsDataLoaded }: IPersonnelNdfDepensesPropsModel) => {
     const [totalDepenses, setTotalDepenses] = useState<number>(0)

     // Query
     const getNdfDepensesQuery = useQuery<IPersonnelNdfDetailModel[], AxiosError>([REACT_QUERY_KEY_GET_LISTING, ndfId], () =>
          getPersonnelNdfDepensesRequest(ndfId).then(r => {
               if (setIsDataLoaded) setIsDataLoaded(true)
               return r.data
          })
     )

     useEffect(() => {
          computeTotalExpenses(getNdfDepensesQuery.data || [])
     }, [getNdfDepensesQuery.data])

     // States
     const [isAddDepenseModalShown, setIsAddDepenseModalShown] = useState<boolean>(false)
     const [currentDepenseInEdition, setCurrentDepenseInEdition] = useState<IPersonnelNdfDetailModel>()
     const [typeAddDepense, setTypeAddDepense] = useState<"simple" | "group" | null>(null)
     const [isCardDepenseSimpleCollapsed, setIsCardDepenseSimpleCollapsed] = useState<boolean>(false)
     const [isNoticeUtilisationShownInModal, setIsNoticeUtilisationShownInModal] = useState<boolean>(false)

     // Modal add depense
     const FormAddDepenseInModal = useMemo(() => {
          return (
               <MyModal
                    title={<span>Ajouter une dépense</span>}
                    show={isAddDepenseModalShown}
                    handleClose={() => {
                         setIsAddDepenseModalShown(false)
                         setTypeAddDepense(null)
                         setIsCardDepenseSimpleCollapsed(false)
                    }}
                    fullscreen
               >
                    <ConnectedUserNdfDepenseCreateForm />
               </MyModal>
          )
     }, [isAddDepenseModalShown, typeAddDepense, isCardDepenseSimpleCollapsed])

     function closeAndReopenAddDepenseModal() {
          setIsAddDepenseModalShown(false)
          setIsAddDepenseModalShown(true)
     }

     function computeTotalExpenses(items: IPersonnelNdfDetailModel[]) {
          let total = 0
          items.map(item => {
               if (item.isParent && item.enfants) {
                    item.enfants.map(enfant => {
                         total += enfant.montantTtc as number
                    })
               } else {
                    total += item.montantTtc as number
               }
          })
          setTotalDepenses(total)
     }

     return (
          <>
               <ConnectedUserNdfDepensesContext.Provider
                    value={{
                         setIsAddDepenseModalShown,
                         ndfId,
                         editionMode,
                         getNdfDepensesQuery,
                         closeAndReopenAddDepenseModal,
                         setCurrentDepenseInEdition,
                         isCardDepenseSimpleCollapsed,
                         setIsCardDepenseSimpleCollapsed,
                         typeAddDepense,
                         totalDepenses,
                         setTypeAddDepense,
                         REACT_QUERY_KEY_GET_LISTING,
                         computeTotalExpenses,
                    }}
               >
                    {/* EDITION MODE ONLY: mode d'emploi + recharger + add */}
                    {editionMode && (
                         <>
                              <div className="d-flex justify-content-between mb-7">
                                   <div className="w-xxl-10 w-xl-25 w-md-30 w-sm-35 w-60">
                                        <button className={`btn btn-primary btn-sm d-flex `} onClick={() => setIsNoticeUtilisationShownInModal(true)}>
                                             <i className={"fas fa-file-alt fs-3 p-0 me-2"} />
                                             Notice d'utilisation
                                        </button>
                                   </div>
                                   <div>
                                        <button className={`btn btn-light-primary btn-sm me-2`} onClick={() => getNdfDepensesQuery.refetch()}>
                                             <span className={`d-md-inline d-none`}>Recharger la liste</span>
                                             <i className={"fas fa-sync ms-md-2 pe-0 fs-3"} />
                                        </button>
                                        <button className={`btn btn-primary btn-sm`} onClick={() => setIsAddDepenseModalShown(true)}>
                                             <span className={`d-md-inline d-none`}>Nouvelle dépense</span>
                                             <i className={"fas fa-plus-circle ms-md-2 pe-0 fs-3"} />
                                        </button>
                                   </div>
                              </div>
                              <div className="separator separator-dashed mb-4 mt-4" />
                         </>
                    )}

                    <PersonnelNdfDepensesListing />

                    {/* Modal: add depense */}
                    {isAddDepenseModalShown && FormAddDepenseInModal}

                    {/* Modal: edition depense */}
                    {currentDepenseInEdition && (
                         <MyModal title={<span>Edition de la dépense</span>} show={true} handleClose={() => setCurrentDepenseInEdition(undefined)} size={"lg"}>
                              <>
                                   {currentDepenseInEdition.isParent ? (
                                        <ConnectedUserNdfDepenseGroupedEdit detail={currentDepenseInEdition} />
                                   ) : (
                                        <ConnectedUserNdfDepenseSimpleEdit detail={currentDepenseInEdition} />
                                   )}
                              </>
                         </MyModal>
                    )}

                    {/* Modal: notice utilisation */}
                    {isNoticeUtilisationShownInModal && (
                         <MyModal title={<>Notice d'utilisation</>} show={true} handleClose={() => setIsNoticeUtilisationShownInModal(false)} size={"lg"} noPadding>
                              <PersonnelNdfDepensesNoticeUtilisation finaliserCallback={() => setIsNoticeUtilisationShownInModal(false)} />
                         </MyModal>
                    )}
               </ConnectedUserNdfDepensesContext.Provider>
          </>
     )
}

export const ConnectedUserNdfDepensesWrapper = () => {
     const navigate = useNavigate()
     const ndf_id = parseInt(useParams<{ ndf_id: string }>().ndf_id as string)

     const [ndf, setNdf] = useState<{ isEditable: boolean; loading: boolean; error: string | null; data: IPersonnelNdfModel | null }>({
          isEditable: true,
          loading: true,
          error: null,
          data: null,
     })

     // Get ndf by id. If exists, redirect to details otherwise redirect to creation page
     useEffect(() => {
          if (!ndf_id) {
               navigate(`/personnel/mes-activites/ndf/create`)
          } else {
               getPersonnelNdfByIdRequest(ndf_id)
                    .then(r => {
                         if (r.data) {
                              setNdf(prev => ({ ...prev, isEditable: r.data!.declaration?.modifiable as boolean, loading: false, error: null, data: r.data }))
                         } else {
                              navigate(`/personnel/mes-activites/ndf/create`)
                         }
                    })
                    .catch(e => {
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                         setNdf(prev => ({ ...prev, isEditable: false, loading: false, error: e.response?.data?.detail, data: null }))
                    })
          }
     }, [])

     const Header = () => {
          return (
               <div className="stepper stepper-links mb-2">
                    <div className="stepper-nav">
                         <div className={`stepper-item completed`}>
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>1. Période</span>
                                   <span className={"d-block d-sm-none fas fa-calendar-plus"} style={{ fontSize: "2rem" }}></span>
                              </h3>
                         </div>
                         <div className={`stepper-item current`}>
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>2. Dépenses</span>
                                   <span className={"d-block d-sm-none fas fa-file-invoice"} style={{ fontSize: "2rem" }}></span>
                              </h3>
                         </div>
                    </div>
               </div>
          )
     }

     const Footer = () => {
          return (
               <>
                    {/* FOOTER */}
                    <BrowserView>
                         <div className="d-flex flex-stack">
                              <div>
                                   <Link to="/personnel/mes-activites/ndf " className={`btn btn-sm btn-light-primary me-2`}>
                                        <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des NDF
                                   </Link>
                              </div>

                              {ndf.isEditable && (
                                   <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                             toast.info("Note de frais finalisée. Vous pouvez reprendre son édition si besoin.")
                                             navigate("/personnel/mes-activites/ndf")
                                        }}
                                   >
                                        <span className={"me-2"}>Finaliser</span>
                                        <span className="fas fa-check-circle"></span>
                                   </button>
                              )}
                         </div>
                    </BrowserView>

                    <MobileView>
                         <div className="d-flex flex-column">
                              <div className={"mb-sm-0 mb-3"}>
                                   <button type="button" className="btn btn-sm btn-light-primary me-3 float-sm-none">
                                        <span className="svg-icon me-1">
                                             <KTSVG path="/media/icons/duotune/arrows/arr063.svg" className="svg-icon-2" />
                                        </span>
                                        Dépenses
                                   </button>

                                   {ndf.isEditable && (
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-primary float-end"
                                             onClick={() => {
                                                  toast.info("Note de frais finalisée. Vous pouvez reprendre son édition si besoin.")
                                                  navigate("/personnel/mes-activites/ndf")
                                             }}
                                        >
                                             <span className={"me-2"}>Finaliser</span>
                                             <span className="fas fa-check-circle"></span>
                                        </button>
                                   )}
                              </div>

                              <Link to="/personnel/mes-activites/ndf " className={`btn btn-sm btn-light-primary`}>
                                   <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des NDF
                              </Link>
                         </div>
                    </MobileView>
               </>
          )
     }

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-65"}>
                    <MyCard>
                         <MyCard.Body>
                              <Header />

                              {/* loader */}
                              {ndf.loading && (
                                   <div className={"text-center p-5"}>
                                        <MySimpleSpinner size={"xl"} />{" "}
                                   </div>
                              )}

                              {/* error */}
                              {!ndf.loading && ndf.error && <MyAlert type={"danger"}>{ndf.error}</MyAlert>}

                              {!ndf.loading && ndf.data && (
                                   <>
                                        <PersonnelNdfDepenses ndfId={ndf.data?.id as number} editionMode={ndf.isEditable} />
                                   </>
                              )}

                              <div className="separator separator-dashed mb-5" />

                              <Footer />
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

interface IPersonnelNdfDepensesPropsModel {
     ndfId: number
     editionMode?: boolean
     setIsDataLoaded?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IPersonnelNdfDepensesContextPropsModel {
     closeAndReopenAddDepenseModal: () => void
     setIsAddDepenseModalShown: Dispatch<SetStateAction<boolean>>
     setCurrentDepenseInEdition: Dispatch<SetStateAction<IPersonnelNdfDetailModel | undefined>>
     setIsCardDepenseSimpleCollapsed: Dispatch<SetStateAction<boolean>>
     isCardDepenseSimpleCollapsed: boolean
     setTypeAddDepense: Dispatch<SetStateAction<"simple" | "group" | null>>
     typeAddDepense: "simple" | "group" | null
     ndfId: number
     editionMode: boolean
     getNdfDepensesQuery: UseQueryResult<IPersonnelNdfDetailModel[], AxiosError>
     REACT_QUERY_KEY_GET_LISTING: string
     totalDepenses: number
     computeTotalExpenses: (items: IPersonnelNdfDetailModel[]) => void
}
