import React, { useCallback } from "react"
import Select from "react-select"
import useSelectReact from "@common-hooks/useSelectReact"

type Props = {
     name: string
     options: Array<{ label: string; value: string | boolean } | any>
     value: string | number | Array<string | number> | undefined | null
     onChange: (value: any) => void
     isInvalid: boolean
     isClearable?: boolean
     isSearchable?: boolean
     placeholder?: string
     isMulti?: boolean
}

const MySelectField: React.FC<Props> = ({ options, name, value, onChange, isClearable = true, isSearchable = false, placeholder = "Choisissez", isInvalid, isMulti = false }) => {
     const { styles, formatGroupLabel } = useSelectReact()

     // selectValue is based on "value". It will get all options matching with value
     const selectValue = useCallback(() => {
          if (!value) return []
          if (Array.isArray(value)) {
               if (value.length == 0) return []

               let selectedOptions: any[] = []
               options.map(opt => {
                    // if opt has options it means that it's an optgroup
                    if ("options" in opt) {
                         opt.options.map(item => {
                              // @ts-ignore
                              if (value.includes(item.value)) selectedOptions.push(item)
                         })
                    } else {
                         // @ts-ignore
                         if (value.includes(opt.value)) selectedOptions.push(opt)
                    }
               })
               return selectedOptions
          } else {
               return options.find(opt => opt.value === value)
          }
     }, [value])

     return (
          <Select
               menuPortalTarget={document.body}
               options={options}
               name={name}
               formatGroupLabel={formatGroupLabel}
               value={selectValue()}
               onChange={val => {
                    if (Array.isArray(val)) {
                         // @ts-ignore
                         onChange(val.map(i => i.value)) // Form only needs values
                    } else {
                         onChange(val?.value ? val.value : "") // Form only needs the value
                    }
               }}
               isMulti={isMulti}
               isClearable={isClearable}
               isSearchable={isSearchable}
               placeholder={placeholder}
               styles={styles(isInvalid)}
          />
     )
}

export default MySelectField
