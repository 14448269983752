import React, { InputHTMLAttributes } from "react"
import InputMask from "react-input-mask"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
     name: string
     value?: any
     mask: string | (string | RegExp)[]
     isInvalid: boolean
     className?: string
     placeholder?: string
     inputMode?: "text" | "numeric" | "tel" | "email"
     onChange: (val) => void
}

const MyInptuMaskField: React.FC<Props> = ({ name, mask, className = "", value, onChange, placeholder, inputMode = "text", isInvalid }) => {
     return (
          <InputMask maskChar={null} className={className} name={name} mask={mask} value={value === null ? "" : value} type={"text"} onChange={onChange} placeholder={placeholder}>
               {() => (
                    <input
                         type="text"
                         name={name}
                         inputMode={inputMode}
                         placeholder={placeholder}
                         onChange={onChange}
                         value={value === null ? "" : value}
                         className={`form-control ${className} ${isInvalid ? "is-invalid" : ""} ${className}`}
                    />
               )}
          </InputMask>
     )
}

export default MyInptuMaskField
