import React, { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { ICandidatCommentaireModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { addCommentaire, getCommentaires } from "../../../../core/_requests"
import { useSearchResultsContext } from "../../CandidatsRechercheResults"
import MyAlert from "@common-utils/MyAlert"
import MyTooltip from "@common-utils/MyTooltip"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import MyEditorField from "@common-utils/fields/MyEditorField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useSearchResultsPreviewContext } from "../_CandidatsRechercheResultsPreview"

const REACT_QUERY_KEY_GET_COMMENTAIRES_QUERY = "REACT_QUERY_KEY_GET_DOCOS_QUERY"

export const CandidatsRechercheResultsPreviewHelpersComments = () => {
     const queryClient = useQueryClient()
     const globalContext = useSearchResultsContext()
     const previewContext = useSearchResultsPreviewContext()
     const [isAddingCommentaire, setIsAddingCommentaire] = useState<boolean>(false)

     const commentairesQuery = useQuery<ICandidatCommentaireModel[], AxiosError>(
          [REACT_QUERY_KEY_GET_COMMENTAIRES_QUERY, globalContext.itemInPreview?.selectedItem.candidat.id],
          async () => {
               return getCommentaires(globalContext.itemInPreview?.selectedItem.candidat.id as number)
                    .then(r => {
                         return r.data
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Toast error
                         toast.error(error?.detail, { autoClose: false })

                         throw e
                    })
                    .finally(() => {
                         // Stop loading preview
                         previewContext.setIsPreviewBodyLoading(false)
                         globalContext.setItemInPreview(prev =>
                              prev
                                   ? {
                                          ...prev,
                                          isSelectedItemLoading: false,
                                     }
                                   : null
                         )
                    })
          }
     )

     const CommentaireItem = ({ item }: { item: ICandidatCommentaireModel }) => {
          return (
               <div className={`d-flex  align-items-center p-4`}>
                    <div className={"d-flex align-items-center"}>
                         <MyTooltip title={<>{item.personnel?.prenomNom}</>}>
                              <img
                                   src={
                                        item.personnel?.avatarGoogleDriveId
                                             ? FileAndDriveHelper.getThumbnailDriveUrl(item.personnel?.avatarGoogleDriveId)
                                             : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                   }
                                   className="rounded-circle me-4"
                                   style={{ width: "50px" }}
                                   alt="Avatar"
                              />
                         </MyTooltip>
                         <div className={"fs-4 border-start border-primary ps-4"}>
                              <div dangerouslySetInnerHTML={{ __html: item.commentaire || "" }} />
                              <div className={"text-muted fs-8"}>Le {item.date?.format("dddd Do MMM YYYY")}</div>
                         </div>
                    </div>
               </div>
          )
     }

     const FormAddCommentaire = () => {
          const schema = Yup.object().shape({
               commentaire: Yup.string().label(`"Commentaire"`).required(),
          })

          const initialValues: { commentaire?: string } = {}

          function handleSubmit(values: { commentaire?: string }, helpers: FormikHelpers<{ commentaire?: string }>) {
               helpers.setStatus(null)
               addCommentaire(globalContext.itemInPreview?.selectedItem.candidat.id as number, values.commentaire as string)
                    .then(r => {
                         // Prepend commentaire among the list
                         queryClient.setQueryData<ICandidatCommentaireModel[] | undefined>(
                              REACT_QUERY_KEY_GET_COMMENTAIRES_QUERY,
                              (prev: ICandidatCommentaireModel[] | undefined) => {
                                   if (prev) return [r.data, ...prev]

                                   return prev
                              }
                         )

                         // Toast message
                         toast.success("Le commentaire a bien été ajouté.")
                         setIsAddingCommentaire(false)
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)
                         toast.error(error?.detail, { autoClose: false })

                         // Stop submit loader
                         helpers.setSubmitting(false)
                    })
          }

          return (
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {/* Notes */}
                                   <div className={"mb-5"}>
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Commentaire</label>
                                        <MyEditorField
                                             value={helpers.values.commentaire}
                                             height={250}
                                             onChange={val => helpers.setFieldValue("commentaire", val)}
                                             placeholder={"Saisissez votre commentaire"}
                                        />
                                        <div className={"mb-2"}>{helpers.errors.commentaire && <div className={"text-danger"}>{helpers.errors.commentaire}</div>}</div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? "Ajouter" : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          )
     }

     return (
          <>
               <div className={"preview-height py-8 px-4"}>
                    <div className={"text-center"}>
                         <button className={"btn btn-sm btn-outline btn-outline-primary fw-bold"} type={"button"} onClick={() => setIsAddingCommentaire(true)}>
                              Ajouter un commentaire <i className={"fas fa-plus-circle ms-1"} />
                         </button>
                    </div>
                    <div className="separator separator-dotted border-primary mt-4 mb-4" />
                    {/* In case of error */}
                    {commentairesQuery.error ? (
                         <MyAlert type={"danger"}>{commentairesQuery.error.response?.data.detail}</MyAlert>
                    ) : (
                         <>
                              {!isAddingCommentaire && (
                                   <>
                                        {commentairesQuery.data && commentairesQuery.data.length > 0 && (
                                             <>
                                                  {commentairesQuery.data.map((item: ICandidatCommentaireModel, index) => (
                                                       <CommentaireItem item={item} />
                                                  ))}
                                             </>
                                        )}
                                   </>
                              )}

                              {isAddingCommentaire && <FormAddCommentaire />}
                         </>
                    )}
               </div>
          </>
     )
}
