import React, { useState } from "react"
import * as Yup from "yup"
import { IForgotPasswordRequestModel } from "../core/_models"
import MyAlert from "@common-utils/MyAlert"
import { Form, Formik, FormikHelpers } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { forgotPasswordRequest } from "../core/_requests"
import { Link, useLocation } from "react-router-dom"
import { toAbsoluteUrl } from "@common-metronic/helpers"

type LocationProps = {
     state: {
          email: string
          message: string | null
     }
}

function ForgotPassword() {
     const location = useLocation() as unknown as LocationProps

     const [isMailSent, setIsMailSent] = useState<boolean>(false)
     const urlParams = new URLSearchParams(window.location.search)

     const initialValues: IForgotPasswordRequestModel = {
          email: location?.state?.email || urlParams.get("email") || "",
     }

     const schema = Yup.object().shape({
          email: Yup.string().email().label(`"Email"`).required(),
     })

     function handleSubmit(values: IForgotPasswordRequestModel, helpers: FormikHelpers<IForgotPasswordRequestModel>) {
          helpers.setStatus(null)

          forgotPasswordRequest(values.email as string)
               .then(() => {
                    setIsMailSent(true)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <div
               className="d-flex flex-column align-items-center justify-content-center"
               style={{
                    backgroundSize: "cover",
                    backgroundImage: `url(${toAbsoluteUrl("/media/misc/auth-bg.jpg")})`,
                    height: "100vh",
                    color: "black",
               }}
          >
               <div
                    className="w-85 w-sm-75 w-md-50 w-xxl-30 px-5 py-5 mb-15"
                    style={{
                         opacity: "0.90",
                         backgroundColor: "white",
                         borderRadius: "20px",
                         boxShadow: "0px 0px 10px -2px rgba(0, 0, 0, 0.42)",
                         overflow: "scroll",
                         maxHeight: "95%",
                    }}
               >
                    <div className={"mb-10 text-center"}>
                         <img src={toAbsoluteUrl("/media/logos/logo-talent-structure.png")} alt="" className={"w-75 ms-5"} />
                    </div>

                    <>
                         {isMailSent ? (
                              <MyAlert type={"success"} title={<h5 className="mb-1">Parfait ! Votre demande a bien été prise en compte.</h5>}>
                                   Si un compte est associé à cette adresse mail, nous y enverrons un lien de réinitialisation de mot de passe.
                              </MyAlert>
                         ) : (
                              <>
                                   <div className="text-center mb-10">
                                        <h1 className="text-black fw-bolder mb-3">Mot de passe oublié ?</h1>
                                        <div className="text-gray-500 fw-semibold fs-6">Entrez votre email pour réinitialiser votre mot de passe</div>
                                   </div>

                                   <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                        {helpers => {
                                             return (
                                                  <Form noValidate autoComplete="off">
                                                       <div className="mb-5">
                                                            <label className="form-label fw-bolder text-black fs-6">Email</label>

                                                            {/* Simple search */}
                                                            <MyInputField
                                                                 name={"email"}
                                                                 value={helpers.values.email}
                                                                 onChange={val => {
                                                                      helpers.setFieldValue("email", val).then()
                                                                 }}
                                                                 isInvalid={!!helpers.errors.email}
                                                                 placeholder={"Email"}
                                                                 classNames={"bg-transparent"}
                                                            />
                                                            {helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}
                                                       </div>

                                                       <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                                            <Link to="/auth">
                                                                 <button type="button" className="btn btn-light-primary me-4" disabled={helpers.isSubmitting}>
                                                                      Annuler
                                                                 </button>
                                                            </Link>

                                                            <button type="submit" id="kt_password_reset_submit" className="btn btn-primary">
                                                                 <span className="indicator-label">
                                                                      {!helpers.isSubmitting && "Valider"}
                                                                      {helpers.isSubmitting && (
                                                                           <span className="indicator-progress" style={{ display: "block" }}>
                                                                                Veuillez patienter...
                                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                           </span>
                                                                      )}
                                                                 </span>
                                                            </button>
                                                       </div>
                                                  </Form>
                                             )
                                        }}
                                   </Formik>
                              </>
                         )}
                    </>

                    <div className="d-flex flex-column fw-bold text-primary flex-center mt-8">
                         <img
                              style={{
                                   height: "40px",
                                   width: "55px",
                              }}
                              src={toAbsoluteUrl("/media/logos/logos-cts-1.png")}
                              alt={""}
                         />
                    </div>
               </div>
          </div>
     )
}

export default ForgotPassword
