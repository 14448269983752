import React from "react"
import * as Yup from "yup"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import {
     CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CLIENT,
     CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE,
     CONST_DISPONIBILITE_DATE_EXACTE,
     CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_APPLICATION_DOC,
     CONST_MIME_APPLICATION_DOCX,
     CONST_MIME_APPLICATION_GOOGLE_DOC,
     CONST_MIME_APPLICATION_PDF,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
} from "@common-constants/*"
import { CONST_API_UTILS_GET_DEPARTEMENTS_URL_ENDPOINT, CONST_API_UTILS_GET_REGIONS_URL_ENDPOINT, CONST_API_UTILS_GET_VILLES_URL_ENDPOINT } from "../../../../endpoints"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "@common-utils/MyAlert"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import MySelectField from "@common-utils/fields/MySelectField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import MyInputField from "@common-utils/fields/MyInputField"
import MyEditorField from "@common-utils/fields/MyEditorField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MyFileField from "@common-utils/fields/MyFileField"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { ICandidatSuiviEditFormikModel, ICandidatSuiviEditFormPropsModel, ICandidatSuiviEditRequestModel } from "./core/_models"
import { getCandidatSuivi, setCandidatSuivi } from "./core/_requests"
import { ICandidatSuiviModel, IHttpErrorResponseModel } from "@common-models/*"
import { useQuery } from "react-query"

// Suivi form modal: DMS
const REACT_QUERY_KEY_GET_CANDIDAT_SUIVI_DETAILS_QUERY = "REACT_QUERY_KEY_GET_CANDIDAT_SUIVI_DETAILS_QUERY"
const CandidatSuiviEditForm = ({ id, handleSubmitCallBack }: ICandidatSuiviEditFormPropsModel) => {
     const detailsQuery = useQuery<ICandidatSuiviModel, AxiosError>([REACT_QUERY_KEY_GET_CANDIDAT_SUIVI_DETAILS_QUERY, id], () => {
          return getCandidatSuivi(id)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     if (detailsQuery.isFetching)
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />{" "}
               </div>
          )

     if (!detailsQuery.isFetching && detailsQuery.error) return <MyAlert type={"danger"}>{detailsQuery.error.response?.data.detail}</MyAlert>

     const schema = Yup.object().shape({
          notes: Yup.string().label(`"Notes"`).nullable(),
          disponibilite: Yup.string().label(`"Disponibilité"`).nullable(),
          disponibiliteDate: Yup.string()
               .when("disponibilite", {
                    is: CONST_DISPONIBILITE_DATE_EXACTE,
                    then: schema => schema.required(),
               })
               .label(`"Date de disponibilité"`)
               .nullable(),
          mobilite: Yup.string().nullable().label(`"Mobilité"`).nullable(),
          mobiliteRegions: Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_REGIONAL,
                    then: schema => schema.required(),
               })
               .label(`"Régions"`)
               .nullable(),
          mobiliteDepartements: Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_DEPARTEMENTAL,
                    then: schema => schema.required(),
               })
               .label(`"Départements"`)
               .nullable(),
          mobiliteVilles: Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_PAR_VILLE,
                    then: schema => schema.required(),
               })
               .label(`"Villes"`)
               .nullable(),
          salaireNetMensuelSouhaite: Yup.number().label(`"Salaire Net mensuel souhaité"`).nullable(),
          voulezVousAjouterOuRemplacerDocument: Yup.boolean(),
          document: Yup.mixed()
               .when("voulezVousAjouterOuRemplacerDocument", {
                    is: true,
                    then: schema => schema.required(),
               })
               .test("fileFormat", "Seuls les formats word ou PDF sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_PDF, CONST_MIME_APPLICATION_DOCX, CONST_MIME_APPLICATION_DOC, CONST_MIME_APPLICATION_GOOGLE_DOC].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Document"`),
     })

     const initialValues: ICandidatSuiviEditFormikModel = {
          notes: detailsQuery.data?.commentaire,
          ...(detailsQuery.data?.type === CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CLIENT ? { client: detailsQuery.data?.client?.id } : {}),
          ...(detailsQuery.data?.type === CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE && detailsQuery.data?.candidatSuiviSMDs && detailsQuery.data?.candidatSuiviSMDs.length > 0
               ? {
                      disponibilite: detailsQuery.data?.candidatSuiviSMDs[0].disponibilite,
                      disponibiliteDate: detailsQuery.data?.candidatSuiviSMDs[0].disponibiliteDate?.format("YYYY-MM-DD"),
                      mobilite: detailsQuery.data?.candidatSuiviSMDs[0].mobilite,
                      mobiliteRegions: detailsQuery.data?.candidatSuiviSMDs[0].candidatSuiviSMDRegions.map(item => item.region?.id as number),
                      mobiliteDepartements: detailsQuery.data?.candidatSuiviSMDs[0].candidatSuiviSMDDepartements.map(item => item.departement?.id as number),
                      mobiliteVilles: detailsQuery.data?.candidatSuiviSMDs[0].candidatSuiviSMDVilles.map(item => item.ville?.id as number),
                      salaireNetMensuelSouhaite: detailsQuery.data?.candidatSuiviSMDs[0].salaire,
                 }
               : {}),
          voulezVousAjouterOuRemplacerDocument: false,
     }

     function handleSubmit(values: ICandidatSuiviEditFormikModel, helpers: FormikHelpers<ICandidatSuiviEditFormikModel>) {
          helpers.setStatus(null)

          const requestModel: ICandidatSuiviEditRequestModel = {
               notes: values.notes,
               ...(detailsQuery.data?.type === CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE
                    ? {
                           disponibilite: values.disponibilite,
                           disponibiliteDate: values.disponibiliteDate,
                           mobilite: values.mobilite,
                           mobiliteRegions: values.mobiliteRegions,
                           mobiliteDepartements: values.mobiliteDepartements,
                           mobiliteVilles: values.mobiliteVilles,
                           salaireNetMensuelSouhaite: values.salaireNetMensuelSouhaite,
                      }
                    : {}),
               ...(detailsQuery.data?.type === CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CLIENT
                    ? {
                           client: values.client,
                      }
                    : {}),
               document: values.document,
          }

          setCandidatSuivi(id, requestModel)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    handleSubmitCallBack(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}
                              {/* Notes */}
                              <>
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Notes</label>
                                   <MyEditorField value={helpers.values.notes} height={250} onChange={val => helpers.setFieldValue("notes", val)} placeholder={"Notes"} />
                                   <div className={"mb-2"}>{helpers.errors.notes && <div className={"text-danger"}>{helpers.errors.notes}</div>}</div>
                              </>

                              {detailsQuery.data?.type === CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE && (
                                   <>
                                        {/* Dispo */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Disponibilité</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"disponibilite"}
                                                       options={CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.disponibilite}
                                                       onChange={val => helpers.setFieldValue("disponibilite", val)}
                                                       isInvalid={!!helpers.errors.disponibilite}
                                                       isSearchable={false}
                                                  />
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.disponibilite && <div className={"text-danger"}>{helpers.errors.disponibilite}</div>}
                                                  </div>

                                                  {helpers.values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && (
                                                       <>
                                                            <MyDateDayMonthYearField
                                                                 name={"disponibiliteDate"}
                                                                 value={helpers.values.disponibiliteDate}
                                                                 placeholder={"Choisissez votre date"}
                                                                 onChange={val => helpers.setFieldValue("disponibiliteDate", val)}
                                                                 isInvalid={!!helpers.errors.disponibiliteDate}
                                                            />
                                                            {helpers.errors.disponibiliteDate && <div className={"text-danger"}>{helpers.errors.disponibiliteDate}</div>}
                                                       </>
                                                  )}
                                             </div>
                                        </div>

                                        {/* Mobilité */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mobilité</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"mobilite"}
                                                       options={CONST_MOBILITE_OPTIONS_FOR_SELECT2}
                                                       value={helpers.values.mobilite}
                                                       isInvalid={!!helpers.errors.mobilite}
                                                       onChange={val => helpers.setFieldValue("mobilite", val)}
                                                       isSearchable={false}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.mobilite && <div className={"text-danger"}>{helpers.errors.mobilite}</div>}</div>

                                                  {helpers.values.mobilite === CONST_MOBILITE_DEPARTEMENTAL && (
                                                       <>
                                                            <MySelectSearchDataFromServer
                                                                 name={"mobiliteDepartements"}
                                                                 value={helpers.values.mobiliteDepartements}
                                                                 onChange={val => helpers.setFieldValue("mobiliteDepartements", val)}
                                                                 defaultOptions={(
                                                                      (detailsQuery.data.candidatSuiviSMDs &&
                                                                           detailsQuery.data.candidatSuiviSMDs.length > 0 &&
                                                                           detailsQuery.data.candidatSuiviSMDs[0].candidatSuiviSMDDepartements) ||
                                                                      []
                                                                 ).map(item => ({
                                                                      label: item.departement?.nom as string,
                                                                      value: item.departement?.id as number,
                                                                 }))}
                                                                 url={CONST_API_UTILS_GET_DEPARTEMENTS_URL_ENDPOINT}
                                                                 isInvalid={!!helpers.errors.mobiliteDepartements}
                                                                 method={"GET"}
                                                                 minimumLengthSearch={3}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.mobiliteDepartements && <div className={"text-danger"}>{helpers.errors.mobiliteDepartements}</div>}
                                                       </>
                                                  )}

                                                  {helpers.values.mobilite === CONST_MOBILITE_REGIONAL && (
                                                       <>
                                                            <MySelectGetAllDataFromServer
                                                                 name={"mobiliteRegions"}
                                                                 value={helpers.values.mobiliteRegions}
                                                                 defaultOptions={(
                                                                      (detailsQuery.data.candidatSuiviSMDs &&
                                                                           detailsQuery.data.candidatSuiviSMDs.length > 0 &&
                                                                           detailsQuery.data.candidatSuiviSMDs[0].candidatSuiviSMDRegions) ||
                                                                      []
                                                                 ).map(item => ({
                                                                      label: item.region?.nom as string,
                                                                      value: item.region?.id as number,
                                                                 }))}
                                                                 onChange={val => helpers.setFieldValue("mobiliteRegions", val)}
                                                                 isInvalid={!!helpers.errors.mobiliteRegions}
                                                                 url={CONST_API_UTILS_GET_REGIONS_URL_ENDPOINT}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.mobiliteRegions && <div className={"text-danger"}>{helpers.errors.mobiliteRegions}</div>}
                                                       </>
                                                  )}
                                                  {helpers.values.mobilite === CONST_MOBILITE_PAR_VILLE && (
                                                       <>
                                                            <MySelectSearchDataFromServer
                                                                 name={"mobiliteVilles"}
                                                                 value={helpers.values.mobiliteVilles}
                                                                 onChange={val => helpers.setFieldValue("mobiliteVilles", val)}
                                                                 defaultOptions={(
                                                                      (detailsQuery.data.candidatSuiviSMDs &&
                                                                           detailsQuery.data.candidatSuiviSMDs.length > 0 &&
                                                                           detailsQuery.data.candidatSuiviSMDs[0].candidatSuiviSMDVilles) ||
                                                                      []
                                                                 ).map(item => ({
                                                                      label: item.ville?.nom as string,
                                                                      value: item.ville?.id as number,
                                                                 }))}
                                                                 url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                 isInvalid={!!helpers.errors.mobiliteVilles}
                                                                 method={"GET"}
                                                                 minimumLengthSearch={3}
                                                                 isMulti
                                                            />
                                                            {helpers.errors.mobiliteVilles && <div className={"text-danger"}>{helpers.errors.mobiliteVilles}</div>}
                                                       </>
                                                  )}
                                             </div>
                                        </div>

                                        {/* Salaire */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Salaire net mensuel souhaité</label>

                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"salaireNetMensuelSouhaite"}
                                                       value={helpers.values.salaireNetMensuelSouhaite}
                                                       onChange={val => helpers.setFieldValue("salaireNetMensuelSouhaite", val)}
                                                       placeholder={"Salaire net souhaité"}
                                                       isInvalid={!!helpers.errors.salaireNetMensuelSouhaite}
                                                       inputMode={"numeric"}
                                                       type={"number"}
                                                  />
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.salaireNetMensuelSouhaite && <div className={"text-danger"}>{helpers.errors.salaireNetMensuelSouhaite}</div>}
                                                  </div>
                                             </div>
                                        </div>

                                        {/* Field voulezVousAjouterOuRemplacerDocument */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                  {detailsQuery.data?.candidatSuiviDocuments && detailsQuery.data?.candidatSuiviDocuments.length > 0
                                                       ? "Voulez-vous remplacer le document du suivi?"
                                                       : "Voulez-vous joindre un document au suivi?"}
                                             </label>

                                             <div className="col-lg-8 d-flex">
                                                  <div className="d-flex align-items-center">
                                                       <MyCheckBoxField
                                                            name={"voulezVousAjouterOuRemplacerDocument"}
                                                            value={helpers.values.voulezVousAjouterOuRemplacerDocument}
                                                            isInvalid={!!helpers.errors.voulezVousAjouterOuRemplacerDocument}
                                                            onChange={val => helpers.setFieldValue("voulezVousAjouterOuRemplacerDocument", val)}
                                                       />
                                                  </div>
                                                  {helpers.values.voulezVousAjouterOuRemplacerDocument && (
                                                       <div className="flex-grow-1 ms-2">
                                                            <MyFileField
                                                                 name={"document"}
                                                                 isInvalid={!!helpers.errors.document}
                                                                 onChange={val => helpers.setFieldValue("document", val)}
                                                            />
                                                            {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                                       </div>
                                                  )}
                                             </div>
                                        </div>
                                   </>
                              )}

                              {/* Submit button */}
                              <div className={"text-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? "Appliquer les modifications" : <MySimpleSpinner size={"sm"} />}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default CandidatSuiviEditForm
