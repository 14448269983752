import React, { FC } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../../AppContext"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { useThemeMode } from "@common-metronic/partials"

const HeaderUserMenu: FC = () => {
     const { mode } = useThemeMode()
     const { currentUser, logout } = useAuth()

     return (
          <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
               <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                         <div className="symbol symbol-50px me-5">
                              <img
                                   alt="Logo"
                                   src={
                                        currentUser?.avatarGoogleDriveID
                                             ? FileAndDriveHelper.getThumbnailDriveUrl(currentUser.avatarGoogleDriveID)
                                             : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                   }
                              />
                         </div>

                         <div className="d-flex flex-column">
                              <div className="fw-bolder d-flex align-items-center fs-5">{currentUser?.prenomNom}</div>
                         </div>
                    </div>
               </div>

               <div className="separator my-2"></div>

               <div className="menu-item px-5">
                    <Link to={"/personnel/mon-compte/profil"} className="menu-link px-5">
                         Mon profil
                    </Link>
               </div>

               <div className="separator my-2"></div>

               {/*<Languages/>*/}

               <div className="menu-item px-5 my-1">
                    <Link to="/personnel/mon-compte/settings" className="menu-link px-5">
                         Paramètres du compte
                    </Link>
               </div>

               <div className="menu-item px-5">
                    <span onClick={logout} className="menu-link px-5 cursor-pointer">
                         Déconnexion
                    </span>
               </div>
          </div>
     )
}

export { HeaderUserMenu }
