import axios from "axios"
import { IPersonnelCraModel, IPersonnelDeclarationMensuelleModel, IPersonnelDeclarationMensuelleSuiviModel, IPersonnelNdfModel } from "@common-models/*"
import {
     CONST_API_PERSONNEL_DECLARATION_CRA_AUTOCOMPLETE_HEURES_TRAVAIL_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_CRA_CREATE_LIGNE_ACTIVITE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_CRA_DELETE_LIGNE_ACTIVITE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS_TOGGLE_ALL_TR_MONTH_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS_TOGGLE_TR_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_CRA_SET_HEURES_ABSENCE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_CRA_SET_HEURES_TRAVAIL_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_GET_ACTIVE_CRA_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_GET_ACTIVE_NDF_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_GET_BY_ID_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_RECTIF_PAR_RESP_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_RECTIF_PAR_SIEGE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_REGUL_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_FINALISER_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_RECTIF_SANS_VALIDATION_RESP_OU_SIEGE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_REENVOYER_MAIL_FINALISATION_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_REJET_DEMANDE_REGUL_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_SIGNER_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_DEMANDE_REGUL_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_PAR_RESP_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_PAR_SIEGE_ENDPOINT,
} from "../../../../endpoints"
import {
     IAutocompletePersonnelDeclarationCraHeuresTravailRequestModel,
     ISetPersonnelDeclarationCraHeuresAbsenceRequestModel,
     ISetPersonnelDeclarationCraHeuresTravailRequestModel,
} from "./_models"

export function getPersonnelDeclarationConsultantSuiviRequest(declaration_id: number) {
     return axios.get<IPersonnelDeclarationMensuelleSuiviModel[]>(CONST_API_PERSONNEL_DECLARATION_SUIVI_ENDPOINT(declaration_id))
}

export function getPersonnelDeclarationByIdRequest(id: number) {
     return axios.get<IPersonnelDeclarationMensuelleModel>(CONST_API_PERSONNEL_DECLARATION_GET_BY_ID_ENDPOINT(id))
}

export function getPersonnelDeclarationActiveNdfRequest(id: number) {
     return axios.get<IPersonnelNdfModel>(CONST_API_PERSONNEL_DECLARATION_GET_ACTIVE_NDF_ENDPOINT(id))
}

// Suivi
// -- Finaliser
export function suiviEnvoyerCodeSignaturePersonnelDeclarationRequest(id: number) {
     return axios.post(CONST_API_PERSONNEL_DECLARATION_SUIVI_FINALISER_ENDPOINT(id), {})
}

// -- Reenvoyer mail finalisation
export function suiviReenvoyerMailFinalisationPersonnelDeclarationRequest(id: number) {
     return axios.post(CONST_API_PERSONNEL_DECLARATION_SUIVI_REENVOYER_MAIL_FINALISATION_ENDPOINT(id), {})
}

// -- Rectification sans validation resp / siege
export function suiviRectificationSansValidationRespOuSiegePersonnelDeclarationRequest(id: number) {
     return axios.post<IPersonnelDeclarationMensuelleSuiviModel>(CONST_API_PERSONNEL_DECLARATION_SUIVI_RECTIF_SANS_VALIDATION_RESP_OU_SIEGE_ENDPOINT(id), {})
}

// -- Signer
export function suiviSignerPersonnelDeclarationRequest(id: number, code: string, commentaire?: string) {
     return axios.post(CONST_API_PERSONNEL_DECLARATION_SUIVI_SIGNER_ENDPOINT(id), {
          code,
          commentaire,
     })
}

// -- Demande rectif par resp
export function suiviDemandeRectifParRespPersonnelDeclarationRequest(id: number, commentaire: string) {
     return axios.post<IPersonnelDeclarationMensuelleSuiviModel>(CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_RECTIF_PAR_RESP_ENDPOINT(id), { commentaire })
}

// -- Demande rectif par siège
export function suiviDemandeRectifParSiegePersonnelDeclarationRequest(id: number, commentaire: string) {
     return axios.post(CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_RECTIF_PAR_SIEGE_ENDPOINT(id), {
          commentaire,
     })
}

// -- Validation par resp
export function suiviValidationParRespPersonnelDeclarationRequest(id: number, commentaire: string) {
     return axios.post<IPersonnelDeclarationMensuelleSuiviModel>(CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_PAR_RESP_ENDPOINT(id), { commentaire })
}

// -- Validation par siège
export function suiviValidationParSiegePersonnelDeclarationRequest(id: number, commentaire: string) {
     return axios.post(CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_PAR_SIEGE_ENDPOINT(id), {
          commentaire,
     })
}

// -- Demande de régul
export function suiviDemandeRegulPersonnelDeclarationRequest(id: number, commentaire: string) {
     return axios.post(CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_REGUL_ENDPOINT(id), {
          commentaire,
     })
}

// -- Validation demande de régul par resp
export function suiviValidationDemandeRegulParRespPersonnelDeclarationRequest(id: number) {
     return axios.post<IPersonnelDeclarationMensuelleSuiviModel>(CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_DEMANDE_REGUL_ENDPOINT(id), {})
}

// -- Rejet demande de régul par resp
export function suiviRejetDemandeRegulParRespPersonnelDeclarationRequest(id: number, commentaire: string) {
     return axios.post<IPersonnelDeclarationMensuelleSuiviModel>(CONST_API_PERSONNEL_DECLARATION_SUIVI_REJET_DEMANDE_REGUL_ENDPOINT(id), { commentaire })
}

// CRA
export function getPersonnelDeclarationActiveCra(id: number) {
     return axios.get<IPersonnelCraModel>(CONST_API_PERSONNEL_DECLARATION_GET_ACTIVE_CRA_ENDPOINT(id))
}

export function setPersonnelDeclarationCraHeuresTravail(id: number, payload: ISetPersonnelDeclarationCraHeuresTravailRequestModel) {
     return axios.post<IPersonnelCraModel>(CONST_API_PERSONNEL_DECLARATION_CRA_SET_HEURES_TRAVAIL_ENDPOINT(id), payload)
}

export function setPersonnelDeclarationCraHeuresAbsence(id: number, payload: ISetPersonnelDeclarationCraHeuresAbsenceRequestModel) {
     return axios.post<IPersonnelCraModel>(CONST_API_PERSONNEL_DECLARATION_CRA_SET_HEURES_ABSENCE_ENDPOINT(id), payload)
}

export function autoCompletePersonnelDeclarationCraHeuresTravail(id: number, payload: IAutocompletePersonnelDeclarationCraHeuresTravailRequestModel) {
     return axios.post<IPersonnelCraModel>(CONST_API_PERSONNEL_DECLARATION_CRA_AUTOCOMPLETE_HEURES_TRAVAIL_ENDPOINT(id), payload)
}

export function deletePersonnelDeclarationCraLigneActivite(cra_id: number, type: string) {
     return axios.post<IPersonnelCraModel>(CONST_API_PERSONNEL_DECLARATION_CRA_DELETE_LIGNE_ACTIVITE_ENDPOINT(cra_id), { type })
}

export function createPersonnelDeclarationCraLigneActivite(cra_id: number, type: string) {
     return axios.post<IPersonnelCraModel>(CONST_API_PERSONNEL_DECLARATION_CRA_CREATE_LIGNE_ACTIVITE_ENDPOINT(cra_id), { type })
}

// CRA DETAILS
export function togglePersonnelDeclarationTrRequest(id: number) {
     return axios.put<{ ticketNewValue: boolean }>(CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS_TOGGLE_TR_ENDPOINT(id), {})
}

export function toggleAllPersonnelDeclarationTrMonthRequest(mois: string, annee: string) {
     return axios.put<{ personnelCraDetailsAffectedIds: number[] }>(CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS_TOGGLE_ALL_TR_MONTH_ENDPOINT, { mois, annee })
}
