import React, { useRef } from "react"
import { CONST_DEFAULT_TOAST_OPTIONS } from "@common-constants/*"
import { IConsultantDeclarationMensuelleModel } from "@common-models/*"
import { toast } from "react-toastify"
import { useConsultantsDeclarationsListingContext } from "../ConsultantsDeclarationsListing"
import { IGetConsultantsDeclarationsDataItemResponseModel } from "../core/_models"
import { getSelectedDeclarationPlusPreviousAndNextDeclaration } from "../core/helpers"
import { EtatBadge, IconRelance } from "./_ConsultantsDeclarationsListingListItemHelpers"
import MyTooltip from "@common-utils/MyTooltip"
import { Link } from "react-router-dom"

// List context
const ConsultantsDeclarationsListingListItem = ({ item, index }: { item: IGetConsultantsDeclarationsDataItemResponseModel; index: number }) => {
     const context = useConsultantsDeclarationsListingContext()
     const checkboxInputRef = useRef<any>(null)

     // Is tr clickable
     function isTrClickable(e: React.MouseEvent<any>, declaration: IConsultantDeclarationMensuelleModel | null): boolean {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInModal?.selectedItem.declaration?.id !== declaration?.id) {
               return !!declaration
          }

          return false
     }

     // Shows déclaration in a modal
     function handleClickItem(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview")) {
               const isRowClickable = isTrClickable(e, item.declaration)

               if (isRowClickable) {
                    context.setItemInModal(
                         getSelectedDeclarationPlusPreviousAndNextDeclaration(
                              item,
                              index,
                              context.listingQuery.data!.data,
                              context.listingQuery.data!.page,
                              context.listingQuery.data!.totalPages
                         )
                    )
               } else {
                    toast.info("Aucune déclaration à afficher", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 1000 })
               }
          }
     }

     function handleOnCheckItem(e: React.ChangeEvent<HTMLInputElement>) {
          const isItemChecked = context.isBulkSelectionEnabled.checkedElementsInPage.find(
               (item_: IGetConsultantsDeclarationsDataItemResponseModel) => item_.consultant?.id === item.consultant?.id
          )
          if (!isItemChecked) {
               context.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               context.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(
                         (item_: IGetConsultantsDeclarationsDataItemResponseModel) => item_.consultant?.id !== item.consultant?.id
                    ),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <>
               <tr className={`bg-hover-light-primary align-middle`} key={item.consultant?.id} onClick={handleClickItem}>
                    {/* checkbox */}
                    {context.isBulkSelectionEnabled.enabled && (
                         <td>
                              <div className={`form-check form-check-custom form-check-solid form-check-sm not-concerned-by-preview cursor-pointer`}>
                                   <input
                                        type="checkbox"
                                        className={`form-check-input border border-secondary not-concerned-by-preview me-2`}
                                        style={{ height: "2rem", width: "2rem" }}
                                        checked={context.isBulkSelectionEnabled.checkedElementsInPage.find(
                                             (item_: IGetConsultantsDeclarationsDataItemResponseModel) => item.consultant?.id === item_.consultant?.id
                                        )}
                                        onChange={handleOnCheckItem}
                                        ref={checkboxInputRef}
                                   />
                              </div>
                         </td>
                    )}
                    <td>{item.entite.nom}</td>
                    <td>
                         <MyTooltip title={"Voir le consultant"}>
                              <Link to={`/business/consultants/${item.consultant.id}/details`} target={"_blank"}>
                                   <span className={"not-concerned-by-preview"}>{item.consultant.prenomNom}</span>
                              </Link>
                         </MyTooltip>
                    </td>
                    <td>{item.responsable.prenomNom}</td>
                    <td className={"not-concerned-by-preview"}>
                         {item.hasSuivi ? (
                              <MyTooltip title={"Voir le suivi"}>
                                   <div className={"not-concerned-by-preview"} onClick={() => context.setSuiviInModal(item.declaration as IConsultantDeclarationMensuelleModel)}>
                                        <EtatBadge declaration={item.declaration} />
                                   </div>
                              </MyTooltip>
                         ) : (
                              <EtatBadge declaration={item.declaration} />
                         )}
                    </td>
                    <td className={"text-center fs-3 cursor-default"}>
                         {item.derniereRelance ? (
                              <MyTooltip
                                   title={`Dernière relance le ${item.derniereRelance.createdAt?.format("dddd D MMMM YYYY à H[h]m")} par ${item.derniereRelance.relanceur
                                        ?.prenomNom}`}
                              >
                                   <span className={"fas fa-check-circle text-success"} />
                              </MyTooltip>
                         ) : (
                              <span className={"fas fa-times-circle text-muted not-concerned-by-preview"} />
                         )}
                    </td>
                    <td style={{ minWidth: "10rem" }}>
                         {item.declaration && (
                              <MyTooltip title={"Télécharger cette déclaration"}>
                                   <span
                                        className={"fas fa-download fs-1 text-dark me-4 not-concerned-by-preview text-hover-danger"}
                                        onClick={() => context.handleDownloadDeclarations([item])}
                                   />
                              </MyTooltip>
                         )}
                         <MyTooltip title={"Consulter cette déclaration"}>
                              <span className={"fas fa-eye fs-1 text-dark text-hover-primary"} />
                         </MyTooltip>
                         <MyTooltip title={"Relancer par mail"}>
                              <span className={"not-concerned-by-preview"}>
                                   <IconRelance item={item} />
                              </span>
                         </MyTooltip>
                    </td>
               </tr>
          </>
     )
}

export default ConsultantsDeclarationsListingListItem
