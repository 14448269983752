import { usePersonnelDetailsContext } from "../PersonnelDetails"
import { CONST_PAYS_ID_FRANCE } from "@common-constants/*"

const InfoPersoViewOnly = () => {
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data

     return (
          <>
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Civilité</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.civiliteLabel}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.prenomNom}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Date naissance</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.dateNaissance?.format("DD/MM/YYYY")}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Email</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              <a className={"text-dark text-hover-primary"} href={`mailto:${details?.personnel.mailPersonnel}`}>
                                   {details?.personnel.mailPersonnel}
                              </a>
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Téléphone</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              <a
                                   href={`tel:${details?.personnel.telPersonnelIndicatif ? details?.personnel.telPersonnelIndicatif.phoneCodeWithPlusPrefix : ""} ${details
                                        ?.personnel.telProfessionnel}`}
                                   className="text-dark text-hover-primary"
                              >
                                   {details?.personnel.telPersonnelIndicatif ? details?.personnel.telPersonnelIndicatif.phoneCodeWithPlusPrefix : ""}{" "}
                                   {details?.personnel.telPersonnel}
                              </a>
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">N° de sécurité sociale</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.nSecuriteSociale}</span>
                    </div>
               </div>

               <h5 className="mt-8 mb-4">Résidence</h5>
               <div className="separator separator-dashed mb-6" />

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Adresse</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.residenceAdresse}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Ville et code postal</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {details?.personnel.residencePays?.id === CONST_PAYS_ID_FRANCE ? details?.personnel?.residenceVilleFrance?.nom : details?.personnel?.residenceVille}{" "}
                              {details?.personnel?.residenceCodePostal}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Pays</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.residencePays?.nomFr}</span>
                    </div>
               </div>

               <h5 className="mt-8 mb-4">Personne à contacter en cas d'urgence</h5>
               <div className="separator separator-dashed mb-6" />

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{details?.personnel.personneToContactUrgencePrenomNom}</span>
                    </div>
               </div>

               <div className="row">
                    <label className="col-lg-4 fw-bold text-muted">Téléphone</label>
                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              <a
                                   href={`tel:${
                                        details?.personnel.personneToContactUrgenceTelIndicatif
                                             ? details?.personnel.personneToContactUrgenceTelIndicatif.phoneCodeWithPlusPrefix
                                             : ""
                                   } ${details?.personnel.personneToContactUrgenceTel}`}
                                   className="text-dark text-hover-primary"
                              >
                                   {details?.personnel.personneToContactUrgenceTelIndicatif ? details?.personnel.personneToContactUrgenceTelIndicatif.phoneCodeWithPlusPrefix : ""}{" "}
                                   {details?.personnel.personneToContactUrgenceTel}
                              </a>
                         </span>
                    </div>
               </div>
          </>
     )
}

export default InfoPersoViewOnly
