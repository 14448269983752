import { IConsultantSuiviProlongementMissionSalarieRequestModel, IGetPrefillFormDataProlongementMissionRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MIME_APPLICATION_DOCX, CONST_PAYS_ID_FRANCE } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import { consultantSuiviProlongementMissionSalarieRequestModel, getPrefillFormDataProlongementMissionRequest } from "../core/_requests"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { useQuery } from "react-query"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_PAYS_ENDPOINT, CONST_API_UTILS_GET_VILLES_URL_ENDPOINT } from "../../../../../endpoints"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import * as Yup from "yup"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyFileField from "@common-utils/fields/MyFileField"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"

const ConsultantSuiviProlongementMissionSalarie = ({
     consultant_ID,
     onSubmitCallback = () => {},
     annulerCallback = () => {},
     setModalNoPaddingCallback = () => {},
     setModalSizeCallback = () => {},
}: IConsultantSuiviProlongementMissionSalariePropsModel) => {
     const [ordreMission, setOrdreMission] = useState<{
          loading: boolean
          googleDriveID: string
     }>()
     const prefillInfoQuery = useQuery<IGetPrefillFormDataProlongementMissionRequestModel, AxiosError>("REACT_QUERY_KEY_GET_PRE_FILL_INFO", () =>
          getPrefillFormDataProlongementMissionRequest(consultant_ID).then(r => r.data)
     )

     // Schema
     const schema = Yup.object().shape({
          dateDebut: Yup.string().required().nullable().label(`"Date de début"`),
          dateFin: Yup.string().required().nullable().label(`"Date de fin"`),
          adresse: Yup.string().required().nullable().label(`"Adresse postale"`),
          ville: Yup.string()
               .nullable()
               .label(`"Ville"`)
               .when("pays", {
                    is: val => val && val !== CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          villeFrance: Yup.number()
               .nullable()
               .label(`"Ville"`)
               .when("pays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          codePostal: Yup.number().required().nullable().label(`"Code postal"`),
          pays: Yup.number().required().nullable().label(`"Pays"`),
          ordreMissionUploadedOrGenerated: Yup.string().required().nullable().label(`"Génération ou upload"`),
          document: Yup.mixed()
               .test("fileFormat", "Seul le format Word est autorisé.", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_DOCX].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Ordre de mission"`)
               .when("ordreMissionUploadedOrGenerated", {
                    is: "ORDRE_MISSION_UPLOADED_OR_GENERATED__UPLOADED",
                    then: schema => schema.required(),
               }),
     })

     // Init values
     const initialValues: IConsultantSuiviProlongementMissionSalarieRequestModel = {
          dateDebut: prefillInfoQuery.data?.dateDebut?.format("YYYY-MM-DD"),
          dateFin: prefillInfoQuery.data?.dateFin?.format("YYYY-MM-DD"),
          adresse: prefillInfoQuery.data?.mission?.travailAdresse,
          ville: prefillInfoQuery.data?.mission?.travailVille,
          villeFrance: prefillInfoQuery.data?.mission?.travailvillle?.id,
          codePostal: prefillInfoQuery.data?.mission?.travailCodePostal,
          pays: prefillInfoQuery.data?.mission?.travailPays?.id,
     }

     // Handle submit
     function handleSubmit(values: IConsultantSuiviProlongementMissionSalarieRequestModel, helpers: FormikHelpers<IConsultantSuiviProlongementMissionSalarieRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviProlongementMissionSalarieRequestModel(consultant_ID, values)
               .then(r => {
                    if (values.ordreMissionUploadedOrGenerated === "ORDRE_MISSION_UPLOADED_OR_GENERATED__UPLOADED") {
                         onSubmitCallback()
                         toast.success("Mission prolongée.")
                    } else {
                         setModalNoPaddingCallback(true)
                         setModalSizeCallback("fullscreen")
                         setOrdreMission({ loading: true, googleDriveID: r.data.ordreDeMissionGoogleDriveID })
                         toast.info("Assurez-vous que le document généré est conforme à vos attentes. Sinon merci d'apporter les modifications nécessaires.", { autoClose: false })
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     if (ordreMission) {
          return (
               <>
                    <div className="text-center mt-2">
                         <button
                              type={"button"}
                              className={"btn btn-sm btn-primary"}
                              onClick={() => {
                                   onSubmitCallback()
                                   toast.success(`Mission prolongée.`)
                              }}
                         >
                              TERMINER <i className={"fas fa-check-circle"} />
                         </button>
                    </div>
                    <MyLoadingOverlay isActive={ordreMission.loading}>
                         <iframe
                              onLoad={() => setOrdreMission(prev => prev && { ...prev, loading: false })}
                              src={FileAndDriveHelper.getUrlOfDriveFileEditMode(ordreMission.googleDriveID)}
                              className={"vh-100 w-100"}
                         />
                    </MyLoadingOverlay>
               </>
          )
     } else {
          return (
               <>
                    {prefillInfoQuery.isFetching && (
                         <div className={"text-center p-5"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {!prefillInfoQuery.isFetching && prefillInfoQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{prefillInfoQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => prefillInfoQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}

                    {!prefillInfoQuery.isFetching && !prefillInfoQuery.isError && prefillInfoQuery.data && (
                         <>
                              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                   {helpers => {
                                        console.log(helpers.errors)
                                        return (
                                             <Form noValidate autoComplete="off">
                                                  {helpers.status && (
                                                       <MyAlert type={"danger"} classNames={"mb-4"}>
                                                            {helpers.status}
                                                       </MyAlert>
                                                  )}

                                                  {/* Upload ou Génération*/}
                                                  <div className="border border-dashed p-4">
                                                       <h4 className={"mb-4"}>ODM</h4>
                                                       <div className="separator separator-dashed mb-6" />
                                                       <div className="row">
                                                            <div className="col-lg-6 mb-2 mb-lg-0">
                                                                 <MyRadioWithLabelAndDescriptionField
                                                                      name={"ordreMissionUploadedOrGenerated"}
                                                                      value={"ORDRE_MISSION_UPLOADED_OR_GENERATED__UPLOADED"}
                                                                      isChecked={helpers.values.ordreMissionUploadedOrGenerated === "ORDRE_MISSION_UPLOADED_OR_GENERATED__UPLOADED"}
                                                                      label={"Saisie manuelle"}
                                                                      description={"Joignez votre propre document"}
                                                                      iconFontAwesomeClassName={"upload"}
                                                                      color={"primary"}
                                                                      isInvalid={!!helpers.errors.ordreMissionUploadedOrGenerated}
                                                                      onChange={val => helpers.setFieldValue("ordreMissionUploadedOrGenerated", val)}
                                                                 />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                 <MyRadioWithLabelAndDescriptionField
                                                                      name={"ordreMissionUploadedOrGenerated"}
                                                                      value={"ORDRE_MISSION_UPLOADED_OR_GENERATED__GENERATED"}
                                                                      isChecked={
                                                                           helpers.values.ordreMissionUploadedOrGenerated === "ORDRE_MISSION_UPLOADED_OR_GENERATED__GENERATED"
                                                                      }
                                                                      label={
                                                                           <span>
                                                                                Génération automatique <br /> ( recommandé )
                                                                           </span>
                                                                      }
                                                                      description={"Générer un ODM pré-rempli éditable en ligne"}
                                                                      iconFontAwesomeClassName={"bolt"}
                                                                      color={"success"}
                                                                      isInvalid={!!helpers.errors.ordreMissionUploadedOrGenerated}
                                                                      onChange={val => helpers.setFieldValue("ordreMissionUploadedOrGenerated", val)}
                                                                 />
                                                            </div>
                                                       </div>

                                                       {/* ODM */}
                                                       {helpers.values.ordreMissionUploadedOrGenerated === "ORDRE_MISSION_UPLOADED_OR_GENERATED__UPLOADED" && (
                                                            <div className="row mt-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ordre de mission</label>

                                                                 <div className="col-lg-8">
                                                                      <MyFileField
                                                                           name={"document"}
                                                                           onChange={val => helpers.setFieldValue("document", val)}
                                                                           isInvalid={!!helpers.errors.document}
                                                                           accept={`${CONST_MIME_APPLICATION_DOCX}`}
                                                                      />
                                                                      <div className={"mb-2"}>
                                                                           {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       )}

                                                       {helpers.values.ordreMissionUploadedOrGenerated === "ORDRE_MISSION_UPLOADED_OR_GENERATED__GENERATED" && (
                                                            <MyAlert type={"primary"} classNames={"mt-4"}>
                                                                 <b className={"text-dark"}>Vous avez opter pour une génération automatique</b>. Une fois que vous aurez validé le
                                                                 formulaire, un Ordre De Mission sera généré en se basant sur les informations saisies, et vous pourrez ensuite le
                                                                 modifier en ligne.
                                                            </MyAlert>
                                                       )}
                                                  </div>
                                                  <div className={"mb-2"}>
                                                       {helpers.errors.ordreMissionUploadedOrGenerated && (
                                                            <div className={"text-danger"}>{helpers.errors.ordreMissionUploadedOrGenerated}</div>
                                                       )}
                                                  </div>

                                                  <div className="border border-dashed p-4 mb-2">
                                                       <h4 className={"mb-4"}>Mission</h4>
                                                       <div className="separator separator-dashed mb-6" />

                                                       {/* Période */}
                                                       <div className="row mb-5">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nouvelle période</label>

                                                            <div className="col-lg-8">
                                                                 <div className="d-flex">
                                                                      <MyDateDayMonthYearField
                                                                           name={"dateDebut"}
                                                                           value={helpers.values.dateDebut}
                                                                           isInvalid={!!helpers.errors.dateDebut}
                                                                           maxDate={helpers.values.dateFin}
                                                                           onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                                           placeholder={"Date de début"}
                                                                           classNames={"rounded-0 rounded-start"}
                                                                      />
                                                                      <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                      <MyDateDayMonthYearField
                                                                           name={"dateFin"}
                                                                           value={helpers.values.dateFin}
                                                                           minDate={helpers.values.dateDebut}
                                                                           isInvalid={!!helpers.errors.dateFin}
                                                                           onChange={val => helpers.setFieldValue("dateFin", val)}
                                                                           placeholder={"Date de fin"}
                                                                           classNames={"rounded-0 rounded-end"}
                                                                      />
                                                                 </div>
                                                                 {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                                                 {helpers.errors.dateFin && <div className={"text-danger"}>{helpers.errors.dateFin}</div>}
                                                            </div>
                                                       </div>

                                                       {/* Adresse postale */}
                                                       <div className="row mb-5">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Adresse postale</label>

                                                            <div className="col-lg-8">
                                                                 <MyInputField
                                                                      name={"adresse"}
                                                                      value={helpers.values.adresse}
                                                                      onChange={val => helpers.setFieldValue("adresse", val)}
                                                                      placeholder={"Adresse de résidence"}
                                                                      isInvalid={!!helpers.errors.adresse}
                                                                 />
                                                                 <div className={"mb-2"}>
                                                                      {helpers.errors.adresse && <div className={"text-danger"}>{helpers.errors.adresse}</div>}
                                                                 </div>
                                                            </div>
                                                       </div>

                                                       {/* Pays residence */}
                                                       <div className="row mb-5">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Pays</label>

                                                            <div className="col-lg-8">
                                                                 <MySelectGetAllDataFromServer
                                                                      name={"pays"}
                                                                      url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                                      defaultOptions={[
                                                                           {
                                                                                value: prefillInfoQuery?.data.mission.travailPays?.id as number,
                                                                                label: prefillInfoQuery?.data.mission.travailPays?.nomFr as string,
                                                                           },
                                                                      ]}
                                                                      value={helpers.values.pays}
                                                                      onChange={val => helpers.setFieldValue("pays", val)}
                                                                      isInvalid={!!helpers.errors.pays}
                                                                 />
                                                                 <div className={"mb-2"}>{helpers.errors.pays && <div className={"text-danger"}>{helpers.errors.pays}</div>}</div>
                                                            </div>
                                                       </div>

                                                       {helpers.values.pays && (
                                                            <>
                                                                 {helpers.values.pays === CONST_PAYS_ID_FRANCE ? (
                                                                      <div className="row mb-5">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                                           <div className="col-lg-8">
                                                                                <MySelectSearchDataFromServer
                                                                                     name={"villeFrance"}
                                                                                     value={helpers.values.villeFrance}
                                                                                     onChange={val => helpers.setFieldValue("villeFrance", val)}
                                                                                     defaultOptions={[
                                                                                          {
                                                                                               value: prefillInfoQuery?.data.mission.travailvillle?.id as number,
                                                                                               label: prefillInfoQuery?.data.mission.travailvillle?.nom as string,
                                                                                          },
                                                                                     ]}
                                                                                     url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                                     isInvalid={!!helpers.errors.villeFrance}
                                                                                     method={"GET"}
                                                                                     minimumLengthSearch={3}
                                                                                />
                                                                                {helpers.errors.villeFrance && <div className={"text-danger"}>{helpers.errors.villeFrance}</div>}
                                                                           </div>
                                                                      </div>
                                                                 ) : (
                                                                      <>
                                                                           {/* Ville hors france */}
                                                                           <div className="row mb-5">
                                                                                <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                                                <div className="col-lg-8">
                                                                                     <MyInputField
                                                                                          name={"ville"}
                                                                                          value={helpers.values.ville}
                                                                                          onChange={val => helpers.setFieldValue("ville", val)}
                                                                                          placeholder={"Ville"}
                                                                                          isInvalid={!!helpers.errors.ville}
                                                                                     />
                                                                                     <div className={"mb-2"}>
                                                                                          {helpers.errors.ville && <div className={"text-danger"}>{helpers.errors.ville}</div>}
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </>
                                                                 )}

                                                                 {/* Code postal */}
                                                                 <div className="row mb-5">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Code postal</label>

                                                                      <div className="col-lg-8">
                                                                           <MyInputField
                                                                                name={"codePostal"}
                                                                                value={helpers.values.codePostal}
                                                                                onChange={val => helpers.setFieldValue("codePostal", val)}
                                                                                placeholder={"Code postal"}
                                                                                isInvalid={!!helpers.errors.codePostal}
                                                                                inputMode={"numeric"}
                                                                           />
                                                                           <div className={"mb-2"}>
                                                                                {helpers.errors.codePostal && <div className={"text-danger"}>{helpers.errors.codePostal}</div>}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </>
                                                       )}
                                                  </div>

                                                  {/* Submit button */}
                                                  <div className={"d-flex justify-content-end"}>
                                                       <button
                                                            className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                            type={"button"}
                                                            onClick={annulerCallback}
                                                            disabled={helpers.isSubmitting}
                                                       >
                                                            Annuler
                                                       </button>
                                                       <button
                                                            className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                            type={"submit"}
                                                            disabled={helpers.isSubmitting}
                                                       >
                                                            {!helpers.isSubmitting ? <>Prolonger</> : <MySimpleSpinner size={"sm"} />}
                                                       </button>
                                                  </div>
                                             </Form>
                                        )
                                   }}
                              </Formik>
                         </>
                    )}
               </>
          )
     }
}

interface IConsultantSuiviProlongementMissionSalariePropsModel {
     consultant_ID: number
     annulerCallback?: () => void
     setModalNoPaddingCallback?: (noPadding: boolean) => void
     setModalSizeCallback?: (size: "lg" | "xl" | "fullscreen") => void
     onSubmitCallback?: () => void
}

export default ConsultantSuiviProlongementMissionSalarie
