import React, { useState } from "react"
import { usePersonnelDeclarationDetailsContext } from "./_PersonnelDeclarationDetails"
import PersonnelCra from "../cra/_PersonnelCra"

const CRA = () => {
     const context = usePersonnelDeclarationDetailsContext()
     const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

     const Footer = () => {
          return (
               <>
                    <div className="d-flex justify-content-between">
                         <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                   context.setNavigationStep("ddc")
                              }}
                         >
                              <i className={"fas fa-arrow-left me-1"} />
                              DDC
                         </button>
                         <div className={"d-flex"}>
                              {context.areActionsDemandeRectifOrValidationAvailable && (
                                   <button type="button" className="btn btn-sm btn-warning d-flex align-items-center me-2" onClick={() => context.handleDemandeRectification()}>
                                        <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                        <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                                   </button>
                              )}
                              {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                                   <>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-success d-flex align-items-center me-2"
                                             onClick={() => context.handleValidationDemandeRegul()}
                                        >
                                             <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                             <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                             <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                        </button>
                                   </>
                              )}
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary"
                                   onClick={() => {
                                        context.setNavigationStep("ndf")
                                   }}
                              >
                                   NDF
                                   <i className={"fas fa-arrow-right ms-1"} />
                              </button>
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               <div className="mb-4">
                    <PersonnelCra declaration_ID={context.declaration.id as number} editionModeEnabled={false} setIsDataLoaded={setIsDataLoaded} />
               </div>

               {isDataLoaded && <Footer />}
          </>
     )
}

export default CRA
