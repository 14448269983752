import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import ConsultantsListing from "./consultants/listing/ConsultantsListing"
import ConsultantDetails from "./consultants/details/ConsultantDetails"
import ConsultantsSuivisListing from "./consultants/suivi/listing/ConsultantsSuivisListing"

const BusinessRoutes = () => {
     return (
          <Routes>
               <Route
                    element={
                         <>
                              {/*<div>header</div>*/}
                              <Outlet />
                         </>
                    }
               >
                    <Route
                         path="/consultants"
                         element={
                              <>
                                   {/*<div>header</div>*/}
                                   <Outlet />
                              </>
                         }
                    >
                         <Route
                              path="listing"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={consultantsBreadCrumbs}>Liste</PageTitle>
                                        <ConsultantsListing />
                                   </>
                              }
                         />
                         <Route
                              path="suivis"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={consultantsBreadCrumbs}>Suivis</PageTitle>
                                        <ConsultantsSuivisListing />
                                   </>
                              }
                         />
                         <Route
                              path=":consultant_id/details"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={consultantsBreadCrumbs}>Détails</PageTitle>
                                        <ConsultantDetails />
                                   </>
                              }
                         />
                    </Route>

                    {/*Default route*/}
                    <Route index element={<Navigate to="consultants/listing" />} />
               </Route>
          </Routes>
     )
}

const consultantsBreadCrumbs: Array<PageLink> = [
     {
          title: "Consultants",
          path: "/business/consultants/listing",
     },
]

export default BusinessRoutes
