import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { toast } from "react-toastify"
import MyTooltip from "@common-utils/MyTooltip"
import { IConsultantMissionModel } from "@common-models/*"
import { useDashboardAlertesMissionsExpirationListingListContext } from "./_DashboardAlertesMissionsExpirationListingList"
import { useDashboardAlertesMissionsExpirationListingContext } from "./DashboardAlertesMissionsExpirationListing"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import moment from "moment"
import { Link } from "react-router-dom"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const globalContext = useDashboardAlertesMissionsExpirationListingContext()
     const listContext = useDashboardAlertesMissionsExpirationListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   <div className={"d-flex align-items-center"}>
                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            item.consultant?.candidat?.user?.avatarGoogleDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(item.consultant?.candidat?.user.avatarGoogleDriveId as string)
                                                                 : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <Link
                                                       to={`/business/consultants/${item.consultant?.id}/details`}
                                                       className={"text-primary fw-bold fs-5 text-hover-dark"}
                                                       target={"_blank"}
                                                  >
                                                       {item.consultant?.prenomNom}
                                                  </Link>
                                             </div>
                                             <div>
                                                  <span className={"text-muted"}>{item.reference} - </span> <span className={"fw-bold"}>{item.propal?.client?.nom}</span>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex align-items-center">
                                        {moment().diff(item.dateFinValidite) >= 0 ? (
                                             <span className={"badge badge-danger"}>Expiré le {item.dateFinValidite?.format("DD/MM/YYYY")}</span>
                                        ) : (
                                             <span className={"badge badge-warning text-black"}>Expire le {item.dateFinValidite?.format("DD/MM/YYYY")}</span>
                                        )}
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"row align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Date publication */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-calendar-days fs-5 w-25px" />
                                                  Du {item.dateDebutValidite?.format("DD/MM/YYYY")} au {item.dateFinValidite?.format("DD/MM/YYYY")}
                                             </div>

                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" /> {item.consultant?.personnel?.prenomNom}
                                             </div>
                                        </div>
                                        <div className="col text-end">
                                             <div>
                                                  <MyTooltip title={"Prolonger la mission"}>
                                                       <button
                                                            type={"button"}
                                                            className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                                            onClick={() => {
                                                                 item.consultant?.contrat === "CONTRAT_ST"
                                                                      ? globalContext.setItemEnProlongementMissionNonSalarieInModal(item)
                                                                      : globalContext.setItemEnProlongementMissionSalarieInModal({
                                                                             item,
                                                                             modalNoPadding: false,
                                                                             modalSize: "lg",
                                                                        })
                                                            }}
                                                       >
                                                            <i className="fas fa-clock fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                             </div>

                                             <div>
                                                  <MyTooltip title={"Changer la mission"}>
                                                       <button
                                                            type={"button"}
                                                            className={`btn btn-outline btn-outline-muted btn-sm btn-icon rounded-circle mb-2`}
                                                            onClick={() => toast.info("En maintenance ...")}
                                                       >
                                                            <i className="fas fa-sync fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const DashboardAlertesMissionsExpirationListingListItem = ({ item, index }: { item: IConsultantMissionModel; index: number }) => {
     const context = useDashboardAlertesMissionsExpirationListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.id === item.id
     const isItemLoading: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.id === item.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.id !== item.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview({
                    ...getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages),
                    isSelectedItemLoading: item.consultant?.contrat !== "CONTRAT_ST",
               })
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IConsultantMissionModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default DashboardAlertesMissionsExpirationListingListItem
