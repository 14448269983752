import React from "react"
import { useLogsSearchCandidateListeContext } from "./LogsSearchCandidate"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"

// Simple search bar
const LogsSearchCandidateSimpleSearchBar = () => {
     const context = useLogsSearchCandidateListeContext()

     function handleSubmit(values: { simpleSearch?: string }) {
          context.setFilters(prev => ({
               ...prev,
               simpleSearch: values.simpleSearch,
          }))
     }

     function handleReset() {
          context.simpleSearchBarFormikRef.current.resetForm() // Reset local form
          context.filtersFormikRef.current.resetForm() // Reset search bar form
          context.setFilters(() => ({ ...context.INITIAL_FILTERS_STATE })) // Reset payload filters

          context.simpleSearchBarFormikRef.current.setSubmitting(true)
     }

     return (
          <Formik<{ simpleSearch?: string }> initialValues={{}} onSubmit={handleSubmit} innerRef={context.simpleSearchBarFormikRef}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete={"off"}>
                              <div className="input-group">
                                   <MyTooltip title={"Voir les filtres"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-secondary "}
                                             onClick={() => context.setAreFiltersVisible(prev => !prev)}
                                        >
                                             <i className={"fas fa-filter fs-4 text-dark"} />
                                        </button>
                                   </MyTooltip>
                                   <MyInputField
                                        name={"simpleSearch"}
                                        value={helpers.values.simpleSearch}
                                        isInvalid={false}
                                        placeholder={"Rechercher ..."}
                                        onChange={val => {
                                             helpers.setFieldValue("simpleSearch", val).then()
                                             context.filtersFormikRef.current.setFieldValue("simpleSearch", val).then()
                                        }}
                                        classNames={"border"}
                                   />
                                   <button type={"submit"} className={"btn btn-sm btn-outline btn-outline-secondary"} disabled={helpers.isSubmitting}>
                                        {helpers.isSubmitting ? <MySimpleSpinner size={"sm"} classNames={"text-dark"} /> : <i className={"fas fa-search text-dark"} />}
                                   </button>
                                   <MyTooltip title={"Réinitialiser les filtres"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-secondary"}
                                             onClick={() => handleReset()}
                                             disabled={helpers.isSubmitting}
                                        >
                                             <i className={"fas fa-sync text-dark"} />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default LogsSearchCandidateSimpleSearchBar
